<template>
  <ActiveReportsLoader v-if="!activeReportsLoader"
                       v-model="activeReportsLoader"
                       @loaded="onActiveReportsLoad" />
  <div v-else id="report-host" ref="reportViewer" />
</template>

<script>
import ActiveReportsLoader from './ActiveReportsLoader.vue';

export default {
  components: {
    ActiveReportsLoader,
  },
  props: {
    reportDefinition: {
      type: Object,
      required: true,
    },
    reportData: {
      type: [Object, Array],
      required: true,
    },
    reportParameters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      disposables: [],
      viewer: {},
      activeReportsLoader: false,
      iCertusLogo: require('@assets/iCertus.png'),
    };
  },
  watch: {
    reportParameters: {
      handler: 'onReportDefinitionChange',
      deep: true,
    },
    reportDefinition: {
      handler: 'onReportDefinitionChange',
      deep: true,
    },
  },
  beforeDestroy() {
    this.disposables.forEach((fn) => fn());
    if (this.viewer && this.viewer.dispose) {
      this.viewer.dispose();
    }
  },
  methods: {
    onReportDefinitionChange() {
      if (this.viewer) {
        this.viewer.open(this.reportDefinition, { ReportParams: this.reportParameters });
      }
    },
    onActiveReportsLoad() {
      const _this = this;
      this.$nextTick(() => {
        const { Viewer } = window.GC.ActiveReports.ReportViewer;
        this.viewer = new Viewer('#report-host');
        this.viewer.toolbar.updateLayout({
          default: ['$navigation', '$split', '$zoom', '$split', '$print', '$galleymode'],
          mobile: ['$navigation'],
        });
        this.viewer.zoom = 'FitPage';
        this.viewer.open(this.reportDefinition, { ReportParams: this.reportParameters });
        this.disposables = [
          this.viewer.reportLoaded.register((args) => _this.reportLoaded(args)),
          this.viewer.documentLoaded.register((args) => _this.documentLoaded(args)),
        ];
      });
    },
    reportLoaded(args) {
      this.$emit('report-loaded', args);
    },
    documentLoaded(args) {
      this.$emit('document-loaded', args);
    },
  },
};
</script>

<style lang="scss" src="./theme/ar-js-ui.scss"></style>
<style lang="scss" src="./theme/ar-js-viewer.scss"></style>

<style>
#report-host {
  width: 100%;
  height: calc(100% - 60px);
}
</style>
