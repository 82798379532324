import GeneralStockItemsReportsSchema from './GeneralStockItemsReportsSchema';

const GeneralStockItemsReports = () => import(/* webpackChunkName: "general-stock-items-reports" */'./GeneralStockItemsReports');

export default [
  {
    path: GeneralStockItemsReportsSchema.route.path,
    name: GeneralStockItemsReportsSchema.route.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Relatórios' },
        { name: 'Estoques' },
      ],
    },
    component: GeneralStockItemsReports,
  },
];
