<!-- eslint-disable no-unexpected-multiline -->
<template>
  <v-autocomplete
    v-if="!readOnly && !field.readOnly"
    :value="parsedFieldValue"
    :hint="field.hint"
    :persistent-hint="true"
    :required="checkRequired()"
    :disabled="checkDisabled()"
    :multiple="field.multiple"
    :chips="field.chips"
    :items="fieldItems"
    :item-value="field.itemValue"
    :item-text="field.itemText"
    :rules="validationRules"
    :label="field.label"
    :return-object="true"
    :class="field.class"
    :append-outer-icon="field.redirectCreateRoute && appendIcon"
    :loading="isLoading"
    :no-data-text="itemsFound"
    :clearable="field.clearable"
    :clear-icon="icons.close"
    validate-on-blur
    :cache-items="field.cacheItems ?? false"
    :rounded="field.rounded"
    :filled="field.filled"
    :prepend-icon="innerIcon ? '' : prependIcon"
    :prepend-inner-icon="innerIcon ? prependIcon : ''"
    v-bind="$attrs"
    v-on="$listeners"
    @click:append-outer="goTo()"
    @input="onSelectChange">
    <template v-if="field.withDetails" v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title v-html="data.item.name" />
        <v-list-item-subtitle v-if="field.typeDetails === 'client' || 'vendor' || 'buyer'">
          {{ data.item?.cnpj_cpf_formatted ?? data.item?.cnpj_cpf }}, {{ data.item?.addresses?.length > 0 ? data.item?.addresses[0].to_s : '' }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:append-outer>
      <slot name="append-outer" />
    </template>
  </v-autocomplete>
  <div v-else class="dm-information">
    <label>{{ field.label }}</label>
    <p>{{ DynamicUtils.formatFieldValue(value, field) }}</p>
  </div>
</template>

<script>
import { ICON_FORM_SELECT, ICON_FOLDER_PLUS, ICON_CLOSE } from '@constants/icons';
import DynamicUtils from './DynamicUtils';
import DynamicFieldMixin from './DynamicFieldMixin';
import { orderedArray } from '@plugins/app-utils';
import * as LocalForage from '@sharedServices/dynamic/LocalForage';

export default {
  name: 'DynamicAutoComplete',
  mixins: [DynamicFieldMixin],
  data() {
    return {
      DynamicUtils,
      fieldValue: this.value,
      parsedFieldValue: [],
      fieldItems: [],
      icons: {
        close: ICON_CLOSE,
        default: ICON_FORM_SELECT,
        folderPlus: ICON_FOLDER_PLUS,
      },
      itemsFound: 'Carregando...',
      isLoading: true,
    };
  },
  computed: {
    prependIcon() {
      return this.field.prependIcon === undefined ? this.icons.default : this.field.prependIcon;
    },
    appendIcon() {
      return this.field.appendIcon || ICON_FOLDER_PLUS;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
  },
  watch: {
    fieldValue: {
      handler: 'onFieldValueChange',
      immediate: true,
    },
    field: {
      handler: 'onFieldDefinitionChange',
      immediate: true,
    },
  },
  methods: {
    async onFieldDefinitionChange(field, oldField) {
      this.itemsFound = 'Carregando...';
      if (oldField && field.model === oldField.model) {
        return;
      }
      this.fieldItems = (await LocalForage.get(field.name)) || [];
      if (field?.dataSource?.origin) {
        const { service, source } = field.dataSource;
        this.fieldItems = await this.$api[service][source];
        this.isLoading = false;
      } else if (field.items) {
        this.fieldItems = typeof field.items === 'function' ? field.items.call(this) : field.items;
        this.isLoading = false;
      } else if (field.dataSource) {
        const ds = field.dataSource;
        const dsProps = ds.props || [];
        await this.$api[ds.service]
          [ds.method](...dsProps)
          .then((response) => {
            this.fieldItems = response.data;
            if (this.field.sort) {
              const { fieldName } = this.field.sort;
              this.fieldItems = orderedArray(this.fieldItems, fieldName) ?? this.fieldItems;
              this.isLoading = false;
            }
          })
          .catch(() => {
            this.itemsFound = 'Nenhum registro encontrado';
          });
      }
      this.itemsFound = 'Nenhum registro encontrado';
      LocalForage.set(field.name, this.fieldItems);
      this.isLoading = false;
    },
    onFieldValueChange(value) {
      if (this.field?.multiple) {
        if (!value || String(value) === '') {
          this.parsedFieldValue = [];
        } else {
          this.parsedFieldValue = String(value).trim().split('|');
        }
      } else {
        this.parsedFieldValue = value;
      }
      this.itemsFound = 'Carregando...';
    },
    onSelectChange(objectValue) {
      const value = objectValue === null ? undefined : objectValue[this.field.itemValue || 'value'];

      if (this.field.multiple && value) {
        this.fieldValue = value.join('|');
      } else {
        this.fieldValue = value;
      }
      this.fieldChanged(this.fieldValue, value);
    },
    goTo() {
      if (this.field.redirectCreateRoute) {
        this.$router.push({ name: this.field.redirectCreateRoute, params: { return: this.$route.name } });
      }
    },
  },
};
</script>

<style lang="scss"></style>
