import DynamicService from '@sharedServices/dynamic/DynamicService';
import * as AppUtils from '@plugins/app-utils';

const endpoint = 'type_installments';

const parseDayDelay = (value) => {
  switch (value) {
    case value > 1:
      return `${value} dias atrasado.`;
    case value === 1:
      return `${value} dia atrasado.`;
    case value === -1:
      return `${value * -1} dia adiantado.`;
    case value < -1:
      return `${value * -1} dias adiantado.`;
    case value === 0:
      return 'Em dia.';
    default:
      return '-';
  }
};

const formatResponse = (item) => {
  item.compensation_date = AppUtils.formatDate(item.compensation_date) || '-';
  item.due_date = AppUtils.formatDate(item.due_date);
  item.forecast = AppUtils.formatDate(item.forecast);
  item.payment_date = AppUtils.formatDate(item.payment_date) || '-';
  item.paid_off = item.status === 'settled';
  item.value_formatted = item.value === 0 ? 'R$ 0,00' : AppUtils.formatCurrency(item.value || 0.00);
  item.difference_value_formatted = item.difference_value === 0 ? 'R$ 0,00' : AppUtils.formatCurrency(item.difference_value || 0.00);
  item.total_value = item.total_value;
  item.total_value_formatted = AppUtils.formatCurrency(item.total_value) || 'R$ 0,00';
  item.day_delay = parseDayDelay(item.day_delay);
  item.inactive_formatted = item.inactive ? 'Sim' : 'Não';
  item.use_day_fixed_formatted = item.use_day_fixed ? 'Sim' : 'Não';
  item.use_input_formatted = item.use_input ? 'Sim' : 'Não';
  item.use_discount_formatted = item.use_discount ? 'Sim' : 'Não';
  item.use_card_formatted = item.use_card ? 'Sim' : 'Não';

  item.use_interest_simple_formatted = item.use_interest_simple ? 'Sim' : 'Não';
  item.use_interest_compound_formatted = item.use_interest_compound ? 'Sim' : 'Não';
  item.use_day_fixed_formatted = item.use_day_fixed ? 'Sim' : 'Não';
  item.use_day_automatic_formatted = item.use_day_automatic ? 'Sim' : 'Não';
};

const TypeInstallmentCommands = ($axios) => ({
  parseDayDelay(value) {
    switch (value) {
      case value > 1:
        return `${value} dias atrasado.`;
      case value === 1:
        return `${value} dia atrasado.`;
      case value === -1:
        return `${value * -1} dia adiantado.`;
      case value < -1:
        return `${value * -1} dias adiantado.`;
      case value === 0:
        return 'Em dia.';
      default:
        return '-';
    }
  },
  formatRequestData(params) {
    const {
      pageType, formType, accountId, data,
    } = params;

    const request = {
      parcels: {
        type_installment_id: data.type_installment_id,
        total_title_value: data.total_title_value,
        issuance_date: data.issuance_date,
        type_parcel: data.type_parcel,
        account_payment_id: data.account_payment_id,
      },
    };

    if (pageType === 'payment') {
      request.parcels.account_payment_id = accountId;
      request.parcels.type_parcel = 'account_payment';
    }

    if (pageType === 'receive') {
      request.parcels.account_receivable_id = accountId;
      request.parcels.type_parcel = 'account_receivable';
    }

    if (formType === 'clone') {
      delete request.parcels.account_payment_id;
      delete request.parcels.account_receivable_id;
    }

    return request;
  },
  formatResponseData(data, type_parcel, type = 'table') {
    const format = (item) => {
      item.due_date = AppUtils.formatDate(item.due_date);
      item.payment_date = AppUtils.formatDate(item.payment_date) || '-';
      item.compensation_date = AppUtils.formatDate(item.compensation_date) || '-';
      item.forecast = AppUtils.formatDate(item.forecast);
      item.value_formatted = item.value === 0 ? 'R$ 0,00' : AppUtils.formatCurrency(item.value || 0.00);
      item.difference_value_formatted = item.difference_value === 0 ? 'R$ 0,00' : AppUtils.formatCurrency(item.difference_value || 0.00);
      item.difference_value = AppUtils.formatCurrency(item.difference_value);
      item.total_value = item.total_value;
      item.total_value_formatted = AppUtils.formatCurrency(item.total_value);
      item.status = AppUtils.getOptionText(`type_${type_parcel || 'account_payment'}_installments_status`, item.status);
      item.paid_off = item.status === 'settled';
      item.day_delay = this.parseDayDelay(item.day_delay);
      item.source_due_date = AppUtils.formatDate(item?.source_due_date);
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      console.table(data);
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  async getParcels(params) {
    const payload = this.formatRequestData(params);
    const { type_parcel } = payload.parcels;
    let responseData = {};
    await $axios.post(`parcels/${type_parcel}.json`, payload).then((response) => {
      const { data } = this.formatResponseData(response.data, type_parcel);
      responseData = data;
    });

    return responseData;
  },
  async getParcelsReceivable(value) {
    let responseData = {};
    await $axios.post('parcels/account_receivable.json', value).then((response) => {
      responseData = this.formatResponseData(response.data);
    });

    return responseData.data;
  },
  calcParcelPayments(data) {
    return $axios.post('account_payments/installments/recalculate.json', data);
  },
  calcParcelReceivables(data) {
    return $axios.post('account_receivables/installments/recalculate.json', data);
  },
  payParcel(data, titleType) {
    const localValue = data;
    let url;
    if (titleType === 'payment') {
      url = 'account_payments/installments';
    } else if (titleType === 'receivable') {
      url = 'account_receivables/installments';
    }
    return $axios.post(`${url}/pay.json`, localValue);
  },
  reverseParcel(data, titleType) {
    const localValue = data;
    let url;
    if (titleType === 'payment') {
      url = 'account_payments/installments';
    } else if (titleType === 'receivable') {
      url = 'account_receivables/installments';
    }
    return $axios.post(`${url}/reverse.json`, localValue);
  },
});

const TypeInstallment = DynamicService(endpoint, {
  formatResponse,
});

export default { TypeInstallment, TypeInstallmentCommands };
export { TypeInstallment, TypeInstallmentCommands };
