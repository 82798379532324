import { OPTIONS_LOAD_UNITY } from '@constants/options';

export default [
  // Line 1
  {
    label: 'Valor Total',
    name: 'load.total_value',
    type: 'text',
    component: 'dynamic-number-field',
    prependIcon: '',
    preference: ['General', 'number_precision_money'],
    step: '0.01',
    cols: 4,
    colClass: 'nf-field top right left bottom',
    readOnly: true,
  },
  {
    label: 'Peso Bruto Total',
    name: 'load.total_weight',
    type: 'number',
    component: 'dynamic-number-field',
    prependIcon: '',
    preference: ['General', 'number_precision_quantity'],
    min: 0.001,
    prefix: '',
    step: '0.01',
    cols: 4,
    colClass: 'nf-field top right bottom',
    required: true,
  },
  {
    label: 'Unidade de medida',
    name: 'load.unity',
    formattedName: 'unity.to_s',
    type: 'text',
    list: true,
    component: 'dynamic-select',
    prependIcon: '',
    colClass: 'nf-field top right bottom',
    items: OPTIONS_LOAD_UNITY,
    itemText: 'text',
    itemValue: 'value',
    required: true,
    md: 4,
  },
];
