import OrderServiceItemBusiness from '@businesses/OrderServiceItem';

import ProductSchema from './Product';

export default {
  business: OrderServiceItemBusiness,
  icon: ProductSchema.icon,
  routes: {
    list: '',
    edit: '',
    create: '',
  },
  titles: {
    item: 'Serviço da Ordem de Serviço',
    items: 'Serviços das Ordens de Serviço',
    edit: 'Editar Serviço',
    create: 'Criar Serviço',
    new: 'Novo Serviço',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: '',
  fields: [
    {
      label: 'Código',
      name: 'product_code',
      formattedName: 'product.code',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            product_type_description_eq: "'Serviço'", // include ' ' to skip graphql string filter
            query: ['id', 'code', 'to_s'],
          },
          'name asc',
        ],
      },
      itemText: 'code',
      itemValue: 'id',
      list: true,
      md: 5,
    },
    {
      label: 'Serviço',
      name: 'product_id',
      formattedName: 'product.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            product_type_description_eq: "'Serviço'", // include ' ' to skip graphql string filter
            query: ['id', 'code', 'to_s'],
          },
          'name asc',
        ],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 7,
    },
    {
      label: 'Nome',
      columnWidth: '300px',
      name: 'name',
      type: 'string',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 7,
    },
    {
      label: 'Unidade de medida',
      name: 'unity_id',
      formattedName: 'unity.to_s',
      type: 'number',
      list: true,
      required: true,
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-measuringunits-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['unity'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 5,
    },
    {
      label: 'Quantidade',
      name: 'quantity',
      formattedName: 'quantity_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      preference: ['General', 'number_precision_quantity'],
      min: 0.001,
      max: 99999999999999,
      step: '0.01',
      default: 1,
      md: 6,
    },
    {
      label: 'Valor unitário',
      name: 'unit_value',
      formattedName: 'unit_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      required: true,
      md: 6,
    },
    {
      label: 'Valor desconto',
      name: 'discount_value',
      formattedName: 'discount_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 4,
    },
    {
      label: '% Desconto',
      name: 'discount_percentage_value',
      formattedName: 'discount_percentage_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      max: 100,
      md: 4,
    },
    {
      label: 'Valor Total',
      name: 'total_value',
      formattedName: 'total_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 4,
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      type: 'number',
      list: true,
      formattedName: 'nature_operation.to_s',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            type_nature_eq: 'nfse',
            query: ['id', 'name'],
          },
          'name asc',
        ],
      },
      itemText: 'name',
      itemValue: 'id',
      md: 6,
    },
    {
      label: 'Código de Serviço',
      name: 'service_code_id',
      formattedName: 'service_code.to_s',
      type: 'number',
      list: true,
      required: true,
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-servicecodes-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['service_code'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 6,
    },
    {
      label: 'Observações',
      name: 'observation',
      type: 'text',
      component: 'dynamic-text-area',
      md: 12,
    },
  ],
};
