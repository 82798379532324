<template>
  <div :id="item.id">
    <GenericCardPanel
      title="INFOMAÇÕES DE IMPOSTOS"
      :fields="taxesFields" />
  </div>
</template>
<script>
/**
 * Número:

Valor original:

Desconto:

Valor Líquido:

Forma de pagamento:
 */
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabEmitter',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      taxesFields: [],
    };
  },
  mounted() {
    this.taxesFields = [
      { label: 'Base de Cálculo do ICMS', value: this.item.icms, col: 3 },
      { label: 'Valor do ICMS', value: this.item.response_protocol.protocol_formatted, col: 3 },
      { label: 'Inscrição Estadual', value: this.item.emitter.ie, col: 3 },
      { label: 'Inscrição Estadual do Subst. Tributário', value: this.item.emitter.iest, col: 3 },
      { label: 'SUFRAMA', value: this.item.buyer.suframa, col: 3 },
      { label: 'CNPJ', value: this.item.emitter.cnpj, col: 3 },
    ];
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
