<template>
  <v-card elevation="2"
          class="card-scroll"
          :height="height"
          :width="width"
          :max-height="maxHeight"
          :max-width="maxWidth">
    <v-toolbar class="card__toolbar--fixed pt-3" height="fit-content" color="white" flat>
      <v-spacer v-if="titleCenter" />
      <v-toolbar-title :class="titleCenter ? 'title pt-4 text-center' : 'title pt-4 ml-6'">
        <v-icon :large="iconLarge" :color="iconColor" :class="titleCenter ? 'mb-2' : 'mb-1 mr-2'">
          {{ icon }}
        </v-icon>
        <br v-if="titleCenter">
        <span class="font-montserrat" v-html="'<b>' + title + '</b>'" />
      </v-toolbar-title>
      <v-spacer />
      <v-btn v-if="showClose" class="ml-n8" icon @click="close()">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="scrollbar">
      <v-card-title
        v-if="text !== ''"
        class="mt-6 mx-5 justify-center grey--text text--darken-4 card__card-text--word-break font-montserrat">
        <v-progress-circular v-if="showProgress" indeterminate class="mr-2" />
        {{ text }}
      </v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
      <v-card-title
        v-if="htmlText !== ''"
        class="mt-6 mb-3 justify-center grey--text text--darken-4 card__card-text--word-break font-montserrat">
        <v-progress-circular v-if="showProgress" indeterminate class="mr-2" />
        <span class="text-h9 red--text" v-html="htmlText" />
      </v-card-title>
      <v-card-text :class="htmlText ? 'mt-1' : 'mt-n5'" />
    </div>
    <v-card-actions v-if="$slots.actions" class="sticky-action-bar white ml-12 pb-10">
      <slot v-if="$slots.actions" name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { ICON_ALERT } from '@constants/icons';

export default {
  name: 'GenericDialogCard',
  props: {
    icon: {
      type: [String, Object],
      default: ICON_ALERT,
    },
    iconLarge: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: 'indigo lighten-1',
    },
    text: {
      type: String,
      default: '',
    },
    htmlText: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'fit-content',
    },
    width: {
      type: String,
      default: '100%',
    },
    maxHeight: {
      type: String,
      default: '90vh',
    },
    maxWidth: {
      type: String,
      default: 'fit-content',
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    titleCenter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('get-close', false);
      this.$emit('close', false);
    },
  },
};
</script>

<style scoped>
.card__card-text--word-break {
  text-align: center;
  word-break: break-word;
}
.scrollbar {
  position: relative;
  max-height: 70vh;
  margin-top: 5px;
  margin-bottom: 5px;
  overflow-y: auto;
}

.card-scroll {
  max-height: 90vh;
  position: relative;
  overflow-y: clip;
  width: 100%;
}
.card__toolbar--fixed {
  position: relative;
  z-index: 1;
}
</style>
