<template>
  <div class="main-container">
    <div class="son-container" :style="{ minWidth }">
      <div class="totalizer--label-container">
        <v-icon id="icon" class="mx-2 text-white">
          {{ icon }}
        </v-icon>
        <span id="title" class="mr-3" :style="{ color: titleColor }">{{ title }}</span>
      </div>
      <div class="totalizer--totals-container" :style="{ minWidth, maxHeight }">
        <span id="total" :style="{ color: totalColor }">{{ total }}</span>
      </div>
      <slot name="custom-button" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GenericTotalizerNew',
  props: {
    icon: {
      type: String,
      default: () => (''),
    },
    title: {
      type: String,
      default: () => (''),
    },
    titleColor: {
      type: String,
      default: () => ('indigo--text text--lighten-1'),
    },
    total: {
      type: String,
      default: () => (''),
    },
    totalColor: {
      type: String,
      default: () => ('indigo--text text--darken-4'),
    },
    minWidth: {
      type: String,
      default: '60px',
    },
    maxHeight: {
      type: String,
      default: 'auto',
    },
  },
  methods: {
    emitClick() {
      this.$emit('handleCustomButtonClick', true);
    },
  },
};
</script>

<style type="scss" scoped>

.main-container {
  display: flex;
  position: relative;
  width: auto;
  margin: 10px;
}
.main-container::after {
  content:none;
}

.son-container {
  position: relative;
}

.totalizer--label-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: auto;
  min-width: 200px;
  text-align: center;
  border-radius: 25px;
  height: 40px;
  z-index: 1;
  background-color: #5A70D3;
}
.totalizer--label-container span#title {
  vertical-align: middle !important;
  font-family: 'Montserrat';
  color: white;
  font-style: bold;
}
.totalizer--label-container span#icon {
  vertical-align: middle !important;
  text-align: center;
  color: white;
}

.totalizer--totals-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: auto;
  min-width: 198px;
  height: auto;
  margin-top: -19px;
  padding-top: 14px;
  z-index: auto;
  min-height: 60px;
  background-color: white;
  border: solid #5A70D3 2px;
  border-radius: 0px 0px 25px 25px;
}

.totalizer--totals-container span#total {
  vertical-align: middle !important;
  text-align: center;
  font-family: 'Montserrat';
  font-style: bold;
  font-size: large;
  color: #5A70D3;
}

.genericcardform-title {
    font-family: 'Montserrat';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0.01em;
    text-align: left;
}
</style>
