import DynamicService from '@sharedServices/dynamic/DynamicService';
import { formatDate } from '@plugins/app-utils';

const emailSentSystemEndpoint = 'invoice_mailers';

const EmailsSentSystem = DynamicService(emailSentSystemEndpoint, {
  formatResponse: (email) => {
    const formattedDateHour = formatDate(email.created_at, true);
    email.formattedDateHour = formattedDateHour;
  },
});

const EmailsSentSystemCommands = ($axios) => ({
  async sendEmail(id) {
    if (!id) return;
    await $axios.post(`${emailSentSystemEndpoint}/send/${id}`);
  },
  async resendEmail(id) {
    if (!id) return;
    await $axios.post(`${emailSentSystemEndpoint}/${id}`);
  },
});

export {
  EmailsSentSystem,
  EmailsSentSystemCommands,
};

export default {
  EmailsSentSystem,
  EmailsSentSystemCommands,
};
