import Vue from 'vue';
import { convertHourStringToMinutes, convertMinutesToHourString } from '@plugins/app-utils';

export function validateHour(str) {
  return /^[0-9]{2,}:[0-5][0-9]/g.test(str);
}

function updateTotalTime({ time_process, time_machine, time_setup }) {
  const timeProcessInMinutes = convertHourStringToMinutes(time_process);
  const timeMachineInMinutes = convertHourStringToMinutes(time_machine);
  const timeSetupInMinutes = convertHourStringToMinutes(time_setup);
  const totalTime = timeProcessInMinutes + timeMachineInMinutes + timeSetupInMinutes;
  const hours = convertMinutesToHourString(totalTime);
  return hours;
}

function changeTimeHour(fieldValue, formValue, errorMessage = '') {
  if (!validateHour(fieldValue)) {
    Vue.prototype.$notifyError(errorMessage);
    return;
  }
  formValue.time_total = updateTotalTime(formValue);
  const time_total = convertHourStringToMinutes(formValue.time_total);
  formValue.total_cost = (formValue.unit_cost / 60) * time_total;
}

const setProduct = async (formValue, fieldValue) => {
  await Vue.prototype.$api.Products.showGraphql(fieldValue, ['id', 'to_s', 'name', 'code', 'purchase_cost', 'sale_value'])
    .then((response) => {
      const product = response.data;
      Vue.set(formValue, 'product_service', product);
      Vue.set(formValue, 'product_service_id', product.id);
      Vue.set(formValue, 'description', product.id);
    });
};

const setMachine = async (formValue, fieldValue) => {
  await Vue.prototype.$api.Products.showGraphql(fieldValue, ['id', 'to_s', 'name', 'code', 'purchase_cost', 'sale_value'])
    .then((response) => {
      const product = response.data;
      Vue.set(formValue, 'product_machine', product);
      Vue.set(formValue, 'product_machine_id', product.id);
      Vue.set(formValue, 'unit_cost_formatted', product.purchase_cost_formatted);
      Vue.set(formValue, 'unit_cost', product.purchase_cost);
    });
};

export default {
  fieldEvents: {
    product_service_id: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    description: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    product_machine_id: {
      change: async (formValue, fieldValue) => {
        await setMachine(formValue, fieldValue);
      },
    },
    time_process: {
      change: (formValue, fieldValue) => {
        formValue.time_process = fieldValue;
        changeTimeHour(fieldValue, formValue, 'Tempo de Processamento inválido');
        Vue.set(formValue, 'time_process', formValue.time_process);
      },
    },
    time_machine: {
      change: (formValue, fieldValue) => {
        formValue.time_machine = fieldValue;
        changeTimeHour(fieldValue, formValue, 'Tempo de Máquina inválido');
        Vue.set(formValue, 'time_machine', formValue.time_machine);
      },
    },
    time_setup: {
      change: (formValue, fieldValue) => {
        formValue.time_setup = fieldValue;
        changeTimeHour(fieldValue, formValue, 'Tempo de Setup inválido');
        Vue.set(formValue, 'time_setup', formValue.time_setup);
      },
    },
  },
};
