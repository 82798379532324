import PriceListSchema from '@schemas/PriceList';

const PriceList = () => import(/* webpackChunkName: "list-cash-flows" */'./ListPriceLists');
const PriceListDetail = () => import(/* webpackChunkName: "detail-cash-flows" */'./DetailPriceLists');

export default [
  {
    path: PriceListSchema.routes.list.path,
    name: PriceListSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Custos e Preços' },
      ],
    },
    component: PriceList,
  },
  {
    path: PriceListSchema.routes.create.path,
    name: PriceListSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Custos e Preços' },
        { name: 'Lista de Preços', route: PriceListSchema.routes.list.name },
        { name: 'Criar Lista de Preço' },
      ],
    },
    component: PriceListDetail,
  },
  {
    path: PriceListSchema.routes.edit.path,
    name: PriceListSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Custos e Preços' },
        { name: 'Lista de Preços', route: PriceListSchema.routes.list.name },
        { name: 'Editar Lista de Preço' },
      ],
    },
    component: PriceListDetail,
  },
];
