import ConsignmentRoutes from './pages/consignments/routes';
import DevolutionPurchasesRoutes from './pages/devolutionPurchases/routes';
import DevolutionSalesRoutes from './pages/devolutionSales/routes';
import IndustrializationRoutes from './pages/industrialization/routes';
import InvoicesRoutes from './pages/invoices/routes';
import InvoicesISSRoutes from './pages/invoicesISS/routes';
import ManifestsRoutes from './pages/manifests/routes';
import MeasuringUnitsRoutes from './pages/measuringUnits/routes';
import OrderPurchasesRoutes from './pages/orderPurchases/routes';
import OrderSalesRoutes from './pages/orderSales/routes';
import OrderServiceRoutes from './pages/orderServices/routes';
import PartialPurchasesRoutes from './pages/partialPurchases/routes';
import PartialSalesRoutes from './pages/partialSales/routes';
import ProductsRoutes from './pages/products/routes';
import StocksRoutes from './pages/stocks/routes';
import StockItemsRoutes from './pages/stockItems/routes';
import StockHistoriesRoutes from './pages/stockhistories/routes';
import SalesBudgetRoutes from './pages/orderbudgets/routes';

export default [
  ...ConsignmentRoutes,
  ...DevolutionPurchasesRoutes,
  ...DevolutionSalesRoutes,
  ...IndustrializationRoutes,
  ...InvoicesRoutes,
  ...InvoicesISSRoutes,
  ...ManifestsRoutes,
  ...MeasuringUnitsRoutes,
  ...OrderPurchasesRoutes,
  ...OrderSalesRoutes,
  ...OrderServiceRoutes,
  ...PartialPurchasesRoutes,
  ...PartialSalesRoutes,
  ...ProductsRoutes,
  ...StocksRoutes,
  ...StockItemsRoutes,
  ...StockHistoriesRoutes,
  ...SalesBudgetRoutes,
];
