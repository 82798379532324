import { OPTIONS_UF, OPTIONS_PRESENCE_INDICATOR, OPTIONS_TAX_PAYER } from '@constants/options';

export default [
  // Line 1
  {
    label: 'Nome / Razão Social',
    name: 'buyer_id',
    formattedName: 'buyer.to_s',
    type: 'number',
    list: true,
    required: true,
    component: 'dynamic-autocomplete',
    dataSource: {
      service: 'Registers',
      method: 'getByFilter',
      props: [
        { type_register_array: 'client' },
        'name asc'],
    },
    sort: {
      fieldName: 'name',
    },
    itemText: 'name',
    itemValue: 'id',
    colClass: 'nf-field top left',
    prependIcon: '',
    withDetails: true,
    typeDetails: 'client',
    md: 7,
  },
  {
    label: 'CNPJ/CPF',
    name: 'buyer.cnpj_cpf',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    colClass: 'nf-field top left',
    prependIcon: '',
  },
  {
    label: 'Data Emissão',
    name: 'emission_datetime',
    formattedName: 'emission_date_formatted',
    type: 'date',
    component: 'dynamic-date-field',
    list: true,
    required: true,
    clearable: true,
    cols: 2,
    colClass: 'nf-field top left right',
    prependIcon: '',
  },
  // Line 2
  {
    label: 'Logradouro',
    name: 'buyer.address.street',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 5,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Número',
    name: 'buyer.address.number',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'CEP',
    name: 'buyer.address.zip_code',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Hora Emissão',
    name: 'emission_date_hour',
    component: 'dynamic-time-picker',
    format: '24hr',
    required: true,
    clearable: true,
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left right',
  },
  // Line 3
  {
    label: 'Complemento',
    name: 'buyer.address.complement',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 4,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Bairro',
    name: 'buyer.address.neighborhood',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'País',
    name: 'buyer.address.country',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Data Saída',
    name: 'exit_datetime',
    formattedName: 'exit_date_formatted',
    type: 'date',
    component: 'dynamic-date-field',
    list: true,
    required: true,
    clearable: true,
    cols: 2,
    colClass: 'nf-field top left right',
    prependIcon: '',
  },
  // Line 5
  {
    label: 'Município',
    name: 'buyer.address.city',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Cód IBGE',
    name: 'buyer.address.ibge',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 1,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'UF',
    name: 'buyer.address.uf',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_UF,
    cols: 1,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'IE/RG',
    name: 'buyer.ie',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'IM',
    name: 'buyer.im',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 1,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Telefone/Fax',
    name: 'buyer.telephone',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Hora Saída',
    name: 'exit_date_hour',
    component: 'dynamic-time-picker',
    format: '24hr',
    required: true,
    clearable: true,
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left right',
  },
  // Line 6
  {
    label: 'Consumidor Final',
    name: 'is_customer',
    type: 'boolean',
    component: 'dynamic-checkbox',
    colClass: 'nf-field top left bottom',
    md: 3,
  },
  {
    label: 'Tipo de Contribuinte',
    name: 'buyer.taxpayer_type',
    type: 'string',
    component: 'dynamic-select',
    items: OPTIONS_TAX_PAYER,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    md: 4,
  },
  {
    label: 'Indicador de Presença',
    name: 'presence_indicator',
    formattedName: 'presence_indicator_formatted',
    type: 'string',
    component: 'dynamic-select',
    items: OPTIONS_PRESENCE_INDICATOR,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    cols: 3,
  },
  {
    label: 'Data de Competência',
    name: 'competence_datetime',
    formattedName: 'competence_date_formatted',
    type: 'date',
    component: 'dynamic-date-field',
    list: true,
    required: true,
    clearable: true,
    cols: 2,
    colClass: 'nf-field top left bottom right',
    prependIcon: '',
  },
  /*
  {
    label: 'Código de Serviço',
    name: 'service_code',
    formattedName: 'service_code.to_s',
    type: 'string',
    component: 'dynamic-select',
    dataSource: {
      service: 'AllTypes',
      method: 'getManyByTypeExcludeDisables',
      props: ['service_code'],
    },
    itemText: 'to_s',
    itemValue: 'id',
    colClass: 'nf-field top left bottom right',
    prependIcon: '',
    md: 2,
  },
  */
];
