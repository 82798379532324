<template>
  <div class="tab-order-purchase-itens">
    <GenericCardForm>
      <PurchaseTaxes :child-schema="getChildSchemaByName('taxes')" :taxes="itemTaxes" />
    </GenericCardForm>
  </div>
</template>

<script>
import { ICON_REFRESH } from '@constants/icons';
import OrderPurchaseSchema from '@schemas/OrderPurchase';
import GenericCardForm from '@sharedComponents/dynamic/GenericCardForm';
import PurchaseTaxes from './PurchaseTaxes';

export default {
  name: 'TabOrderPurchaseItemTax',
  components: {
    GenericCardForm,
    PurchaseTaxes,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      itemTaxes: [],
      icons: {
        refresh: ICON_REFRESH,
      },
      actions: [],
      filterParams: {},
    };
  },
  computed: {
    schema() {
      return OrderPurchaseSchema;
    },
  },
  created() {
    this.itemTaxes = this.item.items;
  },
  methods: {
    getChildSchemaByName(name) {
      return this.schema.childSchemas.find((schema) => schema.name === name);
    },
  },
};
</script>
