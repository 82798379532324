<template>
  <div :id="item.id">
    <GenericCardPanel
      title="INFORMAÇÕES DO PRODUTO"
      bg-color="white"
      :fields="productInfoFields" />
    <GenericCardPanel
      title="INFORMAÇÕES DE TRIBUTAÇÃO"
      bg-color="white"
      :fields="tributationInfoFields" />
  </div>
</template>

<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabProducts',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      productInfoFields: [],
      tributationInfoFields: [],
    };
  },
  mounted() {
    this.productInfoFields = [
      { label: 'Nome', value: this.item.name, col: 3 },
      { label: 'Tipo do Produto', value: this.item?.product_type?.to_s ? this.item.product_type.to_s : '', col: 3 },
      { label: 'Código', value: this.item.code, col: 3 },
      { label: 'Código SKU', value: this.item.skucode, col: 3 },
      { label: 'Apelido', value: this.item.nickname, col: 3 },
      { label: 'Unidade de Medida', value: this.item?.unity?.to_s ? this.item.unity.to_s : '', col: 3 },
      { label: 'Código de Barras', value: this.item.barcode ? this.item.barcode : '', col: 3 },
      { label: 'Integrado com Marketplace', value: this.item.use_integration_marketplace_formatted, col: 3 },
      { label: 'Usar Apelido', value: this.item.use_nickname ? 'Sim' : 'Não', col: 3 },
      { label: 'Categoria do Produto', value: this.item?.product_category?.to_s ? this.item.product_category.to_s : '', col: 9 },
      { label: 'Custo de Aquisição', value: this.item?.purchase_cost_formatted ? this.item.purchase_cost_formatted : '', col: 3 },
      { label: 'Valor de Venda', value: this.item?.sale_value_formatted ? this.item.sale_value_formatted : '', col: 6 },
      { label: 'Desabilitado', value: this.item.use_inactive_formatted, col: 1 },
    ];
    this.tributationInfoFields = [
      { label: 'NCM', value: this.item?.ncm?.to_s ? this.item.ncm.to_s : '', col: 3 },
      { label: 'CEST', value: this.item?.ncm?.to_s ? this.item.ncm.to_s : '', col: 3 },
    ];
  },
};
</script>
