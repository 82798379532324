import * as Options from '@constants/options';
import { ICON_INVOICE } from '@constants/icons';
import InvoiceItemBusiness from '@businesses/InvoiceItem';

export default {
  service: 'Invoices',
  business: InvoiceItemBusiness,
  icon: ICON_INVOICE,
  routes: {
    list: {
      path: '/supply/invoices-list',
      name: 'supply-invoices-list',
    },
    create: {
      path: '/supply/invoices/create',
      name: 'supply-invoices-create',
    },
    edit: {
      path: '/supply/invoices/edit/:id',
      name: 'supply-invoices-edit',
    },
  },
  titles: {
    item: '',
    items: '',
    edit: '',
    new: '',
    create: '',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'Código de Serviço',
      name: 'service_code_id',
      formattedName: 'service_code.to_s',
      type: 'number',
      list: true,
      required: true,
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-servicecodes-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['service_code'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 4,
    },
    {
      label: 'Situação tributaria ISSQN',
      name: 'code_situation_issqn_id',
      formattedName: 'code_situation_issqn_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-serviceissqn-create',
      clearable: true,
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['service_situation_issqn'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 4,
    },
    {
      label: 'Serviço Tributário',
      name: 'service_nature_operation',
      formattedName: 'service_nature_operation_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: Options.OPTIONS_ISS_NATURE_OPERATION,
      required: true,
      md: 4,
    },
  ],
};
