import _ from 'lodash';

export default {
  props: {
    value: {
      type: Object,
      required: false,
    },
    field: {
      type: Object,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: '',
    },
  },

  mounted() {
    this.updatedFormValue(this.value);
  },

  computed: {
    validationRules() {
      if (this.field.rules) {
        return this.field.rules;
      }
      const rules = [];
      if (this.field.required) {
        rules.push((v) => !!v || `${this.field.label} ${'é um campo obrigatório'}`);
      }

      return rules;
    },
    keyValue() {
      if (this.itemKey) {
        return _.get(this.value, this.itemKey);
      }
      return '';
    },
  },

  watch: {
    value(newVal) {
      if (this.field.name === undefined) return;
      this.updatedFormValue(newVal);
    },
  },

  methods: {
    getDynamicProperties() {
      return this.dynamicProperties;
    },

    updatedFormValue(formValue) {
      if (this.field.name === undefined) return;
      const fieldValue = _.get(formValue, this.field.name);

      this.$nextTick(() => {
        if (fieldValue === undefined && this.field.default !== undefined) {
          this.setFieldValue(this.field.default);
        } else {
          this.fieldValue = fieldValue === undefined || fieldValue === null ? this.emptyValue : fieldValue;
        }
      });
    },

    fieldChanged(value, objectValue) {
      if (this.field.change && typeof this.field.change === 'function') {
        this.field.change(value);
      }
      this.$emit('input', this.field, value, objectValue);
      this.$emit('change', this.field, value, objectValue);
    },

    setFieldValue(value) {
      this.fieldValue = value;
      this.fieldChanged(value);
    },

    checkDisabled() {
      if (this.field.disabled && typeof this.field.disabled === 'function') {
        return this.field.disabled(this.value);
      }
      return this.field.disabled || false;
    },

    checkRequired() {
      if (this.field.required && typeof this.field.required === 'function') {
        return this.field.required(this.value);
      }
      return this.field.required || false;
    },

    checkVisible() {
      if (this.field.visible && typeof this.field.visible === 'function') {
        return this.field.visible(this.value);
      }
      return this.field?.visible ?? true;
    },
  },
};
