import { ICON_MEASURING_UNIT } from '@constants/icons';
// import ProductCompositionFactory from '@businesses/ProductCompositionFactory';

export default {
  service: 'CompositionFactory',
  // business: ProductCompositionFactory,
  icon: ICON_MEASURING_UNIT,
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  titles: {
    item: 'Item do procedimento de fabricação',
    items: 'Itens do procedimento de fabricação',
    edit: 'Editar procedimento de fabricação',
    create: 'Criar procedimento de fabricação',
    new: 'Adicionar Item',
  },
  fields: [
    {
      name: 'step',
      label: 'Ordem',
      disabled: true,
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Processo',
      name: 'product_machine_id',
      formattedName: 'product_machine.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilter',
        props: [{ use_inactive_eq: false, product_type_description_eq: 'Máquina' }, 'name asc'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      list: true,
      required: true,
      md: 4,
    },
    {
      label: 'Código',
      name: 'product_service_id',
      formattedName: 'product_service.code',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilter',
        props: [{ use_inactive_eq: false, product_type_description_eq: 'Setor Produtivo' }, 'name asc'],
      },
      itemText: 'code',
      itemValue: 'id',
      list: true,
      required: true,
      md: 4,
    },
    {
      label: 'Descrição',
      name: 'product_service_description',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilter',
        props: [{ use_inactive_eq: false, product_type_description_eq: 'Setor Produtivo' }, 'name asc'],
      },
      itemText: 'name',
      itemValue: 'to_s',
      list: true,
      md: 4,
    },
    {
      label: 'Quantidade de Tempo',
      name: 'time_total',
      formattedName: 'time_total_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 3,
      disabled: true,
    },
    {
      label: 'Custo Unitário',
      name: 'unit_cost',
      formattedName: 'unit_cost_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 3,
      disabled: true,
    },
    {
      label: 'Custo Total',
      name: 'total_cost',
      formattedName: 'total_cost_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 3,
      disabled: true,
    },
  ],
};
