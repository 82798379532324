import * as AppUtils from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';
import { calculateTotals } from '@businesses/Common/CalculateTotals';
import moment from 'moment';
import { ulid } from 'ulid';

const formatDateStatus = (date) => {
  const hour = date ? moment(date).format('HH:mm:ss') : '';
  return `${AppUtils.formatDate(date)} ${hour}`;
};

const setDateStatus = (order) => {
  order.created_at_formatted = formatDateStatus(order.created_at);
  order.pending_at_formatted = formatDateStatus(order.pending_at);
  order.forecast_at_formatted = formatDateStatus(order.forecast_at);
  order.delivered_at_formatted = formatDateStatus(order.delivered_at);
  order.canceled_at_formatted = formatDateStatus(order.canceled_at);
  order.updated_at_formatted = formatDateStatus(order.updated_at);
};

const OrderBudgets = DynamicService('order_budgets', {
  formatClone: (item) => {
    delete item.id;
    delete item.export?.id;
    item.items = item.items || [];
    item.items.forEach((i) => {
      delete i.id;
      delete i?.export_attributes?.id;
      i._ulid = ulid();
    });
  },
  formatResponse: (order) => {
    order.buyer_id = order.buyer_id || (order.buyer && order.buyer.id) || null;
    order.buyer = { ...order.buyer, to_s: order?.buyer?.to_s || order?.buyer?.name || order?.buyer?.nickname };
    order.salesman_id = order.salesman_id || (order.salesman && order.salesman.id) || null;
    order.salesman = { ...order.salesman, to_s: order?.salesman?.to_s || order?.salesman?.name || order?.salesman?.nickname };
    order.type_installment_id = order.type_installment_id || (order.type_installment && order.type_installment.id) || null;
    order.nature_operation_id = order.nature_operation_id || (order.nature_operation && order.nature_operation.id) || null;
    order.nature_operation = { to_s: order?.nature_operation?.to_s || order?.nature_operation?.name || order?.nature_operation?.nickname };
    order.shipping_company_id = order.shipping_company_id || (order.shipping_company && order.shipping_company.id) || null;

    order.emission_date_formatted = AppUtils.formatDate(order.emission_date);
    order.delivery_date_formatted = order.delivery_date ? AppUtils.formatDate(order.delivery_date) : '';
    order.forecast_date_formatted = AppUtils.formatDate(order.forecast_date);

    order.status = order.status || 'initial';
    order.status_formatted = AppUtils.getOptionText('orders_status', order.status);
    order.note_formatted = order.note;

    order.presence_indicator_formatted = AppUtils.getOptionText('presence_indicator', order.presence_indicator);
    order.freight_value_formatted = AppUtils.formatCurrency(parseFloat(order.freight_value || 0));
    order.expense_value_formatted = AppUtils.formatCurrency(parseFloat(order.expense_value || 0));
    order.freight_formatted = AppUtils.getOptionText('freight', order.freight);
    order.price_list_id = order.price_list_id || (order.price_list_id && order.price_list.id) || null;

    setDateStatus(order);

    order.items = order.items || [];
    let totalDiscount = 0;
    let totalTaxes = 0;
    let totalTaxApproximates = 0;
    let totalProducts = 0;

    if (order.items && order.items.length > 0) {
      const itemsFormatted = order.items.map((item) => {
        item.id = parseInt(item.id);
        const HAS_ICMS_ST_TAXES = item.icms_st;
        const HAS_ICMS_TAXES = item.icms;
        const HAS_IPI_TAXES = item.ipi;
        const HAS_PIS_TAXES = item.pis;
        const HAS_COFINS_TAXES = item.cofins;
        const HAS_SIMPLES_NACIONAL_TAXES = item.simple_national;
        const HAS_ICMS_FINAL_COSTUMER_TAXES = item.icms_final_costumer;

        const mainAddress = order?.buyer?.addresses.find((address) => address.main === true);
        item.buyer_uf = mainAddress?.uf ?? '';

        item.product_id = item.product.id;
        item.product_code = item.product_code || (item.product && item.product.code) || null;
        item.nature_operation_id = (item.nature_operation && item.nature_operation_id) || null;
        item.ncm_id = (item.ncm && item.ncm_id) || null;
        item.unity_id = (item.unity && item.unity_id) || null;
        item.cest_id = (item.cest && item.cest_id) || null;

        const HAS_CEST = item?.cest;
        const cestItem = HAS_CEST ? { ...item.cest, to_s: item.cest.description ?? item.cest.to_s } : {};

        const returnedItem = {
          ...item,
          cest: cestItem,
          icms: {
            base: HAS_ICMS_TAXES ? item.icms.base : 0,
            aliquot: HAS_ICMS_TAXES ? item.icms.aliquot : 0,
            value: HAS_ICMS_TAXES ? item.icms.value : 0,
            base_formatted: HAS_ICMS_TAXES ? AppUtils.formatNumber(item.icms.base) : '0,00',
            aliquot_formatted: HAS_ICMS_TAXES ? AppUtils.formatNumber(item.icms.aliquot) : '0,00',
            value_formatted: HAS_ICMS_TAXES ? AppUtils.formatNumber(item.icms.value) : '0,00',
          },
          icms_st: {
            base: HAS_ICMS_ST_TAXES ? item.icms_st.base : 0.0,
            aliquot: HAS_ICMS_ST_TAXES ? item.icms_st.aliquot : 0.0,
            value: HAS_ICMS_ST_TAXES ? item.icms_st.value : 0.0,
            base_formatted: HAS_ICMS_ST_TAXES ? AppUtils.formatNumber(item.icms_st.base) : '0,00',
            aliquot_formatted: HAS_ICMS_ST_TAXES ? AppUtils.formatNumber(item.icms_st.aliquot) : '0,00',
            value_formatted: HAS_ICMS_ST_TAXES ? AppUtils.formatNumber(item.icms_st.value) : '0,00',
          },
          ipi: {
            base: HAS_IPI_TAXES ? item.ipi.base : 0.0,
            aliquot: HAS_IPI_TAXES ? item.ipi.aliquot : 0.0,
            value: HAS_IPI_TAXES ? item.ipi.value : 0.0,
            base_formatted: HAS_IPI_TAXES ? AppUtils.formatNumber(item.ipi.base) : '0,00',
            aliquot_formatted: HAS_IPI_TAXES ? AppUtils.formatNumber(item.ipi.aliquot) : '0,00',
            value_formatted: HAS_IPI_TAXES ? AppUtils.formatNumber(item.ipi.value) : '0,00',
          },
          pis: {
            base: HAS_PIS_TAXES ? item.pis.base : 0.0,
            aliquot: HAS_PIS_TAXES ? item.pis.aliquot : 0.0,
            value: HAS_PIS_TAXES ? item.pis.value : 0.0,
            base_formatted: HAS_PIS_TAXES ? AppUtils.formatNumber(item.pis.base) : '0,00',
            aliquot_formatted: HAS_PIS_TAXES ? AppUtils.formatNumber(item.pis.aliquot) : '0,00',
            value_formatted: HAS_PIS_TAXES ? AppUtils.formatNumber(item.pis.value) : '0,00',
          },
          cofins: {
            base: HAS_COFINS_TAXES ? item.cofins.base : 0.0,
            aliquot: HAS_COFINS_TAXES ? item.cofins.aliquot : 0.0,
            value: HAS_COFINS_TAXES ? item.cofins.value : 0.0,
            base_formatted: HAS_COFINS_TAXES ? AppUtils.formatNumber(item.cofins.base) : '0,00',
            aliquot_formatted: HAS_COFINS_TAXES ? AppUtils.formatNumber(item.cofins.aliquot) : '0,00',
            value_formatted: HAS_COFINS_TAXES ? AppUtils.formatNumber(item.cofins.value) : '0,00',
          },
          simple_national: {
            base: HAS_SIMPLES_NACIONAL_TAXES ? item.simple_national.base : 0.0,
            aliquot: HAS_SIMPLES_NACIONAL_TAXES ? item.simple_national.aliquot : 0.0,
            value: HAS_SIMPLES_NACIONAL_TAXES ? item.simple_national.value : 0.0,
            base_formatted: HAS_SIMPLES_NACIONAL_TAXES ? AppUtils.formatNumber(item.simple_national.base) : '0,00',
            aliquot_formatted: HAS_SIMPLES_NACIONAL_TAXES ? AppUtils.formatNumber(item.simple_national.aliquot) : '0,00',
            value_formatted: HAS_SIMPLES_NACIONAL_TAXES ? AppUtils.formatNumber(item.simple_national.value) : '0,00',
          },
          icms_final_costumer: {
            base: HAS_ICMS_FINAL_COSTUMER_TAXES ? item.icms_final_costumer.base : 0.0,
            aliquot: HAS_ICMS_FINAL_COSTUMER_TAXES ? item.icms_final_costumer.aliquot : 0.0,
            value: HAS_ICMS_FINAL_COSTUMER_TAXES ? item.icms_final_costumer.value : 0.0,
            base_formatted: HAS_ICMS_FINAL_COSTUMER_TAXES ? AppUtils.formatNumber(item.icms_final_costumer.base) : '0,00',
            aliquot_formatted: HAS_ICMS_FINAL_COSTUMER_TAXES ? AppUtils.formatNumber(item.icms_final_costumer.aliquot) : '0,00',
            value_formatted: HAS_ICMS_FINAL_COSTUMER_TAXES ? AppUtils.formatNumber(item.icms_final_costumer.value) : '0,00',
          },
          unit_value_formatted: AppUtils.formatCurrency(parseFloat(item.unit_value || 0)),
          discount_value_formatted: AppUtils.formatCurrency(parseFloat(item.discount_value || 0)),
          discount_percentage_formatted: `${AppUtils.formatNumber(parseFloat(item.discount_percentage_value))}%`,
          total_value_formatted: AppUtils.formatCurrency(parseFloat(item.total_value || 0)),
          total_value_tmp: parseFloat(item.total_value || 0),
          total_value_tmp_formatted: AppUtils.formatCurrency(parseFloat(item.total_value || 0)),
          tax_approximate_formatted: AppUtils.formatNumber(item.tax_approximate || 0),
          tax_approximate_percentage_formatted: AppUtils.formatNumber(item.tax_approximate_percentage || 0),
          quantity_formatted: AppUtils.formatNumber(item.quantity ?? 0),
        };

        totalDiscount += parseFloat(item.discount_value);
        if (HAS_ICMS_ST_TAXES && HAS_IPI_TAXES) {
          totalTaxes += parseFloat(item.icms_st.value) + parseFloat(item.ipi.value);
        }
        totalTaxApproximates += parseFloat(item.tax_approximate);
        totalProducts += parseFloat(item.quantity) * parseFloat(item.unit_value);

        return returnedItem;
      });

      const totalizers = {
        totalDiscount,
        totalTaxes,
        totalTaxApproximates,
        totalProducts,
      };
      order.items = itemsFormatted;
      order.taxes = itemsFormatted;
      calculateTotals(order, totalizers);
    }

    if (order.observations && order.observations.length > 0) {
      const observationsFormatted = order.observations.map((item) => ({
        emission_date_formatted: AppUtils.formatDate(item.emission_date),
        ...item,
      }));
      order.observations = observationsFormatted;
    }
    return order;
  },
  formatRequest: (order) => {
    const formattedItems = order.items.map((item) => {
      delete item._ulid;
      delete item.product_code;
      delete item.icms;
      delete item.icms_st;
      delete item.ipi;
      delete item.pis;
      delete item.cofins;
      delete item.icms_base;
      delete item.icms_aliquot;
      delete item.icms_value;
      delete item.icms_st_base;
      delete item.icms_st_aliquot;
      delete item.icms_st_value;
      delete item.pis_base;
      delete item.pis_aliquot;
      delete item.pis_value;
      delete item.cofins_base;
      delete item.cofins_aliquot;
      delete item.cofins_value;
      delete item.simple_national;
      delete item.simple_national_st;
      delete item.to_s;
      delete item.order_budget;

      item.freight_value = item.freight_value || 0;
      item.another_value = item.another_value || 0;

      item.quantity = item.quantity.toString();
      item.total_value = item.total_value.toString();
      item.unit_value = item.unit_value.toString();

      return item;
    });

    order.items = formattedItems;
    order.taxes_attributes = order.taxes || [];
    order.items_attributes = formattedItems || [];

    order.observations_attributes = order.observations || [];
    order.export_attributes = order.export || null;

    order.type_installment = order.type_installment || null;
    order.type_installment_id = order.type_installment_id || null;
    order.payment_method = order.payment_method || null;
    order.payment_method_id = order.payment_method_id || null;
  },
});

const OrderBudgetsItems = DynamicService('order_budgets/order_budget_items');

const OrderBudgetsTaxes = ($axios) => ({
  async getTotalizer(id) {
    const response = await $axios.get(`/taxes/order_budgets/${id}/totalizers.json`);
    return response;
  },
  async calculateRealTime(data) {
    try {
      const response = await $axios.post('/taxes/order_budgets/real_time.json', data);
      return response;
    } catch (error) {
      console.warn(error);
    }
  },
});

export { OrderBudgets, OrderBudgetsItems, OrderBudgetsTaxes };
export default {
  OrderBudgets,
  OrderBudgetsItems,
  OrderBudgetsTaxes,
};
