import { ICON_DESCRIPTION } from '@constants/icons';
import '@plugins/rules';

export default {
  icon: ICON_DESCRIPTION,
  fields: [
    {
      label: 'Descrição para Nota Fiscal',
      name: 'invoice_info',
      type: 'string',
      component: 'dynamic-text-area',
      autoGrow: true,
      clearable: true,
      md: 12,
    },
    {
      label: 'Usar Descrição na Nota Fiscal',
      name: 'use_invoice_info',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
    },
  ],
};
