import Vue from 'vue';
import loadDataFromShowApi from './Common/loadDataFromShowApi';

const calculateTotalCostAndTotalSale = (formValue) => {
  const purchase_cost = (parseFloat(formValue.purchase_cost) || 0);
  const sale_value = (parseFloat(formValue.sale_value) || 0);

  const quantity = (parseFloat(formValue.quantity) || 0);

  const total = (purchase_cost * quantity) || 0;
  const total_sell = (sale_value * quantity) || 0;
  Vue.set(formValue, 'total_purchase_cost', total);
  Vue.set(formValue, 'total_sale_value', total_sell);
};

const setProduct = async (formValue, fieldValue) => {
  await Vue.prototype.$api.Products.showGraphql(fieldValue, ['id', 'to_s', 'code', 'unity_id', 'ncm_id', 'cest_id', 'purchase_cost', 'sale_value'])
    .then(async (response) => {
      const product = response.data;

      if (product) {
        formValue.product = product;
        formValue.product_id = product.id;
        formValue.product_code = product.id;
        formValue.name = product.to_s;
        formValue.unity_id = product.unity_id;
        formValue.purchase_cost = product.purchase_cost;
        formValue.total_purchase_cost = product.total_purchase_cost;
        formValue.sale_value = product.sale_value;

        await loadDataFromShowApi(formValue, { key: 'unity', fields: [], service: 'AllTypes' });

        calculateTotalCostAndTotalSale(formValue);
      }
    });
};

export default {
  screenEvents: {
  },
  fieldEvents: {
    product_code: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    product_id: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    unity_id: {
      change: async (formValue) => {
        await loadDataFromShowApi(formValue, { key: 'unity', fields: [], service: 'AllTypes' });
      },
    },
    quantity: {
      change: (formValue) => {
        calculateTotalCostAndTotalSale(formValue);
      },
    },
    purchase_cost: {
      change: (formValue) => {
        const purchase_cost = (parseFloat(formValue.purchase_cost) || 0);
        const quantity = (parseFloat(formValue.quantity) || 0);
        const total = (purchase_cost * quantity) || 0;
        Vue.set(formValue, 'total_purchase_cost', total);
      },
    },
    total_purchase_cost: {
      change: (formValue) => {
        const total_purchase_cost = (parseFloat(formValue.total_purchase_cost) || 0);
        const quantity = (parseFloat(formValue.quantity) || 0);
        const total = (total_purchase_cost / quantity) || 0;
        Vue.set(formValue, 'purchase_cost', total);
      },
    },
    sale_value: {
      change: (formValue) => {
        const sale_value = (parseFloat(formValue.sale_value) || 0);
        const quantity = (parseFloat(formValue.quantity) || 0);
        const total = (sale_value * quantity) || 0;
        Vue.set(formValue, 'total_sale_value', total);
      },
    },
    total_sale_value: {
      change: (formValue) => {
        const total_sale_value = (parseFloat(formValue.total_sale_value) || 0);
        const quantity = (parseFloat(formValue.quantity) || 0);
        const total = (total_sale_value / quantity) || 0;
        Vue.set(formValue, 'sale_value', total);
      },
    },
  },
};
