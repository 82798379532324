import { ICON_INVOICE } from '@constants/icons';
import InvoiceItemBusiness from '@businesses/InvoiceItem';

export default {
  service: 'Invoices',
  business: InvoiceItemBusiness,
  icon: ICON_INVOICE,
  routes: {
    list: {
      path: '/supply/invoices-list',
      name: 'supply-invoices-list',
    },
    create: {
      path: '/supply/invoices/create',
      name: 'supply-invoices-create',
    },
    edit: {
      path: '/supply/invoices/edit/:id',
      name: 'supply-invoices-edit',
    },
  },
  titles: {
    item: '',
    items: '',
    edit: '',
    new: '',
    create: '',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'Descrição para Nota Fiscal',
      name: 'invoice_info',
      type: 'string',
      component: 'dynamic-text-area',
      md: 12,
    },
    {
      label: 'Usar Descrição na Nota Fiscal',
      name: 'use_invoice_info',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
    },
  ],
};
