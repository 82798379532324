import { ICON_HISTORIC } from '@constants/icons';

export default {
  service: 'StockReportService',
  icon: ICON_HISTORIC,
  route: {
    path: '/reports/stock/history',
    name: 'reports-stocks-histories',
  },
  title: 'Relatórios de Histórico de Estoque',
  filtersLayout: [
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'v-col',
          cols: '8',
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [
                {
                  label: 'Produto',
                  name: 'id',
                  formattedName: 'product.to_s',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  multiple: true,
                  dataSource: {
                    service: 'Products',
                    method: 'getByFilter',
                    props: [{ use_inactive_eq: false }, 'name asc'],
                  },
                  itemText: 'name',
                  itemValue: 'id',
                  required: true,
                  md: 8,
                  clearable: true,
                },
                {
                  label: 'Código',
                  name: 'items.product.code',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-text-field',
                  condition: '_matches',
                  notCondition: '_does_not_match',
                  md: 4,
                },
                {
                  label: 'Tipo do Produto ou Serviço',
                  name: 'product_type_id',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-select',
                  multiple: true,
                  clearable: true,
                  dataSource: {
                    service: 'AllTypesCommands',
                    method: 'getManyByTypeExcludeDisables',
                    props: ['product_type'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'Unidade de Medida',
                  name: 'unity_id',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-select',
                  multiple: true,
                  clearable: true,
                  dataSource: {
                    service: 'AllTypesCommands',
                    method: 'getManyByTypeExcludeDisables',
                    props: ['unity'],
                  },
                  itemText: 'description',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'Categoria do Produto',
                  name: 'product_category_id',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-select',
                  multiple: true,
                  clearable: true,
                  dataSource: {
                    service: 'AllTypesCommands',
                    method: 'getManyByTypeExcludeDisables',
                    props: ['product_category'],
                  },
                  itemText: 'description',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'NCM',
                  name: 'ncm_id',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-select',
                  multiple: true,
                  clearable: true,
                  dataSource: {
                    service: 'AllTypesCommands',
                    method: 'getManyByTypeExcludeDisables',
                    props: ['ncm'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'CEST',
                  name: 'cest_id',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-select',
                  multiple: true,
                  clearable: true,
                  dataSource: {
                    service: 'AllTypesCommands',
                    method: 'getManyByTypeExcludeDisables',
                    props: ['cest'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'Status do Produto',
                  name: 'use_inactive',
                  type: 'string',
                  multiple: true,
                  clearable: true,
                  hideNot: true,
                  component: 'dynamic-select',
                  items: [
                    { text: 'Ativo', value: false },
                    { text: 'Inativo', value: true },
                  ],
                  md: 4,
                },
              ],
            },
          ],
        },
        {
          component: 'v-col',
          cols: '4',
          noGutters: true,
          containerClass: 'ml-2',
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [
                {
                  label: 'Datas',
                  name: 'dynamic-dates',
                  fields: [
                    {
                      name: 'date_launch',
                      label: 'Data de Lançamento',
                    },
                  ],
                  component: 'dynamic-multi-date-filter',
                  md: 12,
                  clearable: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
