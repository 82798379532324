import Vue from 'vue';
import { ICON_INVOICE } from '@constants/icons';
import { MASK_INVOICEI_NUMBER } from '@constants/masks';
import '@plugins/rules';

export default {
  icon: ICON_INVOICE,
  titles: {
    item: 'Espelho da Nota Fiscal',
    items: 'Notas Fiscais',
    edit: 'Editar Duplicata',
    new: 'Nova Duplicata',
    create: 'Nova Duplicata',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'Número',
      name: 'number',
      type: 'text',
      component: 'dynamic-text-field',
      cols: 4,
      precision: 0,
      clearable: true,
      list: true,
      mask: MASK_INVOICEI_NUMBER,
      required: true,
    },
    {
      label: 'Vencimento',
      name: 'due_date',
      formattedName: 'due_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      cols: 4,
      list: true,
      required: true,
    },
    {
      label: 'Valor',
      name: 'value',
      formattedName: 'value_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      min: 0.001,
      max: 99999999999999,
      step: '0.01',
      list: true,
      required: true,
      rules: [Vue.prototype.$rules.minValue()],
      md: 4,
    },
  ],
};
