import BtgAccountSchema from '@schemas/BtgAccount';

const DetailBtgAccounts = () => import(/* webpackChunkName: "detail-btg-accounts" */'./pages/btg/DetailBtgAccounts');
const DetailBtgBillets = () => import(/* webpackChunkName: "detail-btg-accounts" */'./pages/btg/DetailBtgBillets');
const DetailBtgAccountsStatements = () => import(/* webpackChunkName: "detail-btg-accounts-statements" */'./pages/btg/DetailBtgAccountsStatements');
const ListBtgAuth = () => import(/* webpackChunkName: "list-btg-" */'./pages/btg/ListBtgAuth');
const ListBtgAccounts = () => import(/* webpackChunkName: "list-btg-accounts" */'./pages/btg/ListBtgAccounts');
const ListBtgBillets = () => import(/* webpackChunkName: "list-btg-billets" */'./pages/btg/ListBtgBillets');
const ListBtgAccountsStatements = () => import(/* webpackChunkName: "list-btg-accounts-statements" */'./pages/btg/ListBtgAccountsStatements');
const ListDasGuides = () => import(/* webpackChunkName: "list-das-guides" */'./pages/das/ListDasGuides');

export default [
  {
    path: '/integrations/btg/auth',
    name: 'integrations-btg-auth',
    meta: {
      rule: 'isInitialRule',
      // layout: 'registerbasic',
      // guest: true,
    },
    component: ListBtgAuth,
  },
  {
    path: BtgAccountSchema.routes.list.path,
    name: BtgAccountSchema.routes.list.name,
    meta: {
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Integrações' },
        { name: 'Btg' },
        { name: 'Contas' },
      ],
    },
    component: ListBtgAccounts,
  },
  {
    path: '/integrations/btg/accounts/:id',
    name: 'integrations-btg-listaccount',
    meta: {
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Integrações' },
        { name: 'Btg' },
        { name: 'Contas', route: 'integrations-btg-listaccount' },
        { name: 'Detalhes' },
      ],
    },
    component: DetailBtgAccounts,
  },
  {
    path: '/integrations/btg/statements',
    name: 'integrations-btg-statements',
    meta: {
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Integrações' },
        { name: 'Btg' },
        { name: 'Contas', route: 'integrations-btg-listaccount' },
        { name: 'Transações' },
      ],
    },
    component: ListBtgAccountsStatements,
  },
  {
    path: '/integrations/btg/accounts/:id/statements/:statement_id',
    name: 'integrations-btg-detailaccountstatements',
    meta: {
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Integrações' },
        { name: 'Btg' },
        { name: 'Contas', route: 'integrations-btg-listaccount' },
        { name: 'Transações' },
      ],
    },
    component: DetailBtgAccountsStatements,
  },
  {
    path: '/integrations/btg/billets/:id',
    name: 'integrations-btg-detailbillets',
    meta: {
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Integrações' },
        { name: 'Btg' },
        { name: 'Boletos', route: 'integrations-btg-listaccount' },
      ],
    },
    component: DetailBtgBillets,
  },
  {
    path: '/integrations/btg/billets',
    name: 'integrations-btg-billets',
    meta: {
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Integrações' },
        { name: 'Btg' },
        { name: 'Boletos' },
      ],
    },
    component: ListBtgBillets,
  },
  {
    path: '/integrations/das/guides',
    name: 'integrations-das-guides',
    meta: {
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Integrações' },
        { name: 'Guias do DAS' },
      ],
    },
    component: ListDasGuides,
  },
];
