import AlltypesSchema from '@schemas/Alltypes';

export default [
  {
    path: AlltypesSchema.routes.evaluations.list.path,
    name: AlltypesSchema.routes.evaluations.list.name,
    meta: AlltypesSchema.routes.evaluations.list.meta,
    component: () => import(/* webpackChunkName: 'list-evaluations' */ `${AlltypesSchema.routes.evaluations.list.component}`),
  },
  {
    path: AlltypesSchema.routes.evaluations.create.path,
    name: AlltypesSchema.routes.evaluations.create.name,
    meta: AlltypesSchema.routes.evaluations.create.meta,
    component: () => import(/* webpackChunkName: 'create-evaluations' */ `${AlltypesSchema.routes.evaluations.create.component}`),
  },
  {
    path: AlltypesSchema.routes.evaluations.edit.path,
    name: AlltypesSchema.routes.evaluations.edit.name,
    meta: AlltypesSchema.routes.evaluations.edit.meta,
    component: () => import(/* webpackChunkName: 'edit-evaluations' */ `${AlltypesSchema.routes.evaluations.edit.component}`),
  },
];
