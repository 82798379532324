/** *
 * Sample of Business Rules Layer
 * TODO: Documentar camada de negócios do front
 * TODO: implementar regras para lidar com childSchemas na camada de negócios
 * Available functions:
 *  - for simple fields: change
 *  - for arrays: beforeUpdate, beforeCreate, update, create, select
 */

export default {
  screenEvents: {
    // Return false or a string to cancel save action, returning a String the screen will notify as an error.
    beforeSave() {

    },
  },
};
