import { CnpjMap } from './cnpjmap/CnpjMap';
import { Tutorials } from './videotutorials/VideoTutorials';
import FilterService from './dynamic/FilterService';
import MixpanelService from './dynamic/MixpanelService';

export const globalServices = {
  CnpjMap,
  Tutorials,
  FilterService,
  MixpanelService,
};
