import { ICON_INVOICE, ICON_FORM_NUMERIC } from '@constants/icons';
import { MASK_CPF } from '@constants/masks';
import Vue from 'vue';

export default {
  service: 'Manifest',
  // business: ManifestConductorBusiness,
  icon: ICON_INVOICE,
  routes: {
    list: {
      path: '/supply/manifest-list',
      name: 'supply-manifest-list',
    },
    create: {
      path: '/supply/manifest/create',
      name: 'supply-manifest-create',
    },
    edit: {
      path: '/supply/manifest/edit/:id',
      name: 'supply-manifest-edit',
    },
  },
  titles: {
    item: 'Condutor Manifesto',
    items: 'Condutor Manifesto',
    edit: 'Editar Condutor',
    new: 'Novo Condutor',
    create: 'Novo Condutor',
  },
  messages: {
    successDelete: 'Condutor removido com sucesso!',
    successEdit: 'Condutor editado com sucesso!',
    successCreate: 'Condutor adicionado com sucesso!',
    errorSave: 'Erro ao salvar condutor!',
  },
  fields: [
    {
      label: 'ID',
      name: 'id',
      type: 'number',
      itemText: 'id',
      itemValue: 'id',
      list: false,
      md: 9,
    },
    {
      label: 'CPF *',
      name: 'cpf',
      formattedName: 'cpf',
      type: 'number',
      component: 'dynamic-text-field',
      mask: MASK_CPF,
      prependIcon: ICON_FORM_NUMERIC,
      itemText: 'code',
      itemValue: 'code',
      list: true,
      md: 4,
      required: true,
      rules: [Vue.prototype.$rules.min(14)],
    },
    {
      label: 'Nome Completo *',
      name: 'name',
      formattedName: 'name',
      type: 'string',
      component: 'dynamic-text-field',
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      list: true,
      md: 8,
      rules: [Vue.prototype.$rules.fullName()],
    },
  ],
};
