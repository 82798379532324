import * as AppUtils from '@plugins/app-utils';

const endpoint = 'anticipation';

export default ($axios) => ({
  async getDataAnticipation() {
    const response = await $axios.get(`${endpoint}/integrations.json`);
    const responseData = this.formatResponseData(response.data, 'row');
    return responseData;
  },
  async getDuplicates() {
    const response = await $axios.get(`${endpoint}/invoices.json`);
    return response.data;
  },
  signAnticipation(payload) {
    return $axios.post(`${endpoint}/integrations.json`, payload);
  },
  async getLimits() {
    const { data } = await $axios.get(`${endpoint}/integrations/limit_details.json`);

    const formattedData = this.formatLimitsList(data);
    return formattedData;
  },
  async getDuplicateProposals() {
    const { data } = await $axios.get(`${endpoint}/integrations/approved_titles.json`);
    return this.formatDuplicateProposals(data);
  },
  async operateProposals(duplicates) {
    const { data } = await $axios.post(`${endpoint}/integrations/operate.json`, duplicates);
    return data;
  },
  async generateAgreement(data) {
    const response = await $axios.post(`${endpoint}/integrations/assignment_agreement.json`, data);
    return response.data;
  },
  async uploadXMLFile(payload) {
    const formData = new FormData();
    formData.append('file', payload.file);
    const res = await $axios.post(`${endpoint}/integrations/upload_xml.json`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  },

  /**
   * Format Functions
   */
  formatResponseData(data, type = 'table') {
    const format = (item) => {
      item.available_limit_formatted = item.available_limit !== null || 0 || undefined ? `R$ ${AppUtils.formatCurrency(item.available_limit)}` || 'R$ 0,00' : 'Em Analise';
      item.total_limit_formatted = item.total_limit !== null || 0 || undefined ? `R$ ${AppUtils.formatCurrency(item.total_limit)}` || 'R$ 0,00' : 'Em Analise';

      if (item.situations) {
        item.situations.forEach((i) => {
          i.situation_formatted = this.getSituations(i.situation);
          i.last_update = AppUtils.formatDate(i.updated_at) || '-';
        });
      }
      if (item.limits) {
        item.limits.forEach((i) => {
          i.available_limit_formatted = item.available_limit !== null || 0 || undefined ? `R$ ${AppUtils.formatCurrency(item.available_limit)}` || 'R$ 0,00' : 'Em Analise';
          i.total_limit_formatted = item.total_limit !== null || 0 || undefined ? `R$ ${AppUtils.formatCurrency(item.total_limit)}` || 'R$ 0,00' : 'Em Analise';
          i.last_update = AppUtils.formatDate(i.updated_at) || '-';
        });
      }
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },

  getSituations(situation) {
    switch (situation) {
      case 'disapproved':
        return 'Recusado';
      default:
        return '';
    }
  },

  async getAssignors() {
    const { data } = await $axios.get(`${endpoint}/integrations/get_assignors.json`);
    const formattedData = this.formatAssignorsResponse(data.data);
    return formattedData;
  },
  async loginAuthenticatedAdiante() {
    const { data } = await $axios.get(`${endpoint}/integrations/login_authenticated_adiante.json`);
    const formattedData = `${data.result.data.url}/${data.result.data.token}`;
    return formattedData;
  },
  async refreshLimit(id) {
    const response = await $axios.post(`${endpoint}/integrations/refresh_limit.json,`, { fund_id: id });
    const formattedData = this.formatLimit(response.data);
    return formattedData;
  },
  async registerInvoice(invoice_id) {
    const data = {
      invoice_id,
    };
    const response = await $axios.post(`${endpoint}/integrations/register_nfe.json`, data);
    return response.data;
  },
  async refreshSituations(id) {
    const { data } = await $axios.post(`${endpoint}/integrations/refresh_situation.json`, { fund_id: id });
    return this.formatSituation(data);
  },
  async updateRegisterInfo(data) {
    const removeDash = (cep) => cep.replace('-', '');

    data.address.zip_code = removeDash(data.address.zip_code);
    data.legal_representative.address.zip_code = removeDash(data.legal_representative.address.zip_code);

    const response = await $axios.put(`${endpoint}/integrations/true.json`, data);
    return response.data;
  },
  fakeData() {
    return {
      to_s: 'ASSIGNOR TEST 74053127000105',
      id: 48,
      cnpj: '74053127000105',
      name: 'ASSIGNOR TEST 74053127000105',
      email: '74053127000105@assignor.com.br',
      available_limit: '0.0',
      total_limit: '0.0',
      billed_amount: '100.0',
      drawees: [],
      current_limit: 0,
      address: {
        id: 13,
        zip_code: '03319-110',
        street: 'R JOAQUIM PINTO',
        number: '177',
        complement: 'teste',
        neighborhood: 'TATUAPE',
        type_address: 'Principal',
        assignor_id: 48,
        created_at: '2021-08-03T15:19:42.091Z',
        updated_at: '2021-08-03T15:19:42.091Z',
      },
      bank: {
        id: 9,
        agency: '3293',
        account: '71447',
        digit: '1',
        code_bank: '104',
        type_account: '2',
        assignor_id: 48,
        created_at: '2021-08-03T15:19:42.183Z',
        updated_at: '2021-08-03T15:19:42.183Z',
      },
      phone: {
        id: 7,
        type_phone: 'Principal',
        code_country: '55',
        code_ddd: '11',
        number: ' 55894743',
        assignor_id: 48,
        created_at: '2021-08-03T15:19:42.272Z',
        updated_at: '2021-08-03T15:19:42.272Z',
      },
      user: {
        id: 2,
        provider: 'email',
        allow_password_change: false,
        name: 'Certus Legado',
        nickname: 'Certus Legado',
        image: '',
        email: 'contato@certus.inf.br',
        is_inactive: null,
        admin: true,
        created_at: '2021-07-20T18:40:22.724Z',
        updated_at: '2021-07-20T18:40:22.724Z',
        fund_id: null,
        is_erp: true,
        cnpj: '99.999.999/999-99',
        authentication_token: 'GYs9yxRLNnnvHgRomn29',
      },
      limits: [
        {
          id: 8,
          cnpj: '74053127000105',
          tax: null,
          available_limit: '100000.0',
          total_limit: '100000.0',
          fund_id: 1,
          created_at: '2021-08-03T15:28:58.039Z',
          updated_at: '2021-08-03T15:28:58.039Z',
          user_id: 2,
          assignor_id: 48,
          fund: {
            id: 1,
            cnpj: '20338169000163',
            ie: '23424',
            im: '363424',
            company_name: 'Adiante',
            fantasy: 'Adiante',
            created_at: '2021-07-21T17:31:37.140Z',
            updated_at: '2021-07-21T17:31:37.140Z',
            logo: 'https://icertus-anticipation-images.s3.amazonaws.com/1/logo-adiante.jpg',
          },
        },
      ],
      situations: [
        {
          id: 37,
          code_situation: 3,
          situation: 'disapproved',
          assignor_id: 48,
          fund_id: 1,
          created_at: '2021-08-03T17:27:47.176Z',
          updated_at: '2021-08-03T17:27:47.176Z',
          user_id: 2,
          fund: {
            id: 1,
            cnpj: '20338169000163',
            ie: '23424',
            im: '363424',
            company_name: 'Adiante',
            fantasy: 'Adiante',
            created_at: '2021-07-21T17:31:37.140Z',
            updated_at: '2021-07-21T17:31:37.140Z',
            logo: 'https://icertus-anticipation-images.s3.amazonaws.com/1/logo-adiante.jpg',
          },
        },
      ],
    };
  },
  formatLimits(limits) {
    const limitsFormatted = limits.map((limit) => {
      const totalFormatted = AppUtils.formatCurrency(parseFloat(limit.total_limit));
      const availableFormatted = AppUtils.formatCurrency(parseFloat(limit.available_limit));
      const updatedAtFormatteed = AppUtils.formatDate(limit.updated_at);
      return {
        ...limit,
        total_formatted: totalFormatted,
        available_formatted: availableFormatted,
        updated_at_formatted: updatedAtFormatteed,
      };
    });
    return limitsFormatted;
  },
  formatAssignorsResponse(data) {
    const totalLimitsFormatted = AppUtils.formatNumber(parseFloat(data.total_limit), 2);
    const availableLimiitsFormatted = AppUtils.formatNumber(parseFloat(data.available_limit), 2);
    const limitsFormatted = this.formatLimits(data.limits);
    const situationsFormatted = data.situations.map((situation) => ({
      ...situation,
      updated_at_formatted: AppUtils.formatDate(situation.updated_at),
      situation_formatted: AppUtils.getOptionText('situations', situation.situation),
    }));

    return {
      ...data,
      limits: limitsFormatted,
      total_limit_formatted: totalLimitsFormatted,
      available_limit_formatted: availableLimiitsFormatted,
      situations: situationsFormatted,
      trading_name: data.trading_name ?? '',
      foundation_date: data.foundation_date ?? '',
      main_activity: data.main_activity ?? '',
      external_reference: data.external_reference ?? '',
      legal_representative: data?.legal_representative ?? null,
    };
  },
  formatLimit({ limit }) {
    const totalFormatted = AppUtils.formatCurrency(parseFloat(limit.total_limit));
    const availableFormatted = AppUtils.formatCurrency(parseFloat(limit.available_limit));
    const updatedAtFormatteed = AppUtils.formatDate(limit.updated_at);
    return {
      ...limit,
      total_formatted: totalFormatted,
      available_formatted: availableFormatted,
      updated_at_formatted: updatedAtFormatteed,
    };
  },
  formatLimitsList(data) {
    const totalFormatted = AppUtils.formatNumber(parseFloat(data.total_limit), 2);
    const availableFormatted = AppUtils.formatNumber(parseFloat(data.available_limit), 2);

    const limitsFormatted = data.limits.map((limit) => {
      const totalFormattedItem = AppUtils.formatCurrency(parseFloat(limit.total));
      const availableFormattedItem = AppUtils.formatCurrency(parseFloat(limit.available));
      const updatedAtFormatteed = AppUtils.formatDate(limit.updated_at);
      return {
        ...limit,
        total_formatted: totalFormattedItem,
        available_formatted: availableFormattedItem,
        updated_at_formatted: updatedAtFormatteed,
      };
    });

    return {
      ...data,
      limits: limitsFormatted,
      total_limit_formatted: totalFormatted,
      available_limit_formatted: availableFormatted,
    };
  },
  formatSituation(data) {
    const situationsFormatted = {
      ...data,
      updated_at_formatted: AppUtils.formatDate(data.updated_at),
      situation_formatted: AppUtils.getOptionText('situations', data.situation),
    };
    return situationsFormatted;
  },
  formatDuplicateProposals(duplicates = []) {
    const duplicatesFormatted = duplicates
      .map((duplicate) => {
        const { fund } = duplicate;
        const parcel_value_formatted = AppUtils.formatCurrency(parseFloat(duplicate.parcel_value));
        const acquisition_value_formatted = AppUtils.formatCurrency(parseFloat(duplicate.acquisition_value));
        const due_date_formatted = AppUtils.formatDate(duplicate.due_date);
        return {
          ...duplicate,
          parcel_value_formatted,
          acquisition_value_formatted,
          due_date_formatted,
          fund_fantasy: fund.fantasy,

        };
      });
    return duplicatesFormatted;
  },
  async getPendingDuplicates() {
    const { data } = await $axios.get(`${endpoint}/integrations/pending_titles.json`);
    return this.formatDuplicateProposals(data);
  },

  async getReprovedDuplicates() {
    const { data } = await $axios.get(`${endpoint}/integrations/disapproved_titles.json`);
    return this.formatDuplicateProposals(data);
  },
  async getOperableDuplicates() {
    const { data } = await $axios.get(`${endpoint}/integrations/operables.json`);
    return this.formatDuplicateProposals(data);
  },
  async generateTicket(data) {
    const response = await $axios.post(`${endpoint}/integrations/billets.json`, data);
    return response.data;
  },
});
