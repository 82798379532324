import {
  ICON_ALERT_BOX,
  ICON_DESCRIPTION,
  ICON_CART,
  ICON_TAXES,
  ICON_TRUCK,
  ICON_PRODUCT,
} from '@constants/icons';
import { OPTIONS_PRESENCE_INDICATOR, OPTIONS_FREIGHT, OPTIONS_ORDERSPURCHASESALE_STATUS } from '@constants/options';
import OrderPurchaseBusiness from '@businesses/OrderPurchase';
import OrderPurchaseItem from './OrderPurchaseItem';
import OrderPurchaseItemTax from './OrderPurchaseItemTax';
import OrderPurchaseTransporter from './OrderPurchaseTransporter';

import TabOrderPurchase from '@modules/supply/pages/orderPurchases/components/TabOrderPurchase';
import TabOrderPurchaseDevolutions from '@modules/supply/pages/orderPurchases/components/TabOrderPurchaseDevolutions';
import TabOrderPurchaseItens from '@modules/supply/pages/orderPurchases/components/TabOrderPurchaseItens';
import TabOrderPurchaseItensTax from '@modules/supply/pages/orderPurchases/components/TabOrderPurchaseItensTax';
import TabOrderPurchasePartials from '@modules/supply/pages/orderPurchases/components/TabOrderPurchasePartials';
import TabOrderPurchaseTransporter from '@modules/supply/pages/orderPurchases/components/TabOrderPurchaseTransporter';

import ObservationSchema from './Observation';
import DevolutionPurchase from './DevolutionPurchase';

export default {
  service: 'OrderPurchases',
  business: OrderPurchaseBusiness,
  icon: ICON_CART,
  routes: {
    list: { path: '/supply/order-purchases', name: 'supply-orderpurchases-list' },
    create: { path: '/supply/order-purchases/create', name: 'supply-orderpurchases-create' },
    edit: { path: '/supply/order-purchases/edit/:id', name: 'supply-orderpurchases-edit' },
  },
  titles: {
    item: 'Pedido de Compras',
    items: 'Pedidos de Compras',
    edit: 'Editar Pedido de Compras',
    create: 'Criar Pedido de Compras',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Ordem de compras excluida com sucesso!',
    successEdit: 'Ordem de compras alterada com sucesso!',
    successCreate: 'Ordem de compras criada com sucesso!',
    errorSave: 'Erro ao salvar ordem de compras!',
    tryEditCanceled: 'Não é possível editar uma ordem de compras cancelada.',
    confirmCancel: 'Deseja cancelar a ordem de compras?',
    successCancel: 'Ordem de compras cancelada com sucesso',
    errorCancel: 'Erro ao cancelar ordem de compras',
  },
  filterUrl: 'order_purchases/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  canClone: true,
  statusSettings: {
    options: OPTIONS_ORDERSPURCHASESALE_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
    statusDetails: true,
  },
  formName: 'orderpurchases',
  fields: [
    {
      label: 'INFORMAÇÕES DA COMPRA',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options: 'orders_purchase_sale_status',
      list: true,
    },
    {
      label: 'Fornecedor',
      name: 'vendor_id',
      formattedName: 'vendor.to_s',
      type: 'number',
      list: true,
      required: true,
      hint: '',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_register_array: 'vendor',
          query: ['id', 'name', 'cnpj_cpf', 'addresses', ['id', 'to_s'], 'preference', ['shipping_company_id']],
        }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      withDetails: true,
      typeDetails: 'vendor',
      md: 8,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Pedido',
      name: 'purchase_order',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 2,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Devolução',
      formattedName: 'has_devolution_formatted',
      md: 2,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Data Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      list: true,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Colaborador (Solicitante)',
      name: 'collaborator_id',
      formattedName: 'collaborator.to_s',
      type: 'number',
      list: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{ type_register_array: 'collaborator', query: ['id', 'name'] }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      md: 8,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'NFe',
      name: 'note',
      formattedName: 'note_formatted',
      list: true,
      type: 'text',
      component: 'dynamic-text-field',
      readOnly: true,
      md: 2,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Data Previsão',
      name: 'forecast_date',
      formattedName: 'forecast_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Consumidor Final',
      name: 'is_costumer',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Indicador de Presença',
      name: 'presence_indicator',
      formattedName: 'presence_indicator_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_PRESENCE_INDICATOR,
      md: 5,
      required: true,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Nº da Ordem',
      name: 'order',
      type: 'text',
      component: 'dynamic-text-field',
      md: 2,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Data Entrega',
      name: 'delivery_date',
      formattedName: 'delivery_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      md: 2,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Forma de Pagamento',
      name: 'payment_method_id',
      formattedName: 'payment_method.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-paymentmethod-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['payment_method'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Parcelamento',
      name: 'type_installment_id',
      formattedName: 'type_installment.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-typeinstallment-create',
      dataSource: {
        service: 'TypeInstallment',
        method: 'getByFilter',
        props: [{ inactive_eq: false }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      formattedName: 'stock.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 3,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'Total de Impostos Aproximados',
      formattedName: 'total_tax_aproximates',
      name: 'total_tax_aproximates',
      type: 'number',
    },
    {
      label: 'Valor Total',
      formattedName: 'total_ordered_formatted',
      type: 'number',
      list: true,
      tabGroup: 'infoPurchase',
    },
    {
      label: 'INFORMAÇÕES DE FRETE',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'infoFreight',
    },
    {
      label: 'Frete',
      name: 'freight',
      formattedName: 'freight_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_FREIGHT,
      required: true,
      md: 3,
      tabGroup: 'infoFreight',
    },
    {
      label: 'Valor do Frete',
      name: 'freight_value',
      formattedName: 'freight_value_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
      tabGroup: 'infoFreight',
    },
    {
      label: 'Despesas',
      name: 'expense_value',
      formattedName: 'expense_value_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
      tabGroup: 'infoFreight',
    },
    {
      label: 'Incluir Frete no Total',
      name: 'use_freight',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      tabGroup: 'infoFreight',
    },
    {
      label: 'TRIBUTAÇÕES DO PEDIDO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      formattedName: 'nature_operation.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_nature_in: ['purchase'],
          query: ['id', 'name'],
        }],
      },
      itemText: 'name',
      itemValue: 'id',
      list: true,
      required: true,
      md: 12,
      tabGroup: 'infoPurchase',
    },
  ],
  childSchemas: [
    {
      label: 'Itens do Pedido de Compras',
      name: 'items',
      schema: OrderPurchaseItem,
    },
    {
      label: 'Conferência de Impostos',
      name: 'taxes',
      noCreate: true,
      noEdit: true,
      noDelete: true,
      noActions: true,
      schema: OrderPurchaseItemTax,
      hideDefaultHeader: true,
      customHeader: {
        customClass: 'custom-child-header-order-purchase',
        ungroupedHeadersFront: 2,
        groupHeaders: [
          { text: 'SIMPLES NACIONAL', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS ST', colspan: 3, class: 'order-purchase-header' },
          { text: 'IPI', colspan: 3, class: 'order-purchase-header' },
          { text: 'PIS', colspan: 3, class: 'order-purchase-header' },
          { text: 'COFINS', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS CONSUMIDOR FINAL', colspan: 3, class: 'order-purchase-header' },
          { text: 'VALOR APROXIMADOS', colspan: 3, class: 'order-purchase-header' },
        ],
      },
    },
    {
      label: 'Transportadora',
      name: 'transporter',
      schema: OrderPurchaseTransporter,
      hasOne: true,
    },
    {
      label: 'Observações',
      name: 'observations',
      schema: ObservationSchema,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabOrderPurchase,
    },
    {
      icon: ICON_PRODUCT,
      title: 'Itens do Pedido de Compra',
      tabContent: TabOrderPurchaseItens,
    },
    {
      icon: ICON_TAXES,
      title: 'Conferência de Impostos',
      tabContent: TabOrderPurchaseItensTax,
    },
    {
      icon: ICON_TRUCK,
      title: 'Transportadora',
      tabContent: TabOrderPurchaseTransporter,
    },
    {
      icon: DevolutionPurchase.icon,
      title: 'Devoluções',
      tabContent: TabOrderPurchaseDevolutions,
    },
    {
      icon: ICON_ALERT_BOX,
      title: 'Entregas Parciais',
      tabContent: TabOrderPurchasePartials,
    },
  ],
};
