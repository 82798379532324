import AddressClientTypesRoutes from './addressclienttypes/routes';
import AddressTypesRoutes from './addresstypes/routes';
import CategoriesRoutes from './categories/routes';
import CoinsRoutes from './coins/routes';
import EvaluationRoutes from './evaluations/routes';
import ListPricesRoutes from './listprices/routes';
import MeasuringUnitsRoutes from './measuringunits/routes';
import ObservationsRoutes from './observations/routes';
import OccupationsRoutes from './occupations/routes';
import NcmsRoutes from './ncms/routes';
import OperationsRoutes from './operations/routes';
import PaymentMethods from './paymentmethod/routes';
import ProductCategories from './productcategories/routes';
import ProductTypes from './producttypes/routes';
import SectorsRoutes from './sectors/routes';
import ServiceCodesRoutes from './servicecodes/routes';
import ServiceCstRoutes from './servicecst/routes';
import ServiceIssqnRoutes from './serviceissqn/routes';
import StockTypes from './stocktypes/routes';
import TypesRoutes from './types/routes';

export default [
  ...AddressClientTypesRoutes,
  ...AddressTypesRoutes,
  ...CategoriesRoutes,
  ...CoinsRoutes,
  ...EvaluationRoutes,
  ...ListPricesRoutes,
  ...MeasuringUnitsRoutes,
  ...NcmsRoutes,
  ...ObservationsRoutes,
  ...OccupationsRoutes,
  ...OperationsRoutes,
  ...PaymentMethods,
  ...ProductCategories,
  ...ProductTypes,
  ...SectorsRoutes,
  ...ServiceCodesRoutes,
  ...ServiceCstRoutes,
  ...ServiceIssqnRoutes,
  ...StockTypes,
  ...TypesRoutes,
];
