import AccountReceivables from './services/AccountReceivables';
import AccountPayments from './services/AccountPayments';
import Anticipations from './services/anticipation/Anticipation';
import AnticipationsCreditCards from './services/anticipation/AnticipationsCreditCards';
import { Banks, BanksSimplified } from './pages/banks/service';
import Billets from './services/Billets';
import BilletsPending from './services/BilletsPending';
import BilletsApproved from './services/BilletsApproved';
import BilletsRemittance from './services/BilletsRemittance';
import BilletsReturn from './services/BilletsReturn';
import {
  CashFlows,
  CashFlowsSearch,
  CashFlowsCommands,
} from './pages/cashflows/services.js';
import { CreditPartnerFormCommands } from './pages/creditPartners/service';
import { WorkingCapitalCommands } from './services/workingCapital/service';
import InstallmentsPayments from './services/InstallmentsPayments';
import InstallmentsReceivables from './services/InstallmentsReceivables';
import Launch from './services/banks/Launch';
import OpenTitlesPayments from './services/opentitles/Payments';
import OpenTitlesReceivables from './services/opentitles/Receivables';

export { default as routes } from './routes';

export const globalServices = {
  AccountReceivables,
  AccountPayments,
  Anticipations,
  AnticipationsCreditCards,
  Banks,
  BanksSimplified,
  Billets,
  BilletsPending,
  BilletsApproved,
  BilletsRemittance,
  BilletsReturn,
  CashFlows,
  CashFlowsSearch,
  CashFlowsCommands,
  CreditPartnerFormCommands,
  WorkingCapitalCommands,
  InstallmentsPayments,
  InstallmentsReceivables,
  Launch,
  OpenTitlesPayments,
  OpenTitlesReceivables,
};
