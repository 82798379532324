import EmailSentSystemSchema from '@schemas/EmailSentSystem';

export default [
  {
    path: EmailSentSystemSchema.routes.list.path,
    name: EmailSentSystemSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Configurações' },
        { name: 'Emails Enviados' },
      ],
    },
    component: () => import(/* webpackChunkName: "list-email-sent-system" */'./ListEmailSentSystem'),
  },
];
