import * as AppUtils from '@plugins/app-utils';
import Config from '@src/core/config';
import DynamicService from '@sharedServices/dynamic/DynamicService';
import { validateHour } from '@businesses/ProductCompositionFactory';

const OrderProduction = DynamicService('production', {
  baseURL: Config.API_GATEWAY_PCP,
  formatRequest: (order) => {
    if (order.emission_date && order.emission_time) {
      order.emission_datetime = AppUtils.parseDateTimeToISO8061(AppUtils.parseDate(AppUtils.formatDate(order.emission_date)), order.emission_time);
    }

    if (order.is_enabled_forecast === true
      && order.forecast_date
      && order.forecast_time) {
      order.forecast_datetime = AppUtils.parseDateTimeToISO8061(AppUtils.parseDate(AppUtils.formatDate(order.forecast_date)), order.forecast_time);
    } else {
      delete order.forecast_datetime;
    }

    if (order.is_enabled_start === true
      && order.start_date
      && order.start_time) {
      order.start_datetime = AppUtils.parseDateTimeToISO8061(AppUtils.parseDate(AppUtils.formatDate(order.start_date)), order.start_time);
    } else {
      delete order.start_datetime;
    }

    if (order.is_enabled_end === true
      && order.end_date
      && order.end_time) {
      order.end_datetime = AppUtils.parseDateTimeToISO8061(AppUtils.parseDate(AppUtils.formatDate(order.end_date)), order.end_time);
    } else {
      delete order.end_datetime;
    }

    order.compositions.map((item) => {
      item.product_composition_id = item.id;

      item.ordered_quantity = (item.ordered_quantity || 0);
      item.suggested_quantity = (item.suggested_quantity || 0);
      item.returned_quantity = (item.returned_quantity || 0);
      item.required_quantity = (item.required_quantity || 0);
      item.lost_quantity = (item.lost_quantity || 0);
    });
    order.procedures.map((item) => {
      item.time_process = AppUtils.convertHourStringToMinutes(item.time_process);
      item.time_machine = AppUtils.convertHourStringToMinutes(item.time_machine);
      item.time_setup = AppUtils.convertHourStringToMinutes(item.time_setup);
      item.time_total = AppUtils.convertHourStringToMinutes(item.time_total);
      item.suggested_time_process = AppUtils.convertHourStringToMinutes(item.suggested_time_process);
      item.suggested_time_machine = AppUtils.convertHourStringToMinutes(item.suggested_time_machine);
      item.suggested_time_setup = AppUtils.convertHourStringToMinutes(item.suggested_time_setup);
      item.suggested_time_total = AppUtils.convertHourStringToMinutes(item.suggested_time_total);
      item.used_time_setup = AppUtils.convertHourStringToMinutes(item.used_time_setup);
      item.used_time_process = AppUtils.convertHourStringToMinutes(item.used_time_process);
      item.used_time_machine = AppUtils.convertHourStringToMinutes(item.used_time_machine);
      item.used_time_total = AppUtils.convertHourStringToMinutes(item.used_time_total);

      // formatted fields
      item.used_time_total_formatted = AppUtils.convertMinutesToHourString(item.used_time_total);
    });
  },
  formatResponse: (order) => {
    order.product_id = order.product_id || (order.product && order.product.id) || null;
    order.stock_id = order.stock_id || (order.stock && order.stock.id) || null;

    order.emission_date = order.emission_datetime ? AppUtils.parseDate(AppUtils.formatDate(order.emission_datetime, false)) : order.emission_date;
    order.emission_date_formatted = order.emission_datetime ? AppUtils.formatDate(order.emission_datetime) : order.emission_date_formatted;
    order.emission_time = order.emission_datetime ? AppUtils.getCurrentHours(order.emission_datetime) : order.emission_time;

    order.forecast_date = order.forecast_datetime ? AppUtils.parseDate(AppUtils.formatDate(order.forecast_datetime, false)) : order.forecast_date;
    order.forecast_date_formatted = order.forecast_datetime ? AppUtils.formatDate(order.forecast_datetime) : order.forecast_date_formatted;
    order.forecast_time = order.forecast_datetime ? AppUtils.getCurrentHours(order.forecast_datetime) : order.forecast_time;

    order.start_date = order.start_datetime ? AppUtils.parseDate(AppUtils.formatDate(order.start_datetime, false)) : order.start_date;
    order.start_date_formatted = order.start_datetime ? AppUtils.formatDate(order.start_datetime) : order.start_date_formatted;
    order.start_time = order.start_datetime ? AppUtils.getCurrentHours(order.start_datetime) : order.start_time;

    order.end_date = order.end_datetime ? AppUtils.parseDate(AppUtils.formatDate(order.end_datetime, false)) : order.end_date;
    order.end_date_formatted = order.end_datetime ? AppUtils.formatDate(order.end_datetime) : order.end_date_formatted;
    order.end_time = order.end_datetime ? AppUtils.getCurrentHours(order.end_datetime) : order.end_time;

    order.status = order.status || 'initial';
    order.status_formatted = AppUtils.getOptionText('orders_production_status', order.status);

    order.produced_quantity = order.ordered_quantity - order.lost_quantity;
    order.compositions = order.compositions || [];

    order.compositions.map((item) => {
      item.product_id = item.product.id;
      item.product_composition_id = item.id;

      item.unity_id = (item.unity && item.unity.id) || null;

      item.purchase_cost_formatted = AppUtils.formatCurrency(item.purchase_cost || 0);
      item.total_purchase_cost_formatted = AppUtils.formatCurrency(item.total_purchase_cost || 0);
      item.sale_value_formatted = AppUtils.formatCurrency(item.sale_value || 0);
      item.total_sale_value_formatted = AppUtils.formatCurrency(item.total_sale_value || 0);

      item.ordered_quantity_formatted = AppUtils.formatNumber(item.ordered_quantity || 0);
      item.suggested_quantity_formatted = AppUtils.formatNumber(item.suggested_quantity || 0);
      item.returned_quantity_formatted = AppUtils.formatNumber(item.returned_quantity || 0);
      item.required_quantity_formatted = AppUtils.formatNumber(item.required_quantity || 0);
      item.lost_quantity_formatted = AppUtils.formatNumber(item.lost_quantity || 0);
      item.returned_quantity_base = item.returned_quantity_base || AppUtils.formatNumber(item.returned_quantity || 0);
      item.required_quantity_base = item.required_quantity_base || AppUtils.formatNumber(item.required_quantity || 0);
      item.lost_quantity_base = item.lost_quantity_base || AppUtils.formatNumber(item.lost_quantity || 0);
      item.total_quantity_formatted = AppUtils.formatNumber(item.total_quantity || 0);

      item.is_fixed_formatted = item.is_fixed ? 'Sim' : 'Não';
    });
    order.procedures = order.procedures || [];
    order.procedures.map((procedure, index) => {
      procedure.step = procedure.step ?? index + 1;
      procedure.time_process = validateHour(procedure.time_process) ? procedure.time_process : AppUtils.convertMinutesToHourString(procedure.time_process);
      procedure.time_machine = validateHour(procedure.time_machine) ? procedure.time_machine : AppUtils.convertMinutesToHourString(procedure.time_machine);
      procedure.time_setup = validateHour(procedure.time_setup) ? procedure.time_setup : AppUtils.convertMinutesToHourString(procedure.time_setup);
      procedure.time_total = validateHour(procedure.time_total) ? procedure.time_total : AppUtils.convertMinutesToHourString(procedure.time_total);
      procedure.time_process_formatted = validateHour(procedure.time_process) ? procedure.time_process : AppUtils.convertMinutesToHourString(procedure.time_process);
      procedure.time_machine_formatted = validateHour(procedure.time_machine) ? procedure.time_machine : AppUtils.convertMinutesToHourString(procedure.time_machine);
      procedure.time_setup_formatted = validateHour(procedure.time_setup) ? procedure.time_setup : AppUtils.convertMinutesToHourString(procedure.time_setup);
      procedure.time_total_formatted = validateHour(procedure.time_total) ? procedure.time_total : AppUtils.convertMinutesToHourString(procedure.time_total);
      procedure.suggested_time_process = validateHour(procedure.suggested_time_process) ? procedure.suggested_time_process : AppUtils.convertMinutesToHourString(procedure.suggested_time_process);
      procedure.suggested_time_process_formatted = validateHour(procedure.suggested_time_process)
        ? procedure.suggested_time_process
        : AppUtils.convertMinutesToHourString(procedure.suggested_time_process);
      procedure.suggested_time_machine = validateHour(procedure.suggested_time_machine) ? procedure.suggested_time_machine : AppUtils.convertMinutesToHourString(procedure.suggested_time_machine);
      procedure.suggested_time_machine_formatted = validateHour(procedure.suggested_time_machine)
        ? procedure.suggested_time_machine
        : AppUtils.convertMinutesToHourString(procedure.suggested_time_machine);
      procedure.suggested_time_setup = validateHour(procedure.suggested_time_setup) ? procedure.suggested_time_setup : AppUtils.convertMinutesToHourString(procedure.suggested_time_setup);
      procedure.suggested_time_setup_formatted = validateHour(procedure.suggested_time_setup) ? procedure.suggested_time_setup : AppUtils.convertMinutesToHourString(procedure.suggested_time_setup);
      procedure.suggested_time_total = validateHour(procedure.suggested_time_total) ? procedure.suggested_time_total : AppUtils.convertMinutesToHourString(procedure.suggested_time_total);
      procedure.suggested_time_total_formatted = validateHour(procedure.suggested_time_total) ? procedure.suggested_time_total : AppUtils.convertMinutesToHourString(procedure.suggested_time_total);
      procedure.used_time_setup = validateHour(procedure.used_time_setup) ? procedure.used_time_setup : AppUtils.convertMinutesToHourString(procedure.used_time_setup);
      procedure.used_time_setup_formatted = validateHour(procedure.used_time_setup) ? procedure.used_time_setup : AppUtils.convertMinutesToHourString(procedure.used_time_setup);
      procedure.used_time_process = validateHour(procedure.used_time_process) ? procedure.used_time_process : AppUtils.convertMinutesToHourString(procedure.used_time_process);
      procedure.used_time_process_formatted = validateHour(procedure.used_time_process) ? procedure.used_time_process : AppUtils.convertMinutesToHourString(procedure.used_time_process);
      procedure.used_time_machine = validateHour(procedure.used_time_machine) ? procedure.used_time_machine : AppUtils.convertMinutesToHourString(procedure.used_time_machine);
      procedure.used_time_machine_formatted = validateHour(procedure.used_time_machine) ? procedure.used_time_machine : AppUtils.convertMinutesToHourString(procedure.used_time_machine);
      procedure.used_time_total = validateHour(procedure.used_time_total) ? procedure.used_time_total : AppUtils.convertMinutesToHourString(procedure.used_time_total);
      procedure.used_time_total_formatted = validateHour(procedure.used_time_total) ? procedure.used_time_total : AppUtils.convertMinutesToHourString(procedure.used_time_total);
    });
  },
});

const OrderProductionCommands = ($axios) => ({
  async getQuantity(payload) {
    const res = await $axios(`${Config.API_GATEWAY_PCP}/production/${payload.id}/stock_item/${payload.stock_id}.json`);
    return res;
  },
});

export {
  OrderProduction,
  OrderProductionCommands,
};
export default {
  OrderProduction,
  OrderProductionCommands,
};
