import vuetify from '@plugins/vuetify';
import ReportDialog from './pages/main/ReportDialog';

export default {
  install(Vue) {
    const ReportDialogConstructor = Vue.extend(ReportDialog);
    let reportViewerInstance = null;
    Vue.prototype.$report = {};
    Vue.prototype.$report.open = (config) => new Promise((resolve) => {
      if (reportViewerInstance) return;
      reportViewerInstance = new ReportDialogConstructor({
        vuetify,
        propsData: {
          title: config.title,
          icon: config.icon,
          reportId: config.reportId,
          reportData: config.reportData,
          editable: config.editable,
        },
      });
      reportViewerInstance.$mount();
      reportViewerInstance.$on('close', () => {
        reportViewerInstance.$destroy();
        reportViewerInstance = null;
        resolve();
      });

      reportViewerInstance.open = true;
      document.body.appendChild(reportViewerInstance.$el);
    });

    Vue.prototype.$report.new = (config) => new Promise((resolve) => {
      if (reportViewerInstance) return;
      reportViewerInstance = new ReportDialogConstructor({
        vuetify,
        propsData: {
          reportData: config.reportData,
          schema: config.schema,
          orientation: config.orientation,
          newReport: true,
        },
      });
      reportViewerInstance.$mount();
      reportViewerInstance.$on('close', () => {
        reportViewerInstance.$destroy();
        reportViewerInstance = null;
        resolve();
      });

      reportViewerInstance.open = true;
      document.body.appendChild(reportViewerInstance.$el);
    });
  },
};
