import AlltypesSchema from '@schemas/Alltypes';

export default [
  {
    path: AlltypesSchema.routes.productCategories.list.path,
    name: AlltypesSchema.routes.productCategories.list.name,
    meta: AlltypesSchema.routes.productCategories.list.meta,
    component: () => import(/* webpackChunkName: 'list-product-categories' */ `${AlltypesSchema.routes.productCategories.list.component}`),
  },
  {
    path: AlltypesSchema.routes.productCategories.create.path,
    name: AlltypesSchema.routes.productCategories.create.name,
    meta: AlltypesSchema.routes.productCategories.create.meta,
    component: () => import(/* webpackChunkName: 'create-product-categories' */ `${AlltypesSchema.routes.productCategories.create.component}`),
  },
  {
    path: AlltypesSchema.routes.productCategories.edit.path,
    name: AlltypesSchema.routes.productCategories.edit.name,
    meta: AlltypesSchema.routes.productCategories.edit.meta,
    component: () => import(/* webpackChunkName: 'edit-product-categories' */ `${AlltypesSchema.routes.productCategories.edit.component}`),
  },
];
