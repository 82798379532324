import Magis5Schema from '@schemas/Magis5.js';

const ListMagis5 = () => import(/* webpackChunkName: "list-magis-5" */'./ListMagis5');

export default [
  {
    path: Magis5Schema.routes.list.path,
    name: Magis5Schema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Venda Mais' },
        { name: 'Integrações' },
        { name: 'Magis5' },
      ],
    },
    component: ListMagis5,
  },
];
