import Vue from 'vue';
import _ from 'lodash';
import { applyGlobalDiscount } from './Common/ApplyGlobalDiscount';
import { calculateOrderPurchaseRealTime } from './Common/CalculateRealTime';
import { calculateTotals } from './Common/CalculateTotals';
import { createHintForRegisterField } from './Common/createHintForRegisterField';
import { setTaxesValues } from './Common/SetTaxesValues ';

import { setOrderPurchasesItems, resetOrderPurchasesItems } from '@schemas/DevolutionPurchase';

import { DefaultScreenEvents } from './OrderSalePurchase/DefaultScreenEvents';
import { DefaultFieldEvents } from './OrderSalePurchase/DefaultFieldEvents';

const resetFields = (formValue) => {
  Vue.set(formValue, 'emission_date', '');
  Vue.set(formValue, 'is_costumer', '');
  Vue.set(formValue, 'presence_indicator', '');
  Vue.set(formValue, 'purchase_return', '');
  Vue.set(formValue, 'purchase_order', '');
  Vue.set(formValue, 'note', '');
  Vue.set(formValue, 'delivery_date', '');
  Vue.set(formValue, 'payment_method_id', '');
  Vue.set(formValue, 'type_installment_id', '');
  Vue.set(formValue, 'stock_id', '');
  Vue.set(formValue, 'freight', '');
  Vue.set(formValue, 'freight_value', '');
  Vue.set(formValue, 'expense_value', '');
  Vue.set(formValue, 'use_freight', '');
  Vue.set(formValue, 'nature_operation_id', '');
  Vue.set(formValue, 'items', '');
  Vue.set(formValue, 'taxes', '');
  Vue.set(formValue, 'transporter', '');
};

const disableFields = (formValue) => {
  formValue.emission_date.disabled = true;
  formValue.is_costumer.disabled = true;
  formValue.presence_indicator.disabled = true;
  formValue.purchase_return.disabled = true;
  formValue.purchase_order.disabled = true;
  formValue.note.disabled = true;
  formValue.delivery_date.disabled = true;
  formValue.payment_method_id.disabled = true;
  formValue.type_installment_id.disabled = true;
  formValue.stock_id.disabled = true;
  formValue.freight.disabled = true;
  formValue.freight_value.disabled = true;
  formValue.expense_value.disabled = true;
  formValue.use_freight.disabled = true;
  formValue.nature_operation_id.disabled = true;
};

const setOrderFields = async (formValue) => {
  await Vue.prototype.$api.OrderPurchasesCommands.getOrdersDeliveredByVendorId(formValue.vendor_id).then((orders) => {
    if (orders && orders.data.length > 0) {
      orders.data.forEach((element) => {
        setOrderPurchasesItems({
          text: `${element.to_s}`,
          value: element.id,
        });
      });
    } else {
      resetFields(formValue);
      disableFields(formValue);
      return Vue.prototype.$notifyError('Não foram encontrados pedidos de compras entregues desse fornecedor');
    }
  });
};

export default {
  screenEvents: {
    ...DefaultScreenEvents,
    async beforeUpdate(formValue) {
      if (!formValue.vendor_id) return;
      const vendorHint = await createHintForRegisterField(formValue.vendor_id);
      setOrderFields(formValue);

      return { fields: [{ label: 'Fornecedor', hint: vendorHint }] };
    },
  },
  fieldEvents: {
    vendor_id: {
      async change(formValue) {
        const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
        formValue.vendor = vendor;
        formValue.vendor_id = vendor.id;
        Vue.set(formValue, 'vendor', vendor);
        Vue.set(formValue, 'vendor_id', vendor.id);

        if (vendor.addresses.length === 0) {
          Vue.prototype.$notifyError('Fornecedor não possui endereço', {
            action: {
              text: 'Clique aqui para cadastrar',
              push: {
                name: 'entries-registers-edit',
                params: {
                  id: vendor.id,
                },
              },
              class: 'custom-message',
            },
            duration: 6000,
          });
        } else {
          const apportionment = true;
          calculateTotals(formValue);

          const hint = await createHintForRegisterField(vendor.id, vendor);

          const shipping_company_id = _.get(vendor, 'preference.shipping_company_id');
          if (shipping_company_id) formValue.shipping_company_id = shipping_company_id;

          if (formValue.vendor_id && formValue.nature_operation_id) {
            formValue.vendor = {
              ...formValue.vendor,
              taxpayer_type: vendor.taxpayer_type,
            };
            const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
            setTaxesValues(formValue, data, apportionment);
          }

          resetOrderPurchasesItems();

          setOrderFields(formValue);

          return { fieldAttributes: { hint } };
        }
      },
    },
    is_costumer: {
      change: async (formValue) => {
        if (formValue.vendor_id) {
          const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
          const apportionment = true;
          calculateTotals(formValue);
          formValue.vendor = {
            ...formValue.vendor,
            taxpayer_type: vendor.taxpayer_type,
          };
          const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
          setTaxesValues(formValue, data, apportionment);
        }
      },
    },
    order_purchase_id: {
      change: async (formValue) => {
        if (formValue.order_purchase_id) {
          const { data } = await Vue.prototype.$api.OrderPurchases.show(formValue.order_purchase_id);
          const order = data;

          formValue.purchase_order = order.purchase_order;
          formValue.note = order.note;
          formValue.note_formatted = order.note_formatted;
          formValue.is_costumer = order.is_costumer;
          formValue.presence_indicator = order.presence_indicator;
          formValue.presence_indicator_formatted = order.presence_indicator_formatted;
          formValue.payment_method_id = order.payment_method_id;
          formValue.type_installment = order.type_installment;
          formValue.type_installment_id = order.type_installment_id;
          formValue.stock = order.stock;
          formValue.stock_id = order.stock_id;
          formValue.total_tax_aproximates = order.total_tax_aproximates;
          formValue.total_ordered_formatted = order.total_ordered_formatted;
          formValue.freight = order.freight;
          formValue.freight_formatted = order.freight_formatted;
          formValue.freight_value = order.freight_value;
          formValue.expense_value = order.expense_value;
          formValue.use_freight = order.use_freight;
          formValue.items = order.items;
          formValue.taxes = order.taxes;
          formValue.transporter = order.transporter;
        }
      },
    },
    nature_operation_id: {
      change: async (formValue) => {
        if (formValue.vendor_id) {
          const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);

          formValue.items = formValue.items || [];
          formValue.items.forEach((item) => {
            item.nature_operation_id = formValue.nature_operation_id;
          });
          calculateTotals(formValue);
          if (formValue.vendor_id && formValue.items.length > 0) {
            const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
            setTaxesValues(formValue, data);
            if (data.totalizers?.ipi) {
              formValue.total_ordered = formValue.total_products + data.totalizers.ipi;
            }
          }
        }
      },
    },
    items: {
      beforeCreate: async (formValue, newItem) => {
        if (!formValue.vendor_id) return 'Não é possível adicionar ítens sem selecionar um fornecedor';
        if (!formValue.stock_id) return 'Não é possível adicionar ítens sem selecionar o estoque';
        if (!formValue.nature_operation_id) return 'Não é possível adicionar ítens sem selecionar a natureza de operação';
        if (formValue.vendor?.addresses.length === 0) return 'Para adicionar ítens na devolução de compra precisa ter endereço UF no fornecedor selecionado';

        newItem.stock_id = formValue.stock_id;
        newItem.nature_operation_id = formValue.nature_operation_id;
        newItem.order_number = formValue.order;
        newItem.vendor_id = formValue.vendor_id;
      },
      change: async (formValue) => {
        const apportionment = true;
        calculateTotals(formValue);
        if (formValue.vendor_id) {
          const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
          const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
          setTaxesValues(formValue, data, apportionment);
        }
        Vue.set(formValue, 'items', formValue.items);
      },
    },
    use_freight: {
      change: async (formValue) => {
        const apportionment = true;
        if (formValue.use_freight === false) {
          calculateTotals(formValue, true);
        } else {
          calculateTotals(formValue);
        }
        if (formValue.vendor_id) {
          const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
          const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
          setTaxesValues(formValue, data, apportionment);
        }
      },
    },
    expense_value: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          const apportionment = true;
          calculateTotals(formValue);
          if (formValue.vendor_id) {
            const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
            const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
            setTaxesValues(formValue, data, apportionment);
          }
        }
      },
    },
    freight_value: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          const apportionment = true;
          if (formValue.use_freight === true) {
            calculateTotals(formValue);
          } else {
            calculateTotals(formValue, true);
          }
          if (formValue.vendor_id) {
            const data = await calculateOrderPurchaseRealTime(formValue, 'OrderPurchasesTaxes', formValue.vendor_id);
            setTaxesValues(formValue, data, apportionment);
          }
        }
      },
    },
    observations: {
      beforeCreate: async (formValue) => {
        if (!formValue.buyer_id) return 'Não é possível adicionar observações sem selecionar um cliente';
      },
    },
    ...DefaultFieldEvents,
  },
  applyGlobalDiscount,
};
