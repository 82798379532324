import { ICON_DESCRIPTION, ICON_KEY_LINK } from '@constants/icons';
import { OPTIONS_BTG_ACCOUNT_CURRENCY, OPTIONS_BTG_ACCOUNT_TRANSACTION_OPERATION } from '@constants/options';

export default {
  // service: 'IntegrationsBtg',
  icon: ICON_KEY_LINK,
  noCreate: true,
  noCheck: true,
  noEdit: true,
  noDelete: true,
  disabled: true,
  hideSaveButton: true,
  useExternalItems: true,
  routes: {
    list: {
      name: 'integrations-btg-statements',
    },
  },
  titles: {
    item: 'Transação Conta BTG',
    items: 'Transações Contas BTG',
    list: 'Listar Transações',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'ID da Transação',
      name: 'transaction_id',
      type: 'text',
      noFilter: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Data',
      name: 'date',
      formattedName: 'date_formatted',
      type: 'text',
      noFilter: true,
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Data Início',
      name: 'start_date',
      formattedName: 'start_date_formatted',
      type: 'date',
      list: false,
    },
    {
      label: 'Data Fim',
      name: 'end_date',
      formattedName: 'end_date_formatted',
      type: 'date',
      list: false,
    },
    // {
    //   label: 'Tipo',
    //   name: 'type',
    //   formattedName: 'type_formatted',
    //   type: 'select',
    //   noFilter: true,
    //   component: 'dynamic-select',
    //   items: OPTIONS_BTG_ACCOUNT_TRANSACTION_TYPE,
    //   options_key: 'btg_account_transaction_type',
    //   required: true,
    //   list: true,
    //   md: 3,
    // },
    {
      label: 'Operação',
      name: 'credit_debit_indicator',
      formattedName: 'credit_debit_indicator_formatted',
      type: 'select',
      noFilter: true,
      component: 'dynamic-select',
      items: OPTIONS_BTG_ACCOUNT_TRANSACTION_OPERATION,
      options_key: 'btg_account_transaction_operation',
      required: true,
      list: true,
      md: 3,
    },
    {
      label: 'Descrição',
      name: 'description',
      type: 'text',
      noFilter: true,
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Valor',
      name: 'amount',
      formattedName: 'amount_formatted',
      type: 'text',
      noFilter: true,
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Moeda',
      name: 'currency',
      formattedName: 'currency_formatted',
      type: 'select',
      noFilter: true,
      component: 'dynamic-select',
      items: OPTIONS_BTG_ACCOUNT_CURRENCY,
      options_key: 'btg_account_currency',
      required: true,
      md: 3,
    },
    {
      label: 'Tags',
      name: 'tags',
      type: 'text',
      noFilter: true,
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
  ],
  actions: [
    {
      type: 'route',
      icon: ICON_DESCRIPTION,
      color: 'secondary',
      route: 'integrations-btg-detailaccountstatements',
      target: '_blank',
      ariaLabel: 'Detalhes',
    },
  ],
  // tabs: [
  //   {
  //     icon: ICON_STOCK,
  //     title: 'Itens no Estoque',
  //     tabContent: TabStock,
  //   },
  // ],
};
