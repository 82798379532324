import { ICON_TABLES_ACCOUNTINGACCOUNT_TITLE } from '@constants/icons';

export default {
  service: '',
  icon: ICON_TABLES_ACCOUNTINGACCOUNT_TITLE,
  routes: {
    list: {
      path: '/tables/accountingaccount/list',
      name: 'tables-accountingaccounts-list',
    },
  },
  titles: {
    item: 'Conta Contábil',
    items: 'Contas Contábeis',
  },
};
