import { BtgIntegrationBillets, BtgIntegrationCommands } from './pages/btg/services';
import { DasGuides, DasGuidesCommands } from './pages/das/services';

export { default as routes } from './routes';

export const globalServices = {
  BtgIntegrationBillets,
  BtgIntegrationCommands,
  DasGuides,
  DasGuidesCommands,
};
