import EmailLayoutSchema from '@schemas/EmailLayout';

export default [
  {
    path: EmailLayoutSchema.routes.list.path,
    name: EmailLayoutSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Planejamento' },
      ],
    },
    component: () => import(/* webpackChunkName: "list-email-layout" */'./ListEmailLayout'),
  },
  {
    path: EmailLayoutSchema.routes.edit.path,
    name: EmailLayoutSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Planejamento', route: EmailLayoutSchema.routes.list.name },
        { name: 'Editar Layout de Email' },
      ],
    },
    component: () => import(/* webpackChunkName: "detail-email-layout" */'./DetailEmailLayout'),
  },
];
