import {
  ICON_DESCRIPTION,
  ICON_MEASURING_UNIT,
  ICON_TAXES,
  ICON_TRUCK,
  ICON_SHOPPING,
} from '@constants/icons';
import { OPTIONS_PRESENCE_INDICATOR, OPTIONS_FREIGHT, OPTIONS_ORDERSPURCHASESALE_STATUS } from '@constants/options';
import DevolutionSalesBusiness from '@businesses/DevolutionSales';
import DevolutionSalesItem from './DevolutionSalesItem';
import DevolutionSalesItemTax from './DevolutionSalesItemTax';
import DevolutionSalesTransporter from './DevolutionSalesTransporter';

import TabDevolutionSales from '@modules/supply/pages/devolutionSales/components/TabDevolutionSales';
import TabDevolutionSalesItens from '@modules/supply/pages/devolutionSales/components/TabDevolutionSalesItens';
import TabDevolutionSalesItensTax from '@modules/supply/pages/devolutionSales/components/TabDevolutionSalesItensTax';
import TabDevolutionSalesTransporter from '@modules/supply/pages/devolutionSales/components/TabDevolutionSalesTransporter';

const orderSalesItems = [];

export function getOrderSalesItems() { return orderSalesItems; }
export function setOrderSalesItems(param) { orderSalesItems.push(param); }
export function resetOrderSalesItems() { orderSalesItems.splice(0); }

export default {
  service: 'DevolutionSales',
  business: DevolutionSalesBusiness,
  icon: ICON_SHOPPING,
  routes: {
    list: { path: '/supply/sales/devolutions', name: 'supply-salesdevolutions-list' },
    create: { path: '/supply/sales/devolutions/create', name: 'supply-salesdevolutions-create' },
    edit: { path: '/supply/sales/devolutions/edit/:id', name: 'supply-salesdevolutions-edit' },
  },
  titles: {
    item: 'Devolução de Vendas',
    items: 'Devoluções de Vendas',
    edit: 'Editar Devolução de Vendas',
    create: 'Criar Devolução de Vendas',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Devolução de vendas excluida com sucesso!',
    successEdit: 'Devolução de vendas alterada com sucesso!',
    successCreate: 'Devolução de vendas criada com sucesso!',
    errorSave: 'Erro ao salvar devolução de vendas!',
    tryEditCanceled: 'Não é possível editar uma devolução de vendas cancelada.',
    confirmCancel: 'Deseja cancelar a devolução de vendas?',
    successCancel: 'Devolução de vendas cancelada com sucesso',
    errorCancel: 'Erro ao cancelar vendas de vendas',
  },
  filterUrl: 'sales_devolutions/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  canClone: false,
  statusSettings: {
    options: OPTIONS_ORDERSPURCHASESALE_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  formName: 'salesdevolutions',
  fields: [
    {
      label: 'INFORMAÇÕES DA DEVOLUÇÂO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options: 'orders_purchase_sale_status',
      list: true,
    },
    {
      label: 'Cliente',
      name: 'buyer_id',
      formattedName: 'buyer.to_s',
      type: 'number',
      hint: '',
      list: true,
      required: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_register_array: 'client',
          query: ['id', 'name', 'cnpj_cpf', 'addresses', ['id', 'to_s'], 'preference', ['salesman_id', 'shipping_company_id']],
        }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      withDetails: true,
      typeDetails: 'client',
      md: 8,
      disabled: (formValue) => {
        if (formValue?.id) {
          return true;
        }
        return false;
      },
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Pedido de Venda',
      name: 'order_sale_id',
      hint: 'Escolha um cliente para carregar os pedidos',
      formattedName: 'sale_order',
      type: 'string',
      component: 'dynamic-select',
      items: orderSalesItems,
      disabled: (formValue) => {
        if (formValue?.id) {
          return true;
        }
        if (formValue?.buyer_id) {
          return false;
        }
        return false;
      },
      md: 2,
      required: true,
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Devolução',
      name: 'sale_devolution',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 2,
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Vendedor',
      name: 'salesman_id',
      formattedName: 'salesman.to_s',
      type: 'number',
      list: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{ type_register_array: 'salesman', query: ['id', 'name'] }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      md: 8,
      tabGroup: 'infoDevolution',
    },
    {
      label: 'NFe',
      name: 'note',
      formattedName: 'note_formatted',
      list: true,
      type: 'text',
      component: 'dynamic-text-field',
      readOnly: true,
      md: 2,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Data Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      list: true,
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Consumidor Final',
      name: 'is_costumer',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Indicador de Presença',
      name: 'presence_indicator',
      formattedName: 'presence_indicator_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_PRESENCE_INDICATOR,
      md: 7,
      required: true,
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Data Entrega',
      name: 'delivery_date',
      formattedName: 'delivery_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      md: 2,
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Forma de Pagamento',
      name: 'payment_method_id',
      formattedName: 'payment_method.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-paymentmethod-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['payment_method'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 4,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Parcelamento',
      name: 'type_installment_id',
      formattedName: 'type_installment.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-typeinstallment-create',
      dataSource: {
        service: 'TypeInstallment',
        method: 'getByFilter',
        props: [{ inactive_eq: false }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 4,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      formattedName: 'stock.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 4,
      tabGroup: 'infoDevolution',
    },
    {
      label: 'Total de Impostos Aproximados',
      formattedName: 'total_tax_aproximates',
      name: 'total_tax_aproximates',
      type: 'number',
    },
    {
      label: 'Valor Total',
      formattedName: 'total_ordered_formatted',
      type: 'number',
      list: true,
      tabGroup: 'infoDevolution',
    },
    {
      label: 'INFORMAÇÕES DE FRETE',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'infoFreight',
    },
    {
      label: 'Frete',
      name: 'freight',
      formattedName: 'freight_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_FREIGHT,
      required: true,
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoFreight',
    },
    {
      label: 'Valor do Frete',
      name: 'freight_value',
      formattedName: 'freight_value_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoFreight',
    },
    {
      label: 'Despesas',
      name: 'expense_value',
      formattedName: 'expense_value_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoFreight',
    },
    {
      label: 'Incluir Frete no Total',
      name: 'use_freight',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoFreight',
    },
    {
      label: 'TRIBUTAÇÕES DA DEVOLUÇÃO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      formattedName: 'nature_operation.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_nature_in: ['devolution_sale'],
          query: ['id', 'name'],
        }],
      },
      itemText: 'name',
      itemValue: 'id',
      list: true,
      required: true,
      md: 12,
      tabGroup: 'infoDevolution',
    },
  ],
  childSchemas: [
    {
      label: 'Itens do Devolução de Vendas',
      name: 'items',
      schema: DevolutionSalesItem,
      noCreate: true,
    },
    {
      label: 'Conferência de Impostos',
      name: 'taxes',
      noCreate: true,
      noEdit: true,
      noDelete: true,
      noActions: true,
      schema: DevolutionSalesItemTax,
      hideDefaultHeader: true,
      customHeader: {
        customClass: 'custom-child-header-order-sales',
        ungroupedHeadersFront: 2,
        groupHeaders: [
          { text: 'SIMPLES NACIONAL', colspan: 3, class: 'order-sales-header' },
          { text: 'ICMS', colspan: 3, class: 'order-sales-header' },
          { text: 'ICMS ST', colspan: 3, class: 'order-sales-header' },
          { text: 'IPI', colspan: 3, class: 'order-sales-header' },
          { text: 'PIS', colspan: 3, class: 'order-sales-header' },
          { text: 'COFINS', colspan: 3, class: 'order-sales-header' },
          { text: 'ICMS CONSUMIDOR FINAL', colspan: 3, class: 'order-sales-header' },
          { text: 'VALOR APROXIMADOS', colspan: 3, class: 'order-sales-header' },
        ],
      },
    },
    {
      label: 'Transportadora',
      name: 'transporter',
      schema: DevolutionSalesTransporter,
      hasOne: true,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabDevolutionSales,
    },
    {
      icon: ICON_MEASURING_UNIT,
      title: 'Itens do Devolução de Compra',
      tabContent: TabDevolutionSalesItens,
    },
    {
      icon: ICON_TAXES,
      title: 'Conferência de Impostos',
      tabContent: TabDevolutionSalesItensTax,
    },
    {
      icon: ICON_TRUCK,
      title: 'Transportadora',
      tabContent: TabDevolutionSalesTransporter,
    },
  ],
};
