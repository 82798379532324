import {
  OrderProduction,
  OrderProductionCommands,
} from './pages/orderproduction/services';

export { default as routes } from './routes';

export const globalServices = {
  OrderProduction,
  OrderProductionCommands,
};
