import Vue from 'vue';
import _ from 'lodash';
import { applyGlobalDiscount } from './Common/ApplyGlobalDiscount';
import { calculateOrderSaleRealTime } from './Common/CalculateRealTime';
import { calculateTotals } from './Common/CalculateTotals';
import { createHintForRegisterField } from './Common/createHintForRegisterField';
import { setTaxesValues } from './Common/SetTaxesValues ';

import { setOrderSaleItems, resetOrderSaleItems } from '@schemas/PartialSale';

import { DefaultScreenEvents } from './OrderSalePurchase/DefaultScreenEvents';
import { DefaultFieldEvents } from './OrderSalePurchase/DefaultFieldEvents';

const resetFields = (formValue) => {
  Vue.set(formValue, 'emission_date', '');
  Vue.set(formValue, 'is_costumer', '');
  Vue.set(formValue, 'presence_indicator', '');
  Vue.set(formValue, 'sale_return', '');
  Vue.set(formValue, 'sale_order', '');
  Vue.set(formValue, 'note', '');
  Vue.set(formValue, 'delivery_date', '');
  Vue.set(formValue, 'payment_method_id', '');
  Vue.set(formValue, 'type_installment_id', '');
  Vue.set(formValue, 'stock_id', '');
  Vue.set(formValue, 'freight', '');
  Vue.set(formValue, 'freight_value', '');
  Vue.set(formValue, 'expense_value', '');
  Vue.set(formValue, 'use_freight', '');
  Vue.set(formValue, 'nature_operation_id', '');
  Vue.set(formValue, 'items', '');
  Vue.set(formValue, 'taxes', '');
  Vue.set(formValue, 'transporter', '');
};

const disableFields = (formValue) => {
  formValue.emission_date.disabled = true;
  formValue.is_costumer.disabled = true;
  formValue.presence_indicator.disabled = true;
  formValue.sale_return.disabled = true;
  formValue.sale_order.disabled = true;
  formValue.note.disabled = true;
  formValue.delivery_date.disabled = true;
  formValue.payment_method_id.disabled = true;
  formValue.type_installment_id.disabled = true;
  formValue.stock_id.disabled = true;
  formValue.freight.disabled = true;
  formValue.freight_value.disabled = true;
  formValue.expense_value.disabled = true;
  formValue.use_freight.disabled = true;
  formValue.nature_operation_id.disabled = true;
};

const setOrderFields = async (formValue) => {
  await Vue.prototype.$api.PartialSalesCommands.getOrdersPendingByBuyerId(formValue.buyer_id).then((orders) => {
    if (orders && orders.data.length > 0) {
      orders.data.forEach((element) => {
        setOrderSaleItems({
          text: `${element.to_s}`,
          value: element.id,
        });
      });
    } else {
      resetFields(formValue);
      disableFields(formValue);
      return Vue.prototype.$notifyError('Não foram encontrados pedidos de compras entregues desse cliente');
    }
  });
};

export default {
  screenEvents: {
    ...DefaultScreenEvents,
    async beforeUpdate(formValue) {
      if (!formValue.buyer_id) return;
      const buyerHint = await createHintForRegisterField(formValue.buyer_id);
      setOrderFields(formValue);

      return { fields: [{ label: 'Cliente', hint: buyerHint }] };
    },
  },
  fieldEvents: {
    buyer_id: {
      async change(formValue) {
        const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
        formValue.buyer = buyer;
        formValue.buyer_id = buyer.id;
        Vue.set(formValue, 'buyer', buyer);
        Vue.set(formValue, 'buyer_id', buyer.id);
        if (buyer.addresses.length === 0) {
          Vue.prototype.$notifyError('Cliente não possui endereço', {
            action: {
              text: 'Clique aqui para cadastrar',
              push: {
                name: 'entries-registers-edit',
                params: {
                  id: buyer.id,
                },
              },
              class: 'custom-message',
            },
            duration: 6000,
          });
        } else {
          const apportionment = true;
          const hint = await createHintForRegisterField(buyer.id, buyer);
          const salesmanId = _.get(buyer, 'preference.salesman_id');
          if (salesmanId) {
            const salesman = await Vue.prototype.$api.Registers.show(salesmanId);
            Vue.set(formValue, 'salesman_id', salesmanId);
            Vue.set(formValue, 'salesman', salesman);
          }
          const shipping_company_id = _.get(buyer, 'preference.shipping_company_id');
          if (shipping_company_id) formValue.shipping_company_id = shipping_company_id;

          if (formValue.buyer_id && formValue.nature_operation_id) {
            formValue.buyer = {
              ...formValue.buyer,
              taxpayer_type: buyer.taxpayer_type,
            };
            const data = await calculateOrderSaleRealTime(formValue.items, buyer, formValue.is_costumer);
            setTaxesValues(formValue, data, apportionment);
          }
          resetOrderSaleItems();
          setOrderFields(formValue);
          return { fieldAttributes: { hint } };
        }
      },
    },
    is_costumer: {
      change: async (formValue) => {
        if (formValue.buyer_id) {
          const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
          const apportionment = true;
          formValue.buyer = {
            ...formValue.buyer,
            taxpayer_type: buyer.taxpayer_type,
          };
          const data = await calculateOrderSaleRealTime(formValue.items, buyer, formValue.is_costumer);
          setTaxesValues(formValue, data, apportionment);
        }
      },
    },
    order_sale_id: {
      change: async (formValue) => {
        if (formValue.order_sale_id) {
          const { data } = await Vue.prototype.$api.OrderSales.show(formValue.order_sale_id);
          const order = data;

          formValue.sale_order = order.sale_order;
          formValue.note = order.note;
          formValue.note_formatted = order.note_formatted;
          formValue.is_costumer = order.is_costumer;
          formValue.presence_indicator = order.presence_indicator;
          formValue.presence_indicator_formatted = order.presence_indicator_formatted;
          formValue.payment_method_id = order.payment_method_id;
          formValue.type_installment = order.type_installment;
          formValue.type_installment_id = order.type_installment_id;
          formValue.stock = order.stock;
          formValue.stock_id = order.stock_id;
          formValue.total_tax_aproximates = order.total_tax_aproximates;
          formValue.total_ordered_formatted = order.total_ordered_formatted;
          formValue.freight = order.freight;
          formValue.freight_formatted = order.freight_formatted;
          formValue.freight_value = order.freight_value;
          formValue.expense_value = order.expense_value;
          formValue.use_freight = order.use_freight;
          formValue.nature_operation = order.nature_operation;
          formValue.nature_operation_id = order.nature_operation_id;
          formValue.items = order.items;
          formValue.taxes = order.taxes;
          formValue.transporter = order.transporter;
        }
      },
    },
    nature_operation_id: {
      change: async (formValue) => {
        if (formValue.buyer_id) {
          const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);

          formValue.items = formValue.items || [];
          formValue.items.forEach((item) => {
            item.nature_operation_id = formValue.nature_operation_id;
          });
          if (formValue.buyer_id && formValue.items.length > 0) {
            const data = await calculateOrderSaleRealTime(formValue.items, buyer, formValue.is_costumer);
            setTaxesValues(formValue, data);
            if (data.totalizers?.ipi) {
              formValue.total_ordered = formValue.total_products + data.totalizers.ipi;
            }
          }
        }
      },
    },
    items: {
      beforeCreate: async (formValue, newItem) => {
        if (!formValue.buyer_id) return 'Não é possível adicionar ítens sem selecionar um cliente';
        if (!formValue.nature_operation_id) return 'Não é possível adicionar ítens sem selecionar a natureza de operação';
        if (!formValue.stock_id) return 'Não é possível adicionar ítens sem selecionar o estoque';
        if (formValue?.buyer?.addresses?.length === 0) return 'Para adicionar ítens na venda precisa ter endereço UF no cliente selecionado';

        newItem.stock_id = formValue.stock_id;
        newItem.nature_operation_id = formValue.nature_operation_id;
        newItem.order_number = formValue.order;
        newItem.buyer_id = formValue.buyer_id;
      },
      change: async (formValue) => {
        const apportionment = true;
        if (formValue.buyer_id) {
          const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
          const data = await calculateOrderSaleRealTime(formValue.items, buyer, formValue.is_costumer);
          setTaxesValues(formValue, data, apportionment);
        }
        calculateTotals(formValue);
        Vue.set(formValue, 'items', formValue.items);
      },
    },
    use_freight: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          const apportionment = true;
          if (formValue.buyer_id) {
            const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
            const data = await calculateOrderSaleRealTime(formValue.items, buyer, formValue.is_costumer);
            setTaxesValues(formValue, data, apportionment);
          }
          if (formValue.use_freight === false) {
            calculateTotals(formValue, true);
          } else {
            calculateTotals(formValue);
          }
        }
      },
    },
    expense_value: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          const apportionment = true;
          if (formValue.buyer_id) {
            const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
            const data = await calculateOrderSaleRealTime(formValue.items, buyer, formValue.is_costumer);
            setTaxesValues(formValue, data, apportionment);
          }
          calculateTotals(formValue);
        }
      },
    },
    freight_value: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          const apportionment = true;
          if (formValue.use_freight === false) {
            calculateTotals(formValue, true);
          } else {
            calculateTotals(formValue);
          }
          if (formValue.buyer_id) {
            const data = await calculateOrderSaleRealTime(formValue, 'OrderSalesTaxes', formValue.buyer_id);
            setTaxesValues(formValue, data, apportionment);
          }
        }
      },
    },
    observations: {
      beforeCreate: async (formValue) => {
        if (!formValue.buyer_id) return 'Não é possível adicionar observações sem selecionar um cliente';
      },
    },
    ...DefaultFieldEvents,
  },
  applyGlobalDiscount,
};
