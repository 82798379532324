import * as AppUtils from '@plugins/app-utils';

const endpoint = 'banks';

export default ($axios) => ({
  /** get all objects
   *  @param {Number} bankId Bank Id
   */
  async index(payload) {
    const { bankId } = payload;
    let responseData = {};
    await $axios.get(`${endpoint}/${bankId}/bank_launches.json`)
      .then(
        (response) => {
          responseData = this.formatResponseData(response.data);
        },
      );

    return responseData;
  },

  async indexPaginable(payload) {
    const { bankId, optionsPaginable } = payload;
    let data = {};
    let headers = {};
    let paginator = {};

    const payloadPaginable = `?page=${optionsPaginable.page}&per_page=${optionsPaginable.per_page}`;

    await $axios.get(`${endpoint}/${bankId}/bank_launches.json${payloadPaginable}`).then((response) => {
      headers = response.headers;

      paginator = {
        page: response.data?.page ?? parseInt(headers['icertusweb-current-page']),
        per_page: response.data?.per_page ?? parseInt(headers['icertusweb-items-per-page']),
        total_items: response.data?.total_items ?? parseInt(headers['icertusweb-total-items']),
        total_pages: response.data?.total_pages ?? parseInt(headers['icertusweb-total-pages']),
      };

      data = this.formatResponseData(response.data);
    });
    return { data, headers, paginator };
  },

  /** get one object
   *  @param {Number} bankId Bank Id
   *  @param {Number} launchId Launch Id
   */
  show(payload) {
    const { bankId, launchId } = payload;
    return $axios.get(`${endpoint}/${bankId}/bank_launches/${launchId}.json`);
  },
  /** create one object
   *  @param {Number} bankId Bank Id
   *  @param {Object} data Form Data
   */
  create(payload) {
    const { bankId, data } = payload;
    const parsedData = this.formatRequestData(data);
    return $axios.post(`${endpoint}/${bankId}/bank_launches.json`, parsedData);
  },
  /** update one object
   *  @param {Number} bankId Bank Id
   *  @param {Number} launchId Launch Id
   *  @param {Object} data Form Data
   */
  update(payload) {
    const { bankId, launchId, data } = payload;
    const parsedData = this.formatRequestData(data);
    return $axios.put(`${endpoint}/${bankId}/bank_launches/${launchId}.json`, parsedData);
  },
  /** delete one object
   *  @param {Number} bankId Bank Id
   *  @param {Number} launchId Launch Id
   */
  delete(payload) {
    const { bankId, launchId } = payload;
    return $axios.delete(`${endpoint}/${bankId}/bank_launches/${launchId}.json`);
  },

  recalcBalance(payload) {
    return $axios.post(`${endpoint}/${payload.data.bankId}/recalculate_balance.json`, payload);
  },

  formatResponseData(data) {
    const launches = data;
    launches.forEach((launch) => {
      const sign = launch.type_transaction === 'entry' ? '' : '-';
      const balanceSign = Number(launch.balance) >= 0 ? '' : ' -';
      const currentBalanceSign = Number(launch.current_balance) >= 0 ? '' : ' -';
      const transactionValue = launch.transaction_value || 0.00;

      launch.launch_date_formatted = AppUtils.formatDate(launch.launch_date);
      launch.transaction_value = AppUtils.currencyWithSign(transactionValue, sign);
      launch.balance = AppUtils.currencyWithSign(launch.balance, balanceSign);
      launch.current_balance_formatted = AppUtils.currencyWithSign(launch.current_balance, currentBalanceSign);
      launch.type_launch = AppUtils.getOptionText('launch', launch.type_launch);
    });

    return launches;
  },
  formatRequestData(data) {
    const launch = data;
    return launch;
  },
});
