import {
  camelToSnake,
  removeNullUndefined,
  removeWhiteSpacesFrom,
  getInLocalStorage,
} from '@plugins/app-utils';

const endpoint = 'preferences';

const formatRequest = (preference) => {
  delete preference.updated_at;
  delete preference.updated_by;

  removeNullUndefined(preference);
  removeWhiteSpacesFrom(['prefix', 'sufix'], preference);

  const number_precision = parseInt(preference?.number_precision);
  if (number_precision) {
    return {
      ...preference,
      number_precision: parseInt(preference.number_precision),
    };
  }
  return preference;
};

const CompanyPreferencesCommands = ($axios) => ({
  /**
   * @param {'Register' | 'Product' | 'OrderSale' | 'OrderPurchase' | 'OrderBudget' | 'Invoice' | 'General' | 'AccountPayment' | 'AccountReceivable'} type
   */
  savePreferences: async (payload, type) => {
    if (!payload || !type) return;
    payload = formatRequest(payload);
    const { data } = await $axios.put(`${endpoint}/${camelToSnake(type)}`, payload);
    if (!data) return;
    return data;
  },
  show: async (type) => {
    if (!type) return;
    const { data } = await $axios.get(`${endpoint}/${camelToSnake(type)}`);
    if (!data) return;
    return data;
  },
  showTemplate: async (service, template) => {
    if (!service || !template) return;

    const localStorageData = getInLocalStorage(`IC/Storage/${template}`);
    if (localStorageData && service === 'General') {
      return JSON.parse(localStorageData);
    }
    const { data } = await $axios.get(`${endpoint}/${camelToSnake(service)}/${template}`);
    if (!data) return;
    return data;
  },
  getPreferences: async (type) => {
    if (!type) return;
    const { data } = await $axios.get(`${endpoint}/${camelToSnake(type)}/values`);
    if (!data) return;
    return data;
  },
  resetDefaultTemplate: async (service) => {
    if (!service) return;
    const { data } = await $axios.put(`${endpoint}/${camelToSnake(service)}/reset`);
    if (!data) return;
    return data;
  },
});

export { CompanyPreferencesCommands };
export default { CompanyPreferencesCommands };
