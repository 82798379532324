export default [
  // Line 1
  {
    label: 'Natureza da Operação',
    name: 'nature_operation_id',
    formattedName: 'nature_operation.to_s',
    type: 'number',
    component: 'dynamic-select',
    dataSource: {
      service: 'NatureOperations',
      method: 'getByFilter',
      props: [{
        use_inactive_eq: false,
        type_nature_eq: 'nfse',
      }],
    },
    itemText: 'name',
    itemValue: 'id',
    colClass: 'nf-field top left',
    prependIcon: '',
    md: 6,
  },
  {
    label: 'Protocolo de Autorização de Uso',
    name: 'responseprotocol.protocol_formatted',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 6,
    disabled: true,
    colClass: 'nf-field top left right',
    prependIcon: '',
  },
  // Line 2
  {
    label: 'Inscrição Estadual',
    name: 'emitter.ie',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    readOnly: true,
    colClass: 'nf-field top bottom left ',
    prependIcon: '',
  },
  {
    label: 'Inscrição Estadual do Subst. Tributário',
    name: 'emitter.iest',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    readOnly: true,
    prependIcon: '',
    colClass: 'nf-field top bottom left',
  },
  {
    label: 'SUFRAMA',
    name: 'buyer.suframa',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    prependIcon: '',
    colClass: 'nf-field top bottom left',
  },
  {
    label: 'CNPJ',
    name: 'emitter.cnpj',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    readOnly: true,
    prependIcon: '',
    colClass: 'nf-field top left bottom right',
  },
];
