<template>
  <div :id="item.id">
    <GenericTab :schema="schema" :item="item" />
  </div>
</template>
<script>
import GenericTab from '@sharedComponents/tabs/GenericTab';
import OrderBudgetSchema from '@schemas/OrderBudget';

export default {
  name: 'TabOrderSale',
  components: {
    GenericTab,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      schema: OrderBudgetSchema,
    };
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style scoped></style>
