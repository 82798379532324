<template>
  <div>
    <slot v-if="status" name="loading">
      <v-container grid-list-xl align-center fill-height>
        <v-layout row
                  align-center
                  justify-center
                  fill-height
                  class="ma-0">
          <v-progress-circular :width="2" :color="color" :size="large ? 30 : 15" indeterminate />
        </v-layout>
      </v-container>
    </slot>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 'LoadingTemplate',
  props: {
    when: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: [String, Array],
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    isLoading() {
      const store = this.$store;
      if (!store) {
        // throw new Error('Vuex not initialized.');
      }
      return store.getters.isLoading;
    },
    anyLoading() {
      const store = this.$store;
      if (!store) {
        // throw new Error('Vuex not initialized.');
      }
      return store.getters.anyLoading;
    },
    status() {
      if (this.when) {
        return this.when;
      }
      if (this.loader) {
        return this.isLoading(this.loader);
      }
      return false;
    },
  },
};
</script>
