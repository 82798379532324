const CreateTypeInstallment = () => import(/* webpackChunkName: "create-type-installment" */'./CreateTypeInstallment');
const EditTypeInstallment = () => import(/* webpackChunkName: "edit-type-installment" */'./EditTypeInstallment');
const ListTypeInstallments = () => import(/* webpackChunkName: "list-type-installments" */'./ListTypeInstallments');

export default [
  {
    path: '/tables/typeinstallments',
    name: 'tables-typeinstallments-list',
    meta: {
      requiresAuth: true,
      rule: 'type_installments',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Formas de Parcelamento' },
      ],
    },
    component: ListTypeInstallments,
  },
  {
    path: '/tables/typeinstallment/create',
    name: 'tables-typeinstallment-create',
    meta: {
      requiresAuth: true,
      rule: 'type_installments',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Formas de Parcelamento', link: '/tables/typeinstallments/list' },
        { name: 'Criar Parcelamento' },
      ],
    },
    component: CreateTypeInstallment,
  },
  {
    path: '/tables/typeinstallment/edit/:id',
    name: 'tables-typeinstallment-edit',
    meta: {
      requiresAuth: true,
      rule: 'type_installments',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Formas de Parcelamento', link: '/tables/typeinstallments/list' },
        { name: 'Editar Parcelamento' },
      ],
    },
    component: EditTypeInstallment,
  },

];
