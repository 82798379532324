import * as AppUtils from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';
import Config from '@src/core/config';
import _ from 'lodash';
import moment from 'moment';
import { formatBoolean } from '@plugins/app-utils';

const threatEmpty = (item, field) => (!item[field] || item[field].length === 0 ? {} : item[field]);

const formatResponse = (invoice) => {
  /** Emitter Fields */
  invoice.emitter = threatEmpty(invoice, 'emitter');
  const emitterAddressMain = invoice.emitter?.addresses.find((address) => address.main === true);
  const emitterContactMain = invoice.emitter?.contacts.find((contact) => contact.main === true);

  invoice.emitter = {
    id: invoice.emitter_id || (invoice.emitter && invoice.emitter.id) || null,
    emitter_id: invoice.emitter_id || (invoice.emitter && invoice.emitter.id) || null,
    register_id: invoice.emitter_id || (invoice.emitter && invoice.emitter.id) || null,
    ie: invoice.emitter?.ie || '',
    iest: invoice.emitter?.iests[0]?.ie || '',
    cnpj: invoice.emitter?.cnpj || '',
    street: emitterAddressMain.street,
    number: emitterAddressMain.number,
    zip_code: emitterAddressMain.zip_code,
    complement: emitterAddressMain.complement,
    neighborhood: emitterAddressMain.neighborhood,
    code_country: emitterAddressMain.country ? emitterAddressMain.country.code : '',
    country: emitterAddressMain.country ? emitterAddressMain.country.name : '',
    city: emitterAddressMain.city,
    uf: emitterAddressMain.uf,
    telephone: emitterContactMain.telephone,
    ...invoice.emitter,
  };

  invoice.nature_operation_id = invoice.nature_operation_id || (invoice.nature_operation && invoice.nature_operation.id) || null;
  /** Customer Fields */
  invoice.buyer = threatEmpty(invoice, 'buyer');

  invoice.buyer_id = invoice.buyer_id || (invoice.buyer && invoice.buyer.id) || null;

  // dates
  invoice.start_datetime_formatted = AppUtils.formatDate(invoice.start_datetime);
  invoice.end_datetime_formatted = AppUtils.formatDate(invoice.end_datetime);
  invoice.presence_indicator_formatted = AppUtils.getOptionText('presence_indicator', invoice.presence_indicator);

  invoice.emission_date = AppUtils.formatDate(invoice.emission_datetime);
  invoice.emission_date_hour = invoice.emission_datetime ? moment(invoice.emission_datetime).utc().format('HH:mm') : '';
  invoice.emission_date_formatted = AppUtils.formatDate(invoice.emission_datetime);

  invoice.exit_date = AppUtils.formatDate(invoice.exit_datetime);
  invoice.exit_date_formatted = AppUtils.formatDate(invoice.exit_datetime);
  invoice.competence_date_formatted = AppUtils.formatDate(invoice.competence_datetime);
  invoice.exit_date_hour = invoice.exit_date ? moment(invoice.exit_datetime).utc().format('HH:mm') : '';

  const { origin, uf, city } = invoice.provision_address;
  invoice.provision_address.origin = origin || null;
  invoice.provision_address.uf = uf || '';
  invoice.provision_address.city = city || '';

  invoice.salesman = threatEmpty(invoice, 'salesman');
  invoice.salesman_id = invoice.salesman_id || (invoice.salesman && invoice.salesman.id) || null;
  invoice.provider = threatEmpty(invoice, 'provider');
  invoice.provider_id = invoice.provider_id || (invoice.provider && invoice.provider.id) || null;
  invoice.type_installment_id = invoice.type_installment_id || (invoice.type_installment && invoice.type_installment.id) || null;
  invoice.payment_method_id = invoice.payment_method_id || (invoice.payment_method && invoice.payment_method.id) || null;
  invoice.coin_id = invoice.coin_id || (invoice.coin && invoice.coin.id) || null;
  invoice.price_list_id = invoice.price_list_id || (invoice.price_list && invoice.price_list.id) || null;

  invoice.nfse_number_formatted = invoice.nfse_number;
  invoice.rps_number_formatted = invoice.rps_number;

  invoice.status_formatted = AppUtils.getOptionText('invoice_iss_status', invoice.status);

  invoice.document_type_formatted = AppUtils.getOptionText('invoice_document_type', invoice.document_type);
  invoice.expense_value_formatted = AppUtils.formatCurrency(parseFloat(invoice.expense_value));

  invoice.contingency = threatEmpty(invoice, 'contingency');
  invoice.request_key = threatEmpty(invoice, 'request_key');
  invoice.response_protocol = threatEmpty(invoice, 'response_protocol');
  invoice.service = threatEmpty(invoice, 'service');
  invoice.tax = threatEmpty(invoice, 'tax');

  invoice.justification = threatEmpty(invoice, 'justification');
  invoice.is_customer_formatted = formatBoolean(invoice.is_customer);

  invoice.total.discount_formatted = AppUtils.formatCurrency(parseFloat(invoice.total?.discount || 0));
  invoice.total.services_formatted = AppUtils.formatCurrency(parseFloat(invoice.total?.services || 0));
  invoice.total.invoice_formatted = AppUtils.formatCurrency(parseFloat(invoice.total?.invoice || 0));

  invoice.items = invoice.items || [];
  invoice.taxes = invoice.taxes || [];

  let issTaxes = {};
  const formattedItems = invoice.items.map((item, index) => {
    item.product_id = item.product_id || (item.product && item.product.id) || null;
    item.product_code = item.product_code || (item.product && item.product.code) || null;
    item.nature_operation_id = (item.nature_operation && item.nature_operation_id) || null;
    item.service_code_id = (item.service_code && item.service_code_id) || null;
    item.list_price_id = (item.list_price && item.list_price_id) || null;

    const unsetTax = 0.00;
    issTaxes = {
      percentage_csll: invoice.items[index]?.iss?.percentage_csll ?? unsetTax,
      value_csll: invoice.items[index]?.iss?.value_csll ?? unsetTax,
      is_withhold_csll: invoice.items[index]?.iss?.is_withhold_csll ?? false,

      percentage_csll_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.percentage_csll ?? unsetTax),
      value_csll_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.value_csll ?? unsetTax),
      is_withhold_csll_formatted: AppUtils.formatBoolean(invoice.items[index]?.iss?.is_withhold_csll ?? false),

      percentage_inss: invoice.items[index]?.iss?.percentage_inss ?? unsetTax,
      value_inss: invoice.items[index]?.iss?.value_inss ?? unsetTax,
      is_withhold_inss: invoice.items[index]?.iss?.is_withhold_inss ?? false,

      percentage_inss_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.percentage_inss ?? unsetTax),
      value_inss_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.value_inss ?? unsetTax),
      is_withhold_inss_formatted: AppUtils.formatBoolean(invoice.items[index]?.iss?.is_withhold_inss ?? false),

      percentage_ir: invoice.items[index]?.iss?.percentage_ir ?? unsetTax,
      value_ir: invoice.items[index]?.iss?.value_ir ?? unsetTax,
      is_withhold_ir: invoice.items[index]?.iss?.is_withhold_ir ?? false,

      percentage_ir_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.percentage_ir ?? unsetTax),
      value_ir_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.value_ir ?? unsetTax),
      is_withhold_ir_formatted: AppUtils.formatBoolean(invoice.items[index]?.iss?.is_withhold_ir ?? false),

      percentage_iss: invoice.items[index]?.iss?.percentage_iss ?? unsetTax,
      value_iss: invoice.items[index]?.iss?.value_iss ?? unsetTax,
      is_withhold_iss: invoice.items[index]?.iss?.is_withhold_iss ?? false,

      percentage_iss_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.percentage_iss ?? unsetTax),
      value_iss_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.value_iss ?? unsetTax),
      is_withhold_iss_formatted: AppUtils.formatBoolean(invoice.items[index]?.iss?.is_withhold_iss ?? false),

      percentage_pis: invoice.items[index]?.iss?.percentage_pis ?? unsetTax,
      value_pis: invoice.items[index]?.iss?.value_pis ?? unsetTax,
      is_withhold_pis: invoice.items[index]?.iss?.is_withhold_pis ?? false,

      percentage_pis_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.percentage_pis ?? unsetTax),
      value_pis_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.value_pis ?? unsetTax),
      is_withhold_pis_formatted: AppUtils.formatBoolean(invoice.items[index]?.iss?.is_withhold_pis ?? false),

      percentage_cofins: invoice.items[index]?.iss?.percentage_cofins ?? unsetTax,
      value_cofins: invoice.items[index]?.iss?.value_cofins ?? unsetTax,
      is_withhold_cofins: invoice.items[index]?.iss?.is_withhold_cofins ?? false,

      percentage_cofins_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.percentage_cofins ?? unsetTax),
      value_cofins_formatted: AppUtils.formatNumber(invoice.items[index]?.iss?.value_cofins ?? unsetTax),
      is_withhold_cofins_formatted: AppUtils.formatBoolean(invoice.items[index]?.iss?.is_withhold_cofins ?? false),
    };
    const returnedItem = {
      ...item,
      iss: issTaxes,
      quantity_formatted: AppUtils.formatNumber(item.quantity || 0),
      unit_value_formatted: AppUtils.formatCurrency(parseFloat(item.unit_value || 0)),
      discount_value_formatted: AppUtils.formatCurrency(parseFloat(item.discount_value || 0)),
      discount_percentage_formatted: `${AppUtils.formatNumber(parseFloat(item.discount_percentage_value))}%`,
      total_value_formatted: AppUtils.formatCurrency(parseFloat(item.total_value || 0)),

    };
    return returnedItem;
  });
  invoice.items = formattedItems;
  invoice.taxes = {
    iss: issTaxes,
    ...invoice.taxes,
  };

  if (invoice.current_errors.length > 0) {
    invoice.current_errors.map((error) => {
      error.timestamp_formatted = error?.timestamp ? AppUtils.formatDate(error?.timestamp) : '';
    });
  }
  invoice.current_errors = invoice.current_errors || [];

  invoice.references = invoice.references || [];
  invoice.addresses = invoice.addresses || [];
  invoice.authorizers = invoice.authorizers || [];
  invoice.fields = invoice.fields || [];

  invoice.buyer.taxpayer_type_formatted = AppUtils.getOptionText('tax_payer', invoice.buyer.taxpayer_type);
  invoice.buyer.cnpj = AppUtils.formatCnpjCpf(invoice?.buyer?.cnpj);

  const formattedProtocolDate = invoice.response_protocol.protocol_date ? AppUtils.formatDate(invoice.response_protocol.protocol_date) : '';
  invoice.response_protocol.protocol_formatted = invoice.response_protocol.protocol ? `${invoice.response_protocol.protocol} - ${formattedProtocolDate}` : '';
};

const formatRequest = (invoice) => {
  invoice.emission_date = AppUtils.parseDateTimeToISO8061(AppUtils.parseDate(`${invoice.emission_date}`), invoice.emission_date_hour);
  invoice.exit_date = AppUtils.parseDateTimeToISO8061(AppUtils.parseDate(`${invoice.exit_date}`), invoice.exit_date_hour);

  invoice.total.discount_formatted = AppUtils.formatCurrency(invoice.total.discount);
  invoice.total.services_formatted = AppUtils.formatCurrency(invoice.total.services);
  invoice.total.invoice_formatted_formatted = AppUtils.formatCurrency(invoice.total.invoice);

  const provisionAddress = {
    origin: invoice?.provision_address?.origin,
    uf: invoice?.provision_address?.uf,
    city: invoice?.provision_address?.city,
  };
  invoice.provision_address = provisionAddress || [];

  const invalidInvoiceFields = [
    'to_s',
    'create_at',
    'updated_at',
    'contingency.to_s',
    'request_key.to_s',
    'response_protocol.to_s',
    'service.to_s',
    'tax.to_s',
    'emitter.to_s',
    'justification.to_s',
    'bill.to_s',
    'export.to_s',
    'items.to_s',
    'emission_date_hour',
    'emission_date_formatted',
    'exit_date_hour',
    'exit_date_formatted',
  ];

  const invalidItemFields = [
    'icms_st.to_s',
    'simple.to_s',
    'icms_st.created_at',
    'simple.created_at',
    'icms_st.updated_at',
    'simple.updated_at',
  ];

  const newInvoice = _.omit(invoice, invalidInvoiceFields);

  newInvoice.items = newInvoice.items || [];
  newInvoice.items.forEach((item) => {
    item = _.omit(item, invalidItemFields);

    item.iss = item.iss;

    delete item.iss;
  });

  newInvoice.response_errors = newInvoice.response_errors || [];
  newInvoice.response_errors.forEach((item) => {
    item.created_at_formatted = AppUtils.formatDate(item.created_at);
  });

  newInvoice.contingency = newInvoice.contingency || {};
  newInvoice.request_key = newInvoice.request_key || {};
  newInvoice.response_protocol = newInvoice.response_protocol || {};
  newInvoice.service = newInvoice.service || {};
  newInvoice.tax = newInvoice.tax || {};
  newInvoice.emitter = newInvoice.emitter || {};
  newInvoice.buyer = newInvoice.buyer || {};
  newInvoice.transporter = newInvoice.transporter || {};
  newInvoice.justification = newInvoice.justification || {};
  newInvoice.invoice_info = newInvoice.invoice_info || '';
  newInvoice.use_invoice_info = newInvoice.use_invoice_info || false;

  newInvoice.export = newInvoice.export || {};

  newInvoice.items = newInvoice.items || [];
  newInvoice.references = invoice.references || [];
  newInvoice.addresses = newInvoice.addresses || [];
  newInvoice.authorizers = newInvoice.authorizers || [];
  newInvoice.fields = newInvoice.fields || [];

  delete newInvoice.contingency;
  delete newInvoice.request_key;
  delete newInvoice.response_protocol;
  delete newInvoice.service;
  delete newInvoice.tax;
  delete newInvoice.emitter;
  delete newInvoice.buyer;
  delete newInvoice.transporter;
  delete newInvoice.justification;
  delete newInvoice.bill;
  delete newInvoice.export;

  delete newInvoice.items;
  delete newInvoice.references;
  delete newInvoice.addresses;
  delete newInvoice.authorizers;
  delete newInvoice.fields;

  AppUtils.modifyRef(invoice, newInvoice);
};

const InvoicesISS = DynamicService('nfse', {
  baseURL: Config.API_GATEWAY_NFSE,
  formatResponse,
  formatRequest,
  noUrlSufixJson: true,
});

const InvoiceISSCommands = ($axios) => ({
  async create(payload) {
    const res = await $axios.post(`${Config.API_GATEWAY_NFSE}/nfse`, payload);
    return res;
  },

  async getAll() {
    const res = await $axios.get(`${Config.API_GATEWAY_NFSE}/nfse`);
    return res;
  },

  async transmit(payload) {
    const formattedData = {
      ids: payload?.id ? [payload?.id] : payload?.ids,
    };
    const res = await $axios.post(`${Config.API_GATEWAY_NFSE}/nfse/transmit`, formattedData);
    return res;
  },

  /*
  async action(payload) {
    const res = await $axios.post(`${Config.API_GATEWAY_NFSE}/nfse/action.json`, payload);
    return res;
  },
  */

  async cancel(id, justification) {
    const payload = {
      reason: justification,
    };
    const res = await $axios.post(`${Config.API_GATEWAY_NFSE}/nfse/${id}/cancel.json`, payload);
    return res;
  },

  async status(id) {
    const res = await $axios.get(`${Config.API_GATEWAY_NFSE}/nfse/${id}/status.json`);
    return res;
  },

  async check(payload) {
    const res = await $axios.post(`${Config.API_GATEWAY_NFSE}/nfse/consult`, payload);
    return res;
  },

  async getEvents(id) {
    let result = [];
    await $axios.get(`${Config.API_GATEWAY_NFSE}/nfse/${id}/check`).then((response) => {
      result = response.data;
    });
    return result;
  },

  async download(id, type) {
    const res = await $axios.get(`${Config.API_GATEWAY_NFSE}/nfse/${id}/download?type=${type}`);
    return res;
  },

  async checkCompany() {
    const res = await $axios.get(`${Config.API_GATEWAY_NFSE}/company`).catch(() => false);
    return !!res; // !! = res ? true : false
  },
});

export default { InvoicesISS, InvoiceISSCommands };
export { InvoicesISS, InvoiceISSCommands };
