import * as AppUtils from '@plugins/app-utils';

const endpoint = 'anticipation/drawees';

const Drawees = ($axios) => ({
  index() {
  },
  async show(id) {
    let responseData = {};

    await $axios.get(`${endpoint}/${id}.json`).then((response) => {
      responseData = this.showFormatResponseData(response.data, 'row');
    });

    return responseData;
  },
  formatResponseData(data, type = 'table') {
    const format = () => {

    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  showFormatResponseData(data, type = 'table') {
    const format = (item) => {
      item.invoices.forEach((i) => {
        i.buyer.cnpj_cpf_formatted = AppUtils.formatCnpjCpf(i.buyer.cnpj_cpf);
        i.emission_date_formatted = AppUtils.formatDate(i.emission_date);
        i.totals_note_formatted = AppUtils.formatCurrency(i.totals_note) || 'R$ 0,00';
      });
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  async getByFilter(filters, order) {
    const payload = {
      q: filters || {},
    };
    // add sort option
    if (order) {
      payload.q.s = order;
    }

    let responseData = {};
    await $axios.post(`${endpoint}/1/assignor_drawees/filters.json`, payload).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },
});

export { Drawees };
export default { Drawees };
