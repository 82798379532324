import DynamicService from '@sharedServices/dynamic/DynamicService';

const layoutsEndpoint = 'invoice_mailer_layout';

const EmailsLayouts = DynamicService(layoutsEndpoint, {
  formatResponse: (layout) => {
    if (layout.copy_email) {
      const copy_email = layout?.copy_email.join(', ');
      layout.copy_email = (copy_email || '');
    }
  },
  formatRequest: (layout) => {
    if (layout.copy_email) {
      const copy_email = layout.copy_email.replaceAll(' ', '').split(',');
      layout.copy_email = (copy_email ?? []);
    }
  },
});

export {
  EmailsLayouts,
};

export default {
  EmailsLayouts,
};
