import GeneralRegistersReportSchema from './GeneralRegistersReportsSchema';

const GeneralRegistersReports = () => import(/* webpackChunkName: "general-registers-reports" */'./GeneralRegistersReports.vue');
export const RegistersReportsRoutes = [
  {
    path: GeneralRegistersReportSchema.route.path,
    name: GeneralRegistersReportSchema.route.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Relatórios' },
        { name: 'Cadastros' },
      ],
    },
    component: GeneralRegistersReports,
  },
];
