<template>
  <v-text-field
    ref="numberInput"
    v-model.number.lazy="stringFieldValue"
    v-money="moneyMask"
    :required="checkRequired()"
    :prefix="field.prefix ?? 'R$'"
    :suffix="field.suffix"
    :rounded="field.rounded"
    :filled="field.filled"
    :hint="field.hint"
    :persistent-hint="true"
    :disabled="checkDisabled()"
    :min="field.min"
    :max="field.max"
    :step="field.step"
    :label="field.label"
    :class="field.class"
    :autofocus="field.autofocus"
    :rules="validationRules"
    :type="fieldType"
    :readonly="readOnly || field.readOnly"
    :clearable="field.clearable"
    :clear-icon="iconClose"
    :prepend-icon="innerIcon ? '' : prependIcon"
    :prepend-inner-icon="innerIcon ? prependIcon : ''"
    v-bind="$attrs"
    v-on="$listeners"
    @change="onValueChange" />
</template>

<script>
import { VMoney } from 'v-money';
import { ICON_FORM_NUMERIC, ICON_CLOSE } from '@constants/icons';
import FormatNumber from '@filters/FormatNumber';
import DynamicFieldMixin from './DynamicFieldMixin';

export default {
  name: 'DynamicNumberField',
  directives: { money: VMoney },
  mixins: [DynamicFieldMixin],
  props: {
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    decimal: {
      type: String,
      default: ',',
    },
    thousands: {
      type: String,
      default: '.',
    },
    precision: {
      type: Number,
      default: 2,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptyValue: 0,
      fieldValue: null,
      defaultIcon: ICON_FORM_NUMERIC,
      iconClose: ICON_CLOSE,
    };
  },
  computed: {
    moneyMask() {
      return {
        decimal: this.decimal,
        thousands: this.thousands,
        precision: this.precision,
        masked: false /* doesn't work with directive */,
      };
    },
    prependIcon() {
      return this.field.prependIcon === undefined ? this.defaultIcon : this.field.prependIcon;
    },
    appendIcon() {
      return this.field.appendIcon;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
    returnFloat() {
      return this.field.returnFloat || false;
    },
  },
  watch: {
    fieldValue(value) {
      this.stringFieldValue = FormatNumber.format(value || 0, this.numericMask);
      // bug https://github.com/vuejs-tips/v-money/issues/44
      if (this.$refs.numberInput && this.$refs.numberInput.$el) {
        this.$refs.numberInput.$el.getElementsByTagName('input')[0].value = this.stringFieldValue || '0';
      }
    },
  },
  methods: {
    onValueChange(value) {
      const unformatted = FormatNumber.unformat(value, this.moneyMask, this.returnFloat);
      this.fieldChanged(unformatted);
    },
  },
};
</script>

<style lang="scss"></style>
