import * as localforage from 'localforage';

/**
 * Set LocalStorage as default driver
 */
localforage.setDriver(localforage.LOCALSTORAGE);

/**
 * Dispatch config and set name
 */
localforage.config({
  name: 'IC/Storage',
});

/**
 * get an item by key value
 * @param {*} key
 * @returns
 */
export function get(key) {
  return localforage.getItem(key);
}

/**
 * set an item by key and value
 * @param {*} key
 * @param {*} value
 * @returns
 */
export function set(key, value) {
  return localforage.setItem(key, value);
}

/**
 * remove an item by key value
 * @param {*} key
 * @returns
 */
export function remove(key) {
  return localforage.removeItem(key);
}

/**
 * Delete all items in localForage instance
 * @returns
 */
export function DELETE_ALL() {
  return localforage.clear();
}

/**
 * List all keys stored
 * @returns
 */
export function listKeys() {
  return localforage.keys();
}
