import ProfileSchema from '@schemas/Profile';
import ProfileMenuSchema from '@schemas/ProfileMenu';

const ListProfiles = () => import(/* webpackChunkName: "list-profiles" */'./ListProfiles');
const DetailsProfiles = () => import(/* webpackChunkName: "details-profiles" */'./DetailsProfiles');
const ListProfileMenus = () => import(/* webpackChunkName: "list-profile-menus" */'./components/ListProfileMenus');

export default [
  {
    path: '/settings/profiles',
    name: 'settings-profiles-list',
    meta: {
      required: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: 'Perfis de Acesso' },
      ],
    },
    component: ListProfiles,
  },
  {
    path: '/settings/profiles/create',
    name: 'settings-profiles-create',
    meta: {
      require: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: ProfileSchema.titles.items, route: 'settings-profiles-list' },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailsProfiles,
  },
  {
    path: '/settings/profiles/edit/:id',
    name: 'settings-profiles-edit',
    meta: {
      require: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: ProfileSchema.titles.items, route: 'settings-profiles-list' },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailsProfiles,
  },
  {
    path: ProfileMenuSchema.routes.list.path,
    name: ProfileMenuSchema.routes.list.name,
    meta: {
      require: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: ProfileSchema.titles.items, route: ProfileSchema.routes.list.name },
      ],
    },
    component: ListProfileMenus,
  },
];
