function addStyles(win, styles) {
  styles.forEach((style) => {
    const link = win.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', style);
    win.document.getElementsByTagName('head')[0].appendChild(link);
  });
}

const Printer = {
  install(Vue, options = {}) {
    Vue.prototype.$openPopupPDF = (base64) => {
      const byteCharacters = atob(base64);
      const byteArrays = [];
      const sliceSize = 512;

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    };

    Vue.prototype.$printer = (el, localOptions, cb = () => true) => {
      const defaultTitle = '';
      const defaultTarget = '_blank';
      const defaultSpecs = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'];
      const defaultStyles = [];
      let {
        title = defaultTitle,
        target = defaultTarget,
        specs = defaultSpecs,
        styles = defaultStyles,
      } = options;

      // If has localOptions
      // TODO: improve logic
      if (localOptions) {
        if (localOptions.title) title = localOptions.title;
        if (localOptions.target) target = localOptions.target;
        if (localOptions.specs) specs = localOptions.specs;
        if (localOptions.styles) styles = localOptions.styles;
      }

      specs = specs.length ? specs.join(',') : '';

      const header = window.document.getElementById('printer-header');
      const element = window.document.getElementById(el);
      const elementByClass = window.document.getElementsByClassName(el);
      const footer = window.document.getElementById('printer-footer');

      if (!element) {
        alert(`Elemento #${el} não encontrado!`);
        return;
      }
      if (!elementByClass) {
        alert(`Elemento .${el} não encontrado!`);
        return;
      }
      let formattedTitle = '';
      if (title?.align === 'center') {
        formattedTitle = `<center>${title.value || ''}</center>`;
      } else {
        formattedTitle = `${title.value || ''}`;
      }

      const url = '';
      const win = window.open(url, target, specs);

      win.document.write(`
        <html>
          <head>
            <title>${title}</title>
            <style>
              .header{
                margin-top: 10px;
                margin-left: 30px;
                margin-right: auto;
                display: block;
                text-align: justify;    
              }
              .body{
                display: block;
                margin-top: 100px;
                margin-left: auto;
                margin-right: auto;
                text-align: justify;    
              }
              .footer {
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                color: white;
                text-align: center;
              }
              .printer-text-section {
                text-size: 20px;
              }
            </style>
          </head>
          <body>
            <div class="header">
              ${header.innerHTML || ''}
            </div>
            <div class="body">
              ${formattedTitle || ''}
              ${element.innerHTML || ''}
              ${elementByClass.innerHTML || ''}
            </div>
          </body>
          <footer class="footer">
              ${footer.innerHTML || ''}
          </footer>
        </html>
      `);

      addStyles(win, styles);

      setTimeout(() => {
        win.document.close();
        win.focus();
        win.print();
        win.close();
        cb();
      }, 1000);

      return true;
    };
  },
};

export default Printer;
