// service that handle search requests
export default ($axios) => ({
  async getByFilter(filters, order, url, action_types, options) {
    const payload = {
      q: filters || {},
      action_types: action_types || [],
    };
    // add sort option
    if (order) {
      payload.q.s = order;
    }

    payload.out = {
      value: 'id',
      text: 'name',
    };

    const sufix = options?.noUrlSufixJson ? '' : '.json';
    return $axios.post(`${url}${sufix}`, payload);
  },
});
