import AlltypesSchema from '@schemas/Alltypes';

export default [
  {
    path: AlltypesSchema.routes.types.list.path,
    name: AlltypesSchema.routes.types.list.name,
    meta: AlltypesSchema.routes.types.list.meta,
    component: () => import(/* webpackChunkName: 'list-types' */ `${AlltypesSchema.routes.types.list.component}`),
  },
  {
    path: AlltypesSchema.routes.types.create.path,
    name: AlltypesSchema.routes.types.create.name,
    meta: AlltypesSchema.routes.types.create.meta,
    component: () => import(/* webpackChunkName: 'create-types' */ `${AlltypesSchema.routes.types.create.component}`),
  },
  {
    path: AlltypesSchema.routes.types.edit.path,
    name: AlltypesSchema.routes.types.edit.name,
    meta: AlltypesSchema.routes.types.edit.meta,
    component: () => import(/* webpackChunkName: 'edit-types' */ `${AlltypesSchema.routes.types.edit.component}`),
  },
];
