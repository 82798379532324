<!-- eslint-disable no-unexpected-multiline -->
<template>
  <v-select
    :value="parsedFieldValue"
    :hint="field.hint"
    :persistent-hint="true"
    :required="checkRequired()"
    :disabled="checkDisabled()"
    :multiple="field.multiple"
    :readonly="field.readOnly"
    :chips="field.chips"
    :items="fieldItems"
    :item-value="field.itemValue"
    :item-text="field.itemText"
    :rules="validationRules"
    :label="field.label"
    :return-object="true"
    :class="field.class"
    :append-outer-icon="field.redirectCreateRoute && appendIcon"
    :loading="isLoading"
    :no-data-text="itemsFound"
    :clearable="field.clearable"
    :clear-icon="icons.close"
    validate-on-blur
    :cache-items="field?.cacheItems ?? false"
    :rounded="field.rounded"
    :filled="field.filled"
    :prepend-icon="innerIcon ? '' : prependIcon"
    :prepend-inner-icon="innerIcon ? prependIcon : ''"
    v-bind="$attrs"
    v-on="$listeners"
    @click:append-outer="goTo()"
    @input="onSelectChange">
    <template v-slot:append-outer>
      <slot name="append-outer" />
    </template>
    <template v-if="field.multiple" v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0">
        <span>{{ item[field.itemText || 'text'] }}</span>
      </v-chip>
      <v-checkbox v-if="field?.hideNot" :on-icon="icons.checkBoxOn" :off-icon="icons.checkBoxOff" />
      <span v-if="index === 1 && fieldValue.length > 1" class="grey--text text-caption"> (+{{ fieldValue.length - 1 }} {{ fieldValue.length > 2 ? 'outros' : 'outro' }}) </span>
    </template>
  </v-select>
</template>

<script>
import {
  ICON_CHECKBOX_MARKED, ICON_CHECKBOX_BLANK, ICON_FORM_SELECT, ICON_FOLDER_PLUS, ICON_CLOSE,
} from '@constants/icons';
import DynamicUtils from './DynamicUtils';
import DynamicFieldMixin from './DynamicFieldMixin';
import { orderedArray } from '@plugins/app-utils';
import * as LocalForage from '@sharedServices/dynamic/LocalForage';

export default {
  name: 'DynamicSelect',
  mixins: [DynamicFieldMixin],
  data() {
    return {
      DynamicUtils,
      fieldValue: this.value,
      parsedFieldValue: [],
      fieldItems: [],
      icons: {
        checkBoxOn: ICON_CHECKBOX_MARKED,
        checkBoxOff: ICON_CHECKBOX_BLANK,
        close: ICON_CLOSE,
        default: ICON_FORM_SELECT,
      },
      itemsFound: 'Carregando...',
      isLoading: true,
    };
  },
  computed: {
    prependIcon() {
      return this.field.prependIcon === undefined ? this.icons.default : this.field.prependIcon;
    },
    appendIcon() {
      return this.field.appendIcon || ICON_FOLDER_PLUS;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
  },
  watch: {
    fieldValue: {
      handler: 'onFieldValueChange',
      immediate: true,
    },
    field: {
      handler: 'onFieldDefinitionChange',
      immediate: true,
    },
  },
  methods: {
    async onFieldDefinitionChange(field, oldField) {
      this.itemsFound = 'Carregando...';
      if (oldField && field.model === oldField.model) {
        return;
      }

      this.fieldItems = (await LocalForage.get(field.name)) || [];
      if (field?.dataSource?.origin) {
        const { service, source } = field.dataSource;
        this.fieldItems = await this.$api[service][source];
        this.isLoading = false;
      } else if (field.items) {
        this.fieldItems = typeof field.items === 'function' ? field.items.call(this) : field.items;
        this.isLoading = false;
      } else if (field.dataSource) {
        const ds = field.dataSource;
        const dsProps = ds.props || [];
        await this.$api[ds.service]
          [ds.method](...dsProps)
          .then((response) => {
            this.fieldItems = response.data;
            if (this.field.sort) {
              const { fieldName } = this.field.sort;
              this.fieldItems = orderedArray(this.fieldItems, fieldName);
              this.isLoading = false;
            }
          })
          .catch(() => {
            this.itemsFound = 'Nenhum registro encontrado';
          });
      }
      this.itemsFound = 'Nenhum registro encontrado';
      LocalForage.set(field.name, this.fieldItems);
      this.isLoading = false;
    },
    onFieldValueChange(value) {
      if (this.field.multiple && Array.isArray(value)) {
        this.parsedFieldValue = this.fieldItems.filter((item) => value.findIndex((val) => item[this.field.itemValue || 'value'] === val) !== -1);
        this.itemsFound = 'Carregando...';
        return;
      }
      this.parsedFieldValue = value;
      this.itemsFound = 'Carregando...';
    },
    onSelectChange(objectValue) {
      if (typeof objectValue === 'object') {
        if (objectValue !== null) {
          const value = this.field.multiple ? objectValue.map((o) => o[this.field.itemValue || 'value']) : objectValue[this.field.itemValue || 'value'];
          this.fieldValue = value;
          this.fieldChanged(this.fieldValue, value);
          return;
        }
      }
      this.fieldValue = '';
      this.fieldChanged(this.fieldValue, '');
    },
    goTo() {
      if (this.field.redirectCreateRoute) {
        this.$router.push({ name: this.field.redirectCreateRoute, params: { return: this.$route.name } });
      }
    },
  },
};
</script>

<style lang="scss"></style>
