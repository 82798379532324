export const DefaultFieldEvents = {
  order: {
    change: (formValue) => {
      formValue.items = formValue.items || [];
      formValue.items.forEach((item) => {
        item.order_number = formValue.order;
      });
    },
  },
};
