import { ICON_NOTEBOOK_MULTIPLE } from '@constants/icons';

export default {
  service: '',
  icon: ICON_NOTEBOOK_MULTIPLE,
  routes: {
    list: {
      path: '/tables/paymentmethod',
      name: 'tables-paymentmethod-list',
    },
  },
  titles: {
    item: 'Forma de Pagamento',
    items: 'Formas de Pagamento',
  },
};
