<template>
  <DataListSelect v-if="!readOnly && !field.readOnly"
                  :value="fieldValue"
                  :hint="field.hint"
                  :persistent-hint="true"
                  :service="field.service"
                  :headers="field.headers"
                  :related-id-field="field.relatedIdField"
                  :formatted-field="field.formattedField"
                  :required="checkRequired()"
                  :disabled="checkDisabled()"
                  :multiple="field.multiple"
                  :rules="validationRules"
                  :label="field.label"
                  :return-object="field.returnObject"
                  :class="field.class"
                  :loading="isLoading"
                  validate-on-blur
                  :rounded="field.rounded"
                  :filled="field.filled"
                  :prepend-icon="innerIcon?'':prependIcon"
                  :prepend-inner-icon="innerIcon?prependIcon:''"
                  v-bind="$attrs"
                  v-on="$listeners"
                  @input="onSelectChange" />
  <div v-else class="dm-information">
    <label>{{ field.label }}</label>
    <p>{{ DynamicUtils.formatFieldValue(value, field) }}</p>
  </div>
</template>

<script>
import { ICON_FORM_SELECT } from '@constants/icons';
import DataListSelect from '@sharedComponents/form/DataListSelect';
import DynamicUtils from './DynamicUtils';
import DynamicFieldMixin from './DynamicFieldMixin';

export default {
  name: 'DynamicSelect',
  components: {
    DataListSelect,
  },
  mixins: [DynamicFieldMixin],
  data() {
    return {
      DynamicUtils,
      fieldValue: [],
      fieldItems: [],
      defaultIcon: ICON_FORM_SELECT,
    };
  },
  computed: {
    isLoading() {
      const loader = this.field.dataSource && this.field.dataSource.loader;
      if (loader) {
        return this.$isLoading(loader);
      }
      return false;
    },
    prependIcon() {
      return this.field.prependIcon === undefined ? this.defaultIcon : this.field.prependIcon;
    },
    appendIcon() {
      return this.field.appendIcon;
    },
  },
  methods: {
    onSelectChange(value) {
      this.fieldValue = value;
      this.fieldChanged(this.fieldValue);
    },
  },
};
</script>

<style lang="scss">
</style>
