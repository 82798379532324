import { OPTIONS_UF } from '@constants/options';

export default [
  {
    label: 'UF de Carregamento',
    name: 'load_uf',
    colClass: 'mt-6',
    formattedName: 'loading_uf_formatted',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_UF,
    required: true,
    cols: 12,
    prependIcon: '',
  },
  {
    label: 'UF de Descarregamento',
    name: 'unload_uf',
    colClass: 'mt-6',
    formattedName: 'unloading_uf_formatted',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_UF,
    required: true,
    cols: 12,
    prependIcon: '',
  },
];
