import FormatNumber from './FormatNumber';

export default {
  install(Vue) {
    Vue.filter('capitalize', (value) => {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    });

    Vue.filter('upper', (value) => {
      if (!value) return '';
      return value.toString().toUpperCase();
    });

    Vue.filter('lower', (value) => {
      if (!value) return '';
      return value.toString().toLowerCase();
    });

    // formatNumber

    const formatNumber = (number, options) => FormatNumber.format(number || 0, options);

    Vue.filter('formatNumber', formatNumber);
    Vue.prototype.$formatNumber = formatNumber;
  },
};
