import AllTypesRoutes from './pages/alltypes/routes';
import AccountingAccountRoutes from './pages/accountingaccount/routes';
import CestsRoutes from './pages/alltypes/cests/routes';
import CostCenterRoutes from './pages/costcenter/routes';
import NationalSimpleRoutes from './pages/taxes/nationalSimple/routes';
import NatureOperationRoutes from './pages/taxes/natureOperations/routes';
import NcmsRoutes from './pages/alltypes/ncms/routes';
import TypeInstallmentRoutes from './pages/typeinstallments/routes';

export default [
  ...AllTypesRoutes,
  ...AccountingAccountRoutes,
  ...CestsRoutes,
  ...CostCenterRoutes,
  ...NationalSimpleRoutes,
  ...NatureOperationRoutes,
  ...NcmsRoutes,
  ...TypeInstallmentRoutes,
];
