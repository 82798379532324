import {
  ICON_CASH_EDIT,
  ICON_DESCRIPTION,
} from '@constants/icons';
import CashFlowItem from './CashFlowItem';
import TabCashFlow from '@modules/financial/pages/cashflows/components/TabCashFlow';
import TabCashFlowLaunchs from '@modules/financial/pages/cashflows/components/TabCashFlowLaunchs';
import { OPTIONS_CASHFLOWS_TYPE } from '@constants/options';
import CashFlowBusiness from '@businesses/CashFlow';

export default {
  service: 'CashFlows',
  business: CashFlowBusiness,
  icon: ICON_CASH_EDIT,
  routes: {
    list: { path: '/financial/cashflow', name: 'financial-cashflow-list' },
    create: { path: '/financial/cashflow/create', name: 'financial-cashflow-create' },
    // edit: { path: '/production/order/edit/:id', name: 'production-order-edit' },
  },
  titles: {
    item: 'Fluxo de Caixa',
    items: 'Fluxos de Caixa',
    // edit: 'Editar Ordem de Produção',
    create: 'Criar Fluxo de Caixa',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Fluxo de Caixa excluido com sucesso!',
    // successEdit: 'Fluxo de Caixa alterado com sucesso!',
    successCreate: 'Fluxo de Caixa criado com sucesso!',
    errorSave: 'Erro ao salvar fluxo de caixa!',
  },
  filterUrl: 'cash_flows/filters',
  formName: 'cashflow',
  hideReportsOnCreate: true,
  fields: [
    {
      label: 'Configurações',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
    },
    {
      label: 'Data de Criação',
      name: 'date',
      formattedName: 'date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      list: true,
      required: true,
      clearable: true,
      md: 2,
      order: 1,
    },
    {
      label: 'Nome do Fluxo de Caixa',
      name: 'name',
      type: 'text',
      component: 'dynamic-text-field',
      required: true,
      list: true,
      md: 10,
    },
    {
      label: 'Data Início',
      name: 'start_date',
      formattedName: 'start_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
      md: 2,
    },
    {
      label: 'Data Final',
      name: 'end_date',
      formattedName: 'end_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
      md: 2,
    },
    {
      label: 'Tipo',
      name: 'type',
      formattedName: 'type_formatted',
      type: 'select',
      options_key: 'cashflows_type',
      component: 'dynamic-select',
      items: OPTIONS_CASHFLOWS_TYPE,
      required: true,
      list: true,
      md: 3,
    },
    {
      label: 'Resumido',
      name: 'is_summarized',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    /* @TODO - Removed to Sebrae Release
    {
      label: 'Consolidar',
      name: 'is_consolidate',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    {
      label: 'Buscar',
      name: 'search',
      component: 'dynamic-buttons',
      md: 2,
    },
    */
  ],
  childSchemas: [
    {
      label: 'Lançamentos',
      name: 'items',
      schema: CashFlowItem,
      noCreate: true,
      noEdit: true,
      noDelete: true,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabCashFlow,
    },
    {
      icon: ICON_DESCRIPTION,
      title: 'Lançamentos',
      tabContent: TabCashFlowLaunchs,
    },
  ],
};
