import config from '@src/core/config';
import * as AppUtils from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';

const formatRequest = (pricelist) => {
  pricelist.items.forEach((item) => {
    item.value = parseFloat(item.value);
  });
};

const formatResponse = (pricelist) => {
  pricelist.is_inactive_formatted = pricelist.is_inactive ? 'Sim' : 'Não';
  pricelist.items = pricelist.items || [];
  pricelist.items_quantity = pricelist.items.length;
  pricelist.items.map((product) => {
    product.default_value_formatted = AppUtils.formatCurrency(product.default_value);
    product.value_formatted = AppUtils.formatCurrency(product.value);
  });
};

const PriceList = DynamicService('pricelists', {
  formatRequest,
  formatResponse,
  baseURL: config.API_GATEWAY_FINANCIAL,
});

const PriceListCalc = DynamicService('pricelists/calc', {
  // formatRequest,
  formatResponse,
  baseURL: config.API_GATEWAY_FINANCIAL,
});

const PriceListSearch = DynamicService('pricelists/search', {
  formatRequest,
  formatResponse,
  baseURL: config.API_GATEWAY_FINANCIAL,
});

const PriceListCommands = ($axios) => ({
  async applyListInItems(payload) {
    const res = await $axios.post(`${config.API_GATEWAY_FINANCIAL}/pricelists/apply.json`, payload);
    return res;
  },
  async applyListPriceInOneItem(payload) {
    const { buyer_id, price_list_id, items } = payload;
    const payloadFormatted = {
      buyer_id,
      price_list_id,
      items,
    };
    const res = await $axios.post(`${config.API_GATEWAY_FINANCIAL}/pricelists/apply.json`, payloadFormatted);
    return res;
  },
  async saveValueAsDefaultValue(payload) {
    const res = await $axios.post(`${config.API_GATEWAY_FINANCIAL}/pricelists/save_as_default.json`, payload);
    return res;
  },
  async uploadListPriceFile(payload) {
    const res = await $axios.post(`${config.API_GATEWAY_FINANCIAL}/pricelists/upload.json`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  },
});

export {
  PriceList, PriceListSearch, PriceListCommands, PriceListCalc,
};
export default {
  PriceList, PriceListSearch, PriceListCommands, PriceListCalc,
};
