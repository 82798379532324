import config from '@src/core/config';
import DynamicService from '@sharedServices/dynamic/DynamicService';
import { formatDate, getOptionText } from '@plugins/app-utils';

const Reports = DynamicService('reports', {
  baseURL: config.API_ADMINISTRATION,
});

const endpoint = { stocks: 'stocks' };

const StockReportService = ($axios) => ({
  formatResponseData(data, type = 'list') {
    const format = (item) => {
      item.use_main_formatted = item.use_main === true ? 'Sim' : 'Não';
      if (item.histories) {
        item.histories.forEach((element) => {
          element.origin_formatted = `${element.origin.to_s} - ${element.origin.id}`;
          element.date_launch_formatted = element.date_launch !== null ? formatDate(element.date_launch) : '';
          element.type_launch_formatted = getOptionText('launch_stocks', element.type_launch);
          element.type_transaction_formatted = getOptionText('launch_transaction_types', element.type_transaction);
        });
      }
    };

    let responseData = {};
    if (type === 'list') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return { data };
  },
  async getByFilter(filters, order) {
    const payload = {
      q: filters || {},
    };
    if (order) {
      payload.q.s = order;
    }

    let responseData = {};
    await $axios.post(`${endpoint.stocks}/filters.json`, payload).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },
});

export default { Reports };
export { Reports, StockReportService };
