import SalesRoutes from './pages/sales/routes';
import StockRoutes from './pages/stockItems/routes';
import StockHistoryRoutes from './pages/stockHistory/routes';
import PurchaseRoutes from './pages/purchases/routes';
import { InvoiceRoutes } from './pages/invoices/routes';
import { AccountPaymentRoutes } from './pages/accountPayment/routes';
import { AccountReceivableRoutes } from './pages/accountReceivable/routes';
import { RegistersReportsRoutes } from './pages/Registers/routes';
import { ProductsRoutes } from './pages/products/routes';
import { BanksRoutes } from './pages/banks/routes';

export default [
  ...SalesRoutes,
  ...StockRoutes,
  ...StockHistoryRoutes,
  ...PurchaseRoutes,
  ...InvoiceRoutes,
  ...AccountPaymentRoutes,
  ...AccountReceivableRoutes,
  ...RegistersReportsRoutes,
  ...ProductsRoutes,
  ...BanksRoutes,
];
