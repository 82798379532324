import Vue from 'vue';

export const calculateInvoicesTaxesRealTime = async (items, buyer, is_costumer, operationService) => {
  const service = operationService;
  const itemsData = items.filter((item) => item._destroy !== true).map((item) => {
    let itemBuyerVendor = {
      buyer_id: buyer.register_id,
    };
    if (operationService === 'OrderPurchasesTaxes') {
      itemBuyerVendor = {
        vendor_id: buyer.register_id,
      };
    }
    return {
      ...item,
      product_id: item.product_id ?? item.product.id,
      nature_operation_id: item.nature_operation_id,
      total_without_discount_value: item.total_without_discount_value,
      taxpayer_type: buyer.taxpayer_type,
      is_costumer: Boolean(is_costumer),
      discount_value: parseFloat(item.discount_value) || 0,
      another_value: parseFloat(item.another_value || 0),
      freight_value: parseFloat(item.freight_value || 0),
      ...itemBuyerVendor,
    };
  });
  const itemsCount = itemsData.length;
  if (itemsCount > 0) {
    try {
      const response = await Vue.prototype.$api[service].calculateRealTime({ items: itemsData });
      return response.data;
    } catch (error) {
      console.warn(error);
    }
  }
};

export const calculateOrderPurchaseRealTime = async (items, vendor, is_costumer) => {
  const itemsData = items.map((item) => ({
    ...item,
    product_id: item.product.id,
    nature_operation_id: item.nature_operation_id,
    total_without_discount_value: item.total_without_discount_value,
    taxpayer_type: vendor.taxpayer_type,
    is_costumer: Boolean(is_costumer),
    discount_value: parseFloat(item.discount_value) ?? 0,
    another_value: parseFloat(item.another_value ?? 0),
    freight_value: parseFloat(item.freight_value ?? 0),
    vendor_id: vendor.id,
  }));
  try {
    const response = await Vue.prototype.$api.OrderPurchasesTaxes.calculateRealTime({ items: itemsData });
    return response.data;
  } catch (error) {
    Vue.prototype.$notifyError(error);
  }
};

export const calculateOrderSaleRealTime = async (items, buyer, is_costumer) => {
  const itemsData = items.map((item) => ({
    ...item,
    product_id: item.product.id,
    nature_operation_id: item.nature_operation_id,
    total_without_discount_value: item.total_without_discount_value,
    taxpayer_type: buyer.taxpayer_type,
    is_costumer: Boolean(is_costumer),
    discount_value: parseFloat(item.discount_value) ?? 0,
    another_value: parseFloat(item.another_value ?? 0),
    freight_value: parseFloat(item.freight_value ?? 0),
    buyer_id: buyer.id,
  }));
  try {
    const response = await Vue.prototype.$api.OrderSalesTaxes.calculateRealTime({ items: itemsData });
    return response.data;
  } catch (error) {
    Vue.prototype.$notifyError(error);
  }
};

export const calculateOrderBudgetRealTime = async (items, buyer, is_costumer) => {
  if (items && buyer && items.length > 0) {
    const itemsData = items.map((item) => ({
      ...item,
      product_id: item.product.id,
      nature_operation_id: item.nature_operation_id,
      total_without_discount_value: item.total_without_discount_value,
      taxpayer_type: buyer.taxpayer_type,
      is_costumer: Boolean(is_costumer),
      discount_value: parseFloat(item.discount_value) ?? 0,
      another_value: parseFloat(item.another_value ?? 0),
      freight_value: parseFloat(item.freight_value ?? 0),
      buyer_id: buyer.id,
    }));
    try {
      const response = await Vue.prototype.$api.OrderBudgetsTaxes.calculateRealTime({ items: itemsData });
      return response.data;
    } catch (error) {
      Vue.prototype.$notifyError(error);
    }
  }
};

export const calculateConsignmentRealTime = async (items, buyer, is_costumer) => {
  if (items && buyer && items.length > 0) {
    const itemsData = items.map((item) => ({
      ...item,
      buyer_id: buyer.id,
      product_id: item.product.id,
      nature_operation_id: item.nature_operation_id,
      total_without_discount_value: item.total_without_discount_value,
      taxpayer_type: buyer.taxpayer_type,
      is_costumer: Boolean(is_costumer),
      discount_value: parseFloat(item.discount_value) ?? 0,
      another_value: parseFloat(item.another_value ?? 0),
      freight_value: parseFloat(item.freight_value ?? 0),
    }));
    try {
      const response = await Vue.prototype.$api.RemittanceConsignmentsTaxes.calculateRealTime({ items: itemsData });
      return response.data;
    } catch (error) {
      Vue.prototype.$notifyError(error);
    }
  }
};
export const calculateDevolutionConsignmentRealTime = async (items, buyer, is_costumer) => {
  if (items && buyer && items.length > 0) {
    const itemsData = items.map((item) => ({
      ...item,
      buyer_id: buyer.id,
      product_id: item.product.id,
      nature_operation_id: item.nature_operation_id,
      total_without_discount_value: item.total_without_discount_value,
      taxpayer_type: buyer.taxpayer_type,
      is_costumer: Boolean(is_costumer),
      discount_value: parseFloat(item.discount_value ?? 0),
      another_value: parseFloat(item.another_value ?? 0),
      freight_value: parseFloat(item.freight_value ?? 0),
    }));
    try {
      const response = await Vue.prototype.$api.DevolutionConsignmentsTaxes.calculateRealTime({ items: itemsData });
      return response.data;
    } catch (error) {
      Vue.prototype.$notifyError(error);
    }
  }
};

export const calculateIndustrializationRealTime = async (items, buyer, is_costumer) => {
  if (items && buyer && items.length > 0) {
    const itemsData = items.map((item) => ({
      ...item,
      buyer_id: buyer.id,
      product_id: item.product.id,
      nature_operation_id: item.nature_operation_id,
      total_without_discount_value: item.total_without_discount_value,
      taxpayer_type: buyer.taxpayer_type,
      is_costumer: Boolean(is_costumer),
      discount_value: parseFloat(item.discount_value) ?? 0,
      another_value: parseFloat(item.another_value ?? 0),
      freight_value: parseFloat(item.freight_value ?? 0),
    }));
    try {
      const response = await Vue.prototype.$api.RemittanceIndustrializationTaxes.calculateRealTime({ items: itemsData });
      return response.data;
    } catch (error) {
      Vue.prototype.$notifyError(error);
    }
  }
};
export const calculateDevolutionIndustrializationRealTime = async (items, buyer, is_costumer) => {
  if (items && buyer && items.length > 0) {
    const itemsData = items.map((item) => ({
      ...item,
      buyer_id: buyer.id,
      product_id: item.product.id,
      nature_operation_id: item.nature_operation_id,
      total_without_discount_value: item.total_without_discount_value,
      taxpayer_type: buyer.taxpayer_type,
      is_costumer: Boolean(is_costumer),
      discount_value: parseFloat(item.discount_value ?? 0),
      another_value: parseFloat(item.another_value ?? 0),
      freight_value: parseFloat(item.freight_value ?? 0),
    }));
    try {
      const response = await Vue.prototype.$api.DevolutionIndustrializationTaxes.calculateRealTime({ items: itemsData });
      return response.data;
    } catch (error) {
      Vue.prototype.$notifyError(error);
    }
  }
};

export const calculatePartialPurchaseRealTime = async (items, vendor, is_costumer) => {
  const itemsData = items.map((item) => ({
    product_id: item.product.id,
    nature_operation_id: item.nature_operation_id,
    total_without_discount_value: item.total_without_discount_value,
    taxpayer_type: vendor.taxpayer_type,
    is_costumer: Boolean(is_costumer),
    discount_value: parseFloat(item.discount_value) ?? 0,
    another_value: parseFloat(item.another_value ?? 0),
    freight_value: parseFloat(item.freight_value ?? 0),
    vendor_id: vendor.id,
  }));
  try {
    const response = await Vue.prototype.$api.PartialPurchasesTaxes.calculateRealTime({ items: itemsData });
    return response.data;
  } catch (error) {
    Vue.prototype.$notifyError(error);
  }
};
export const calculatePartialSaleRealTime = async (items, vendor, is_costumer) => {
  const itemsData = items.map((item) => ({
    product_id: item.product.id,
    nature_operation_id: item.nature_operation_id,
    total_without_discount_value: item.total_without_discount_value,
    taxpayer_type: vendor.taxpayer_type,
    is_costumer: Boolean(is_costumer),
    discount_value: parseFloat(item.discount_value) ?? 0,
    another_value: parseFloat(item.another_value ?? 0),
    freight_value: parseFloat(item.freight_value ?? 0),
    vendor_id: vendor.id,
  }));
  try {
    const response = await Vue.prototype.$api.PartialSalesTaxes.calculateRealTime({ items: itemsData });
    return response.data;
  } catch (error) {
    Vue.prototype.$notifyError(error);
  }
};
