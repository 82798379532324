export default [
  // Line 1
  {
    label: 'Base de Cálculo do ICMS',
    name: 'tax.total_icms_base',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 3,
    colClass: 'nf-field top left',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Valor do ICMS',
    name: 'tax.total_icms_value',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 2,
    colClass: 'nf-field top left',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Base de Cálculo do ICMS Subst.',
    name: 'tax.total_icms_st_base',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 2,
    colClass: 'nf-field top left',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Valor do ICMS Subst.',
    name: 'tax.total_icms_st_value',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 2,
    colClass: 'nf-field top left',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Valor Total dos Produtos',
    name: 'total_products',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 3,
    colClass: 'nf-field top left right',
    prependIcon: '',
    readOnly: true,
  },
  // Linha 2
  {
    label: 'Valor do Frete',
    name: 'total_freight',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 2,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Valor do Seguro',
    name: 'total_safe',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 2,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Desconto',
    name: 'total_discount',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 1,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Out. Desp. Acessórias',
    name: 'expense_value',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 2,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Tributos Aproximados',
    name: 'total_tax_approximates',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 2,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Valor do IPI',
    name: 'tax.total_ipi_value',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 1,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Total da Nota Fiscal',
    name: 'total_note',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 2,
    colClass: 'nf-field top left bottom right',
    prependIcon: '',
    readOnly: true,
  },
];
