export default {
  service: 'AccountsStatement',
  icon: '',
  routes: {
    list: {
      path: '/digitalbank/accounts/statement/:id',
      name: 'digitalbank-accountsstatement-list',
    },
  },
};
