import { ICON_MEASURING_UNIT } from '@constants/icons';

export default {
  service: 'MeasuringUnits',
  icon: ICON_MEASURING_UNIT,
  routes: {
    list: 'measuring-units-list',
    edit: 'measuring-units-edit',
    create: 'measuring-units-create',
  },
  titles: {
    item: 'Unidade de medida',
    items: 'Unidades de medida',
    edit: 'Editar unidade de medida',
    create: 'Criar unidade de medida',
    new: 'Nova unidade de medida',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'measuring_units/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
    },
    {
      label: 'Descrição',
      name: 'description',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
    },
  ],
};
