import { formatCnpjCpf, onlyNumbers } from '@plugins/app-utils';

const endpoint = 'invoice_settings';

export default ($axios) => ({
  /**
   * Get all objects
   */
  async upload(file, pass, type) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('read', pass);
    formData.append('type', type);

    const res = await $axios.post(`${endpoint}/upload.json`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res;
  },
  /**
   * Create digital certificate
   */
  async create(payload) {
    const payloadFormatted = {
      ...payload,
      cpf: onlyNumbers(payload.cpf),
      cnpj: onlyNumbers(payload.cnpj),
      telephone: onlyNumbers(payload.telephone),
      other_telephone: onlyNumbers(payload.other_telephone),
    };
    const res = await $axios.post(`${endpoint}/digital_certificate.json`, { digital_certificate: payloadFormatted });
    return res;
  },

  async getUserInfoToRegisterDigitalCertificate() {
    const responseData = {};
    await $axios.get('users/me.json').then((response) => {
      responseData.name = response.data.customer.name;
      responseData.email = response.data.customer.email;
      responseData.telephone = response.data.customer.telephone;
      responseData.with_digital_certificate = response.data.company.with_request_certificate || false;
      responseData.cpf = formatCnpjCpf(response.data.customer.cpnj_cpf);
      responseData.cnpj = formatCnpjCpf(response.data.company.cnpj);
    });
    return responseData;
  },
  async existsCertificate() {
    const res = await $axios.get(`${endpoint}/exist_certificate_base64`);
    return res;
  },
});
