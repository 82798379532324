import ProductSchema from '@schemas/Product';
import ProductCompositionSchema from '@schemas/ProductComposition';
import ProductCostsSchema from '@schemas/ProductCosts';

const Product = () => import(/* webpackChunkName: "products-list" */'./ListProducts');
const ProductDetail = () => import(/* webpackChunkName: "products-detail" */'./DetailProducts');

const DetailProductsComposition = () => import(/* webpackChunkName: "detail-products-composition" */'./composition/DetailProductsComposition');

const DetailProductCosts = () => import(/* webpackChunkName: "detail-products-costs" */'./costs/DetailProductsCosts');

export default [
  {
    path: ProductSchema.routes.list.path,
    name: ProductSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Produtos e Serviços' },
      ],
    },
    component: Product,
  },
  {
    path: ProductSchema.routes.create.path,
    name: ProductSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Produtos e Serviços', route: ProductSchema.routes.list.name },
        { name: 'Criar' },
      ],
    },
    component: ProductDetail,
  },
  {
    path: ProductSchema.routes.edit.path,
    name: ProductSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Produtos e Serviços', route: ProductSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: ProductDetail,
  },
  {
    path: ProductCompositionSchema.routes.edit.path,
    name: ProductCompositionSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Produtos e Serviços', route: ProductSchema.routes.list.name },
        { name: 'Editar Composição' },
      ],
    },
    component: DetailProductsComposition,
  },
  {
    path: ProductCostsSchema.routes.edit.path,
    name: ProductCostsSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Produtos e Serviços', route: ProductCostsSchema.routes.edit.name },
        { name: 'Custos de Produção' },
      ],
    },
    component: DetailProductCosts,
  },
];
