<template>
  <v-list-group
    v-model="expanded"
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    :append-icon="item.initialExpanded ? icons.menuUp : icons.menuDown"
    no-action
    class="mb-2"
    color="indigo lighten-1"
    @click="onGroupClick">
    <template v-slot:activator>
      <v-list-item-icon v-if="!item.icon && !item.avatar" class="text-caption text-uppercase text-center my-2 align-self-center">
        {{ title }}
      </v-list-item-icon>

      <v-list-item-avatar v-if="item.avatar">
        <v-img :src="item.avatar" />
      </v-list-item-avatar>

      <v-list-item-title v-if="item.title" class="ml-n4 item-title">
        {{ item.title }}
      </v-list-item-title>
    </template>

    <template v-for="child in item.items">
      <ListGroup v-if="child.items"
                 :key="child.title"
                 class="py-1 mb-2"
                 active-class="indigo lighten-1"
                 sub-group
                 :item="child" />

      <ListItem v-if="!child.items" :key="child.title" :item="child" :child="true" />
    </template>
  </v-list-group>
</template>

<script>
import { mapState } from 'vuex';
import { ICON_MENU_DOWN, ICON_MENU_UP } from '@constants/icons';
import ListItem from './ListItem';

export default {
  name: 'ListGroup',

  components: {
    ListItem,
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        menuDown: ICON_MENU_DOWN,
        menuUp: ICON_MENU_UP,
      },
      expanded: this.item.initialExpanded,
    };
  },
  computed: {
    ...mapState({
      gradient: (state) => state.user.drawer.gradient,
    }),
    title() {
      const matches = this.item.title.match(/\b(\w)/g);

      return matches.join('');
    },
  },
  methods: {
    onGroupClick() {
      this.$emit('click', this.item);
    },
  },
};
</script>
