import { formatDate, currencyWithSign } from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';

const endpoint = 'banks';
const endpoint_simplified = 'banks_simplified';

const formatResponse = (item) => {
  item.date_opening = item?.date_opening !== null ? formatDate(item.date_opening) : '';
  item.date_safety = item?.date_safety !== null ? formatDate(item.date_safety) : '';
  item.use_nickname_formatted = item?.use_nickname === false ? 'Não' : 'Sim';
  item.inactive_formatted = item?.inactive === false ? 'Não' : 'Sim';
  item.use_cash_flow_formatted = item?.use_cash_flow === false ? 'Não' : 'Sim';
  item.use_digitalbank_integration_formatted = item?.use_digitalbank_integration === false ? 'Não' : 'Sim';
  let balanceSign = item?.balance > 0 ? '' : ' -';
  if (item?.balance === 0) balanceSign = '';
  item.balance_formatted = currencyWithSign(item?.balance || 0, balanceSign);
  return item;
};

const Banks = DynamicService(endpoint, {
  formatResponse,
  formatRequest: (item) => item,
});

const BanksSimplified = DynamicService(endpoint_simplified, {
  formatResponse,
  formatRequest: (item) => item,
});

export { Banks, BanksSimplified };
export default { Banks, BanksSimplified };
