import { ICON_BANK } from '@constants/icons';

export default {
  service: 'Registers',
  icon: ICON_BANK,
  routes: {
    list: { name: 'financial-banks-list', path: '/banks' },
    launches: { name: 'financial-banks-newlaunch', path: '/financial/banks/newlaunch/:id' },
  },
  titles: {
    item: 'Banco',
    items: 'Bancos',
    list: 'Listar Bancos',
    edit: 'Editar banco',
    create: 'Criar banco',
    new: 'Novo',
  },
  fields: [
    {
      label: 'Descrição',
      name: 'description',
      type: 'text',
      list: true,
    },
    {
      label: 'Código',
      name: 'code',
      type: 'text',
      list: true,
    },
    {
      label: 'Apelido',
      name: 'use_nickname',
      type: 'boolean',
    },
    {
      label: 'Ativo',
      name: 'inactive',
    },
    {
      label: 'Número do Banco',
      name: 'bank_number',
    },
    {
      label: 'Agência',
      name: 'agency',
    },
    {
      label: 'Conta',
      name: 'account',
    },
    {
      label: 'Data de Abertura',
      name: 'date_opening',
    },
    {
      label: 'Permitir Lançamentos Após',
      name: 'date_safety',
    },
    {
      label: 'Observações',
      name: 'observation',
    },

  ],
};
