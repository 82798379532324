import DevolutionConsignmentschema from '@schemas/DevolutionConsignment';
import RemittanceConsignmentschema from '@schemas/RemittanceConsignment';
import TransactionConsignmentschema from '@schemas/TransactionConsignment';

const ListRemittanceConsignments = () => import(/* webpackChunkName: "list-remittance-consignments" */'./ListRemittanceConsignments');
const DetailRemittanceConsignment = () => import(/* webpackChunkName: "detail-remittance-consignments" */'./DetailRemittanceConsignments');
const ListDevolutionConsignments = () => import(/* webpackChunkName: "list-devolution-consignments" */'./ListDevolutionConsignments');
const DetailDevolutionConsignment = () => import(/* webpackChunkName: "detail-devolution-consignments" */'./DetailDevolutionConsignments');
const ListTransactionConsignments = () => import(/* webpackChunkName: "list-transaction-consignments" */'./ListTransactionConsignments');

export default [
  {
    path: RemittanceConsignmentschema.routes.list.path,
    name: RemittanceConsignmentschema.routes.list.name,
    meta: {
      requiresAuth: true,
      // @TODO - change rule when it created on backend
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: RemittanceConsignmentschema.titles.items },
      ],
    },
    component: ListRemittanceConsignments,
  },
  {
    path: RemittanceConsignmentschema.routes.create.path,
    name: RemittanceConsignmentschema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: RemittanceConsignmentschema.titles.items, route: RemittanceConsignmentschema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailRemittanceConsignment,
  },
  {
    path: RemittanceConsignmentschema.routes.edit.path,
    name: RemittanceConsignmentschema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: RemittanceConsignmentschema.titles.items, route: RemittanceConsignmentschema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailRemittanceConsignment,
  },
  {
    path: DevolutionConsignmentschema.routes.list.path,
    name: DevolutionConsignmentschema.routes.list.name,
    meta: {
      requiresAuth: true,
      // @TODO - change rule when it created on backend
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionConsignmentschema.titles.items },
      ],
    },
    component: ListDevolutionConsignments,
  },
  {
    path: DevolutionConsignmentschema.routes.create.path,
    name: DevolutionConsignmentschema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionConsignmentschema.titles.items, route: DevolutionConsignmentschema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    props: true,
    component: DetailDevolutionConsignment,
  },
  {
    path: DevolutionConsignmentschema.routes.edit.path,
    name: DevolutionConsignmentschema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionConsignmentschema.titles.items, route: DevolutionConsignmentschema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailDevolutionConsignment,
  },
  {
    path: TransactionConsignmentschema.routes.list.path,
    name: TransactionConsignmentschema.routes.list.name,
    meta: {
      requiresAuth: true,
      // @TODO - change rule when it created on backend
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: TransactionConsignmentschema.titles.items },
      ],
    },
    component: ListTransactionConsignments,
  },
];
