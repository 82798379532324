import AlltypesSchema from '@schemas/Alltypes';

export default [
  {
    path: AlltypesSchema.routes.listPrices.list.path,
    name: AlltypesSchema.routes.listPrices.list.name,
    meta: AlltypesSchema.routes.listPrices.list.meta,
    component: () => import(/* webpackChunkName: 'list-list-prices' */ `${AlltypesSchema.routes.listPrices.list.component}`),
  },
  {
    path: AlltypesSchema.routes.listPrices.create.path,
    name: AlltypesSchema.routes.listPrices.create.name,
    meta: AlltypesSchema.routes.listPrices.create.meta,
    component: () => import(/* webpackChunkName: 'create-list-prices' */ `${AlltypesSchema.routes.listPrices.create.component}`),
  },
  {
    path: AlltypesSchema.routes.listPrices.edit.path,
    name: AlltypesSchema.routes.listPrices.edit.name,
    meta: AlltypesSchema.routes.listPrices.edit.meta,
    component: () => import(/* webpackChunkName: 'edit-list-prices' */ `${AlltypesSchema.routes.listPrices.edit.component}`),
  },
];
