import {
  ICON_PRODUCT,
} from '@constants/icons';
import ProductCostItem from './ProductCostItem';
import ProductCostProcedure from './ProductCostProcedure';
import ProductCostBusiness from '@businesses/ProductCost';

export default {
  service: 'ProductCost',
  business: ProductCostBusiness,
  hideSaveButton: true,
  hideReturnButton: true,
  hideActionsMenu: true,
  icon: ICON_PRODUCT,
  routes: {
    edit: {
      path: '/supply/products/costs/:id',
      name: 'supply-products-editcosts',
    },
  },
  titles: {
    item: 'Custo de Produção',
    items: 'Custos de Produção',
    create: 'Validar Custo de Produção',
    new: 'Novo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'products/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'Usar custos do produto',
      name: 'from_product',
      formattedName: 'from_product_formatted',
      type: 'boolean',
      list: true,
      component: 'dynamic-checkbox',
      md: 3,
    },
  ],
  childSchemas: [
    {
      label: 'Matéria-Prima',
      name: 'compositions',
      schema: ProductCostItem,
      noCreate: true,
      noEdit: true,
      noDelete: true,
    },
    {
      label: 'Procedimento de Fabricação',
      name: 'procedures',
      schema: ProductCostProcedure,
      noCreate: true,
      noEdit: true,
      noDelete: true,
    },
  ],
};
