import { ICON_KEY_LINK } from '@constants/icons';
import { OPTIONS_BTG_ACCOUNT_TYPE, OPTIONS_BTG_ACCOUNT_CURRENCY, OPTIONS_BTG_BILLET_STATUS } from '@constants/options';

export default {
  // service: 'IntegrationsBtg',
  icon: ICON_KEY_LINK,
  noCreate: true,
  noEdit: true,
  noDelete: true,
  disabled: true,
  canCancel: true,
  hideFilters: true,
  useExternalItems: true,
  routes: {
    list: {
      name: 'integrations-btg-billets',
    },
  },
  titles: {
    item: 'Boleto BTG',
    items: 'Boletos BTG',
    list: 'Listar Boletos',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'Status',
      name: 'status',
      formattedName: 'status_formatted',
      type: 'select',
      component: 'dynamic-select',
      items: OPTIONS_BTG_BILLET_STATUS,
      options_key: 'btg_billet_status',
      required: true,
      list: true,
      md: 3,
    },
    {
      label: 'ID do Boleto',
      name: 'bank_slip_id',
      type: 'text',
      // list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 8,
    },
    {
      label: 'ID de Correlação',
      name: 'correlation_id',
      type: 'text',
      // list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Data Criação',
      name: 'created_at',
      // formattedName: 'start_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      // list: true,
      md: 2,
    },
    {
      label: 'Data Atualização',
      name: 'updated_at',
      // formattedName: 'start_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      // list: true,
      md: 2,
    },
    {
      label: 'Data Vencimento',
      name: 'due_date',
      // formattedName: 'start_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
      md: 2,
    },
    {
      label: 'Data Pagamento',
      name: 'paid_at',
      // formattedName: 'start_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
      md: 2,
    },
    {
      label: 'Valor',
      name: 'amount',
      type: 'text',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Nome do Sacado',
      name: 'payer.name',
      type: 'text',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Email do Sacado',
      name: 'payer.email',
      type: 'text',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'CPF do Sacado',
      name: 'payer.tax_id',
      type: 'text',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Telefone do Sacado',
      name: 'payer.phone_number',
      type: 'text',
      // list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Referência',
      name: 'reference_number',
      type: 'text',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Linha digitável',
      name: 'digitable_line',
      type: 'text',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Código de Barras',
      name: 'bar_code',
      type: 'text',
      // list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Código do Banco',
      name: 'bank_code',
      type: 'text',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Tipo',
      name: 'type',
      // formattedName: 'type_formatted',
      type: 'select',
      component: 'dynamic-select',
      items: OPTIONS_BTG_ACCOUNT_TYPE,
      options_key: 'btg_account_type',
      required: true,
      // list: true,
      md: 3,
    },
    {
      label: 'Moeda',
      name: 'currency',
      // formattedName: 'currency_formatted',
      type: 'select',
      component: 'dynamic-select',
      items: OPTIONS_BTG_ACCOUNT_CURRENCY,
      options_key: 'btg_account_currency',
      required: true,
      // list: true,
      md: 3,
    },
    {
      label: 'Código Branch',
      name: 'branch_code',
      type: 'text',
      // list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Número',
      name: 'number',
      type: 'text',
      // list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
  ],
  // actions: [
  // {
  //   type: 'route',
  //   icon: ICON_APPLICATION_IMPORT,
  //   route: 'supply-stocks-items',
  //   ariaLabel: 'Itens',
  //   color: 'secondary',
  // },
  // {
  //   type: 'route',
  //   icon: ICON_HISTORIC,
  //   color: 'secondary',
  //   route: 'supply-stockhistories-launchs',
  //   target: '_blank',
  //   ariaLabel: 'Histórico',
  // },
  // ],
  // tabs: [
  //   {
  //     icon: ICON_STOCK,
  //     title: 'Itens no Estoque',
  //     tabContent: TabStock,
  //   },
  // ],
};
