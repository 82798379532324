const ListCompanyPreferences = () => import(/* webpackChunkName: "list-company-preferences" */ './ListCompanyPreferences');

export default [
  {
    path: '/settings/company/preferences',
    name: 'settings-company-listpreferences',
    meta: {
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: 'Empresas' },
      ],
    },
    component: ListCompanyPreferences,
  },
];
