<template>
  <div :id="item.id">
    <GenericCardPanel
      bg-color="white"
      title="INFORMAÇÕES DO CADASTRO"
      :fields="registerFields" />
    <GenericCardPanel
      title="DADOS DE TRIBUTAÇÃO"
      bg-color="white"
      class="mt-3"
      :fields="taxFields" />
    <br>
  </div>
</template>

<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabRegisters',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      registerFields: [],
      taxFields: [],
    };
  },
  mounted() {
    this.registerFields = [
      { label: 'CNPJ/CPF', value: this.item.cnpj_cpf_formatted, col: 4 },
      { label: 'Nome', value: this.item.name, col: 4 },
      { label: 'Tipo de cadastro', value: this.item.type_register_formatted, col: 4 },
      { label: 'Código', value: this.item.code, col: 4 },
      { label: 'IE/RG', value: this.item.ie_rg, col: 4 },
      { label: 'Apelido/Nome Fantasia', value: this.item.nickname, col: 4 },
      { label: 'Inscrição municipal', value: this.item.im, col: 4 },
      { label: 'Suframa', value: this.item.suframa, col: 4 },
      { label: 'Data de cadastro', value: this.item.date_register, col: 4 },
    ];
    this.taxFields = [
      { label: 'Regime de tributação', value: this.item.tax_regime_formatted, col: 4 },
      { label: 'Tipo de contribuinte', value: this.item.taxpayer_type_formatted, col: 8 },
      { label: 'Tipo de avaliação', value: this.item.evaluation.to_s, col: 4 },
      { label: 'Tipo', value: this.item.type.to_s, col: 4 },
      { label: 'Tipo de ocupação', value: this.item.occupation.to_s, col: 4 },
    ];
  },
  methods: {
    print(id) {
      this.$printer(id, {
        title: {
          value: '<h2>Informações de Cadastro</h2>',
          align: 'center',
        },
      });
    },
  },
};
</script>

<style scoped>
.printer-text-section{
  font-size: 20px;

}
</style>
