import { ICON_HISTORIC } from '@constants/icons';

export default {
  service: 'StockHistories',
  icon: ICON_HISTORIC,
  routes: {
    list: 'supply-stockhistories-list',
    edit: 'supply-stockhistories-edit',
    create: 'supply-stockhistories-create',
    launches: 'supply-stockhistories-launchs',
  },
  titles: {
    item: 'Histórico de Estoque',
    items: 'Histórico dos Estoques',
    edit: 'Editar Histórico do Estoque',
    create: 'Criar Histórico do Estoque',
    launch: 'Criar Lançamento',
    new: 'Novo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'Data',
      name: 'date_launch',
      formattedName: 'date_launch_formatted',
      type: 'string',
      list: true,
    },
    {
      label: 'Origem',
      name: 'origin',
      formattedName: 'origin_formatted',
      type: 'string',
      list: true,
    },
    {
      label: 'Tipo',
      name: 'type_launch',
      formattedName: 'type_launch_formatted',
      component: 'dynamic-select',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            query: ['id', 'code', 'to_s'],
          },
          'name asc',
        ],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 8,
    },
    {
      label: 'Código',
      name: 'product.code',
      type: 'text',
      columnAlign: 'center',
      list: true,
    },
    {
      label: 'Descrição',
      name: 'description',
      formattedName: 'description_formatted',
      type: 'string',
      list: true,
    },
    {
      label: 'Saldo Anterior',
      name: 'balance',
      type: 'string',
      columnAlign: 'center',
      list: true,
    },
    {
      label: 'Movimentação',
      name: 'quantity',
      type: 'string',
      list: true,
    },
    {
      label: 'Saldo Atual',
      name: 'current_balance',
      type: 'string',
      columnAlign: 'center',
      colClass: 'justify-center',
      list: true,
    },
    {
      label: 'Usuário',
      name: 'user.to_s',
      type: 'string',
      list: true,
    },
  ],
};
