const endpoint = 'dashboards';

export default ($axios) => ({
  /** get all objects */
  async buyersQuantity(payload) {
    const payloadFormatted = {
      filters: [
        {
          emission_date: {
            start: payload?.startDate ? payload.startDate : '01/01/2021',
            end: payload?.endDate ? payload.endDate : '31/12/2021',
          },
        },
      ],
    };

    let responseData = {};
    await $axios.post(`${endpoint}/buyers_quantity.json`, payloadFormatted).then((response) => {
      responseData = response;
    });
    return responseData;
  },
  async comparativeBankLaunchs(id, payload) {
    const payloadFormatted = {
      filters: [
        {
          launch_date: {
            start: payload?.startDate ? payload.startDate : '01/01/2021',
            end: payload?.endDate ? payload.endDate : '31/12/2021',
          },
        },
      ],
    };

    let responseData = {};
    await $axios.post(`${endpoint}/comparative_bank_launch/${id}.json`, payloadFormatted).then((response) => {
      responseData = response;
    });
    return responseData;
  },
  async salesmanQuantity(payload) {
    const payloadFormatted = {
      filters: [
        {
          emission_date: {
            start: payload?.startDate ? payload.startDate : '01/01/2021',
            end: payload?.endDate ? payload.endDate : '31/12/2021',
          },
        },
      ],
    };

    let responseData = {};
    await $axios.post(`${endpoint}/salesmen_quantity.json`, payloadFormatted).then((response) => {
      responseData = response;
    });
    return responseData;
  },
  async productsQuantity(payload) {
    const payloadFormatted = {
      filters: [
        {
          emission_date: {
            start: payload?.startDate ? payload.startDate : '01/01/2021',
            end: payload?.endDate ? payload.endDate : '31/12/2021',
          },
        },
      ],
    };

    let responseData = {};
    await $axios.post(`${endpoint}/products_quantity.json`, payloadFormatted).then((response) => {
      responseData = response;
    });
    return responseData;
  },
  async comparativeSalesPurchase(payload) {
    const payloadFormatted = {
      filters: [
        {
          emission_date: {
            start: payload?.startDate ? payload.startDate : '01/01/2021',
            end: payload?.endDate ? payload.endDate : '31/12/2021',
          },
        },
      ],
    };

    let responseData = {};
    await $axios.post(`${endpoint}/comparative_sale_purchase.json`, payloadFormatted).then((response) => {
      responseData = response;
    });
    return responseData;
  },
  async comparativePaymentsReceivables(payload) {
    const payloadFormatted = {
      filters: [
        {
          discharge_date: {
            start: payload?.startDate ? payload.startDate : '01/01/2021',
            end: payload?.endDate ? payload.endDate : '31/12/2021',
          },
        },
      ],
    };

    let responseData = {};
    await $axios.post(`${endpoint}/comparative_receivable_payment.json`, payloadFormatted).then((response) => {
      responseData = response;
    });
    return responseData;
  },
  async comparativeOpenTitles(payload) {
    const payloadFormatted = {
      filters: [
        {
          emission_date: {
            start: payload?.startDate ? payload.startDate : '01/01/2021',
            end: payload?.endDate ? payload.endDate : '31/12/2021',
          },
        },
      ],
    };

    let responseData = {};
    await $axios.post(`${endpoint}/comparative_open_title.json`, payloadFormatted).then((response) => {
      responseData = response;
    });
    return responseData;
  },
});
