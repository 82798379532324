<template>
  <div class="load-page justify-center align-center">
    <div v-if="loading" class="report-loading">
      <v-progress-circular indeterminate
                           :height="16"
                           :width="2"
                           color="primary" />
      <h3 class="indigo--text text--lighten-1">
        Carregando dependências
      </h3>
    </div>
    <div v-else>
      <h3 class="indigo--text text--lighten-1">
        Erro ao carregar dependências
      </h3>
      <v-btn block class="dm-page-button" color="primary" @click="tryAgain">
        Tentar novamente
      </v-btn>
    </div>
  </div>
</template>

<script>
import config from '@src/core/config';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    requireDesigner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      loadedModules: {},
    };
  },
  created() {
    this.loadActiveReports();
  },
  methods: {
    loadActiveReports() {
      this.loading = true;
      const GC = window.GC || {};
      const ActiveReports = GC.ActiveReports || {};
      if (!ActiveReports.Core) {
        this.installScript('core');
      } else {
        this.activeReportsLoaded();
      }
    },
    installScript(name) {
      const baseScriptId = 'active-reports-script';
      const script = document.createElement('script');
      script.id = `${baseScriptId}-${name}`;
      script.setAttribute('src', `https://cdn.grapecity.com/activereportsjs/3.latest/dist/ar-js-${name}.js`);
      script.addEventListener('load', () => { this.activeReportsLoaded(name); }, false);
      script.addEventListener('error', () => { this.loading = false; }, false);
      document.head.appendChild(script);
    },
    tryAgain() {
      this.loadActiveReports();
    },
    activeReportsLoaded() {
      const GC = window.GC || {};
      const ActiveReports = GC.ActiveReports || {};
      ActiveReports.Core.setLicenseKey(config.ACTIVE_REPORTS_KEY);

      if (!ActiveReports.ReportViewer) {
        this.installScript('viewer');
      }

      if (this.requireDesigner && !ActiveReports.ReportDesigner) {
        this.installScript('designer');
      }

      this.$nextTick(() => {
        const allLoaded = !!ActiveReports.Core && !!ActiveReports.ReportViewer && (this.requireDesigner ? !!ActiveReports.ReportDesigner : true);
        this.$emit('input', allLoaded);
        if (allLoaded === true) {
          this.$emit('loaded');
        }
      });
    },
  },
};
</script>

<style lang="scss">
.load-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .report-loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
</style>
