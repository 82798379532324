/* eslint-disable */
import Vue from 'vue';
import { calculateTotals, calculateTotalsInvoice } from './CalculateTotals';
import { formatCurrency, formatNumber } from '@plugins/app-utils';

export const applyGlobalDiscount = (formValue, discount, usePercent = false) => {
  const totalProducts = formValue.total_products ?? formValue.total_items;
  if (totalProducts === 0) {
    return;
  }
  const totalDiscount = usePercent ? (totalProducts * discount) / 100 : discount;
  
  formValue.items = formValue.items || [];
  const itemsFormatted = formValue.items.map((item) => {
    const totalItem = (item.quantity || 0) * (item.unit_value || 0);
    const discountValue = parseFloat((totalDiscount * (totalItem / totalProducts)).toFixed(2));
    const discountValueFormatted = formatCurrency(totalDiscount * (totalItem / totalProducts));
    const discountPercent = parseFloat((totalItem > 0 ? (discountValue / totalItem) * 100 : 0).toFixed(2));
    const discountPercentageFormatted = `${formatNumber(discountPercent)}%`;
    
    return {
      ...item,
      discount_value: discountValue,
      discount_value_formatted: discountValueFormatted,
      discount_percentage_value: discountPercent,
      discount_percentage_formatted: discountPercentageFormatted,
      total_value: totalItem,
      total_value_formatted: formatCurrency(totalItem),
    };
  });
  formValue.items = itemsFormatted;
  Vue.set(formValue, 'items', formValue.items);

  formValue.total_discount = totalDiscount;
  formValue.total_discount_formatted = formatCurrency(totalDiscount);

  calculateTotals(formValue);
};

export const applyGlobalDiscountInvoice = (formValue, discount, usePercent = false) => {
  const totalProducts = formValue.total_products ?? formValue.total_items;
  if (totalProducts === 0) {
    return;
  }
  const totalDiscount = usePercent ? (totalProducts * discount) / 100 : discount;
  
  formValue.items = formValue.items || [];

  const itemsFormatted = formValue.items.map((item) => {
    const unit = parseFloat(item.unit_value) || 0;
    const totalItem = (item.comercial_quantity ?? 0) * (item.unit_value ?? 0);
    const discountValue = parseFloat((totalDiscount * (totalItem / totalProducts)).toFixed(2));
    const discountValueFormatted = formatCurrency(totalDiscount * (totalItem / totalProducts));
    const discountPercent = parseFloat((totalItem > 0 ? (discountValue / totalItem) * 100 : 0));
    const discountPercentageFormatted = `${formatNumber(discountPercent)}%`;
    const totalWithDiscount = totalItem - discountValue;
    
    return {
      ...item,
      unit_value: unit,
      discount_value: discountValue,
      discount_value_formatted: discountValueFormatted,
      discount_percentage_value: discountPercent,
      discount_percentage_value_formatted: discountPercentageFormatted,
      total_without_discount_value: totalItem,
      total_value: totalWithDiscount,
      total_value_formatted: formatCurrency(parseFloat(totalWithDiscount)),
    };
  });
  formValue.items = itemsFormatted;
  
  Vue.set(formValue, 'items', formValue.items);
  formValue.total_discount = totalDiscount;
  formValue.total_discount_formatted = formatCurrency(totalDiscount);
  Vue.set(formValue, 'total_discount', formValue.total_discount);
  Vue.set(formValue, 'total_discount_formatted', formValue.total_discount_formatted);
  calculateTotalsInvoice(formValue);
};

