export default [
  {
    label: 'Status',
    formattedName: 'status',
    name: 'status',
    type: 'select',
    options_key: 'invoice_status',
    list: true,
  },
  {
    label: 'Modelo',
    name: 'model',
    type: 'text',
    list: false,
  },
  {
    label: 'Tipo',
    name: 'document_type',
    formattedName: 'document_type_formatted',
    type: 'text',
    list: true,
    noFilter: true,
  },
  {
    label: 'Série',
    name: 'series',
    type: 'text',
    list: false,
  },
  {
    label: 'NFe',
    name: 'invoice_number',
    type: 'number',
    list: true,
  },
  {
    label: 'Pedido',
    name: 'invoiceable_order',
    type: 'text',
    list: true,
  },
  {
    label: 'Emissão',
    name: 'emission_date',
    formattedName: 'emission_date_formatted',
    type: 'date',
    list: true,
  },
  {
    label: 'Saída',
    name: 'exit_date',
    formattedName: 'exit_date_formatted',
    type: 'date',
    list: false,
  },
  {
    label: 'Cliente',
    name: 'buyer.to_s',
    filterName: 'buyer_name_or_buyer_fantasy',
    type: 'text',
    list: true,
  },
  {
    label: 'CNPJ / CPF',
    name: 'buyer.cnpj_cpf',
    formattedName: 'buyer.cnpj_cpf_formatted',
    filterName: 'buyer_cnpj_cpf',
    type: 'text',
    list: true,
  },
  {
    label: 'UF',
    name: 'buyer.uf',
    filterName: 'buyer_uf',
    type: 'text',
    list: true,
  },
  {
    label: 'Total',
    name: 'total_note',
    formattedName: 'total_note_formatted',
    type: 'number',
    list: true,
    noFilter: true,
  },
];
