import Vue from 'vue';

export const DefaultFieldEvents = {
  order: {
    change: (formValue) => {
      formValue.items = formValue.items || [];
      formValue.items.forEach((item) => {
        item.order_number = formValue.order;
      });
    },
  },
  stock_id: {
    change: async (formValue) => {
      formValue.items = formValue.items || [];

      formValue.items = await Promise.all(
        formValue.items.map(async (item) => {
          const { data } = await Vue.prototype.$api.Stocks.showGraphql(formValue.stock_id, ['id', 'to_s']);
          item.stock = { id: data.id, to_s: data.to_s };
          return item;
        }),
      );

      formValue.items.forEach((item) => {
        item.stock_id = formValue.stock_id;
      });
    },
  },
};
