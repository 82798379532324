import { ICON_TAXES } from '@constants/icons';
import NationalSimpleBusiness from '@businesses/NationalSimple';
import NationalSimpleProductTaxes from './NationalSimpleProductTaxes';
import NationalSimpleBilling from './NationalSimpleBilling';
//
export default {
  service: 'NationalSimple',
  business: NationalSimpleBusiness,
  icon: ICON_TAXES,
  routes: {
    edit: { path: '/tables/nationalsimple/edit', name: 'tables-nationalsimple-edit' },
  },
  titles: {
    item: 'Simples Nacional',
    items: 'Simples Nacional',
    edit: 'Editar Simples Nacional',
    create: 'Criar Simples Nacional',
    new: 'Novo Simples Nacional',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'simple_national/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  hideReturnButton: true,
  hideActionsMenu: true,
  fields: [
    {
      label: 'Total do mês atual',
      name: 'current_month_total_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-totalizer',
      precision: 2,
      md: 4,
      disabled: true,
    },
    {
      label: 'Total do ano atual',
      name: 'current_year_total_value_formatted',
      type: 'number',
      component: 'dynamic-totalizer',
      precision: 2,
      md: 4,
      disabled: true,
    },
    {
      label: 'Total Faturamento',
      name: 'billing_total_value_formatted',
      type: 'number',
      component: 'dynamic-totalizer',
      precision: 2,
      md: 4,
      disabled: true,
    },
  ],
  childSchemas: [
    {
      label: 'Tabela para Produtos Comércio',
      name: 'commerce_product_taxes',
      schema: NationalSimpleProductTaxes,
    },
    {
      label: 'Tabela para Produtos Indústria',
      name: 'industry_product_taxes',
      schema: NationalSimpleProductTaxes,
    },
    {

      label: 'Faturamento dos 12 ultimos meses',
      name: 'billings',
      schema: NationalSimpleBilling,
      noDelete: true,
      disablePagination: true,
    },
  ],
};
