import {
  Biddings,
  BiddingsKeywords,
} from './pages/biddings/services';

import {
  Magis5Commands,
} from './pages/magis5/services';

export { default as routes } from './routes';

export const globalServices = {
  Biddings,
  BiddingsKeywords,
  Magis5Commands,
};
