export default [
  {
    path: '/tables/costcenter/list',
    name: 'tables-costcenters-list',
    meta: {
      requiresAuth: true,
      rule: 'cost_centers',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Centro de Custo' },
      ],
    },
    component: () => import(/* webpackChunkName: "tables-costcenters-list" */'./ListCostCenter'),
  },
  {
    path: '/tables/costcenter/create',
    name: 'tables-costcenters-create',
    meta: {
      requiresAuth: true,
      rule: 'cost_centers',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Centro de Custo', link: '/tables/costcenter/list' },
        { name: 'Criar Centro de Custo' },
      ],
    },
    component: () => import(/* webpackChunkName: "create-cost-center" */'./CreateCostCenter'),
  },
  {
    path: '/tables/costcenters/edit/:id',
    name: 'tables-costcenters-edit',
    meta: {
      requiresAuth: true,
      rule: 'cost_centers',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Centro de Custo', link: '/tables/costcenter/list' },
        { name: 'Editar Centro de Custo' },
      ],
    },
    component: () => import(/* webpackChunkName: 'tables-costcenters-edit' */'./EditCostCenter'),
  },
];
