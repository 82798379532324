<template>
  <v-menu ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="fieldValue"
        :required="checkRequired()"
        :prefix="field.prefix"
        :suffix="field.suffix"
        :multi-line="field.multiLine"
        :hint="field.hint"
        :persistent-hint="true"
        :disabled="field.disabled"
        :rules="validationRules"
        :label="field.label"
        :class="field.class"
        :autofocus="field.autofocus"
        :readonly="readOnly || field.readOnly"
        :clearable="field.clearable"
        :clear-icon="iconClose"
        :rounded="field.rounded"
        :filled="field.filled"
        :prepend-icon="innerIcon ? '' : prependIcon"
        :prepend-inner-icon="innerIcon ? prependIcon : ''"
        v-bind="$attrs"
        @change="fieldChanged"
        v-on="on" />
    </template>
    <v-time-picker v-model="fieldValue" :format="field.format" :use-seconds="field.useSeconds" @input="menu = false" />
  </v-menu>
</template>

<script>
import Vue from 'vue';
import { ICON_DESCRIPTION, ICON_CLOSE } from '@constants/icons';
import { slugify } from '@plugins/app-utils';
import VueTheMask from 'vue-the-mask';
import DynamicFieldMixin from './DynamicFieldMixin';
import {
  ICON_CALENDAR_BLANK, ICON_SKIP_NEXT, ICON_SKIP_PREVIOUS, ICON_CALENDAR,
} from '@constants/icons';

Vue.use(VueTheMask);

export default {
  name: 'DynamicTimePicker',

  mixins: [DynamicFieldMixin],
  data() {
    return {
      fieldValue: '',
      icons: {
        calendarBlank: ICON_CALENDAR_BLANK,
        prependIcon: ICON_CALENDAR,
        skipNext: ICON_SKIP_NEXT,
        skipPrevious: ICON_SKIP_PREVIOUS,
      },
      defaultIcon: ICON_DESCRIPTION,
      iconClose: ICON_CLOSE,
      hour: null,
      menu: false,
    };
  },
  computed: {
    fieldType() {
      return this.field?.type && 'text';
    },
    prependIcon() {
      return this.field.prependIcon === undefined ? this.defaultIcon : this.field.prependIcon;
    },
    appendIcon() {
      return this.field.appendIcon;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
  },
  methods: {
    onBlurTextField() {
      if (this.field.slug) {
        this.fieldValue = slugify(this.fieldValue);
        if (this?.maxLength) {
          this.fieldValue = this.fieldValue.substr(0, this?.maxLength);
        }
        this.fieldChanged(this.fieldValue);
      }
    },
  },
};
</script>

<style lang="scss"></style>
