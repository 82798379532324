import { ICON_COGS, ICON_CLOCK } from '@constants/icons';
import ProductCompositionFactory from '@businesses/ProductCompositionFactory';

export default {
  service: 'Compositions',
  business: ProductCompositionFactory,
  icon: ICON_COGS,
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  titles: {
    item: 'Item da procedimento de fabricação',
    items: 'Itens da procedimento de fabricação',
    edit: 'Editar procedimento de fabricação',
    create: 'Criar procedimento de fabricação',
    new: 'Adicionar Item',
  },
  fields: [
    {
      name: 'step',
      label: 'Ordem',
      disabled: true,
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Código',
      name: 'product_service_id',
      formattedName: 'product_service.code',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            product_type_description_eq: "'Setor Produtivo'",
            query: ['id', 'code'],
          }, 'name asc',
        ],
      },
      itemText: 'code',
      itemValue: 'id',
      list: true,
      required: true,
      md: 4,
    },
    {
      label: 'Descrição',
      name: 'product_service_id',
      formattedName: 'product_service.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            product_type_description_eq: "'Setor Produtivo'",
            query: ['id', 'to_s'],
          }, 'name asc',
        ],
      },
      itemText: 'to_s',
      itemValue: 'id',
      list: true,
      md: 4,
    },
    {
      label: 'Máquina',
      name: 'product_machine_id',
      formattedName: 'product_machine.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          product_type_description_eq: "'Máquina'",
          query: ['id', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      list: true,
      md: 4,
    },
    {
      label: 'Como Fazer',
      name: 'how_to_do',
      type: 'text',
      list: true,
      component: 'dynamic-text-area',
      md: 12,
    },
    {
      label: 'Tempo de Setup',
      name: 'time_setup',
      formattedName: 'time_setup_formatted',
      list: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo de Processo',
      name: 'time_process',
      formattedName: 'time_process_formatted',
      list: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo de Máquina',
      name: 'time_machine',
      formattedName: 'time_machine_formatted',
      list: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo Total',
      name: 'time_total',
      formattedName: 'time_total_formatted',
      type: 'time',
      prependIcon: ICON_CLOCK,
      list: true,
      component: 'dynamic-text-field',
      md: 3,
      disabled: true,
    },
    {
      label: 'Custo p/ hora',
      name: 'unit_cost',
      formattedName: 'unit_cost_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
      disabled: true,
    },
    {
      label: 'Custo Total',
      name: 'total_cost',
      formattedName: 'total_cost_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
      disabled: true,
    },
    /*
    {
      label: 'Usa insumos',
      name: 'use_feedstock',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 4,
    },
    */
    {
      label: 'Fixo',
      name: 'fixed',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 4,
    },
  ],
};
