import RegisterSchema from '@schemas/Register';

const ListRegisters = () => import(/* webpackChunkName: "list-registers" */'./ListRegisters');
const CreateRegisters = () => import(/* webpackChunkName: "create-registers" */'./CreateRegisters');
const EditRegisters = () => import(/* webpackChunkName: "edit-registers" */'./EditRegisters');

export default [
  {
    ...RegisterSchema.routes.list,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Cadastros' },
      ],
    },
    component: ListRegisters,
  },
  {
    ...RegisterSchema.routes.create,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Cadastros', route: RegisterSchema.routes.list.name },
        { name: 'Criar' },
      ],
    },
    component: CreateRegisters,
  },
  {
    ...RegisterSchema.routes.edit,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Cadastros', route: RegisterSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: EditRegisters,
  },
];
