import {
  ICON_EMAIL,
} from '@constants/icons';

import {
  OPTIONS_EMAIL_LAYOUT_ORIGINS,
} from '@constants/options';

export default {
  service: 'EmailsLayouts',
  // business: CashFlowBusiness,
  formName: 'emailLayout',
  icon: ICON_EMAIL,
  routes: {
    list: { path: '/settings/emails/layouts', name: 'settings-emaillayouts-list' },
    edit: { path: '/settings/emails/layouts/edit/:id', name: 'settings-emaillayouts-edit' },
  },
  titles: {
    item: 'Layout de Email',
    items: 'Layouts de Emails',
    edit: 'Editar Layout de Email',
  },
  messages: {
    successDelete: 'Layout de Email excluido com sucesso!',
    successEdit: 'Layout de Email alterado com sucesso!',
    errorSave: 'Erro ao salvar layout de email!',
  },
  hideReportsOnList: true,
  hideReportsOnCreate: true,
  hideReportsOnEdit: true,
  noCreate: true,
  noDelete: true,
  fields: [
    {
      label: 'Origem - Tipo do Email',
      name: 'origin_type',
      formattedName: 'origin',
      type: 'text',
      component: 'dynamic-select',
      items: OPTIONS_EMAIL_LAYOUT_ORIGINS,
      itemId: 'text',
      itemValue: 'value',
      disabled: true,
      list: true,
      md: 10,
    },
    {
      label: 'Email tipo <html>',
      name: 'is_html',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    {
      label: 'Assunto',
      name: 'subject',
      type: 'text',
      component: 'dynamic-text-field',
      md: 6,
      required: true,
      list: true,
    },
    {
      label: 'Enviar cópia para',
      name: 'copy_email',
      type: 'text',
      component: 'dynamic-text-field',
      hint: 'Separe os emails entre virgula "," ex: email1@example.com, email2@example.com',
      md: 6,
      required: false,
      list: true,
    },
    {
      label: 'Layout da Mensagem do Email',
      name: 'layout_body',
      type: 'text',
      component: 'dynamic-text-area',
      md: 12,
      required: true,
      list: false,
    },
  ],
};
