import Vue from 'vue';
import * as validations from './validations';

const Rules = {
  install(vue) {
    vue.prototype.$rules = validations;
  },
};

Vue.use(Rules);
