<template>
  <div :id="item.id">
    <GenericCardPanel
      title="Informações da licitação"
      :fields="biddingFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabBiddings',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      biddingFields: [],
    };
  },
  mounted() {
    this.biddingFields = [
      { label: 'Objetivo', value: this.item.object, col: 8 },
    ];
  },
};
</script>

<style scoped></style>
