import _ from 'lodash';

const defaultOpt = {
  prefix: '',
  suffix: '',
  thousands: '.',
  decimal: ',',
  precision: 2,
  locale: 'pt-BR',
};

function toStr(value) {
  return value ? value.toString() : '';
}

function onlyNumbers(input) {
  return toStr(input).replace(/\D+/g, '') || '0';
}

function between(min, n, max) {
  return Math.max(min, Math.min(n, max));
}

// Uncaught RangeError: toFixed() digits argument must be between 0 and 20 at Number.toFixed
function fixed(precision) {
  return between(0, precision, 20);
}

function format(input, opt) {
  if (!opt) {
    opt = defaultOpt;
  } else {
    opt = _.merge(_.cloneDeep(defaultOpt), opt);
  }

  if (typeof input === 'string') {
    input = parseFloat(input);
  }

  input = Number.isNaN(input) ? 0 : input;
  const formatted = Number(input).toLocaleString(opt.locale, {
    style: 'decimal',
    minimumFractionDigits: opt.precision,
    maximumFractionDigits: opt.precision,
  });

  return opt.prefix + formatted + opt.suffix;
}

function unformat(input, numericMask, parseToFloat) {
  if (typeof input === 'number') {
    input = input.toFixed(fixed(numericMask.precision));
  }
  const negative = input.indexOf('-') >= 0 ? -1 : 1;
  const split = input.split(numericMask.decimal || ',');
  const integerPart = onlyNumbers(split[0] || '');
  const decimalPart = onlyNumbers(split[1] || '');
  const stringNumber = `${integerPart}.${decimalPart}`;
  if (parseToFloat === false) {
    return (negative === -1 ? '-' : '') + stringNumber;
  }
  return parseFloat(stringNumber) * negative;
}

export default {
  format,
  unformat,
};
