import OrderServiceSchema from '@schemas/OrderService';

const ListOrderServices = () => import(/* webpackChunkName: "order-services-list" */'./ListOrderServices');
const DetailOrderServices = () => import(/* webpackChunkName: "order-services-detail" */'./DetailOrderServices');

export default [
  {
    path: OrderServiceSchema.routes.list.path,
    name: OrderServiceSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: OrderServiceSchema.titles.items },
      ],
    },
    component: ListOrderServices,
  },
  {
    path: OrderServiceSchema.routes.create.path,
    name: OrderServiceSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: OrderServiceSchema.titles.items, route: OrderServiceSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailOrderServices,
  },
  {
    path: OrderServiceSchema.routes.edit.path,
    name: OrderServiceSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: OrderServiceSchema.titles.items, route: OrderServiceSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailOrderServices,
  },
];
