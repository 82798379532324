/*
 * Constants of Icons in the UI
 * When create icon, keep the organization in the exports in alphabetical order
 *
 * Cheatsheet: https://materialdesignicons.com/cheatsheet
 */
import * as mdi from '@mdi/js';

/**
 * General Icons
 */

// A

export const ICON_ACCOUNT = mdi.mdiAccount;
export const ICON_ACCOUNT_BOX = mdi.mdiAccountBox;
export const ICON_ACCOUNT_BOX_MULTIPLE = mdi.mdiAccountBoxMultiple;
export const ICON_ACCOUNT_CASH = mdi.mdiAccountCash;
export const ICON_ACCOUNT_DETAILS = mdi.mdiAccountDetails;
export const ICON_ACCOUNT_TIE = mdi.mdiAccountTie;
export const ICON_ADDRESS = mdi.mdiHome;
export const ICON_ADDRESS_MINUS = mdi.mdiHomeMinus;
export const ICON_ADDRESS_PLUS = mdi.mdiHomePlus;
export const ICON_ADDRESS_SEARCH = mdi.mdiHomeSearchOutline;
export const ICON_ALERT = mdi.mdiAlertCircleOutline;
export const ICON_ALERT_BOX = mdi.mdiAlertBox;
export const ICON_ALERT_CIRCLE = mdi.mdiAlertCircle;
export const ICON_APPLICATION = mdi.mdiApplication;
export const ICON_APPLICATION_BRACKETS_OUTLINE = mdi.mdiApplicationBracketsOutline;
export const ICON_APPLICATION_EXPORT = mdi.mdiApplicationExport;
export const ICON_APPLICATION_IMPORT = mdi.mdiApplicationImport;
export const ICON_ARROW_DOWN = mdi.mdiArrowDown;
export const ICON_ARROW_LEFT = mdi.mdiArrowLeft;
export const ICON_ARROW_RIGHT = mdi.mdiArrowRight;
export const ICON_ARROW_UP = mdi.mdiArrowUp;
export const ICON_ATTACHMENT = mdi.mdiAttachment;

// B

export const ICON_BANK = mdi.mdiBank;
export const ICON_BANK_CHECK = mdi.mdiBankCheck;
export const ICON_BANK_PLUS = mdi.mdiBankPlus;
export const ICON_BANK_REMOVE = mdi.mdiBankRemove;
export const ICON_BANK_TRANSFER_IN = mdi.mdiBankTransferIn;
export const ICON_BANK_TRANSFER_OUT = mdi.mdiBankTransferOut;
export const ICON_BELL = mdi.mdiBell;
export const ICON_BLUR = mdi.mdiBlur;
export const ICON_BOOK_OUTLINE = mdi.mdiBookOutline;
export const ICON_BREADCRUMB = mdi.mdiChevronRight;
export const ICON_BREADCRUMB_REVERSE = mdi.mdiChevronLeft;

// C

export const ICON_CALCULATOR = mdi.mdiCalculator;
export const ICON_CALENDAR = mdi.mdiCalendarRange;
export const ICON_CALENDAR_BLANK = mdi.mdiCalendarBlank;
export const ICON_CALENDAR_EDIT = mdi.mdiCalendarEdit;
export const ICON_CAMERA = mdi.mdiCamera;
export const ICON_CANCEL = mdi.mdiCloseBox;
export const ICON_CANCEL_CIRCLE = mdi.mdiAlphaXCircle;
export const ICON_CAR_PICKUP = mdi.mdiCarLiftedPickup;
export const ICON_CARD = mdi.mdiCreditCardOutline;
export const ICON_CARD_ADD = mdi.mdiCardPlusOutline;
export const ICON_CARD_TEXT = mdi.mdiCardText;
export const ICON_CART = mdi.mdiCart;
export const ICON_CASH = mdi.mdiCash;
export const ICON_CASH_CHECK = mdi.mdiCashCheck;
export const ICON_CASH_CLOCK = mdi.mdiCashClock;
export const ICON_CASH_EDIT = mdi.mdiCashEdit;
export const ICON_CASH_MINUS = mdi.mdiCashMinus;
export const ICON_CASH_MULTIPLE = mdi.mdiCashMultiple;
export const ICON_CASH_PLUS = mdi.mdiCashPlus;
export const ICON_CASH_REFUND = mdi.mdiCashRefund;
export const ICON_CASH_REGISTER = mdi.mdiCashRegister;
export const ICON_CELL_PHONE = mdi.mdiCellphone;
export const ICON_CEST = mdi.mdiCellphone;
export const ICON_CIRCLE_SMALL = mdi.mdiCircleSmall;
export const ICON_CIRCLE_SLICE_7 = mdi.mdiCircleSlice7;
export const ICON_CLOCK = mdi.mdiClock;
export const ICON_CLOCK_OUTLINE = mdi.mdiClockOutline;
export const ICON_CLOSE = mdi.mdiClose;
export const ICON_CLOUD_DOWNLOAD_OUTLINE = mdi.mdiCloudDownloadOutline;
export const ICON_CLOUD_SYNC = mdi.mdiCloudSync;
export const ICON_COGS = mdi.mdiCogs;
export const ICON_CORRECT_INVOICE = mdi.mdiBookMultipleOutline;
export const ICON_COPY = mdi.mdiContentCopy;
export const ICON_CHART_ARC = mdi.mdiChartArc;
export const ICON_CHART_BAR = mdi.mdiChartBar;
export const ICON_CHART_BAR_STACKED = mdi.mdiChartBarStacked;
export const ICON_CHART_BOX_OUTLINE = mdi.mdiChartBoxOutline;
export const ICON_CHART_LINE = mdi.mdiChartLine;
export const ICON_CHART_PIE = mdi.mdiChartPie;
export const ICON_CHECK = mdi.mdiCheck;
export const ICON_CHECK_CIRCLE = mdi.mdiCheckCircle;
export const ICON_CHECKBOX_BLANK = mdi.mdiCheckboxBlank;
export const ICON_CHECKBOX_MARKED = mdi.mdiCheckboxMarked;
export const ICON_CLIPBOARD_CHECK_MULTIPLE_OUTLINE = mdi.mdiClipboardCheckMultipleOutline;
export const ICON_CREDIT_CARD_MULTIPLE = mdi.mdiCreditCardMultiple;
export const ICON_CREDIT_CARD_OUTLINE = mdi.mdiCreditCardOutline;

// D

export const ICON_DASHBOARD = mdi.mdiRocketLaunch;
export const ICON_DELETE = mdi.mdiDelete;
export const ICON_DOWNLOAD = mdi.mdiDownload;
export const ICON_DOTS_VERTICAL = mdi.mdiDotsVertical;
export const ICON_DESCRIPTION = mdi.mdiFileDocumentMultipleOutline;
export const ICON_DOCUMENT = mdi.mdiCardAccountDetails;
export const ICON_DOMAIN = mdi.mdiDomain;
export const ICON_DOMAIN_MINUS = mdi.mdiDomainRemove;
export const ICON_DOWN = mdi.mdiChevronDown;
export const ICON_DOT = mdi.mdiMusicNoteWhole;

// E

export const ICON_EDIT = mdi.mdiPencil;
export const ICON_EMAIL = mdi.mdiEmail;
export const ICON_EMAIL_CHECK = mdi.mdiEmailCheck;
export const ICON_EMAIL_MINUS = mdi.mdiEmailMinus;
export const ICON_EMAIL_NEWSLETTER = mdi.mdiEmailNewsletter;
export const ICON_EMAIL_SEND = mdi.mdiEmailArrowRight;
export const ICON_EMAIL_SYNC = mdi.mdiEmailSync;
export const ICON_ERROR = mdi.mdiAlertCircle;
export const ICON_EYE = mdi.mdiEye;
export const ICON_EYE_OFF = mdi.mdiEyeOff;

// F

export const ICON_FACTORY = mdi.mdiFactory;
export const ICON_FAX = mdi.mdiFax;
export const ICON_FILE_CANCEL = mdi.mdiFileCancel;
export const ICON_FILE_CHART = mdi.mdiFileChart;
export const ICON_FILE_DOWNLOAD = mdi.mdiFileDownload;
export const ICON_FILE_EXPORT = mdi.mdiFileExport;
export const ICON_FILE_IMPORT = mdi.mdiFileImport;
export const ICON_FILE_LINK = mdi.mdiFileLink;
export const ICON_FILE_OUTLINE = mdi.mdiFileOutline;
export const ICON_FILE_SEARCH = mdi.mdiFileSearch;
export const ICON_FILE_SEND = mdi.mdiFileSend;
export const ICON_FILTER = mdi.mdiFilterOutline;
export const ICON_FINANCE = mdi.mdiFinance;
export const ICON_FLAG_VARIANT = mdi.mdiFlagVariant;
export const ICON_FLOOR_PLAN = mdi.mdiFloorPlan;
export const ICON_FOLDER = mdi.mdiFolder;
export const ICON_FOLDER_OPEN = mdi.mdiFolderOpen;
export const ICON_FOLDER_MULTIPLE = mdi.mdiFolderMultiple;
export const ICON_FOLDER_PLUS = mdi.mdiFolderPlus;
export const ICON_FOLDER_TABLE = mdi.mdiFolderTable;
export const ICON_FORM_NUMERIC = mdi.mdiNumeric;
export const ICON_FORM_TEXTBOX_PASSWORD = mdi.mdiFormTextboxPassword;
export const ICON_FORM_SELECT = mdi.mdiFormSelect;
export const ICON_FULLSCREEN = mdi.mdiFullscreen;
export const ICON_FULLSCREEN_EXIT = mdi.mdiFullscreenExit;

// G

export const ICON_GAVEL = mdi.mdiGavel;
export const ICON_GOOGLE_MAPS = mdi.mdiGoogleMaps;

// H

export const ICON_HAND_COIN = mdi.mdiHandCoin;
export const ICON_HANDS_SHAKE = mdi.mdiHandshake;
export const ICON_HEADSET = mdi.mdiHeadset;
export const ICON_HELP = mdi.mdiHelp;
export const ICON_HELP_CIRCLE = mdi.mdiHelpCircle;
export const ICON_HISTORIC = mdi.mdiHistory;
export const ICON_HOME = mdi.mdiHome;

// I

export const ICON_INFO_OUTLINE = mdi.mdiInformationOutline;
export const ICON_INVOICE = mdi.mdiInvoiceTextMultiple;
export const ICON_INVOICE_CHECK = mdi.mdiInvoiceTextCheck;
export const ICON_INVOICE_CLOCK = mdi.mdiInvoiceTextClock;
export const ICON_INVOICE_MINUS = mdi.mdiInvoiceTextMinus;
export const ICON_INVOICE_PLUS = mdi.mdiInvoiceTextPlus;
export const ICON_INVOICE_REMOVE = mdi.mdiInvoiceTextRemove;
export const ICON_INVOICE_SEND = mdi.mdiInvoiceTextSend;
export const ICON_INVOICE_TEXT = mdi.mdiInvoiceText;

// K

export const ICON_KEY = mdi.mdiKey;
export const ICON_KEY_LINK = mdi.mdiKeyLink;

// L

export const ICON_LIGHTBULB = mdi.mdiLightbulb;
export const ICON_LINK = mdi.mdiLink;
export const ICON_LOCK = mdi.mdiLock;
export const ICON_LOGOUT = mdi.mdiPower;

// M

export const ICON_MAGNIFY = mdi.mdiMagnify;
export const ICON_MEASURING_UNIT = mdi.mdiTapeMeasure;
export const ICON_MENU = mdi.mdiMenu;
export const ICON_MENU_DOWN = mdi.mdiMenuDown;
export const ICON_MENU_UP = mdi.mdiMenuUp;
export const ICON_MENU_OPEN = mdi.mdiMenuOpen;
export const ICON_MINUS = mdi.mdiMinus;
export const ICON_MINUS_BOX_OUTLINE = mdi.mdiMinusBoxOutline;

// N

export const ICON_NATURE_OPERATION = mdi.mdiAlphaNBoxOutline;
export const ICON_NATURE_OPERATION_STATE = mdi.mdiMapMarker;
export const ICON_NCM = mdi.mdiAlphaNBoxOutline;
export const ICON_NOTEBOOK_MULTIPLE = mdi.mdiNotebookMultiple;

// O

export const ICON_OPEN_IN_NEW = mdi.mdiOpenInNew;

// P

export const ICON_PERCENT = mdi.mdiLabelPercent;
export const ICON_PHONE = mdi.mdiPhone;
export const ICON_PLUS_CIRCLE_OUTLINE = mdi.mdiPlusCircleOutline;
export const ICON_PLUS = mdi.mdiPlus;
export const ICON_POINT_OF_SALE = mdi.mdiPointOfSale;
export const ICON_PRINTER = mdi.mdiPrinter;
export const ICON_PRODUCT = mdi.mdiPackage;
export const ICON_PRODUCT_FILL = mdi.mdiBasketFill;
export const ICON_PRODUCT_PLUS = mdi.mdiBasketPlus;
export const ICON_PUZZLE = mdi.mdiPuzzle;

// Q

export const ICON_QUICK_SWITCH = mdi.mdiLightningBolt;

// R

export const ICON_RADIOBOX_OFF = mdi.mdiRadioboxBlank;
export const ICON_RADIOBOX_ON = mdi.mdiRadioboxMarked;
export const ICON_RECEIPT_TEXT = mdi.mdiReceiptText;
export const ICON_REFRESH = mdi.mdiRefresh;
export const ICON_RETURN = mdi.mdiKeyboardReturn;
export const ICON_ROCKET_LAUNCH = mdi.mdiRocketLaunch;

// S

export const ICON_SAVE = mdi.mdiContentSave;
export const ICON_SAVE_EXIT = mdi.mdiContentSaveMove;
export const ICON_SHARE = mdi.mdiShare;
export const ICON_SEARCH = mdi.mdiMagnify;
export const ICON_SEARCH_CLOSE = mdi.mdiMagnifyClose;
export const ICON_SEARCH_FEATURE = mdi.mdiFeatureSearch;
export const ICON_SEARCH_FILE = mdi.mdiFileSearch;
export const ICON_SEARCH_MINUS = mdi.mdiMagnifyMinus;
export const ICON_SEARCH_PLUS = mdi.mdiMagnifyPlus;
export const ICON_SEARCH_TABLE = mdi.mdiTableSearch;
export const ICON_SEARCH_WEB = mdi.mdiSearchWeb;
export const ICON_SET_CENTER = mdi.mdiSetCenter;
export const ICON_SETTINGS = mdi.mdiWrenchOutline;
export const ICON_SHARE_ALL = mdi.mdiShareAll;
export const ICON_SHOPPING = mdi.mdiShopping;
export const ICON_SITEMAP = mdi.mdiSitemap;
export const ICON_SKIP_NEXT = mdi.mdiSkipNext;
export const ICON_SKIP_PREVIOUS = mdi.mdiSkipPrevious;
export const ICON_SPROUT = mdi.mdiSprout;
export const ICON_STOCK = mdi.mdiWarehouse;
export const ICON_STORE = mdi.mdiStore;
export const ICON_SWAP_VERTICAL = mdi.mdiSwapVertical;

// T

export const ICON_TAG_MULTIPLE = mdi.mdiTagMultiple;
export const ICON_TAG_PLUS = mdi.mdiTagPlus;
export const ICON_TAXES = ICON_CALCULATOR;
export const ICON_TRANSFER = mdi.mdiTransfer;
export const ICON_TEXT_BOX_CHECK_OUTLINE = mdi.mdiTextBoxCheckOutline;
export const ICON_TEXT_BOX_SEARCH = mdi.mdiTextBoxSearch;
export const ICON_TITLE = mdi.mdiStickerPlusOutline;
export const ICON_TIMER_SAND = mdi.mdiTimerSand;
export const ICON_TOOLS = mdi.mdiTools;
export const ICON_TOOLTIP = mdi.mdiHelpCircleOutline;
export const ICON_TRASH = mdi.mdiDelete;
export const ICON_TREE = mdi.mdiFileTreeOutline;
export const ICON_TREE_EXPAND = mdi.mdiChevronDown;
export const ICON_TRIANGLE = mdi.mdiTriangle;
export const ICON_TRIANGLE_OUTLINE = mdi.mdiTriangleOutline;
export const ICON_TRUCK = mdi.mdiTruck;

// U

export const ICON_UPLOAD = mdi.mdiUpload;

/**
 * Module/function or Exclusive Icons
*
* Insert here the exclusive icons that will be used to identify
* modules, sources or functionalities. ex: Module's title, Especific Button
 */

export const ICON_ACTION_OPEN_ORIGIN = mdi.mdiShare;
export const ICON_TABLES_ACCOUNTINGACCOUNT_TITLE = mdi.mdiNewspaper;
export const ICON_YOUTUBE = mdi.mdiYoutube;
export const ICON_WHATSAPP = mdi.mdiWhatsapp;
