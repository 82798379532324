const DownloadFile = {
  install(Vue) {
    Vue.prototype.$downloadFile = (fileURL, fileName) => {
      const link = document.createElement('a');
      link.href = fileURL;
      link.target = '_blank';
      link.download = `${fileName}.pdf`;
      link.dispatchEvent(new MouseEvent('click'));
    };
  },
};

export default DownloadFile;
