import * as AppUtils from '@plugins/app-utils';

const getHeaders = (schema, addActions, actionsWidth = 150) => {
  let mappedHeaders = schema.fields.filter(
    (field) => field.list === true,
  ).map((field, index) => ({
    text: field.label,
    fixed: field.fixed,
    value: field.formattedName || field.name,
    width: field.columnWidth,
    order: field.order || index,
    class: field.class,
    cellClass: field.cellClass,
    align: field.columnAlign,
    sortable: schema.paginable ? field.sortable : true,
  }));

  mappedHeaders = AppUtils.orderedArray(mappedHeaders, 'order');

  if (addActions) {
    mappedHeaders.push({
      text: 'Ações',
      value: 'actions',
      class: 'action-column-header',
      cellClass: 'action-column',
      width: actionsWidth,
      sortable: false,
    });
  }

  return mappedHeaders;
};

const getHeadersValues = (schema, includesId) => {
  let mappedHeaders = schema.fields.filter(
    (field) => field.list === true,
  ).map((field) => ({
    value: field.name,
  }));

  mappedHeaders = AppUtils.orderedArray(mappedHeaders, 'order');

  if (includesId) {
    mappedHeaders.push({
      value: 'id',
    });
  }

  return mappedHeaders;
};

const getHeadersValuesFormatted = (schema, includesId) => {
  let mappedHeaders = schema.fields.filter(
    (field) => field.list === true,
  ).map((field) => ({
    value: field.formattedName,
  }));

  mappedHeaders = AppUtils.orderedArray(mappedHeaders, 'order');

  if (includesId) {
    mappedHeaders.push({
      value: 'id',
    });
  }

  return mappedHeaders;
};

// @TODO check value field validations
const getListFields = (schema, item) => {
  let mappedFields = schema.fields.filter(
    (field) => field.list === true,
  ).map((field, index) => ({
    label: field.label,
    value: item[field?.formattedName || field?.name],
    col: field.md,
    order: field.order || index,
  }));

  mappedFields = AppUtils.orderedArray(mappedFields, 'order');

  return mappedFields;
};

const groupBy = (items, key) => items.reduce((result, item) => ({ ...result, [item[key]]: [...(result[item[key]] || []), item] }), {});

const getListFieldsByTabGroup = (schema, item) => {
  const tabGroups = groupBy(schema.fields, 'tabGroup');
  delete tabGroups.undefined;

  const solveTabs = Object.keys(tabGroups).map((key) => tabGroups[key]).map((tabGroup) => {
    const title = tabGroup.filter((field) => field.component === 'dynamic-title')[0]?.label;
    const fields = tabGroup.filter((field) => field.component !== 'dynamic-title').map((field) => {
      if (field?.formattedName && field?.formattedName.includes('.to_s')) {
        const formattedTabName = item[field?.formattedName.replace('.to_s', '')]?.to_s;
        return {
          label: field.label,
          value: formattedTabName,
          col: field.md,
        };
      }
      if (field?.type === 'boolean' || typeof item[field?.name || field?.formattedName] === 'boolean') {
        return {
          label: field.label,
          value: item[field?.name || field?.formattedName] ? 'Sim' : 'Não',
          col: field.md,
        };
      }
      return {
        label: field.label,
        value: item[field?.formattedName || field?.name],
        col: field.md,
      };
    });
    const finalResponse = {
      title,
      fields,
    };
    return finalResponse;
  });
  return solveTabs;
};

const getListFieldSort = (schema) => {
  const sortable = schema.fields.filter((item) => item.list === true)
    .reduce((obj, field) => {
      if (field.sortBy) {
        obj.sortBy.push(field?.formattedName || field?.name);
        obj.sortDesc.push(!!field?.sortDesc);
      }
      return obj;
    }, {
      sortBy: [],
      sortDesc: [],
    });
  return sortable;
};

const callBusinessFunction = async (formValue, businessDefinition, event, field, fieldValue, extraValue) => {
  if (!businessDefinition || !event) {
    return;
  }

  const baseEventsStructure = field ? businessDefinition.fieldEvents : businessDefinition.screenEvents;

  if (!baseEventsStructure) {
    return;
  }

  if (field) {
    if (!Object.prototype.hasOwnProperty.call(baseEventsStructure, field.name)) {
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(baseEventsStructure[field.name], event)) {
      return;
    }
  } else if (!Object.prototype.hasOwnProperty.call(baseEventsStructure, event)) {
    return;
  }

  const eventFunction = field ? baseEventsStructure[field.name][event] : baseEventsStructure[event];

  if (typeof eventFunction === 'function') {
    const result = await eventFunction(formValue, fieldValue, extraValue);
    return result;
  }
};

export default {
  getHeaders,
  getHeadersValues,
  getHeadersValuesFormatted,
  callBusinessFunction,
  getListFields,
  getListFieldsByTabGroup,
  getListFieldSort,
};
