import AdminsSchema from '@schemas/Admins';

const ListAdmins = () => import(/* webpackChunkName: "list-admins" */'./ListAdmins');
const DetailAdmins = () => import(/* webpackChunkName: "detail-admins" */'./DetailAdmins');

export default [
  {
    path: '/settings/admins',
    name: 'settings-admins-list',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: AdminsSchema.titles.items },
      ],
    },
    component: ListAdmins,
  },
  {
    path: '/settings/admins/create',
    name: 'settings-admins-create',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: AdminsSchema.titles.items, route: 'settings-admins-list' },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailAdmins,
  },
  {
    path: '/settings/admins/edit/:id',
    name: 'settings-admins-edit',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: AdminsSchema.titles.items, route: 'settings-admins-list' },
        { name: 'Editar' },
      ],
    },
    component: DetailAdmins,
  },
];
