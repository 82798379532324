<template>
  <v-card :max-width="maxWidth"
          :min-width="minWidth"
          :color="bgColor"
          class="pa-2 my-1"
          v-bind="$attrs"
          outlined>
    <v-card-title v-if="title" :class="`${textColor}`">
      <span
        :class="`genericcardform-title ml-3 text-md-h6 ${(titleCapitalize ? 'capitalize' : '')}`">
        {{ title }}
      </span>
    </v-card-title>
    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'GenericCardForm',
  props: {
    title: {
      type: String,
      default: () => (''),
    },
    bgColor: {
      type: String,
      default: () => ('grey lighten-4'),
    },
    textColor: {
      type: String,
      default: () => ('indigo--text text--lighten-1'),
    },
    titleCapitalize: {
      type: Boolean,
      default: () => (true),
    },
    maxWidth: {
      type: String,
      default: '',
    },
    minWidth: {
      type: String,
      default: '',
    },
  },
};
</script>
<style type="scss" scoped>
.genericcardform-title {
    font-family: 'Montserrat';
    font-size: 18px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0.01em;
    text-align: left;
}
.capitalize {
  text-transform: uppercase !important;
}
</style>
