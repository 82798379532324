import { ICON_ACCOUNT_BOX_MULTIPLE } from '@constants/icons';

export default {
  service: 'ProfilesMenus',
  icon: ICON_ACCOUNT_BOX_MULTIPLE,
  routes: {
    list: {
      name: 'settings-profilemenus-list',
      path: '/settings/profiles/:profile_id/menus',
    },
  },
  titles: {
    items: 'Privilégios do Perfil',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'roles',
  fields: [
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 2,
    },
  ],

};
