import DevolutionSalesSchema from '@schemas/DevolutionSales';

const ListDevolutionSales = () => import(/* webpackChunkName: "list-purchases-returns" */'./ListDevolutionSales');
const DetailDevolutionSales = () => import(/* webpackChunkName: "detail-purchases-returns" */'./DetailDevolutionSales');

export default [
  {
    path: DevolutionSalesSchema.routes.list.path,
    name: DevolutionSalesSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionSalesSchema.titles.items },
      ],
    },
    component: ListDevolutionSales,
  },
  {
    path: DevolutionSalesSchema.routes.create.path,
    name: DevolutionSalesSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionSalesSchema.titles.items, route: DevolutionSalesSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailDevolutionSales,
  },
  {
    path: DevolutionSalesSchema.routes.edit.path,
    name: DevolutionSalesSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionSalesSchema.titles.items, route: DevolutionSalesSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailDevolutionSales,
  },
];
