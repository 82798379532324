import * as AppUtils from '@plugins/app-utils';

const endpoint = 'account_receivables/installments_filter.json';

export default ($axios) => ({
  formatInstallmentData(data, type = 'table') {
    const format = (item) => {
      item.compensation_date = AppUtils.formatDate(item.compensation_date) || '-';
      item.bank.to_s = item.bank.to_s || '-';
      item.payment_method.to_s = item.payment_method.to_s || '-';
      item.day_delay = item.day_delay > 0 ? `${item.day_delay} dias atrasado` : '-';
      item.due_date = AppUtils.formatDate(item.due_date);
      item.due_date_tmp = item.due_date;
      item.source_due_date = AppUtils.formatDate(item.source_due_date);
      item.payment_date = AppUtils.formatDate(item.payment_date) || '-';
      item.value_formatted = AppUtils.formatCurrency(item.value);
      item.difference_value_formatted = AppUtils.formatCurrency(item.difference_value) || 'R$ 0,00';
      item.total_value_formatted = AppUtils.formatCurrency(item.total_value) || 'R$ 0,00';
      item.type_installment = AppUtils.getOptionText('type_account_receivable_installments', item.type_installment) || '-';
      item.paid_off = item.status === 'settled';
      item.status = AppUtils.getOptionText('type_account_receivable_installments_status', item.status);
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData.data;
    }
    format(data);
    return data;
  },
  async getByFilter(filters, order) {
    const payload = {
      q: filters || {},
    };
      // add sort option
    if (order) {
      payload.q.s = order;
    }
    let responseData = await $axios.post(endpoint, payload);
    responseData = this.formatInstallmentData(responseData.data.installments);
    return { data: responseData };
  },
});
