export default [
  {
    path: '/settings/companies',
    name: 'settings-companies-list',
    meta: {
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: 'Empresas' },
      ],
    },
    component: () => import(/* webpackChunkName: "list-companies" */ './ListCompanies'),
  },
  {
    path: '/settings/companies/create',
    name: 'settings-companies-create',
    meta: {
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: 'Empresas', link: '/settings/companies' },
        { name: 'Criar Empresa' },
      ],
    },
    component: () => import(/* webpackChunkName: "create-companies" */ './CreateCompanies'),
  },
  {
    path: '/settings/companies/edit/:id',
    name: 'settings-companies-edit',
    meta: {
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: 'Empresas', link: '/settings/companies' },
        { name: 'Editar Empresa' },
      ],
    },
    component: () => import(/* webpackChunkName: "edit-companies" */ './EditCompanies'),
  },
  {
    path: '/settings/companies/invites/:id',
    name: 'settings-companies-invites',
    meta: {
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: 'Empresas', link: '/settings/companies' },
        { name: 'Convites Enviados' },
      ],
    },
    component: () => import(/* webpackChunkName: "list-invites-companies" */ './ListInvitesCompanies'),
  },
];
