import Vue from 'vue';
import './vue-toasted';
import { BackendGenericError } from '../errors/backendErrors';

/**
 * Notify error with vue-toasted
 * treats case of arg being and array with messages, or just a string
 * @param {Mixed} arg string or array com mensagem de erro
 */
export function notifyError(arg, options) {
  if (arg instanceof BackendGenericError) {
    this.$toasted.error(
      arg.message || 'Ocorreu um erro desconhecido no servidor',
    );
    throw arg;
  }
  if (arg instanceof Error) {
    this.$toasted.error(
      arg.message || 'Ocorreu um erro desconhecido na aplicação',
    );
    throw arg;
  }
  if (typeof arg === 'string') {
    this.$toasted.error(arg, options);
  }
}

/**
 * Notify success with vue-toasted
 * @param {String} arg
 */
export function notifySuccess(arg, options) {
  this.$toasted.success(arg, options);
}

/**
 * Notify warning with vue-toasted
 * @param {String} arg
 */
export function notifyWarning(arg, options) {
  this.$toasted.warning(arg, options);
}

/**
 * Notify info with vue-toasted
 * @param {String} arg
 */
export function notifyInfo(arg, options) {
  this.$toasted.info(arg, options);
}

/**
 * Notify default with vue-toasted
 * @param {String} arg
 */
export function notifyShow(arg, options) {
  this.$toasted.show(arg, options);
}

export function useNotify(context) {
  return {
    notifyError: notifyError.bind(context.root),
    notifySuccess: notifySuccess.bind(context.root),
    notifyWarning: notifyWarning.bind(context.root),
    notifyShow: notifyShow.bind(context.root),
    notifyInfo: notifyInfo.bind(context.root),
  };
}

export const Notify = {
  install(vue) {
    vue.prototype.$notifyError = notifyError;
    vue.prototype.$notifySuccess = notifySuccess;
    vue.prototype.$notifyWarning = notifyWarning;
    vue.prototype.$notifyShow = notifyShow;
    vue.prototype.$notifyInfo = notifyInfo;

    // add vue-router guard to clear toasts on route change
    vue.mixin({
      beforeRouteLeave(to, from, next) {
        const { meta = {} } = to;
        if (meta.clearToasts) {
          this.$toasted.clear();
        }
        next();
      },
    });
  },
};

Vue.use(Notify);
