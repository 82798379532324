import AlltypesSchema from '@schemas/Alltypes';

export default [
  {
    path: AlltypesSchema.routes.sectors.list.path,
    name: AlltypesSchema.routes.sectors.list.name,
    meta: AlltypesSchema.routes.sectors.list.meta,
    component: () => import(/* webpackChunkName: 'list-sectors' */ `${AlltypesSchema.routes.sectors.list.component}`),
  },
  {
    path: AlltypesSchema.routes.sectors.create.path,
    name: AlltypesSchema.routes.sectors.create.name,
    meta: AlltypesSchema.routes.sectors.create.meta,
    component: () => import(/* webpackChunkName: 'create-sectors' */ `${AlltypesSchema.routes.sectors.create.component}`),
  },
  {
    path: AlltypesSchema.routes.sectors.edit.path,
    name: AlltypesSchema.routes.sectors.edit.name,
    meta: AlltypesSchema.routes.sectors.edit.meta,
    component: () => import(/* webpackChunkName: 'edit-sectors' */ `${AlltypesSchema.routes.sectors.edit.component}`),
  },
];
