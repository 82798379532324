import AlltypesSchema from '@schemas/Alltypes';

export default [
  {
    path: AlltypesSchema.routes.serviceCodes.list.path,
    name: AlltypesSchema.routes.serviceCodes.list.name,
    meta: AlltypesSchema.routes.serviceCodes.list.meta,
    component: () => import(/* webpackChunkName: 'list-service-codes' */ `${AlltypesSchema.routes.serviceCodes.list.component}`),
  },
  {
    path: AlltypesSchema.routes.serviceCodes.create.path,
    name: AlltypesSchema.routes.serviceCodes.create.name,
    meta: AlltypesSchema.routes.serviceCodes.create.meta,
    component: () => import(/* webpackChunkName: 'create-service-codes' */ `${AlltypesSchema.routes.serviceCodes.create.component}`),
  },
  {
    path: AlltypesSchema.routes.serviceCodes.edit.path,
    name: AlltypesSchema.routes.serviceCodes.edit.name,
    meta: AlltypesSchema.routes.serviceCodes.edit.meta,
    component: () => import(/* webpackChunkName: 'edit-service-codes' */ `${AlltypesSchema.routes.serviceCodes.edit.component}`),
  },
];
