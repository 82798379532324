import {
  ICON_DESCRIPTION,
  ICON_HANDS_SHAKE,
  ICON_TAXES,
  ICON_TRUCK,
} from '@constants/icons';
import { OPTIONS_FREIGHT, OPTIONS_ORDERSPURCHASESALE_STATUS, OPTIONS_PRESENCE_INDICATOR } from '@constants/options';
import DevolutionConsignmentBusiness from '@businesses/DevolutionConsignment';
import DevolutionConsignmentItem from './DevolutionConsignmentItem';
import DevolutionConsignmentItemTax from './DevolutionConsignmentItemTax';
import DevolutionConsignmentTransporter from './DevolutionConsignmentTransporter';

import TabDevolutionConsignment from '@modules/supply/pages/consignments/components/TabDevolutionConsignment';
import TabDevolutionConsignmentItens from '@modules/supply/pages/consignments/components/TabDevolutionConsignmentItens';
import TabDevolutionConsignmentItensTax from '@modules/supply/pages/consignments/components/TabDevolutionConsignmentItensTax';
import TabDevolutionConsignmentTransporter from '@modules/supply/pages/consignments/components/TabDevolutionConsignmentTransporter';

import ProductSchema from './Product';

export default {
  service: 'DevolutionConsignments',
  business: DevolutionConsignmentBusiness,
  icon: ICON_HANDS_SHAKE,
  routes: {
    list: { path: '/supply/consignments/devolutions', name: 'supply-devolutionconsignments-list' },
    create: { path: '/supply/consignments/devolutions/create', name: 'supply-devolutionconsignments-create' },
    edit: { path: '/supply/consignments/devolutions/edit/:id', name: 'supply-devolutionconsignments-edit' },
  },
  titles: {
    item: 'Consignação - Devolução',
    items: 'Consignações - Devoluções',
    edit: 'Editar Consignação - Devoluções',
    create: 'Criar Consignação - Devoluções',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Consignação - devolução excluida com sucesso!',
    successEdit: 'Consignação - devolução alterada com sucesso!',
    successCreate: 'Consignação - devolução criada com sucesso!',
    errorSave: 'Erro ao salvar consignação - devolução!',
    tryEditCanceled: 'Não é possível editar uma consignação - devolução cancelada.',
    confirmCancel: 'Deseja cancelar a consignação - devolução?',
    successCancel: 'Consignação - devolução cancelada com sucesso',
    errorCancel: 'Erro ao cancelar consignação - devolução',
  },
  filterUrl: 'order_purchases/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  statusSettings: {
    options: OPTIONS_ORDERSPURCHASESALE_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  formName: 'DevolutionConsignments',
  fields: [
    {
      label: 'INFORMAÇÕES DA CONSIGNAÇÃO - DEVOLUÇÕES',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
    },
    {
      label: 'Status',
      formattedName: 'status',
      name: 'status',
      type: 'select',
      options: 'orders_purchase_sale_status',
      list: true,
    },
    {
      label: 'Cliente',
      name: 'buyer_id',
      formattedName: 'buyer.to_s',
      type: 'number',
      hint: '',
      list: true,
      required: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_register_array: 'client',
          query: ['id', 'name', 'cnpj_cpf', 'addresses', ['id', 'to_s'], 'preference', ['salesman_id', 'shipping_company_id']],
        }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      withDetails: true,
      typeDetails: 'vendor',
      md: 8,
    },
    {
      label: 'Pedido',
      name: 'consignment_devolution_order',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 2,
    },
    {
      label: 'Data Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      list: true,
    },
    {
      label: 'Vendedor',
      name: 'salesman_id',
      formattedName: 'salesman.to_s',
      type: 'number',
      list: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{ type_register_array: 'salesman', query: ['id', 'name'] }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      md: 6,
    },
    {
      label: 'NFe',
      name: 'note',
      formattedName: 'note_formatted',
      list: true,
      type: 'text',
      component: 'dynamic-text-field',
      readOnly: true,
      md: 2,
    },
    {
      label: 'Data Previsão',
      name: 'forecast_date',
      formatteName: 'forecast_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
    },
    {
      label: 'Consumidor Final',
      name: 'is_costumer',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
    },
    {
      label: 'Indicador de Presença',
      name: 'presence_indicator',
      formattedName: 'presence_indicator_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_PRESENCE_INDICATOR,
      md: 5,
      required: true,
    },
    {
      label: 'Nº da Ordem',
      name: 'order',
      type: 'text',
      component: 'dynamic-text-field',
      md: 2,
      list: true,
    },
    {
      label: 'Data Entrega',
      name: 'delivery_date',
      formatteName: 'delivery_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      md: 2,
    },
    {
      label: 'Total de Impostos Aproximados',
      formattedName: 'total_tax_aproximates',
      name: 'total_tax_aproximates',
      type: 'number',
    },
    {
      label: 'Valor Total',
      formattedName: 'total_ordered_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'INFORMAÇÕES DE FRETE',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Frete',
      name: 'freight',
      formattedName: 'freight_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_FREIGHT,
      required: true,
      md: 3,
    },
    {
      label: 'Valor do Frete',
      name: 'freight_value',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Despesas',
      name: 'expense_value',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Incluir Frete no Total',
      name: 'use_freight',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
    },
    {
      label: 'TRIBUTAÇÕES DO PEDIDO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      formattedName: 'nature_operation.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_nature_in: ['purchase'],
          query: ['id', 'name'],
        }],
      },
      itemText: 'name',
      itemValue: 'id',
      list: true,
      required: true,
      md: 8,
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      formattedName: 'stock.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 4,
    },
  ],
  childSchemas: [
    {
      label: 'Itens da Consignação - Devolução',
      name: 'items',
      schema: DevolutionConsignmentItem,
    },
    {
      label: 'Conferência de Impostos',
      name: 'taxes',
      noCreate: true,
      noEdit: true,
      noDelete: true,
      noActions: true,
      schema: DevolutionConsignmentItemTax,
      hideDefaultHeader: true,
      customHeader: {
        customClass: 'custom-child-header-order-purchase',
        ungroupedHeadersFront: 2,
        groupHeaders: [
          { text: 'SIMPLES NACIONAL', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS ST', colspan: 3, class: 'order-purchase-header' },
          { text: 'IPI', colspan: 3, class: 'order-purchase-header' },
          { text: 'PIS', colspan: 3, class: 'order-purchase-header' },
          { text: 'COFINS', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS CONSUMIDOR FINAL', colspan: 3, class: 'order-purchase-header' },
          { text: 'VALOR APROXIMADOS', colspan: 3, class: 'order-purchase-header' },
        ],
      },
    },
    {
      label: 'Transportadora',
      name: 'transporter',
      schema: DevolutionConsignmentTransporter,
      hasOne: true,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabDevolutionConsignment,
    },
    {
      icon: ProductSchema.icon,
      title: 'Itens da Consignação - Devolução',
      tabContent: TabDevolutionConsignmentItens,
    },
    {
      icon: ICON_TAXES,
      title: 'Conferência de Impostos',
      tabContent: TabDevolutionConsignmentItensTax,
    },
    {
      icon: ICON_TRUCK,
      title: 'Transportadora',
      tabContent: TabDevolutionConsignmentTransporter,
    },
  ],
};
