import { ICON_STOCK, ICON_HISTORIC, ICON_APPLICATION_IMPORT } from '@constants/icons';
import TabStock from '@modules/supply/pages/stocks/components/TabStock';

export default {
  service: 'Stocks',
  icon: ICON_STOCK,
  routes: {
    list: 'supply-stocks-list',
    edit: 'supply-stocks-edit',
    create: 'supply-stocks-create',
  },
  titles: {
    item: 'Estoque',
    items: 'Estoques',
    edit: 'Editar Estoque',
    create: 'Novo Estoque',
    list: 'Listar Estoques',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'stocks/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 8,
    },
    {
      label: 'Tipo do Estoque',
      name: 'stock_type_id',
      formattedName: 'stock_type.to_s',
      type: 'text',
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-stocktypes-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['stock_type'],
      },
      itemText: 'description',
      itemValue: 'id',
      list: true,
      md: 4,
    },
    {
      label: 'Principal',
      name: 'use_main',
      formattedName: 'use_main_formatted',
      type: 'boolean',
      component: 'dynamic-checkbox',
      list: true,
      disabled: true,
      md: 4,
    },
    {
      label: 'Inativo',
      name: 'use_inactive',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 4,
    },
    /*
    {
      label: 'Compartilhar Estoque',
      name: 'use_shared',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 4,
    },
    */
    {
      label: 'Total Disponível',
      name: 'total_stock',
      list: true,
    },
    {
      label: 'Total Geral',
      name: 'total_stock',
      list: true,
    },
  ],
  actions: [
    {
      type: 'route',
      icon: ICON_APPLICATION_IMPORT,
      route: 'supply-stocks-items',
      ariaLabel: 'Itens',
      color: 'secondary',
    },
    {
      type: 'route',
      icon: ICON_HISTORIC,
      color: 'secondary',
      route: 'supply-stockhistories-launchs',
      target: '_blank',
      ariaLabel: 'Histórico',
    },
  ],
  tabs: [
    {
      icon: ICON_STOCK,
      title: 'Itens no Estoque',
      tabContent: TabStock,
    },
  ],
};
