import { onlyNumbers } from '@plugins/app-utils';

const endpoint = 'partners_mailer';

function formatRequest(payload) {
  const {
    cpf,
    phone,
    cnpj,
  } = payload;

  return {
    ...payload,
    cpf: onlyNumbers(cpf),
    phone: onlyNumbers(phone),
    cnpj: onlyNumbers(cnpj),
  };
}

const CreditPartnerFormCommands = ($axios) => ({
  async send(payload) {
    const formattedRequest = formatRequest(payload);
    await $axios.post(`${endpoint}`, formattedRequest);
  },
});

export { CreditPartnerFormCommands };
export default { CreditPartnerFormCommands };
