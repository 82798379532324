import GeneralPurchasesReportsSchema from './GeneralPurchasesReportsSchema';

const GeneralPurchasesReports = () => import(/* webpackChunkName: "reports-purchases-general" */'./GeneralPurchasesReports');
export default [
  {
    path: GeneralPurchasesReportsSchema.route.path,
    name: GeneralPurchasesReportsSchema.route.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Relatórios' },
        { name: 'Compras' },
      ],
    },
    component: GeneralPurchasesReports,
  },
];
