import { ICON_PRODUCT } from '@constants/icons';

export default {
  service: 'Assignors',
  icon: ICON_PRODUCT,
  routes: {
    list: {
      path: '/anticipation',
      name: 'anticipation-dashboard-list',
    },
    show: {
      path: '/anticipation/assignors/:id',
      name: 'anticipation-assignors-show',
    },
  },
  titles: {
    item: 'iCertus Antecipa',
    items: 'iCertus Antecipa',
  },
  filterUrl: '', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: '#',
      name: 'id',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Empresa',
      name: 'name',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'CNPJ',
      name: 'cnpj',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'R$ Faturado',
      name: 'fatured',
      formattedName: 'fatured_formatted',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Valor Antecipado',
      name: 'anticipation',
      formattedName: 'anticipation_formatted',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Classificação Certus',
      name: 'certus',
      formattedName: 'certus_formatted',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
  ],
};
