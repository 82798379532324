import Vue from 'vue';
import * as AppUtils from '@plugins/app-utils';

export const setTaxesValues = (formValue, { items }) => {
  const unsetTax = 0.00;
  const updatedItems = formValue.items.map((item, index) => {
    const issTaxes = {
      percentage_csll: items[index]?.iss?.percentage_csll ?? unsetTax,
      value_csll: items[index]?.iss?.value_csll ?? unsetTax,
      is_withhold_csll: items[index]?.iss?.is_withhold_csll ?? false,

      percentage_csll_formatted: AppUtils.formatNumber(items[index]?.iss?.percentage_csll ?? unsetTax),
      value_csll_formatted: AppUtils.formatNumber(items[index]?.iss?.value_csll ?? unsetTax),
      is_withhold_csll_formatted: AppUtils.formatBoolean(items[index]?.iss?.is_withhold_csll ?? false),

      percentage_inss: items[index]?.iss?.percentage_inss ?? unsetTax,
      value_inss: items[index]?.iss?.value_inss ?? unsetTax,
      is_withhold_inss: items[index]?.iss?.is_withhold_inss ?? false,

      percentage_inss_formatted: AppUtils.formatNumber(items[index]?.iss?.percentage_inss ?? unsetTax),
      value_inss_formatted: AppUtils.formatNumber(items[index]?.iss?.value_inss ?? unsetTax),
      is_withhold_inss_formatted: AppUtils.formatBoolean(items[index]?.iss?.is_withhold_inss ?? false),

      percentage_ir: items[index]?.iss?.percentage_ir ?? unsetTax,
      value_ir: items[index]?.iss?.value_ir ?? unsetTax,
      is_withhold_ir: items[index]?.iss?.is_withhold_ir ?? false,

      percentage_ir_formatted: AppUtils.formatNumber(items[index]?.iss?.percentage_ir ?? unsetTax),
      value_ir_formatted: AppUtils.formatNumber(items[index]?.iss?.value_ir ?? unsetTax),
      is_withhold_ir_formatted: AppUtils.formatBoolean(items[index]?.iss?.is_withhold_ir ?? false),

      percentage_iss: items[index]?.iss?.percentage_iss ?? unsetTax,
      value_iss: items[index]?.iss?.value_iss ?? unsetTax,
      is_withhold_iss: items[index]?.iss?.is_withhold_iss ?? false,

      percentage_iss_formatted: AppUtils.formatNumber(items[index]?.iss?.percentage_iss ?? unsetTax),
      value_iss_formatted: AppUtils.formatNumber(items[index]?.iss?.value_iss ?? unsetTax),
      is_withhold_iss_formatted: AppUtils.formatBoolean(items[index]?.iss?.is_withhold_iss ?? false),

      percentage_pis: items[index]?.iss?.percentage_pis ?? unsetTax,
      value_pis: items[index]?.iss?.value_pis ?? unsetTax,
      is_withhold_pis: items[index]?.iss?.is_withhold_pis ?? false,

      percentage_pis_formatted: AppUtils.formatNumber(items[index]?.iss?.percentage_pis ?? unsetTax),
      value_pis_formatted: AppUtils.formatNumber(items[index]?.iss?.value_pis ?? unsetTax),
      is_withhold_pis_formatted: AppUtils.formatBoolean(items[index]?.iss?.is_withhold_pis ?? false),

      percentage_cofins: items[index]?.iss?.percentage_cofins ?? unsetTax,
      value_cofins: items[index]?.iss?.value_cofins ?? unsetTax,
      is_withhold_cofins: items[index]?.iss?.is_withhold_cofins ?? false,

      percentage_cofins_formatted: AppUtils.formatNumber(items[index]?.iss?.percentage_cofins ?? unsetTax),
      value_cofins_formatted: AppUtils.formatNumber(items[index]?.iss?.value_cofins ?? unsetTax),
      is_withhold_cofins_formatted: AppUtils.formatBoolean(items[index]?.iss?.is_withhold_cofins ?? false),
    };
    return {
      ...item,
      iss: issTaxes,
      product_id: item.product?.id || item.product_id,
      product_code: item.product?.code || item.product_code,
      quantity_formatted: AppUtils.formatNumber(item.quantity || 0),
      unit_value_formatted: AppUtils.formatCurrency(parseFloat(item.unit_value || 0)),
      discount_value_formatted: AppUtils.formatCurrency(parseFloat(item.discount_value || 0)),
      discount_percentage_formatted: `${AppUtils.formatNumber(parseFloat(item.discount_percentage_value))}%`,
      total_value_formatted: AppUtils.formatCurrency(parseFloat(item.total_value || 0)),
    };
  });
  formValue.items = updatedItems;
  formValue.taxes = updatedItems;
  Vue.set(formValue, 'items', formValue.items);
  Vue.set(formValue, 'taxes', formValue.taxes);
};
