<template>
  <div>
    <v-img
      :class="'text-'+ size +'-'+ propTag"
      :src="src"
      :max-width="maxWidth"
      :max-height="maxHeight"
      :width="width"
      :height="heigth"
      :style="rounded && 'border-radius: 50%'"
      v-bind="$attrs"
      v-on="$listeners" />
  </div>
</template>

<script>
export default {
  name: 'DynamicTitle',
  props: {
    size: {
      type: String,
      default: 'md',
    },
    propTag: {
      type: String,
      default: 'h1',
    },
    src: {
      type: String,

    },
    maxWidth: {
      type: String,
      default: '100%',
    },
    maxHeight: {
      type: String,
      default: '100%',
    },
    width: {
      type: String,
      default: '100px',
    },
    heigth: {
      type: String,
      default: '100px',
    },
    alt: {
      type: String,
      default: 'imagem',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    };
  },

};
</script>
