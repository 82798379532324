import AvailableInvoiceSchema from '@schemas/AvailableInvoice';

const ListAvailableInvoices = () => import(/* webpackChunkName: "availableinvoices-list" */'./ListAvailableInvoices');

export default [
  {
    path: AvailableInvoiceSchema.routes.list.path,
    name: AvailableInvoiceSchema.routes.list.name,
    meta: {
      requiresAuth: false,
      antecipation: true,
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Antecipação de Recebíveis' },
        { name: 'Notas Ficais Disponíveis' },
      ],
    },
    component: ListAvailableInvoices,
  },
];
