import GeneralStocksHistoriesReportsSchema from './GeneralStocksHistoriesReportsSchema';

const GeneralStocksHistoriesReports = () => import(/* webpackChunkName: "general-stocks-histories-reports" */'./GeneralStocksHistoriesReports');

export default [
  {
    path: GeneralStocksHistoriesReportsSchema.route.path,
    name: GeneralStocksHistoriesReportsSchema.route.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Relatórios' },
        { name: 'Estoques' },
      ],
    },
    component: GeneralStocksHistoriesReports,
  },
];
