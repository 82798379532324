import {
  ICON_CART,
  ICON_DESCRIPTION,
  ICON_MEASURING_UNIT,
  ICON_TAXES,
  ICON_TRUCK,
} from '@constants/icons';
import { OPTIONS_PRESENCE_INDICATOR, OPTIONS_FREIGHT, OPTIONS_ORDERSPURCHASESALE_STATUS } from '@constants/options';

import DevolutionPurchaseBusiness from '@businesses/DevolutionPurchase';
import DevolutionPurchaseItem from './DevolutionPurchaseItem';
import DevolutionPurchaseItemTax from './DevolutionPurchaseItemTax';
import DevolutionPurchaseTransporter from './DevolutionPurchaseTransporter';

import TabDevolutionPurchase from '@modules/supply/pages/devolutionPurchases/components/TabDevolutionPurchase';
import TabDevolutionPurchaseItens from '@modules/supply/pages/devolutionPurchases/components/TabDevolutionPurchaseItens';
import TabDevolutionPurchaseItensTax from '@modules/supply/pages/devolutionPurchases/components/TabDevolutionPurchaseItensTax';
import TabDevolutionPurchaseTransporter from '@modules/supply/pages/devolutionPurchases/components/TabDevolutionPurchaseTransporter';

const orderPurchaseItems = [];
export function getOrderPurchasesItems() { return orderPurchaseItems; }
export function setOrderPurchasesItems(param) { orderPurchaseItems.push(param); }
export function resetOrderPurchasesItems() { orderPurchaseItems.splice(0); }

export default {
  service: 'DevolutionPurchases',
  business: DevolutionPurchaseBusiness,
  icon: ICON_CART,
  routes: {
    list: { path: '/supply/purchases/devolutions', name: 'supply-purchasedevolutions-list' },
    create: { path: '/supply/purchases/devolutions/create', name: 'supply-purchasedevolutions-create' },
    edit: { path: '/supply/purchases/devolutions/edit/:id', name: 'supply-purchasedevolutions-edit' },
  },
  titles: {
    item: 'Devolução de Compras',
    items: 'Devoluções de Compras',
    edit: 'Editar Devolução de Compras',
    create: 'Criar Devolução de Compras',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Devolução de compras excluida com sucesso!',
    successEdit: 'Devolução de compras alterada com sucesso!',
    successCreate: 'Devolução de compras criada com sucesso!',
    errorSave: 'Erro ao salvar devolução de compras!',
    tryEditCanceled: 'Não é possível editar uma devolução de compras cancelada.',
    confirmCancel: 'Deseja cancelar a devolução de compras?',
    successCancel: 'Devolução de compras cancelada com sucesso',
    errorCancel: 'Erro ao cancelar devolução de compras',
  },
  filterUrl: 'purchase_devolutions/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  canClone: false,
  statusSettings: {
    options: OPTIONS_ORDERSPURCHASESALE_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  formName: 'purchasesdevolutions',
  fields: [
    {
      label: 'INFORMAÇÕES DA DEVOLUÇÃO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
      tabGroup: 'infoPurchases',
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options: 'orders_purchase_sale_status',
      list: true,
    },
    {
      label: 'Fornecedor',
      name: 'vendor_id',
      formattedName: 'vendor.to_s',
      type: 'number',
      hint: '',
      list: true,
      required: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_register_array: 'vendor',
          query: ['id', 'name', 'cnpj_cpf', 'addresses', ['id', 'to_s'], 'preference', ['shipping_company_id']],
        }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      withDetails: true,
      typeDetails: 'vendor',
      md: 8,
      tabGroup: 'infoPurchases',
      disabled: (formValue) => {
        if (formValue?.id) {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Pedido de Compra',
      name: 'order_purchase_id',
      hint: 'Escolha um fornecedor para carregar os pedidos',
      formattedName: 'purchase_order',
      type: 'string',
      component: 'dynamic-select',
      items: orderPurchaseItems,
      disabled: (formValue) => {
        if (formValue?.id) {
          return true;
        }
        if (formValue?.vendor_id) {
          return false;
        }
        return false;
      },
      md: 2,
      required: true,
      tabGroup: 'infoPurchases',
    },
    {
      label: 'Data Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      list: true,
      tabGroup: 'infoPurchases',
    },
    {
      label: 'Consumidor Final',
      name: 'is_costumer',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPurchases',
    },
    {
      label: 'Indicador de Presença',
      name: 'presence_indicator',
      formattedName: 'presence_indicator_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_PRESENCE_INDICATOR,
      md: 4,
      required: true,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPurchases',
    },
    {
      label: 'Devolução',
      name: 'purchase_devolution',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 2,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPurchases',
    },
    {
      label: 'NFe',
      name: 'note',
      formattedName: 'note_formatted',
      list: true,
      type: 'text',
      component: 'dynamic-text-field',
      readOnly: true,
      md: 2,
      disabled: true,
      tabGroup: 'infoPurchases',
    },

    {
      label: 'Data Entrega',
      name: 'delivery_date',
      formattedName: 'delivery_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      md: 2,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPurchases',
    },
    {
      label: 'Forma de Pagamento',
      name: 'payment_method_id',
      formattedName: 'payment_method.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-paymentmethod-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['payment_method'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPurchases',
    },
    {
      label: 'Parcelamento',
      name: 'type_installment_id',
      formattedName: 'type_installment.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-typeinstallment-create',
      dataSource: {
        service: 'TypeInstallment',
        method: 'getByFilter',
        props: [{ inactive_eq: false }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPurchases',
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      formattedName: 'stock.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPurchases',
    },
    {
      label: 'Total de Impostos Aproximados',
      formattedName: 'total_tax_aproximates',
      name: 'total_tax_aproximates',
      type: 'number',
    },
    {
      label: 'Valor Total',
      formattedName: 'total_ordered_formatted',
      type: 'number',
      list: true,
      tabGroup: 'infoPurchases',
    },
    {
      label: 'INFORMAÇÕES DE FRETE',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'infoFreight',
    },
    {
      label: 'Frete',
      name: 'freight',
      formattedName: 'freight_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_FREIGHT,
      required: true,
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoFreight',
    },
    {
      label: 'Valor do Frete',
      name: 'freight_value',
      formattedName: 'freight_value_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoFreight',
    },
    {
      label: 'Despesas',
      name: 'expense_value',
      formattedName: 'expense_value_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoFreight',
    },
    {
      label: 'Incluir Frete no Total',
      name: 'use_freight',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoFreight',
    },
    {
      label: 'TRIBUTAÇÕES DA DEVOLUÇÃO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      formattedName: 'nature_operation.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_nature_in: ['devolution_purchase'],
          query: ['id', 'name'],
        }],
      },
      itemText: 'name',
      itemValue: 'id',
      list: true,
      required: true,
      md: 12,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPurchases',
    },
  ],
  childSchemas: [
    {
      label: 'Itens da Devolução de Compras',
      name: 'items',
      schema: DevolutionPurchaseItem,
      noCreate: true,
    },
    {
      label: 'Conferência de Impostos',
      name: 'taxes',
      noCreate: true,
      noEdit: true,
      noDelete: true,
      noActions: true,
      schema: DevolutionPurchaseItemTax,
      hideDefaultHeader: true,
      customHeader: {
        customClass: 'custom-child-header-order-purchase',
        ungroupedHeadersFront: 2,
        groupHeaders: [
          { text: 'SIMPLES NACIONAL', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS ST', colspan: 3, class: 'order-purchase-header' },
          { text: 'IPI', colspan: 3, class: 'order-purchase-header' },
          { text: 'PIS', colspan: 3, class: 'order-purchase-header' },
          { text: 'COFINS', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS CONSUMIDOR FINAL', colspan: 3, class: 'order-purchase-header' },
          { text: 'VALOR APROXIMADOS', colspan: 3, class: 'order-purchase-header' },
        ],
      },
    },
    {
      label: 'Transportadora',
      name: 'transporter',
      schema: DevolutionPurchaseTransporter,
      hasOne: true,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabDevolutionPurchase,
    },
    {
      icon: ICON_MEASURING_UNIT,
      title: 'Itens do Devolução de Compra',
      tabContent: TabDevolutionPurchaseItens,
    },
    {
      icon: ICON_TAXES,
      title: 'Conferência de Impostos',
      tabContent: TabDevolutionPurchaseItensTax,
    },
    {
      icon: ICON_TRUCK,
      title: 'Transportadora',
      tabContent: TabDevolutionPurchaseTransporter,
    },
  ],
};
