import Vue from 'vue';
import { setCitiesItems, resetCitiesItems } from '@schemas/InvoiceTemplate.js';

const fillSelectCities = async (uf) => {
  await Vue.prototype.$api.InvoiceTemplatesCommands.getCities(uf).then((cities) => {
    if (cities && cities.length > 0) {
      resetCitiesItems();
      cities.forEach((city) => { setCitiesItems({ text: city.name, value: city.name }); });
    }
  });
};

export default {
  screenEvents: {
    async beforeSave(formValue) {
      if (formValue.type_invoice === 'nfse' && !formValue.uf) {
        return 'Preencha a UF para salvar notas do tipo NFSe';
      }
      if (formValue.type_invoice === 'nfse' && !formValue.city) {
        return 'Preencha a cidade/municipio para salvar configurações do tipo NFSe';
      }
    },
  },
  fieldEvents: {
    uf: {
      change: async (fieldValue) => {
        fillSelectCities(fieldValue.uf);
      },
    },
  },
};
