<template>
  <div :id="item.id">
    <GenericCardPanel
      title="Informações do Local de Prestação do Serviço"
      :fields="orderServiceLocalFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabOrderSaleTransporter',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      orderServiceLocalFields: [],
    };
  },
  mounted() {
    this.orderServiceLocalFields = [
      { label: 'Tipo de Endereço', value: this.item?.provision_address?.origin_formatted, col: 4 },
      { label: 'UF', value: this.item?.provision_address?.uf, col: 2 },
      { label: 'Município', value: this.item?.provision_address?.city, col: 6 },
    ];
  },
};
</script>
