import { Assignors } from './pages/assignors/services.js';
import { AvailableInvoices } from './pages/availableinvoices/services.js';
import { Drawees } from './pages/drawees/services.js';

export { default as routes } from './routes';

export const globalServices = {
  Assignors,
  AvailableInvoices,
  Drawees,
};
