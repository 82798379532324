export default {
  service: 'Magis5',
  routes: {
    list: {
      path: '/sellmore/integration/magis5',
      name: 'sellmore-integration-magis5',
    },
  },
  titles: {
    item: 'Magis5',
    items: 'Magis5',
    edit: 'Magis5',
    create: 'Magis5',
    new: 'Magis5',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [],
};
