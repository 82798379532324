<template>
  <div class="tab-order-sale-itens">
    <GenericCardForm>
      <DataList :headers="headers"
                :items="setOrderPurchaseDevolutions(item)"
                item-key="id"
                no-edit
                no-delete
                no-check
                :sort-by="['id']"
                :sort-desc="[false, true]" />
    </GenericCardForm>
  </div>
</template>

<script>
import { ICON_REFRESH } from '@constants/icons';
import DataList from '@sharedComponents/dynamic/DataList';
import GenericCardForm from '@sharedComponents/dynamic/GenericCardForm';

export default {
  name: 'TabOrderPurchaseDevolutions',
  components: {
    DataList,
    GenericCardForm,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      icons: {
        refresh: ICON_REFRESH,
      },
      headers: [
        { text: 'Status', value: 'status_formatted' },
        { text: 'Data Emissão', value: 'emission_date_formatted' },
        { text: 'Data Entrega', value: 'delivery_date_formatted' },
        { text: 'NFe', value: 'note_formatted' },
        { text: 'Valor Total', value: 'total_ordered_formatted' },
        // { text: 'Criado por', value: 'total_ordered_formatted' },
        // { text: 'Atualizado por', value: 'total_ordered_formatted' },
        // { text: 'Data de Alteração', value: 'total_ordered_formatted' },
      ],
      actions: [],
      filterParams: {},
    };
  },
  computed: {},
  methods: {
    setOrderPurchaseDevolutions(item) {
      const OrderPurchaseDevolutions = item.devolution_purchase;
      return OrderPurchaseDevolutions;
    },
  },
};
</script>
