import {
  ICON_DESCRIPTION, ICON_TOOLS, ICON_TAXES, ICON_TRUCK,
} from '@constants/icons';
import * as Options from '@constants/options';
import OrderServiceBusiness from '@businesses/OrderService'; // Use same business rules
import OrderServiceItemSchema from './OrderServiceItem';
import OrderServiceItemTaxSchema from './OrderServiceItemTax';
import OrderServiceInvoiceDetailsSchema from './OrderServiceInvoiceDetails';

import TabOrderService from '@modules/supply/pages/orderServices/components/TabOrderService';
import TabOrderServicesItems from '@modules/supply/pages/orderServices/components/TabOrderServiceItems';
import TabOrderServicesItemsTax from '@modules/supply/pages/orderServices/components/TabOrderServiceItemsTax';
import TabOrderServiceTransporter from '@modules/supply/pages/orderServices/components/TabOrderServiceTransporter';
import TabOrderServiceInvoiceDetail from '@modules/supply/pages/orderServices/components/TabOrderServiceInvoiceDetail';

import PriceListSchema from './PriceList';
import ProductSchema from './Product';

const citiesItems = [];

export function setCitiesItems(params) {
  citiesItems.push(params);
}
export function getCitiesItems() {
  return citiesItems;
}
export function resetCitiesItems() {
  citiesItems.splice(0);
}

export default {
  service: 'OrderServices',
  business: OrderServiceBusiness,
  icon: ICON_TOOLS,
  routes: {
    list: { path: '/supply/order-services', name: 'supply-orderservices-list' },
    create: { path: '/supply/order-services/create', name: 'supply-orderservices-create' },
    edit: { path: '/supply/order-services/edit/:id', name: 'supply-orderservices-edit' },
  },
  titles: {
    item: 'Ordem de Serviços',
    items: 'Ordens de Serviços',
    edit: 'Editar Ordem de Serviços',
    create: 'Criar Ordem de Serviços',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Ordem de Serviços excluida com sucesso!',
    successEdit: 'Ordem de Serviços alterada com sucesso!',
    successCreate: 'Ordem de Serviços criada com sucesso!',
    errorSave: 'Erro ao salvar ordem de Serviços!',
    tryEditCanceled: 'Não é possível editar uma ordem de Serviços cancelada.',
    confirmCancel: 'Deseja cancelar a ordem de Serviços?',
    successCancel: 'Ordem de Serviços cancelada com sucesso',
    errorCancel: 'Erro ao cancelar ordem de Serviços',
  },
  filterUrl: 'order_services/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  canClone: true,
  cloneType: 'post',
  // hasResetButton: true,
  statusSettings: {
    options: Options.OPTIONS_ORDERSSERVICE_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  formName: 'OrderServices',
  fields: [
    {
      label: 'INFORMAÇÕES DO SERVIÇO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
    },
    {
      label: 'Status',
      formattedName: 'status',
      name: 'status',
      type: 'select',
      options_key: 'orders_service_status',
      list: true,
    },
    {
      label: 'Cliente',
      name: 'buyer_id',
      formattedName: 'buyer.to_s',
      type: 'number',
      list: true,
      required: true,
      hint: '',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            type_register_array: 'client',
            query: ['id', 'name', 'cnpj_cpf', 'addresses', ['id', 'to_s'], 'preference', ['salesman_id', 'shipping_company_id']],
          },
          'name asc',
        ],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      withDetails: true,
      typeDetails: 'client',
      md: 8,
    },
    {
      label: 'Ordem',
      name: 'order',
      type: 'number',
      mask: '####################',
      list: true,
      required: false,
      component: 'dynamic-text-field',
      md: 2,
    },
    {
      label: 'NF-Serviço',
      name: 'nfse_number',
      formattedName: 'nfse_number_formatted',
      type: 'text',
      list: true,
    },
    {
      label: 'Data Inicio',
      name: 'start_datetime',
      formattedName: 'start_datetime_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      list: true,
      required: true,
    },
    {
      label: 'Vendedor',
      name: 'salesman_id',
      formattedName: 'salesman.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            type_register_array: 'salesman',
            query: ['id', 'name'],
          },
          'name asc',
        ],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      md: 8,
    },
    {
      label: 'Nº da NFS-e',
      name: 'nfse_number',
      type: 'text',
      component: 'dynamic-text-field',
      readOnly: true,
      md: 2,
    },
    {
      label: 'Data Competência',
      name: 'competence_datetime',
      formattedName: 'competence_datetime_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      list: true,
      required: true,
    },
    {
      label: 'Prestador',
      name: 'provider_id',
      formattedName: 'provider.to_s',
      type: 'number',
      list: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilter',
        props: [{ type_register_array: 'provider', query: ['id', 'name'] }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      md: 6,
    },
    {
      label: 'Moeda',
      name: 'coin_id',
      formattedName: 'coin.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-coins-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['coin'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 2,
    },
    {
      label: 'Lista de Preço',
      name: 'price_list_id',
      formattedName: 'price_list.to_s',
      type: 'number',
      component: 'dynamic-select',
      clearable: true,
      dataSource: {
        service: 'PriceList',
        method: 'getByFilter',
        props: [
          {
            is_inactive_eq: false,
          },
          'name asc',
        ],
      },
      itemText: 'name',
      itemValue: 'id',
      md: 2,
      redirectCreateRoute: PriceListSchema.routes.create.name,
    },
    {
      label: 'Data Final',
      name: 'end_datetime',
      formattedName: 'end_datetime_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
      md: 2,
    },
    {
      label: 'Consumidor Final',
      name: 'is_costumer',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
    },
    {
      label: 'Indicador de Presença',
      name: 'presence_indicator',
      formattedName: 'presence_indicator_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: Options.OPTIONS_PRESENCE_INDICATOR,
      required: true,
      md: 3,
    },
    {
      label: 'Forma de Pagamento',
      name: 'payment_method_id',
      formattedName: 'payment_method.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-paymentmethod-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['payment_method'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
    },
    {
      label: 'Parcelamento',
      name: 'type_installment_id',
      formattedName: 'type_installment.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-typeinstallment-create',
      dataSource: {
        service: 'TypeInstallment',
        method: 'getByFilter',
        props: [{ inactive_eq: false }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
    },
    /** Alocar como totalizador
    {
      label: 'Despesas',
      name: 'expense_value',
      formattedName: 'expense_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      md: 6,
    },
    {
      label: 'Desconto do Serviço',
      name: 'service_discount_value',
      formattedName: 'service_discount_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      md: 6,
    },
    */
    /*
    {
      label: 'Data Execução',
      name: 'execution_date',
      formattedName: 'execution_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
      md: 2,
    },
    */
    {
      label: 'Total do Serviço',
      formattedName: 'total_services_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Total de Impostos Retidos',
      formattedName: 'total_withheld_taxes',
      name: 'total_withheld_taxes_formatted',
      type: 'number',
    },
    {
      label: 'Valor Total',
      formattedName: 'total_order_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'LOCAL DA PRESTAÇÃO DO SERVIÇO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Tipo de Endereço',
      name: 'provision_address.origin',
      formattedName: 'provision_address.origin_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: Options.OPTIONS_ORDERSSERVICE_ADDRESS_TYPE,
      required: true,
      list: true,
      md: 4,
    },
    {
      label: 'UF',
      name: 'provision_address.uf',
      type: 'string',
      component: 'dynamic-select',
      items: Options.OPTIONS_UF,
      required: true,
      list: true,
      md: 4,
    },
    {
      label: 'Município',
      name: 'provision_address.city',
      hint: 'Selecione o estado para carregar as cidades',
      type: 'text',
      list: true,
      required: true,
      items: citiesItems,
      component: 'dynamic-select',
      md: 4,
    },
    {
      label: 'TRIBUTAÇÕES DO SERVIÇO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      formattedName: 'nature_operation.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            type_nature_eq: 'nfse',
            query: ['id', 'name'],
          },
        ],
      },
      itemText: 'name',
      itemValue: 'id',
      list: true,
      required: true,
      md: 3,
    },
    {
      label: 'Código de Serviço',
      name: 'service_code_id',
      formattedName: 'service_code.to_s',
      type: 'number',
      list: true,
      required: true,
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-servicecodes-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['service_code'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
    },
    {
      label: 'Situação tributaria ISSQN',
      name: 'code_situation_issqn_id',
      formattedName: 'code_situation_issqn_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-serviceissqn-create',
      clearable: true,
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['service_situation_issqn'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
    },
    {
      label: 'Serviço Tributário',
      name: 'service_nature_operation',
      formattedName: 'service_nature_operation_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: Options.OPTIONS_ISS_NATURE_OPERATION,
      required: true,
      md: 3,
    },
  ],
  childSchemas: [
    {
      label: 'Serviços',
      name: 'items',
      schema: OrderServiceItemSchema,
    },
    {
      label: 'Conferência de Impostos',
      name: 'taxes',
      noCreate: true,
      noEdit: true,
      noDelete: true,
      noActions: true,
      schema: OrderServiceItemTaxSchema,
      hideDefaultHeader: true,
      customHeader: {
        customClass: 'custom-child-header-order-service',
        ungroupedHeadersFront: 2,
        groupHeaders: [
          { text: 'CSLL', colspan: 3, class: 'order-service-header' },
          { text: 'INSS', colspan: 3, class: 'order-service-header' },
          { text: 'IR', colspan: 3, class: 'order-service-header' },
          { text: 'ISS', colspan: 3, class: 'order-service-header' },
          { text: 'PIS', colspan: 3, class: 'order-service-header' },
          { text: 'COFINS', colspan: 3, class: 'order-service-header' },
        ],
      },
    },
    {
      label: 'Descrição para Nota Fiscal',
      name: 'invoice_description',
      schema: OrderServiceInvoiceDetailsSchema,
      hasOne: true,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabOrderService,
    },
    {
      icon: ProductSchema.icon,
      title: 'Serviços',
      tabContent: TabOrderServicesItems,
    },
    {
      icon: ICON_TAXES,
      title: 'Conferência de Impostos',
      tabContent: TabOrderServicesItemsTax,
    },
    {
      icon: ICON_TRUCK,
      title: 'Local da Prestação de Serviço',
      tabContent: TabOrderServiceTransporter,
    },
    {
      icon: ICON_DESCRIPTION,
      title: 'Descrição para Nota Fiscal',
      tabContent: TabOrderServiceInvoiceDetail,
    },
  ],
};
