import Vue from 'vue';
import Toasted from 'vue-toasted';
import router from '@src/core/router';

window.addEventListener('load', () => {
  Vue.use(Toasted, {
    theme: 'bubble',
    duration: 4000,
    containerClass: 'v-application',
    position: 'top-center',
    action: {
      icon: 'close',
      class: 'white--text',
      onClick: (e, toastObject) => {
        toastObject.goAway(0);
      },
    },
    router,
  });
});
