<template>
  <div :id="item.id">
    <GenericCardPanel
      title="Informações do MDF-e"
      :fields="manifestInfoFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabManifest',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      manifestInfoFields: [],
    };
  },
  mounted() {
    this.manifestInfoFields = [
      { label: 'UF de Carregamento', value: this.item.load_uf, col: 2 },
      { label: 'UF de Descarregamento', value: this.item.unload_uf, col: 2 },
      { label: 'Data de Emissão', value: this.item.emission_date_formatted, col: 2 },
      { label: 'Hora da Emissão', value: this.item.emission_date_hour, col: 2 },
      { label: 'Data de Saída', value: this.item.exit_date_formatted, col: 2 },
      { label: 'Hora de Saída', value: this.item.exit_date_hour, col: 2 },
    ];
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style scoped></style>
