import AlltypesSchema from '@schemas/Alltypes';

export default [
  {
    path: AlltypesSchema.routes.occupations.list.path,
    name: AlltypesSchema.routes.occupations.list.name,
    meta: AlltypesSchema.routes.occupations.list.meta,
    component: () => import(/* webpackChunkName: 'list-occupations' */ `${AlltypesSchema.routes.occupations.list.component}`),
  },
  {
    path: AlltypesSchema.routes.occupations.create.path,
    name: AlltypesSchema.routes.occupations.create.name,
    meta: AlltypesSchema.routes.occupations.create.meta,
    component: () => import(/* webpackChunkName: 'create-occupations' */ `${AlltypesSchema.routes.occupations.create.component}`),
  },
  {
    path: AlltypesSchema.routes.occupations.edit.path,
    name: AlltypesSchema.routes.occupations.edit.name,
    meta: AlltypesSchema.routes.occupations.edit.meta,
    component: () => import(/* webpackChunkName: 'edit-occupations' */ `${AlltypesSchema.routes.occupations.edit.component}`),
  },
];
