import { ICON_BANK } from '@constants/icons';

export default {
  service: 'Banks',
  icon: ICON_BANK,
  route: {
    path: '/reports/banks/general',
    name: 'reports-banks-general',
  },
  title: 'Relatório de Bancos',
  filtersLayout: [
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'v-col',
          cols: '6',
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [
                {
                  label: 'Banco',
                  name: 'id',
                  type: 'number',
                  hint: '',
                  hideNot: true,
                  component: 'dynamic-select',
                  dataSource: {
                    service: 'Banks',
                    method: 'getByFilter',
                    props: [{ inactive_eq: false }],

                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 12,
                  clearable: true,
                },

              ],
            },
          ],
        },
        {
          component: 'v-col',
          cols: '6',
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [
                {
                  label: 'Datas',
                  name: 'dynamic-dates',
                  fields: [
                    {
                      name: 'launch_date',
                      label: 'Data de lançamentos',
                    },
                  ],
                  component: 'dynamic-multi-date-filter',
                  md: 12,
                  clearable: true,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
