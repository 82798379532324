import Vue from 'vue';

const calculateAliquot = (formValue) => {
  const aliquot = parseFloat(formValue.irpj_aliquot || 0)
                  + parseFloat(formValue.csll_aliquot || 0)
                  + parseFloat(formValue.cofins_aliquot || 0)
                  + parseFloat(formValue.pis_aliquot || 0)
                  + parseFloat(formValue.cpp_aliquot || 0)
                  + parseFloat(formValue.icms_aliquot || 0)
                  + parseFloat(formValue.ipi_aliquot || 0);
  return aliquot;
};

export default {
  screenEvents: {
    // Return false or a string to cancel save action, returning a String the screen will notify as an error.
    beforeSave(formValue) {
      if (!formValue.min_value) {
        formValue.min_value = 0;
      }
      if (formValue.min_value >= formValue.max_value) {
        return 'O valor mínimo deve ser superior ao valor máximo da faixa de tributação.';
      }
    },
  },
  fieldEvents: {
    irpj_aliquot: {
      async change(formValue) {
        formValue.aliquot = calculateAliquot(formValue);
        Vue.set(formValue, 'aliquot', formValue.aliquot);
      },
    },
    csll_aliquot: {
      async change(formValue) {
        formValue.aliquot = calculateAliquot(formValue);
        Vue.set(formValue, 'aliquot', formValue.aliquot);
      },
    },
    cofins_aliquot: {
      async change(formValue) {
        formValue.aliquot = calculateAliquot(formValue);
        Vue.set(formValue, 'aliquot', formValue.aliquot);
      },
    },
    pis_aliquot: {
      async change(formValue) {
        formValue.aliquot = calculateAliquot(formValue);
        Vue.set(formValue, 'aliquot', formValue.aliquot);
      },
    },
    cpp_aliquot: {
      async change(formValue) {
        formValue.aliquot = calculateAliquot(formValue);
        Vue.set(formValue, 'aliquot', formValue.aliquot);
      },
    },
    icms_aliquot: {
      async change(formValue) {
        formValue.aliquot = calculateAliquot(formValue);
        Vue.set(formValue, 'aliquot', formValue.aliquot);
      },
    },
    ipi_aliquot: {
      async change(formValue) {
        formValue.aliquot = calculateAliquot(formValue);
        Vue.set(formValue, 'aliquot', formValue.aliquot);
      },
    },
  },
};
