import * as AppUtils from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';

export const formatResponse = (order) => {
  order.id = order.code;
  order.start_date = AppUtils.formatDate(order.start_date);
  order.edition_date = AppUtils.formatDate(order.edition_date, true);

  if (order.items_bidding && order.items_bidding.length > 0) {
    const formattedItems = order.items_bidding.map((item) => {
      item.unitary_value = AppUtils.formatCurrency(item.unitary_value || 0);
      item.total_value = AppUtils.formatCurrency(item.total_value || 0);

      return item;
    });
    order.items_bidding = formattedItems;
  }
};

const Biddings = DynamicService('biddings', {
  formatResponse,
  // formatRequest,
  getDataOnItems: true,
});

const BiddingsKeywords = DynamicService('biddings_keywords', {
  ignoreItemId: true,
  // formatResponse,
  // formatRequest,
});

export { Biddings, BiddingsKeywords };
export default { Biddings, BiddingsKeywords };
