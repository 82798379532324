<template>
  <div>
    <span v-if="title && titleOutside" class="py-0 ml-4 mb-5 text-h6" :color="textColor" :style="textStyle">
      {{ title }}
    </span>
    <v-card :color="bgColor"
            class="pa-4 my-1 px-2 py-2 pt-6"
            outlined
            v-bind="$attrs"
            v-on="$listeners">
      <v-card-title v-if="title && !titleOutside" :class="`${textColor} mt-n4`">
        <strong v-if="textBold" :style="textStyle">{{ title }}</strong>
        <span v-else :style="textStyle">{{ title }}</span>
      </v-card-title>
      <v-card-text v-if="fields">
        <slot name="headerFieldList" />
        <v-col>
          <v-row>
            <slot v-if="$slots.contentLeft" name="contentLeft" />
            <FieldsList :fields="fields" />
            <slot v-if="$slots.contentRight" name="contentRight" />
          </v-row>
        </v-col>
      </v-card-text>
      <slot />
    </v-card>
  </div>
</template>

<script>
import FieldsList from '@sharedComponents/dynamic/FieldsList';

export default {
  name: 'GenericCardPanel',
  components: {
    FieldsList,
  },
  props: {
    fields: {
      required: true,
      type: Array,
      default: () => ([]),
    },
    title: {
      type: String,
      default: () => (''),
    },
    bgColor: {
      type: String,
      default: () => ('grey lighten-4'),
    },
    textColor: {
      type: String,
      default: () => ('indigo--text text--lighten-1'),
    },
    textBold: {
      type: Boolean,
      default: false,
    },
    textStyle: {
      type: Object,
      default: () => {},
    },
    titleOutside: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
