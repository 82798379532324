import AntecipationDashboardSchema from '@schemas/AntecipationDashboard';

const ListDashboard = () => import(/* webpackChunkName: "dashboard-list" */'./ListDashboard');

export default [
  {
    path: AntecipationDashboardSchema.routes.list.path,
    name: AntecipationDashboardSchema.routes.list.name,
    meta: {
      requiresAuth: false,
      antecipation: true,
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Antecipação de Recebíveis' },
        { name: 'Dashboard' },
      ],
    },
    component: ListDashboard,
  },
];
