export default [
  {
    label: 'Status',
    name: 'status',
    list: true, // doest exist in api
  },
  {
    label: 'NFS-e',
    name: 'invoice_number',
    type: 'text',
    list: true,
  },
  {
    label: 'RPS',
    name: 'rps_number',
    type: 'text',
    list: true,
  },
  {
    label: 'Série',
    name: 'series',
    type: 'text',
    list: true,
  },
  {
    label: 'Pedido',
    name: 'invoiceable_order',
    type: 'text',
    list: true,
  },
  {
    label: 'Emissão',
    name: 'emission_date_formatted',
    type: 'text',
    list: true,
  },
  {
    label: 'Cliente',
    name: 'buyer.name',
    type: 'text',
    list: true,
  },
  {
    label: 'CNPJ / CPF',
    name: 'buyer.cnpj_cpf',
    type: 'text',
    list: true,
  },
  {
    label: 'UF',
    name: 'buyer.address.uf',
    type: 'text',
    // list: true,  //doest exist in api
  },
  {
    label: 'Total',
    name: 'total.invoice_formatted',
    type: 'text',
    list: true,
  },
];
