import { ICON_ACCOUNT_BOX_MULTIPLE } from '@constants/icons';

export default {
  service: 'Profiles',
  icon: ICON_ACCOUNT_BOX_MULTIPLE,
  routes: {
    list: 'settings-profiles-list',
    create: 'settings-profiles-create',
    edit: 'settings-profiles-edit',
  },
  titles: {
    item: 'Perfil de Acesso',
    items: 'Perfis de Acesso',
    edit: 'Editar Perfil de Acesso',
    create: 'Criar Perfil de Acesso',
    new: 'Novo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'profiles/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 12,
    },
  ],
};
