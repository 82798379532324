import * as AppUtils from '@plugins/app-utils';
import Config from '@src/core/config';
import DynamicService from '@sharedServices/dynamic/DynamicService';
import { calculateTotals } from '@businesses/OrderService/CalculateTotals';
import searchCitiesByUf from '@plugins/searchCitiesByUf';
import { ulid } from 'ulid';

export const formatResponse = (order) => {
  order.buyer_id = order.buyer_id || (order.buyer && order.buyer.id) || null;
  order.salesman_id = order.salesman_id || (order.salesman && order.salesman.id) || null;
  order.provider_id = order.provider_id || (order.provider && order.provider.id) || null;

  order.type_installment_id = order.type_installment_id || (order.type_installment && order.type_installment.id) || null;
  order.nature_operation_id = order.nature_operation_id || (order.nature_operation && order.nature_operation.id) || null;

  order.payment_method_id = order.payment_method_id || (order.payment_method && order.payment_method.id) || null;
  order.service_code_id = order.service_code_id || (order.service_code && order.service_code.id) || null;
  order.coin_id = order.coin_id || (order.coin && order.coin.id) || null;
  order.price_list_id = order.price_list_id || (order.price_list && order.price_list.id) || null;

  order.start_datetime = AppUtils.parseDate(AppUtils.formatDate(order.start_datetime, false));
  order.start_datetime_formatted = AppUtils.formatDate(order.start_datetime, false);

  order.competence_datetime = AppUtils.parseDate(AppUtils.formatDate(order.competence_datetime, false));
  order.competence_datetime_formatted = AppUtils.formatDate(order.competence_datetime, false);

  order.end_datetime = AppUtils.parseDate(AppUtils.formatDate(order.end_datetime, false));
  order.end_datetime_formatted = AppUtils.formatDate(order.end_datetime, false);

  order.status = order.status || 'initial';
  order.status_formatted = AppUtils.getOptionText('orders_service_status', order.status);
  order.nfse_number_formatted = order.nfse_number;

  const { origin, uf, city } = order.provision_address;
  order.provision_address.origin = origin || null;
  order.provision_address.uf = uf || '';
  order.provision_address.city = city || '';

  order.provision_address.origin_formatted = AppUtils.getOptionText('orders_service_address_type', order.provision_address.origin);
  order.presence_indicator_formatted = AppUtils.getOptionText('presence_indicator', order.presence_indicator);

  order.items = order.items || [];
  order.taxes = order.taxes || [];

  if (order.items && order.items.length > 0) {
    const itemsFormatted = order.items.map((item, index) => {
      item.product_id = item.product_id || (item.product && item.product.id) || null;
      item.product_code = item.product_code || (item.product && item.product.code) || null;
      item.nature_operation_id = (item.nature_operation && item.nature_operation_id) || null;
      item.service_code_id = (item.service_code && item.service_code_id) || null;
      item.unity_id = item.unity_id || (item.unity && item.unity.id) || null;

      const unsetTax = 0.0;
      const issTaxes = {
        percentage_csll: order.items[index].iss?.percentage_csll ?? unsetTax,
        value_csll: order.items[index].iss?.value_csll ?? unsetTax,
        is_withhold_csll: order.items[index].iss?.is_withhold_csll ?? false,

        percentage_csll_formatted: AppUtils.formatNumber(order.items[index].iss?.percentage_csll ?? unsetTax),
        value_csll_formatted: AppUtils.formatNumber(order.items[index].iss?.value_csll ?? unsetTax),
        is_withhold_csll_formatted: AppUtils.formatBoolean(order.items[index].iss?.is_withhold_csll ?? false),

        percentage_inss: order.items[index].iss?.percentage_inss ?? unsetTax,
        value_inss: order.items[index].iss?.value_inss ?? unsetTax,
        is_withhold_inss: order.items[index].iss?.is_withhold_inss ?? false,

        percentage_inss_formatted: AppUtils.formatNumber(order.items[index].iss?.percentage_inss ?? unsetTax),
        value_inss_formatted: AppUtils.formatNumber(order.items[index].iss?.value_inss ?? unsetTax),
        is_withhold_inss_formatted: AppUtils.formatBoolean(order.items[index].iss?.is_withhold_inss ?? false),

        percentage_ir: order.items[index].iss?.percentage_ir ?? unsetTax,
        value_ir: order.items[index].iss?.value_ir ?? unsetTax,
        is_withhold_ir: order.items[index].iss?.is_withhold_ir ?? false,

        percentage_ir_formatted: AppUtils.formatNumber(order.items[index].iss?.percentage_ir ?? unsetTax),
        value_ir_formatted: AppUtils.formatNumber(order.items[index].iss?.value_ir ?? unsetTax),
        is_withhold_ir_formatted: AppUtils.formatBoolean(order.items[index].iss?.is_withhold_ir ?? false),

        percentage_iss: order.items[index].iss?.percentage_iss ?? unsetTax,
        value_iss: order.items[index].iss?.value_iss ?? unsetTax,
        is_withhold_iss: order.items[index].iss?.is_withhold_iss ?? false,

        percentage_iss_formatted: AppUtils.formatNumber(order.items[index].iss?.percentage_iss ?? unsetTax),
        value_iss_formatted: AppUtils.formatNumber(order.items[index].iss?.value_iss ?? unsetTax),
        is_withhold_iss_formatted: AppUtils.formatBoolean(order.items[index].iss?.is_withhold_iss ?? false),

        percentage_pis: order.items[index].iss?.percentage_pis ?? unsetTax,
        value_pis: order.items[index].iss?.value_pis ?? unsetTax,
        is_withhold_pis: order.items[index].iss?.is_withhold_pis ?? false,

        percentage_pis_formatted: AppUtils.formatNumber(order.items[index].iss?.percentage_pis ?? unsetTax),
        value_pis_formatted: AppUtils.formatNumber(order.items[index].iss?.value_pis ?? unsetTax),
        is_withhold_pis_formatted: AppUtils.formatBoolean(order.items[index].iss?.is_withhold_pis ?? false),

        percentage_cofins: order.items[index].iss?.percentage_cofins ?? unsetTax,
        value_cofins: order.items[index].iss?.value_cofins ?? unsetTax,
        is_withhold_cofins: order.items[index].iss?.is_withhold_cofins ?? false,

        percentage_cofins_formatted: AppUtils.formatNumber(order.items[index].iss?.percentage_cofins ?? unsetTax),
        value_cofins_formatted: AppUtils.formatNumber(order.items[index].iss?.value_cofins ?? unsetTax),
        is_withhold_cofins_formatted: AppUtils.formatBoolean(order.items[index].iss?.is_withhold_cofins ?? false),
      };

      const returnedItem = {
        ...item,
        iss: issTaxes,
        quantity_formatted: AppUtils.formatNumber(item.quantity || 0),
        unit_value_formatted: AppUtils.formatCurrency(parseFloat(item.unit_value || 0)),
        discount_value_formatted: AppUtils.formatCurrency(parseFloat(item.discount_value || 0)),
        discount_percentage_formatted: `${AppUtils.formatNumber(parseFloat(item.discount_percentage_value))}%`,
        total_value_formatted: AppUtils.formatCurrency(parseFloat(item.total_value || 0)),
      };
      return returnedItem;
    });

    order.items = itemsFormatted;
    order.taxes = itemsFormatted;

    const totals = {
      items: order?.total?.items || 0,
      services: order?.total?.services || 0,
      discount: order?.total?.discount || 0,
      taxes: order?.total?.taxes,
      withheld_taxes: order?.total?.withheld_taxes || 0,
      order: order?.total?.order || 0,
    };
    calculateTotals(order, { totals });
  }
};

export const formatRequest = (order) => {
  delete order.to_s;
  delete order.created_at;
  delete order.updated_at;
  delete order.buyer;
  delete order.salesman;
  delete order.provider;
  delete order.type_installment;
  order.taxes = order.taxes || [];

  if (order.start_datetime) {
    order.start_datetime = AppUtils.parseDateTimeToISO8061(AppUtils.parseDate(AppUtils.formatDate(order.start_datetime)), '00:00:00');
  }
  if (order.competence_datetime) {
    order.competence_datetime = AppUtils.parseDateTimeToISO8061(AppUtils.parseDate(AppUtils.formatDate(order.competence_datetime)), '00:00:00');
  }
  if (order.end_datetime) {
    order.end_datetime = AppUtils.parseDateTimeToISO8061(AppUtils.parseDate(AppUtils.formatDate(order.end_datetime)), '00:00:00');
  }

  const provisionAddress = {
    origin: order.provision_address.origin,
    uf: order.provision_address.uf,
    city: order.provision_address.city,
  };
  order.provision_address = provisionAddress || [];

  const formattedItems = order.items.map((item) => {
    delete item._ulid;
    delete item.icms;
    delete item.icms_st;
    delete item.ipi;
    delete item.pis;
    delete item.cofins;
    delete item.icms_base;
    delete item.icms_aliquot;
    delete item.icms_value;
    delete item.icms_st_base;
    delete item.icms_st_aliquot;
    delete item.icms_st_value;
    delete item.pis_base;
    delete item.pis_aliquot;
    delete item.pis_value;
    delete item.cofins_base;
    delete item.cofins_aliquot;
    delete item.cofins_value;
    delete item.simple_national;
    delete item.simple_national_st;
    delete item.to_s;
    delete item.order_sale;
    return item;
  });
  order.items = formattedItems;
  order.items = order.items || [];
};

export const formatClone = (order) => {
  delete order.id;
  order.items = order.items || [];
  order.items.forEach((i) => {
    delete i.id;
    i._ulid = ulid();
  });
};

const OrderServices = DynamicService('service_orders', {
  baseURL: Config.API_GATEWAY_TRADE,
  cloneType: 'post',
  formatResponse,
  formatRequest,
  formatClone,
});

const OrderServicesItems = DynamicService('service_orders/items');

const OrderServicesISS = DynamicService('iss', {
  baseURL: Config.API_GATEWAY_TAXES,
});

export const OrderServicesCommands = () => ({
  getCities: (uf) => searchCitiesByUf(uf),
});

export { OrderServices, OrderServicesItems, OrderServicesISS };
export default { OrderServices, OrderServicesItems, OrderServicesISS };
