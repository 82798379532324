import { ICON_PRODUCT } from '@constants/icons';

export default {
  service: 'Drawees',
  icon: ICON_PRODUCT,
  routes: {
    list: {
      path: '/anticipation/drawees',
      name: 'anticipation-drawees-list',
    },
    show: {
      path: '/anticipation/drawees/:id',
      name: 'anticipation-drawees-show',
    },
    create: '',
    edit: '',
  },
  titles: {
    item: '',
    items: 'Detalhes do Sacado',
    edit: '',
    create: '',
    new: '',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'assignors/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'Nota Fiscal',
      name: 'invoice_key',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Destinatário',
      name: 'buyer.to_s',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'CNPJ/CPF',
      name: 'buyer.cnpj_cpf_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'UF',
      name: 'buyer.uf',
      formattedName: 'buyer.uf',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Data de Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Valor Nota Fiscal',
      name: 'totals_note',
      formattedName: 'totals_note_formatted',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Forma Parcelamento',
      name: 'payment_method_name',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Prazo Médio',
      name: 'certus',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
  ],
};
