import Vue from 'vue';
import * as AppUtils from '@plugins/app-utils';
import { calculateOrderServiceTaxesRealTime } from './OrderService/CalculateRealTime';
import { setTaxesValues } from './OrderService/SetTaxesValues ';

const calculateTotals = (formValue) => {
  let totalDiscount = 0;
  let totalTaxes = 0;
  let totalServices = 0;
  let totalInvoice = 0;
  formValue.items = formValue.items || [];
  formValue.items.forEach((item) => {
    totalDiscount += parseFloat(item.discount_value) || 0;
    totalTaxes += parseFloat(item.total_taxes) || 0;
    totalServices += (parseFloat(item.total_value) || 0);
  });
  totalInvoice = (totalServices - totalDiscount) + totalTaxes;

  formValue.total_taxes = AppUtils.formatCurrency(totalTaxes);
  formValue.total_discount = AppUtils.formatCurrency(totalDiscount);
  formValue.totals_products_formatted = AppUtils.formatCurrency(totalInvoice);
  formValue.total_invoice_formatted = AppUtils.formatCurrency(totalInvoice);
  formValue.totals_note = totalInvoice;
  Vue.set(formValue, 'totals_products_formatted', formValue.totals_products_formatted);
};

export default {
  screenEvents: {
    // Return false or a string to cancel save action, returning a String the screen will notify as an error.
    // beforeSave(formValue) {
    //   formValue.items = formValue.items || [];
    //   const visibleItems = formValue.items.filter((item) => !item._destroy);
    //   if (!visibleItems.length) {
    //     return 'Não é possível gravar o pedido sem ítens!';
    //   }
    //   const action = formValue.id ? 'update' : 'create';

    //   if (action === 'update' && formValue.status === 'canceled') {
    //     return 'Não é possível alterar uma ordem cancelada.';
    //   }

    //   if (action === 'update' && formValue.status === 'delivered') {
    //     return 'Não é possível alterar uma ordem entregue.';
    //   }

    //   if (!formValue.status || formValue.status === 'initial') {
    //     formValue.status = 'pending';
    //   }

    //   if (formValue.delivery_date) {
    //     formValue.status = 'delivered';
    //   } else {
    //     formValue.status = 'pending';
    //   }
    // },
    // // is possible to change the initial formValue before start creating
    // beforeCreate(formValue) {
    //   formValue.emission_date = new Date();
    // },
    // beforeDelete(formValue) {
    //   if (formValue.status !== 'canceled') {
    //     return 'Primeiro efetue o cancelamento da ordem.';
    //   }
    // },
  },
  fieldEvents: {
    buyer_id: {
      change: async (formValue, newValue, objectValue) => {
        if (objectValue.addresses.length === 0) {
          Vue.prototype.$notifyError('Usuário não possui endereço', {
            action: {
              text: 'Clique aqui para cadastrar',
              push: {
                name: 'entries-registers-edit',
                params: {
                  id: objectValue.id,
                },
              },
              class: 'custom-message',
            },
            duration: 6000,
          });
        } else {
          const invoice_customer_id = formValue.buyer.id;
          const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
          formValue.buyer = buyer;
          const addressMain = buyer.addresses.find((address) => address.main === true);
          const contactMain = buyer.contacts.find((contact) => contact.main === true);
          formValue.buyer = {
            id: invoice_customer_id,
            register_id: formValue.buyer_id,
            name: buyer.name,
            cnpj: buyer.cnpj_cpf,
            ie: buyer.ie_rg,
            taxpayer_type: buyer.taxpayer_type,
            street: addressMain.street,
            number: addressMain.number,
            zip_code: addressMain.zip_code,
            complement: addressMain.complement,
            neighborhood: addressMain.neighborhood,
            code_country: addressMain.country ? addressMain.country.code : '',
            country: addressMain.country ? addressMain.country.name : '',
            city: addressMain.city,
            uf: addressMain.uf,
            code_ibge: buyer.addresses.length ? buyer.addresses[0].ibge : '',
            telephone: buyer.contacts.length ? buyer.contacts[0].telephone : '',
          };
          Vue.set(formValue, 'buyer.cnpj', buyer.cnpj);
          Vue.set(formValue, 'buyer.ie', buyer.ie_rg);
          Vue.set(formValue, 'buyer.street', addressMain.street);
          Vue.set(formValue, 'buyer.number', addressMain.number);
          Vue.set(formValue, 'buyer.zip_code', addressMain.zip_code);
          Vue.set(formValue, 'buyer.complement', addressMain.complement);
          Vue.set(formValue, 'buyer.neighborhood', addressMain.neighborhood);
          Vue.set(formValue, 'buyer.code_ibge', addressMain.ibge);
          if (addressMain.country) Vue.set(formValue, 'buyer.country', addressMain.country.name);
          Vue.set(formValue, 'buyer.city', addressMain.city);
          Vue.set(formValue, 'buyer.uf', addressMain.uf);
          if (buyer.contacts.length) Vue.set(formValue, 'buyer.telephone', contactMain.telephone);
        }
      },
    },
    items: {
      beforeCreate: (formValue, newItem) => {
        newItem.nature_operation_id = formValue.nature_operation_id;
        newItem.order_number = formValue.order;
      },
      change: async (formValue) => {
        formValue.items.forEach((item) => {
          item.unit_value_formatted = AppUtils.formatCurrency(parseFloat(item.unit_value));
          item.discount_percentage_formatted = `${AppUtils.formatNumber(parseFloat(item.discount_percentage))}%`;
          item.discount_value_formatted = AppUtils.formatCurrency(parseFloat(item.discount_value));
          item.total_value_formatted = AppUtils.formatCurrency(parseFloat(item.total_value));
        });
        const data = await calculateOrderServiceTaxesRealTime(formValue);
        if (data) {
          setTaxesValues(formValue, data);
          calculateTotals(formValue);
        }
      },
    },
  },
  applyGlobalDiscount: (formValue, discount, usePercent) => {
    calculateTotals(formValue);
    const totalServices = formValue.total_products;
    if (totalServices === 0) {
      return;
    }
    const totalDiscount = usePercent ? (totalServices * discount) / 100 : discount;

    formValue.items = formValue.items || [];
    formValue.items.forEach((item) => {
      const totalItem = (item.quantity || 0) * (item.unit_value || 0);
      const discountValue = parseFloat((totalDiscount * (totalItem / totalServices)).toFixed(2));
      const discountPercent = parseFloat((totalItem > 0 ? (discountValue / totalItem) * 100 : 0).toFixed(2));

      Vue.set(item, 'discount_value', discountValue);
      Vue.set(item, 'discount_percentage_value', discountPercent);
    });
    calculateTotals(formValue);
  },
};
