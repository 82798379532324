import _ from 'lodash';
import vuetify from '@plugins/vuetify';
import dynamicDialog from './DynamicDialog.vue';

export default {
  install(Vue) {
    const DynamicDialogConstructor = Vue.extend(dynamicDialog);
    let instance;

    Vue.prototype.$dynamicDialog = (options) => {
      if (options.readOnly === true) {
        _.each(options.fields, (field) => {
          field.readOnly = true;
        });
      }
      // if (instance) return;

      instance = new DynamicDialogConstructor(
        {
          vuetify,
          propsData: {
            title: options.title,
            fields: options.fields,
            value: options.value,
            icon: options.icon,
            maxWidth: options.maxWidth,
            showClose: options.showClose,
            readOnly: options.readOnly,
            persistent: options.persistent,
            buttons: options.buttons,
            saveLabel: options.saveLabel,
            saveIcon: options.saveIcon,
            btnsWidth: options.btnsWidth,
            rowClass: options.rowClass,
          },
        },
      );
      instance.$mount();
      document.getElementById('app').appendChild(instance.$el);

      return new Promise((resolve) => {
        instance.$on('save', (payload) => {
          instance.$destroy();
          instance = null;
          resolve(payload);
        });

        instance.$on('close', () => {
          instance.$destroy();
          instance = null;
        });

        instance.open();
      });
    };

    Vue.prototype.$dynamicDialog.close = () => {
      if (instance) {
        instance.close();
      }
    };
  },
};
