import {
  OPTIONS_UF, OPTIONS_PRESENCE_INDICATOR, OPTIONS_TAX_PAYER, OPTIONS_INTERMEDIARY_INDICATOR,
} from '@constants/options';
import { MASK_CPF_CNPJ, MASK_CEP } from '@constants/masks';

export default [
  // Line 1
  {
    label: 'Nome / Razão Social',
    name: 'buyer_id',
    formattedName: 'buyer.to_s',
    type: 'number',
    list: true,
    required: true,
    component: 'dynamic-autocomplete',
    dataSource: {
      service: 'Registers',
      method: 'getByFilterGraphql',
      props: [
        {
          use_inactive_eq: false,
          type_register_array: 'client',
          query: ['id', 'name', 'cnpj_cpf', 'addresses', ['id', 'to_s'], 'preference', ['salesman_id', 'shipping_company_id']],
        },
      ],
    },
    sort: {
      fieldName: 'name',
    },
    itemText: 'name',
    itemValue: 'id',
    colClass: 'nf-field top left',
    prependIcon: '',
    withDetails: true,
    typeDetails: 'client',
    md: 7,
  },
  {
    label: 'CNPJ/CPF',
    name: 'buyer.cnpj_cpf',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    colClass: 'nf-field top left',
    prependIcon: '',
  },
  {
    label: 'Data Emissão',
    name: 'emission_date',
    formattedName: 'emission_date_formatted',
    type: 'date',
    component: 'dynamic-date-field',
    list: true,
    required: true,
    clearable: true,
    cols: 2,
    colClass: 'nf-field top left right',
    prependIcon: '',
  },
  // Line 2
  {
    label: 'Logradouro',
    name: 'buyer.street',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 5,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Número',
    name: 'buyer.number',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'CEP',
    name: 'buyer.zip_code',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    prependIcon: '',
    mask: MASK_CEP,
    colClass: 'nf-field top left',
  },
  {
    label: 'Hora Emissão',
    name: 'emission_date_hour',
    component: 'dynamic-time-picker',
    format: '24hr',
    required: true,
    clearable: true,
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left right',
  },
  // Line 3
  {
    label: 'Complemento',
    name: 'buyer.complement',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 4,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Bairro',
    name: 'buyer.neighborhood',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'País',
    name: 'buyer.country',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Data Saída',
    name: 'exit_date',
    formattedName: 'exit_date_formatted',
    type: 'date',
    component: 'dynamic-date-field',
    list: true,
    required: true,
    clearable: true,
    cols: 2,
    colClass: 'nf-field top left right',
    prependIcon: '',
  },
  // Line 5
  {
    label: 'Município',
    name: 'buyer.city',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Código Município/IBGE',
    name: 'buyer.code_ibge',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'UF',
    name: 'buyer.uf',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_UF,
    cols: 1,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'IE/RG',
    name: 'buyer.ie',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Telefone/Fax',
    name: 'buyer.telephone',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 3,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Hora Saída',
    name: 'exit_date_hour',
    component: 'dynamic-time-picker',
    format: '24hr',
    required: true,
    clearable: true,
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left right',
  },
  // Line 6
  {
    label: 'Consumidor Final',
    name: 'is_costumer',
    type: 'boolean',
    component: 'dynamic-checkbox',
    colClass: 'nf-field top left bottom',
    md: 3,
  },
  {
    label: 'Tipo de Contribuinte',
    name: 'buyer.taxpayer_type',
    type: 'string',
    component: 'dynamic-select',
    items: OPTIONS_TAX_PAYER,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    md: 4,
  },
  {
    label: 'Indicador de Presença',
    name: 'presence_indicator',
    type: 'string',
    component: 'dynamic-select',
    items: OPTIONS_PRESENCE_INDICATOR,
    colClass: 'nf-field top left bottom right',
    required: true,
    prependIcon: '',
    md: 3,
  },
  {
    label: 'Indicador de Intermediador:',
    name: 'indicator_intermediary',
    type: 'string',
    component: 'dynamic-select',
    items: OPTIONS_INTERMEDIARY_INDICATOR,
    colClass: 'nf-field top left bottom right',
    prependIcon: '',
    md: 2,
  },
  {
    label: 'CNPJ do Intermediador da Transação (agenciador, plataforma de delivery, marketplace e similar) de serviços e de negócios',
    name: 'intermediary_cnpj',
    mask: MASK_CPF_CNPJ,
    type: 'text',
    required: true,
    component: 'dynamic-text-field',
    cols: 3,
    colClass: 'nf-field left bottom right',
    visible: (formValue) => {
      if (formValue?.indicator_intermediary === '1') {
        return true;
      }
      return false;
    },
  },
  {
    label: 'Nome do usuário ou identificação do perfil do vendedor no site do intermediador.',
    name: 'intermediary_name',
    type: 'number',
    required: true,
    component: 'dynamic-text-field',
    cols: 4,
    colClass: 'nf-field bottom right',
    visible: (formValue) => {
      if (formValue?.indicator_intermediary === '1') {
        return true;
      }
      return false;
    },
  },
];
