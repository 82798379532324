import PartialSalesSchema from '@schemas/PartialSale';

const ListPartialSales = () => import(/* webpackChunkName: "list-partial-sales" */'./ListPartialSales');
const DetailPartialSales = () => import(/* webpackChunkName: "detail-partial-sale" */'./DetailPartialSale');

export default [
  {
    path: PartialSalesSchema.routes.list.path,
    name: PartialSalesSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: PartialSalesSchema.titles.items },
      ],
    },
    component: ListPartialSales,
  },
  {
    path: PartialSalesSchema.routes.create.path,
    name: PartialSalesSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: PartialSalesSchema.titles.items, route: PartialSalesSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailPartialSales,
  },
  {
    path: PartialSalesSchema.routes.edit.path,
    name: PartialSalesSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: PartialSalesSchema.titles.items, route: PartialSalesSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailPartialSales,
  },
];
