import DynamicService from '@sharedServices/dynamic/DynamicService';
import { getOptionText } from '@plugins/app-utils';

const formatResponse = (item) => {
  item.demonstrative = getOptionText('demonstrative_accounting_account', item.demonstrative);
  item.type_accounting_account = getOptionText('type_accounting_account', item.type_accounting_account);
};

const AccountingAccounts = DynamicService('accounting_accounts', {
  formatResponse,
});

export { AccountingAccounts };
export default { AccountingAccounts };
