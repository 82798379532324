<template>
  <div :id="item.id">
    <GenericCardPanel
      title="RPS"
      :fields="rpsInfoFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabInvoiceTemplateRPS',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      rpsInfoFields: [],
    };
  },
  mounted() {
    this.rpsInfoFields = [
      { label: 'Natureza da Operação', value: this.item.nfs.nature_operation_formatted, col: 4 },
      { label: 'Regime Tributário', value: this.item.nfs.tax_regime_formatted, col: 4 },
      { label: 'Regime Especial de  Tributação', value: this.item.nfs.special_tax_regime_formatted, col: 4 },
      { label: 'Número do Lote Atual', value: this.item.nfs.current_batch_number, col: 4 },
      { label: 'Série do Lote Atual', value: this.item.nfs.current_batch_serie, col: 4 },
      { label: 'Alíquota ISS', value: this.item.nfs.aliquot_iss, col: 4 },
      { label: 'Enviar Notificação para o Cliente', value: this.item.nfs.send_notification_to_costumer ? 'Sim' : 'Não', col: 4 },
      { label: 'Email', value: this.item.nfs.email, col: 4 },
      { label: 'Receber Notificação', value: this.item.nfs.get_notification ? 'Sim' : 'Não', col: 4 },
    ];
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style scoped></style>
