import Vue from 'vue';
import {
  setWalletsItems, resetWalletsItems, setSpeciesItems, resetSpeciesItems, setInstructionsItems, resetInstructionsItems,
} from '@schemas/CnabsTicket';

/** *
 * Sample of Business Rules Layer
 * TODO: Documentar camada de negócios do front
 * TODO: implementar regras para lidar com childSchemas na camada de negócios
 * Available functions:
 *  - for simple fields: change
 *  - for arrays: beforeUpdate, beforeCreate, update, create, select
 */

const resetFields = (formValue, fields) => {
  fields.forEach((field) => Vue.set(formValue, field, ''));
};

const setWalletsFields = async (formValue) => {
  await Vue.prototype.$api.CnabsCommands.getConfig(formValue?.bank_layout, 'wallets').then((wallets) => {
    if (wallets.length > 0) {
      wallets.forEach((wallet) => {
        setWalletsItems({
          to_s: `${wallet.to_s}`,
          id: wallet.id,
        });
      });
    } else {
      resetFields(formValue, ['wallet_id']);
      // return Vue.prototype.$notifyError('');
    }
  });
};

const setSpeciesFields = async (formValue) => {
  await Vue.prototype.$api.CnabsCommands.getConfig(formValue?.bank_layout, 'species').then((species) => {
    if (species.length > 0) {
      species.forEach((wallet) => {
        setSpeciesItems({
          to_s: `${wallet.to_s}`,
          id: wallet.id,
        });
      });
    } else {
      resetFields(formValue, ['specie_id']);
      // return Vue.prototype.$notifyError('');
    }
  });
};

const setInstructionsFields = async (formValue) => {
  await Vue.prototype.$api.CnabsCommands.getConfig(formValue?.bank_layout, 'instructions').then((instructions) => {
    if (instructions.length > 0) {
      instructions.forEach((wallet) => {
        setInstructionsItems({
          to_s: `${wallet.to_s}`,
          id: wallet.id,
        });
      });
    } else {
      resetFields(formValue, ['instruction_id']);
      // return Vue.prototype.$notifyError('');
    }
  });
};

export default {
  screenEvents: {
    beforeUpdate(formValue) {
      setWalletsFields(formValue);
      setSpeciesFields(formValue);
      setInstructionsFields(formValue);
    },
  },
  fieldEvents: {
    bank_layout: {
      change(formValue) {
        resetWalletsItems();
        setWalletsFields(formValue);

        resetSpeciesItems();
        setSpeciesFields(formValue);

        resetInstructionsItems();
        setInstructionsFields(formValue);
      },
    },
    bank_id: {
      change() {

      },
    },
    payment_method_id: {
      change() {

      },
    },
    assignor_cnpj_cpf: {
      change() {

      },
    },
    assignor_name: {
      change() {

      },
    },
    convention_code: {
      change() {

      },
    },
    agency: {
      change() {

      },
    },
    agency_digit: {
      change() {

      },
    },
    account: {
      change() {

      },
    },
    account_digit: {
      change() {

      },
    },
    assignor_code: {
      change() {

      },
    },
    check_digit: {
      change() {

      },
    },
    current_number: {
      change() {
      },
    },
    accept: {
      change() {

      },
    },
    sequence_number: {
      change() {

      },
    },
    active: {
      change() {

      },
    },
    wallet_id: {
      change() {

      },
    },
    specie_id: {
      change() {

      },
    },
    interest_per_day: {
      change() {

      },
    },
    select: {
      change() {

      },
    },
    instruction_id: {
      change() {

      },
    },
    coin_id: {
      change() {

      },
    },
    discount_limit_day: {
      change() {

      },
    },
    fine_day: {
      change() {

      },
    },
    protest_day: {
      change() {

      },
    },
    floater_day: {
      change() {

      },
    },
    discount_value: {
      change() {

      },
    },
    rebate_value: {
      change() {

      },
    },
    interest_value: {
      change() {

      },
    },
    fine_value: {
      change() {

      },
    },
    iof_value: {
      change() {

      },
    },
    info_1: {
      change() {

      },
    },
    info_2: {
      change() {

      },
    },
    info_3: {
      change() {

      },
    },
    info_4: {
      change() {

      },
    },
  },
};
