/* eslint-disable no-param-reassign */

const endpoint = 'companies/checks';

export default ($axios) => ({
  async index() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      responseData = response.data.checks;
    });
    return responseData;
  },
  async checkAccountPayments() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_account_payment === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkAccountReceivables() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_account_receivable === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkAddress() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_address === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkAnticipation() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_anticipation === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkMakeAnticipation() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_make_anticipation === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkBanks() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_bank_registration === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkInvoice() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_make_invoice === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkInvoiceTemplate() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_invoice_template === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkDigitalCertificate() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_certificate === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkLegalRepresentative() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_legal_representatives === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkRegisterClient() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_customer_registration === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkRegisterProvider() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_vendor_registration === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkNatureOperation() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_nature_operation === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkNcms() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_ncm_registration === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkProducts() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_product === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkOrderPurchase() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_make_purchase === true) {
        responseData = true;
      }
    });
    return responseData;
  },
  async checkOrderSale() {
    let responseData = false;
    await $axios.get(`${endpoint}.json`).then((response) => {
      if (response.data?.checks?.with_make_sale === true) {
        responseData = true;
      }
    });
    return responseData;
  },
});
