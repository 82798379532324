import { ICON_BANK } from '@constants/icons';

export default {
  service: 'Biddings',
  icon: ICON_BANK,
  routes: {
    list: {
      name: 'sellmore-biddings-list',
      path: '/sellmore/biddings',
    },

  },
  noCreate: true,
  noEdit: true,
  noDelete: true,
  titles: {
    item: 'Item da licitação',
    items: 'Itens das licitações',
    new: 'Novo',
  },
  fields: [
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      list: true,
    },
    {
      label: 'Link',
      name: 'link',
      type: 'text',
    },
  ],
};
