<template>
  <div class="tab-btg-account-statements">
    <GenericTabItems :schema="schema" :items="setBtgAccountStatements(item)" />
  </div>
</template>

<script>
import { ICON_REFRESH } from '@constants/icons';
import GenericTabItems from '@sharedComponents/tabs/GenericTabItems';
import BtgAccountStatementSchema from '@schemas/BtgAccountStatement';

export default {
  name: 'TabBtgAccountStatements',
  components: {
    GenericTabItems,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      schema: BtgAccountStatementSchema,
      icons: {
        refresh: ICON_REFRESH,
      },
      headers: [
        { text: 'Código', value: 'product.code' },
        { text: 'Produto', value: 'product.to_s' },
        { text: 'Nome', value: 'name' },
        { text: 'Unidade de medida', value: 'unity.to_s' },
        { text: 'Quantidade', value: 'quantity' },
        { text: 'Valor unitário', value: 'unit_value' },
        { text: 'Valor desconto', value: 'discount_value' },
        { text: '% Desconto', value: 'discount_percentage_value' },
        { text: 'Valor Total', value: 'total_value_formatted' },
        { text: 'Estoque', value: 'stock.to_s' },
        { text: 'Natureza da Operação', value: 'type_installment' },
        { text: 'NCM', value: 'ncm.to_s' },
        { text: 'Número da Ordem', value: 'order_number' },
        { text: 'Número do Item', value: 'item_number' },
        { text: 'Observações', value: 'observation' },
      ],
      actions: [],
      filterParams: {},
    };
  },
  computed: {},
  methods: {
    setBtgAccountStatements(item) {
      const BtgAccountStatements = item.transactions;
      return BtgAccountStatements;
    },
  },
};
</script>
