<template>
  <div :id="item.id">
    <GenericCardForm>
      <div v-if="item.response_errors && item.response_errors.length">
        <v-row v-for="(error, index) in item.response_errors" :key="index">
          <v-col cols="8">
            <span class="font-weight-bold">
              {{ error.description }}
            </span>
          </v-col>
          <v-col cols="4">
            <span class="font-weight-bold">
              {{ error.created_at_formatted }}
            </span>
          </v-col>
        </v-row>
      </div>
      <div v-else class="mx-4 my-2">
        Nenhum erro de transmissão foi encontrado
      </div>
    </GenericCardForm>
  </div>
</template>
<script>
import GenericCardForm from '@sharedComponents/dynamic/GenericCardForm';

export default {
  name: 'TabManifestError',
  components: {
    GenericCardForm,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      errorsFields: [],
    };
  },
};
</script>
