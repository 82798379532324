import { ICON_MEASURING_UNIT } from '@constants/icons';
// import OrderProductionItemBusiness from '@businesses/OrderProductionItem';

export default {
  service: 'PriceList',
  // business: OrderProductionItemBusiness,
  icon: ICON_MEASURING_UNIT,
  routes: {
    list: '',
    edit: '',
    create: '',
  },
  titles: {
    item: 'Item da Lista de Preço',
    items: 'Itens da Lista de Preço',
    edit: 'Editar Lista de Preço',
    create: 'Criar Lista de Preço',
    new: 'Adicionar Item',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  noEdit: true,
  noDelete: true,
  noActions: true,
  filterUrl: '',
  fields: [
    {
      label: 'Código',
      name: 'product_code',
      formattedName: 'product.code',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'code',
      itemValue: 'id',
      list: true,
      disabled: true,
      md: 4,
    },
    {
      label: 'Produto',
      name: 'product_id',
      formattedName: 'product.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      list: true,
      disabled: true,
      md: 8,
    },
    {
      label: 'Unidade de medida',
      name: 'unity_id',
      formattedName: 'unity.to_s',
      type: 'number',
      list: true,
      disabled: true,
      required: true,
      componentf: 'dynamic-select',
      redirectCreateRoute: 'tables-measuringunits-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['unity'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
    },
    {
      label: 'Valor Padrão',
      name: 'default_value',
      formattedName: 'default_value_formatted',
      type: 'number',
      list: true,
      disabled: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
    },
    {
      label: 'Valor Lista',
      name: 'value',
      formattedName: 'value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
    },
  ],
};
