<template>
  <v-checkbox v-if="!readOnly && !field.readOnly"
              v-model="fieldValue"
              :disabled="checkDisabled()"
              :require="checkRequired()"
              :hint="field.hint"
              :hide-details="!field.hint"
              :persistent-hint="true"
              :label="field.label"
              color="primary"
              :class="`${field.class} mx-0 my-2`"
              :on-icon="icons.checkBoxOn"
              :off-icon="icons.checkBoxOff"
              v-bind="$attrs"
              v-on="$listeners"
              @change="fieldChanged" />
  <div v-else class="dm-information">
    <label>{{ field.label }}</label>
    <p>{{ Boolean(fieldValue) ? 'Sim': 'Não' }}</p>
  </div>
</template>

<script>
import { ICON_CHECKBOX_BLANK, ICON_CHECKBOX_MARKED } from '@constants/icons';
import DynamicFieldMixin from './DynamicFieldMixin';

export default {
  name: 'DynamicCheckbox',
  mixins: [DynamicFieldMixin],
  data() {
    return {
      icons: {
        checkBoxOff: ICON_CHECKBOX_BLANK,
        checkBoxOn: ICON_CHECKBOX_MARKED,
      },
      fieldValue: this.field.default || undefined,
      emptyValue: false,
    };
  },
};
</script>

<style lang="scss">
</style>
