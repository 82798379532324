import { ICON_TAXES } from '@constants/icons';

export default {
  service: 'OrderPurchaseItemTaxes',
  icon: ICON_TAXES,
  business: 'colocar regra de negócio de cálculo de impostos aqui',
  routes: {
    list: '',
    edit: '',
    create: '',
  },
  hideDefaultHeader: true,
  customHeader: {
    customClass: 'custom-child-header-order-service',
    ungroupedHeadersFront: 2,
    groupHeaders: [
      { text: 'CSLL', colspan: 3, class: 'order-service-header' },
      { text: 'INSS', colspan: 3, class: 'order-service-header' },
      { text: 'IR', colspan: 3, class: 'order-service-header' },
      { text: 'ISS', colspan: 3, class: 'order-service-header' },
      { text: 'PIS', colspan: 3, class: 'order-service-header' },
      { text: 'COFINS', colspan: 3, class: 'order-service-header' },
    ],
  },
  titles: {
    item: 'Conferência de Imposto',
    items: 'Conferência de Impostos',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: '',
  fields: [
    {
      label: 'Código',
      name: 'product.code',
      type: 'number',
      list: true,
    },
    {
      label: 'Nome',
      columnWidth: '300px',
      name: 'name',
      type: 'string',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 8,
    },
    // CSLL
    {
      label: 'Percentagem',
      name: 'iss.percentage_csll',
      formattedName: 'iss.percentage_csll_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'iss.value_csll',
      formattedName: 'iss.value_csll_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Retido',
      name: 'iss.is_withhold_csll',
      formattedName: 'iss.is_withhold_csll_formatted',
      type: 'boolean',
      list: true,
    },
    // INSS
    {
      label: 'Percentagem',
      name: 'iss.percentage_inss',
      formattedName: 'iss.percentage_inss_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'iss.value_inss',
      formattedName: 'iss.value_inss_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Retido',
      name: 'iss.is_withhold_inss',
      formattedName: 'iss.is_withhold_inss_formatted',
      type: 'boolean',
      list: true,
    },
    // IR
    {
      label: 'Percentagem',
      name: 'iss.percentage_ir',
      formattedName: 'iss.percentage_ir_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'iss.value_ir',
      formattedName: 'iss.value_ir_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Retido',
      name: 'iss.is_withhold_ir',
      formattedName: 'iss.is_withhold_ir_formatted',
      type: 'boolean',
      list: true,
    },
    // ISS
    {
      label: 'Percentagem',
      name: 'iss.percentage_iss',
      formattedName: 'iss.percentage_iss_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'iss.value_iss',
      formattedName: 'iss.value_iss_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Retido',
      name: 'iss.is_withhold_iss',
      formattedName: 'iss.is_withhold_iss_formatted',
      type: 'boolean',
      list: true,
    },
    // PIS
    {
      label: 'Percentagem',
      name: 'iss.percentage_pis',
      formattedName: 'iss.percentage_pis_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'iss.value_pis',
      formattedName: 'iss.value_pis_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Retido',
      name: 'iss.is_withhold_pis',
      formattedName: 'iss.is_withhold_pis_formatted',
      type: 'boolean',
      list: true,
    },
    // COFINS
    {
      label: 'Percentagem',
      name: 'iss.percentage_cofins',
      formattedName: 'iss.percentage_cofins_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'iss.value_cofins',
      formattedName: 'iss.value_cofins_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Retido',
      name: 'iss.is_withhold_cofins',
      formattedName: 'iss.is_withhold_cofins_formatted',
      type: 'boolean',
      list: true,
    },

  ],
};
