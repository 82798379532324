const ListCharts = () => import(/* webpackChunkName: "list-charts" */'./pages/charts/ListCharts');
const BankBalancePeriodCharts = () => import(/* webpackChunkName: "banks-balance-period-charts" */'./pages/charts/BankBalancePeriodCharts');
const BankLaunchsPeriodCharts = () => import(/* webpackChunkName: "banks-launchs-period-charts" */'./pages/charts/BankLaunchsPeriodCharts');
const BuyersQuantityCharts = () => import(/* webpackChunkName: "buyers-quantity-charts" */'./pages/charts/BuyersQuantityCharts');
const ComparativeSalesPurchaseCharts = () => import(/* webpackChunkName: "comparative-sales-purchase-charts" */'./pages/charts/ComparativeSalesPurchaseCharts');
const ComparativeOpenTitlesPaymentsReceivablesCharts = () => import(/* webpackChunkName: "comparative-open-titles-payments-receivables-charts" */'./pages/charts/ComparativeOpenTitlesPaymentsReceivablesCharts');
const ComparativePaymentsReceivablesCharts = () => import(/* webpackChunkName: "comparative-payments-receivables-charts" */'./pages/charts/ComparativePaymentsReceivablesCharts');
const ProductsQuantityCharts = () => import(/* webpackChunkName: "products-quantity-charts" */'./pages/charts/ProductsQuantityCharts');
const SalesmenQuantityCharts = () => import(/* webpackChunkName: "salesmen-quantity-charts" */'./pages/charts/SalesmenQuantityCharts');
const StockItemsQuantityCharts = () => import(/* webpackChunkName: "stock-items-quantity-charts" */'./pages/charts/StockItemsQuantityCharts');

export default [
  {
    path: '/analytics/charts',
    name: 'analytics-charts-list',
    meta: {
      requiresAuth: true,
      rule: 'banks',
      breadcrumb: [
        { name: 'Analítico' },
        { name: 'Gráficos' },
      ],
    },
    component: ListCharts,
  },
  {
    path: '/analytics/charts/bankbalance',
    name: 'analytics-charts-bankbalance',
    meta: {
      requiresAuth: true,
      rule: 'banks',
      breadcrumb: [
        { name: 'Analítico' },
        { name: 'Gráficos', link: '/analytics/charts' },
        { name: 'Movimentações do Saldo Bancário' },
      ],
    },
    component: BankBalancePeriodCharts,
  },
  {
    path: '/analytics/charts/buyerspurchase',
    name: 'analytics-charts-buyerspurchase',
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Analítico' },
        { name: 'Gráficos', link: '/analytics/charts' },
        { name: 'Compras dos Clientes' },
      ],
    },
    component: BuyersQuantityCharts,
  },
  {
    path: '/analytics/charts/comparativesalespurchase',
    name: 'analytics-charts-comparativesalespurchase',
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Analítico' },
        { name: 'Gráficos', link: '/analytics/charts' },
        { name: 'Comparativo Compras/Vendas' },
      ],
    },
    component: ComparativeSalesPurchaseCharts,
  },
  {
    path: '/analytics/charts/comparativepaymentsreceivables',
    name: 'analytics-charts-comparativepaymentsreceivables',
    meta: {
      requiresAuth: true,
      rule: 'account_payments',
      breadcrumb: [
        { name: 'Analítico' },
        { name: 'Gráficos', link: '/analytics/charts' },
        { name: 'Comparativo Contas a Pagar/Receber' },
      ],
    },
    component: ComparativePaymentsReceivablesCharts,
  },
  {
    path: '/analytics/charts/comparativeopentitlespaymentsreceivables',
    name: 'analytics-charts-comparativeopentitlespaymentsreceivables',
    meta: {
      requiresAuth: true,
      rule: 'open_titles',
      breadcrumb: [
        { name: 'Analítico' },
        { name: 'Gráficos', link: '/analytics/charts' },
        { name: 'Comparativo Títulos em Aberto - Pagar/Receber' },
      ],
    },
    component: ComparativeOpenTitlesPaymentsReceivablesCharts,
  },
  {
    path: '/analytics/charts/salesmenperformance',
    name: 'analytics-charts-salesmenperformance',
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Analítico' },
        { name: 'Gráficos', link: '/analytics/charts' },
        { name: 'Desempenho dos Vendedores' },
      ],
    },
    component: SalesmenQuantityCharts,
  },
  {
    path: '/analytics/charts/bankslaunchs',
    name: 'analytics-charts-bankslaunchs',
    meta: {
      requiresAuth: true,
      rule: 'banks',
      breadcrumb: [
        { name: 'Analítico' },
        { name: 'Gráficos', link: '/analytics/charts' },
        { name: 'Lançamentos dos Bancos' },
      ],
    },
    component: BankLaunchsPeriodCharts,
  },
  {
    path: '/analytics/charts/stockitems',
    name: 'analytics-charts-stockitems',
    meta: {
      requiresAuth: true,
      rule: 'stocks',
      breadcrumb: [
        { name: 'Analítico' },
        { name: 'Gráficos', link: '/analytics/charts' },
        { name: 'Itens do Estoque' },
      ],
    },
    component: StockItemsQuantityCharts,
  },
  {
    path: '/analytics/charts/topsellingproducts',
    name: 'analytics-charts-topsellingproducts',
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Analítico' },
        { name: 'Gráficos', link: '/analytics/charts' },
        { name: 'Produtos Mais Vendidos' },
      ],
    },
    component: ProductsQuantityCharts,
  },
];
