import Vue from 'vue';

export const calculateTotalUsed = (formValue) => {
  const ordered_quantity = (parseFloat(formValue.suggested_quantity) || 0);
  const returned_quantity = (parseFloat(formValue.returned_quantity) || 0);
  const required_quantity = (parseFloat(formValue.required_quantity) || 0);
  const lost_quantity = (parseFloat(formValue.lost_quantity) || 0);
  const total_quantity = (ordered_quantity - returned_quantity + required_quantity - lost_quantity) || 0;

  Vue.set(formValue, 'total_quantity', total_quantity);
};

const getQuantityInStock = async (formValue) => {
  if (formValue.product_id && formValue.stock_id) {
    const response = await Vue.prototype.$api.OrderProductionCommands.getQuantity({ id: formValue.product_id, stock_id: formValue.stock_id });
    if (response.data) {
      Vue.set(formValue, 'stock_quantity', response.data.quantity_available);
    } else {
      Vue.prototype.$notifyError('Erro ao tentar buscar informações da quantidade em estoque, tente novamente');
    }
  }
};

const setProduct = async (formValue, fieldValue) => {
  await Vue.prototype.$api.Products.show(fieldValue, ['id', 'to_s', 'code', 'unity_id', 'ncm_id', 'cest_id', 'purchase_cost', 'sale_value']).then(async (response) => {
    const product = response.data;

    if (product) {
      formValue.product = product;
      formValue.name = product.to_s;
      formValue.product_id = product.id;
      formValue.product_code = product.id;
      formValue.unity = product.unity;
      formValue.unity_id = product.unity_id;
      Vue.set(formValue, 'product_id', product.id);
      Vue.set(formValue, 'product_code', product.id);
      formValue.purchase_cost = product.purchase_cost;
      Vue.set(formValue, 'purchase_cost', product.purchase_cost);
      formValue.sale_value = product.sale_value;
      Vue.set(formValue, 'sale_value', product.sale_value);
      Vue.set(formValue, 'unity_id', product.unity_id);
      calculateTotalUsed(formValue);
      getQuantityInStock(formValue);
    }
  });
};

export default {
  screenEvents: {
    async beforeCreate(formValue) {
      calculateTotalUsed(formValue);
    },
    beforeUpdate(formValue) {
      calculateTotalUsed(formValue);
    },
  },
  fieldEvents: {
    product_code: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    product_id: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    unity_id: {
      change: async (formValue, fieldValue) => {
        const unity = await Vue.prototype.$api.AllTypes.show(fieldValue);
        formValue.unity = unity;
      },
    },
    ordered_quantity: {
      change: (formValue) => {
        calculateTotalUsed(formValue);
      },
    },
    returned_quantity: {
      change: (formValue, newValue) => {
        if (parseFloat(formValue.returned_quantity_base) >= parseFloat(newValue)) {
          Vue.prototype.$notifyError('O valor do retorno deve ser maior que o valor atual');
          formValue.returned_quantity = formValue.returned_quantity_base;
        } else {
          formValue.returned_quantity_base = formValue.returned_quantity_base;
          formValue.returned_quantity = newValue;
          calculateTotalUsed(formValue);
        }
      },
    },
    required_quantity: {
      change: async (formValue, newValue) => {
        if (parseFloat(formValue.required_quantity_base) >= parseFloat(newValue)) {
          Vue.prototype.$notifyError('O valor do requerido deve ser maior que o valor atual');
          formValue.required_quantity = formValue.required_quantity_base;
        } else {
          formValue.required_quantity_base = formValue.required_quantity_base;
          formValue.required_quantity = newValue;
          calculateTotalUsed(formValue);
        }
      },
    },
    lost_quantity: {
      change: (formValue, newValue) => {
        if (parseFloat(formValue.lost_quantity_base) >= parseFloat(newValue)) {
          Vue.prototype.$notifyError('O valor da perda deve ser maior que o valor atual');
          formValue.lost_quantity = formValue.lost_quantity_base;
        } else {
          formValue.lost_quantity_base = formValue.lost_quantity_base;
          formValue.lost_quantity = newValue;
          calculateTotalUsed(formValue);
        }
      },
    },
    total_quantity: {
      change: (formValue) => {
        calculateTotalUsed(formValue);
      },
    },
    stock_id: {
      change: async (formValue) => {
        if (formValue.product_id) {
          const stockResponse = await Vue.prototype.$api.Stocks.showGraphql(formValue.stock_id, ['id', 'to_s']);
          formValue.stock = stockResponse?.data;
          formValue.stock_id = stockResponse?.data?.id;
          await getQuantityInStock(formValue);
        } else {
          Vue.prototype.$notifyError('Selecione um Produto');
        }
      },
    },
  },
};
