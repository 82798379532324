<template>
  <v-menu v-model="menu"
          :disabled="disabled"
          :close-on-content-click="!multiple"
          transition="scale-transition"
          offset-y
          full-width
          bottom
          right
          min-width="0">
    <template v-slot:activator="{ on }">
      <v-text-field slot="activator"
                    :value="formattedText"
                    :label="label"
                    :required="required"
                    :disabled="disabled"
                    :hint="hint"
                    :persistent-hint="persistentHint"
                    :solo="solo"
                    :class="[textInputClass, large ? 'dm-large-inline-calendar' : '', hideLine ? 'dm-hide-line' : '']"
                    :hide-details="hideDetails"
                    :single-line="singleLine"
                    :prepend-icon="prependIcon"
                    :append-icon="appendIcon"
                    :placeholder="placeholder"
                    :rules="rules"
                    :clearable="clearable"
                    :rounded="rounded"
                    :filled="filled"
                    readonly
                    v-on="on" />
    </template>
    <DataList :headers="headers"
              :items="localItems"
              no-search
              :disable-pagination="disablePagination"
              :hide-default-footer="hideDefaultFooter"
              @select-item="onSelectItem" />
  </v-menu>
</template>

<script>
import DataList from '@sharedComponents/dynamic/DataList';

export default {
  name: 'DataListSelect',
  components: {
    DataList,
  },
  props: {
    value: {
      type: [Object, Array],
      default: () => [],
    },
    service: {
      type: String,
      required: true,
    },
    relatedIdField: {
      type: String,
      required: true,
    },
    formattedField: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    concatDestroyOnRemove: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: [Object, String],
      default: '',
    },
    appendIcon: {
      type: [Object, String],
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    textInputClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: undefined,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideLine: {
      type: Boolean,
      default: false,
    },
    multiple: { // Todo implement not multiple option
      type: Boolean,
      default: true,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      localItems: [],
      localSelectedItems: [],
    };
  },
  computed: {
    formattedText() {
      let formatted = '';
      this.localSelectedItems.forEach((checkedItem) => {
        if (checkedItem._destroy === true) {
          return;
        }
        const item = this.localItems.find((i) => i.id === checkedItem[this.relatedIdField]) || {};
        if (item.isSelected) {
          if (formatted !== '') {
            formatted += ', ';
          }
          formatted += item[this.formattedField];
        }
      });
      return formatted;
    },
  },
  watch: {
    value: {
      handler: 'onValueChange',
      immediate: true,
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.$api[this.service].index().then((response) => {
        this.localItems = response.data;
        this.setCheckedItems();
      });
    },
    onValueChange() {
      this.setCheckedItems();
    },
    setCheckedItems() {
      this.localSelectedItems = this.value; // todo, clone array to avoid 'by ref' changes
      this.localSelectedItems.forEach((checkedItem) => {
        const foundIndex = this.localItems.findIndex((item) => item.id === checkedItem[this.relatedIdField]);
        if (foundIndex !== -1) {
          const item = this.localItems[foundIndex];
          item.isSelected = true;
        }
      });
    },
    onSelectItem(item) {
      let payload = { [this.relatedIdField]: item.id };
      const foundIndex = this.localSelectedItems.findIndex((checkedItem) => item.id === checkedItem[this.relatedIdField]);
      if (foundIndex !== -1) {
        payload = this.localSelectedItems[foundIndex];
        this.localSelectedItems.splice(foundIndex, 1);
      }

      if (item.isSelected) {
        if (payload._destroy) {
          delete payload._destroy;
        }
        this.localSelectedItems.push(payload);
      } else if (!item.isSelected) {
        if (this.concatDestroyOnRemove) {
          payload._destroy = true;
          this.localSelectedItems.push(payload);
        }
      }

      this.$emit('input', this.localSelectedItems);
    },
  },
};
</script>
