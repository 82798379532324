<template>
  <div :id="item.id">
    <GenericCardPanel
      title="Informações da Compra"
      :fields="purchaseInfoFields" />
    <GenericCardPanel
      title="Informações do Frete"
      :fields="freightInfoFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabOrderPurchase',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      purchaseInfoFields: [],
      freightInfoFields: [],
    };
  },
  mounted() {
    this.purchaseInfoFields = [
      { label: 'Status', value: this.item.status_formatted, col: 12 },
      { label: 'Fornecedor', value: this.item?.vendor?.to_s ? this.item.vendor.to_s : '', col: 8 },
      { label: 'Número do Pedido', value: this.item.purchase_order, col: 2 },
      { label: 'Data Emissão', value: this.item.emission_date_formatted, col: 2 },
      { label: 'Colaborador (Solicitante)', value: this.item?.collaborator?.to_s ? this.item.collaborator.to_s : '', col: 8 },
      { label: 'Número da NF', value: this.item.note, col: 2 },
      { label: 'Data Previsão', value: this.item.forecast_date_formatted, col: 2 },
      { label: 'Indicador de Presença', value: this.item.presence_indicator_formatted, col: 8 },
      { label: 'Número da Ordem', value: this.item.order, col: 2 },
      { label: 'Data Entrega', value: this.item.delivery_date_formatted, col: 2 },
      { label: 'Consumidor Final', value: this.item.is_costumer ? 'Sim' : 'Não', col: 3 },
      { label: 'Forma de Pagamento', value: this.item?.payment_method?.to_s ? this.item.payment_method.to_s : '', col: 3 },
      { label: 'Parcelamento', value: this.item?.type_installment?.to_s ? this.item.type_installment.to_s : '', col: 3 },
      { label: 'Estoque', value: this.item?.stock?.to_s ? this.item.stock.to_s : '', col: 3 },
      { label: 'Valor Total', value: this.item.total_ordered_formatted, col: 3 },
      { label: 'Natureza da Operação', value: this.item?.nature_operation?.to_s ? this.item.nature_operation.to_s : '', col: 3 },
    ];
    this.freightInfoFields = [
      { label: 'Frete', value: this.item.freight_formatted ? this.item.freight_formatted : '', col: 3 },
      { label: 'Valor do Frete', value: this.item.freight_value_formatted ? this.item.freight_value_formatted : '', col: 2 },
      { label: 'Incluir Frete no Total', value: this.item.use_freight ? 'Sim' : 'Não', col: 2 },
      { label: 'Despesas', value: this.item.expense_value_formatted ? this.expense_value_formatted : '', col: 2 },
    ];
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style scoped></style>
