import Vue from 'vue';

export const DefaultScreenEvents = {
  async beforeSave(formValue) {
    formValue.items = formValue.items || [];
    const visibleItems = formValue.items.filter((item) => !item._destroy);
    if (!visibleItems.length) {
      return 'Não é possível gravar o pedido sem ítens!';
    }
    const action = formValue.id ? 'update' : 'create';

    if (action === 'update' && formValue.status === 'canceled') {
      return 'Não é possível alterar uma ordem cancelada.';
    }

    if (action === 'update' && formValue.status === 'delivered') {
      return 'Não é possível alterar uma ordem entregue.';
    }

    if (!formValue.status || formValue.status === 'initial') {
      formValue.status = 'pending';
    }

    if (!formValue.nature_operation) {
      const { data } = await Vue.prototype.$api.NatureOperations.show(formValue.nature_operation_id);
      formValue.nature_operation = data;
    }
  },
  beforeCreate(formValue) {
    formValue.emission_date = new Date();
  },
  beforeDelete(formValue) {
    if (formValue.status !== 'canceled') {
      return 'Primeiro efetue o cancelamento da ordem.';
    }
  },

};
