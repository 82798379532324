import Vue from 'vue';

export const calculateOrderServiceTaxesRealTime = async ({ items, provision_address }) => {
  if (provision_address.uf) {
    const itemsData = items.filter((item) => !item._destroy).map((item) => ({
      product_id: item.product.id,
      nature_operation: item.nature_operation,
      total_value: parseFloat(item.total_value ?? 0),
      discount_value: parseFloat(item.discount_value ?? 0),
    }));
    try {
      const payload = {
        items: itemsData,
        uf: provision_address?.uf,
      };

      // @TODO - Refactor ISS Taxes calc to get only nature operation params used
      const response = await Vue.prototype.$api.OrderServicesISS.create({ data: payload });
      return response.data;
    } catch (error) {
      Vue.prototype.$notifyError(error);
      return null;
    }
  } else {
    Vue.prototype.$notifyError('Preencha todos os campos obrigatórios para calcular o ISS');
  }
};
