import { MASK_CPF_CNPJ } from '@constants/masks';

export default [
  // Line 1
  {
    label: 'Protocolo de Autorização de Uso',
    name: 'emitter.protocol',
    formattedName: 'protocol_formatted',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 6,
    disabled: true,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
  },
  {
    label: 'Inscrição Estadual',
    name: 'emitter.ie',
    type: 'date',
    component: 'dynamic-text-field',
    disabled: true,
    cols: 3,
    readOnly: true,
    colClass: 'nf-field top bottom left',
    prependIcon: '',
  },
  {
    label: 'CNPJ/CPF',
    name: 'emitter.cnpj_cpf',
    type: 'text',
    mask: MASK_CPF_CNPJ,
    component: 'dynamic-text-field',
    cols: 3,
    prependIcon: '',
    readOnly: true,
    disabled: true,
    colClass: 'nf-field top left bottom right',
  },
];
