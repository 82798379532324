import * as AppUtils from '@plugins/app-utils';

const endpoint = 'subscription';

export default ($axios) => ({
  /**
   * Get all objects
   */
  async index() {
    let responseData = {};
    await $axios.get(`${endpoint}.json`).then((response) => {
      responseData = this.formatResponseData(response.data, 'row');
      // responseData = response.data;
    });
    return responseData;
  },
  /**
   * Get one object
   * @param {Number} id Subscription Id
   */
  async show(id) {
    let responseData = {};
    await $axios.get(`${endpoint}/${id}.json`).then((response) => {
      // responseData = this.formatResponseData(response.data, 'row');
      responseData = response.data;
    });
    return responseData;
  },
  /**
   * Get all objects
   */
  async plans(payload) {
    let responseData = {};
    await $axios.post(`${endpoint}/active_plans.json`, payload).then((response) => {
      responseData = this.formatResponseDataPlans(response.data);
      responseData = response.data;
    });
    return responseData;
  },
  /**
   * Get current object
   */
  async current() {
    let responseData = {};
    await $axios.get('subscription.json').then((response) => {
      responseData = this.formatResponseData(response.data, 'row');
    });
    return responseData;
  },
  /** create one object */
  create(payload) {
    return $axios.post(`${endpoint}.json`, payload);
  },
  /** update one object */
  update(payload) {
    return $axios.put(`${endpoint}.json`, payload);
  },
  /** cancel one object */
  cancel() {
    return $axios.get(`${endpoint}/cancel.json`);
  },
  /**
   * Get transactions of object
   * @param {Number} id Subscription Id
   */
  async getTransactions() {
    let responseData = {};
    await $axios.get(`${endpoint}/transactions.json`).then((response) => {
      responseData = this.formatResponseTransactionsData(response.data, 'table');
    });
    return responseData;
  },
  /*
  * Format Functions
  */
  formatResponseData(data, type = 'table') {
    const format = (item) => {
      item.status_formatted = AppUtils.getOptionText('subscriptions_transactions_status', item.status);
      item.amount_formatted = `${AppUtils.formatCurrency(Number(item.amount))}`;
      item.payment_method_formatted = AppUtils.getOptionText('subscriptions_payment_methods', item.payment_method);
      item.boleto_expiration_date_formatted = AppUtils.formatDate(item.boleto_expiration_date);
      item.current_period_start_formatted = AppUtils.formatDate(item.current_period_start);
      item.current_period_end_formatted = AppUtils.formatDate(item.current_period_end);
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  formatResponseTransactionsData(data, type = 'table') {
    const format = (item) => {
      item.status_formatted = AppUtils.getOptionText('subscriptions_transactions_status', item.status);
      item.amount_formatted = `${AppUtils.formatCurrency(Number(item.amount))}`;
      item.payment_method_formatted = AppUtils.getOptionText('subscriptions_payment_methods', item.payment_method);
      item.boleto_expiration_date_formatted = AppUtils.formatDate(item.boleto_expiration_date, true);
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },

  formatResponseDatad(data, type = 'table') {
    const formatTransactions = (item) => {
      item.status_formatted = AppUtils.getOptionText('subscriptions_transactions_status', item.status);
      item.amount_formatted = `${AppUtils.formatCurrency(Number(item.amount))}`;
      item.payment_method_formatted = AppUtils.getOptionText('subscriptions_payment_methods', item.payment_method);
      item.boleto_expiration_date_formatted = AppUtils.formatDate(item.boleto_expiration_date, true);
    };

    const format = (item) => {
      item.status_formatted = AppUtils.getOptionText('subscriptions_status', item.status);
      item.plan_name_formatted = AppUtils.getOptionText('subscriptions_plans', item.plan_id);
      item.payment_method_formatted = AppUtils.getOptionText('subscriptions_payment_methods', item.payment_method);
      item.current_period_start_formatted = AppUtils.formatDate(item.current_period_start, true);
      item.current_period_end_formatted = AppUtils.formatDate(item.current_period_end, true);

      // formatTransactions(item.transaction);
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    if (type === 'transactions') {
      data.forEach((item) => {
        formatTransactions(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  formatResponseDataPlans(data, type = 'table') {
    const format = (item) => {
      item.description = item.description.split('@');
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };

      return responseData;
    }

    format(data);
    return data;
  },
});
