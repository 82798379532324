import {
  ICON_DASHBOARD,
  ICON_BANK,
} from '@constants/icons';
import drawerItems from './drawerItems';

import AntecipationDashboardSchema from '@schemas/AntecipationDashboard';
import AssignorSchema from '@schemas/Assignor';
import AvailableInvoiceSchema from '@schemas/AvailableInvoice';

const app = {
  namespaced: true,
  state: () => ({
    version: '1.0.0',
    drawer: null,
    drawerImage: true,
    mini: false,
    showSubMenu: false,
    printMode: false,
    antecipationItems: [
      { title: 'Dashboard', icon: ICON_DASHBOARD, to: AntecipationDashboardSchema.routes.list.name },
      {
        title: 'Antecipação de Recebíveis',
        icon: ICON_BANK,
        items: [
          { title: 'Cedentes', to: AssignorSchema.routes.list.name },
          { title: 'Notas Fiscais Disponíveis', to: AvailableInvoiceSchema.routes.list.name },
        ],
      },
    ],
    items: drawerItems.items,
  }),
  /** @FIXME - Remover/comentar funções de store */
  mutations: {
    setShowSubMenu(state, value) {
      state.showSubMenu = value;
    },
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    toggleMini(state) {
      state.mini = !state.mini;
    },
    toggleImage(state) {
      state.drawerImage = !state.drawerImage;
    },
    setPrintMode(state, value) {
      state.printMode = value;
    },
  },
  /** @FIXME - Remover/comentar funções de store */
  actions: {
    setShowSubMenu({ commit }, value) {
      commit('setShowSubMenu', value);
    },
    toggleDrawer({ commit }) {
      commit('toggleDrawer');
    },
    toggleMini({ commit }) {
      commit('toggleMini');
    },
    toggleImage({ commit }) {
      commit('toggleImage');
    },
    setPrintMode({ commit }, value) {
      commit('setPrintMode', value);
    },
  },
};

export default app;
export { app };
