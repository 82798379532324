import {
  ICON_DESCRIPTION,
  ICON_MEASURING_UNIT,
  ICON_TAXES,
  ICON_TRUCK,
} from '@constants/icons';
import { OPTIONS_PRESENCE_INDICATOR, OPTIONS_FREIGHT, OPTIONS_ORDERSPURCHASESALE_STATUS } from '@constants/options';
import PartialPurchaseBusiness from '@businesses/PartialPurchase';
import PartialPurchaseItem from './PartialPurchaseItem';
import PartialPurchaseItemTax from './PartialPurchaseItemTax';
import PartialPurchaseTransporter from './PartialPurchaseTransporter';

import TabPartialPurchase from '@modules/supply/pages/partialPurchases/components/TabPartialPurchase';
import TabPartialPurchaseItens from '@modules/supply/pages/partialPurchases/components/TabPartialPurchaseItens';
import TabPartialPurchaseItensTax from '@modules/supply/pages/partialPurchases/components/TabPartialPurchaseItensTax';
import TabPartialPurchaseTransporter from '@modules/supply/pages/partialPurchases/components/TabPartialPurchaseTransporter';

import OrderPurchaseSchema from './OrderPurchase';

const orderPurchaseItems = [];

export function getOrderPurchasesItems() { return orderPurchaseItems; }
export function setOrderPurchasesItems(param) { orderPurchaseItems.push(param); }
export function resetOrderPurchasesItems() { orderPurchaseItems.splice(0); }

export default {
  service: 'PartialPurchases',
  business: PartialPurchaseBusiness,
  icon: OrderPurchaseSchema.icon,
  routes: {
    list: { path: '/supply/purchases/partials', name: 'supply-purchasepartials-list' },
    create: { path: '/supply/purchases/partials/create', name: 'supply-purchasepartials-create' },
    edit: { path: '/supply/purchases/partials/edit/:id', name: 'supply-purchasepartials-edit' },
  },
  titles: {
    item: 'Entrega Parcial de Compras',
    items: 'Entregas Parciais de Compras',
    edit: 'Editar Entrega Parcial de Compras',
    create: 'Criar Entrega Parcial de Compras',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Entrega parcial de compras excluida com sucesso!',
    successEdit: 'Entrega parcial de compras alterada com sucesso!',
    successCreate: 'Entrega parcial de compras criada com sucesso!',
    errorSave: 'Erro ao salvar entrega parcial de compras!',
    tryEditCanceled: 'Não é possível editar uma entrega parcial de compras cancelada.',
    confirmCancel: 'Deseja cancelar a entrega parcial de compras?',
    successCancel: 'Entrega parcial de compras cancelada com sucesso',
    errorCancel: 'Erro ao cancelar entrega parcial de compras',
  },
  filterUrl: 'purchase_partials/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  canClone: false,
  statusSettings: {
    options: OPTIONS_ORDERSPURCHASESALE_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  formName: 'purchasespartials',
  fields: [
    {
      label: 'INFORMAÇÕES DA ENTREGA PARCIAL',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
      tabGroup: 'infoPartialPurchase',
    },
    {
      label: 'Status',
      formattedName: 'status',
      name: 'status',
      type: 'select',
      options: 'orders_purchase_sale_status',
      list: true,
    },
    {
      label: 'Fornecedor',
      name: 'vendor_id',
      formattedName: 'vendor.to_s',
      type: 'number',
      hint: '',
      list: true,
      required: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_register_array: 'vendor',
          query: ['id', 'name', 'cnpj_cpf', 'addresses', ['id', 'to_s'], 'preference', ['shipping_company_id']],
        }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      withDetails: true,
      typeDetails: 'vendor',
      md: 8,
      disabled: (formValue) => {
        if (formValue?.id) {
          return true;
        }
        return false;
      },
      tabGroup: 'infoPartialPurchase',
    },
    {
      label: 'Pedido de Compra',
      name: 'order_purchase_id',
      type: 'string',
      component: 'dynamic-select',
      items: orderPurchaseItems,
      disabled: (formValue) => {
        if (formValue?.id) {
          return true;
        }
        if (formValue?.vendor_id) {
          return false;
        }
        return true;
      },
      md: 2,
      required: true,
      tabGroup: 'infoPartialPurchase',
    },
    {
      label: 'Data Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      list: true,
      tabGroup: 'infoPartialPurchase',
    },
    {
      label: 'Consumidor Final',
      name: 'is_costumer',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
    },
    {
      label: 'Indicador de Presença',
      name: 'presence_indicator',
      formattedName: 'presence_indicator_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_PRESENCE_INDICATOR,
      md: 4,
      required: true,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPartialPurchase',
    },
    {
      label: 'Entrega Parcial',
      name: 'partial_number',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 2,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
    },
    {
      label: 'NFe',
      name: 'note',
      formattedName: 'note_formatted',
      list: true,
      type: 'text',
      component: 'dynamic-text-field',
      readOnly: true,
      md: 2,
      disabled: true,
      tabGroup: 'infoPartialPurchase',
    },

    {
      label: 'Data Entrega',
      name: 'delivery_date',
      formatteName: 'delivery_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      md: 2,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPartialPurchase',
    },
    {
      label: 'Forma de Pagamento',
      name: 'payment_method_id',
      formattedName: 'payment_method.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-paymentmethod-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['payment_method'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPartialPurchase',
    },
    {
      label: 'Parcelamento',
      name: 'type_installment_id',
      formattedName: 'type_installment.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-typeinstallment-create',
      dataSource: {
        service: 'TypeInstallment',
        method: 'getByFilter',
        props: [{ inactive_eq: false }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPartialPurchase',
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      formattedName: 'stock.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPartialPurchase',
    },
    {
      label: 'Total de Impostos Aproximados',
      formattedName: 'total_tax_aproximates',
      name: 'total_tax_aproximates',
      type: 'number',
    },
    {
      label: 'Valor Total',
      formattedName: 'total_ordered_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'INFORMAÇÕES DE FRETE',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'freight',
    },
    {
      label: 'Frete',
      name: 'freight',
      formattedName: 'freight_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_FREIGHT,
      required: true,
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'freight',
    },
    {
      label: 'Valor do Frete',
      name: 'freight_value',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'freight',
    },
    {
      label: 'Despesas',
      name: 'expense_value',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'freight',
    },
    {
      label: 'Incluir Frete no Total',
      name: 'use_freight',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'freight',
    },
    {
      label: 'TRIBUTAÇÕES DA DEVOLUÇÃO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      formattedName: 'nature_operation.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_nature_in: ['purchase'],
          query: ['id', 'name'],
        }],
      },
      itemText: 'name',
      itemValue: 'id',
      list: true,
      required: true,
      md: 12,
      disabled: (formValue) => {
        if (formValue?.order_purchase_id) {
          return false;
        }
        return true;
      },
    },
  ],
  childSchemas: [
    {
      label: 'Itens da Entrega Parcial de Compras',
      name: 'items',
      schema: PartialPurchaseItem,
      noCreate: true,
    },
    {
      label: 'Conferência de Impostos',
      name: 'taxes',
      noCreate: true,
      noEdit: true,
      noDelete: true,
      noActions: true,
      schema: PartialPurchaseItemTax,
      hideDefaultHeader: true,
      customHeader: {
        customClass: 'custom-child-header-order-purchase',
        ungroupedHeadersFront: 2,
        groupHeaders: [
          { text: 'SIMPLES NACIONAL', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS ST', colspan: 3, class: 'order-purchase-header' },
          { text: 'IPI', colspan: 3, class: 'order-purchase-header' },
          { text: 'PIS', colspan: 3, class: 'order-purchase-header' },
          { text: 'COFINS', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS CONSUMIDOR FINAL', colspan: 3, class: 'order-purchase-header' },
          { text: 'VALOR APROXIMADOS', colspan: 3, class: 'order-purchase-header' },
        ],
      },
    },
    {
      label: 'Transportadora',
      name: 'transporter',
      schema: PartialPurchaseTransporter,
      hasOne: true,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabPartialPurchase,
    },
    {
      icon: ICON_MEASURING_UNIT,
      title: 'Itens do Entrega Parcial de Compra',
      tabContent: TabPartialPurchaseItens,
    },
    {
      icon: ICON_TAXES,
      title: 'Conferência de Impostos',
      tabContent: TabPartialPurchaseItensTax,
    },
    {
      icon: ICON_TRUCK,
      title: 'Transportadora',
      tabContent: TabPartialPurchaseTransporter,
    },
  ],
};
