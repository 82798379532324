import DynamicService from '@sharedServices/dynamic/DynamicService';
import { getOptionText, formatCurrency, formatNumber } from '@plugins/app-utils';
import { OPTIONS_ICMS_CST, OPTIONS_ICMS_CST_SIMPLES } from '@constants/options';

const formatResponse = (item) => {
  item.id = parseInt(item.id);
  item.states = item.states ?? item.nature_operation_states;
  item.type_nature_formatted = getOptionText('type_nature', item.type_nature);
  item.use_move_stock_formatted = item.use_move_stock ? 'Sim' : 'Não';
  item.use_manual_tax_launch_formatted = item.use_manual_tax_launch ? 'Sim' : 'Não';
};

const formatRequest = (item) => {
  item.date_origin = item.date_origin ?? '';
  item.simple_national_type = item.simple_national_type ?? '';
};

const endpoint = 'nature_operations';

const NatureOperations = DynamicService(endpoint, {
  formatResponse,
  formatRequest,
});

const NatureOperationCommands = ($axios) => ({
  async index_to_nfs(id) {
    const response = await $axios.post(`nature_operations/${id}.json`);
    return response;
  },
  async getDetailsByUF(id, uf) {
    if (id && uf) {
      const response = await $axios.get(`nature_operations/details/${id}/${uf}`);
      if (response.data) return response.data;
      return response;
    }
  },
});

const action = 'clone';
const CloneNatureOperationStates = ($axios) => ({
  async clone(data) {
    const response = await $axios.post(`${endpoint}/${action}.json`, data);
    return response;
  },
});

function formatBoolean(value) {
  return value ? 'Sim' : 'Não';
}

function formatOption(option, value) {
  if (!value) return '-';
  return getOptionText(option, value);
}
const NatureOperationStates = DynamicService('nature_operations/:nature_operation_id/states', {
  formatResponse: (state) => {
    state.image = `${state.uf}.png`;
    state.destiny_location_formatted = getOptionText('destiny_location', state.destiny_location);

    const icms_formatted = {
      cst_formatted: formatOption('icms_cst_simples', state?.icms?.cst),
      origin_formatted: formatOption('icms_origin', state?.icms?.origin),
      aliquot_formatted: `${formatNumber(parseFloat(state?.icms?.aliquot ?? 0))}%`,
      determination_mode_formatted: formatOption('icms_determination_mode', state?.icms?.determination_mode),
      use_simple_national_formatted: formatBoolean(state?.icms?.use_simple_national),
      use_credits_formatted: formatBoolean(state?.icms?.use_credits),
      use_debits_formatted: formatBoolean(state?.icms?.use_debits),
      use_value_reduction_formatted: formatBoolean(state?.icms?.use_value_reduction),
      use_basis_reduction_formatted: formatBoolean(state?.icms?.use_basis_reduction),
      use_discount_formatted: formatBoolean(state?.icms?.use_discount),
      use_ipi_formatted: formatBoolean(state?.icms?.use_ipi),
      use_expenses_formatted: formatBoolean(state?.icms?.use_expenses),
      use_freight_formatted: formatBoolean(state?.icms?.use_freight),
      use_import_formatted: formatBoolean(state?.icms?.use_import),
      exemption_reason_formatted: formatOption('icms_exemption_reason', state?.icms?.exemption_reason),
      reduction_value_formatted: `${formatNumber(state?.icms?.reduction_value || 0, 10)}%`,
      deferral_aliquot_formatted: `${formatNumber(state?.icms?.deferral_aliquot || 0)}`,
      fcp_formatted: `${formatNumber(parseFloat(state?.icms?.fcp || 0))}`,
    };
    const icms_st_formatted = {
      determination_mode_formatted: formatOption('icms_st_determination_mode', state?.icms_st?.determination_mode),
      mva_formatted: `${formatNumber(parseFloat(state?.icms_st?.mva || 0))}%`,
      internal_icms_value_formatted: formatCurrency(state?.icms?.internal_icms_value || 0),
      use_freight_formatted: formatBoolean(state?.icms_st?.use_freight),
      use_expenses_formatted: formatBoolean(state?.icms_st?.use_expenses),
      use_discount_formatted: formatBoolean(state?.icms_st?.use_discount),
      use_highlight_formatted: formatBoolean(state?.icms_st?.use_highlight),
      use_basis_reduction_formatted: formatBoolean(state?.icms_st?.use_basis_reduction),
      use_ipi_formatted: formatBoolean(state?.icms_st?.use_ipi),
      use_pmpf_formatted: formatBoolean(state?.icms_st?.use_pmpf),
      use_difal_st_formatted: formatBoolean(state?.icms_st?.use_difal_st),
      fecop_number_formatted: formatNumber(state?.icms_st?.fecop_number || 0),
      fecop_aliquot_formatted: `${formatNumber(state?.icms_st?.fecop_aliquot || 0)}%`,
      pmpf_value_formatted: formatCurrency(parseFloat(state?.icms_icms_st?.pmpf || 0)),
      reduction_value_formatted: `${formatNumber(parseFloat(state?.icms_st?.reduction_value || 0), 10)}%`,
      fcp_aliquot_formatted: formatCurrency(parseFloat(state?.icms_st?.fcp_aliquot || 0)),
    };
    const icms_final_costumer_formatted = {
      interstate_aliquot_formatted: `${formatNumber(state?.icms_final_costumer?.interstate_aliquot || 0)}%`,
      internal_aliquot_formatted: `${formatNumber(state?.icms_final_costumer?.internal_aliquot || 0)}%`,
      use_internal_formatted: formatBoolean(state?.icms_final_costumer?.use_internal),
      operation_aliquot_formatted: `${formatNumber(state?.icms_final_costumer?.operation_aliquot || 0)}%`,
      fcp_aliquot_formatted: `${formatNumber(state?.icms_final_costumer?.fcp_aliquot || 0)}%`,
      origin_uf_aliquot_formatted: `${formatNumber(state?.icms_final_costumer?.origin_uf_aliquot || 0)}%`,
      use_origin_aliquot_formatted: formatBoolean(state?.icms_final_costumer?.use_origin_aliquot),
      destiny_uf_aliquot_formatted: `${formatNumber(state?.icms_final_costumer?.destiny_uf_aliquot || 0)}%`,
    };
    const ipi_formatted = {
      cst_formatted: formatOption('ipi_cst', state?.ipi?.cst),
      aliquot_formatted: `${formatNumber(parseFloat(state?.ipi?.aliquot || 0))}%`,
      framming_class_formatted: state?.ipi?.framming_class || '-',
      framming_code_formatted: state?.ipi?.framming_code || '-',
      use_credits_formatted: formatBoolean(state?.ipi?.use_credits),
      use_debits_formatted: formatBoolean(state?.ipi?.use_debits),
      use_freight_formatted: formatBoolean(state?.ipi?.use_freight),
      use_discount_formatted: formatBoolean(state?.ipi?.use_discount),
      use_expenses_formatted: formatBoolean(state?.ipi?.use_expenses),
      use_on_product_formatted: formatBoolean(state?.ipi?.use_on_product),
      use_import_formatted: formatBoolean(state?.ipi?.use_import),
    };
    const pis_formatted = {
      cst_formatted: formatOption('pis_cofins_cst', state?.pis?.cst),
      aliquot_formatted: `${formatNumber(parseFloat(state?.pis?.aliquot || 0))}%`,
      use_credits_formatted: formatBoolean(state?.pis?.use_credits),
      use_debits_formatted: formatBoolean(state?.pis?.use_debits),
      use_freight_formatted: formatBoolean(state?.pis?.use_freight),
      use_discount_formatted: formatBoolean(state?.pis?.use_discount),
      use_expenses_formatted: formatBoolean(state?.pis?.use_expenses),
      use_on_product_formatted: formatBoolean(state?.pis?.use_on_product),
      use_import_formatted: formatBoolean(state?.pis?.use_import),
      use_basis_reduction_formatted: formatBoolean(state?.pis?.use_basis_reduction),
      reduction_value_formatted: `${formatNumber(parseFloat(state?.pis?.reduction_value || 0), 10)}%`,
    };
    const cofins_formatted = {
      cst_formatted: formatOption('pis_cofins_cst', state?.cofins?.cst),
      aliquot_formatted: `${formatNumber(parseFloat(state?.cofins?.aliquot || 0))}%`,
      use_credits_formatted: formatBoolean(state?.cofins?.use_credits),
      use_debits_formatted: formatBoolean(state?.cofins?.use_debits),
      use_freight_formatted: formatBoolean(state?.cofins?.use_freight),
      use_discount_formatted: formatBoolean(state?.cofins?.use_discount),
      use_expenses_formatted: formatBoolean(state?.cofins?.use_expenses),
      use_on_product_formatted: formatBoolean(state?.cofins?.use_on_product),
      use_import_formatted: formatBoolean(state?.cofins?.use_import),
      use_basis_reduction_formatted: formatBoolean(state?.cofins?.use_basis_reduction),
      reduction_value_formatted: `${formatNumber(parseFloat(state?.cofins?.reduction_value || 0), 10)}%`,
    };
    const iss_formatted = {
      percentage_csll_formatted: `${formatNumber(parseFloat(state?.iss?.percentage_csll || 0))}%`,
      above_csll_formatted: `${formatNumber(parseFloat(state?.iss?.above_csll || 0))}`,
      is_withhold_csll_formatted: formatBoolean(state?.iss?.is_withhold_csll),

      percentage_inss_formatted: `${formatNumber(parseFloat(state?.iss?.percentage_inss || 0))}%`,
      above_inss_formatted: `${formatNumber(parseFloat(state?.iss?.above_inss || 0))}`,
      is_withhold_inss_formatted: formatBoolean(state?.iss?.is_withhold_inss),

      percentage_iss_formatted: `${formatNumber(parseFloat(state?.iss?.percentage_iss || 0))}%`,
      above_iss_formatted: `${formatNumber(parseFloat(state?.iss?.above_iss || 0))}`,
      is_withhold_iss_formatted: formatBoolean(state?.iss?.is_withhold_iss),

      percentage_ir_formatted: `${formatNumber(parseFloat(state?.iss?.percentage_ir || 0))}%`,
      above_ir_formatted: `${formatNumber(parseFloat(state?.iss?.above_ir || 0))}`,
      is_withhold_ir_formatted: formatBoolean(state?.iss?.is_withhold_ir),

      percentage_pis_formatted: `${formatNumber(parseFloat(state?.iss?.percentage_pis || 0))}%`,
      above_pis_formatted: `${formatNumber(parseFloat(state?.iss?.above_pis || 0))}`,
      is_withhold_pis_formatted: formatBoolean(state?.iss?.is_withhold_pis),

      percentage_cofins_formatted: `${formatNumber(parseFloat(state?.iss?.percentage_cofins || 0))}%`,
      above_cofins_formatted: `${formatNumber(parseFloat(state?.iss?.above_cofins || 0))}`,
      is_withhold_cofins_formatted: formatBoolean(state?.iss?.is_withhold_cofins),
    };

    state.icms = { ...state.icms, ...icms_formatted };
    state.icms_st = { ...state.icms_st, ...icms_st_formatted };
    state.icms_final_costumer = { ...state.icms_final_costumer, ...icms_final_costumer_formatted };
    state.ipi = { ...state.ipi, ...ipi_formatted };
    state.pis = { ...state.pis, ...pis_formatted };
    state.cofins = { ...state.cofins, ...cofins_formatted };
    state.iss = { ...state.iss, ...iss_formatted };
    state.cbenef_formatted = state.cbenef || '-';
  },
  formatRequest: (item) => {
    delete item.image;
    item.icms_attributes = item.icms;
    item.icms_st_attributes = item.icms_st;
    item.icms_final_costumer_attributes = item.icms_final_costumer;
    item.ipi_attributes = item.ipi;
    item.pis_attributes = item.pis;
    item.iss_attributes = item.iss;
    item.cofins_attributes = item.cofins;
  },
});

const NatureOperationStatesICMS = ($axios) => ({
  async getICSMItems() {
    const me = await this.me();
    const company = await this.companyShow(me.company.id);
    const response = {
      data: company.tax_regime === 'normal_regime' ? OPTIONS_ICMS_CST : OPTIONS_ICMS_CST_SIMPLES,
    };
    return response;
  },
  async companyShow(id) {
    let responseData = {};
    await $axios.get(`companies/${id}.json`).then((response) => {
      responseData = response.data;
    });
    return responseData;
  },
  async me() {
    let responseData = {};
    await $axios.get('users/me.json').then((response) => {
      responseData = response.data;
    });
    return responseData;
  },
});

export default {
  CloneNatureOperationStates, NatureOperations, NatureOperationStates, NatureOperationStatesICMS, NatureOperationCommands,
};
export {
  CloneNatureOperationStates, NatureOperations, NatureOperationStates, NatureOperationStatesICMS, NatureOperationCommands,
};
