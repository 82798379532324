import axios from 'axios';
import * as AppUtils from '@plugins/app-utils';

const endpoint = 'anticipation/assignors';

const Assignors = ($axios) => ({
  async index() {
    let responseData = {};

    await $axios.get(`${endpoint}.json`).then((response) => {
      responseData = this.formatResponseData(response.data);
    });

    return responseData;
  },
  async show(id) {
    let responseData = {};

    await $axios.get(`${endpoint}/${id}.json`).then((response) => {
      responseData = this.showFormatResponseData(response.data, 'row');
    });

    return responseData;
  },
  async getAnticipationLimits(cnpj) {
    let responseData = {};

    const payload = {
      q: {
        cnpj_cont: cnpj,
      },
    };
    await axios.post('/anticipation_limit/filters.json', payload).then((response) => {
      responseData = response.data;
    });

    return responseData;
  },
  async saveAnticipationLimit(payload) {
    let responseData = {};

    await axios.post('/anticipation_limit.json', payload).then((response) => {
      responseData = response.data;
    });

    return responseData;
  },
  async updateAnticipationLimit(id, payload) {
    let responseData = {};

    await axios.put(`/anticipation_limit/${id}.json`, payload).then((response) => {
      responseData = response.data;
    });

    return responseData;
  },
  formatResponseData(data, type = 'table') {
    const format = (item) => {
      item.id = String(item.id);
      item.billed_amount_formatted = AppUtils.formatCurrency(item.billed_amount) || 'R$ 0,00';
      item.anticipated_value_formatted = AppUtils.formatCurrency(item.anticipated_value) || 'R$ 0,00';
      item.available_anticipated_formatted = AppUtils.formatCurrency(item.available_anticipated) || 'R$ 0,00';
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  showFormatResponseData(data, type = 'table') {
    const format = (item) => {
      data.limits = {
        id: 0,
        limit_value: 0,
        tax_value: 0,
      };
      data.exist_limits = false;
      item.drawees.forEach((i) => {
        i.adimplencia = '2 dias';
        i.quantity_titles = '157';
        i.date_register_formatted = AppUtils.formatDate(i.date_register);
        i.billed_amount_formatted = AppUtils.formatCurrency(i.billed_amount) || 'R$ 0,00';
        i.biggest_purchase_formatted = AppUtils.formatCurrency(i.biggest_purchase) || 'R$ 0,00';
        i.last_purchase_formatted = AppUtils.formatCurrency(i.last_purchase) || 'R$ 0,00';
      });
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  async getByFilter(filters, order) {
    const payload = {
      q: filters || {},
    };
    // add sort option
    if (order) {
      payload.q.s = order;
    }

    let responseData = {};
    await $axios.post(`${endpoint}/filters.json`, payload).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },
});

export { Assignors };
export default { Assignors };
