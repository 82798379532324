<template>
  <div :id="item.id">
    <GenericCardPanel
      title="Informações da Transportadora"
      :fields="transporterInfoFields" />
    <GenericCardPanel
      title="Informações do Veículo"
      :fields="vehicleInfoFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabOrderSaleTransporter',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      transporterInfoFields: [],
      vehicleInfoFields: [],
    };
  },
  mounted() {
    this.transporterInfoFields = [
      { label: 'Transportadora', value: this.item.shipping_company.to_s, col: 6 },
      { label: 'Espécie', value: this.item.volume_species, col: 3 },
      { label: 'Marca', value: this.item.volume_brand, col: 3 },
      { label: 'Quantidade', value: this.item.volume_amount, col: 3 },
      { label: 'Peso Líquido', value: this.item.volume_net_weight, col: 3 },
      { label: 'Peso Bruto', value: this.item.volume_gross_weight, col: 3 },
    ];
    this.vehicleInfoFields = [
      { label: 'Placa', value: this.item.vehicle_plate, col: 3 },
      { label: 'UF', value: this.item.vehicle_uf, col: 3 },
      { label: 'RNTC', value: this.item.vehicle_rntc, col: 3 },

    ];
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style scoped></style>
