import Vue from 'vue';

function resolveEntityUF(formValue) {
  return formValue?.vendor_uf || formValue?.vendor?.uf || formValue?.buyer_uf || formValue?.buyer?.uf;
}

export async function setCbenef(formValue, data) {
  const uf = resolveEntityUF(formValue);
  if (!uf) return;
  const cbenefSelected = data?.states?.filter((state) => state.uf_state === (uf)) ?? [];
  const { cbenef } = await Vue.prototype.$api.NatureOperationCommands.getDetailsByUF(formValue.nature_operation_id, uf);
  formValue.cbenef = cbenefSelected[0]?.state_cbenef ?? cbenef;
  Vue.set(formValue, 'cbenef', formValue.cbenef);
}
