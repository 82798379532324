/* eslint-disable no-param-reassign */
const endpoint = 'auto_increments';
export default ($axios) => ({

  async index() {
    const { data } = await $axios.get(`${endpoint}.json`);
    return data;
  },
  create(payload) {
    const formattedData = this.formatRequestData(payload);
    return $axios.post(`${endpoint}.json`, formattedData);
  },
  /** update one object */
  update(id, payload) {
    const formattedData = this.formatRequestData(payload);
    return $axios.put(`${endpoint}/${id}.json`, formattedData);
  },
  /** delete one object */
  delete(id) {
    return $axios.delete(`${endpoint}/${id}.json`);
  },

  formatResponseData(data, type = 'table') {
    const format = () => {
      // item.cnpj_cpf = AppUtils.formatCnpjCpf(item.cnpj_cpf);
      // item.type_register_formatted = item.type_register !== null ? this.getTypeRegisters(item.type_register) : '';
      // item.date_register = item.date_register !== null ? AppUtils.formatDate(item.date_register) : '';
      // item.inactive_date = item.inactive_date !== null ? AppUtils.formatDate(item.inactive_date) : '';
      // item.blocked_date = item.blocked_date !== null ? AppUtils.formatDate(item.blocked_date) : '';
      // item.credit_date = item.credit_date !== null ? AppUtils.formatDate(item.credit_date) : '';
      // item.tax_regime_formatted = item.tax_regime !== null ? this.getTaxRegime(item.tax_regime) : '';
      // item.taxpayer_type_formatted = item.taxpayer_type !== null ? this.getTaxPayer(item.taxpayer_type) : '';

      // if (item.contacts) {
      //   item.contacts.forEach((i) => {
      //     i.type_notification_formatted = i.type_notification !== null ? this.getTypeNotification(i.type_notification) : '';
      //     i.gender_formatted = i.gender !== null ? this.getGender(i.gender) : '';
      //     i.date_birth = i.date_birth !== null ? AppUtils.formatDate(i.date_birth) : '';
      //   });
      // }

      // if (item.preference.length === 0) { item.preference = {}; }
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  formatRequestData(data) {
    const format = (item) => {
      delete item.to_s;
    };
    format(data);
    return data;
  },

});
