import { ICON_COGS } from '@constants/icons';
import OrderProductionFactory from '@businesses/OrderProductionFactory';

export default {
  service: 'CompositionFactory',
  business: OrderProductionFactory,
  icon: ICON_COGS,
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  titles: {
    item: 'Item da Processo de Fabricação do Produto',
    items: 'Itens da Processo de Fabricação do Produto',
    edit: 'Editar Processo de Fabricação',
    create: 'Criar Processo de Fabricação',
    new: 'Adicionar Item',
  },
  fields: [
    {
      label: 'Ordem',
      name: 'step',
      type: 'text',
      list: true,
      disabled: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Processo',
      name: 'product_service_id',
      formattedName: 'product_service.code',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilter',
        props: [{ use_inactive_eq: false, product_type_description_eq: 'Setor Produtivo' }, 'name asc'],
      },
      itemText: 'code',
      itemValue: 'id',
      list: true,
      required: true,
      disabled: true,
      md: 4,
    },
    {
      label: 'Máquina',
      name: 'product_machine_id',
      formattedName: 'product_machine.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilter',
        props: [{ use_inactive_eq: false, product_type_description_eq: 'Máquina' }, 'name asc'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      list: true,
      required: true,
      md: 4,
    },
    {
      label: 'Tempo de Processo',
      name: 'time_process',
      formattedName: 'time_process_formatted',
      type: 'string',
      list: true,
      disabled: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo de Máquina',
      name: 'time_machine',
      formattedName: 'time_machine_formatted',
      type: 'text',
      list: true,
      disabled: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo de Setup',
      name: 'time_setup',
      formattedName: 'time_setup_formatted',
      type: 'text',
      list: true,
      disabled: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo de Total',
      name: 'time_total',
      formattedName: 'time_total_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 3,
      disabled: true,
    },
    {
      label: 'Tempo Sug. de Processo',
      name: 'suggested_time_process',
      formattedName: 'suggested_time_process_formatted',
      type: 'string',
      list: true,
      disabled: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo Sug. de Máquina',
      name: 'suggested_time_machine',
      formattedName: 'suggested_time_machine_formatted',
      type: 'text',
      list: true,
      disabled: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo Sug. de Setup',
      name: 'suggested_time_setup',
      formattedName: 'suggested_time_setup_formatted',
      type: 'text',
      list: true,
      disabled: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo Sug. Total',
      name: 'suggested_time_total',
      formattedName: 'suggested_time_total_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 3,
      disabled: true,
    },
    {
      label: 'Tempo Utl. de Máquina',
      name: 'used_time_machine',
      formattedName: 'used_time_machine_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo Utl. de Setup',
      name: 'used_time_setup',
      formattedName: 'used_time_setup_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo Utl. de Processo',
      name: 'used_time_process',
      formattedName: 'used_time_process_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-hours-field',
      md: 3,
      validateOnBlur: true,
    },
    {
      label: 'Tempo Utl. Total',
      name: 'used_time_total',
      formattedName: 'used_time_total_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 3,
      disabled: true,
    },
  ],
};
