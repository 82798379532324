<template>
  <div :id="item.id">
    <GenericCardPanel
      title="Informações do Serviço"
      :fields="serviceInfoFields" />
    <GenericCardPanel
      title="Informações de Tributação"
      :fields="taxesServiceFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabOrderService',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      serviceInfoFields: [],
      taxesServiceFields: [],
    };
  },
  mounted() {
    this.serviceInfoFields = [
      { label: 'Status', value: this.item.status_formatted, col: 6 },
      { label: 'Total do Serviço', value: this.item.total_services_formatted, col: 6 },
      { label: 'Cliente', value: this.item?.buyer?.to_s ? this.item.buyer.to_s : '', col: 8 },
      { label: 'Número da Ordem', value: this.item.order, col: 2 },
      { label: 'Data Inicio', value: this.item.start_datetime_formatted, col: 2 },
      { label: 'Vendedor', value: this.item?.salesman?.to_s ? this.item.salesman.to_s : '', col: 8 },
      { label: 'Número da NFS-e', value: this.item.nfse_number_formatted, col: 2 },
      { label: 'Data Final', value: this.item.end_datetime_formatted, col: 2 },
      { label: 'Prestador', value: this.item?.provider?.to_s ? this.item.provider.to_s : '', col: 8 },
      { label: 'Moeda', value: this.item?.coin?.to_s ? this.item.coin.to_s : '', col: 2 },
      { label: 'Lista de Preço', value: this.item?.price_list?.to_s ? this.item.price_list.to_s : '', col: 2 },
      { label: 'Consumidor Final', value: this.item.is_costumer ? 'Sim' : 'Não', col: 3 },
      { label: 'Indicador de Presença', value: this.item.presence_indicator_formatted, col: 6 },
      { label: 'Forma de Pagamento', value: this.item?.payment_method?.to_s ? this.item.payment_method.to_s : '', col: 3 },
      { label: 'Parcelamento', value: this.item?.type_installment?.to_s ? this.item.type_installment.to_s : '', col: 3 },
    ];
    this.taxesServiceFields = [
      { label: 'Natureza de Operação', value: this.item?.nature_operation?.to_s ? this.item.nature_operation.to_s : '', col: 6 },
      { label: 'Código de Serviço', value: this.item?.service_code?.to_s ? this.item.service_code.to_s : '', col: 3 },
      { label: 'Código de Tributação ISSQN', value: this.item?.service_code?.to_s ? this.item.service_code.to_s : '', col: 3 },
    ];
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style scoped></style>
