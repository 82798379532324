export default [
  {
    path: '/',
    name: 'dashboard',
    meta: {
      requiresAuth: true,
      rule: 'isInitialRule',
    },
    component: () => import(/* webpackChunkName: "dashboard" */'./layouts/DashboardLayout'),
  },
  {
    path: '/profile',
    name: 'dashboard-profile',
    meta: {
      requiresAuth: true,
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Dashboard', link: '/' },
        { name: 'Perfil' },
      ],
    },
    component: () => import(/* webpackChunkName: "dashboard" */'./layouts/ProfileLayout'),
  },
  {
    path: '*',
    name: 'notfound',
    meta: {
      requiresAuth: true,
      rule: 'isPublic',
    },
    component: () => import(/* webpackChunkName: "dashboard" */'./layouts/NotFoundLayout'),
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    meta: {
      requiresAuth: true,
      rule: 'isPublic',
    },
    component: () => import(/* webpackChunkName: "maintenance" */'./layouts/MaintenanceLayout'),
  },
  {
    path: '/notpermitted',
    name: 'notpermitted',
    meta: {
      requiresAuth: true,
      rule: 'isPublic',
    },
    component: () => import(/* webpackChunkName: "dashboard" */'./layouts/NotPermittedLayout'),
  },
];
