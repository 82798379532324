import { ICON_INVOICE } from '@constants/icons';
import { OPTIONS_UF } from '@constants/options';
import InvoiceItemBusiness from '@businesses/InvoiceItem';

function showFuelField(invoiceItem) {
  const product_type = invoiceItem?.product?.product_type?.to_s.replaceAll('í', 'i').toLowerCase().trim();
  return product_type === 'combustivel';
}

export default {
  service: 'Invoices',
  business: InvoiceItemBusiness,
  icon: ICON_INVOICE,
  routes: {
    list: {
      path: '/supply/invoices-list',
      name: 'supply-invoices-list',
    },
    create: {
      path: '/supply/invoices/create',
      name: 'supply-invoices-create',
    },
    edit: {
      path: '/supply/invoices/edit/:id',
      name: 'supply-invoices-edit',
    },
  },
  titles: {
    item: 'Espelho da Nota Fiscal',
    items: 'Notas Fiscais',
    edit: 'Editar Item',
    new: 'Adicionar Item',
    create: 'Adicionar Item',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'Código',
      name: 'product_code',
      formattedName: 'product_code',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            query: ['id', 'code', 'to_s'],
          },
          'name asc',
        ],
      },
      itemText: 'code',
      itemValue: 'id',
      list: true,
      md: 4,
    },
    {
      label: 'Produto',
      name: 'product_id',
      formattedName: 'product_name',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            query: ['id', 'code', 'to_s'],
          },
          'name asc',
        ],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 8,
    },
    {
      label: 'Nome',
      name: 'name',
      columnWidth: '300px',
      type: 'string',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 8,
    },
    {
      label: 'Unidade de medida',
      name: 'unity_id',
      formattedName: 'unity.to_s',
      type: 'number',
      list: true,
      required: true,
      component: 'dynamic-select',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['unity'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 4,
    },
    {
      label: 'Quantidade',
      name: 'comercial_quantity',
      formattedName: 'comercial_quantity_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      preference: ['General', 'number_precision_quantity'],
      min: 0.001,
      max: 99999999999999,
      step: '0.01',
      default: 1,
      md: 6,
    },
    {
      label: 'Valor unitário',
      name: 'unit_value',
      formattedName: 'unit_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      preference: ['General', 'number_precision_money'],
      md: 6,
    },
    {
      label: 'Valor desconto',
      name: 'discount_value',
      formattedName: 'discount_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 4,
    },
    {
      label: '% Desconto',
      name: 'discount_percentage_value',
      formattedName: 'discount_percentage_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      max: 100,
      md: 4,
    },
    {
      label: 'Valor Total',
      name: 'total_value',
      formattedName: 'total_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 4,
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      formattedName: 'nature_operation.to_s',
      list: true,
      required: true,
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            type_nature_in: ['sale', 'ecommerce', 'purchase'],
            query: ['id', 'name'],
          },
          'name asc',
        ],
      },
      itemText: 'name',
      itemValue: 'id',
      md: 12,
    },
    {
      label: 'NCM',
      name: 'ncm_id',
      formattedName: 'ncm.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['ncm'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      list: true,
      required: true,
      clearable: true,
      md: 6,
    },
    {
      label: 'CEST',
      name: 'cest_id',
      formattedName: 'cest.to_s',
      type: 'number',
      list: true,
      clearable: true,
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-cests-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['cest'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 6,
    },
    {
      label: 'Número da Ordem',
      name: 'order_number',
      type: 'text',
      component: 'dynamic-text-field',
      list: true,
      md: 6,
    },
    {
      label: 'Número do Item',
      name: 'item_number',
      type: 'text',
      component: 'dynamic-text-field',
      list: true,
      md: 6,
    },
    {
      label: 'Código de Benefício Fiscal',
      name: 'cbenef',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 6,
    },
    {
      label: 'Código de Barra',
      name: 'barcode',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 6,
    },
    {
      label: 'Código ANP',
      name: 'cod_prod_anp',
      type: 'text',
      component: 'dynamic-text-field',
      visible: (formValue) => showFuelField(formValue),
      md: 4,
    },
    {
      label: 'Descrição ANP',
      name: 'desc_prod_anp',
      type: 'text',
      visible: (formValue) => showFuelField(formValue),
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'UF de consumo',
      name: 'uf_consumo',
      type: 'text',
      component: 'dynamic-select',
      visible: (formValue) => showFuelField(formValue),
      items: OPTIONS_UF,
      md: 4,
    },
    {
      label: '% GLP OPC',
      name: 'percentage_glp_opc',
      formattedName: 'percentage_glp_opc_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      visible: (formValue) => showFuelField(formValue),
      max: 100,
      md: 4,
    },
    {
      label: '% GNN OPC',
      name: 'percentage_gnn_opc',
      formattedName: 'percentage_gnn_opc_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      visible: (formValue) => showFuelField(formValue),
      max: 100,
      md: 4,
    },
    {
      label: '% GNI OPC',
      name: 'percentage_gni_opc',
      formattedName: 'percentage_gni_opc_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      visible: (formValue) => showFuelField(formValue),
      max: 100,
      md: 4,
    },
    {
      label: 'Observações',
      name: 'observation',
      type: 'text',
      component: 'dynamic-text-area',
      md: 12,
    },
  ],
};
