import * as AppUtils from '@plugins/app-utils';

const endpoint = 'companies';

export default ($axios) => ({
  /**
   * Get all objects
   */
  async index() {
    let responseData = {};
    await $axios.get(`${endpoint}.json`).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },
  /**
   * Get one object
   * @param {Number} id Company Id
   */
  async show(id) {
    let responseData = {};
    await $axios.get(`${endpoint}/${id}.json`).then((response) => {
      responseData = this.formatResponseData(response.data, 'row');
    });
    return responseData;
  },
  /**
   * Create one object
   * @param {Object} data Form Data
   */
  create(payload) {
    const formattedData = this.formatRequestData(payload);
    return $axios.post(`${endpoint}.json`, formattedData);
  },
  /** update one object */
  update(id, payload) {
    const formattedData = this.formatRequestData(payload);
    return $axios.put(`${endpoint}/${id}.json`, formattedData);
  },
  /**
   * Destroy one object
   * @param {Number} id Company Id
   */
  async destroy(id) {
    await $axios.delete(`${endpoint}/${id}.json`);
  },
  /**
   * Link one company to current user
   */
  async linkCompanyToUser(id) {
    await $axios.get(`${endpoint}/${id}/link_to_user.json`);
  },
  /**
   * Get all invites of this company
   */
  async getInvites(id) {
    // return $axios.get(`${endpoint}/${id}/invitations.json`);

    let responseData = {};
    await $axios.get(`${endpoint}/${id}/invitations.json`).then((response) => {
      responseData = this.formatResponseData(response.data, 'invites');
    });
    return responseData;
  },
  /**
   * Accept current invitation
   */
  acceptInvitation(id, invite_id, payload) {
    return $axios.post(`${endpoint}/${id}/invitations/${invite_id}/accept.json`, payload);
  },
  /**
   * ReSend a invite to user
   */
  resendInvitation(id, invite_id) {
    return $axios.get(`${endpoint}/${id}/invitations/${invite_id}/resend.json`);
  },
  /**
   * Delete one pedent invite
   */
  deleteInvitation(id, invite_id) {
    return $axios.delete(`${endpoint}/${id}/invitations/${invite_id}.json`);
  },
  /**
   * Invite one user to current company
   */
  inviteToCompany(id, payload) {
    return $axios.post(`${endpoint}/${id}/invitation.json`, { user: payload });
  },
  /** get one object by cpf or cnpj */
  getByCnpj(value) {
    value = value.replace(/[^0-9]/gi, '');
    const payload = {
      cnpj: value,
    };
    return $axios.post(`${endpoint}/cnpj.json`, payload);
  },
  /*
  * Format Functions
  */
  formatResponseData(data, type = 'table') {
    const format = (item) => {
      item.cnpj = AppUtils.formatCnpjCpf(item.cnpj);
      item.tax_regime_formatted = item.tax_regime !== null ? this.getTaxRegime(item.tax_regime) : '';
      item.is_industry_formatted = item.is_industry === true ? 'Indústria' : '';
      item.is_trade_formatted = item.is_trade === true ? 'Comércio' : '';
      item.is_service_formatted = item.is_service === true ? 'Serviço' : '';

      if (item.contacts) {
        item.contacts.forEach((i) => {
          i.type_notification_formatted = i.type_notification !== null ? this.getTypeNotification(i.type_notification) : '';
          i.gender_formatted = i.gender !== null ? this.getGender(i.gender) : '';
          i.date_birth = i.date_birth !== null ? AppUtils.formatDate(i.date_birth) : '';
        });
      }
      item.legal_representant = item.representative;
    };
    const formatInvites = (item) => {
      item.send_at_formatted = AppUtils.formatDate(item.invitation_sent_at, true);
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    if (type === 'invites') {
      data.forEach((item) => {
        formatInvites(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  formatRequestData(data) {
    const format = (item) => {
      delete item.to_s;
      item.iests_attributes = item.iests;
      if (item.addresses) {
        item.addresses.forEach((address) => { delete address.to_s; });
      }

      const representatives_attributes = {
        name: item.legal_representant?.name,
        rg: item.legal_representant?.rg,
        cpf: item.legal_representant?.cpf,
        issuing_body: item.legal_representant?.issuing_body,
        nationality: item.legal_representant?.nationality,
        passport: item.legal_representant?.passport,
        birtdate: item.legal_representant?.birtdate,
        gender: item.legal_representant?.gender,
        email: item.legal_representant?.email,
        address_zip_code: item.legal_representant?.address.zip_code,
        address_street: item.legal_representant?.address.street,
        address_number: item.legal_representant?.address.number,
        address_complement: item.legal_representant?.address.complement,
        address_neighborhood: item.legal_representant?.address.neighborhood,
        address_city: item.legal_representant?.address.city,
        address_uf: item.legal_representant?.address.uf,
        address_country: item.legal_representant?.address.country,
        phone_code_country: item.legal_representant?.phone.code_country,
        phone_code_area: item.legal_representant?.phone.code_area,
        phone_number: item.legal_representant?.phone.number,
      };
      item.representatives_attributes = representatives_attributes;
    };
    format(data);

    return data;
  },
  getTaxRegime(value) {
    if (!value) return '';

    return AppUtils.getOptionText('tax_regime_trib', value);
  },
  getGender(value) {
    if (!value) return '';
    return AppUtils.getOptionText('gender', value);
  },
  getTypeNotification(values) {
    if (!values) return '';

    let result = '';
    values.forEach((element) => {
      result += `${AppUtils.getOptionText('direct_mails', element)}, `;
    });
    return result.replace(/,\s*$/, '');
  },
});
