import { omit } from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import * as AppUtils from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';
import { ulid } from 'ulid';

const threatEmpty = (item, field) => (!item[field] || item[field].length === 0 ? {} : item[field]);

const setEmitterfields = (manifest) => {
  manifest.emitter = threatEmpty(manifest, 'emitter');

  Vue.prototype.$api.Me.index().then((response) => {
    const {
      company: {
        to_s, addresses, cnpj, ie,
      },
    } = response.data;

    const mainAddress = addresses.map((item) => (item.main ? item : ''))[0];

    manifest.emitter.name = to_s ?? '';
    manifest.emitter.cnpj_cpf = cnpj ?? '';
    manifest.emitter.ie = ie ?? '';

    // address-fields
    manifest.manifest_uf = mainAddress.uf;
    manifest.emitter.street = mainAddress.street;
    manifest.emitter.neighborhood = mainAddress.neighborhood;
    manifest.emitter.zip_code = mainAddress.zip_code;
    manifest.emitter.city = mainAddress.city;

    Vue.set(manifest.emitter, 'cnpj_cpf', cnpj);
    Vue.set(manifest.emitter, 'uf', manifest.manifest_uf);
    Vue.set(manifest.emitter, 'ie', ie);
  });
};

const setInvoicesFields = (manifest) => {
  manifest.invoice = threatEmpty(manifest, 'invoice');

  if (manifest.invoices) {
    manifest.invoices.map((item) => {
      item.invoice_emission_date = item.emission_date ? AppUtils.formatDate(item.emission_date) : '';
      item.invoice_emission_date_formatted = item.emission_date ? AppUtils.formatDate(item.emission_date) : '';
      item.client = item.buyer ? item?.buyer?.name : '';
      item.invoice_uf = item.buyer ? item?.buyer?.uf : '';
      item.invoice_total_value = item.total_note ? item.total_note : '';
      item.invoice_total_value_formatted = item.total_note ? AppUtils.formatCurrency(item.total_note) : '';
    });
  }
  manifest.invoice = manifest.invoices ? manifest.invoices : [];
};

const setConductorFields = (manifest) => {
  manifest.conductor = threatEmpty(manifest, 'conductor');

  if (manifest.conductors) {
    manifest.conductors.map((item) => {
      item.cpf = item.cpf ?? '';
      item.name = item.name ?? '';
    });
  }
  manifest.conductor = manifest.conductors;
};

const setVehicleFields = (manifest) => {
  manifest.vehicle = threatEmpty(manifest, 'vehicles');
  manifest.vehicle = manifest.vehicles;

  manifest?.vehicle?.forEach((item) => {
    item.body = AppUtils.getOptionText('vehicle_body_type', item.body);
    item.traction_type = AppUtils.getOptionText('vehicle_traction_type', item.traction_type);
  });
};

const setCourseStates = (manifest) => {
  manifest.logistic = threatEmpty(manifest, 'logistic');
  manifest.logistic.course_states = threatEmpty(manifest, 'course_states');
  manifest.logistic.course_states = [];

  if (manifest.course_states) {
    manifest.logistic.course_states = manifest.course_states.map((uf) => ({ uf }));
  }
};

const formatDates = (manifest) => {
  manifest.manifest = threatEmpty(manifest, 'manifest');

  manifest.emission_date = manifest.created_at;

  manifest.emission_date_hour = manifest.emission_date ? moment(manifest.emission_date).format('HH:mm') : '';
  manifest.emission_date_formatted = manifest.emission_date ? AppUtils.formatDate(manifest.emission_date) : '';

  manifest.exit_date_hour = manifest.exit_date ? moment(manifest.exit_date).format('HH:mm') : '';
  manifest.exit_date_formatted = manifest.exit_date ? AppUtils.formatDate(manifest.exit_date) : '';
};

const formatResponse = (manifest) => {
  setEmitterfields(manifest);
  setInvoicesFields(manifest);
  setConductorFields(manifest);
  setVehicleFields(manifest);
  setCourseStates(manifest);

  // dates
  formatDates(manifest);

  // course_states
  manifest.logistic.course_states.forEach((state) => {
    state._ulid = ulid();
  });

  manifest.status_formatted = AppUtils.getOptionText('manifest_status', manifest.status);

  manifest.nfe_quantity = manifest.invoice ? manifest.invoice.length : 0;

  manifest.logistic = threatEmpty(manifest, 'logistic');

  manifest.load = threatEmpty(manifest, 'load');
  manifest.load.total_weight = manifest.total_weight ?? '';
  manifest.load.total_value = manifest.total_value ?? '';
  manifest.load.unity = manifest.unity ?? '';

  const formatProtocol = (protocol) => {
    const protocolDate = protocol.protocol_date ? AppUtils.formatDate(protocol?.protocol_date, true) : '';
    return protocol.protocol ? `${protocol.protocol} - ${protocolDate}` : '';
  };

  if (manifest.response_protocols) {
    manifest.response_protocols.forEach((protocol, index) => {
      const actual = manifest?.response_protocols[index];
      const next = manifest?.response_protocols[index + 1];

      if (!next && protocol.situation === manifest.status) {
        manifest.protocol_formatted = formatProtocol(actual);
        return;
      }
      if (actual.protocol_date > next.protocol_date && protocol.situation === manifest.status) {
        manifest.protocol_formatted = formatProtocol(actual);
        return;
      }
      if (next.protocol_date > actual.protocol_date && protocol.situation === manifest.status) {
        manifest.protocol_formatted = formatProtocol(next);
        return;
      }
      manifest.protocol_formatted = formatProtocol(actual);
    });
  }

  if (manifest?.response_errors && manifest.response_errors?.length > 0) {
    manifest.activeTab = 1;
  }
};

const cleanCourseStatesUF = (manifest) => {
  const { logistic: { course_states } } = manifest;

  return course_states.map((state) => { if (!state._destroy) return state.uf; });
};

const formatRequest = (manifest) => {
  const invalidManifestFields = [
    'to_s',
    'create_at',
    'updated_at',
    'response_protocols.to_s',
    'service.to_s',
    'tax.to_s',
    'emitter.to_s',
    'justification.to_s',
    'bill.to_s',
    'export.to_s',
    'items.to_s',
    'emission_date_hour',
    'emission_date_formatted',
    'exit_date_formatted',
    'nature_operation_id',
  ];

  const invalidManifestVehicleFields = [
    'nature_operation_id',
    '_ulid',
  ];

  const invalidManifestConductorFields = [
    'nature_operation_id',
    '_ulid',
    'invoice_number',
    'series',
    'invoice_key',
    'invoice_emission_date',
    'invoice_emission_date_formatted',
    'invoice_uf',
    'client',
    'invoice_total_value',
    'invoice_total_value_formatted',
  ];
  const newManifest = omit(manifest, invalidManifestFields);

  if (newManifest.logistic) {
    newManifest.course_states = cleanCourseStatesUF(manifest);
  }

  newManifest?.vehicle?.forEach((item) => {
    item = omit(item, invalidManifestVehicleFields);
    delete item.nature_operation_id;
  });

  newManifest?.conductor?.forEach((item) => {
    item = omit(item, invalidManifestConductorFields);
    delete item.nature_operation_id;
  });

  newManifest.vehicle = newManifest.vehicle || [];

  newManifest.vehicle.forEach((item) => {
    item.body = AppUtils.getOptionValue('vehicle_body_type', item.body);
    item.traction_type = AppUtils.getOptionValue('vehicle_traction_type', item.traction_type);
  });

  if (newManifest?.exit_date && newManifest?.exit_date_hour) {
    const date = newManifest.exit_date ? AppUtils.formatDate(newManifest.exit_date) : '';
    newManifest.exit_date = `${date} ${newManifest.exit_date_hour}`;
  }

  newManifest.invoice = newManifest?.invoice?.filter((item) => !item._destroy) || [];
  newManifest.total_value = newManifest.load ? newManifest.load.total_value : '';
  newManifest.total_weight = newManifest.load ? newManifest.load.total_weight : 0;
  newManifest.unity = newManifest.load ? newManifest.load.unity : 0;

  newManifest.conductors_attributes = newManifest.conductor || [];
  newManifest.vehicles_attributes = newManifest.vehicle || [];

  delete newManifest.conductor;
  delete newManifest.conductors;
  delete newManifest.vehicle;
  delete newManifest.vehicles;
  delete newManifest.manifest;
  delete newManifest.invoices;

  AppUtils.modifyRef(manifest, newManifest);
};

const Manifests = DynamicService('manifests', {
  formatResponse,
  formatRequest,
});

const ManifestsCommands = ($axios) => ({
  async action(payload) {
    const res = await $axios.post('manifests/action.json', payload);
    return res;
  },

  async status() {
    const res = await $axios.post('manifests/status.json');
    return res;
  },

  async transmit(payload) {
    const res = await $axios.post('manifests/transmit.json', payload);
    return res;
  },

  async unbind(id) {
    const res = await $axios.post(`manifests/${id}/unbind_order.json`);
    return res;
  },

  async download(id) {
    const payload = { id };
    const res = await $axios.post('manifests/download.json', payload);
    return res;
  },

  async downloadBase64(id) {
    const payload = { id };
    const res = await $axios.post('manifests/download_base64.json', payload);
    return res?.data || '';
  },

  async downloadXMLPDFBase64(id) {
    const payload = { id };
    const res = await $axios.post('manifests/generate_xml_pdf_base64.json', payload);
    return res?.data;
  },
});

export default { Manifests, ManifestsCommands };
export { Manifests, ManifestsCommands };
