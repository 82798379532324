import Vue from 'vue';
import { convertHourStringToMinutes, convertMinutesToHourString } from '@plugins/app-utils';

// function changeTimeHour(fieldValue, formValue, errorMessage = '') {
//   if (!validateHour(fieldValue)) {
//     Vue.prototype.$notifyError(errorMessage);
//     return;
//   }
//   formValue.time_total = updateTotalTime(formValue);
// }

export function validateHour(str) {
  return /^[0-9]{2,}:[0-5][0-9]/g.test(str);
}

function updateTotalTime({ used_time_process, used_time_machine, used_time_setup }) {
  const timeProcessInMinutes = convertHourStringToMinutes(used_time_process);
  const timeMachineInMinutes = convertHourStringToMinutes(used_time_machine);
  const timeSetupInMinutes = convertHourStringToMinutes(used_time_setup);
  const totalTime = timeProcessInMinutes + timeMachineInMinutes + timeSetupInMinutes;
  const hours = convertMinutesToHourString(totalTime);
  return hours;
}

function changeUsedTimeHour(fieldValue, formValue, errorMessage = '') {
  if (!validateHour(fieldValue)) {
    Vue.prototype.$notifyError(errorMessage);
    return;
  }
  formValue.used_time_total = updateTotalTime(formValue);
  Vue.set(formValue, 'used_time_total', updateTotalTime(formValue));
}

export default {
  fieldEvents: {
    product_machine_id: {
      change: (formValue, fieldValue, object) => {
        formValue.product_machine = object;
      },
    },
    used_time_process: {
      change: (formValue, fieldValue) => {
        formValue.used_time_process = fieldValue;
        changeUsedTimeHour(fieldValue, formValue, 'Tempo utilizado inválido');
        Vue.set(formValue, 'used_time_process', formValue.used_time_process);
      },
    },
    used_time_machine: {
      change(formValue, fieldValue) {
        formValue.used_time_machine = fieldValue;
        changeUsedTimeHour(fieldValue, formValue, 'Tempo utilizado inválido');
        Vue.set(formValue, 'used_time_machine', formValue.used_time_machine);
      },
    },
    used_time_setup: {
      change(formValue, fieldValue) {
        formValue.used_time_setup = fieldValue;
        changeUsedTimeHour(fieldValue, formValue, 'Tempo utilizado inválido');
        Vue.set(formValue, 'used_time_setup', formValue.used_time_setup);
      },
    },
  },
};
