import Vue from 'vue';
import _ from 'lodash';
import { applyGlobalDiscount } from './Common/ApplyGlobalDiscount';
import { calculateIndustrializationRealTime } from './Common/CalculateRealTime';
import { calculateTotals } from './Common/CalculateTotals';
import { createHintForRegisterField } from './Common/createHintForRegisterField';
import { setTaxesValues } from './Common/SetTaxesValues ';

import { DefaultScreenEvents } from './OrderSalePurchase/DefaultScreenEvents';
import { DefaultFieldEvents } from './OrderSalePurchase/DefaultFieldEvents';

export default {
  screenEvents: {
    ...DefaultScreenEvents,
    async beforeUpdate(formValue) {
      if (!formValue.buyer_id) return;
      const vendorHint = await createHintForRegisterField(formValue.buyer_id);

      return { fields: [{ label: 'Fornecedor', hint: vendorHint }] };
    },
  },
  fieldEvents: {
    buyer_id: {
      async change(formValue) {
        const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
        formValue.buyer = buyer;
        formValue.buyer_id = buyer.id;
        Vue.set(formValue, 'buyer', buyer);
        Vue.set(formValue, 'buyer_id', buyer.id);
        if (buyer.addresses.length === 0) {
          Vue.prototype.$notifyError('Fornecedor não possui endereço', {
            action: {
              text: 'Clique aqui para cadastrar',
              push: {
                name: 'entries-registers-edit',
                params: {
                  id: buyer.id,
                },
              },
              class: 'custom-message',
            },
            duration: 6000,
          });
        } else {
          const vendor = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
          formValue.vendor = vendor;
          Vue.set(formValue, 'vendor', vendor);

          const apportionment = true;
          const hint = await createHintForRegisterField(buyer.id, buyer);
          const salesmanId = _.get(buyer, 'preference.salesman_id');
          if (salesmanId) {
            formValue.salesman_id = salesmanId;
          }
          const shipping_company_id = _.get(buyer, 'preference.shipping_company_id');
          if (shipping_company_id) formValue.shipping_company_id = shipping_company_id;

          if (formValue.buyer_id && formValue.nature_operation_id) {
            formValue.vendor = {
              ...formValue.vendor,
              taxpayer_type: vendor.taxpayer_type,
            };
            const data = await calculateIndustrializationRealTime(formValue.items, vendor, formValue.is_costumer);
            setTaxesValues(formValue, data, apportionment);
          }
          return { fieldAttributes: { hint } };
        }
      },
    },
    is_costumer: {
      change: async (formValue) => {
        if (formValue.buyer_id) {
          const vendor = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
          const apportionment = true;
          calculateTotals(formValue);
          formValue.vendor = {
            ...formValue.vendor,
            taxpayer_type: vendor.taxpayer_type,
          };
          const data = await calculateIndustrializationRealTime(formValue.items, vendor, formValue.is_costumer);
          setTaxesValues(formValue, data, apportionment);
        }
      },
    },
    nature_operation_id: {
      change: async (formValue) => {
        if (formValue.buyer_id) {
          const vendor = await Vue.prototype.$api.Registers.show(formValue.buyer_id);

          formValue.items = formValue.items || [];
          formValue.items.forEach((item) => {
            item.nature_operation_id = formValue.nature_operation_id;
          });
          calculateTotals(formValue);
          if (formValue.buyer_id && formValue.items.length > 0) {
            const data = await calculateIndustrializationRealTime(formValue.items, vendor, formValue.is_cosutmer);
            setTaxesValues(formValue, data);
            if (data.totalizers?.ipi) {
              formValue.total_ordered = formValue.total_products + data.totalizers.ipi;
            }
          }
        }
      },
    },
    items: {
      beforeCreate: async (formValue, newItem) => {
        if (!formValue.buyer_id) return 'Não é possível adicionar ítens sem selecionar um fornecedor';
        if (!formValue.nature_operation_id) return 'Não é possível adicionar ítens sem selecionar a natureza de operação';
        if (!formValue.stock_id) return 'Não é possível adicionar ítens sem selecionar o estoque';
        if (formValue?.buyer?.addresses?.length === 0) return 'Para adicionar ítens na industrialização precisa ter endereço UF no fornecedor selecionado';

        newItem.stock_id = formValue.stock_id;
        newItem.nature_operation_id = formValue.nature_operation_id;
        newItem.order_number = formValue.order;
        newItem.buyer_id = formValue.buyer_id;
      },
      change: async (formValue) => {
        const apportionment = true;
        calculateTotals(formValue);
        if (formValue.buyer_id) {
          const vendor = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
          const data = await calculateIndustrializationRealTime(formValue.items, vendor, formValue.is_cosutmer);
          setTaxesValues(formValue, data, apportionment);
        }
      },
    },
    use_freight: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          const apportionment = true;
          if (formValue.use_freight === false) {
            calculateTotals(formValue, true);
          } else {
            calculateTotals(formValue);
          }
          if (formValue.buyer_id) {
            const vendor = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
            const data = await calculateIndustrializationRealTime(formValue.items, vendor, formValue.is_cosutmer);
            setTaxesValues(formValue, data, apportionment);
          }
        }
      },
    },
    expense_value: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          const apportionment = true;
          calculateTotals(formValue);
          if (formValue.buyer_id) {
            const vendor = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
            const data = await calculateIndustrializationRealTime(formValue.items, vendor, formValue.is_cosutmer);
            setTaxesValues(formValue, data, apportionment);
          }
        }
      },
    },
    freight_value: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          const apportionment = true;
          if (formValue.use_freight === false) {
            calculateTotals(formValue, true);
          } else {
            calculateTotals(formValue);
          }
          if (formValue.buyer_id) {
            const data = await calculateIndustrializationRealTime(formValue, 'OrderPurchasesTaxes', formValue.buyer_id);
            setTaxesValues(formValue, data, apportionment);
          }
        }
      },
    },
    ...DefaultFieldEvents,
  },
  applyGlobalDiscount,
};
