<template>
  <div :id="item.id">
    <GenericCardPanel
      title="INFORMAÇÕES DO USUÁRIO"
      :fields="UserInfo">
      <template v-slot:contentLeft>
        <v-col md="3">
          <v-avatar
            class="profile"
            color="grey"
            size="164">
            <v-img :src="item.avatar_url" />
          </v-avatar>
        </v-col>
      </template>
    </GenericCardPanel>
  </div>
</template>

<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabDetail',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      UserInfo: [],
    };
  },
  mounted() {
    this.UserInfo = [
      { label: 'Nome', value: this.item.name, col: 3 },
      { label: 'Email', value: this.item.email, col: 3 },
      { label: 'Inativo', value: this.item.is_inactive ? 'Sim' : 'Não', col: 3 },
      { label: 'Apelido', value: this.item.nickname, col: 3 },
    ];
  },
};
</script>

<style lang="scss" scoped>

</style>
