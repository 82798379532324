<template>
  <v-alert
    v-bind="$attrs"
    :icon="icon"
    :border="border"
    :color="color"
    :elevation="elevation"
    :style="{minWidth, maxHeight}"
    :rounded="rounded"
    v-on="$listeners">
    <span :class="titleColor" v-html="title" /> <br>
    <b :class="totalColor">{{ total }}</b> <br>
    <slot name="custom-button" />
  </v-alert>
</template>

<script>

export default {
  name: 'GenericCardForm',
  props: {
    icon: {
      type: String,
      default: () => (''),
    },
    border: {
      type: String,
      default: () => (''),
    },
    color: {
      type: String,
      default: () => ('indigo lighten-1'),
    },
    elevation: {
      type: String,
      default: () => ('1'),
    },
    title: {
      type: String,
      default: () => (''),
    },
    btnLabel: {
      type: String,
      default: () => (''),
    },
    titleColor: {
      type: String,
      default: () => ('indigo--text text--lighten-1'),
    },
    total: {
      type: String,
      default: () => (''),
    },
    totalColor: {
      type: String,
      default: () => ('indigo--text text--darken-4'),
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: String,
      default: 'fit-content',
    },
    maxHeight: {
      type: String,
      default: 'fit-content',
    },
  },
  methods: {
    emitClick() {
      this.$emit('handleCustomButtonClick', true);
    },
  },
};
</script>
<style type="scss" scoped>
.genericcardform-title {
    font-family: 'Montserrat';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0.01em;
    text-align: left;
}
</style>
