import { ICON_HISTORIC } from '@constants/icons';

export default {
  icon: ICON_HISTORIC,
  routes: {
    launches: { name: 'financial-banks-launchs', path: '/financial/banks/launchs' },
  },
  titles: {
    bankHistory: 'Histórico dos Bancos',
    item: 'Lançamento',
    items: 'Lançamentos',
    list: 'Listar Lançamentos',
    create: 'Novo Lançamento',
  },
  fields: [],
};
