import NationalSimpleSchema from '@schemas/NationalSimple';

export default [
  {
    path: NationalSimpleSchema.routes.edit.path,
    name: NationalSimpleSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'simple_nationals',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Impostos' },
        { name: 'Simples Nacional' },
      ],
    },
    component: () => import(/* webpackChunkName: 'tables-nationalsimple-list' */'./DetailNationalSimple'),
  },
];
