import {
  formatCamelCaseKeysToSnakeCaseKeys, generateGraphqlCollection, generateGraphqlFilter, snakeToCamel,
} from '@plugins/app-utils';

/**
 *
 * options can be:
 * {
 *   formatResponse: <function> // format all itens returned by index() getByFilter() getTreeViewFirstLevel(), show(), clone()
 *   formatRequest: <function> // executed before update() and create() methods
 *   formatClone: <function> // executed at clone befores formatResponse methods
 * }
 *
 */

const replaceEndpointKeys = (endpoint, endpointReplaceKeys) => {
  let replacedEndpoint = endpoint;
  if (endpointReplaceKeys) {
    const keys = Object.keys(endpointReplaceKeys);
    keys.forEach((key) => {
      replacedEndpoint = replacedEndpoint.replace(`:${key}`, endpointReplaceKeys[key]);
    });
  }
  return replacedEndpoint;
};

export default (endpoint, options = {}) => ($axios) => ({
  /**
   * expose endpoint
   */

  get axios() {
    return $axios;
  },
  /**
   * expose endpoint
   */

  get endpoint() {
    return endpoint;
  },
  /**
   * expose options
   */

  get options() {
    return options;
  },

  async checkTypePlan(plan) {
    if (plan === 'none') {
      localStorage.setItem('IC/Storage/typePlan', 'none');
    } else {
      localStorage.setItem('IC/Storage/typePlan', '');
    }
  },

  /**
  * Get all objects
  */
  async index(endpointReplaceKeys) {
    let data = [];
    const replacedEndpoint = replaceEndpointKeys(endpoint, endpointReplaceKeys);
    const sufix = options.noUrlSufixJson ? '' : '.json';
    await $axios.get(`${replacedEndpoint}${sufix}`, options.baseURL ? { baseURL: options.baseURL } : undefined).then(async (response) => {
      data = options.getDataOnItems ? response.data?.items : response.data;
      await this.checkTypePlan(data.company?.subscription?.plan?.name);
      if (options.formatResponse && typeof options.formatResponse === 'function') {
        data.forEach((item) => {
          options.formatResponse(item);
        });
      }
    });

    return { data };
  },
  /**
    * Get all objects
    */
  async indexPaginable(params) {
    const {
      endpointReplaceKeys, optionsPaginable, filterParams, bodyParams,
    } = params;
    let data = [];
    let headers = {};
    let paginator = {};

    let replacedEndpoint = replaceEndpointKeys(endpoint, endpointReplaceKeys);

    if (optionsPaginable?.sortBy?.length === 0) optionsPaginable.sortBy = ['updated_at'];
    if (optionsPaginable?.sortDesc?.length === 0) optionsPaginable.sortDesc = [true];

    const payloadPaginable = `?page=${optionsPaginable.page}&per_page=${optionsPaginable.per_page}`;
    let verbHTTP = 'get';

    if (filterParams || bodyParams) {
      verbHTTP = 'post';
    }
    if (filterParams) {
      replacedEndpoint = `${replacedEndpoint}/filters`;
    }

    const sufix = options.noUrlSufixJson ? '' : '.json';
    const optionsResolved = (options.baseURL ? { baseURL: options.baseURL } : undefined) || filterParams || bodyParams || undefined;

    await $axios[verbHTTP](`${replacedEndpoint}${sufix}${payloadPaginable}`, optionsResolved).then((response) => {
      headers = response.headers;

      paginator = {
        page: response.data?.page ?? headers['icertusweb-current-page'],
        per_page: response.data?.per_page ?? headers['icertusweb-items-per-page'],
        total_items: response.data?.total_items ?? headers['icertusweb-total-items'],
        total_pages: response.data?.total_pages ?? headers['icertusweb-total-pages'],
      };

      data = options.getDataOnItems ? response.data?.items : response.data;
      if (options.formatResponse && typeof options.formatResponse === 'function') {
        data.forEach((item) => {
          options.formatResponse(item);
        });
      }
    });
    return { data, headers, paginator };
  },

  /**
   * Get all objects based on graphql queries
   *
   * @param {Array} fields It consists of the representation of fields in array form - ex: ["id","name"]
   * @returns {Array} An array of objects that match the query
   */
  async indexGraphql(fields, paginator = false) {
    let data = [];

    if (!fields) {
      console.warn('fields is empty on indexGraphql call');
      return { data };
    }

    const operationName = snakeToCamel(endpoint);
    const collection = generateGraphqlCollection(fields);

    let queryResolved = `${operationName}Index`;
    let metadata = '';

    if (paginator) {
      queryResolved += `(page:${paginator.page}, limit: ${paginator.per_page})`;
      metadata += 'metadata { totalPages totalCount currentPage limitValue }}';
    }
    const graphqlQuery = {
      query: `{ ${queryResolved} { collection ${collection} ${metadata}}`,
    };

    await $axios.post('graphql', graphqlQuery).then((response) => {
      data = formatCamelCaseKeysToSnakeCaseKeys(response.data.data ?? []);
      if (options.formatResponse && typeof options.formatResponse === 'function') {
        data.forEach((item) => {
          options.formatResponse(item);
        });
      }
      paginator = {
        total_items: response.data?.metadata?.total_count,
        total_pages: response.data?.metadata?.total_pages,
        ...paginator,
      };
    });
    return { data, paginator };
  },
  /**
   * Get all objects by filters
   * */
  async getByFilter(filters, order = 'created_at desc', outParams = {}) {
    const { query, ...filterResolved } = filters;
    let data = [];
    const payload = {
      q: filterResolved || {},
    };
    // add sort option
    if (order) {
      payload.q.s = order;
    }
    // if (outParams) {
    payload.out = outParams;

    const sufix = options.noUrlSufixJson ? '' : '.json';
    await $axios.post(`${endpoint}/filters${sufix}`, payload, options.baseURL ? { baseURL: options.baseURL } : undefined)
      .then((response) => {
        data = response.data;
        if (options.formatResponse && typeof options.formatResponse === 'function') {
          data.forEach((item) => {
            options.formatResponse(item);
          });
        }
      });
    return { data };
  },

  /**
   * Get all objects based on graphql filter
   *
   * @param {Array} params It consists of the representation of filter with a query array form -
   * ex:
   * [{
          use_inactive_eq: false,
          type_nature_in: ['purchase'],
          query: ['id', 'name', 'natureOperationStates', ['id', 'state', 'cofins', ['id', 'cst']]],
        }]
   * @returns {Array} An array of objects that match the filter
   */
  async getByFilterGraphql(params) {
    let data = [];

    if (!params) {
      console.warn('params is empty on getByFilterGraphql call');
      return { data };
    }

    const { query, ...filter } = params;

    const operationName = snakeToCamel(endpoint);
    const collection = generateGraphqlCollection(query);
    const filterFormatted = generateGraphqlFilter(filter);

    const graphqlQuery = {
      query: `{ ${operationName}Filter (filter: ${filterFormatted}) { collection ${collection}}}`,
    };

    await $axios.post('graphql', graphqlQuery).then((response) => {
      // @TODO - Change to response.data when backend api response is refactored
      data = formatCamelCaseKeysToSnakeCaseKeys(response.data.data ?? []);
      if (options.formatResponse && typeof options.formatResponse === 'function') {
        data.forEach((item) => {
          options.formatResponse(item);
        });
      }
    });
    return { data };
  },

  async getTreeViewFirstLevel(endpointReplaceKeys) {
    const replacedEndpoint = replaceEndpointKeys(endpoint, endpointReplaceKeys);
    const sufix = options.noUrlSufixJson ? '' : '.json';
    const localUrl = `${replacedEndpoint}/first_level${sufix}`;
    let data = [];
    await $axios.get(localUrl, options.baseURL ? { baseURL: options.baseURL } : undefined).then((response) => {
      data = response.data;
      if (options.formatResponse && typeof options.formatResponse === 'function') {
        data.forEach((item) => {
          options.formatResponse(item);
        });
      }
    });
    return { data };
  },
  /**
   * Get one object
   * @param {Number} id itemId
   */
  async show(id, endpointReplaceKeys) {
    let data = {};
    if (!id && !options.ignoreItemId) return data;
    const replacedEndpoint = replaceEndpointKeys(endpoint, endpointReplaceKeys);
    const sufix = options.noUrlSufixJson ? '' : '.json';
    const address = options.ignoreItemId ? `${replacedEndpoint}${sufix}` : `${replacedEndpoint}/${id}${sufix}`;
    await $axios.get(address, options.baseURL ? { baseURL: options.baseURL } : undefined).then((response) => {
      data = response.data;
      if (options.formatResponse && typeof options.formatResponse === 'function') {
        options.formatResponse(data);
      }
    });
    if (options.useDirectData) {
      return data;
    }
    return { data };
  },

  /**
   * Get a object based on graphql query with id and params
   *
   * @param {number} id The id of object in database
   * @param {Array} params It consists of the representation of params in array form - ex: ["id","name"]
   * @returns {Array} An array of objects that match the filter
   */
  async showGraphql(id, params) {
    let data = {};

    if (!id || !params) {
      console.warn('id or params is empty on showGraphql call');
      return { data };
    }

    const operationName = snakeToCamel(endpoint);
    const collection = generateGraphqlCollection(params);

    const graphqlQuery = {
      query: `{ ${operationName} (id: ${id}) ${collection}}`,
    };

    await $axios.post('graphql', graphqlQuery).then((response) => {
      data = response.data.data;
      if (options.formatResponse && typeof options.formatResponse === 'function') {
        options.formatResponse(data);
      }
    });
    if (options.useDirectData) {
      return data;
    }
    return { data };
  },

  /**
     * Create one object
     * @param {Object} data Form Data
     */
  async create(payload, endpointReplaceKeys) {
    if (options.readonly) {
      console.error(`${endpoint} is readonly`);
      return;
    }
    if (options.readonly) {
      console.error(`${endpoint} is readonly`);
      return;
    }

    let payloadFormatted = {};
    if (options?.sendCustomPayload) {
      payloadFormatted = {
        ...payload,
      };
    } else if (!payload.data) {
      console.error('WARNING: payload.data is empty');
    } else if (payload.data) {
      const { data } = payload;
      payloadFormatted = data;
    }

    if (options.formatRequest && typeof options.formatRequest === 'function') {
      options.formatRequest(payloadFormatted);
    }
    const method = options.usePutOnCreate ? 'put' : 'post';
    const sufix = options.noUrlSufixJson ? '' : '.json';
    const replacedEndpoint = replaceEndpointKeys(endpoint, endpointReplaceKeys);
    const result = await $axios[method](`${replacedEndpoint}${sufix}`, payloadFormatted, options.baseURL ? { baseURL: options.baseURL } : undefined);
    return result;
  },
  /**
     * Update one object
     * @param {Number} id itemId
     * @param {Object} data Form Data
     */
  async update(payload, endpointReplaceKeys) {
    if (options.readonly) {
      console.error(`${endpoint} is readonly`);
      return;
    }

    let payloadFormatted = {};
    let customId = '';
    if (options.sendCustomPayload) {
      payloadFormatted = {
        ...payload,
      };
      customId = payload.id;
    } else if (!payload.data) {
      console.error('WARNING: payload.data is empty');
    } else if (payload.data) {
      const { id, data } = payload;
      payloadFormatted = data;
      customId = id;
    }

    if (options.formatRequest && typeof options.formatRequest === 'function') {
      options.formatRequest(payloadFormatted);
    }
    const replacedEndpoint = replaceEndpointKeys(endpoint, endpointReplaceKeys);
    const sufix = options.noUrlSufixJson ? '' : '.json';
    const address = options.ignoreItemId ? `${replacedEndpoint}${sufix}` : `${replacedEndpoint}/${customId}${sufix}`;
    const result = await $axios.put(address, payloadFormatted, options.baseURL ? { baseURL: options.baseURL } : undefined);
    return result;
  },
  /**
     * Destroy one object
     * @param {Number} id itemId
     */
  async delete(id, endpointReplaceKeys) {
    if (options.readonly) {
      console.error(`${endpoint} is readonly`);
      return;
    }
    if (options.readonly) {
      console.error(`${endpoint} is readonly`);
      return;
    }
    const replacedEndpoint = replaceEndpointKeys(endpoint, endpointReplaceKeys);
    const sufix = options.noUrlSufixJson ? '' : '.json';
    const address = options.ignoreItemId ? `${replacedEndpoint}${sufix}` : `${replacedEndpoint}/${id}${sufix}`;
    await $axios.delete(address, options.baseURL ? { baseURL: options.baseURL } : undefined);
  },
  /**
     * Change status to canceled
     * @param {Number} id itemId
     */
  async cancel(id, endpointReplaceKeys) {
    let data = {};
    const replacedEndpoint = replaceEndpointKeys(endpoint, endpointReplaceKeys);
    const sufix = options.noUrlSufixJson ? '' : '.json';
    const address = options.ignoreItemId ? `${replacedEndpoint}/cancel${sufix}` : `${replacedEndpoint}/${id}/cancel${sufix}`;
    await $axios.get(address, options.baseURL ? { baseURL: options.baseURL } : undefined).then((response) => {
      data = response;
    });
    return { data };
  },
  /**
     * Change status to canceled with params
     * @param {Number} id itemId
     */
  async cancelWithPayload(id, endpointReplaceKeys, payload) {
    let data = {};
    const replacedEndpoint = replaceEndpointKeys(endpoint, endpointReplaceKeys);
    const sufix = options.noUrlSufixJson ? '' : '.json';
    const address = options.ignoreItemId ? `${replacedEndpoint}/cancel${sufix}` : `${replacedEndpoint}/${id}/cancel${sufix}`;
    await $axios.post(`${options.baseURL}/${address}`, options.baseURL ? { baseURL: options.baseURL, ...payload } : payload).then((response) => {
      data = response;
    });
    return { data };
  },
  /**
     * Clone item
     * @param {Number} id itemId
     */
  async clone(id, endpointReplaceKeys) {
    let data = {};
    const replacedEndpoint = replaceEndpointKeys(endpoint, endpointReplaceKeys);
    const sufix = options.noUrlSufixJson ? '' : '.json';
    const address = options.ignoreItemId ? `${replacedEndpoint}/clone${sufix}` : `${replacedEndpoint}/${id}/clone${sufix}`;

    if (options?.cloneType === 'post') {
      const response = await $axios.post(`${options.baseURL}/${address}`);
      data = response.data;
    } else {
      const response = await $axios.get(address, options.baseURL ? { baseURL: options.baseURL } : undefined);
      data = response.data;
    }
    if (options.formatClone && typeof options.formatClone === 'function') {
      options.formatClone(data);
    }
    if (options.formatResponse && typeof options.formatResponse === 'function') {
      options.formatResponse(data);
    }

    return { data };
  },

});
