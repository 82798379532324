export default [
  {
    label: 'Status',
    name: 'status',
    type: 'select',
    options_key: 'manifest_status',
    list: true,
  },
  {
    label: 'Modelo',
    name: 'model',
    type: 'text',
    list: false,
  },
  {
    label: 'Série',
    name: 'series',
    type: 'text',
    list: false,
  },
  {
    label: 'MDF-e',
    name: 'manifest_number',
    type: 'text',
    list: true,
  },
  {
    label: 'Emissão',
    name: 'emission_date_formatted',
    type: 'text',
    list: true,
  },
  {
    label: 'Qtd NF-e',
    name: 'nfe_quantity',
    type: 'text',
    list: true,
  },
  {
    label: 'Chave de Acesso',
    name: 'manifest_key',
    type: 'text',
    list: true,
  },
];
