import StockSchema from '@schemas/Stock';

const ListStocks = () => import(/* webpackChunkName: "list-stocks" */'./ListStocks');
const DetailsStocks = () => import(/* webpackChunkName: "details-stocks" */'./DetailsStocks');

export default [
  {
    path: '/supply/stocks',
    name: 'supply-stocks-list',
    meta: {
      requiresAuth: true,
      rule: 'stocks',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: StockSchema.titles.items },
      ],
    },
    component: ListStocks,
  },
  {
    path: '/supply/stocks/create',
    name: 'supply-stocks-create',
    meta: {
      requiresAuth: true,
      rule: 'stocks',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: StockSchema.titles.items, route: 'supply-stocks-list' },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailsStocks,
  },
  {
    path: '/supply/stocks/edit/:id',
    name: 'supply-stocks-edit',
    meta: {
      requiresAuth: true,
      rule: 'stocks',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: StockSchema.titles.items, route: 'supply-stocks-list' },
        { name: 'Editar' },
      ],
    },
    component: DetailsStocks,
  },
];
