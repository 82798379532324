<template>
  <div :id="item.id">
    <GenericTab :schema="schema" :item="item" />
  </div>
</template>
<script>
import GenericTab from '@sharedComponents/tabs/GenericTab';
import CnabsSchema from '@schemas/Cnabs';

export default {
  name: 'TabOrderSale',
  components: {
    GenericTab,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      schema: CnabsSchema,
    };
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

  <style scoped></style>
