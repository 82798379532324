/*
* Constants of Masks in the UI
*
* Used with v-mask prop in input element
* When create a mask, keep the organization in the exports in alphabetical order
*
* Vue-the-mask docs: https://vuejs-tips.github.io/vue-the-mask/
*/
import '@plugins/vue-the-mask';

/**
 * General Masks
 */

export const MASK_BANK_ACCOUNT = ['###-#', '####-#', '#####-#', '######-#', '#######-#', '########-#'];
export const MASK_BANK_AGENCY = ['###', '####', '#####', '######', '#######'];
export const MASK_BARCODE_8 = '########';
export const MASK_BARCODE_12 = '############';
export const MASK_BARCODE_13 = '#############';
export const MASK_BARCODE_14 = '##############';
export const MASK_BR_CAR_OLD_PLATE = 'AAA ####';
export const MASK_BR_CAR_PLATE = 'AAA #X##';
export const MASK_CEP = '#####-###';
export const MASK_CEST = '#######';
export const MASK_CNPJ = '##.###.###/####-##';
export const MASK_CPF = '###.###.###-##';
export const MASK_CPF_CNPJ = ['###.###.###-##', '##.###.###/####-##'];
export const MASK_CREDIT_CARD = '#### #### #### ####';
export const MASK_CURRENCY = '###.###.###.###.###.###.###,##';
export const MASK_CVV = '###';
export const MASK_DV = '#';
export const MASK_DATE = '##/##/####';
export const MASK_DATE_TIME = '##/##/#### ##:##:##';
export const MASK_EXPIRATION_CARD = '##/##';
export const MASK_INVOICEI_NUMBER = '######';
export const MASK_MONTH_YEAR = '##/####';
export const MASK_NCM = '########';
export const MASK_PHONE = '(##) ####-####';
export const MASK_PHONES_BR = ['####-####', '#####-####', '(##) ####-####', '(##) #####-####'];
export const MASK_PHONE_BR_CEL = '(##) #####-####';
export const MASK_PHONE_BR_CEL_COUNTRYCODE = '+## (##) #####-####';
export const MASK_PHONE_COUNTRYCODE = '+## (##) ####-####';
export const MASK_RENAVAN = '##########-#';
export const MASK_RNTC = 'AAA-########';
export const MASK_RNTC_OLD = 'AAA-##############';
export const MASK_TIME = '##:##:##';
export const MASK_TIME_COUNT = ['##:##', '###:##', '####:##'];

// big masks

const MASK_IE = [
  { text: '##.###.###/###-##', value: 'AC' },
  { text: '#########', value: 'AL' },
  { text: '#########', value: 'AP' },
  { text: '##.###.###-#', value: 'AM' },
  { text: '###.###.##-#', value: 'BA' },
  { text: '########-#', value: 'CE' },
  { text: '###########-##', value: 'DF' },
  { text: '###.###.##-#', value: 'ES' },
  { text: '##.###.###-#', value: 'GO' },
  { text: '#########', value: 'MA' },
  { text: '#########', value: 'MT' },
  { text: '#########', value: 'MS' },
  { text: '###.###.###/####', value: 'MG' },
  { text: '##-######-#', value: 'PA' },
  { text: '########-#', value: 'PB' },
  { text: '########-##', value: 'PR' },
  { text: '##.#.###.#######-#', value: 'PE' },
  { text: '#########', value: 'PI' },
  { text: '##.###.##-#', value: 'RJ' },
  { text: '##.###.###-#', value: 'RN' },
  { text: '###-#######', value: 'RS' },
  { text: '########-#', value: 'RO' },
  { text: '###.#####-#', value: 'RR' },
  { text: '###.###.###', value: 'SC' },
  { text: '###.###.###.###', value: 'SP' },
  { text: '#########-#', value: 'SE' },
  { text: '###########', value: 'TO' },
  { text: '##############', value: 'EX' },
];

export const masks = [
  {
    state_inscription_mask: MASK_IE,
  },
];
