import { ICON_APPLICATION_IMPORT } from '@constants/icons';

export default {
  service: 'StockItems',
  icon: ICON_APPLICATION_IMPORT,
  routes: {
    list: {
      name: 'supply-stocks-items',
      path: '/supply/stocks/items/:id',
    },
    edit: 'supply-stocks-edititem',
    create: 'supply-stocks-createitem',
  },
  titles: {
    item: 'Item do Estoque',
    items: 'Itens do Estoque',
    edit: 'Editar Item do estoque',
    create: 'Criar Item',
    new: 'Novo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'stocks/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'Código',
      name: 'product.code',
      list: true,
    },
    {
      label: 'Descrição',
      name: 'product_id',
      formattedName: 'product.to_s',
      type: 'number',
      list: true,
      component: 'dynamic-select',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 6,
    },
    {
      label: 'Qtd. Disponível',
      name: 'quantity_available',
      type: 'number',
      list: true,
      required: true,
    },
    // {
    //   label: 'Qtd. Reservado',
    //   name: 'quantity_reserved',
    // },
    {
      label: 'Qtd. Total',
      name: 'quantity_total',
      type: 'number',
      list: true,
    },
    {
      label: 'Local no Estoque',
      name: 'local',
      type: 'text',
      list: true,
      required: true,
    },
  ],
};
