import Vue from 'vue';
import { applyListPriceInOneItem } from './Common/ApplyListPrice';
import { formatCurrency } from '@plugins/app-utils';
import { setCbenef } from './Common/ItemCbenef';
import loadDataFromShowApi from './Common/loadDataFromShowApi';
import OrderSaleSchema from '@schemas/OrderSale';
import { DefaultScreenEvents } from './OrderSalePurchase/ChildBusiness/DefaultScreenEvents';
/** *
 * Sample of Business Rules Layer
 * TODO: Documentar camada de negócios do front
 * TODO: implementar regras para lidar com childSchemas na camada de negócios
 * Available functions:
 *  - for simple fields: change
 *  - for arrays: beforeUpdate, beforeCreate, update, create, select
 */

const calcTotalAndAbsoluteDiscount = (formValue) => {
  const discount = (parseFloat(formValue.discount_percentage_value) || 0) / 100;
  const unit = (parseFloat(formValue.unit_value) || 0);
  const quantity = (parseFloat(formValue.quantity) || 0);
  Vue.set(formValue, 'unit_value', formValue.unit_value);
  Vue.set(formValue, 'discount', formValue.discount);
  Vue.set(formValue, 'quantity', formValue.quantity);

  const total = unit * quantity;
  const totalWithDiscount = total - (total * discount);
  const discountValue = total - totalWithDiscount;
  Vue.set(formValue, 'discount_value', discountValue);
  Vue.set(formValue, 'total_without_discount_value', total);
  Vue.set(formValue, 'total_value', totalWithDiscount);
};

const setProduct = async (formValue, fieldValue) => {
  await Vue.prototype.$api.Products.showGraphql(fieldValue, ['id', 'to_s', 'code', 'unity_id', 'ncm_id', 'cest_id', 'purchase_cost', 'sale_value']).then(async (response) => {
    let product = response.data;

    const { buyer_id, price_list_id } = formValue;

    if (buyer_id && price_list_id) {
      product.product_id = product.id;
      product.quantity = formValue.quantity;

      const payloadPriceList = { buyer_id, price_list_id, items: [product] };
      const responsePriceList = await applyListPriceInOneItem(payloadPriceList, OrderSaleSchema.service);
      const [productPriceListCalculated] = responsePriceList.filter((item) => item.id === product.id);
      product = { ...product, ...productPriceListCalculated };
    }

    if (product) {
      formValue.product = product;
      formValue.product_id = product.id;
      formValue.product_code = product.id;
      formValue.name = product.to_s;
      formValue.unity_id = product.unity_id;
      formValue.unit_cost_value = product.purchase_cost;
      formValue.unit_value = product.unit_value > 0 ? product.unit_value : product.sale_value;
      formValue.ncm_id = product.ncm_id;
      formValue.cest_id = product.cest_id;

      const commonFields = ['id', 'to_s'];
      await loadDataFromShowApi(formValue, { key: 'unity', fields: [], service: 'AllTypes' });
      await loadDataFromShowApi(formValue, { key: 'cest', fields: commonFields, service: 'AllTypes' });
      await loadDataFromShowApi(formValue, { key: 'stock', fields: commonFields, service: 'StocksSimplified' });

      calcTotalAndAbsoluteDiscount(formValue);
    }
  });
};

export default {
  screenEvents: {
    ...DefaultScreenEvents,
  },
  fieldEvents: {
    product_code: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    product_id: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    unity_id: {
      change: async (formValue, fieldValue) => {
        const unity = await Vue.prototype.$api.AllTypes.show(fieldValue);
        formValue.unity = unity;
      },
    },
    unit_value: {
      change: (formValue) => {
        calcTotalAndAbsoluteDiscount(formValue);
        Vue.set(formValue, 'unit_value_formatted', formatCurrency(formValue.unit_value));
      },
    },
    quantity: {
      change: (formValue) => {
        const unit = (parseFloat(formValue.unit_value) || 0);
        const quantity = (parseFloat(formValue.quantity) || 0);
        const total = (unit * quantity) - (parseFloat(formValue.discount_value) || 0);
        Vue.set(formValue, 'total_value', total);
        Vue.set(formValue, 'quantity_formatted', quantity);
        calcTotalAndAbsoluteDiscount(formValue);
      },
    },
    total_value: {
      change: (formValue, fieldValue) => {
        const quantity = parseFloat(formValue.quantity) || 0;
        const discount = (parseFloat(formValue.discount_percentage_value) || 0);
        if (quantity > 0) {
          const totalWithoutDiscount = (parseFloat(fieldValue) * 100) / (100 - discount);
          const unit = (totalWithoutDiscount / quantity);
          Vue.set(formValue, 'unit_value', unit);
          Vue.set(formValue, 'total_without_discount_value', totalWithoutDiscount);
          Vue.set(formValue, 'discount_value', totalWithoutDiscount - fieldValue);
        }
      },
    },
    discount_value: {
      change: (formValue, fieldValue) => {
        const total = (parseFloat(formValue.unit_value) || 0) * (parseFloat(formValue.quantity) || 0);
        const totalWithDiscount = total - (parseFloat(fieldValue) || 0);
        const discountPercent = total > 0 ? (parseFloat(fieldValue) / total) * 100 : 0;
        Vue.set(formValue, 'discount_percentage_value', discountPercent);
        Vue.set(formValue, 'total_without_discount_value', total);
        Vue.set(formValue, 'total_value', totalWithDiscount);
      },
    },
    discount_percentage_value: {
      change: (formValue, fieldValue) => {
        const total = (parseFloat(formValue.unit_value) || 0) * (parseFloat(formValue.quantity) || 0);
        const totalWithDiscount = total - (total * ((parseFloat(fieldValue) || 0) / 100));
        const discountValue = total - totalWithDiscount;
        Vue.set(formValue, 'discount_value', discountValue);
        Vue.set(formValue, 'total_without_discount_value', total);
        Vue.set(formValue, 'total_value', totalWithDiscount);
      },
    },
    stock_id: {
      change: async (formValue) => {
        if (formValue.stock_id) {
          const { data } = await Vue.prototype.$api.Stocks.showGraphql(formValue.stock_id, ['id', 'to_s']);
          formValue.stock = { id: data.id, to_s: data.to_s };
        }
      },
    },
    cest_id: {
      change: async (formValue) => {
        if (formValue.product) {
          await loadDataFromShowApi(formValue, { key: 'cest', fields: ['id', 'to_s'], service: 'AllTypes' });
          formValue.cest_id = formValue.cest?.id;
        }
      },
    },
    nature_operation_id: {
      change: async (formValue, fieldObjectValue) => {
        const nature_operation_id = fieldObjectValue;
        const { data } = nature_operation_id ? await Vue.prototype.$api.NatureOperations.showGraphql(nature_operation_id, ['id', 'to_s', 'natureOperationStates', ['uf_state']]) : { data: {} };

        await setCbenef(formValue, data);
      },
    },
  },
};
