export default [
  // Line 1
  {
    label: 'Deduções',
    name: 'taxes.deductions',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 2,
    colClass: 'nf-field top left',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Base de Cálculo (R$)',
    name: 'taxes.total_base',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 2,
    colClass: 'nf-field top left',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Aliquota (%)',
    name: 'taxes.aliquot',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 2,
    colClass: 'nf-field top left',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'ISS',
    name: 'taxes.total_ISS',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 3,
    colClass: 'nf-field top left',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Total dos Serviços',
    name: 'total.services',
    type: 'number',
    component: 'dynamic-number-field',
    cols: 3,
    colClass: 'nf-field top left right',
    prependIcon: '',
    readOnly: true,
  },
  // Linha 2
  {
    label: 'INSS',
    name: 'taxes.total_INSS',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 2,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'IR',
    name: 'taxes.total_IR',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 1,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'CSLL',
    name: 'taxes.total_CSLL',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 1,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'COFINS',
    name: 'taxes.total_COFINS',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 1,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'PIS',
    name: 'taxes.total_PIS',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 1,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Descontos Diversos',
    name: 'total.another_discount',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 1.5,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Desc. Inco.',
    name: 'taxes.total_discounts',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 1.5,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
    readOnly: true,
  },
  {
    label: 'Valor da Nota',
    name: 'total.invoice',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 3,
    colClass: 'nf-field top left right bottom',
    prependIcon: '',
    readOnly: true,
  },

];
