import GeneralInvoicesReportsSchema from './GeneralInvoicesReportsSchema';

const GeneralInvoicesReports = () => import(/* webpackChunkName: "reports-invoices-general" */'./GeneralInvoicesReports');
export const InvoiceRoutes = [
  {
    path: GeneralInvoicesReportsSchema.route.path,
    name: GeneralInvoicesReportsSchema.route.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Relatórios' },
        { name: 'Notas Fiscais' },
      ],
    },
    component: GeneralInvoicesReports,
  },
];
