import GeneralBanksReportsSchema from './GeneralBanksReportsSchema';

const GeneralBanks = () => import(/* webpackChunkName: "general-banks-reports" */ './GeneralBanksReports');

export const BanksRoutes = [
  {
    path: GeneralBanksReportsSchema.route.path,
    name: GeneralBanksReportsSchema.route.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Relatórios' },
        { name: 'Bancos' },
      ],
    },
    component: GeneralBanks,
  },
];
