const ListBilletsPending = () => import(/* webpackChunkName: "list-billets-pending" */'./ListBilletsPending');
const ListBilletsApproved = () => import(/* webpackChunkName: "list-billets-approved" */'./ListBilletsApproved');
const ListBilletsRemittance = () => import(/* webpackChunkName: "list-billets-remittance" */'./ListBilletsRemittance');
const ListBilletsReturn = () => import(/* webpackChunkName: "list-billets-return" */'./ListBilletsReturn');
const ListImportReturn = () => import(/* webpackChunkName: "list-import-return" */'./ListImportReturn');

export default [
  {
    path: '/finacial/billetspending',
    name: 'financial-billetspending-list',
    meta: {
      requiresAuth: true,
      rule: 'billets',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Boletos Pendentes' },
      ],
    },
    component: ListBilletsPending,
  },
  {
    path: '/finacial/billetsapproved',
    name: 'financial-billetsapproved-list',
    meta: {
      requiresAuth: true,
      rule: 'billets',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Boletos Aprovados' },
      ],
    },
    component: ListBilletsApproved,
  },
  {
    path: '/finacial/billetsremittance',
    name: 'financial-billetsremittance-list',
    meta: {
      requiresAuth: true,
      rule: 'billets',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Boletos Remessas' },
      ],
    },
    component: ListBilletsRemittance,
  },
  {
    path: '/finacial/billetsreturn',
    name: 'financial-billetsreturn-list',
    meta: {
      requiresAuth: true,
      rule: 'billets',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Boletos Retornos' },
      ],
    },
    component: ListBilletsReturn,
  },
  {
    path: '/finacial/importreturn',
    name: 'financial-importreturn-list',
    meta: {
      requiresAuth: true,
      rule: 'billets',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Importar Retornos' },
      ],
    },
    component: ListImportReturn,
  },
];
