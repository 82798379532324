import {
  ICON_FOLDER,
  ICON_HOME,
  ICON_PHONE,
} from '@constants/icons';

import TabRegisters from '@modules/entries/pages/registers/components/TabRegisters';
import TabAddresses from '@modules/entries/pages/registers/components/TabAddresses';
import TabContacts from '@modules/entries/pages/registers/components/TabContacts';
// import TabPreferences from '@modules/entries/pages/registers/components/TabPreferences';

export default {
  service: 'Registers',
  icon: ICON_FOLDER,
  routes: {
    list: {
      name: 'entries-registers-list',
      path: '/registers',
    },
    edit: {
      path: '/registers/edit/:id',
      name: 'entries-registers-edit',
    },
    create: {
      path: '/registers/create',
      name: 'entries-registers-create',
    },
  },
  titles: {
    item: 'Cadastro',
    items: 'Cadastros',
    edit: 'Editar cadastro',
    create: 'Criar cadastro',
    new: 'Novo',
  },
  fields: [
    {
      label: 'Nome/Empresa',
      name: 'name',
      list: true,
    },
    {
      label: 'CNPJ / CPF',
      name: 'cnpj_cpf',
      formattedName: 'cnpj_cpf_formatted',
      type: 'text',
      list: true,
    },
    {
      label: 'Código',
      name: 'code',
      list: true,
    },
    {
      label: 'Apelido',
      name: 'nickname',
      list: true,
    },
    {
      label: 'IE / RG',
      name: 'ie_rg',
      list: true,
    },
    {
      label: 'Tipo de Cadastro',
      name: 'type_register',
      formattedName: 'type_register_formatted',
      list: true,
    },

  ],
  tabs: [
    {
      icon: ICON_FOLDER,
      title: 'Cadastro',
      tabContent: TabRegisters,
    },
    {
      icon: ICON_HOME,
      title: 'Endereços',
      tabContent: TabAddresses,
    },
    {
      icon: ICON_PHONE,
      title: 'Contatos',
      tabContent: TabContacts,
    },
    // {
    //   icon: ICON_TOOLS,
    //   title: 'Preferências',
    //   tabContent: TabPreferences,
    // },
  ],
};
