/**
 * Returns the address with provided brazilian CEP.
 *
 * @export
 * @param {String} cep
 * @returns {Object} address
 */
export default function searchCEP(cep) {
  const promise = new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('get', `https://viacep.com.br/ws/${cep}/json/`, true);
    request.send();
    request.onload = () => {
      if (request.status === 200) {
        const {
          erro, logradouro, uf, localidade, cidade, bairro, complemento, numero, ibge,
        } = JSON.parse(
          request.response,
        );
        if (erro) {
          reject(new Error('CEP inválido.'));
        }
        resolve({
          logradouro,
          complemento,
          localidade,
          numero,
          bairro,
          ibge,
          cidade,
          uf,
        });
      }
      reject(
        new Error(
          'O local de onde buscamos o CEP está offline. Digite manualmente o endereço.',
        ),
      );
    };
  });
  return promise;
}
