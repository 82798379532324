import Vue from 'vue';
import * as AppUtils from '@plugins/app-utils';

const calculateTotalCostAndTotalSale = (formValue) => {
  const purchase_cost = (parseFloat(formValue.purchase_cost) || 0);
  const sale_value = (parseFloat(formValue.sale_value) || 0);

  const quantity = (parseFloat(formValue.quantity) || 0);

  const total = (purchase_cost * quantity) || 0;
  const total_sell = (sale_value * quantity) || 0;
  Vue.set(formValue, 'total_purchase_cost', total);
  Vue.set(formValue, 'total_sale_value', total_sell);
};

export default {
  screenEvents: {
    beforeUpdate(formValue) {
      formValue.from_product = false;
      Vue.set(formValue, 'from_product', false);
    },
  },
  fieldEvents: {
    product_code: {
      change: (formValue, fieldValue, fieldObjectValue) => {
        // popular descrição e outros campos
        const product = fieldObjectValue;
        formValue.product = product;
        formValue.name = product.to_s;
        formValue.product_id = product.id;
        Vue.set(formValue, 'product_id', product.id);
        formValue.purchase_cost = product.purchase_cost;
        Vue.set(formValue, 'purchase_cost', product.purchase_cost);
        formValue.sale_value = product.sale_value;
        Vue.set(formValue, 'sale_value', product.sale_value);
        formValue.unit_id = product.unity_id;
        Vue.set(formValue, 'unit_id', product.unity_id);
        calculateTotalCostAndTotalSale(formValue);
      },
    },
    product_id: {
      change: (formValue, fieldValue, fieldObjectValue) => {
        // popular descrição e outros campos
        const product = fieldObjectValue;

        formValue.product = product;
        formValue.name = product.to_s;
        formValue.product_code = product.code;
        Vue.set(formValue, 'product_code', product.code);
        formValue.purchase_cost = product.purchase_cost;
        Vue.set(formValue, 'purchase_cost', product.purchase_cost);
        formValue.sale_value = product.sale_value;
        Vue.set(formValue, 'sale_value', product.sale_value);
        // TODO - Alterar o nome unit p/ unity, qnd for corrigido no backend
        formValue.unit_id = product.unity_id;
        Vue.set(formValue, 'unit_id', product.unity_id);
        calculateTotalCostAndTotalSale(formValue);
      },
    },
    quantity: {
      change: (formValue) => {
        calculateTotalCostAndTotalSale(formValue);
      },
    },
    purchase_cost: {
      change: (formValue) => {
        const purchase_cost = (parseFloat(formValue.purchase_cost) || 0);
        const quantity = (parseFloat(formValue.quantity) || 0);
        const total = (purchase_cost * quantity) || 0;
        Vue.set(formValue, 'total_purchase_cost', total);
      },
    },
    total_purchase_cost: {
      change: (formValue) => {
        const total_purchase_cost = (parseFloat(formValue.total_purchase_cost) || 0);
        const quantity = (parseFloat(formValue.quantity) || 0);
        const total = (total_purchase_cost / quantity) || 0;
        Vue.set(formValue, 'purchase_cost', total);
      },
    },
    sale_value: {
      change: (formValue) => {
        const sale_value = (parseFloat(formValue.sale_value) || 0);
        const quantity = (parseFloat(formValue.quantity) || 0);
        const total = (sale_value * quantity) || 0;
        Vue.set(formValue, 'total_sale_value', total);
      },
    },
    total_sale_value: {
      change: (formValue) => {
        const total_sale_value = (parseFloat(formValue.total_sale_value) || 0);
        const quantity = (parseFloat(formValue.quantity) || 0);
        const total = (total_sale_value / quantity) || 0;
        Vue.set(formValue, 'sale_value', total);
      },
    },
    from_product: {
      change: async (formValue) => {
        let totalCost = 0;
        if (formValue.from_product === true) {
          formValue.compositions.map((item) => {
            item.total_purchase_cost = item.quantity * item.product_purchase_cost;
            totalCost += item.total_purchase_cost;
            item.total_purchase_cost_formatted = AppUtils.formatCurrency(item.total_purchase_cost);
          });
        } else {
          formValue.compositions.map((item) => {
            item.total_purchase_cost = item.quantity * item.purchase_cost;
            totalCost += item.total_purchase_cost;
            item.total_purchase_cost_formatted = AppUtils.formatCurrency(item.total_purchase_cost);
          });
        }
        totalCost += formValue.cost_procedures;

        formValue.cost_total = totalCost;
        formValue.cost_total_formatted = AppUtils.formatCurrency(totalCost);
      },
    },
  },
};
