import Vue from 'vue';
import _ from 'lodash';
import { applyListPriceInItems } from './Common/ApplyListPrice';
import { applyGlobalDiscount } from './Common/ApplyGlobalDiscount';
import { createHintForRegisterField } from './Common/createHintForRegisterField';

import { calculateOrderServiceTaxesRealTime } from './OrderService/CalculateRealTime';
import { setTaxesValues } from './OrderService/SetTaxesValues ';
import { DefaultScreenEvents } from './OrderService/DefaultScreenEvents';
import { DefaultFieldEvents } from './OrderService/DefaultFieldEvents';
import { calculateTotals } from './OrderService/CalculateTotals';
import { resetCitiesItems, setCitiesItems } from '@schemas/OrderService';

const fillSelectCities = async (uf) => {
  await Vue.prototype.$api.OrderServicesCommands.getCities(uf).then((cities) => {
    if (cities && cities.length > 0) {
      resetCitiesItems();
      cities.forEach((city) => {
        setCitiesItems({ text: city.name, value: city.name });
      });
    }
  });
};

function checkDateFieldsIsEnabled(formValue) {
  const currentDate = new Date();

  const action = formValue.id ? 'update' : 'create';

  if (action === 'create') {
    formValue.start_datetime = currentDate;
    formValue.competence_datetime = currentDate;
  }
}
async function calculateTaxes(formValue) {
  const action = formValue.id ? 'update' : 'create';
  if (action === 'update') {
    if (formValue.buyer_id) {
      const data = await calculateOrderServiceTaxesRealTime(formValue);
      if (data) {
        setTaxesValues(formValue, data);
        calculateTotals(formValue, data);
      }
    }
    Vue.set(formValue, 'items', formValue.items);
    Vue.set(formValue, 'taxes', formValue.items);
  }
}

export default {
  screenEvents: {
    ...DefaultScreenEvents,
    async beforeCreate(formValue) {
      formValue.items = [];

      checkDateFieldsIsEnabled(formValue);
    },
    async beforeUpdate(formValue) {
      calculateTaxes(formValue);

      if (!formValue.buyer_id) return;
      const hint = await createHintForRegisterField(formValue.buyer_id);
      return {
        fields: [{ label: 'Cliente', hint }],
      };
    },
  },
  fieldEvents: {
    'provision_address.uf': {
      change: async (formValue) => {
        fillSelectCities(formValue.provision_address.uf);
      },
    },
    buyer_id: {
      async change(formValue, fieldValue) {
        const buyer = await Vue.prototype.$api.Registers.show(fieldValue);
        formValue.buyer = buyer;
        formValue.buyer_id = buyer.id;
        Vue.set(formValue, 'buyer', buyer);
        Vue.set(formValue, 'buyer_id', buyer.id);

        if (buyer.addresses.length === 0) {
          Vue.prototype.$notifyError('Cliente não possui endereço', {
            action: {
              text: 'Clique aqui para cadastrar',
              push: {
                name: 'entries-registers-edit',
                params: {
                  id: buyer.id,
                },
              },
              class: 'custom-message',
            },
            duration: 6000,
          });
        } else {
          const hint = await createHintForRegisterField(buyer.id, buyer);
          const salesmanId = _.get(buyer, 'preference.salesman_id');
          if (salesmanId) {
            const salesman = await Vue.prototype.$api.Registers.show(salesmanId);
            Vue.set(formValue, 'salesman_id', salesmanId);
            Vue.set(formValue, 'salesman', salesman);
          }

          if (formValue.buyer_id && formValue.nature_operation_id) {
            const response = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
            formValue.buyer = {
              ...formValue.buyer,
              taxpayer_type: response.taxpayer_type,
            };
            const data = await calculateOrderServiceTaxesRealTime(formValue);
            if (data) {
              setTaxesValues(formValue, data);
              calculateTotals(formValue, data);
            }
          }
          return { fieldAttributes: { hint } };
        }
      },
    },
    nature_operation_id: {
      change: async (formValue, fieldValue) => {
        formValue.items = formValue.items || [];
        const apportionment = true;

        const { data } = await Vue.prototype.$api.NatureOperations.show(fieldValue) ?? { data: {} };
        formValue.nature_operation = data;
        Vue.set(formValue, 'nature_operation', data);

        const uf = formValue?.buyer?.addresses.length ? formValue?.buyer?.addresses[0].uf : '';
        const [uf_select] = data?.states.filter((item) => item.uf_state === uf);

        Vue.set(formValue, 'service_nature_operation', uf_select?.taxes?.iss?.service_nature_operation ? `${uf_select.taxes.iss.service_nature_operation}` : null);
        formValue.code_situation_issqn_id = uf_select?.taxes?.iss?.service_situation_issqn_id ?? null;
        Vue.set(formValue, 'code_situation_issqn_id', uf_select?.taxes?.iss?.service_situation_issqn_id ?? null);

        if (formValue.buyer_id && formValue.nature_operation_id && formValue.items.length > 0) {
          const real = await calculateOrderServiceTaxesRealTime(formValue);
          if (real) {
            setTaxesValues(formValue, real, apportionment);
            calculateTotals(formValue, real);
          }
        }
      },
    },
    service_code_id: {
      change: async (formValue, fieldValue, fieldObjectValue) => {
        formValue.service_code = fieldObjectValue;
        Vue.set(formValue, 'service_code', fieldObjectValue);
      },
    },
    code_situation_issqn_id: {
      change: async (formValue, fieldValue, fieldObjectValue) => {
        formValue.code_situation_issqn = fieldObjectValue;
        Vue.set(formValue, 'code_situation_issqn', fieldObjectValue);
      },
    },
    price_list_id: {
      change: async (formValue) => {
        formValue.items = await applyListPriceInItems(formValue);
        Vue.set(formValue, 'items', formValue.items);
      },
    },
    items: {
      beforeCreate: async (formValue, newItem) => {
        if (!formValue.buyer_id) return 'Não é possível adicionar ítens sem selecionar um cliente';
        if (!formValue?.provision_address?.uf) return 'Não é possível adicionar ítens sem selecionar a UF do local da prestação do serviço';
        if (!formValue.nature_operation_id) return 'Não é possível adicionar ítens sem selecionar a natureza de operação';
        if (!formValue.service_code_id) return 'Não é possível adicionar ítens sem selecionar o código de serviço';

        newItem.nature_operation_id = formValue.nature_operation_id;
        newItem.service_code_id = formValue.service_code_id;
        newItem.buyer_id = formValue.buyer_id;
        newItem.order_number = formValue.order;
      },
      delete: async (formValue) => {
        const data = await calculateOrderServiceTaxesRealTime(formValue);
        if (data) {
          setTaxesValues(formValue, data);
          calculateTotals(formValue, data);
          Vue.set(formValue, 'items', formValue.items);
        }
      },
      change: async (formValue) => {
        if (formValue.pricelist_id) {
          formValue.items = await applyListPriceInItems(formValue);
        }
        if (formValue.buyer_id) {
          const data = await calculateOrderServiceTaxesRealTime(formValue);
          if (data) {
            setTaxesValues(formValue, data);
            calculateTotals(formValue, data);
          }
        }
        Vue.set(formValue, 'items', formValue.items);
      },
    },
    ...DefaultFieldEvents,
  },
  applyGlobalDiscount,
};
