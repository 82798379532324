import OrderBudgetSchema from '@schemas/OrderBudget';

const ListOrderBudget = () => import(/* webpackChunkName: "list-order-budget" */'./ListOrderBudget');
const DetailOrderBudget = () => import(/* webpackChunkName: "detail-order-budget" */'./DetailOrderBudget');

export default [
  {
    path: OrderBudgetSchema.routes.list.path,
    name: OrderBudgetSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      /* @TODO - Criar rules do Orçamentos no backend */
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: 'Orçamentos' },
      ],
    },
    component: ListOrderBudget,
  },
  {
    path: OrderBudgetSchema.routes.create.path,
    name: OrderBudgetSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      /* @TODO - Criar rules do Orçamentos no backend */
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: 'Orçamentos', route: OrderBudgetSchema.routes.list.name },
        { name: 'Criar Orçamento' },
      ],
    },
    component: DetailOrderBudget,
  },
  {
    path: OrderBudgetSchema.routes.edit.path,
    name: OrderBudgetSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      /* @TODO - Criar rules do Orçamentos no backend */
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: 'Orçamentos', route: OrderBudgetSchema.routes.list.name },
        { name: 'Editar Orçamento' },
      ],
    },
    component: DetailOrderBudget,
  },
];
