<template>
  <v-navigation-drawer app
                       stateless
                       :mini-variant.sync="miniFatherCallback"
                       expand-on-hover
                       permanent
                       class="ml-2 mt-3"
                       style="border-radius: 35px;"
                       :width="navWidth"
                       height="98%">
    <v-row class="fill-height" no-gutters>
      <v-navigation-drawer dark
                           :mini-variant.sync="miniSonCallback"
                           mini-variant-width="68"
                           permanent
                           :expand-on-hover="$vuetify.breakpoint.mdAndUp"
                           class="background-black">
        <v-row dense no-gutters class="fill-height flex-column" style>
          <v-col class="flex-grow-0 py-4 mt-n4">
            <v-list nav>
              <v-list-item :class="!miniFatherCallback && !miniSonCallback ? 'mx-6 px-4 home-button' : 'px-4 home-button'" to="/" :ripple="false">
                <v-list-item-avatar>
                  <v-img width="1200" :src="iconWhite" />
                </v-list-item-avatar>
                <v-list-item-title v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">
                  Home
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="mt-n4">
            <v-list nav>
              <template v-for="item in items">
                <v-list-item :key="item.title"
                             class="menu-main-buttons"
                             right
                             style="border-radius: 25px;"
                             :ripple="false"
                             @click="onModuleClick(item)">
                  <v-list-item-icon :class="!miniFatherCallback && !miniSonCallback ? 'ml-4' : ''">
                    <v-icon>
                      {{ item.icon }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title v-if="$vuetify.breakpoint.mdAndUp">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
          <v-spacer />
          <v-col class="flex-grow-0">
            <QuickSwitch :father-call-back="miniFatherCallback" :son-call-back="miniSonCallback" />
          </v-col>
        </v-row>
      </v-navigation-drawer>

      <!-- Module items -->

      <v-col v-if="showSubMenu" justify="start" no-gutters class="fill-height overflow-hidden background-gray">
        <v-row v-if="selectedModule.icon && selectedModule.title" class="text-h6 indigo--text text--lighten-1 ml-1 pa-3 pl-3 py-4 background-gray" no-gutters align="center">
          <v-icon class="mr-2" color="indigo lighten-1" large>
            {{ selectedModule.icon }}
          </v-icon>
          <b class="module-text ml-2">
            {{ selectedModule.title }}
          </b>
        </v-row>
        <v-row v-else class="text-h6 indigo--text text--lighten-1 mx-2 pa-3 pl-3 py-4 background-gray" no-gutters align="center">
          <v-icon class="mr-2" color="indigo lighten-1" large>
            {{ icons.dashboard }}
          </v-icon>
          <b class="ml-2"> Olá, {{ user.name.split(' ', 1).toString() }} </b>
        </v-row>

        <VuePerfectScrollbar v-if="selectedModule.items" ref="perfectScroll" class="scroll-area" :settings="scrollSettings">
          <List :items="selectedModule.items" class="mt-n1 mb-16" @click="refreshPerfectScroll" @hideSubMenu="onListItemClick" />
        </VuePerfectScrollbar>
        <v-row v-else class="text-p indigo--text text--lighten-1 pa-3 pl-3 py-6 background-gray" no-gutters align="center">
          <p class="ml-3">
            Clique em um dos módulos no menu esquerdo para utilizar suas funcionalidades.
          </p>
        </v-row>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import { ICON_DASHBOARD } from '@constants/icons';
import { mapState, mapActions } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import List from '@sharedComponents/List';
import QuickSwitch from './widgets/QuickSwitch';

export default {
  name: 'Sidebar',
  components: {
    List,
    VuePerfectScrollbar,
    QuickSwitch,
  },
  data() {
    return {
      drawer: true,
      selectedModule: {},
      icons: {
        dashboard: ICON_DASHBOARD,
      },
      iconWhite: require('@assets/icon_white.png'),
      scrollSettings: {
        maxScrollbarLength: 60,
      },
      showSubMenu: true,
      mini: false,
      miniFatherCallback: true,
      miniSonCallback: true,
      user: {
        name: localStorage.getItem('name'),
      },
    };
  },
  computed: {
    ...mapState({
      dark: (state) => state.user.dark,
      gradient: (state) => state.user.gradient,
      image: (state) => state.user.image,
      appItems: (state) => state.app.items,
      antecipationItems: (state) => state.app.antecipationItems,
      version: (state) => state.app.version,
      drawerImage: (state) => state.app.drawerImage,
    }),
    navWidth() {
      return this.showSubMenu && !this.mini ? 550 : 68;
    },
    items() {
      return this.$route.meta.antecipation ? this.antecipationItems : this.appItems;
    },
  },
  beforeMount() {
    this.$router.afterEach((to) => {
      this.checkShowSubMenu(to.name);
    });
  },
  mounted() {
    this.checkShowSubMenu(this.$router.currentRoute.name || '');
  },
  methods: {
    ...mapActions({
      setShowSubMenu: 'app/setShowSubMenu',
      toggleMini: 'app/toggleMini',
    }),
    checkShowSubMenu(currentRouteName) {
      const { foundModule } = this.findRouteMenu(currentRouteName);
      if (foundModule && foundModule.items) {
        this.setShowSubMenu(true);
        this.selectedModule = foundModule;
      } else {
        this.setShowSubMenu(false);
        this.selectedModule = {};
      }
    },
    findRouteMenu(routeName) {
      let foundItem;
      let foundModule;
      const firstPartRouteName = routeName.split('-')[0];
      const recursiveFind = (items, firstLevel) => items.every((item) => {
        if (foundItem) {
          return false;
        }
        if (firstLevel) {
          foundModule = item;
        }

        const itemTo = item.to || '';

        if (itemTo === routeName || itemTo.startsWith(firstPartRouteName)) {
          foundItem = item;
          return false;
        }
        if (item.items) {
          recursiveFind(item.items, false);
        }
        return true;
      });

      recursiveFind(this.items, true);

      if (!foundItem) {
        foundModule = undefined;
      }
      return { foundItem, foundModule };
    },
    onModuleClick(item) {
      if (item.items) {
        this.selectedModule = item;
        this.setShowSubMenu(true);
        if (this.mini) {
          this.toggleMini();
        }
      } else {
        this.selectedModule = {};
        this.setShowSubMenu(false);
        this.$router.push({
          name: item.to,
        });
      }
    },
    refreshPerfectScroll() {
      const that = this;
      setTimeout(() => {
        that.$refs.perfectScroll.update();
      }, 200);
    },
    onListItemClick(value) {
      this.showSubMenu = value;
      setTimeout(() => {
        this.showSubMenu = !value;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-area {
  position: relative;
  bottom: 0;
  height: 100%;
  background-color: #f4f4f4;
}
// .menu-main-buttons:active,
.menu-main-buttons {
  transition: all 0.1s ease-in-out;
  // transition: border-radius 0.1s ease-out;
}
.menu-main-buttons:hover,
// .menu-main-buttons:before,
.menu-main-buttons:focus {
  background-color: #5c6bc0 !important;
  border-radius: 30px !important;
  opacity: unset !important;
  font-weight: 700;
  transition: all 0.1s ease-in-out;
}
.menu-main-buttons:active {
  background-color: #5a69c0 !important;
}
.theme--dark.v-list-item:hover:before {
  opacity: 0 !important;
}
.theme--dark.v-list-item--active:before {
  opacity: 0 !important;
}
.background-black {
  background-color: #3a3a3a !important;
}
.background-gray {
  background-color: #f4f4f4;
}
.module-text {
  font-family: 'Montserrat';
  font-size: 1.4em;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
}
.module-divider {
  border-color: #5a71d4 !important;
  width: 12em !important;
  margin-left: 4vw;
}
.inner-divider {
  border-color: #f0f0f0 !important;
  width: 1.7em !important;
  margin-left: 1vw;
}
</style>

<style>
.home-button .v-list-item__title {
  font-weight: bolder;
  font-size: large;
}

.home-button .v-list-item__title:after {
  content: "";
  position: absolute;
  display: flex;
  left: 10px;
  bottom: -3px;
  border-radius: 30px;
  background-color: white;
  height: 3px;
  width: 0%;
  transition: all 0.2s ease-in-out;
}

.home-button:hover .v-list-item__title:after {
  content: "";
  position: absolute;
  display: flex;
  left: 10px;
  bottom: -3px;
  border-radius: 30px;
  background-color: white;
  height: 3px;
  width: 90%;
}

.v-list-item--link .v-list-item__title.item-title {
  font-weight: 300;
  transition: all 0.1s ease-in-out;
}
.v-list-item--link:hover .v-list-item__title.item-title {
  font-weight: 600;
  transition: all 0.1s ease-in-out;
}

.v-list-item--link .v-list-item__title.item-title {
  font-weight: 300;
  transition: all 0.1s ease-in-out;
}
.v-list-item--link.v-list-item--active:hover .v-list-item__title.item-title {
  font-weight: 600;
  opacity: 100%;
  transition: all 0.1s ease-in-out;
}
.v-list-item--link:hover .v-list-item__title.item-title {
  font-weight: 600;
  opacity: 70%;
  transition: all 0.1s ease-in-out;
}

.v-list-item--link .v-list-item__icon {
  transform: scale(1);
  transition: all 0.1s ease-in-out;
}
.v-list-item--link:hover .v-list-item__icon {
  transform: scale(1.2);
  transition: all 0.1s ease-in-out;
}
</style>
