<template>
  <div>
    <v-card color="white" class="pa-2 my-0 mt-n4" outlined>
      <v-card-title class="indigo--text text--lighten-1">
        ENDEREÇOS
      </v-card-title>
      <v-card-text>
        <div v-if="item.addresses && item.addresses.length > 0">
          <template v-for="(element, index) in item.addresses">
            <v-divider v-if="index !== 0" :key="index" class="divider mt-n2 mb-6 mx-2" />
            <GenericCardPanel
              :key="index"
              bg-color="white"
              text-color="grey--text text--darken-3 text-h6 mt-n8"
              text-bold
              :text-style="{ fontSize: 'medium' }"
              class="mb-2"
              :title="`Endereço ${element.main === true ? 'principal' : 'auxiliar'}`"
              :fields="getAddressFields(element)" />
          </template>
        </div>
        <div v-else>
          Não há endereços cadastrados
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabAddresses',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    getAddressFields(item) {
      return [
        { label: 'Tipo de endereço', value: item.type_address ? item.type_address.to_s : '', col: 4 },
        { label: 'CEP', value: item.zip_code, col: 4 },
        { label: 'UF', value: item.zip_code, col: 4 },
        { label: 'Logradouro', value: item.street, col: 4 },
        { label: 'Número', value: item.number, col: 4 },
        { label: 'Complemento', value: item.complement, col: 4 },
        { label: 'Cidade', value: item.city, col: 4 },
        { label: 'Bairro', value: item.neighborhood, col: 4 },
      ];
    },
  },
};
</script>

<style scoped>
.title-size {
  font-size: small;
}
</style>
