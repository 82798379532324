import CashFlowSchema from '@schemas/CashFlow';

const CashFlow = () => import(/* webpackChunkName: "list-cash-flows" */'./ListCashFlows');
const CashFlowDetail = () => import(/* webpackChunkName: "detail-cash-flows" */'./DetailCashFlows');

export default [
  {
    path: CashFlowSchema.routes.list.path,
    name: CashFlowSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Planejamento' },
      ],
    },
    component: CashFlow,
  },
  {
    path: CashFlowSchema.routes.create.path,
    name: CashFlowSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Planejamento', route: CashFlowSchema.routes.list.name },
        { name: 'Criar Fluxo de Caixa' },
      ],
    },
    component: CashFlowDetail,
  },
];
