import Vue from 'vue';
import _ from 'lodash';
import { applyGlobalDiscount } from './Common/ApplyGlobalDiscount';
import { calculateOrderPurchaseRealTime } from './Common/CalculateRealTime';
import { calculateTotals } from './Common/CalculateTotals';
import { createHintForRegisterField } from './Common/createHintForRegisterField';
import { setTaxesValues } from './Common/SetTaxesValues ';

import { DefaultScreenEvents } from './OrderSalePurchase/DefaultScreenEvents';
import { DefaultFieldEvents } from './OrderSalePurchase/DefaultFieldEvents';
import { setCbenef } from './Common/ItemCbenef';

/** *
 * Sample of Business Rules Layer
 * TODO: Documentar camada de negócios do front
 * TODO: implementar regras para lidar com childSchemas na camada de negócios
 * Available functions:
 *  - for simple fields: change
 *  - for arrays: beforeUpdate, beforeCreate, update, create, select
 */

export default {
  screenEvents: {
    ...DefaultScreenEvents,
    async beforeUpdate(formValue) {
      if (!formValue.vendor_id) return;
      const vendorHint = await createHintForRegisterField(formValue.vendor_id);

      return { fields: [{ label: 'Fornecedor', hint: vendorHint }] };
    },
  },
  fieldEvents: {
    vendor_id: {
      async change(formValue) {
        const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
        formValue.vendor = vendor;
        formValue.vendor_id = vendor.id;
        Vue.set(formValue, 'vendor', vendor);
        Vue.set(formValue, 'vendor_id', vendor.id);

        if (vendor.addresses.length === 0) {
          Vue.prototype.$notifyError('Fornecedor não possui endereço', {
            action: {
              text: 'Clique aqui para cadastrar',
              push: {
                name: 'entries-registers-edit',
                params: {
                  id: vendor.id,
                },
              },
              class: 'custom-message',
            },
            duration: 6000,
          });
        } else {
          const apportionment = true;
          const hint = await createHintForRegisterField(vendor.id, vendor);

          const shipping_company_id = _.get(vendor, 'preference.shipping_company_id');
          if (shipping_company_id) formValue.shipping_company_id = shipping_company_id;

          if (formValue.vendor_id && formValue.nature_operation_id) {
            formValue.vendor = {
              ...formValue.vendor,
              taxpayer_type: vendor.taxpayer_type,
            };
            const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
            setTaxesValues(formValue, data, apportionment);
          }
          return { fieldAttributes: { hint } };
        }
      },
    },
    is_costumer: {
      change: async (formValue) => {
        if (formValue.vendor_id) {
          const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
          formValue.vendor = vendor;
          const apportionment = true;
          formValue.vendor = {
            ...formValue.vendor,
            taxpayer_type: vendor.taxpayer_type,
          };
          const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
          setTaxesValues(formValue, data, apportionment);
        }
      },
    },
    nature_operation_id: {
      change: async (formValue) => {
        if (formValue.vendor_id) {
          const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
          formValue.vendor = vendor;

          formValue.items = formValue.items || [];
          formValue.items.forEach((item) => {
            item.nature_operation_id = formValue.nature_operation_id;
          });
          if (formValue.vendor_id && formValue.items.length > 0) {
            const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer, formValue.buyer_uf);
            setTaxesValues(formValue, data);
            await setCbenef(formValue, data);
            if (data.totalizers?.ipi) {
              formValue.total_ordered = formValue.total_products + data.totalizers.ipi;
            }
          }
        }
      },
    },
    items: {
      beforeCreate: async (formValue, newItem) => {
        if (!formValue.vendor_id) return 'Não é possível adicionar ítens sem selecionar um fornecedor';
        if (!formValue.stock_id) return 'Não é possível adicionar ítens sem selecionar o estoque';
        if (!formValue.nature_operation_id) return 'Não é possível adicionar ítens sem selecionar a natureza de operação';
        if (formValue?.vendor?.addresses?.length === 0) return 'Para adicionar ítens na compra precisa ter endereço UF no fornecedor selecionado';

        newItem.stock_id = formValue.stock_id;
        newItem.nature_operation_id = formValue.nature_operation_id;
        newItem.order_number = formValue.order;
        newItem.vendor_id = formValue.vendor_id;
      },
      change: async (formValue) => {
        const apportionment = true;

        calculateTotals(formValue);
        if (formValue.vendor_id) {
          const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
          formValue.vendor = vendor;
          const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
          setTaxesValues(formValue, data, apportionment);
        }
        Vue.set(formValue, 'items', formValue.items);
      },
    },
    use_freight: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          const apportionment = true;
          if (formValue.use_freight === false) {
            calculateTotals(formValue, true);
          } else {
            calculateTotals(formValue);
          }
          if (formValue.vendor_id) {
            const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
            formValue.vendor = vendor;
            const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
            setTaxesValues(formValue, data, apportionment);
          }
        }
      },
    },
    expense_value: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          const apportionment = true;
          calculateTotals(formValue);
          if (formValue.vendor_id) {
            const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
            formValue.vendor = vendor;
            const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
            setTaxesValues(formValue, data, apportionment);
          }
        }
      },
    },
    freight_value: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          const apportionment = true;
          if (formValue.use_freight === true) {
            calculateTotals(formValue);
          } else {
            calculateTotals(formValue, true);
          }
          if (formValue.vendor_id) {
            const vendor = await Vue.prototype.$api.Registers.show(formValue.vendor_id);
            formValue.vendor = vendor;
            const data = await calculateOrderPurchaseRealTime(formValue.items, vendor, formValue.is_costumer);
            setTaxesValues(formValue, data, apportionment);
          }
        }
      },
      observations: {
        beforeCreate: async (formValue) => {
          if (!formValue.buyer_id) return 'Não é possível adicionar observações sem selecionar um cliente';
        },
      },
    },
    ...DefaultFieldEvents,
  },
  applyGlobalDiscount,
};
