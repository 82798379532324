<template>
  <v-select
    v-model="selected"
    :value="parsedFieldValue"
    :filterable="false"
    :field-items="fieldItems"
    label="text"
    :rounded="field.rounded"
    :filled="field.filled"
    v-bind="$attrs"
    v-on="$listeners"
    @search="onSearch">
    <template slot="no-fieldItems">
      Digite para pesquisar...
    </template>
    <template slot="option" slot-scope="option">
      <div class="d-center">
        {{ option.text }}
      </div>
    </template>
    <template slot="selected-option" slot-scope="option">
      <div class="selected d-center">
        {{ option.text }}
      </div>
    </template>
  </v-select>
</template>

<script>
import Vue from 'vue';
import 'vue-select/dist/vue-select.css';

import _ from 'lodash';
import vSelect from 'vue-select';

import DynamicUtils from './DynamicUtils';
import DynamicFieldMixin from './DynamicFieldMixin';

export default {
  name: 'DynamicInfiniteScroll',
  components: {
    vSelect,
  },
  mixins: [DynamicFieldMixin],
  props: {
    service: {
      type: String,
      default: '',
    },
    dataSource: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      DynamicUtils,
      fieldItems: [],
      fieldValue: this.value,
      parsedFieldValue: [],
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this.dataSource, this);
      }
    },
    search: _.debounce(async (loading, search, dataSource) => {
      await Vue.prototype.$api[dataSource.service].getByFilter({
        use_inactive_eq: false,
        name_cont: search,
      },
      'name ASC',
      {
        value: 'id',
        text: 'name',
      }).then((response) => {
        const formattedItemsToSelect = response.data.map((item) => ({
          value: item.id,
          text: item.name || item.to_s,
        }));

        this.fieldItems = formattedItemsToSelect;
        loading(false);
      })
        .catch(() => {
          loading(false);
        });
    }, 500),
    onFieldValueChange(value) {
      if (this.field.multiple && Array.isArray(value)) {
        this.parsedFieldValue = this.fieldItems.filter((item) => value.findIndex((val) => item[this.field.itemValue || 'value'] === val) !== -1);
        return;
      }
      this.parsedFieldValue = value;
    },
    onSelectChange(objectValue) {
      const value = this.field.multiple ? objectValue.map((o) => o[this.field.itemValue || 'value']) : objectValue[this.field.itemValue || 'value'];
      this.fieldValue = value;
      this.fieldChanged(this.fieldValue, objectValue);
    },
    goTo() {
      if (this.field.redirectCreateRoute) {
        this.$router.push({ name: this.field.redirectCreateRoute, params: { return: this.$route.name } });
      }
    },
  },
};
</script>

<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
