export default [
  // Line 1
  {
    label: 'Número',
    name: 'bill.number',
    type: 'text',
    component: 'dynamic-number-field',
    cols: 12,
    colClass: 'nf-field bottom',
    precision: 0,
    prependIcon: '',
  },
  // {
  //   label: 'Limpar Fatura',
  //   name: 'icms',
  //   type: 'text',
  //   component: 'dynamic-button',
  //   cols: 4,
  //   colClass: 'nf-field top left',
  //   prependIcon: '',
  // },
  // {
  //   label: 'Trocar para N NF',
  //   name: 'icms',
  //   type: 'text',
  //   component: 'dynamic-button',
  //   cols: 4,
  //   colClass: 'nf-field top left',
  //   prependIcon: '',
  // },
  // Linha 2
  {
    label: 'Valor original',
    name: 'bill.title_value',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 4,
    colClass: 'nf-field bottom',
    prependIcon: '',
  },
  {
    label: 'Desconto',
    name: 'bill.discont_value',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 4,
    colClass: 'nf-field bottom left',
    prependIcon: '',
  },
  {
    label: 'Valor Líquido',
    name: 'bill.net_value',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 4,
    colClass: 'nf-field bottom left',
    prependIcon: '',
  },
  // Linha 3
  {
    label: 'Forma de pagamento',
    name: 'payment_method_id',
    formattedName: 'payment_method.to_s',
    type: 'number',
    component: 'dynamic-select',
    dataSource: {
      service: 'AllTypesCommands',
      method: 'getManyByTypeExcludeDisables',
      props: ['payment_method'],
    },
    itemText: 'to_s',
    itemValue: 'id',
    cols: 12,
    colClass: 'nf-field',
    prependIcon: '',
    clearable: true,
  },
];
