import StockItemsSchema from '@schemas/StockItem';

const ListStockItems = () => import(/* webpackChunkName: "list-stocks-items" */'./ListStockItems');

export default [
  {
    ...StockItemsSchema.routes.list,
    meta: {
      requiresAuth: true,
      rule: 'stocks',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: StockItemsSchema.titles.items },
      ],
    },
    component: ListStockItems,
  },
];
