import {
  ICON_DESCRIPTION,
  ICON_INVOICE,
  ICON_ERROR, ICON_TAXES,
} from '@constants/icons';
import InvoiceISSBusiness from '@businesses/InvoiceISS';
import { OPTIONS_INVOICE_ISS_STATUS } from '@constants/options';

import CustomerFields from './invoiceISS/CustomerFields';
import EmitterFields from './invoiceISS/EmitterFields';
import ListFields from './invoiceISS/ListFields';
import TaxesFields from './invoiceISS/TaxesFields';

import InvoiceISSDetailsSchema from './InvoiceISSDetails';
import InvoiceISSItemsSchema from './InvoiceISSItems';
import InvoiceISSLocationSchema from './InvoiceISSLocation';
import InvoiceISSTaxationSchema from './InvoiceISSTaxation';
import InvoiceISSItemTaxSchema from './InvoiceISSItemTax';

import TabInvoiceISS from '@modules/supply/pages/invoicesISS/components/TabInvoiceISS';
import TabInvoiceISSTaxes from '@modules/supply/pages/invoicesISS/components/TabInvoiceISSTaxes';
import TabInvoiceISSEvents from '@modules/supply/pages/invoicesISS/components/TabInvoiceISSEvents';

export default {
  service: 'InvoicesISS',
  business: InvoiceISSBusiness,
  icon: ICON_INVOICE,
  routes: {
    list: {
      path: '/supply/invoices-iss',
      name: 'supply-invoicesiss-list',
    },
    create: {
      path: '/supply/invoices-iss/create',
      name: 'supply-invoicesiss-create',
    },
    edit: {
      path: '/supply/invoices-iss/edit/:id',
      name: 'supply-invoicesiss-edit',
    },
  },
  titles: {
    item: 'Espelho da Nota Fiscal de Serviço',
    items: 'Notas Fiscais de Serviço',
    edit: 'Editar Nota Fiscal de Serviço',
    new: 'Novo',
  },
  messages: {
    successTransmitted: 'Nota(s) de serviço enviada(s) com sucesso',
    errorTransmitted: 'Erro ao enviar nota(s) de serviço',
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  statusSettings: {
    options: OPTIONS_INVOICE_ISS_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  noEdit: false,
  fields: [
    ...ListFields,
  ],
  layout: [
    {
      component: 'slot',
      name: 'nf-header',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          containerClass: 'pt-0',
          fields: [...EmitterFields],
        },
      ],
    },
    {
      label: 'DESTINATÁRIO / REMETENTE',
      class: 'mb-0 mx-3 pb-0',
      component: 'dynamic-divider',
      md: 12,
      size: 'md',
      propTag: 'h5',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          fields: [...CustomerFields],
        },
      ],
    },
    {
      label: 'LOCAL DA PRESTAÇÃO DE SERVIÇO',
      component: 'dynamic-divider',
      class: 'mb-4 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'generic-card-form',
      class: 'mx-3 my-3',
      children: [{
        component: 'v-row',
        noGutters: true,
        class: 'nf-field top right bottom left ma-0',
        children: [
          {
            component: 'dynamic-form',
            fieldName: 'provission_address',
            containerClass: 'pa-0',
            fields: [...InvoiceISSLocationSchema.fields],
          },
        ],
      }],
    },
    {
      label: 'TRIBUTAÇÕES DE SERVIÇO',
      component: 'dynamic-divider',
      class: 'mb-4 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'generic-card-form',
      class: 'mx-3 my-3',
      children: [{
        component: 'v-row',
        noGutters: true,
        class: 'nf-field top right bottom left ma-0',
        children: [
          {
            component: 'dynamic-form',
            fieldName: 'provission_address',
            containerClass: 'pa-0',
            fields: [...InvoiceISSTaxationSchema.fields],
          },
        ],
      }],
    },
    {
      label: 'DADOS DOS SERVIÇOS',
      component: 'dynamic-divider',
      class: 'mb-4 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'generic-card-form',
      class: 'mx-3 my-3',
      children: [
        {
          component: 'v-row',
          noGutters: true,
          class: 'nf-field top right bottom left ma-0',
          children: [
            {
              component: 'dynamic-tabs',
              tabs: [
                {
                  label: 'Itens da Nota',
                  children: [
                    {
                      component: 'dynamic-child-schema',
                      noCheck: true,
                      noCreate: true,
                      noEdit: true,
                      noDelete: true,
                      fieldName: 'items',
                      containerClass: 'pa-0',
                      childSchema: InvoiceISSItemsSchema,
                    },
                  ],
                },
                {
                  label: 'Conferência de Impostos',
                  children: [
                    {
                      component: 'dynamic-child-schema',
                      fieldName: 'items',
                      containerClass: 'pa-0',
                      noCheck: true,
                      noEdit: true,
                      noDelete: true,
                      noCreate: true,
                      noActions: true,
                      childSchema: InvoiceISSItemTaxSchema,
                    },
                  ],
                },
                {
                  label: 'Descrição dos Serviços',
                  children: [
                    {
                      component: 'dynamic-form',
                      fieldName: 'items',
                      containerClass: 'pa-0',
                      fields: [...InvoiceISSDetailsSchema.fields],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      component: 'slot',
      name: 'nf-totals',
    },
    {
      label: 'CÁLCULO DOS IMPOSTOS',
      component: 'dynamic-divider',
      class: 'mb-0 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          fields: [...TaxesFields],
        },
      ],
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabInvoiceISS,
    },
    {
      icon: ICON_ERROR,
      title: 'Eventos de transmissão',
      tabContent: TabInvoiceISSEvents,
    },
    {
      icon: ICON_TAXES,
      title: 'Impostos',
      tabContent: TabInvoiceISSTaxes,
    },
  ],
};
