import PartialPurchasesSchema from '@schemas/PartialPurchase';

const ListPartialPurchases = () => import(/* webpackChunkName: "list-partial-purchases" */'./ListPartialPurchases');
const DetailPartialPurchases = () => import(/* webpackChunkName: "detail-partial-purchases" */'./DetailPartialPurchase');

export default [
  {
    path: PartialPurchasesSchema.routes.list.path,
    name: PartialPurchasesSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: PartialPurchasesSchema.titles.items },
      ],
    },
    component: ListPartialPurchases,
  },
  {
    path: PartialPurchasesSchema.routes.create.path,
    name: PartialPurchasesSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: PartialPurchasesSchema.titles.items, route: PartialPurchasesSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailPartialPurchases,
  },
  {
    path: PartialPurchasesSchema.routes.edit.path,
    name: PartialPurchasesSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: PartialPurchasesSchema.titles.items, route: PartialPurchasesSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailPartialPurchases,
  },
];
