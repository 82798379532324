import DashboardRoutes from './pages/dashboard/routes';
import AssignorsRoutes from './pages/assignors/routes';
import AvailableInvoicesRoutes from './pages/availableinvoices/routes';
import DraweesRoutes from './pages/drawees/routes';

export default [
  ...DashboardRoutes,
  ...AssignorsRoutes,
  ...AvailableInvoicesRoutes,
  ...DraweesRoutes,
];
