<template>
  <div :id="item.id">
    <GenericCardPanel
      title="NFC-e"
      :fields="nfceInfoFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabInvoiceTemplateNFCe',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      nfceInfoFields: [],
    };
  },
  mounted() {
    this.nfceInfoFields = [
      { label: 'ID Token', value: this.item.nfce.id_token ? this.item.nfce.id_token : 'Não Cadastrado', col: 6 },
      { label: 'CSC do Token', value: this.item.nfce.csc_token ? this.item.nfce.csc_token : 'Não Cadastrado', col: 6 },
    ];
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style scoped></style>
