import { ICON_INVOICE } from '@constants/icons';
import ManifestInvoiceBusiness from '@businesses/ManifestInvoice';
import { OPTIONS_UF } from '@constants/options';

export default {
  service: 'Manifest',
  business: ManifestInvoiceBusiness,
  icon: ICON_INVOICE,
  routes: {
    list: {
      path: '/supply/manifest-list',
      name: 'supply-manifest-list',
    },
    create: {
      path: '/supply/manifest/create',
      name: 'supply-manifest-create',
    },
    edit: {
      path: '/supply/manifest/edit/:id',
      name: 'supply-manifest-edit',
    },
  },
  titles: {
    item: 'NFe Manifesto',
    items: 'NFe Manifesto',
    edit: 'Editar registro',
    new: 'Adicionar NF-e',
    create: 'Adicionar NF-e',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'NF-e *',
      name: 'invoice_id',
      formattedName: 'invoice_number',
      type: 'number',
      component: 'dynamic-autocomplete',
      itemText: 'invoice_number',
      itemValue: 'id',
      list: true,
      md: 9,
      required: true,
      dataSource: {
        service: 'Invoices',
        method: 'getByFilter',
        props: [{ status_eq: 'authorized' }],
      },
    },
    {
      label: 'Série *',
      name: 'series',
      formattedName: 'series',
      type: 'text',
      component: 'dynamic-text-field',
      itemText: 'to_s',
      itemValue: 'id',
      list: true,
      md: 3,
      disabled: true,
    },
    {
      label: 'Chave de Acesso *',
      name: 'invoice_key',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 12,
      disabled: true,
    },
    {
      label: 'Data Emissão *',
      name: 'invoice_emission_date',
      formattedName: 'invoice_emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      list: true,
      md: 3,
      disabled: true,
    },
    {
      label: 'Estado *',
      name: 'invoice_uf',
      formattedName: 'invoice_uf',
      type: 'text',
      component: 'dynamic-select',
      items: OPTIONS_UF,
      cols: 4,
      list: true,
      disabled: true,
    },
    {
      label: 'Cliente *',
      name: 'client',
      formattedName: 'client',
      type: 'text',
      component: 'dynamic-text-field',
      itemText: 'to_s',
      itemValue: 'id',
      list: true,
      md: 5,
      disabled: true,
    },
    {
      label: 'Valor Total *',
      name: 'invoice_total_value',
      formattedName: 'invoice_total_value_formatted',
      type: 'text',
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      cols: 4,
      disabled: true,
      list: true,
    },
  ],
};
