import { ICON_PRODUCT } from '@constants/icons';

export default {
  service: 'AvailableInvoice',
  icon: ICON_PRODUCT,
  routes: {
    list: {
      path: '/anticipation/availableinvoices',
      name: 'anticipation-availableinvoices-list',
    },
    create: '',
    edit: '',
  },
  titles: {
    item: 'iCertus Antecipa',
    items: 'iCertus Antecipa',
    edit: '',
    create: '',
    new: '',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: '', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: '#',
      name: 'id',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Empresa/CNPJ',
      name: 'cnpj',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Valor Faturado',
      name: 'value1',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Valor Antecipado',
      name: 'value2',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Classificação Certus',
      name: 'certus',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
  ],
};
