import OrderProductionSchema from '@schemas/OrderProduction';

const ListOrderProduction = () => import(/* webpackChunkName: "list-order-production" */'./ListOrderProduction');
const DetailOrderProduction = () => import(/* webpackChunkName: "detail-order-production" */'./DetailOrderProduction');

export default [
  {
    path: OrderProductionSchema.routes.list.path,
    name: OrderProductionSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Produção' },
        { name: 'Ordens de Produção', route: OrderProductionSchema.routes.list.name },
        { name: 'Listar' },
      ],
    },
    component: ListOrderProduction,
  },
  {
    path: OrderProductionSchema.routes.create.path,
    name: OrderProductionSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Produção' },
        { name: 'Ordens de Produção', route: OrderProductionSchema.routes.list.name },
        { name: 'Criar Ordem de Produção', route: OrderProductionSchema.routes.list.name },
      ],
    },
    component: DetailOrderProduction,
  },
  {
    path: OrderProductionSchema.routes.edit.path,
    name: OrderProductionSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'products',
      breadcrumb: [
        { name: 'Produção' },
        { name: 'Ordens de Produção', route: OrderProductionSchema.routes.list.name },
        { name: 'Editar Ordem de Produção', route: OrderProductionSchema.routes.list.name },
      ],
    },
    component: DetailOrderProduction,
  },
];
