<template>
  <v-menu v-model="menu"
          :disabled="disabled"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          full-width
          bottom
          right
          min-width="0">
    <template v-slot:activator="{ on }">
      <v-text-field
        slot="activator"
        v-model="formatted"
        :label="label"
        :required="required"
        :disabled="disabled"
        :hint="hint"
        :persistent-hint="persistentHint"
        :solo="solo"
        :class="[textInputClass, large ? 'dm-large-inline-calendar' : '', hideLine ? 'dm-hide-line' : '']"
        :hide-details="hideDetails"
        :single-line="singleLine"
        :prepend-icon="prependIcon"
        :append-icon="appendIcon"
        :placeholder="placeholder"
        :rules="rules"
        :clearable="clearable"
        :clear-icon="icons.close"
        readonly
        @click:clear="clearDate"
        v-on="on" />
    </template>
    <v-date-picker
      v-model="localDate"
      :picker-date="pickerDate"
      :actions="false"
      :allowed-dates="checkAllowedDates"
      :type="type"
      :year-icon="icons.calendarBlank"
      :prev-icon="icons.skipPrevious"
      :next-icon="icons.skipNext"
      :show-adjacent-months="true"
      scrollable
      locale="pt-BR"
      @input="changeSelectedDate">
      <v-spacer v-if="clearable" />
      <v-btn v-if="clearable" text color="primary" @click="clearDate">
        Limpar
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import {
  ICON_DELETE, ICON_CALENDAR_BLANK, ICON_SKIP_NEXT, ICON_SKIP_PREVIOUS,
} from '@constants/icons';

export default {
  name: 'DmInlineCalendar',
  props: {
    date: {
      type: [Date, Object, String],
    },
    type: {
      type: String,
      default: 'date',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    textInputClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    allowed: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    displayFormat: {
      type: String,
      default: 'DD/MM/YYYY',
    },
    pickerDate: {
      type: String,
      default: undefined,
    },
    large: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: undefined,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    hideLine: {
      type: Boolean,
      default: false,
    },
    returnAsMoment: {
      type: Boolean,
      default: false,
    },
    defaultDate: {
      type: String,
      default: '',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      localDate: '',
      formatted: '',
      icons: {
        calendarBlank: ICON_CALENDAR_BLANK,
        close: ICON_DELETE,
        skipNext: ICON_SKIP_NEXT,
        skipPrevious: ICON_SKIP_PREVIOUS,
      },
    };
  },
  computed: {
    allowedDates() {
      if (!this.allowed) {
        return null;
      }

      return this.allowed.map((date) => date.format('YYYY-MM-DD'));
    },
  },
  watch: {
    date: {
      handler: 'updateDate',
      immediate: true,
    },
    defaultDate: {
      handler: 'updateDefaultDate',
      immediate: true,
    },
  },
  methods: {
    updateDefaultDate(defaultDate) {
      if (this.date || !defaultDate) return;
      let dynamicDate;
      if (defaultDate === 'currentMonth') {
        dynamicDate = moment().startOf('month').toDate();
      } else if (defaultDate === 'lastMonth') {
        dynamicDate = moment().startOf('month').subtract(1, 'month').toDate();
      }
      if (dynamicDate) {
        this.changeSelectedDate(dynamicDate);
      }
    },
    updateDate(date) {
      if (date instanceof Date) {
        this.localDate = moment(date).format('YYYY-MM-DD');
      } else if (!_.isEmpty(date) && date.format) {
        this.localDate = date.format('YYYY-MM-DD');
      } else if (!_.isEmpty(date)) {
        this.localDate = String(date);
      } else {
        this.localDate = '';
      }

      this.updateformatted();
    },
    clearDate() {
      this.localDate = '';
      this.changeSelectedDate(this.localDate);
    },
    changeSelectedDate(date) {
      this.menu = false;
      this.updateformatted();
      if (date === '') {
        this.$emit('update:date', null);
        return;
      }
      const returnDate = moment(date, 'YYYY-MM-DD');
      if (this.returnAsMoment) {
        this.$emit('update:date', returnDate);
      } else {
        this.$emit('update:date', returnDate.toDate());
      }
    },
    updateformatted() {
      if (this.localDate === '') {
        this.formatted = '';
      } else if (this.type === 'month') {
        this.formatted = moment(this.localDate, 'YYYY-MM-DD').format('MMMM YYYY');
      } else {
        this.formatted = moment(this.localDate, 'YYYY-MM-DD').format(this.displayFormat);
      }
    },
    clearInput() {
      this.localDate = '';
      this.updateformatted();
    },
    checkAllowedDates(date) {
      if (this.allowedDates) {
        return this.allowedDates.includes(date);
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.dm-large-inline-calendar {
  height: 44px;
  padding: 0px;
  .v-input-group__input {
    input {
      height: 40px;
      font-size: 18px;
    }
  }
}
</style>
