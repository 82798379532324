// import config from '@src/core/config';
import { formatCurrency } from '@plugins/app-utils';

const endpoint = 'das';

const formatResponse = (items) => {
  items.periods.forEach((period, i) => {
    period.total_formatted = formatCurrency(period.total);
    period.fines_formatted = formatCurrency(period.fines);
    period.fees_formatted = formatCurrency(period.fees);
    period.icms_formatted = formatCurrency(period.icms);
    period.iss_formatted = formatCurrency(period.iss);
    period.inss_formatted = formatCurrency(period.inss);
    period.id = i + 1;
  });
  return items;
};

const DasGuides = ($axios) => ({
  async index(year) {
    const { data } = await $axios.get(`${endpoint}/list/${year}`);
    if (data.message) {
      return data;
    }
    return formatResponse(data);
  },
});

const DasGuidesCommands = ($axios) => ({
  async emitDas(payload) {
    const { number, due_date } = payload;
    await $axios.get(`${endpoint}/emit/${number}/${due_date}`);
  },
});

export {
  DasGuides, DasGuidesCommands,
};
export default {
  DasGuides, DasGuidesCommands,
};
