import config from '@src/core/config';
import * as AppUtils from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';

const formatRequest = (item) => {
  item.start_date = item.start_date.toISOString();
  item.end_date = item.end_date.toISOString();
};

const formatResponse = (item) => {
  item.date_formatted = AppUtils.formatDate(item.date);

  item.start_date_formatted = AppUtils.formatDate(item.start_date);
  item.end_date_formatted = AppUtils.formatDate(item.end_date);

  item.type_formatted = AppUtils.getOptionText('cashflows_type', item.type);
  item.is_summarized_formatted = item.is_summarized ? 'Sim' : 'Não';

  item.items = item.items || [];
  item.items.map((launch) => {
    launch.date_formatted = AppUtils.formatDate(launch.date);
    launch.value_entry_formatted = AppUtils.formatCurrency(launch.value_entry);
    launch.value_exit_formatted = AppUtils.formatCurrency(launch.value_exit);
    launch.balance_formatted = AppUtils.formatCurrency(launch.balance);
    launch.partial_formatted = AppUtils.formatCurrency(launch.partial);
  });
};

const CashFlows = DynamicService('cashflows', {
  formatRequest,
  formatResponse,
  baseURL: config.API_GATEWAY_FINANCIAL,
});

const CashFlowsSearch = DynamicService('cashflows/search', {
  baseURL: config.API_GATEWAY_FINANCIAL,
});

const CashFlowsCommands = ($axios) => ({
  async getBalance(payload) {
    const res = await $axios.post(`${config.API_GATEWAY_FINANCIAL}/cashflows/balance.json`, payload);
    return res;
  },
});

export {
  CashFlows,
  CashFlowsSearch,
  CashFlowsCommands,
};

export default {
  CashFlows,
  CashFlowsSearch,
  CashFlowsCommands,
};
