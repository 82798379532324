const ListHelp = () => import(/* webpackChunkName: "list-help" */'./ListHelp');

export default [
  {
    path: '/settings/help',
    name: 'settings-help-list',
    meta: {
      requiresAuth: true,
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Configurações' },
        { name: 'Ajuda' },
      ],
    },
    component: ListHelp,
  },
];
