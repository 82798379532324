<template>
  <div class="mx-auto my-2">
    <span v-if="label || field.label" :class="'text-'+ size +'-'+ propTag +' '+ color">
      {{ label || field.label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'DynamicTitle',
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    dense: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    propTag: {
      type: String,
      default: 'h1',
    },
    color: {
      type: String,
      default: 'indigo--text text--lighten-1',
    },
  },
  data() {
    return {
    };
  },
};
</script>
