<template>
  <div :id="item.id">
    <GenericCardPanel title="EVENTOS DE TRANSMISSÃO">
      <div v-if="events.length > 0">
        <v-row class="mx-2">
          <v-col cols="12">
            <template v-for="(event, index) in events">
              <span :key="index">{{ event }}</span><br :key="index">
            </template>
          </v-col>
        </v-row>
      </div>
      <div v-else class="mx-4 my-2 indigo--text text--darken-2">
        Nenhum evento de transmissão foi encontrado
      </div>
    </GenericCardPanel>
  </div>
</template>
<script>
import * as AppUtils from '@plugins/app-utils';
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabInvoiceISSErrors',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      events: [],
    };
  },
  async mounted() {
    this.getEvents();
  },
  methods: {
    stripHTML(value) {
      return AppUtils.stripHTML(value);
    },
    async getEvents() {
      await this.$api.InvoiceISSCommands.getEvents(this.item.id).then((response) => {
        this.events = response.events;
      });
    },
  },
};
</script>
