const ListAccountsPayments = () => import(/* webpackChunkName: "list-accounts-payments" */'./pay/ListAccountsPayments');
const CreateAccountPayment = () => import(/* webpackChunkName: "create-account-payment" */'./pay/CreateAccountPayment');
const EditAccountPayment = () => import(/* webpackChunkName: "edit-account-payment" */'./pay/EditAccountPayment');
const CloneAccountPayment = () => import(/* webpackChunkName: "clone-account-payment" */'./pay/CloneAccountPayment');
const ListAccountsReceive = () => import(/* webpackChunkName: "list-accounts-receive" */'./receive/ListAccountsReceive');
const CreateAccountReceive = () => import(/* webpackChunkName: "create-account-receive" */'./receive/CreateAccountReceive');
const EditAccountReceive = () => import(/* webpackChunkName: "edit-account-receive" */'./receive/EditAccountReceive');
const CloneAccountReceive = () => import(/* webpackChunkName: "clone-account-receive" */'./receive/CloneAccountReceive');

export default [
  {
    path: '/financial/accountpayments',
    name: 'financial-accountpayments-list',
    meta: {
      requiresAuth: true,
      rule: 'account_payments',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Contas a Pagar' },
      ],
    },
    component: ListAccountsPayments,
  },
  {
    path: '/financial/accountpayments/create',
    name: 'financial-accountpayments-create',
    meta: {
      requiresAuth: true,
      rule: 'account_payments',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Contas a Pagar', link: '/financial/accountpayments' },
        { name: 'Criar Conta' },
      ],
    },
    component: CreateAccountPayment,
  },
  {
    path: '/financial/accountpayments/edit/:id',
    name: 'financial-accountpayments-edit',
    meta: {
      requiresAuth: true,
      rule: 'account_payments',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Contas a Pagar', link: '/financial/accountpayments' },
        { name: 'Editar Conta' },
      ],
    },
    component: EditAccountPayment,
  },
  {
    // to simulate a create redirect
    path: '/financial/accountpayments/:id/clone',
    name: 'financial-accountpayments-clone',
    meta: {
      requiresAuth: true,
      rule: 'account_payments',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Contas a Pagar', link: '/financial/accountpayments' },
        { name: 'Criar Conta' },
      ],
    },
    component: CloneAccountPayment,
  },
  {
    path: '/financial/accountreceivables',
    name: 'financial-accountreceivables-list',
    meta: {
      rule: 'account_receivables',
      requiresAuth: true,
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Contas a Receber' },
      ],
    },
    component: ListAccountsReceive,
  },
  {
    path: '/financial/accountreceivables/create',
    name: 'financial-accountreceivables-create',
    meta: {
      requiresAuth: true,
      rule: 'account_receivables',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Contas a Receber', link: '/financial/accountreceivables' },
        { name: 'Criar Conta' },
      ],
    },
    component: CreateAccountReceive,
  },
  {
    path: '/financial/accountreceivables/edit/:id',
    name: 'financial-accountreceivables-edit',
    meta: {
      requiresAuth: true,
      rule: 'account_receivables',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Contas a Receber', link: '/financial/accountreceivables' },
        { name: 'Editar Conta' },
      ],
    },
    component: EditAccountReceive,
  },
  {
    path: '/financial/accountreceivables/:id/clone',
    name: 'financial-accountreceivables-clone',
    meta: {
      requiresAuth: true,
      rule: 'account_receivables',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Contas a Receber', link: '/financial/accountreceivables' },
        { name: 'Criar Conta' },
      ],
    },
    component: CloneAccountReceive,
  },
];
