import { formatNumber } from '@plugins/app-utils';

const endpoint = 'working_capital';

const formatAssignorsResponse = (data) => {
  const approvedTotalValueFormatted = formatNumber(parseFloat(data.approved_total_value), 2);
  const installmentValueFormatted = formatNumber(parseFloat(data.installment_value), 2);

  return {
    ...data,
    approved_total_value_formatted: approvedTotalValueFormatted,
    installment_value_formatted: installmentValueFormatted,
  };
};

const WorkingCapitalCommands = ($axios) => ({
  async getWorkingCapital(companyId) {
    if (!companyId) return;
    const { data } = await $axios.get(`${endpoint}/companies/${companyId}`);
    const formattedData = formatAssignorsResponse(data);
    return formattedData;
  },
});

export { WorkingCapitalCommands };
export default { WorkingCapitalCommands };
