import Vue from 'vue';
import * as AppUtils from '@plugins/app-utils';
import { DefaultScreenEvents } from './OrderProduction/DefaultScreenEvents';
import { validateHour } from './OrderProductionFactory';

const getStockQuantityInfo = async (formValue) => {
  if (formValue.product_id && formValue.stock_id) {
    const response = await Vue.prototype.$api.OrderProductionCommands.getQuantity({ id: formValue.product_id, stock_id: formValue.stock_id });
    if (response.data) {
      Vue.set(formValue, 'stock_quantity', response.data.quantity_available);
    } else {
      Vue.prototype.$notifyError('Erro ao tentar buscar informações da quantidade em estoque, tente novamente');
    }
  }
};

function updateCompositionsToProduce(compositions, ordered_quantity) {
  return compositions.map((composition) => {
    const { id } = composition;
    return {
      ...composition,
      suggested_quantity: id ? ordered_quantity * composition.ordered_quantity : 0,
    };
  });
}

function formatCompositionsToOrderProduction(compositions, ordered_quantity) {
  const compositionsFormatted = compositions.map((composition) => {
    const returnedQuantity = parseFloat(composition.returned_quantity ?? 0);
    const lostQuantity = parseFloat(composition.lost_quantity ?? 0);
    const requiredQuantity = parseFloat(composition.required_quantity ?? 0);
    let compositionQuantity = composition.quantity ?? composition.ordered_quantity;
    let orderedQuantity = ordered_quantity ? parseFloat(ordered_quantity) * parseFloat(compositionQuantity) : compositionQuantity;
    if (!composition.id) {
      compositionQuantity = 0;
      orderedQuantity = 0;
    }

    const total_quantity = (orderedQuantity - returnedQuantity + requiredQuantity - lostQuantity);

    return {
      ...composition,
      unity: composition.product.unity,
      unity_id: composition.product.unity_id,
      ordered_quantity: compositionQuantity,
      ordered_quantity_formatted: AppUtils.formatNumber(compositionQuantity),
      suggested_quantity: orderedQuantity,
      suggested_quantity_formatted: AppUtils.formatNumber(orderedQuantity),
      lost_quantity: lostQuantity,
      lost_quantity_formatted: AppUtils.formatNumber(lostQuantity),
      returned_quantity: returnedQuantity,
      returned_quantity_formatted: AppUtils.formatNumber(returnedQuantity),
      required_quantity: requiredQuantity,
      required_quantity_formatted: AppUtils.formatNumber(requiredQuantity),
      total_quantity,
      total_quantity_formatted: AppUtils.formatNumber(total_quantity),
    };
  });
  return compositionsFormatted;
}

function formatProceduresToOrderProduction(procedures = [], ordered_quantity = 0) {
  return procedures.map((procedure) => {
    const timeProcessInMinutes = AppUtils.convertHourStringToMinutes(procedure.time_process);
    const timeSetupInMinutes = AppUtils.convertHourStringToMinutes(procedure.time_setup);
    const timeMachineInMinutes = AppUtils.convertHourStringToMinutes(procedure.time_machine);

    const timeSetupSuggestedInMinutes = timeSetupInMinutes * ordered_quantity;
    const timeProcessSuggestedInMinutes = timeProcessInMinutes * ordered_quantity;
    const timeMachineSuggestedInMinutes = timeMachineInMinutes * ordered_quantity;
    const suggestedTimeTotal = timeSetupSuggestedInMinutes + timeProcessSuggestedInMinutes + timeMachineSuggestedInMinutes;
    return {
      ...procedure,
      time_process_formatted: procedure.time_process,
      time_machine_formatted: procedure.time_machine,
      time_setup_formatted: procedure.time_setup,
      time_total_formatted: procedure.time_total,
      suggested_time_process: AppUtils.convertMinutesToHourString(timeProcessSuggestedInMinutes),
      suggested_time_machine: AppUtils.convertMinutesToHourString(timeMachineSuggestedInMinutes),
      suggested_time_setup: AppUtils.convertMinutesToHourString(timeSetupSuggestedInMinutes),
      suggested_time_total: AppUtils.convertMinutesToHourString(suggestedTimeTotal),
      used_time_process: procedure.used_time_process ?? '00:00',
      used_time_machine: procedure.used_time_machine ?? '00:00',
      used_time_setup: procedure.used_time_setup ?? '00:00',
      used_time_total: procedure.used_time_total ?? '00:00',
      suggested_time_process_formatted: AppUtils.convertMinutesToHourString(timeProcessSuggestedInMinutes),
      suggested_time_machine_formatted: AppUtils.convertMinutesToHourString(timeMachineSuggestedInMinutes),
      suggested_time_setup_formatted: AppUtils.convertMinutesToHourString(timeSetupSuggestedInMinutes),
      suggested_time_total_formatted: AppUtils.convertMinutesToHourString(suggestedTimeTotal),
      used_time_process_formatted: procedure.used_time_process ?? '00:00',
      used_time_machine_formatted: procedure.used_time_machine ?? '00:00',
      used_time_setup_formatted: procedure.used_time_setup ?? '00:00',
      used_time_total_formatted: procedure.used_time_total ?? '00:00',
    };
  });
}
export const getStatus = (formValue) => formValue.status;

function checkDateFieldsIsEnabled(formValue) {
  const action = formValue.id ? 'update' : 'create';
  // Setted initial date and time
  const currentDate = new Date();
  if (action === 'create') {
    formValue.emission_date = currentDate;
    formValue.emission_time = AppUtils.getCurrentHours(currentDate);
    formValue.forecast_date = currentDate;
    formValue.forecast_time = AppUtils.getCurrentHours(currentDate);
    formValue.start_date = currentDate;
    formValue.start_time = AppUtils.getCurrentHours(currentDate);
    formValue.end_date = currentDate;
    formValue.end_time = AppUtils.getCurrentHours(currentDate);
  }

  if (formValue.is_enabled_forecast === false) {
    formValue.forecast_date.disabled = true;
    formValue.forecast_time.disabled = true;
  }

  if (formValue.is_enabled_start === false) {
    formValue.start_date.disabled = true;
    formValue.start_time.disabled = true;
  }
  if (formValue.is_enabled_end === false) {
    formValue.end_date.disabled = true;
    formValue.end_time.disabled = true;
  }
  if (formValue.is_consumed_feedstock === true) {
    formValue.is_consumed_feedstock.disabled = true;
  }
}

async function fetchProductCompositionInfo(productId, ordered_quantity) {
  const response = await Vue.prototype.$api.Compositions.show(productId);
  const { compositions, procedures } = response.data;
  const formmatedCompositions = formatCompositionsToOrderProduction(compositions, ordered_quantity ?? null);
  const formattedProcedures = formatProceduresToOrderProduction(procedures, ordered_quantity ?? 0);
  return { compositions: formmatedCompositions, procedures: formattedProcedures };
}

const setProduct = async (formValue, fieldValue) => {
  await Vue.prototype.$api.Products.showGraphql(fieldValue, ['id', 'to_s', 'code', 'unity_id', 'ncm_id', 'cest_id', 'purchase_cost', 'sale_value']).then(async (response) => {
    const product = response.data;

    if (product) {
      formValue.product = product;
      formValue.name = product.to_s;
      formValue.product_code = product.id;
      formValue.product_id = product.id;
      Vue.set(formValue, 'product_code', product.id);
      const { compositions, procedures } = await fetchProductCompositionInfo(product.id, formValue.ordered_quantity);
      formValue.compositions = compositions;
      formValue.procedures = procedures;
      Vue.set(formValue, 'compositions', formValue.compositions);
      Vue.set(formValue, 'procedures', formValue.procedures);
      getStockQuantityInfo(formValue);
    }
  });
};

export default {
  screenEvents: {
    ...DefaultScreenEvents,
    async beforeCreate(formValue) {
      // Setted initial empty composition
      formValue.compositions = [];

      checkDateFieldsIsEnabled(formValue);

      if (formValue.product_id) {
        const response = await Vue.prototype.$api.OrderProductionCommands.getQuantity({ id: formValue.product_id, stock_id: formValue.stock_id });
        if (response.data) {
          Vue.set(formValue, 'stock_quantity', response.data.quantity_available);
        } else {
          Vue.prototype.$notifyError('Erro ao tentar buscar informações da quantidade em estoque, tente novamente');
        }
      }

      const stockResponse = await Vue.prototype.$api.Stocks.getByFilter({ use_main_eq: true });
      if (stockResponse.data[0]) {
        const mainStock = stockResponse.data[0];
        formValue.stock_id = mainStock.id;
        Vue.set(formValue, 'stock', mainStock);
        Vue.set(formValue, 'stock_id', mainStock.id);
      }
    },
    async beforeUpdate(formValue) {
      const disableIfFinalized = true;

      const isDisabledForecastDateTime = formValue.is_enabled_forecast;
      const isDisabledStartDateTime = formValue.is_enabled_start;
      const isDisabledEndDateTime = formValue.is_enabled_end;

      const isDisabledConsumedFeedstock = formValue.is_consumed_feedstock;
      const disableCheckBoxFinal = formValue.status === 'pending';

      return {
        fields: [
          { label: 'Produto', disabled: !disableIfFinalized },
          { label: 'Data Previsão', disabled: !isDisabledForecastDateTime },
          { label: 'Hora Previsão', disabled: !isDisabledForecastDateTime },
          { label: 'Data Início', disabled: !isDisabledStartDateTime },
          { label: 'Hora Início', disabled: !isDisabledStartDateTime },
          { label: 'Data Final', disabled: !isDisabledEndDateTime },
          { label: 'Hora Final', disabled: !isDisabledEndDateTime },
          { label: 'Saída de Mat. Prima do Estoque', disabled: isDisabledConsumedFeedstock },
          { label: 'Final', disabled: disableCheckBoxFinal },
        ],
      };
    },
  },
  fieldEvents: {
    product_code: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    product_id: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    stock_id: {
      change: async (formValue) => {
        if (formValue.product_id) {
          const { data } = await Vue.prototype.$api.Stocks.showGraphql(formValue.stock_id, ['id', 'to_s']);
          formValue.stock = { id: data.id, to_s: data.to_s };
          getStockQuantityInfo(formValue);
        }
      },
    },
    is_enabled_forecast: {
      change: (formValue, fieldValue) => {
        const currentDate = new Date();
        formValue.forecast_date = currentDate;
        formValue.forecast_time = AppUtils.getCurrentHours(currentDate);
        return { fieldAttributes: { disabled: !fieldValue }, fieldNames: ['forecast_date', 'forecast_time'] };
      },
    },
    is_enabled_start: {
      change: (formValue, fieldValue) => {
        const currentDate = new Date();
        formValue.start_date = currentDate;
        formValue.start_time = AppUtils.getCurrentHours(currentDate);
        return { fieldAttributes: { disabled: !fieldValue }, fieldNames: ['start_date', 'start_time'] };
      },
    },
    is_enabled_end: {
      change: (formValue, fieldValue) => {
        const currentDate = new Date();
        formValue.end_date = currentDate;
        formValue.end_time = AppUtils.getCurrentHours(currentDate);
        return { fieldAttributes: { disabled: !fieldValue }, fieldNames: ['end_date', 'end_time'] };
      },
    },
    is_entered_stock: {
      change: (formValue, fieldValue) => {
        if (formValue.is_entered_stock === false) {
          formValue.produced_quantity = 0;
          return { fieldAttributes: { disabled: !fieldValue }, fieldNames: ['produced_quantity'] };
        }
        return { fieldAttributes: { disabled: !fieldValue }, fieldNames: ['produced_quantity'] };
      },
    },
    compositions: {
      beforeCreate: async (formValue, newItem) => {
        newItem.stock_id = formValue.stock_id;
        if (!formValue.product_id) return 'Não é possível adicionar ítens sem selecionar um produto';
        return {
          mutateChildSchema: {
            fields: [
              {
                name: 'product_id',
                dataSource: {
                  service: 'Products',
                  method: 'getByFilterGraphql',
                  props: [{ use_inactive_eq: false, id_not_eq: formValue.product_id, query: ['id', 'code', 'to_s'] }, 'name asc'],
                },
              },
              {
                name: 'product_code',
                dataSource: {
                  service: 'Products',
                  method: 'getByFilterGraphql',
                  props: [{ use_inactive_eq: false, id_not_eq: formValue.product_id, query: ['id', 'code', 'to_s'] }, 'name asc'],
                },

              },
            ],
          },
        };
      },
      beforeUpdate: async (formValue) => ({
        mutateChildSchema: {
          fields: [
            {
              name: 'product_id',
              dataSource: {
                service: 'Products',
                method: 'getByFilterGraphql',
                props: [{ use_inactive_eq: false, id_not_eq: formValue.product_id, query: ['id', 'code', 'to_s'] }, 'name asc'],
              },
            },
            {
              name: 'product_code',
              dataSource: {
                service: 'Products',
                method: 'getByFilterGraphql',
                props: [{ use_inactive_eq: false, id_not_eq: formValue.product_id, query: ['id', 'code', 'to_s'] }, 'name asc'],
              },
            },
          ],
        },
      }),
      change: async (formValue) => {
        const { compositions } = formValue;
        formValue.compositions = formatCompositionsToOrderProduction(compositions, formValue.ordered_quantity);
      },
    },
    procedures: {
      beforeUpdate: async (formValue) => {
        const procedures = formValue.procedures.map((procedure) => ({
          ...procedure,
          time_process: validateHour(procedure.time_process) ? procedure.time_process : AppUtils.convertMinutesToHourString(procedure.time_process),
          time_machine: validateHour(procedure.time_machine) ? procedure.time_machine : AppUtils.convertMinutesToHourString(procedure.time_machine),
          time_setup: validateHour(procedure.time_setup) ? procedure.time_setup : AppUtils.convertMinutesToHourString(procedure.time_setup),
          time_total: validateHour(procedure.time_total) ? procedure.time_total : AppUtils.convertMinutesToHourString(procedure.time_total),
          suggested_time_process: validateHour(procedure.suggested_time_process) ? procedure.suggested_time_process : AppUtils.convertMinutesToHourString(procedure.suggested_time_process),
          suggested_time_machine: validateHour(procedure.suggested_time_machine) ? procedure.suggested_time_machine : AppUtils.convertMinutesToHourString(procedure.suggested_time_machine),
          suggested_time_setup: validateHour(procedure.suggested_time_setup) ? procedure.suggested_time_setup : AppUtils.convertMinutesToHourString(procedure.suggested_time_setup),
          suggested_time_total: validateHour(procedure.suggested_time_total) ? procedure.suggested_time_total : AppUtils.convertMinutesToHourString(procedure.suggested_time_total),
          used_time_setup: validateHour(procedure.used_time_setup) ? procedure.used_time_setup : AppUtils.convertMinutesToHourString(procedure.used_time_setup),
          used_time_process: validateHour(procedure.used_time_process) ? procedure.used_time_process : AppUtils.convertMinutesToHourString(procedure.used_time_process),
          used_time_machine: validateHour(procedure.used_time_machine) ? procedure.used_time_machine : AppUtils.convertMinutesToHourString(procedure.used_time_machine),
          used_time_total: validateHour(procedure.used_time_total) ? procedure.used_time_total : AppUtils.convertMinutesToHourString(procedure.used_time_total),
        }));
        const calculatedProcedures = formatProceduresToOrderProduction(procedures, formValue.ordered_quantity);
        formValue.procedures = calculatedProcedures;
        Vue.set(formValue, 'procedures', calculatedProcedures);
      },
      change: async (formValue) => {
        formValue.forecast_datetime = formValue.forecast_datetime ?? formValue.forecast_datetime_temp;
        const { procedures, ordered_quantity } = formValue;
        const calculatedProcedures = formatProceduresToOrderProduction(procedures, ordered_quantity);
        formValue.procedures = calculatedProcedures;
        Vue.set(formValue, 'procedures', formValue.procedures);
      },
    },
    ordered_quantity: {
      change: (formValue, fieldValue) => {
        formValue.compositions = updateCompositionsToProduce(formValue.compositions, fieldValue);
        formValue.compositions = formatCompositionsToOrderProduction(formValue.compositions, fieldValue);
        formValue.procedures = formatProceduresToOrderProduction(formValue.procedures, fieldValue);

        if (formValue.ordered_quantity < formValue.lost_quantity) return 'A quantidade a produzir não pode ser menor que a quandidade de perda!';
        const produced_quantity = formValue.ordered_quantity - (formValue.lost_quantity ?? 0);
        formValue.produced_quantity = produced_quantity;
      },
    },
    lost_quantity: {
      change: (formValue) => {
        if (formValue.ordered_quantity < formValue.lost_quantity) return 'A quantidade a produzir não pode ser menor que a quandidade de perda!';
        formValue.produced_quantity = formValue.ordered_quantity - (formValue.lost_quantity ?? 0);
      },
    },
  },
};
