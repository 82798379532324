import Vue from 'vue';
/* eslint-disable no-unused-vars */
import { Vuelidate } from 'vuelidate';
import {
  ValidationObserver,
} from 'vee-validate';
import babelPolyFill from 'babel-polyfill';
import Filters from '@filters/filters';
import money from 'v-money';
import App from './app.vue';
import router from './core/router';
// import { Acl } from 'vue-acl';
import acl from './core/acl';
import store from './core/store';
import './packs/plugin/composition-api';
import { pluginApi, provideApi } from './modules/api';
import Printer from './packs/plugin/printer';
import vuetify from './packs/plugin/vuetify';
import DownloadXML from './packs/plugin/download-xml';
import DownloadFile from './packs/plugin/download-file';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Loading from './packs/plugin/loading/loading';
import DynamicDialog from './packs/plugin/dynamicDialog/dynamicDialog.js';
import Confirm from './packs/plugin/confirm/confirm.js';
import Reports from '@modules/reports/reportPlugin.js';
import VueShortKey from 'vue-shortkey';

import MaintenanceLayout from '@modules/main/layouts/MaintenanceLayout';
import Config from '@src/core/config';
// import Delete from './packs/plugin/confirm/delete.js';

Vue.component('ValidationObserver', ValidationObserver);
Vue.use(Vuelidate);
Vue.use(money, { precision: 2 });
Vue.use(Loading);
Vue.use(DynamicDialog);
Vue.use(Confirm);
Vue.use(VueShortKey);
Vue.use(Reports);
// Vue.use(Delete);

// Vue.use(Acl, { router, init: 'any' });

// compatibility with old vue 2.x not composable api
Vue.use(pluginApi);
Vue.config.productionTip = false;

Filters.install(Vue);

const optionsHtmlToPaper = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
};
Vue.use(Printer, optionsHtmlToPaper);
Vue.use(DownloadXML);
Vue.use(DownloadFile);

let app;
document.addEventListener('DOMContentLoaded', () => {
  if (Config?.MAINTENANCE_MODE === 'true') {
    app = new Vue({
      router,
      acl,
      store,
      vuetify,
      setup() {
        provideApi();
      },
      render: (h) => h(MaintenanceLayout),
    }).$mount();
  } else {
    app = new Vue({
      router,
      acl,
      store,
      vuetify,
      setup() {
        provideApi();
      },
      render: (h) => h(App),
    }).$mount();
  }
  document.body.appendChild(app.$el);
});
