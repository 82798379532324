import {
  ICON_FORM_NUMERIC,
  ICON_PRODUCT,
} from '@constants/icons';
import { checkSuperUser } from '@plugins/app-utils';
import ProductBusiness from '@businesses/Product';
import '@plugins/rules';

const nicknameSize = checkSuperUser() ? 5 : 8;
const useNicknameSize = checkSuperUser() ? 2 : 3;

export default {
  formName: 'Registers',
  service: 'Registers',
  noCreate: true,
  noEdit: true,
  noDelete: true,
  noActions: true,
  hideSaveButton: true,
  hideReturnButton: true,
  business: ProductBusiness,
  useExternalItems: true,
  icon: ICON_PRODUCT,
  routes: {
    list: {
      path: '/supply/registers',
      name: 'supply-registers-list',
    },
    edit: {
      path: '/supply/registers/edit/:id',
      name: 'supply-registers-edit',
    },
    create: {
      path: '/supply/registers/create',
      name: 'supply-registers-create',
    },
  },
  titles: {
    item: 'Importar Registro',
    items: 'Importar Registros',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  canClone: true,
  filterUrl: 'registers/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'Informações do Registro',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
    },
    {
      label: 'CNPJ / CPF',
      name: 'cnpj',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
    },
    {
      label: 'Nome / Razão Social',
      name: 'name',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 8,
      sortable: true,
    },
    {
      label: 'Código',
      name: 'code',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 4,
      sortable: true,
    },
    {
      label: 'Apelido',
      name: 'nickname',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: nicknameSize,
      sortable: true,
    },
    {
      label: 'Usar Apelido',
      name: 'use_nickname',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: useNicknameSize,
    },
    {
      label: 'Tipo de cadastro',
      name: 'type_register',
      type: 'text',
      hint: '',
      hideNot: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilter',
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 12,
      clearable: true,
    },
    {
      label: 'Data do registro',
      name: 'date_register_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'IE/RG',
      name: 'ie_rg',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 4,
      sortable: true,
    },
    {
      label: 'Inscrição Municipal',
      name: 'im',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 4,
      sortable: true,
    },
    {
      label: 'Suframa',
      name: 'suframa',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 4,
      sortable: true,
    },
    {
      label: 'Inativo',
      name: 'use_inactive',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: useNicknameSize,
    },
    {
      label: 'Data de inativação',
      name: 'inactive_date_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Regime tributário',
      name: 'tax_regime',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Tipo de contribuinte',
      name: 'taxpayer_type',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Tipo de Avaliação',
      name: 'evaluation_id',
      type: 'text',
      hideNot: true,
      component: 'dynamic-select',
      multiple: true,
      clearable: true,
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['evaluation'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 4,
    },
    {
      label: 'Tipo',
      name: 'buyer.type_id',
      type: 'text',
      hideNot: true,
      component: 'dynamic-select',
      multiple: true,
      clearable: true,
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['type'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 4,
    },
    {
      label: 'Atividade / Ocupação',
      name: 'buyer.occupation_id',
      type: 'text',
      hideNot: true,
      component: 'dynamic-select',
      multiple: true,
      clearable: true,
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['occupation'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 4,
    },
  ],
};
