import DigitalBankSchema from '@schemas/Account';
import AccountStatementsSchema from '@schemas/AccountStatement';
import AccountBilletSchema from '@schemas/AccountBillet';
// import AccountTransfersSchema from '@schemas/AccountTransfer';

const ListAccounts = () => import(/* webpackChunkName: "list-accounts" */'./pages/accounts/ListAccounts');
const CreateAccount = () => import(/* webpackChunkName: "create-account" */'./pages/accounts/CreateAccount');
const EditAccounts = () => import(/* webpackChunkName: "edit-accounts" */ './pages/accounts/EditAccounts');
const ListAccountStatements = () => import(/* webpackChunkName: "list-account-statements" */'./pages/accounts/ListAccountStatements');
// const ListAccountTransfers = () => import(/* webpackChunkName: "list-account-transfers" */'./pages/accounts/ListAccountTransfers');
// const CreateAccountTransfer = () => import(/* webpackChunkName: "create-account-transfers" */'./pages/accounts/CreateAccountTransfer');
const ListAccountBillets = () => import(/* webpackChunkName: "list-account-billets" */'./pages/accounts/ListAccountBillets');
const CreateAccountsBilletsPay = () => import(/* webpackChunkName: "create-accounts-billets-pay" */'./pages/accounts/CreateAccountsBilletsPay');

export default [
  {
    ...DigitalBankSchema.routes.list,
    meta: {
      requiresAuth: true,
      rule: 'white_labels',
      breadcrumb: [
        { name: 'Contas', link: '/accounts' },
        { name: 'Listas das contas' },
      ],
    },
    component: ListAccounts,
  },
  {
    ...DigitalBankSchema.routes.create,
    meta: {
      requiresAuth: true,
      rule: 'white_labels',
      breadcrumb: [
        { name: 'Contas', link: '/accounts' },
        { name: 'Adicionar conta' },
      ],
    },
    component: CreateAccount,
  },
  {
    ...DigitalBankSchema.routes.edit,
    meta: {
      requiresAuth: true,
      rule: 'white_labels',
      breadcrumb: [
        { name: 'Contas', link: '/accounts' },
        { name: 'Alterar conta' },
      ],
    },
    component: () => EditAccounts,
  },
  {
    ...AccountStatementsSchema.routes.list,
    meta: {
      requiresAuth: true,
      rule: 'white_labels',
      breadcrumb: [
        { name: 'Contas', link: '/accounts' },
        { name: 'Listas dos Extratos' },
      ],
    },
    component: ListAccountStatements,
  },
  // {
  //   ...AccountTransfersSchema.routes.list,
  //   meta: {
  //     requiresAuth: true,
  //     rule: 'white_labels',
  //     breadcrumb: [
  //       { name: 'Contas', link: '/accounts' },
  //       { name: 'Listas das transferências' },
  //     ],
  //   },
  //   component: ListAccountTransfers,
  // },
  // {
  //   ...AccountTransfersSchema.routes.create,
  //   meta: {
  //     requiresAuth: true,
  //     rule: 'white_labels',
  //     breadcrumb: [
  //       { name: 'Contas', link: '/accounts' },
  //       { name: 'Nova transferência' },
  //     ],
  //   },
  //   component: CreateAccountTransfer,
  // },
  {
    ...AccountBilletSchema.routes.list,
    meta: {
      requiresAuth: true,
      rule: 'white_labels',
      breadcrumb: [
        { name: 'Contas', link: '/accounts' },
        { name: 'Listas dos Boletos' },
      ],
    },
    component: ListAccountBillets,
  },
  {
    ...AccountBilletSchema.routes.create,
    meta: {
      requiresAuth: true,
      rule: 'white_labels',
      breadcrumb: [
        { name: 'Contas', link: '/accounts' },
        { name: 'Pagar Boleto' },
      ],
    },
    component: CreateAccountsBilletsPay,
  },
];
