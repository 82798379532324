<template>
  <div :id="item.id">
    <GenericTab :schema="schema" :item="item" />
  </div>
</template>
<script>
import GenericTab from '@sharedComponents/tabs/GenericTab';
import RemittanceConsignmentSchema from '@schemas/RemittanceConsignment';

export default {
  name: 'TabRemittanceConsignment',
  components: {
    GenericTab,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      schema: RemittanceConsignmentSchema,
    };
  },
};
</script>

<style scoped></style>
