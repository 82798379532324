import InvoiceTemplatesSchema from '@schemas/InvoiceTemplate';

const ListInvoiceTemplates = () => import(/* webpackChunkName: "list-invoice-templates" */'./ListInvoiceTemplates');
const DetailsInvoiceTemplates = () => import(/* webpackChunkName: "details-invoice-templates" */'./DetailsInvoiceTemplates');

export default [
  {
    path: '/settings/invoices',
    name: 'settings-invoices-list',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: InvoiceTemplatesSchema.titles.items },
      ],
    },
    component: ListInvoiceTemplates,
  },
  {
    path: '/settings/invoices/create',
    name: 'settings-invoices-create',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: InvoiceTemplatesSchema.titles.items, route: 'settings-invoices-list' },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailsInvoiceTemplates,
  },
  {
    path: '/settings/invoices/edit/:id',
    name: 'settings-invoices-edit',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: InvoiceTemplatesSchema.titles.items, route: 'settings-invoices-list' },
        { name: 'Editar' },
      ],
    },
    component: DetailsInvoiceTemplates,
  },
];
