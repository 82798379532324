import Vue from 'vue';

import {
  ICON_INVOICE,
  ICON_FORM_NUMERIC,
} from '@constants/icons';
import {
  MASK_BR_CAR_OLD_PLATE,
  MASK_BR_CAR_PLATE,
  MASK_CPF,
  MASK_RENAVAN,
} from '@constants/masks';
import {
  OPTIONS_UF,
  OPTIONS_VEHICLE_TRACTION_TYPE,
  OPTIONS_VEHICLE_BODY_TYPE,
  OPTIONS_VEHICLE_TYPE,
} from '@constants/options';

export default {
  service: 'Manifest',
  // business: ManifestVehicleBusiness,
  icon: ICON_INVOICE,
  routes: {
    list: {
      path: '/supply/manifest-list',
      name: 'supply-manifest-list',
    },
    create: {
      path: '/supply/manifest/create',
      name: 'supply-manifest-create',
    },
    edit: {
      path: '/supply/manifest/edit/:id',
      name: 'supply-manifest-edit',
    },
  },
  titles: {
    item: 'Veículos Manifesto',
    items: 'Veículos Manifesto',
    edit: 'Editar Veículo',
    new: 'Novo Veículo',
    create: 'Novo Veículo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'ID',
      name: 'id',
      formattedName: 'id',
      type: 'number',
      itemText: 'id',
      itemValue: 'id',
      list: false,
    },
    {
      label: 'CPF do proprietário *',
      name: 'owner_cpf',
      formattedName: 'owner_cpf',
      type: 'number',
      component: 'dynamic-text-field',
      mask: MASK_CPF,
      prependIcon: ICON_FORM_NUMERIC,
      itemText: 'code',
      itemValue: 'code',
      list: true,
      md: 12,
      required: true,
      rules: [Vue.prototype.$rules.cpf()],
    },
    {
      label: 'Tipo de Veículo *',
      name: 'vehicle_type',
      type: 'text',
      list: true,
      component: 'dynamic-select',
      items: OPTIONS_VEHICLE_TYPE,
      md: 4,
      required: true,
      itemValue: 'text',
      itemText: 'text',
    },
    {
      label: 'Tipo de Tração *',
      name: 'traction_type',
      type: 'text',
      list: true,
      component: 'dynamic-select',
      items: OPTIONS_VEHICLE_TRACTION_TYPE,
      md: 4,
      required: true,
      itemValue: 'text',
      itemText: 'text',
    },
    {
      label: 'Tipo Carroceria *',
      name: 'body',
      type: 'text',
      list: true,
      component: 'dynamic-select',
      items: OPTIONS_VEHICLE_BODY_TYPE,
      md: 4,
      required: true,
      itemValue: 'text',
      itemText: 'text',
    },

    {
      label: 'Placa *',
      name: 'license_plate',
      type: 'string',
      component: 'dynamic-text-field',
      mask: [MASK_BR_CAR_PLATE, MASK_BR_CAR_OLD_PLATE],
      required: true,
      list: true,
      md: 4,
    },
    {
      label: 'Renavam *',
      name: 'renavam',
      type: 'string',
      list: true,
      mask: MASK_RENAVAN,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Estado *',
      name: 'uf',
      type: 'text',
      component: 'dynamic-select',
      items: OPTIONS_UF,
      cols: 4,
      required: true,
      list: true,
    },
    {
      label: 'Tara KG *',
      name: 'tare_weight',
      hint: 'Peso Bruto do Veículo',
      type: 'number',
      mask: '##############',
      prependIcon: ICON_FORM_NUMERIC,
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 3,
    },
    {
      label: 'Capacidade Total *',
      name: 'weight_capacity',
      list: true,
      type: 'number',
      mask: '##############',
      prependIcon: ICON_FORM_NUMERIC,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Capacidade em Metros Cubicos(m³) *',
      name: 'cubic_meter_capacity',
      list: true,
      type: 'number',
      mask: '##############',
      prependIcon: ICON_FORM_NUMERIC,
      component: 'dynamic-text-field',
      required: true,
      md: 5,
    },
  ],
};
