<template>
  <div :id="item.id">
    <GenericCardPanel
      title="Descrição para Nota Fiscal"
      :fields="invoiceDetailInfoFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabOrderService',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      invoiceDetailInfoFields: [],
    };
  },
  mounted() {
    this.invoiceDetailInfoFields = [
      { label: 'Observação', value: this.item.invoice_info, col: 12 },
      { label: 'Usar Descrição na Nota Fiscal', value: this.item.use_invoice_info ? 'Sim' : 'Não', col: 12 },
    ];
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style scoped></style>
