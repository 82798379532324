<template>
  <div class="tab-order-sale-itens">
    <GenericCardPanel title="Arquivos" :fields="[]">
      <DataList :headers="headers"
                no-check
                no-edit
                no-delete
                :items="setBiddingsFiles(item)"
                item-key="id"
                class="mx-2"
                :sort-by="['id']"
                :sort-desc="[false, true]">
        <template v-slot:actions="item">
          <Btn color="secondary" aria-label="Download" @click="handleExternalLink(item.link)">
            <v-icon>{{ icons.fileLink }}</v-icon>
          </Btn>
        </template>
      </DataList>
    </GenericCardPanel>
  </div>
</template>

<script>
import { ICON_DOWNLOAD } from '@constants/icons';
import Btn from '@sharedComponents/app/Btn';
import DataList from '@sharedComponents/dynamic/DataList';
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabBiddingsFiles',
  components: {
    Btn,
    DataList,
    GenericCardPanel,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      icons: {
        fileLink: ICON_DOWNLOAD,
      },
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Ações', value: 'actions' },
      ],
      actions: [{}],
      filterParams: {},
    };
  },
  computed: {},
  methods: {
    setBiddingsFiles(item) {
      return item.file_links;
    },
    handleExternalLink(url) {
      window.open(url, '_blank').focus();
    },
  },
};
</script>
