import config from '@src/core/config';

const { APP_URL_AUTH, API_ROOT } = config;
const endpoint = 'auth';

export default ($axios) => ({

  async create(payload) {
    const res = await $axios.post(`${endpoint}.json`, payload);
    return res;
  },
  async createBasic(payload) {
    const response = await $axios.post('auth_v2.json', payload);

    const token = response.headers['access-token'];
    // eslint-disable-next-line camelcase
    const token_type = response.headers['token-type'];
    const {
      uid, client, expiry, authorization,
    } = response.headers;
    const { data: userData } = response.data;
    const { company_id, customer_id } = userData;

    localStorage.setItem('authorization', authorization);
    localStorage.setItem('token', token);
    localStorage.setItem('token-type', token_type);
    localStorage.setItem('uid', uid);
    localStorage.setItem('client', client);
    localStorage.setItem('expiry', expiry);
    localStorage.setItem('company_id', company_id);
    localStorage.setItem('customer_id', customer_id);

    return response;
  },

  loginURL() {
    const response_type = 'token';
    const client_id = 'frontend';
    const window_location = `${window.location.protocol}//${window.location.host}`;
    const redirect_uri = encodeURIComponent(`${window_location}/auth/redirect`);
    const scope = 'core';
    const state = new Date().getTime();

    return `${APP_URL_AUTH}/oauth/auth?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}`;
  },
  async logout() {
    // const params = {
    //   event_id: 'access_logout',
    //   event: 'Logout',
    //   name: localStorage.getItem('name'),
    //   email: localStorage.getItem('uid'),
    //   company_id: localStorage.getItem('company_id'),
    //   customer_id: localStorage.getItem('customer_id'),
    // };

    // await Vue.prototype.$api.MixpanelService.sendLog(params);

    localStorage.removeItem('uid');
    localStorage.removeItem('name');
    localStorage.removeItem('company_id');
    localStorage.removeItem('customer_id');
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    localStorage.removeItem('functions');

    window.location = '/auth/login';
  },
  async acceptInvitation(payload) {
    delete payload.email;
    delete payload.reset_password_token;
    const res = await $axios.put(`${endpoint}/invitation`, { accept_invitation: payload });
    return res;
  },
  async sendEmailToRequestPassword(payload) {
    delete payload.invitation_token;
    delete payload.reset_password_token;
    delete payload.password;
    delete payload.password_confirmation;

    const res = await $axios.post(`${endpoint}/password`, { reset_password: payload });
    return res;
  },
  async resetPassword(payload) {
    delete payload.email;
    delete payload.invitation_token;
    const res = await $axios.put(`${endpoint}/password`, { reset_password: payload });
    return res;
  },

  async update(payload) {
    const response = await $axios.put(`${endpoint}`, payload);
    return response.data;
  },
  async getCnpj(value) {
    const payload = {
      cnpj: value,
    };
    if (value == null || value.length === 0) { return {}; }
    const response_formatted = {};
    await $axios.post(`${API_ROOT}/map_cnpj/search/cnpj`, payload).then((response) => {
      const { corporate_name, address, cnae } = response.data;

      if (Object.keys(response.data).length === 0) { return {}; }

      response_formatted.cnpj = response.data.cnpj;
      response_formatted.razao_social = corporate_name;
      response_formatted.address = {};
      response_formatted.address.zip_code = address.cep || '';
      response_formatted.address.street = address.type_street.concat(' ', address.street) || '';
      response_formatted.address.number = address.number || '';
      response_formatted.address.complement = address.complement || '';
      response_formatted.address.neighborhood = address.neighborhood || '';
      response_formatted.address.city = address.city.name || '';
      response_formatted.address.uf = address.state.uf || '';
      const cnaeFilter = cnae.find((item) => item.principal === true);
      response_formatted.cnae = cnaeFilter?.id;
      response_formatted.main_activity = response.data.main_activity || '';
      response_formatted.activity_code = response.data.activity_code || '';
      response_formatted.foundation_date = response.data.foundation_date || '';
      response_formatted.fantasy_name = response.data.fantasy_name || '';
    });

    return response_formatted || {};
  },
  async sendContactInfo(payload) {
    if (!payload) return;
    await $axios.post(`${API_ROOT}/send_contact_info`, payload);
  },
});
