import {
  PriceList,
  PriceListCalc,
  PriceListCommands,
  PriceListSearch,
} from './pages/pricelist/services';
import { Registers, RegistersCommands } from './pages/registers/service';

export { default as routes } from './routes';

export const globalServices = {
  PriceList,
  PriceListCalc,
  PriceListCommands,
  PriceListSearch,
  Registers,
  RegistersCommands,
};
