import * as Options from '@constants/options';
import { ICON_INVOICE } from '@constants/icons';
import InvoiceItemBusiness from '@businesses/InvoiceItem';

export default {
  service: 'Invoices',
  business: InvoiceItemBusiness,
  icon: ICON_INVOICE,
  routes: {
    list: {
      path: '/supply/invoices-list',
      name: 'supply-invoices-list',
    },
    create: {
      path: '/supply/invoices/create',
      name: 'supply-invoices-create',
    },
    edit: {
      path: '/supply/invoices/edit/:id',
      name: 'supply-invoices-edit',
    },
  },
  titles: {
    item: '',
    items: '',
    edit: '',
    new: '',
    create: '',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'Tipo de Endereço',
      name: 'provision_address.origin',
      formattedName: 'provision_address.origin_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: Options.OPTIONS_ORDERSSERVICE_ADDRESS_TYPE,
      required: true,
      list: true,
      md: 4,
    },
    {
      label: 'UF',
      name: 'provision_address.uf',
      type: 'string',
      component: 'dynamic-select',
      items: Options.OPTIONS_UF,
      required: true,
      list: true,
      md: 4,
    },
    {
      label: 'Município',
      name: 'provision_address.city',
      type: 'text',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
  ],
};
