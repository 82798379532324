import AnticipationCreditCard from '@schemas/AnticipationCreditCard';

const ListAnticipation = () => import(/* webpackChunkName: "list-anticipation" */ './ListAnticipation');
const ListCreditCardAnticipation = () => import(/* webpackChunkName: "list-anticipation-credit-card" */ './ListCreditCardAnticipation');

export default [
  {
    path: '/financial/anticipation',
    name: 'financial-anticipation-list',
    meta: {
      requiresAuth: true,
      rule: 'isInitialRule',
      breadcrumb: [{ name: 'Financeiro' }, { name: 'Antecipações' }],
    },
    component: ListAnticipation,
  },
  {
    path: AnticipationCreditCard.routes.list.path,
    name: AnticipationCreditCard.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'isInitialRule',
      breadcrumb: [{ name: 'Financeiro' }, { name: AnticipationCreditCard.titles.item }],
    },
    component: ListCreditCardAnticipation,
  },
];
