import { formatCurrency, formatDate, formatCnpjCpf } from '@plugins/app-utils';

const endpoint = 'anticipation/integrations/ryze';

export default ($axios) => ({
  async fetchSecurities(cnpj) {
    const req = { cnpj };
    const res = await $axios.post(`${endpoint}/securities`, req);
    return this.formatReceivables(res.data.data);
  },
  async checkCreditCardAnticipationRelease() {
    const response = await $axios.get(`${endpoint}/check_credit_card_anticipation_release`);
    const { data } = response.data;
    return data;
  },
  async getOpenReceivables() {
    try {
      const {
        data: { receivables },
      } = await $axios.get(`${endpoint}/list_open_credit_card_receivables`);
      return this.formatReceivables(receivables);
    } catch (error) {
      // throw new Error('Erro ao buscar recebíveis abertos');
    }
  },

  async getProposals() {
    try {
      const {
        data: { proposals },
      } = await $axios.get(`${endpoint}/proposals`);
      return this.formatProposals(proposals);
    } catch (error) {
      // throw new Error('Erro ao buscar recebíveis abertos');
    }
  },

  async operateProposals(receivables) {
    if (!Array.isArray(receivables) || receivables.length === 0) {
      // throw new Error('Invalid receivables array');
    }

    const { cnpj } = receivables[0];
    if (!cnpj) {
      // throw new Error('Invalid receivables object: missing cnpj property');
    }

    const req = { proposals: receivables, cnpj };
    const { data } = await $axios.post(`${endpoint}`, req);

    if (data.status === 'error') {
      // throw new Error('Error in API response');
    }

    return data;
  },
  async getUserInfoToAntecipation() {
    try {
      const { data } = await $axios.get('users/me.json');
      const {
        company: { addresses, contacts },
        company,
      } = data;
      return { company, addresses, contacts };
    } catch (error) {
      // throw new Error('Error in API response');
    }
  },
  formatReceivables(receivables = []) {
    const receivablesFormatted = receivables.map((receivable) => {
      const available_amount_formatted = formatCurrency(parseFloat(receivable.available_amount));
      const settled_amount_formatted = formatCurrency(parseFloat(receivable.settled_amount));
      const total_amount_formatted = formatCurrency(parseFloat(receivable.total_amount));
      const settlement_expected_for_formatted = formatDate(receivable.settlement_expected_for);
      const debtor_formatted = formatCnpjCpf(receivable.debtor);
      return {
        ...receivable,
        available_amount_formatted,
        settled_amount_formatted,
        total_amount_formatted,
        settlement_expected_for_formatted,
        debtor_formatted,
      };
    });
    return receivablesFormatted;
  },
  formatProposals(proposals = []) {
    const proposalsFormatted = proposals.map((proposal) => {
      const chunks_sum_formatted = formatCurrency(parseFloat(proposal.chunks_sum));
      const amount_formatted = formatCurrency(parseFloat(proposal.amount));
      const created_at_formatted = formatDate(proposal.created_at);
      return {
        ...proposal,
        chunks_sum_formatted,
        amount_formatted,
        created_at_formatted,
      };
    });
    return proposalsFormatted;
  },
});
