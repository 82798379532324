import { ICON_CARD_TEXT } from '@constants/icons';

export default {
  service: 'Observations',
  formName: 'observations',
  // business: ObservationBusiness,
  icon: ICON_CARD_TEXT,
  routes: {
  },
  titles: {
    item: 'Observação',
    items: 'Observações',
    edit: 'Editar Observação',
    create: 'Criar Observação',
    new: 'Nova observação',
  },
  messages: {
    successDelete: 'Observação excluida com sucesso!',
    successEdit: 'Observação alterada com sucesso!',
    successCreate: 'Observação criada com sucesso!',
    errorSave: 'Erro ao salvar observação!',
    tryEditCanceled: 'Não é possível editar uma observação cancelada.',
    confirmCancel: 'Deseja cancelar a observação?',
    successCancel: 'Observação cancelada com sucesso',
    errorCancel: 'Erro ao cancelar observação',
  },
  filterUrl: 'observations/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'OBSERVAÇÃO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
      tabGroup: 'infoObservation',
    },
    {
      label: 'Data',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 6,
      list: true,
      tabGroup: 'infoObservation',
    },
    {
      label: 'Tipo',
      name: 'type_observation_id',
      formattedName: 'type_observation.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-observations-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['observation'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 6,
      tabGroup: 'infoObservation',
      list: true,
    },
    {
      label: 'Mensagem',
      name: 'text',
      type: 'text',
      list: true,
      component: 'dynamic-text-area',
      md: 12,
    },
  ],
};
