import Vue from 'vue';
import { formatCurrency } from '@plugins/app-utils';

export const calculateTotals = (formValue, noRateil = false) => {
  let totalTaxes = 0;
  let totalProducts = 0;
  let totalTaxApproximates = 0;

  formValue.items = formValue.items || [];
  formValue.items.forEach((item) => {
    if (!item._destroy) {
      totalTaxes += parseFloat(item.icms_st?.value) + parseFloat(item.ipi?.value);
      totalProducts += (parseFloat(item.quantity) || 0) * (parseFloat(item.unit_value) || 0);
      totalTaxApproximates += parseFloat(item.tax_approximate);
    }
  });

  // rateia frete total entre os itens
  let totalFreightValue = 0;
  let totalAnotherValue = 0;
  let totalDiscountValue = parseFloat(formValue.total_discount || 0);

  if (formValue.total_products && !noRateil) {
    totalFreightValue = parseFloat(formValue.freight_value) || 0;
    totalAnotherValue = parseFloat(formValue.expense_value) || 0;
    totalDiscountValue = parseFloat(formValue.total_discount) || 0;

    const formattedItems = formValue.items.map((item) => {
      const totalItem = (parseFloat(item.quantity) || 0) * (parseFloat(item.unit_value) || 0);
      return {
        ...item,
        freight_value: totalFreightValue * (totalItem / totalProducts),
        another_value: totalAnotherValue * (totalItem / totalProducts),
        total_value: (parseFloat(item.quantity) * parseFloat(item.unit_value)) - parseFloat(item.discount_value),
        total_value_formatted: formatCurrency(item.total_value),
      };
    });
    formValue.items = formattedItems;

    Vue.set(formValue, 'items', formValue.items);
  }

  if (noRateil === true) {
    const no_freight_items = formValue.items.map((item) => ({
      ...item,
      freight_value: 0,
    }));
    formValue.items = no_freight_items;

    Vue.set(formValue, 'items', formValue.items);
  }

  let totalOrdered = (parseFloat(totalProducts) + parseFloat(totalTaxes)) - parseFloat(totalDiscountValue);
  if (formValue.expense_value) { totalOrdered += parseFloat(formValue.expense_value); }
  if (formValue.use_freight) { totalOrdered += parseFloat(formValue.freight_value) || 0; }

  formValue.total_discount = parseFloat(totalDiscountValue);
  formValue.total_discount_formatted = formatCurrency(totalDiscountValue);

  Vue.set(formValue, 'total_discount', formValue.total_discount);
  Vue.set(formValue, 'total_discount_formatted', formValue.total_discount_formatted);

  formValue.total_taxes = parseFloat(totalTaxes || 0);
  formValue.total_taxes_formatted = formatCurrency(parseFloat(totalTaxes || 0));
  Vue.set(formValue, 'total_taxes', formValue.total_taxes);
  Vue.set(formValue, 'total_taxes_formatted', formValue.total_taxes_formatted);

  formValue.total_tax_approximates = parseFloat(totalTaxApproximates || 0);
  formValue.total_tax_approximates_formatted = formatCurrency(parseFloat(totalTaxApproximates || 0));
  Vue.set(formValue, 'total_tax_approximates', formValue.total_tax_approximates);
  Vue.set(formValue, 'total_tax_approximates_formatted', formValue.total_tax_approximates_formatted);

  formValue.total_products = parseFloat(totalProducts || 0);
  formValue.total_products_formatted = formatCurrency(parseFloat(totalProducts || 0));
  Vue.set(formValue, 'total_products', formValue.total_products);
  Vue.set(formValue, 'total_products_formatted', formValue.total_products_formatted);

  formValue.total_ordered = parseFloat(totalOrdered || 0);
  formValue.total_ordered_formatted = formatCurrency(parseFloat(totalOrdered || 0));
  Vue.set(formValue, 'total_ordered', formValue.total_ordered);
  Vue.set(formValue, 'total_ordered_formatted', formValue.total_ordered_formatted);
};

export const calculateTotalsInvoice = (formValue, noRateil = false) => {
  let totalDiscount = 0;
  let totalTaxes = 0;
  let totalProducts = 0;
  let totalInvoice = 0;

  const freightValue = formValue?.transporter?.use_freight ? parseFloat(formValue.total_freight) : 0;
  const expenseValue = parseFloat(formValue.expense_value ?? 0);
  const totalSafe = parseFloat(formValue.total_safe ?? 0);

  formValue.items = formValue.items || [];
  formValue.items
    .filter((item) => item._destroy !== true)
    .forEach((item) => {
      if (!item._destroy) {
        totalDiscount += parseFloat(item.discount_value ?? 0);
        totalTaxes += parseFloat(item.total_taxes ?? 0);
        totalProducts += parseFloat(item.total_without_discount_value ?? 0);
      }
    });

  // rateia frete total entre os itens
  noRateil = formValue?.transporter?.use_freight;
  if (totalProducts && !noRateil) {
    const totalFreightValue = parseFloat(formValue?.total_freight ?? 0);
    const totalAnotherValue = parseFloat(formValue.expense_value ?? 0);

    const formattedItems = formValue.items.map((item) => {
      const totalItem = (parseFloat(item.quantity) || 0) * (parseFloat(item.unit_value) || 0);
      return {
        ...item,
        freight_value: totalFreightValue * (totalItem / totalProducts),
        another_value: totalAnotherValue * (totalItem / totalProducts),
        total_value: (parseFloat(item.quantity) * parseFloat(item.unit_value)) - parseFloat(item.discount_value),
        total_value_formatted: formatCurrency(item.total_value),
      };
    });
    formValue.items = formattedItems;
    Vue.set(formValue, 'items', formValue.items);
  }
  if (noRateil === true) {
    const no_freight_items = formValue.items.map((item) => ({
      ...item,
      freight_value: 0,
    }));
    formValue.items = no_freight_items;
    Vue.set(formValue, 'items', formValue.items);
  }

  totalInvoice = totalProducts - totalDiscount + totalTaxes;
  totalInvoice += freightValue + expenseValue + totalSafe + (formValue.totalizers?.total_ipi ?? 0) + (formValue.totalizers?.total_icms_st ?? 0);

  formValue.total_discount = totalDiscount;
  formValue.total_discount_formatted = formatCurrency(totalDiscount);
  Vue.set(formValue, 'total_discount', formValue.total_discount);
  Vue.set(formValue, 'total_discount_formatted', formValue.total_discount_formatted);
  formValue.total_taxes = totalTaxes;
  formValue.total_taxes_formatted = formatCurrency(totalTaxes);
  Vue.set(formValue, 'total_taxes', formValue.total_taxes);
  Vue.set(formValue, 'total_taxes_formatted', formValue.total_taxes_formatted);
  formValue.total_products = totalProducts;
  formValue.total_products_formatted = formatCurrency(totalProducts);
  Vue.set(formValue, 'total_products_formatted', formValue.total_products_formatted);
  Vue.set(formValue, 'total_products', formValue.total_products);
  formValue.total_invoice = totalInvoice;
  formValue.total_invoice_formatted = formatCurrency(totalInvoice);
  Vue.set(formValue, 'total_invoice', formValue.total_invoice);
  Vue.set(formValue, 'total_invoice_formatted', formValue.total_invoice_formatted);
  formValue.total_note = totalInvoice;
  formValue.total_note_formatted = formatCurrency(totalInvoice);
  Vue.set(formValue, 'total_note', formValue.total_note);
  Vue.set(formValue, 'total_note_formatted', formValue.total_note_formatted);
  formValue.total_freight = formValue.total_freight ?? 0;
  Vue.set(formValue, 'total_freight', formValue.total_freight);
  formValue.expense_value = formValue.expense_value ?? 0;
  Vue.set(formValue, 'expense_value', formValue.expense_value);
  formValue.total_safe = formValue.total_safe ?? 0;
  Vue.set(formValue, 'total_safe', formValue.total_safe);
  formValue.total_other = formValue.expense_value ?? 0;
  Vue.set(formValue, 'total_other', formValue.total_other);
  Vue.set(formValue, 'items', formValue.items);
};
