import * as AppUtils from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';

// const ImportRegisters = ($axios) => ({

//   getCities: (uf) => searchCitiesByUf(uf),

//   async getCompanyCheckAPINFSe() {
//     const { data } = await $axios.get(`${Config.API_GATEWAY_NFSE}/company`);
//     return !!data; // !! = res ? true : false
//   },

//   async sendCompanyAPINFse() {
//     const res = await $axios.post(`${Config.API_GATEWAY_NFSE}/company`);
//     return res;
//   },

//   async sendCertificateCompanyAPINFse(payload) {
//     const res = await $axios.post(`${Config.API_GATEWAY_NFSE}/company/certificate`, payload);
//     return res;
//   },

// });

const formatRequest = (item) => {
  if (item?.nfs) {
    item.nfs_attributes = {
      nature_operation: item.nfs.nature_operation,
      tax_regime: item.nfs.tax_regime,
      special_tax_regime: item.nfs.special_tax_regime,
      current_batch_number: item.nfs.current_batch_number,
      current_batch_serie: item.nfs.current_batch_serie,
      city_hall_login: item.nfs.city_hall_login,
      city_hall_password: item.nfs.city_hall_password,
      aliquot_iss: item.nfs.aliquot_iss,
      send_notification_to_customer: item.nfs.send_notification_to_customer || false,
      email: item.nfs.email,
      get_notification: item.nfs.get_notification,
      access_key: item.nfs.access_key,
    };
    delete item.nfs;
  }

  if (item?.nfce) {
    item.nfce_attributes = {
      id_token: item.nfce.id_token,
      csc_token: item.nfce.csc_token,
    };
    delete item.nfce;
  }
};

const formatResponse = async (item) => {
  item.type_invoice_formatted = AppUtils.getOptionText('type_invoices', item.type_invoice);
  item.environment_formatted = AppUtils.getOptionText('type_invoices_environments', item.environment);
  item.file = item.file || {};
  const valid = item.file.certificate_valid ? 'Certificado Validado com Sucesso' : 'O Certificado Enviado é Inválido';
  item.certificate_status = !item.file.certificate_path ? 'Certificado Não Enviado' : valid;

  if (item.nfs) {
    item.nfs.nature_operation_formatted = AppUtils.getOptionText('type_invoices_rps_nature_operations', item.nfs.nature_operation);
    item.nfs.tax_regime_formatted = AppUtils.getOptionText('type_invoices_rps_tax_regime', item.nfs.tax_regime);
    item.nfs.special_tax_regime_formatted = AppUtils.getOptionText('type_invoices_rps_special_tax_regime', item.nfs.special_tax_regime);
    // item.nfs.company_check_api_nfse = await Vue.prototype.$api.ImportRegisters.getCompanyCheckAPINFSe();
  }

  item.nfs = item.nfs || {};
  item.nfce = item.nfce || {};
};

export default DynamicService('invoice_settings', {
  formatRequest,
  formatResponse,
});
