import * as AppUtils from '@plugins/app-utils';

const endpoint = 'billets/in_return';

export default ($axios) => ({
  async index() {
    let responseData = {};
    await $axios.get(`${endpoint}.json`).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },
  async getByFilter(filters, order) {
    const payload = {
      q: filters || {},
    };
    // add sort option
    if (order) {
      payload.q.s = order;
    }
    let responseData = {};
    await $axios.post(`${endpoint}/filters.json`, payload).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },
  formatResponseData(data, type = 'table') {
    const format = (item) => {
      item.emission_date = AppUtils.formatDate(item.emission_date);
      item.due_date = AppUtils.formatDate(item.due_date);
      item.document_value_formatted = AppUtils.formatCurrency(item.document_value) || 'R$ 0,00';
      item.isSelected = false;

      if (item.origin) {
        item.origin.url = this.getParseDomain(item.origin.domain);
      }
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  getParseDomain(text) {
    if (!text) return '';
    return AppUtils.getDomainParse('account_receivable', text);
  },
});
