<template>
  <v-dialog v-model="open" max-width="650px" persistent>
    <GenericDialogCard
      :icon="icons.alert"
      title="Atenção"
      title-center
      icon-large
      :show-close="false"
      text="Foram feitas alterações nos dados, deseja salvar ou descartar as alterações?"
      @close="(open = false)">
      <template v-slot:actions>
        <v-spacer class="ml-n4" />
        <div class="ml-n8">
          <Btn ref="detaultButton"
               color="primary"
               width="98"
               type="submit"
               class="btn-lower mx-1"
               @click.stop="onSaveChanges()">
            Salvar
          </Btn>
          <Btn v-shortkey="['esc']"
               color="secondary"
               width="98"
               class="btn-lower mx-1"
               @shortkey="onCancel()"
               @click.stop="onCancel()">
            <strong style="color: #3a3a3a">Voltar</strong>
          </Btn>
          <Btn ref="detaultButton"
               color="error"
               width="98"
               type="custom"
               class="btn-lower mx-1"
               @click.stop="onLostChanges()">
            <strong>Descartar</strong>
          </Btn>
        </div>
        <v-spacer />
      </template>
    </GenericDialogCard>
  </v-dialog>
</template>

<script>
import { ICON_SAVE, ICON_ALERT } from '@constants/icons';
import GenericDialogCard from '@sharedComponents/dynamic/GenericDialogCard';
import Btn from '@sharedComponents/app/Btn';

export default {
  name: 'DmSaveOrDiscardChangesDialog',
  components: {
    Btn,
    GenericDialogCard,
  },
  data() {
    return {
      icons: {
        alert: ICON_ALERT,
        save: ICON_SAVE,
      },
      open: false,
      text: '',
    };
  },
  methods: {
    onSaveTempChanges() {
      this.open = false;
      this.$emit('action', 'save');
    },
    onSaveChanges() {
      this.open = false;
      this.$emit('action', 'saveBD');
    },
    onLostChanges() {
      this.open = false;
      this.$emit('action', 'discart');
    },
    onCancel() {
      this.open = false;
      this.$emit('action', 'cancel');
    },
  },
};
</script>
