import OrderSaleSchema from '@schemas/OrderSale';

const ListOrderSales = () => import(/* webpackChunkName: "order-sales-list" */'./ListOrderSales');
const DetailOrderSale = () => import(/* webpackChunkName: "order-sales-detail" */'./DetailOrderSale');

export default [
  {
    path: OrderSaleSchema.routes.list.path,
    name: OrderSaleSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: OrderSaleSchema.titles.items },
      ],
    },
    component: ListOrderSales,
  },
  {
    path: OrderSaleSchema.routes.create.path,
    name: OrderSaleSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: OrderSaleSchema.titles.items, route: OrderSaleSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailOrderSale,
  },
  {
    path: OrderSaleSchema.routes.edit.path,
    name: OrderSaleSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: OrderSaleSchema.titles.items, route: OrderSaleSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailOrderSale,
  },
];
