import {
  ICON_EMAIL_SEND,
} from '@constants/icons';

export default {
  service: 'EmailsSentSystem',
  // business: CashFlowBusiness,
  formName: 'emailSentSystem',
  icon: ICON_EMAIL_SEND,
  routes: {
    list: { path: '/settings/emails/sends', name: 'settings-emailssends-list' },
  },
  titles: {
    item: 'Email Enviado',
    items: 'Emais Enviados',
  },
  messages: {
    successResend: 'Email re-enviado com sucesso!',
  },
  hideReportsOnList: true,
  hideReportsOnCreate: true,
  hideReportsOnEdit: true,
  noCreate: true,
  noEdit: true,
  noDelete: true,
  fields: [
    {
      label: 'Data e Hora',
      name: 'created_at',
      formattedName: 'formattedDateHour',
      type: 'text',
      component: 'dynamic-text-field',
      md: 6,
      required: true,
      list: true,
    },
    {
      label: 'Origem',
      name: 'origin',
      type: 'text',
      component: 'dynamic-text-field',
      md: 6,
      required: true,
      list: true,
    },
    {
      label: 'Enviado para',
      name: 'destiny',
      type: 'text',
      component: 'dynamic-text-field',
      md: 6,
      required: true,
      list: true,
    },
    {
      label: 'Com cópia para',
      name: 'copy_email',
      type: 'text',
      component: 'dynamic-text-field',
      md: 6,
      required: true,
      list: true,
    },
    {
      label: 'Assunto',
      name: 'subject',
      type: 'text',
      component: 'dynamic-text-area',
      md: 12,
      required: true,
      list: true,
    },
  ],
};
