<template>
  <v-container fluid :class="containerClass">
    <GenericCardForm :bg-color="bgColor">
      <v-form ref="form"
              v-model="valid"
              lazy-validation
              :disabled="checkDisabled()"
              @submit.prevent
              @keyup.enter.native="save()">
        <v-row no-gutters>
          <v-col
            v-for="(field, index) in visibleFields"
            :key="field.id || (field.name || '') + index"
            :class="`${field.colClass || ''} py-0 px-1`"
            :cols="field.cols || 12"
            :sm="field.sm"
            :md="field.md"
            :lg="field.lg"
            :xl="field.xl"
            :offset-sm="field.offsetSm"
            :offset-md="field.offsetMd"
            :offset-lg="field.offsetLg"
            :offset-xl="field.offsetXl">
            <v-row no-gutters align-content="center" :class="`fill-height text-${field.propTag}`">
              <component
                :is="field.component || 'dynamic-text-field'"
                v-show="fieldIsVisible(field)"
                :id="'component' + index"
                :ref="'component' + index"
                :tabindex="index"
                :field="field"
                :read-only="readOnly"
                :value="formValue"
                :item-key="itemKey"
                :dense="dense"
                :size="field.size"
                :prop-tag="field.propTag"
                :color="field.color"
                :data-testid="field.name"
                style="width: 100%"
                :data-source="field.dataSource"
                @input="onFieldValueChange" />
            </v-row>
            <v-divider v-if="showDivider" />
          </v-col>
        </v-row>
      </v-form>
      <slot />
    </GenericCardForm>
  </v-container>
</template>

<script>
import _ from 'lodash';
import DynamicAutoComplete from './dynamicComponents/DynamicAutoComplete';
import DynamicButtons from '@sharedComponents/DynamicButtons';
import DynamicCheckBox from './dynamicComponents/DynamicCheckBox';
import DynamicCheckBoxGroup from './dynamicComponents/DynamicCheckBoxGroup';
import DynamicChips from './dynamicComponents/DynamicChips';
import DynamicDataListSelect from './dynamicComponents/DynamicDataListSelect';
import DynamicDateField from './dynamicComponents/DynamicDateField';
import DynamicDivider from './dynamicComponents/DynamicDivider';
import DynamicFileInput from './dynamicComponents/DynamicFileInput';
import DynamicHoursFieldVue from './dynamicComponents/DynamicHoursField';
import DynamicImage from './dynamicComponents/DynamicImage';
import DynamicInfiniteScroll from './dynamicComponents/DynamicInfiniteScroll';
import DynamicInputCurrency from './dynamicComponents/DynamicInputCurrency';
import DynamicInputPassword from './dynamicComponents/DynamicInputPassword';
import DynamicNumberField from './dynamicComponents/DynamicNumberField';
import DynamicRadioGroup from './dynamicComponents/DynamicRadioGroup';
import DynamicRangeSlider from './dynamicComponents/DynamicRangeSlider';
import DynamicSelect from './dynamicComponents/DynamicSelect';
import DynamicTextArea from './dynamicComponents/DynamicTextArea';
import DynamicTextField from './dynamicComponents/DynamicTextField';
import DynamicTimePicker from './dynamicComponents/DynamicTimePicker';
import DynamicTitle from './dynamicComponents/DynamicTitle';
import DynamicTotalizer from '@sharedComponents/dynamicComponents/DynamicTotalizer';
import DynamicTotalizerNew from '@sharedComponents/dynamicComponents/DynamicTotalizerNew';

import GenericCardForm from '@sharedComponents/dynamic/GenericCardForm';

export default {
  name: 'DmDynamicForm',
  components: {
    'dynamic-autocomplete': DynamicAutoComplete,
    'dynamic-buttons': DynamicButtons,
    'dynamic-checkbox': DynamicCheckBox,
    'dynamic-checkbox-group': DynamicCheckBoxGroup,
    'dynamic-chips': DynamicChips,
    'dynamic-data-list-select': DynamicDataListSelect,
    'dynamic-date-field': DynamicDateField,
    'dynamic-divider': DynamicDivider,
    'dynamic-file-input': DynamicFileInput,
    'dynamic-hours-field': DynamicHoursFieldVue,
    'dynamic-image': DynamicImage,
    'dynamic-input-currency': DynamicInputCurrency,
    'dynamic-input-password': DynamicInputPassword,
    'dynamic-infinite-scroll': DynamicInfiniteScroll,
    'dynamic-number-field': DynamicNumberField,
    'dynamic-radio-group': DynamicRadioGroup,
    'dynamic-range-slider': DynamicRangeSlider,
    'dynamic-text-area': DynamicTextArea,
    'dynamic-text-field': DynamicTextField,
    'dynamic-time-picker': DynamicTimePicker,
    'dynamic-select': DynamicSelect,
    'dynamic-title': DynamicTitle,
    'dynamic-totalizer': DynamicTotalizer,
    'dynamic-totalizer-new': DynamicTotalizerNew,
    GenericCardForm,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      required: true,
      type: Array,
    },
    containerClass: {
      type: String,
      default: '',
    },
    showDivider: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    hideEmpty: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: '',
    },
    image: {
      type: String,
    },
    maxWidth: {
      type: String,
      default: '100%',
    },
    maxHeight: {
      type: String,
      default: '100%',
    },
    width: {
      type: String,
      default: '100px',
    },
    heigth: {
      type: String,
      default: '100px',
    },
    alt: {
      type: String,
      default: 'imagem',
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean || Function,
      default: false,
    },
    bgColor: {
      type: String,
      default: () => ('grey lighten-4'),
    },
  },
  data() {
    return {
      valid: true,
      formValue: {},
    };
  },
  computed: {
    visibleFields() {
      const visibleFields = _.filter(this.fields, (field) => this.fieldIsVisible(field));

      if (this.hideEmpty) {
        return _.filter(visibleFields, (field) => {
          const fieldValue = _.get(this.formValue, field.name);
          return fieldValue || !_.isEmpty(fieldValue);
        });
      }

      return visibleFields;
    },
  },
  watch: {
    value: {
      handler: 'onFormValueChange',
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onFormValueChange(newVal) {
      this.formValue = { ...newVal };
    },
    onFieldValueChange(field, newValue, objectValue) {
      if (field.name === undefined) return;
      _.set(this.formValue, field.name, newValue);
      this.$emit('input', this.formValue);
      this.$emit('field-change', field, newValue, objectValue);
    },
    fieldIsVisible(field) {
      if (field.component === undefined) {
        return false;
      }
      if (field.visible === undefined) {
        return true;
      }
      if (typeof field.visible === 'function') {
        return field.visible(this.formValue);
      }
      return Boolean(field.visible);
    },
    checkDisabled() {
      if (this.disabled && typeof this.disabled === 'function') {
        return this.disabled(this.value);
      }
      return this.disabled || false;
    },
    save() {
      this.$emit('input', this.formValue);
      this.$emit('save', this.formValue);
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      return this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss">
.dm-input .v-input-group__details {
  display: block;
}

.dm-dynamic-field-row {
  width: 100%;
}
</style>
