<template>
  <ActiveReportsLoader v-if="!activeReportsLoader"
                       v-model="activeReportsLoader"
                       require-designer
                       @loaded="onActiveReportsLoad" />
  <div v-else id="report-host" ref="reportDesigner" />
</template>

<script>
import ActiveReportsLoader from './ActiveReportsLoader.vue';

export default {
  components: {
    ActiveReportsLoader,
  },
  props: {
    reportDefinition: {
      type: Object,
      required: true,
    },
    reportData: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      disposables: [],
      designer: {},
      activeReportsLoader: false,
    };
  },
  watch: {
    reportDefinition: {
      handler: 'onReportDefinitionChange',
      deep: true,
    },
  },
  beforeDestroy() {
    if (this.designer && this.designer.dispose) {
      this.designer.dispose();
    }
  },
  methods: {
    onReportDefinitionChange() {
      this.designer.setReport({
        definition: this.reportDefinition,
      });
    },
    onActiveReportsLoad() {
      this.$nextTick(() => {
        const { Designer } = window.GC.ActiveReports.ReportDesigner;
        this.designer = new Designer('#report-host');
        this.designer.zoom = 'FitPage';

        this.designer.setReport({
          definition: this.reportDefinition,
        });
      });
    },
    async getReport() {
      const reportInfo = await this.designer.getReport();
      return reportInfo;
    },
  },
};
</script>

<style lang="scss" src="./theme/ar-js-ui.scss"></style>
<style lang="scss" src="./theme/ar-js-viewer.scss"></style>
<style lang="scss" src="./theme/ar-js-designer.scss"></style>

<style>
#report-host .gc-grid-item--statusbar {
  width: 100%;
  height: calc(100% - 60px);
  margin-bottom: 20px;
}
</style>
