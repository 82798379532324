import Vue from 'vue';
import _ from 'lodash';
import FormatNumber from '@filters/FormatNumber';

const formatBytes = (a, b) => {
  if (a === 0) return '0 Bytes';
  const c = 1024;
  const d = b || 2;
  const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const f = Math.floor(Math.log(a) / Math.log(c));
  return `${parseFloat((a / c ** f).toFixed(d))} ${e[f]}`;
};

const formatFieldValue = (model, field) => {
  if (!model || !field) return;
  let value = '';

  if (field.systemType === 'user' || field.systemType === 'users') {
    if (field.userModel) {
      return _.get(model, field.userModel);
    }
    return model;
  }

  if (field.systemType === 'Photo' || field.systemType === 'File') {
    value = _.get(model, field.model);
    if (value) {
      const parsedValue = JSON.parse(value);
      if (_.isArray(parsedValue)) {
        return parsedValue[0];
      }
      return parsedValue;
    }
    return {};
  }

  if (field.items) {
    const items = typeof field.items === 'function' ? field.items() : field.items;
    if (!_.isArray(items)) {
      return undefined;
    }
    items.forEach((item) => {
      if (typeof item === 'object') {
        if (_.get(model, field.model) === _.get(item, field.itemValue || 'value')) {
          value = _.get(item, field.itemText || 'text');
        }
      } else {
        value = _.get(model, field.model);
      }
    });

    if (value && (field.multiple || field.component === 'dynamic-checkbox-group')) {
      value = value.replace(/\|/g, ', ');
    }
  } else if (field.dataSource && model._related && model._related[field.model]) {
    const related = model._related[field.model];
    if (_.isArray(related)) {
      related.forEach((item) => {
        value += (value && ', ') + _.get(item, field.itemText || 'text');
      });
    } else if (related) {
      value = related[field.itemText || 'text'];
    }
  } else if (field.dataSource && field.dataSource.state && field.dataSource.modelName) {
    const dataSource = [];// TODO, get from $api

    if (_.isArray(dataSource)) {
      _.each(dataSource, (item) => {
        if (_.get(item, field.itemValue || 'id') === _.get(model, field.model)) {
          value += (value && ', ') + _.get(item, field.itemText || 'text');
          return false;
        }
      });
    } else if (dataSource) {
      value = dataSource[field.itemText || 'text'];
    }
  } else if (field.type === 'v-chips') {
    value = _.get(model, field.model).join(', ');
  } else if (field.type === 'boolean') {
    value = _.get(model, field.model) === true ? 'Sim' : 'Não';
  } else if (field.type === 'number') {
    const numericMask = {
      decimal: field.decimal || ',',
      thousands: field.thousands || '.',
      prefix: field.prefix || '',
      suffix: field.suffix || '',
      precision: field.precision === undefined ? 2 : field.precision,
      masked: false,
    };

    value = FormatNumber.format(parseFloat(_.get(model, field.model)) || 0, numericMask);
  } else if (field.type === 'date' || field.type === 'datetime') {
    const date = _.get(model, field.model);
    if (date) {
      const momentDate = Vue.moment(_.get(model, field.model));
      value = momentDate.format(field.format || 'l');
    } else {
      value = '';
    }
  } else if (field.type === 'bytes') {
    const floatValue = parseFloat(_.get(model, field.model)) || 0.0;
    const multiplier = field.multiplier || 1;
    value = formatBytes(floatValue * multiplier, 2);
  } else {
    value = _.get(model, field.model);
  }

  return value;
};

const cerberusToDynamic = (columns) => {
  if (_.isObject(columns)) {
    const columnsClone = _.cloneDeep(columns);
    return _.map(columnsClone, (column, key) => {
      if (!key) return;
      column.model = key;
      return column;
    });
  }

  return columns || [];
};

const dynamicToCerberus = (columns) => {
  if (_.isArray(columns)) {
    const columnsClone = _.cloneDeep(columns);
    return _(columnsClone)
      .map((column) => column)
      .keyBy('model')
      .value();
  }

  return columns || {};
};

export default {
  formatFieldValue,
  cerberusToDynamic,
  dynamicToCerberus,
};
