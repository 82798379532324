import { ulid } from 'ulid';

const endpoint = 'profiles';

export default ($axios) => ({
  async index() {
    let responseData = {};

    await $axios.get(`${endpoint}.json`).then((response) => {
      responseData = response;
    });

    return responseData;
  },
  async show(id) {
    let responseData = {};

    await $axios.get(`${endpoint}/${id}.json`).then((response) => {
      responseData = response;
    });

    return responseData;
  },
  async update(id, data) {
    const params = this.formatRequest(id, data);
    await $axios.put(`${endpoint}/${id}.json`, params).then((response) => response);
  },
  async getProfilesFunctions() {
    let responseData = {};

    await $axios.get('profiles/functions.json').then((response) => {
      responseData = response;
    });

    return responseData;
  },
  async getProfileRoles() {
    let responseData = {};

    await $axios.get('profiles/roles.json').then((response) => {
      responseData = response;
    });

    this.addIdsAndTranslateNames(responseData.data);

    return responseData;
  },
  // @TODO - Removes when backend send default names
  addIdsAndTranslateNames(data) {
    data.forEach((item) => {
      item.id = ulid();
      item.translated_name = item.translated_name ?? item.name;
      if (item.children) {
        this.addIdsAndTranslateNames(item.children);
      }
    });
  },

  formatRequest(profile_id, data) {
    const params = {
      profile: {
        menus_attributes: [],
      },
    };

    if (data.type === 'add') {
      data.data.forEach((item) => {
        params.profile.menus_attributes.push({
          name: item.name,
          class_name: item.name,
          profile_id,
        });
      });
    } else {
      data.data.forEach((item) => {
        params.profile.menus_attributes.push({
          id: item.id,
          _destroy: true,
        });
      });
    }

    return params;
  },
});
