<template>
  <div>
    <span v-if="label || field.label" class="subheading primary--text">
      {{ label || field.label }}
    </span>
    <v-divider :class="dense ? 'mt-3' : ''" />
  </div>
</template>

<script>
export default {
  name: 'DynamicDivider',
  props: {
    label: {
      type: String,
      default: '',
    },
    field: {
      type: Object,
      default: () => ({}),
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
};
</script>
