import Vue from 'vue';
import VueRouter from 'vue-router';
import VueRouterBackButton from 'vue-router-back-button';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: 'nav-item active',
});

const { match } = router.matcher;
router.matcher.match = (...args) => {
  const route = match.apply(router, args);
  if (!route.meta.external) {
    return route;
  }
  return Object.freeze({ ...route, fullPath: route.meta.external.path });
};

// Override resolver to fix external links.
const { resolve } = router;
router.resolve = (...args) => {
  const resolved = resolve.apply(router, args);
  if (resolved.route.meta.external) {
    resolved.href = resolved.route.meta.external.path;
  }
  return resolved;
};

router.beforeEach((to, from, next) => {
  const TOKEN = localStorage.getItem('token');
  const CUSTOMER_ID = localStorage.getItem('customer_id');
  const COMPANY_ID = localStorage.getItem('company_id');
  const NO_PLAN = localStorage.getItem('IC/Storage/typePlan') === '"none"';

  if (NO_PLAN && window.location.pathname !== '/settings/subscriptions') {
    window.location.replace('/settings/subscriptions');
    return next();
  }

  if (to.meta.external) {
    if (to.meta.external.newWindow) {
      window.open(to.meta.external.path);
    } else {
      window.location.replace(to.meta.external.path);
    }
    return next(false);
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (TOKEN === null) {
      next({
        path: '/auth/login',
        params: { nextUrl: to.fullPath },
      });
    } else if ((CUSTOMER_ID === 'null' || COMPANY_ID === 'null')) {
      next({ name: 'login' });
    } else next();
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (TOKEN === null) {
      next();
    } else {
      next({ name: 'login' });
    }
  } else {
    next();
  }
});

Vue.use(VueRouterBackButton, { router });

export default router;
