import Vue from 'vue';

const applyListPriceInItems = async (formValue, service) => {
  if (formValue.price_list_id) {
    await Vue.prototype.$api.PriceListCommands.applyListInItems(formValue).then((response) => {
      formValue.items = response?.data.items || formValue.items;
      Vue.prototype.$api[service].options.formatResponse(formValue);
    }).catch(() => {
      console.warn('PriceList server is offline, try again later...');
    });
  }
  return formValue.items;
};
const applyListPriceInOneItem = async (formValue, service) => {
  if (formValue.price_list_id) {
    await Vue.prototype.$api.PriceListCommands.applyListPriceInOneItem(formValue).then((response) => {
      formValue.items = response?.data.items || formValue.items;
      Vue.prototype.$api[service].options.formatResponse(formValue);
    }).catch(() => {
      console.warn('PriceList server is offline, try again later...');
    });
  }
  return formValue.items;
};

export { applyListPriceInItems, applyListPriceInOneItem };
