import { ICON_MEASURING_UNIT } from '@constants/icons';
import ProductCompositionItemBusiness from '@businesses/ProductCompositionItem';

export default {
  service: 'ProductComposition',
  business: ProductCompositionItemBusiness,
  icon: ICON_MEASURING_UNIT,
  noEdit: true,
  noDelete: true,
  routes: {
    list: '',
    edit: '',
    create: '',
  },
  titles: {
    item: 'Ítem da Composição do Produto',
    items: 'Itens da Composição do Produto',
    edit: 'Editar Composição',
    create: 'Criar Composição',
    new: 'Adicionar Item',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: '',
  fields: [
    {
      label: 'Código',
      name: 'product_code',
      formattedName: 'product.code',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'code',
      itemValue: 'code',
      list: true,
      md: 4,
    },
    {
      label: 'Produto',
      name: 'product_child_id',
      formattedName: 'product.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      list: true,
      md: 8,
    },
    {
      label: 'Unidade de medida',
      name: 'unity_id',
      formattedName: 'unity.to_s',
      type: 'number',
      list: true,
      required: true,
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-measuringunits-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['unity'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
    },
    {
      label: 'Quantidade',
      name: 'quantity',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      precision: 0,
      default: 1,
      min: 0.001,
      md: 3,
    },
    {
      label: 'Custo Unitário',
      name: 'product_purchase_cost',
      formattedName: 'product_purchase_cost_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Custo Unitário na Composição',
      name: 'purchase_cost',
      formattedName: 'purchase_cost_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Custo Total',
      name: 'total_purchase_cost',
      formattedName: 'total_purchase_cost_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      md: 3,
    },
  ],
};
