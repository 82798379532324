import moment from 'moment';
import Vue from 'vue';

export default {
  fieldEvents: {
    commerce_product_taxes: {
      beforeCreate(formValue, fieldValue) {
        const len = formValue.commerce_product_taxes && formValue.commerce_product_taxes.length;
        if (len) {
          fieldValue.min_value = formValue.commerce_product_taxes[len - 1].max_value;
        }
      },
      change: async (formValue) => {
        // formValue.commerce_product_taxes.push(fieldValue);
        Vue.set(formValue, 'commerce_product_taxes', formValue.commerce_product_taxes);
      },
    },
    industry_product_taxes: {
      beforeCreate(formValue, fieldValue) {
        const len = formValue.industry_product_taxes && formValue.industry_product_taxes.length;
        if (len) {
          fieldValue.min_value = formValue.industry_product_taxes[len - 1].max_value;
        }
      },
    },
    billings: {
      change(formValue) {
        let monthTotal = 0;
        let yearTotal = 0;
        let total = 0;
        formValue.billings = formValue.billings || [];
        formValue.billings.forEach((billing) => {
          const value = parseFloat(billing.billing_total_value) || 0;
          const date = moment(billing.reference_month, 'MM/yyyy');
          const today = new Date();
          if (date.isSame(today, 'month')) {
            monthTotal = value;
          }
          if (date.isSame(today, 'year')) {
            yearTotal += value;
          }
          total += value;
        });

        formValue.billing_total_value = total;
        formValue.current_month_total_value = monthTotal;
        formValue.current_year_total_value = yearTotal;
      },
    },
  },
};
