<template>
  <v-dialog v-model="dialog" max-width="600px" scrollable>
    <v-card v-shortkey="{up: ['arrowup'], down: ['arrowdown'], enter: ['enter']}"
            @shortkey="onShortKey">
      <v-toolbar color="grey lighten-4" flat>
        <v-toolbar-title>
          <v-icon :large="false" class="mr-2" color="primary">
            {{ icons.shareAll }}
          </v-icon>
          <strong>Atalhos</strong>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon text @click="onClose">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-text-field v-model="searchText"
                    label="Navegar para"
                    placeholder="Digite o nome do modulo"
                    filled
                    rounded
                    autofocus
                    :loading="searching"
                    class="mb-n6 mt-3 px-3"
                    @input="onSearchChange" />
      <v-card-text class="ma-0 pa-0" style="height: 300px;">
        <v-list dense>
          <v-list-item v-for="item in filteredAppItems"
                       :key="item.title"
                       :class="item === selectedItem ? 'focused-item' : ''"
                       @focus="onItemFocus(item)"
                       @click="onAppItemClick(item)">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-spacer />
      <v-card-actions class="text-size grey lighten-4">
        <v-icon>{{ icons.return }}</v-icon>
        <span class="mr-3">selecionar</span>
        <v-icon>{{ icons.swap }}</v-icon>
        <span>navegar</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { debounce } from 'lodash';
import Fuse from 'fuse.js';
import { mapState } from 'vuex';
import {
  ICON_SHARE_ALL, ICON_CLOSE, ICON_RETURN, ICON_SWAP_VERTICAL,
} from '@constants/icons';

export default {
  name: 'QuickSwitchDialog',
  data() {
    return {
      icons: {
        close: ICON_CLOSE,
        return: ICON_RETURN,
        shareAll: ICON_SHARE_ALL,
        swap: ICON_SWAP_VERTICAL,
      },
      dialog: false,
      searchText: '',
      fuseSearch: {},
      flatAppItems: [],
      filteredAppItems: [],
      searching: false,
      selectedIndex: null,
      selectedItem: {},
    };
  },
  computed: {
    ...mapState({
      appItems: (state) => state.app.items,
    }),
  },
  mounted() {
    this.updateItems();
    const options = {
      keys: ['title', 'key'],
    };
    this.fuseSearch = new Fuse(this.flatAppItems, options);
  },
  methods: {
    onSearchChange() {
      this.searching = true;
      this.debounceSeach(this);
    },
    updateItems() {
      this.flatAppItems = [];
      this.appItems.forEach((item) => {
        this.flatAppItems.push(...this.getDescendants(item));
      });
    },
    getDescendants(menu, ascendantText) {
      ascendantText = ascendantText ? `${ascendantText} > ${menu.title}` : menu.title;
      if (menu.items) {
        const res = [];
        menu.items.forEach((child) => {
          res.push(...this.getDescendants(child, ascendantText));
        });
        return res;
      }
      return [{
        icon: menu.icon,
        title: ascendantText,
        key: menu.title,
        to: menu.to,
      }];
    },
    debounceSeach: debounce(function () {
      if (this.fuseSearch && this.fuseSearch.search && this.searchText) {
        this.filteredAppItems = this.fuseSearch.search(this.searchText).map((res) => res.item);
      } else {
        this.filteredAppItems = this.flatAppItems;
      }

      this.selectedIndex = 0;
      this.selectedItem = this.filteredAppItems[this.selectedIndex];

      this.searching = false;
    }, 100),
    open() {
      this.searchText = '';
      this.selectedIndex = null;
      this.selectedItem = {};
      this.filteredAppItems = this.flatAppItems;
      this.dialog = true;
    },
    onClose() {
      this.dialog = false;
    },
    onItemFocus(item) {
      this.selectedIndex = this.filteredAppItems.findIndex((i) => i === item);
      this.selectedItem = item;
    },
    onShortKey(e) {
      if (e.srcKey === 'up') {
        if (this.selectedIndex === null) {
          this.selectedIndex = this.filteredAppItems.length - 1;
        } else {
          this.selectedIndex = this.selectedIndex === 0 ? this.filteredAppItems.length - 1 : this.selectedIndex - 1;
        }
        this.selectedItem = this.filteredAppItems[this.selectedIndex];
      } else if (e.srcKey === 'down') {
        if (this.selectedIndex === null) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex = this.selectedIndex === this.filteredAppItems.length - 1 ? 0 : this.selectedIndex + 1;
        }
        this.selectedItem = this.filteredAppItems[this.selectedIndex];
      } else if (e.srcKey === 'enter') {
        if (this.selectedIndex !== null) {
          this.onAppItemClick(this.selectedItem);
        }
      }
    },
    onAppItemClick(item) {
      if (item && item.to) {
        this.$router.push({
          name: item.to,
        });
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.focused-item {
  background: #5a70d3;
  .v-icon, .v-list-item__title {
    color: #FFF;
  }
}
.text-size {
  font-size: 1.2em;
  margin-bottom: 3px;
  font-weight: 550;
}
</style>
