import StockHistorySchema from '@schemas/StockHistory';

const DetailsStockHistories = () => import(/* webpackChunkName: "details-stock-histories" */'./DetailsStockHistories');
const ListStockHistories = () => import(/* webpackChunkName: "list-stock-histories" */'./ListStockHistories');

export default [
  {
    path: '/supply/stock/histories',
    name: 'supply-stockhistories-list',
    meta: {
      requiresAuth: true,
      rule: 'stocks',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: StockHistorySchema.titles.items },
      ],
    },
    component: ListStockHistories,
  },
  {
    path: '/supply/stocks/histories/:id',
    name: 'supply-stockhistories-launchs',
    meta: {
      requiresAuth: true,
      rule: 'stocks',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: StockHistorySchema.titles.items, route: 'supply-stockhistories-list' },
        { name: 'Lançamentos' },
      ],
    },
    component: DetailsStockHistories,
  },
  {
    path: '/supply/stocks/histories/:id/filters',
    name: 'supply-stockhistories-launchs-filters',
    meta: {
      requiresAuth: true,
      rule: 'stocks',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: StockHistorySchema.titles.items, route: 'supply-stockhistories-list' },
        { name: 'Lançamentos' },
      ],
    },
    component: DetailsStockHistories,
  },
];
