<template>
  <v-dialog
    v-model="open"
    :max-width="width"
    persistent>
    <GenericDialogCard
      :icon="titleIcon"
      :title="title"
      title-center
      icon-large
      :show-close="showClose"
      :text="message"
      :max-width="width"
      @close="onClose()">
      <template v-if="!hideActions" v-slot:actions>
        <v-spacer class="ml-n7" />
        <div class="mt-n16">
          <Btn ref="defaultButton"
               :disabled="!canConfirm"
               :color="confirmColor"
               :width="btnsWidth"
               class="mx-2 ml-n3"
               type="custom"
               @click.stop="onConfirm()">
            <v-icon>
              {{ confirmIcon }}
            </v-icon>
            <strong :style="{ color: confirmTextColor }">{{ confirmText }}</strong>
          </Btn>
          <Btn
            elevation="0"
            :color="cancelColor"
            :width="btnsWidth"
            class="mx-2"
            type="custom"
            @click.stop="onCancel()">
            <v-icon v-if="cancelIcon" class="mr-2">
              {{ cancelIcon }}
            </v-icon>
            <strong :style="{ color: cancelTextColor }">{{ cancelText }}</strong>
          </Btn>
        </div>
        <v-spacer />
      </template>
    </GenericDialogCard>
  </v-dialog>
</template>

<script>
import { ICON_CLOSE, ICON_ALERT } from '@constants/icons';
import GenericDialogCard from '@sharedComponents/dynamic/GenericDialogCard';
import Btn from '@sharedComponents/app/Btn';

export default {
  components: {
    GenericDialogCard,
    Btn,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: String,
      default: ICON_ALERT,
    },
    message: {
      type: String,
      default: '',
    },
    htmlText: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'Ok',
    },
    confirmIcon: {
      type: String,
      default: '',
    },
    confirmColor: {
      type: String,
      default: 'primary',
    },
    confirmTextColor: {
      type: String,
      default: 'white',
    },
    cancelIcon: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    cancelColor: {
      type: String,
      default: 'error',
    },
    cancelTextColor: {
      type: String,
      default: 'white',
    },
    typeToConfirm: {
      type: Boolean,
      default: false,
    },
    textToCompare: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '480px',
    },
    btnsWidth: {
      type: String,
      default: '98px',
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      open: false,
      text: '',
      closeIcons: ICON_CLOSE,
    };
  },
  computed: {
    canConfirm() {
      return this.typeToConfirm ? this.textToCompare.toLowerCase() === this.text.toLowerCase() : true;
    },
  },
  watch: {
    value(val) {
      this.open = val;
    },
  },
  methods: {
    onConfirm() {
      this.open = false;
      this.$emit('input', false);
      this.$emit('confirm');
    },
    onCancel() {
      this.open = false;
      this.$emit('input', false);
      this.$emit('cancel');
    },
    onClose() {
      this.open = false;
      this.$emit('input', false);
      this.$emit('close');
    },
  },
};
</script>
