import { ICON_TAXES } from '@constants/icons';

export default {
  service: 'RemittanceConsignmentItemTaxes',
  icon: ICON_TAXES,
  routes: {
    list: '',
    edit: '',
    create: '',
  },
  titles: {
    item: 'Conferência de Imposto',
    items: 'Conferência de Impostos',
    new: 'Adicionar Item',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: '',
  fields: [
    {
      label: 'Código',
      name: 'product.code',
      type: 'number',
      list: true,
    },
    {
      label: 'Nome',
      columnWidth: '300px',
      name: 'name',
      type: 'string',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 8,
    },
    {
      label: 'Base',
      name: 'simple_national.base',
      formattedName: 'simple_national.base_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Alíquota',
      name: 'simple_national.aliquot',
      formattedName: 'simple_national.aliquot_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'simple_national.value',
      formattedName: 'simple_national.value_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Base',
      name: 'icms.base',
      formattedName: 'icms.base_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Alíquota',
      name: 'icms.aliquot',
      formattedName: 'icms.aliquot_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'icms.value',
      formattedName: 'icms.value_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Base',
      name: 'icms_st.base',
      formattedName: 'icms_st.base_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Alíquota',
      name: 'icms_st.aliquot',
      formattedName: 'icms_st.aliquot_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'icms_st.value',
      formattedName: 'icms_st.value_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Base',
      name: 'ipi.base',
      formattedName: 'ipi.base_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Alíquota',
      name: 'ipi.aliquot',
      formattedName: 'ipi.aliquot_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'ipi.value',
      formattedName: 'ipi.value_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Base',
      name: 'pis.base',
      formattedName: 'pis.base_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Alíquota',
      name: 'pis.aliquot',
      formattedName: 'pis.aliquot_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'pis.value',
      formattedName: 'pis.value_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Base',
      name: 'cofins.base',
      formattedName: 'cofins.base_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Alíquota',
      name: 'cofins.aliquot',
      formattedName: 'cofins.aliquot_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Valor',
      name: 'cofins.value',
      formattedName: 'cofins.value_formatted',
      type: 'number',
      list: true,
    },
  ],
};
