import Vue from 'vue';
import { applyListPriceInOneItem } from './Common/ApplyListPrice';
import { formatCurrency } from '@plugins/app-utils';
import OrderServiceSchema from '@schemas/OrderService';

/** *
 * Sample of Business Rules Layer
 * TODO: Documentar camada de negócios do front
 * TODO: implementar regras para lidar com childSchemas na camada de negócios
 * Available functions:
 *  - for simple fields: change
 *  - for arrays: beforeUpdate, beforeCreate, update, create, select
 */

const calcTotalAndAbsoluteDiscount = (formValue) => {
  const discount = (parseFloat(formValue.discount_percentage_value) || 0) / 100;
  const unit = parseFloat(formValue.unit_value) || 0;
  const quantity = parseFloat(formValue.quantity) || 0;
  Vue.set(formValue, 'unit_value', formValue.unit_value);
  Vue.set(formValue, 'discount', formValue.discount);
  Vue.set(formValue, 'quantity', formValue.quantity);

  const total = unit * quantity;
  const totalWithDiscount = total - total * discount;
  const discountValue = total - totalWithDiscount;
  Vue.set(formValue, 'discount_value', discountValue);
  Vue.set(formValue, 'total_without_discount_value', total);
  Vue.set(formValue, 'total_value', totalWithDiscount);
};

const calcItemTaxes = (formValue) => {
  const icms = { value: 0, aliquot: 0, base: 0 };
  const icms_st = { value: 0, aliquot: 0, base: 0 };
  const ipi = { value: 0, aliquot: 0, base: 0 };
  Vue.set(formValue, 'icms', icms);
  Vue.set(formValue, 'icms_st', icms_st);
  Vue.set(formValue, 'ipi', ipi);

  return {
    icms,
    icms_st,
    ipi,
  };
};

const setProduct = async (formValue, fieldValue) => {
  await Vue.prototype.$api.Products.showGraphql(fieldValue, ['id', 'to_s', 'code', 'unity_id', 'ncm_id', 'cest_id', 'purchase_cost', 'sale_value']).then(async (response) => {
    let product = response.data;

    const { buyer_id, price_list_id } = formValue;

    if (buyer_id && price_list_id) {
      product.product_id = product.id;
      product.quantity = formValue.quantity;

      const payloadPriceList = { buyer_id, price_list_id, items: [product] };
      const responsePriceList = await applyListPriceInOneItem(payloadPriceList, OrderServiceSchema.service);
      const [productPriceListCalculated] = responsePriceList.filter((item) => item.id === product.id);
      product = { ...product, ...productPriceListCalculated };
    }

    if (product) {
      formValue.product = product;
      formValue.product_id = product?.id ?? '';
      formValue.product_code = product.id;
      Vue.set(formValue, 'product', product);
      Vue.set(formValue, 'product_id', product.id);
      Vue.set(formValue, 'product_code', product.id);

      formValue.name = product.to_s;
      Vue.set(formValue, 'name', product.to_s);

      formValue.unit_cost_value = product.purchase_cost;
      formValue.unit_value = product.sale_value;
      Vue.set(formValue, 'unit_cost_value', product.purchase_cost);
      Vue.set(formValue, 'unit_value', product.sale_value);

      if (product?.unity_id) {
        const unity = await Vue.prototype.$api.AllTypes.show(product.unity_id);
        formValue.unity = unity;
        formValue.unity_id = unity.id;
        Vue.set(formValue, 'unity_id', product.unity_id);
      }

      if (product?.service_code) {
        formValue.service_code = { ...product.service_code };
        formValue.service_code_id = product.service_code_id;
        Vue.set(formValue, 'service_code', product.service_code);
        Vue.set(formValue, 'service_code_id', product?.service_code?.id);
      }
      calcTotalAndAbsoluteDiscount(formValue);
    }
  });
};

export default {
  screenEvents: {
    async beforeSave(formValue) {
      const taxes = calcItemTaxes(formValue);
      if (!formValue.nature_operation) {
        const { data } = await Vue.prototype.$api.NatureOperations.show(formValue.nature_operation_id);
        formValue.nature_operation = data;
      }

      if (formValue.service_code_id) {
        const responseServiceCode = await Vue.prototype.$api.AllTypes.show(formValue.service_code_id);
        formValue.service_code = responseServiceCode;
      }
      if (formValue.unity_id) {
        const responseUnity = await Vue.prototype.$api.AllTypes.show(formValue.unity_id);
        formValue.unity_id = responseUnity;
      }
      formValue = { ...formValue, ...taxes };
    },
    beforeDelete() {
      Vue.prototype.$notifyError('Não é possível excluir um serviço');
    },
  },
  fieldEvents: {
    product_code: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    product_id: {
      change: async (formValue, fieldValue) => {
        await setProduct(formValue, fieldValue);
      },
    },
    unity_id: {
      change: async (formValue, fieldValue, fieldObjectValue) => {
        formValue.unity = fieldObjectValue;
        Vue.set(formValue, 'unity', fieldObjectValue);
      },
    },
    unit_value: {
      change: (formValue) => {
        calcTotalAndAbsoluteDiscount(formValue);
        Vue.set(formValue, 'unit_value_formatted', formatCurrency(formValue.unit_value));
      },
    },
    quantity: {
      change: (formValue) => {
        const unit = parseFloat(formValue.unit_value) || 0;
        const quantity = parseFloat(formValue.quantity) || 0;
        const total = unit * quantity - (parseFloat(formValue.discount_value) || 0);
        Vue.set(formValue, 'total_value', total);
        calcTotalAndAbsoluteDiscount(formValue);
      },
    },
    total_value: {
      change: (formValue, fieldValue) => {
        const quantity = parseFloat(formValue.quantity) || 0;
        const discount = parseFloat(formValue.discount_percentage_value) || 0;
        if (quantity > 0) {
          const totalWithoutDiscount = (parseFloat(fieldValue) * 100) / (100 - discount);
          const unit = totalWithoutDiscount / quantity;
          Vue.set(formValue, 'unit_value', unit);
          Vue.set(formValue, 'total_without_discount_value', totalWithoutDiscount);
          Vue.set(formValue, 'discount_value', totalWithoutDiscount - fieldValue);
        }
      },
    },
    discount_value: {
      change: (formValue, fieldValue) => {
        const total = (parseFloat(formValue.unit_value) || 0) * (parseFloat(formValue.quantity) || 0);
        const totalWithDiscount = total - (parseFloat(fieldValue) || 0);
        const discountPercent = total > 0 ? (parseFloat(fieldValue) / total) * 100 : 0;
        Vue.set(formValue, 'discount_percentage_value', discountPercent);
        Vue.set(formValue, 'total_without_discount_value', total);
        Vue.set(formValue, 'total_value', totalWithDiscount);
      },
    },
    discount_percentage_value: {
      change: (formValue, fieldValue) => {
        const total = (parseFloat(formValue.unit_value) || 0) * (parseFloat(formValue.quantity) || 0);
        const totalWithDiscount = total - total * ((parseFloat(fieldValue) || 0) / 100);
        const discountValue = total - totalWithDiscount;
        Vue.set(formValue, 'discount_value', discountValue);
        Vue.set(formValue, 'total_without_discount_value', total);
        Vue.set(formValue, 'total_value', totalWithDiscount);
      },
    },
    service_code_id: {
      change: async (formValue, fieldValue, fieldObjectValue) => {
        formValue.service_code = fieldObjectValue;
        Vue.set(formValue, 'service_code', fieldObjectValue);
      },
    },
    nature_operation_id: {
      change: async (formValue, fieldValue) => {
        const nature_operation_id = fieldValue;
        // @TODO - Refactor to Graphql ASAP
        const { data } = await Vue.prototype.$api.NatureOperations.show(nature_operation_id) ?? { data: {} };
        formValue.nature_operation = data;
        formValue.nature_operation_id = nature_operation_id;
      },
    },
  },
};
