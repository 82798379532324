<template>
  <v-text-field
    v-model="fieldValue"
    v-mask="['##:##', '###:##']"
    :required="checkRequired()"
    :prefix="field.prefix"
    :counter="maxLength"
    :maxlength="maxLength"
    :suffix="field.suffix"
    :multi-line="field.multiLine"
    :hint="field.hint"
    :persistent-hint="true"
    :disabled="checkDisabled()"
    :rules="rules"
    :label="field.label"
    type="time"
    :class="field.class"
    :autofocus="field.autofocus"
    :readonly="readOnly || field.readOnly"
    :validate-on-blur="field.validateOnBlur"
    :rounded="field.rounded"
    :filled="field.filled"
    :prepend-icon="innerIcon ? '' : prependIcon"
    v-bind="$attrs"
    v-on="$listeners"
    @change="fieldChanged"
    @blur="onBlurTextField">
    <template v-slot:append-outer>
      <slot name="append-outer" />
    </template>
  </v-text-field>
</template>

<script>
import Vue from 'vue';
import { ICON_CLOCK } from '@constants/icons';
import { slugify } from '@plugins/app-utils';
import VueTheMask from 'vue-the-mask';
import DynamicFieldMixin from './DynamicFieldMixin';
import { MASK_TIME_COUNT } from '@constants/masks';
import '@plugins/rules';

Vue.use(VueTheMask);

export default {
  name: 'DynamicHoursField',

  mixins: [DynamicFieldMixin],

  data() {
    return {
      fieldValue: '',
      defaultIcon: ICON_CLOCK,
      hoursMask: MASK_TIME_COUNT,
      rules: [
        this.$rules.hours(),
        // (v) => (v && /^[0-9]*:[0-5][0-9]/.test(v) ? true : 'hora errada'),
      ],
    };
  },

  computed: {
    fieldType() {
      if (this.field.password) {
        return 'password';
      }
      if (this.field.numericKeyboard) {
        return 'tel';
      }
      return 'text';
    },
    maxLength() {
      if (this.field.maxLength && String(this.field.maxLength) !== '0') {
        return parseInt(this.field.maxLength);
      }

      return undefined;
    },
    prependIcon() {
      return this.field.prependIcon === undefined ? this.defaultIcon : this.field.prependIcon;
    },
    appendIcon() {
      return this.field.appendIcon;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
  },
  mounted() {
    this.updatedFormValue(this.value);
  },

  methods: {
    onBlurTextField() {
      if (this.field.slug) {
        this.fieldValue = slugify(this.fieldValue);
        if (this.maxLength) {
          this.fieldValue = this.fieldValue.substr(0, this.maxLength);
        }
        this.fieldChanged(this.fieldValue);
      }
    },

    fieldChanged(value, objectValue) {
      if (this.field.change && typeof this.field.change === 'function') {
        this.field.change(value);
      }

      this.$emit('input', this.field, value, { value: '00:00', valueInMinutes: 0 });
      this.$emit('change', this.field, value, objectValue);
    },
    convertMinutesToHourString(timeInMinutes = 0) {
      const hours = Math.trunc(timeInMinutes / 60);
      const minutes = timeInMinutes % 60;
      return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    },
    convertToMinutes(time = '00:00') {
      const [hours, minutes] = time.split(':');
      return Number(hours) * 60 + Number(minutes);
    },
    validateHour(str) {
      return /^[0-9]{2,}:[0-5][0-9]/g.test(str);
    },
  },
};
</script>

<style lang="scss"></style>
