import BiddingsSchema from '@schemas/Bidding';

const ListBiddings = () => import(/* webpackChunkName: "sellmore-biddings-list" */'./ListBiddings');

export default [
  {
    path: BiddingsSchema.routes.list.path,
    name: BiddingsSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      /* @TODO - Criar rules do Orçamentos no backend */
      rule: 'order_sales',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: 'Licitações' },
      ],
    },
    component: ListBiddings,
  },
];
