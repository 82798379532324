export default {
  screenEvents: {
    async beforeCreate(formValue) {
      // Set current date in Date fields
      const currentDate = new Date();
      formValue.date = currentDate;
      formValue.start_date = currentDate;
      formValue.end_date = currentDate;
      formValue.type = 'nonpaid';

      // getStartBalance(formValue);
    },
    async beforeUpdate() {
      // getStartBalance(formValue);
    },
  },
  fieldEvents: {
  },
};
