export default [
  {
    path: '/settings/subscriptions',
    name: 'settings-subscriptions-list',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: 'Assinatura' },
      ],
    },
    component: () => import(/* webpackChunkName: "list-subscriptions" */'./ListSubscriptions'),
  },
];
