<template>
  <DataList
    :headers="taxesHeaders"
    :sort-by="['id']"
    item-key="id"
    :no-edit="true"
    :no-delete="true"
    :no-check="true"
    :hide-default-header="hideDefaultHeader"
    :sort-desc="[false, true]"
    :items="taxes">
    <template v-slot:customHeader>
      <thead :class="`v-data-table-header ${childSchema.customHeader.customClass}`">
        <tr>
          <th v-for="(n, index) in customHeader.ungroupedHeadersFront" :key="index" />
          <template v-for="header in childSchema.customHeader.groupHeaders">
            <th :key="header.text" class="text-center parent-header" :colspan="header.colspan" :class="header.class">
              {{ header.text }}
            </th>
          </template>
        </tr>
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="text-center"
            :class="index >= childSchema.customHeader.ungroupedHeadersFront ? 'child-header' : ''"
            :style="{ minWidth: header.width }">
            {{ header.text }}
          </th>
        </tr>
      </thead>
    </template>
  </DataList>
</template>

<script>
import { ICON_REFRESH } from '@constants/icons';
import DataList from '@sharedComponents/dynamic/DataList';
import SchemaUtil from '@schemas/Util';

export default {
  name: 'DevolutionSalesTaxes',
  components: {
    DataList,
  },
  props: {
    taxes: {
      type: Array,
      default: () => [],
    },
    childSchema: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      icons: {
        refresh: ICON_REFRESH,
      },
      hideDefaultHeader: true,
      customHeader: {
        ungroupedHeadersFront: 2,
        groupHeaders: [
          { text: 'SIMPLES NACIONAL', colspan: 3, class: 'order-sale-header' },
          { text: 'ICMS', colspan: 3, class: 'order-sale-header' },
          { text: 'ICMS ST', colspan: 3, class: 'order-sale-header' },
          { text: 'IPI', colspan: 3, class: 'order-sale-header' },
          { text: 'PIS', colspan: 3, class: 'order-sale-header' },
          { text: 'COFINS', colspan: 3, class: 'order-sale-header' },
        ],
      },
      headers: [
        { text: 'Código', value: 'product_id' },
        { text: 'Produto', value: 'product.to_s' },
        { text: 'Base', value: 'simple_national.base' },
        { text: 'Alíquota', value: 'simple_national.aliquot' },
        { text: 'Valor', value: 'simple_national.value' },
        { text: 'Base', value: 'icms.base' },
        { text: 'Alíquota', value: 'icms.aliquot' },
        { text: 'Valor', value: 'icms.value' },
        { text: 'Base', value: 'icms_st.base' },
        { text: 'Alíquota', value: 'icms_st.aliquot' },
        { text: 'Valor', value: 'icms_st.value' },
        { text: 'Base', value: 'ipi.base' },
        { text: 'Alíquota', value: 'ipi.aliquot' },
        { text: 'Valor', value: 'ipi.value' },
        { text: 'Base', value: 'pis.base' },
        { text: 'Alíquota', value: 'pis.aliquot' },
        { text: 'Valor', value: 'pis.value' },
        { text: 'Base', value: 'cofins.base' },
        { text: 'Alíquota', value: 'cofins.aliquot' },
        { text: 'Valor', value: 'cofins.value' },
      ],
      actions: [],
      filterParams: {},
    };
  },
  computed: {
    taxesHeaders() {
      return SchemaUtil.getHeaders(this.childSchema.schema, false);
    },
  },
};
</script>
