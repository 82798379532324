/*
 * Constants of options to inserting in selects of UI
 *
 * When create option array, keep the organization in the
 * exports in alphabetical order, and insert the options in
 * default const option export in the end of the file
 *
 */
export const OPTIONS_BANK_ACCOUNT_TYPE = [
  { text: 'Corrente', value: '1' },
  { text: 'Poupança', value: '2' },
];

export const OPTIONS_BANK_CODE = [
  { text: '341 ITAÚ UNIBANCO S.A', value: '341' },
  { text: '237 BRADESCO S.A', value: '237' },
  { text: '001 BANCO DO BRASIL S.A (BB)', value: '001' },
  { text: '033 BANCO SANTANDER BRASIL S.A', value: '033' },
  { text: '104 CAIXA ECONÔMICA FEDERAL (CEF)', value: '104' },
  { text: '748 SICREDI S.A', value: '748' },
  { text: '269 HSBC BANCO DE INVESTIMENTO', value: '269' },
  { text: '260 NU PAGAMENTOS S.A (NUBANK)', value: '260' },
  { text: '274 BANCO MONEY PLUS', value: '274' },
  { text: '290 Pagseguro Internet S.A (PagBank)', value: '290' },
  { text: '380 PicPay Servicos S.A.', value: '380' },
  { text: '323 Mercado Pago - conta do Mercado Livre', value: '323' },
  { text: '756 BANCOOB (BANCO COOPERATIVO DO BRASIL)', value: '756' },
  { text: '335 Banco Digio S.A', value: '335' },
  { text: '237 NEXT BANK (UTILIZAR O MESMO CÓDIGO DO BRADESCO)', value: '237' },
  { text: '637 BANCO SOFISA S.A (SOFISA DIRETO)', value: '637' },
  { text: '077 BANCO INTER S.A', value: '077' },
  { text: '212 BANCO ORIGINAL S.A', value: '212' },
  { text: '655 BANCO VOTORANTIM S.A', value: '655' },
  { text: '655 NEON PAGAMENTOS S.A (OS MESMOS DADOS DO BANCO VOTORANTIM)', value: '655' },
  { text: '041 BANRISUL – BANCO DO ESTADO DO RIO GRANDE DO SUL S.A', value: '041' },
  { text: '389 BANCO MERCANTIL DO BRASIL S.A', value: '389' },
  { text: '422 BANCO SAFRA S.A', value: '422' },
  { text: '070 BANCO DE BRASÍLIA (BRB)', value: '070' },
  { text: '136 UNICRED COOPERATIVA', value: '136' },
  { text: '741 BANCO RIBEIRÃO PRETO', value: '741' },
  { text: '739 BANCO CETELEM S.A', value: '739' },
  { text: '743 BANCO SEMEAR S.A', value: '743' },
  { text: '100 PLANNER CORRETORA DE VALORES S.A', value: '100' },
  { text: '096 BANCO B3 S.A', value: '096' },
  { text: '747 Banco RABOBANK INTERNACIONAL DO BRASIL S.A', value: '747' },
  { text: '752 BNP PARIBAS BRASIL S.A', value: '752' },
  { text: '091 UNICRED CENTRAL RS', value: '091' },
  { text: '399 KIRTON BANK', value: '399' },
  { text: '108 PORTOCRED S.A', value: '108' },
  { text: '757 BANCO KEB HANA DO BRASIL S.A', value: '757' },
  { text: '102 XP INVESTIMENTOS S.A', value: '102' },
  { text: '348 BANCO XP S/A', value: '348' },
  { text: '340 SUPER PAGAMENTOS S/A (SUPERDITAL)', value: '340' },
  { text: '364 GERENCIANET PAGAMENTOS DO BRASIL', value: '364' },
  { text: '084 UNIPRIME NORTE DO PARANÁ', value: '084' },
  { text: '180 CM CAPITAL MARKETS CCTVM LTDA', value: '180' },
  { text: '066 BANCO MORGAN STANLEY S.A', value: '066' },
  { text: '015 UBS BRASIL CCTVM S.A', value: '015' },
  { text: '143 TREVISO CC S.A', value: '143' },
  { text: '062 HIPERCARD BM S.A', value: '062' },
  { text: '074 BCO. J.SAFRA S.A', value: '074' },
  { text: '099 UNIPRIME CENTRAL CCC LTDA', value: '099' },
  { text: '025 BANCO ALFA S.A.', value: '025' },
  { text: '075 BCO ABN AMRO S.A', value: '075' },
  { text: '040 BANCO CARGILL S.A', value: '040' },
  { text: '190 SERVICOOP', value: '190' },
  { text: '063 BANCO BRADESCARD', value: '063' },
  { text: '191 NOVA FUTURA CTVM LTDA', value: '191' },
  { text: '064 GOLDMAN SACHS DO BRASIL BM S.A', value: '064' },
  { text: '097 CCC NOROESTE BRASILEIRO LTDA', value: '097' },
  { text: '016 CCM DESP TRÂNS SC E RS', value: '016' },
  { text: '012 BANCO INBURSA', value: '012' },
  { text: '003 BANCO DA AMAZONIA S.A', value: '003' },
  { text: '060 CONFIDENCE CC S.A', value: '060' },
  { text: '037 BANCO DO ESTADO DO PARÁ S.A', value: '037' },
  { text: '159 CASA CREDITO S.A', value: '159' },
  { text: '172 ALBATROSS CCV S.A', value: '172' },
  { text: '085 COOP CENTRAL AILOS', value: '085' },
  { text: '114 CENTRAL COOPERATIVA DE CRÉDITO DO ESPÍRITO SANTO', value: '114' },
  { text: '036 BANCO BBI S.A', value: '036' },
  { text: '394 BANCO BRADESCO FINANCIAMENTOS S.A', value: '394' },
  { text: '004 BANCO DO NORDESTE DO BRASIL S.A.', value: '004' },
  { text: '320 BANCO CCB BRASIL S.A', value: '320' },
  { text: '189 HS FINANCEIRA', value: '189' },
  { text: '105 LECCA CFI S.A', value: '105' },
  { text: '076 BANCO KDB BRASIL S.A.', value: '076' },
  { text: '082 BANCO TOPÁZIO S.A', value: '082' },
  { text: '286 CCR DE OURO', value: '286' },
  { text: '093 PÓLOCRED SCMEPP LTDA', value: '093' },
  { text: '273 CCR DE SÃO MIGUEL DO OESTE', value: '273' },
  { text: '157 ICAP DO BRASIL CTVM LTDA', value: '157' },
  { text: '183 SOCRED S.A', value: '183' },
  { text: '014 NATIXIS BRASIL S.A', value: '014' },
  { text: '130 CARUANA SCFI', value: '130' },
  { text: '127 CODEPE CVC S.A', value: '127' },
  { text: '079 BANCO ORIGINAL DO AGRONEGÓCIO S.A', value: '079' },
  { text: '081 BBN BANCO BRASILEIRO DE NEGOCIOS S.A', value: '081' },
  { text: '118 STANDARD CHARTERED BI S.A', value: '118' },
  { text: '133 CRESOL CONFEDERAÇÃO', value: '133' },
  { text: '121 BANCO AGIBANK S.A', value: '121' },
  { text: '083 BANCO DA CHINA BRASIL S.A', value: '083' },
  { text: '138 GET MONEY CC LTDA', value: '138' },
  { text: '024 BCO BANDEPE S.A', value: '024' },
  { text: '095 BANCO CONFIDENCE DE CÂMBIO S.A', value: '095' },
  { text: '094 BANCO FINAXIS', value: '094' },
  { text: '276 SENFF S.A', value: '276' },
  { text: '137 MULTIMONEY CC LTDA', value: '137' },
  { text: '092 BRK S.A', value: '092' },
  { text: '047 BANCO BCO DO ESTADO DE SERGIPE S.A', value: '047' },
  { text: '144 BEXS BANCO DE CAMBIO S.A.', value: '144' },
  { text: '126 BR PARTNERS BI', value: '126' },
  { text: '301 BPP INSTITUIÇÃO DE PAGAMENTOS S.A', value: '301' },
  { text: '173 BRL TRUST DTVM SA', value: '173' },
  { text: '119 BANCO WESTERN UNION', value: '119' },
  { text: '254 PARANA BANCO S.A', value: '254' },
  { text: '268 BARIGUI CH', value: '268' },
  { text: '107 BANCO BOCOM BBM S.A', value: '107' },
  { text: '412 BANCO CAPITAL S.A', value: '412' },
  { text: '124 BANCO WOORI BANK DO BRASIL S.A', value: '124' },
  { text: '149 FACTA S.A. CFI', value: '149' },
  { text: '197 STONE PAGAMENTOS S.A', value: '197' },
  { text: '142 BROKER BRASIL CC LTDA', value: '142' },
  { text: '389 BANCO MERCANTIL DO BRASIL S.A.', value: '389' },
  { text: '184 BANCO ITAÚ BBA S.A', value: '184' },
  { text: '634 BANCO TRIANGULO S.A (BANCO TRIÂNGULO)', value: '634' },
  { text: '545 SENSO CCVM S.A', value: '545' },
  { text: '132 ICBC DO BRASIL BM S.A', value: '132' },
  { text: '298 VIPS CC LTDA', value: '298' },
  { text: '129 UBS BRASIL BI S.A', value: '129' },
  { text: '128 MS BANK S.A BANCO DE CÂMBIO', value: '128' },
  { text: '194 PARMETAL DTVM LTDA', value: '194' },
  { text: '310 VORTX DTVM LTDA', value: '310' },
  { text: '163  COMMERZBANK BRASIL S.A BANCO MÚLTIPLO', value: '163' },
  { text: '280 AVISTA S.A', value: '280' },
  { text: '146 GUITTA CC LTDA', value: '146' },
  { text: '279 CCR DE PRIMAVERA DO LESTE', value: '279' },
  { text: '182 DACASA FINANCEIRA S/A', value: '182' },
  { text: '278 GENIAL INVESTIMENTOS CVM S.A', value: '278' },
  { text: '271 IB CCTVM LTDA', value: '271' },
  { text: '021 BANCO BANESTES S.A', value: '021' },
  { text: '246 BANCO ABC BRASIL S.A', value: '246' },
  { text: '751 SCOTIABANK BRASIL', value: '751' },
  { text: '208 BANCO BTG PACTUAL S.A', value: '208' },
  { text: '746 BANCO MODAL S.A', value: '746' },
  { text: '241 BANCO CLASSICO S.A', value: '241' },
  { text: '612 BANCO GUANABARA S.A', value: '612' },
  { text: '604 BANCO INDUSTRIAL DO BRASIL S.A', value: '604' },
  { text: '505 BANCO CREDIT SUISSE (BRL) S.A', value: '505' },
  { text: '196 BANCO FAIR CC S.A', value: '196' },
  { text: '300 BANCO LA NACION ARGENTINA', value: '300' },
  { text: '477 CITIBANK N.A', value: '477' },
  { text: '266 BANCO CEDULA S.A', value: '266' },
  { text: '122 BANCO BRADESCO BERJ S.A', value: '122' },
  { text: '376 BANCO J.P. MORGAN S.A', value: '376' },
  { text: '473 BANCO CAIXA GERAL BRASIL S.A', value: '473' },
  { text: '745 BANCO CITIBANK S.A', value: '745' },
  { text: '120 BANCO RODOBENS S.A', value: '120' },
  { text: '265 BANCO FATOR S.A', value: '265' },
  { text: '007 BNDES (Banco Nacional do Desenvolvimento Social)', value: '007' },
  { text: '188 ATIVA S.A INVESTIMENTOS', value: '188' },
  { text: '134 BGC LIQUIDEZ DTVM LTDA', value: '134' },
  { text: '641 BANCO ALVORADA S.A', value: '641' },
  { text: '029 BANCO ITAÚ CONSIGNADO S.A', value: '029' },
  { text: '243 BANCO MÁXIMA S.A', value: '243' },
  { text: '078 HAITONG BI DO BRASIL S.A', value: '078' },
  { text: '111 BANCO OLIVEIRA TRUST DTVM S.A', value: '111' },
  { text: '017 BNY MELLON BANCO S.A', value: '017' },
  { text: '174 PERNAMBUCANAS FINANC S.A', value: '174' },
  { text: '495 LA PROVINCIA BUENOS AIRES BANCO', value: '495' },
  { text: '125 BRASIL PLURAL S.A BANCO', value: '125' },
  { text: '488 JPMORGAN CHASE BANK', value: '488' },
  { text: '065 BANCO ANDBANK S.A', value: '065' },
  { text: '492 ING BANK N.V', value: '492' },
  { text: '250 BANCO BCV', value: '250' },
  { text: '145 LEVYCAM CCV LTDA', value: '145' },
  { text: '494 BANCO REP ORIENTAL URUGUAY', value: '494' },
  { text: '253 BEXS CC S.A', value: '253' },
  { text: '213 BCO ARBI S.A', value: '213' },
  { text: '139 INTESA SANPAOLO BRASIL S.A', value: '139' },
  { text: '018 BANCO TRICURY S.A', value: '018' },
  { text: '630 BANCO INTERCAP S.A', value: '630' },
  { text: '224 BANCO FIBRA S.A', value: '224' },
  { text: '600 BANCO LUSO BRASILEIRO S.A', value: '600' },
  { text: '623 BANCO PAN', value: '623' },
  { text: '204 BANCO BRADESCO CARTOES S.A', value: '204' },
  { text: '479 BANCO ITAUBANK S.A', value: '479' },
  { text: '456 BANCO MUFG BRASIL S.A', value: '456' },
  { text: '464 BANCO SUMITOMO MITSUI BRASIL S.A', value: '464' },
  { text: '613 OMNI BANCO S.A', value: '613' },
  { text: '652 ITAÚ UNIBANCO HOLDING BM S.A', value: '652' },
  { text: '653 BANCO INDUSVAL S.A', value: '653' },
  { text: '069 BANCO CREFISA S.A', value: '069' },
  { text: '370 BANCO MIZUHO S.A', value: '370' },
  { text: '249 BANCO INVESTCRED UNIBANCO S.A', value: '249' },
  { text: '318 BANCO BMG S.A', value: '318' },
  { text: '626 BANCO FICSA S.A', value: '626' },
  { text: '270 SAGITUR CC LTDA', value: '270' },
  { text: '366 BANCO SOCIETE GENERALE BRASIL', value: '366' },
  { text: '113 MAGLIANO S.A', value: '113' },
  { text: '131 TULLETT PREBON BRASIL CVC LTDA', value: '131' },
  { text: '011 C.SUISSE HEDGING-GRIFFO CV S.A (Credit Suisse)', value: '011' },
  { text: '611 BANCO PAULISTA', value: '611' },
  { text: '755 BOFA MERRILL LYNCH BM S.A', value: '755' },
  { text: '089 CCR REG MOGIANA', value: '089' },
  { text: '643 BANCO PINE S.A', value: '643' },
  { text: '140 EASYNVEST - TÍTULO CV S.A', value: '140' },
  { text: '707 BANCO DAYCOVAL S.A', value: '707' },
  { text: '288 CAROL DTVM LTDA', value: '288' },
  { text: '101 RENASCENCA DTVM LTDA', value: '101' },
  { text: '487 DEUTSCHE BANK S.A BANCO ALEMÃO', value: '487' },
  { text: '233 BANCO CIFRA', value: '233' },
  { text: '177 GUIDE', value: '177' },
  { text: '633 BANCO RENDIMENTO S.A', value: '633' },
  { text: '218 BANCO BS2 S.A', value: '218' },
  { text: '292 BS2 DISTRIBUIDORA DE TÍTULOS E INVESTIMENTOS', value: '292' },
  { text: '169 BANCO OLÉ BONSUCESSO CONSIGNADO S.A', value: '169' },
  { text: '293 LASTRO RDV DTVM LTDA', value: '293' },
  { text: '285 FRENTE CC LTDA', value: '285' },
  { text: '080 B&T CC LTDA', value: '080' },
  { text: '753 NOVO BANCO CONTINENTAL S.A BM', value: '753' },
  { text: '222 BANCO CRÉDIT AGRICOLE BR S.A', value: '222' },
  { text: '754 BANCO SISTEMA', value: '754' },
  { text: '098 CREDIALIANÇA CCR', value: '098' },
  { text: '610 BANCO VR S.A', value: '610' },
  { text: '712 BANCO OURINVEST S.A', value: '712' },
  { text: '010 CREDICOAMO', value: '010' },
  { text: '283 RB CAPITAL INVESTIMENTOS DTVM LTDA', value: '283' },
  { text: '217 BANCO JOHN DEERE S.A', value: '217' },
  { text: '117 ADVANCED CC LTDA', value: '117' },
  { text: '336 BANCO C6 S.A - C6 BANK', value: '336' },
  { text: '654 BANCO DIGIMAIS S.A', value: '654' },
  { text: '403 CORA SOCIEDADE DE CRÉDITO DIRETO S.A.', value: '403' },
];

export const OPTIONS_BANK_LAYOUT = [
  { text: 'Banco do Brasil 240', value: 'banco_brasil_240' },
  { text: 'Banco do Brasil 400', value: 'banco_brasil_400' },
  { text: 'Banco Brasília 240', value: 'banco_brasilia_240' },
  { text: 'Banco Brasília 400', value: 'banco_brasilia_400' },
  { text: 'Banco Nordeste 240', value: 'banco_nordeste_240' },
  { text: 'Banco Nordeste 400', value: 'banco_nordeste_400' },
  { text: 'Banestes 240', value: 'banestes_240' },
  { text: 'Banestes 400', value: 'banestes_400' },
  { text: 'Banrisul 240', value: 'banrisul_240' },
  { text: 'Banrisul 400', value: 'banrisul_400' },
  { text: 'Base 240', value: 'base_240' },
  { text: 'Base 400', value: 'base_400' },
  { text: 'Bradesco 240', value: 'bradesco_240' },
  { text: 'Bradesco 400', value: 'bradesco_400' },
  { text: 'BTG Pactual (Digital Bank)', value: 'btg_pactual_208' },
  { text: 'Caixa 240', value: 'caixa_240' },
  { text: 'Caixa 400', value: 'caixa_400' },
  { text: 'Cecred 240', value: 'cecred_240' },
  { text: 'Cecred 400', value: 'cecred_400' },
  { text: 'Citibank 240', value: 'citibank_240' },
  { text: 'Citibank 400', value: 'citibank_400' },
  { text: 'Credisis 240', value: 'credisis_240' },
  { text: 'Credisis 400', value: 'credisis_400' },
  { text: 'HSBC 240', value: 'hsbc_240' },
  { text: 'HSBC 400', value: 'hsbc_400' },
  { text: 'Itaú 240', value: 'itau_240' },
  { text: 'Itaú 400', value: 'itau_400' },
  { text: 'Safra 240', value: 'safra_240' },
  { text: 'Safra 400', value: 'safra_400' },
  { text: 'Santander 240', value: 'santander_240' },
  { text: 'Santander 400', value: 'santander_400' },
  { text: 'Sicoob 240', value: 'sicoob_240' },
  { text: 'Sicoob 400', value: 'sicoob_400' },
  { text: 'Sicredi 240', value: 'sicredi_240' },
  { text: 'Sicredi 400', value: 'sicredi_400' },
];

export const OPTIONS_BANK_TYPE = [
  { text: 'Bmp', value: 'bmp' },
  { text: 'Outros', value: 'other' },
];

export const OPTIONS_BIDDINGS_ORDER_BY_FILTERS = [
  { text: 'Data de Edição', value: 'edition_date' },
  { text: 'Data de Inicio', value: 'start_date' },
];

export const OPTIONS_BIDDINGS_ORDER_FILTERS = [
  { text: 'Crescente', value: 'asc' },
  { text: 'Decrescente', value: 'desc' },
];

export const OPTIONS_BTG_ACCOUNT_CURRENCY = [
  { text: 'Real Brasileiro', value: 'BRL' },
  { text: 'Peso Chileno', value: 'CLP' },
];

export const OPTIONS_BTG_ACCOUNT_STATUS = [
  { text: 'Ativa', value: 'ACTIVE' },
  { text: 'Parcialmente Bloqueada', value: 'PARTIAL_BLOCK' },
  { text: 'Totalmente Bloqueada', value: 'TOTAL_BLOCK' },
  { text: 'Inativa', value: 'INACTIVE' },
  { text: 'Encerrada', value: 'CLOSED' },
  { text: 'Em encerramento', value: 'CLOSING' },
];

export const OPTIONS_BTG_ACCOUNT_TRANSACTION_OPERATION = [
  { text: 'Crédito', value: 'CREDIT' },
  { text: 'Débito', value: 'DEBIT' },
];

export const OPTIONS_BTG_ACCOUNT_TRANSACTION_TYPE = [
  { text: 'TED', value: 'TED' },
  { text: 'DOC', value: 'DOC' },
  { text: 'PIX', value: 'PIX' },
];

export const OPTIONS_BTG_ACCOUNT_TYPE = [
  { text: 'Garantia', value: 'ESCROW' },
  { text: 'Transação', value: 'TRANSACTION' },
];

export const OPTIONS_BTG_BILLET_STATUS = [
  { text: 'Atualizado', value: 'UPDATED' },
  { text: 'Atualização falhou', value: 'UPDATE_FAILED' },
  { text: 'Cancelado', value: 'CANCELED' },
  { text: 'Cancelamento falhou', value: 'CANCEL_FAILED' },
  { text: 'Cancelando', value: 'CANCELING' },
  { text: 'Criado', value: 'CREATED' },
  { text: 'Erro', value: 'REQUEST_ERROR' },
  { text: 'Estornado', value: 'REVERSED' },
  { text: 'Falho', value: 'FAILED' },
  { text: 'Pago', value: 'PAID' },
  { text: 'Sucesso', value: 'REQUEST_SUCCESS' },
  { text: 'Processando', value: 'PROCESSING' },
  { text: 'Processando pagamento', value: 'PROCESSING_PAYMENT' },
  { text: 'Rejeitado', value: 'REJECTED' },
];

export const OPTIONS_CHARTS_PERIOD = [
  { text: 'Por Dia', value: 'day' },
  { text: 'Por Semana', value: 'week' },
  { text: 'Por Mês', value: 'month' },
  { text: 'Por Ano', value: 'year' },
];

export const OPTIONS_CASHFLOWS_TYPE = [
  { text: 'Realizado', value: 'paid' },
  { text: 'À Realizar', value: 'nonpaid' },
];

export const OPTIONS_DAS_GUIDE = [
  {
    text: 'Pago',
    value: 'paid',
    color: 'success',
    icon: 'ICON_BANK_CHECK',
  },
  {
    text: 'Em aberto',
    value: 'unpaid',
    color: 'default',
    icon: 'ICON_BANK',
  },
  {
    text: 'Em aberto - Devedor',
    value: 'outdated',
    color: 'error',
    icon: 'ICON_BANK',
  },
];

export const OPTIONS_DATE_ORIGIN = [
  { text: 'Entrega', value: 'delivery' },
  { text: 'Emissão', value: 'emission' },
];

export const OPTIONS_DESTINY_LOCATION = [
  { text: '1 - Operação interna', value: 'internal' },
  { text: '2 - Operação interestadual', value: 'interstate' },
  { text: '3 - Operação externa', value: 'external' },
];

export const OPTIONS_DEMONSTRATIVE_ACCOUNTING_ACCOUNT = [
  { text: 'Nenhum', value: 'nothing' },
  { text: 'Amortização', value: 'amortizations' },
  { text: 'Aplicações financeiras', value: 'financial_applications' },
  { text: 'Comissões', value: 'commissions' },
  { text: 'CSLL', value: 'csll' },
  { text: 'Depreciação', value: 'depreciation' },
  { text: 'Dividendos', value: 'dividend' },
  { text: 'Investimentos', value: 'investments' },
  { text: 'IRPJ', value: 'irpj' },
  { text: 'Juros', value: 'interest' },
  { text: 'Resgate de aplicações financeiras', value: 'redemption_financial_investments' },
  { text: 'Total de impostos a pagar', value: 'total_taxes_payable' },
  { text: 'Outros', value: 'others' },
];

export const OPTIONS_DIRECT_MAILS = [
  { text: 'Boleto', value: 'billet' },
  { text: 'Cobrança', value: 'charge' },
  { text: 'NFe', value: 'nfe' },
  { text: 'Financeira', value: 'financial' },
];

export const OPTIONS_EMAIL_LAYOUT_ORIGINS = [
  { text: 'Nota Fiscal Eletronica - Produto', value: 'invoice_nf_product' },
  { text: 'Nota Fiscal Eletronica - Produto - Cancelamento', value: 'invoice_nf_cancel' },
  { text: 'Nota Fiscal Eletronica - Serviço', value: 'invoice_nfs_service' },
  { text: 'Pedido de Orçamento', value: 'order_budget' },
  { text: 'Pedido de Venda', value: 'order_sale' },
  { text: 'Venda Representada', value: 'sale_represented' },
];

export const OPTIONS_EMMIT_INVOICE_FORMAT = [
  { text: 'Normal', value: 'normal' },
  { text: 'Contigência SVC-AN  (SEFAZ virtual de contigencia AN)', value: 'svc_an' },
  { text: 'Contigência SVC-RS  (SEFAZ virtual de contigencia RS)', value: 'svc_rs' },
  { text: 'Contigência offline  (SEFAZ virtual de contigencia NFC-e)', value: 'offilne' },
];

export const OPTIONS_EMMIT_MANIFEST_FORMAT = [
  { text: 'Produção', value: '1' },
  { text: 'Homologação', value: '2' },
];

export const OPTIONS_FINALLY_INVOICE = [
  { text: 'Normal', value: 'normal_nfe' },
  { text: 'Complementar', value: 'additional' },
  { text: 'Ajuste', value: 'adjustment' },
  { text: 'Devolução/Retorno', value: 'devotion' },
];

export const OPTIONS_EMMIT_TYPE = [
  { text: 'Prestador de Serviço de Transporte', value: '1' },
  { text: 'Transportador de Carga Própria', value: '2' },
  { text: 'Prestador de Serviço de Transporte Que Emitirá CT-e Globalizado', value: '3' },
];

export const OPTIONS_FORMAT_MODALITY_MANIFEST = [
  { text: 'Rodoviário', value: '1' },
  { text: 'Aéreo', value: '2' },
  { text: 'Aquaviário', value: '3' },
  { text: 'Ferroviário', value: '4' },
];

export const OPTIONS_FREIGHT = [
  { text: 'Por conta do Emitente', value: 'issuer' },
  { text: 'Por conta do Destinatário', value: 'recipient' },
  { text: 'Por conta do Terceiro', value: 'third_party' },
  { text: 'Próprio, Por conta do Emitente', value: 'own_issuer' },
  { text: 'Próprio, por conta do Destinatário', value: 'own_recipient' },
  { text: 'Sem frete', value: 'no_freight' },
];

export const OPTIONS_GENDER = [
  { text: 'Masculino', value: 'male' },
  { text: 'Feminino', value: 'female' },
];

export const OPTIONS_ICMS_CST = [
  { text: '00 - Tributada Integralmente', value: 'cst_00' },
  { text: '10 - Tributada com Cobrança do ICMS por ST', value: 'cst_10' },
  { text: '10 - Tributada com Cobrança do ICMS por ST (com partilha do ICMS entre a UF de origem e a UF de destino ou a UF definida na legislação)', value: 'cst_10b' },
  { text: '20 - Com Redução da base de cálculo', value: 'cst_20' },
  { text: '30 - Isenta ou não Tributada e com Cobrança do ICMS por ST', value: 'cst_30' },
  { text: '40 - Isenta', value: 'cst_40' },
  { text: '41 - Não Tributada', value: 'cst_41' },
  { text: '41 - Não Tributada (ICMS ST devido para UF de destino, nas operações Interestaduais de produto que tiverem retenção antecipada ICMS por ST)', value: 'cst_41b' },
  { text: '50 - Suspensão', value: 'cst_50' },
  { text: '51 - Diferimento', value: 'cst_51' },
  { text: '60 - Cobrado anteriormente por ST', value: 'cst_60' },
  { text: '61 – Tributação monofásica sobre combustíveis cobrada anteriormente', value: 'cst_61' },
  { text: '70 - Com redução da base de cálculo e cobrança do ICMS por ST', value: 'cst_70' },
  { text: '90 - Outras (com partilha por ICMS entre a UF de origem e a UF de destino ou UF na legislação)', value: 'cst_90b' },
  { text: '90 - Outras', value: 'cst_90' },
];

export const OPTIONS_ICMS_CST_SIMPLES = [
  { text: '101 - Tributada com permissão de crédito', value: 'cst_101' },
  { text: '102 - Tributada sem permissão de crédito', value: 'cst_102' },
  { text: '103 - Isenção do ICMS para faixa de receita bruta', value: 'cst_103' },
  { text: '201 - Tributada com permissão de crédito e com cobrança de ICMS por ST', value: 'cst_201' },
  { text: '202 - Tributada sem permissão de crédito e com cobrança de ICMS por ST', value: 'cst_202' },
  { text: '203 - Isenção do ICMS para faixa de receita bruta e com cobrança do ICMS por ST', value: 'cst_203' },
  { text: '300 - Imune', value: 'cst_300' },
  { text: '400 - Não Tributada', value: 'cst_400' },
  { text: '500 - ICMS cobrado anteriormente por ST ou por antecipação', value: 'cst_500' },
  { text: '900 - Outros', value: 'cst_900' },
];

export const OPTIONS_ICMS_DETERMINATION_MODE = [
  { text: '0 - Margem de valor agregado', value: 'value_added_margin' },
  { text: '1 - Pauta (valor)', value: 'tariff_value' },
  { text: '2 - Preço tabelado Max(valor)', value: 'max_tabulated_price' },
  { text: '3 - Valor da operação', value: 'operation_value' },
];

export const OPTIONS_ICMS_EXEMPTION_REASON = [
  { text: '1 - Táxi', value: 'taxi' },
  { text: '2 - Deficiente físico', value: 'handicapped' },
  { text: '3 - Produtor agropecuário', value: 'agricultural_producer' },
  { text: '4 - Frotista/Locadora', value: 'fleet_rental' },
  { text: '5 - Diplomático/Consular', value: 'Diplomatic' },
  { text: '6 - Utilitários e motocicletas da Amazônia Ocidental e Áreas de Livre Comércio (resolução 714/88 e 790/94 - CONTRAN e suas alterações)', value: 'amazon_714' },
  { text: '7 - SUFRAMA', value: 'suframa' },
  { text: '8 - Venda a Orgãos Públicos', value: 'sale_public' },
  { text: '9 - Outros (NT 2011/004)', value: 'others' },
];

export const OPTIONS_ICMS_ORIGIN = [
  { text: '0 - Nacional, exceto as indicadas nos códigos 3 a 5', value: 'national_zero' },
  { text: '1 - Estrangeira - Importação direta, exceto a indicada no código 6', value: 'foreign_one' },
  { text: '2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7', value: 'foreign_two' },
  { text: '3 - Nacional, mercadoria ou bem com conteúdo de importação superior a  40% (quarenta por cento)', value: 'national_three' },
  {
    text: '4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei nº 288/67, e as Leis nºs 8.248/91, 8.387/91, 10.176/01 e 11.484/07',
    value: 'national_four',
  },
  { text: '5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40% (quarenta por cento)', value: 'national_five' },
  { text: '6 - Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução CAMEX', value: 'foreign_six' },
  { text: '7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX', value: 'foreign_seven' },
  { text: '8 - Nacional, mercadoria ou bem com conteúdo de importação superior a 70% (setenta por cento)', value: 'national_eight' },
];

export const OPTIONS_ICMS_ST_DETERMINATION_MODE = [
  { text: '0 - Preço tabelado ou Máximo Sugerido', value: 'tabulated_price' },
  { text: '1 - Lista Negativa (valor)', value: 'negative_list' },
  { text: '2 - Lista Positiva (valor)', value: 'positive_list' },
  { text: '3 - Lista Neutra (valor)', value: 'neutral_list' },
  { text: '4 - Margem de valor Agregado (%)', value: 'value_added_margin' },
  { text: '5 - Pauta (valor)', value: 'tariff_value' },
  { text: '6 - Valor da Operação', value: 'operation_value' },
];

export const OPTIONS_IPI_CST = [
  { text: '00 - Entrada com recuperação de crédito', value: 'cst_00' },
  { text: '01 - Entrada tributada com alíquota zero', value: 'cst_01' },
  { text: '02 - Entrada isenta', value: 'cst_02' },
  { text: '03 - Entrada não-tributada', value: 'cst_03' },
  { text: '04 - Entrada imune', value: 'cst_04' },
  { text: '05 - Entrada com suspensão', value: 'cst_05' },
  { text: '49 - Outras entradas', value: 'cst_49' },
  { text: '50 - Saída tributada', value: 'cst_50' },
  { text: '51 - Saída tributada com alíquota zero', value: 'cst_51' },
  { text: '52 - Saída isenta', value: 'cst_52' },
  { text: '53 - Saída não-tributada', value: 'cst_53' },
  { text: '54 - Saída imune', value: 'cst_54' },
  { text: '55 - Saída com suspensão', value: 'cst_55' },
  { text: '99 - Outras saídas', value: 'cst_99' },
];

export const OPTIONS_INVOICE_DOCUMENT_TYPE = [
  { text: 'Entrada', value: 'entry' },
  { text: 'Saida', value: 'exit' },
];

export const OPTIONS_INVOICE_STATUS = [
  {
    text: 'Pendente',
    value: 'pending',
    color: 'grey lighten-2',
    icon: 'ICON_INVOICE_SEND',
  },
  {
    text: 'Processando',
    value: 'processing',
    color: 'warning',
    icon: 'ICON_INVOICE_CLOCK',
  },
  {
    text: 'Autorizada',
    value: 'authorized',
    color: 'success',
    icon: 'ICON_INVOICE_CHECK',
  },
  {
    text: 'Cancelada',
    value: 'canceled',
    color: 'error',
    icon: 'ICON_INVOICE_REMOVE',
  },
  {
    text: 'Inutilizada',
    value: 'disabled',
    color: 'grey lighten-1',
    icon: 'ICON_INVOICE_MINUS',
  },
  {
    text: 'Denegada',
    value: 'denied',
    color: 'error',
    icon: 'ICON_INVOICE_REMOVE',
  },
  {
    text: 'Aguardando Sefaz',
    value: 'problem',
    color: 'warning',
    icon: 'ICON_INVOICE_CLOCK',
  },
  {
    text: 'Rejeitada',
    value: 'rejected',
    color: 'error',
    icon: 'ICON_ALERT_CIRCLE',
  },
];

export const OPTIONS_RECEIVABLE_STATUS = [
  {
    text: 'Pendente',
    value: 'pending',
    color: 'default',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Aprovado',
    value: 'contracted',
    color: 'success',
    icon: 'ICON_CHECK',
  },
  {
    text: 'Cancelado',
    value: 'canceled',
    color: 'error',
    icon: 'ICON_CANCEL',
  },
  {
    text: 'Contratando...',
    value: 'contracting',
    color: 'primary',
    icon: 'ICON_TIMER_SAND',
  },
  {
    text: 'Em análise',
    value: 'analysis',
    color: 'warning',
    icon: 'ICON_TIMER_SAND',
  },
];

export const OPTIONS_INVOICE_ISS_STATUS = [
  {
    text: 'Autorizada',
    value: 'authorized',
    color: 'success',
    icon: 'ICON_FILE_SEND',
  },
  {
    text: 'Processando',
    value: 'processing',
    color: 'warning',
    icon: 'ICON_TIMER_SAND',
  },
  {
    text: 'Rejeitada',
    value: 'rejected',
    color: 'accent',
    icon: 'ICON_ALERT',
  },
  {
    text: 'Cadastrada',
    value: 'registered',
    color: 'teal accent-3',
    icon: 'ICON_CHECK',
  },
  {
    text: 'Pendente',
    value: 'pending',
    color: 'default',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Cancelada',
    value: 'canceled',
    color: 'error',
    icon: 'ICON_CANCEL',
  },
  {
    text: 'Rascunho',
    value: 'draft',
    color: 'secondary',
    icon: 'ICON_TITLE',
  },
];

export const OPTIONS_INVOICE_TYPE = [
  { text: 'NF-e', value: 'nfe' },
  { text: 'CT-e', value: 'cte' },
];

export const OPTIONS_LAUNCH = [
  { text: 'Transferência', value: 'transfer' },
  { text: 'Manual', value: 'manual' },
];

export const OPTIONS_LAUNCH_STOCK = [{ text: 'Manual', value: 'manual' }];

export const OPTIONS_LAUNCH_TRANSACTION_TYPES = [
  { text: 'Entrada', value: 'entry' },
  { text: 'Saída', value: 'exit' },
];

export const OPTIONS_LAUNCH_HISTORIES_STATUS = [
  {
    text: 'Entregue',
    value: 'delivered',
    color: 'success',
    icon: 'ICON_CHECK',
  },
  {
    text: 'Cancelado',
    value: 'canceled',
    color: 'error',
    icon: 'ICON_CANCEL',
  },
];

export const OPTIONS_LOAD_UNITY = [
  { text: 'Quilograma', value: '01' },
  { text: 'Tonelada', value: '02' },
];

export const OPTIONS_LOAD_TYPE = [
  { text: 'Granel sólido', value: '01' },
  { text: 'Granel líquido', value: '02' },
  { text: 'Frigorificada', value: '03' },
  { text: 'Conteinerizada', value: '04' },
  { text: 'Carga Geral', value: '05' },
  { text: 'Neogranel', value: '06' },
  { text: 'Perigosa (granel sólido)', value: '07' },
  { text: 'Perigosa (granel líquido)', value: '08' },
  { text: 'Perigosa (carga frigorificada)', value: '09' },
  { text: 'Perigosa (conteinerizada)', value: '10' },
  { text: 'Perigosa (carga geral)', value: '11' },
];

export const OPTIONS_MANIFEST_STATUS = [
  {
    text: 'Pendente',
    value: 'pending',
    color: 'default',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Processando',
    value: 'processing',
    color: 'warning',
    icon: 'ICON_TIMER_SAND',
  },
  {
    text: 'Autorizada',
    value: 'authorized',
    color: 'success',
    icon: 'ICON_CHECK',
  },
  {
    text: 'Encerrada',
    value: 'closed',
    color: 'info',
    icon: 'ICON_CHECK',
  },
  {
    text: 'Cancelada',
    value: 'canceled',
    color: 'error',
    icon: 'ICON_CANCEL',
  },
  {
    text: 'Rejeitada',
    value: 'rejected',
    color: 'error',
    icon: 'ICON_ALERT',
  },
];

export const OPTIONS_MANIFEST_EMITTER = [
  { text: 'Prestador de Serviço de Transporte', value: '1' },
  { text: 'Transportador de Carga Própria', value: '2' },
  { text: 'Prestador de Serviço de Transporte Que Emitirá CT-e Globalizado', value: '3' },
];

export const OPTIONS_MANIFEST_TRANSPORTER_TYPE = [
  { text: 'ETC - Empresa de Transporte de Cargas', value: '1' },
  { text: 'TAC - Transportador Autônomo de Cargas', value: '2' },
  { text: 'CTC - Cooperativa de Transporte de Cargas', value: '3' },
];

export const OPTIONS_MANIFEST_CARGO_TYPE = [
  { text: 'Container', value: 'container' },
  { text: 'ULD', value: 'uld' },
  { text: 'Pallet', value: 'pallet' },
  { text: 'Outros', value: 'others' },
];

export const OPTIONS_NATIONAL_SIMPLE_TYPE = [
  { text: 'Comércio', value: 'commerce_product' },
  { text: 'Indústria', value: 'industry_product' },
];

export const OPTIONS_ORDERS_STATUS = [
  {
    text: 'Novo',
    value: 'initial',
    color: 'primary',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Iniciado',
    value: 'started',
    color: 'primary',
    icon: 'ICON_SKIP_NEXT',
  },
  {
    text: 'Pendente',
    value: 'pending',
    color: 'secondary',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Entregue',
    value: 'delivered',
    color: 'success',
    icon: 'ICON_TIMER_SAND',
  },
  {
    text: 'Cancelado',
    value: 'canceled',
    color: 'error',
    icon: 'ICON_CANCEL',
  },
  {
    text: 'Finalizado',
    value: 'finished',
    color: 'success',
    icon: 'ICON_CHECK',
  },
];

export const OPTIONS_ORDERSPURCHASESALE_STATUS = [
  {
    text: 'Novo',
    value: 'initial',
    color: 'primary',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Pendente',
    value: 'pending',
    color: 'default',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Parcial',
    value: 'partial',
    color: 'warning',
    icon: 'ICON_ALERT_BOX',
  },
  {
    text: 'Entregue',
    value: 'delivered',
    color: 'success',
    icon: 'ICON_CHECK',
  },
  {
    text: 'Cancelado',
    value: 'canceled',
    color: 'error',
    icon: 'ICON_CANCEL',
  },
];

export const OPTIONS_ORDERBUDGET_STATUS = [
  {
    text: 'Novo',
    value: 'initial',
    color: 'primary',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Pendente',
    value: 'pending',
    color: 'default',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Finalizado',
    value: 'delivered',
    color: 'success',
    icon: 'ICON_CHECK',
  },
  {
    text: 'Cancelado',
    value: 'canceled',
    color: 'error',
    icon: 'ICON_CANCEL',
  },
];

export const OPTIONS_ORDERSSERVICE_STATUS = [
  {
    text: 'Novo',
    value: 'initial',
    color: 'primary',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Iniciado',
    value: 'started',
    color: 'primary',
    icon: 'ICON_SKIP_NEXT',
  },
  {
    text: 'Finalizado com NFs',
    value: 'invoiced',
    color: 'success',
    icon: 'ICON_CHECK',
  },
  {
    text: 'Cancelado',
    value: 'canceled',
    color: 'error',
    icon: 'ICON_CANCEL',
  },
  {
    text: 'Finalizado',
    value: 'finished',
    color: 'success',
    icon: 'ICON_CHECK',
  },
];

export const OPTIONS_ORDERSPRODUCTION_STATUS = [
  {
    text: 'Novo',
    value: 'blank',
    color: 'secondary',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Pendente',
    value: 'pending',
    color: 'default',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Previsto',
    value: 'previewed',
    color: 'secondary',
    icon: 'ICON_TIMER_SAND',
  },
  {
    text: 'Iniciado',
    value: 'started',
    color: 'warning',
    icon: 'ICON_SKIP_NEXT',
  },
  {
    text: 'Em Processo',
    value: 'processing',
    color: 'secondary',
    icon: 'ICON_TOOLS',
  },
  {
    text: 'Finalizado',
    value: 'finished',
    color: 'success',
    icon: 'ICON_CHECK',
  },
  {
    text: 'Cancelado',
    value: 'canceled',
    color: 'error',
    icon: 'ICON_CANCEL',
  },
];

export const OPTIONS_ORDERSSERVICE_ADDRESS_TYPE = [
  { text: 'Endereço Destinatário', value: 'recipient' },
  { text: 'Endereço Emitente', value: 'sender' },
  { text: 'Outro', value: 'other' },
];

export const OPTIONS_PAGE_LENGTH = [
  { text: '10', value: 10 },
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
  { text: '500', value: 500 },
  { text: '1000', value: 1000 },
];

export const OPTIONS_PERIOD_PLAN = [
  { text: 'Mensal', value: 'month' },
  { text: 'Anual', value: 'year' },
];

export const OPTIONS_PIS_COFINS_CST = [
  { text: '01 - Operação tributável - Base de cálculo = Valor da operação alíquota normal (Cumulativo/não Cumulativo)', value: 'cst_01' },
  { text: '02 - Operação tributável - Base de cálculo - Valor da operação (Alíquota Diferenciada)', value: 'cst_02' },
  { text: '03 - Operação tributável - Base de cálculo = Quantidade vendida x Alíquota por unidade de produto', value: 'cst_03' },
  { text: '04 - Operação tributável - Tributação Monofásica - (Alíquota zero)', value: 'cst_04' },
  { text: '05 - Operação tributável (Substituição tributária)', value: 'cst_05' },
  { text: '06 - Operação tributável - Alíquota zero', value: 'cst_06' },
  { text: '07 - Operação isenta de contribuição', value: 'cst_07' },
  { text: '08 - Operação sem incidência de Contribuição', value: 'cst_08' },
  { text: '09 - Operação com suspensão de Contribuição', value: 'cst_09' },
  { text: '49 - Outras operações de saída', value: 'cst_49' },
  { text: '50 - Operação com direito a crédito - Vinculada exclusivamente a receita tributária no mercado interno', value: 'cst_50' },
  { text: '51 - Operação com direito a crédito - Vinculada exclusivamente a receita não tributária no mercado interno', value: 'cst_51' },
  { text: '52 - Operação com direito a crédito - vinculada exclusivamente a Receita de Exportação', value: 'cst_52' },
  { text: '53 - Operação com direito a crédito - vinculada a Receita tributária e não Tributada no Mercado Interno', value: 'cst_53' },
  { text: '54 - Operação com direito a crédito - Vinculada a receita tributária no Mercado Interno e de Exportação', value: 'cst_54' },
  { text: '55 - Operação com direito a crédito - Vinculada a receita não-tributária no Mercado Interno e de Exportação', value: 'cst_55' },
  { text: '56 - Operação com direito a crédito - Vinculada a receita Tributaria e não-tributária no Mercado Interno e de Exportação', value: 'cst_56' },
  { text: '60 - Crédito Presumido - Operação de aquisição vinculada exclusivamente a receita Tributada no Mercado Interno', value: 'cst_60' },
  { text: '61 - Crédito Presumido - Operação de aquisição vinculada exclusivamente a receita não-Tributada no Mercado Interno', value: 'cst_61' },
  { text: '62 - Crédito Presumido - Operação de aquisição vinculada exclusivamente a receita de Exportação', value: 'cst_62' },
  { text: '63 - Crédito Presumido - Operação de aquisição vinculada a receitas não-Tributada no Mercado Interno', value: 'cst_63' },
  { text: '64 - Crédito Presumido - Operação de aquisição vinculada a receitas Tributada no Mercado Interno e de Exportação', value: 'cst_64' },
  { text: '65 - Crédito Presumido - Operação de aquisição vinculada a receitas não-Tributada no Mercado Interno e de Exportação', value: 'cst_65' },
  { text: '66 - Crédito Presumido - Operação de aquisição vinculada a receitas Tributada e não-Tributada no Mercado Interno e de Exportação', value: 'cst_66' },
  { text: '67 - Crédito Presumido - Outras operações', value: 'cst_67' },
  { text: '70 - Operação de aquisição sem direito a crédito', value: 'cst_70' },
  { text: '71 - Operação de aquisição com Insenção', value: 'cst_71' },
  { text: '72 - Operação de aquisição com Suspensão', value: 'cst_72' },
  { text: '73 - Operação de aquisição a Alíquota Zero', value: 'cst_73' },
  { text: '74 - Operação de aquisição sem Incidência da Contribuição', value: 'cst_74' },
  { text: '75 - Operação de aquisição por Substituição tributária', value: 'cst_75' },
  { text: '98 - Outras operações de Entrada', value: 'cst_98' },
  { text: '99 - Outras operações', value: 'cst_99' },
];

export const OPTIONS_PIX_TYPE = [
  { text: 'CPF', value: 'CPF' },
  { text: 'CNPJ', value: 'CNPJ' },
  { text: 'Celular', value: 'PHONE' },
  { text: 'Email', value: 'EMAIL' },
  { text: 'Chave aleatória', value: 'EVP' },
];

export const OPTIONS_PRESENCE_INDICATOR = [
  { text: 'Presencial', value: 'in_person' },
  { text: 'Não presencial, via internet', value: 'by_internet' },
  { text: 'Não presencial, teleatendimento', value: 'by_phone' },
  { text: 'Presencial, fora do estabelecimento', value: 'in_person_outside' },
  { text: 'Não presencial, outros', value: 'others' },
];

export const OPTIONS_INTERMEDIARY_INDICATOR = [
  { text: 'Operação sem intermediador (em site ou plataforma própria).', value: '0' },
  { text: 'Operação em site ou plataforma de terceiros (intermediadores / marketplace).', value: '1' },
];

export const OPTIONS_PRICELIST_APPLY_ABOUT = [
  { text: 'Valor Padrão', value: 'default' },
  { text: 'Valor Lista', value: 'list' },
];

export const OPTIONS_PRICELIST_TYPE_LAUNCH = [
  { text: 'Acréscimo', value: 'increase' },
  { text: 'Desconto', value: 'discount' },
];

export const OPTIONS_PRICELIST_TYPE_VALUE = [
  { text: 'Valor em Reais', value: 'value' },
  { text: 'Porcentagem', value: 'percent' },
];

export const OPTIONS_PRINT_FORMAT_DANFE = [
  { text: 'DANFE normal, Retrato', value: 'portrait' },
  { text: 'DANFE normal, Paisagem', value: 'landscape' },
  { text: 'DANFE simplificado', value: 'simplified' },
  { text: 'Sem DANFE', value: 'no_danfe' },
  { text: 'DANFE NFC-e', value: 'danfe_nfce' },
  { text: 'DANFE NFC-e em mensagem eletrônica', value: 'danfe_nfce_message' },
];

export const OPTIONS_REGISTER_BANK_ACCOUNT_TYPE = [
  { text: 'Conta Corrente', value: 'CC' },
  { text: 'Pagamento', value: 'PG' },
];

export const OPTIONS_RPS_REGISTER_STATUS = [
  { text: 'Não Cadastrado', value: 'notRegistered' },
  { text: 'Cadastrado', value: 'registered' },
];

const OPTIONS_SITUATIONS_TYPES = [
  { text: 'Aprovado', value: 'approved' },
  { text: 'Negado', value: 'unknown' },
  { text: 'Pendente', value: 'pending' },
  { text: 'Reprovado', value: 'disapproved' },
  { text: 'Criado', value: 'created' },
];

export const OPTIONS_SUBSCRIPTIONS_PAYMENT_METHODS = [
  { text: 'Cartão de Credito', value: 'credit_card' },
];

export const OPTIONS_SUBSCRIPTIONS_PLANS = [
  { text: 'Certus Fintech Semestral', value: '580075' },
  { text: 'Certus Fintech Semestral', value: '580075' },
];

export const OPTIONS_SUBSCRIPTIONS_STATUS = [
  { text: 'Trial', value: 'trialing' },
  { text: 'Pagamento Recebido', value: 'paid' },
  { text: 'Pagamento Pendente', value: 'pending_payment' },
  { text: 'Não Paga', value: 'unpaid' },
  { text: 'Cancelada', value: 'canceled' },
  { text: 'Encerrada', value: 'ended' },
];

export const OPTIONS_SUBSCRIPTIONS_TRANSACTIONS_STATUS = [
  { text: 'Processando', value: 'processing' },
  { text: 'Autorizada', value: 'authorized' },
  { text: 'Pagamento Recebido', value: 'paid' },
  { text: 'Extornada', value: 'refunded' },
  { text: 'Aguardando Pagamento', value: 'waiting_payment' },
  { text: 'Extorno Pendente', value: 'pending_refund' },
  { text: 'Recusada', value: 'refused' },
];

export const OPTIONS_HISTORY_ORIGIN_NAME = [
  { text: 'Compra Parcial', value: 'PartialPurchase' },
  { text: 'Devolução de Compra', value: 'DevolutionPurchase' },
  { text: 'Devolução de Consignação', value: 'ConsignmentDevolution' },
  { text: 'Devolução de Industrialização', value: 'IndustrializationDevolution' },
  { text: 'Devolução de Venda', value: 'DevolutionSale' },
  { text: 'Manual - Estoque Principal', value: 'PRINCIPAL' },
  { text: 'Ordem de Serviço', value: 'OrderService' },
  { text: 'Pedido de Venda', value: 'OrderSale' },
  { text: 'Pedido de Compra', value: 'OrderPurchase' },
  { text: 'Remessa de Consignação', value: 'RemittanceConsignment' },
  { text: 'Remessa de Industrialização', value: 'RemittanceIndustrialization' },
  { text: 'Venda Parcial', value: 'PartialSale' },
];

export const OPTIONS_TAX_PAYER = [
  { text: '1 - Contribuinte ICMS (informar a IE)', value: 'taxpayer' },
  { text: '2 - Contribuinte isento de inscrição no cadastro de contribuintes do ICMS', value: 'exempt_taxpayer' },
  { text: '9 - Não contribuinte (que pode ou não possuir inscrição estadual no cadastro de contribuintes do ICMS)', value: 'non_taxpayer' },
];

export const OPTIONS_TAX_REGIME = [
  { text: 'Normal - lucro real', value: 'normal_real' },
  { text: 'Normal - lucro presumido', value: 'normal_presumed' },
  { text: 'Simples nacional', value: 'simple_national' },
  { text: 'Pessoa física', value: 'physical_person' },
];

export const OPTIONS_TAX_REGIME_TRIB = [
  { text: 'Micro Empreendedor Individual (MEI)', value: 'mei' },
  { text: 'Simples Nacional', value: 'simple_national' },
  { text: 'Simples Nacional - Excesso de Sublimite da Receita Bruta', value: 'simple_national_excess' },
  { text: 'Regime Normal', value: 'normal_regime' },
];

export const OPTIONS_TYPE_ACCOUNT_STATUS = [
  {
    text: 'Inicial',
    value: 'initial',
    color: 'default',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Pendente',
    value: 'pending',
    color: 'default',
    icon: 'ICON_TITLE',
  },
  {
    text: 'Pago',
    value: 'settled',
    color: 'success',
    icon: 'ICON_CHECK',
  },
  {
    text: 'Cancelado',
    value: 'canceled',
    color: 'error',
    icon: 'ICON_CANCEL',
  },
  {
    text: 'Parcial',
    value: 'partial',
    color: 'warning',
    icon: 'ICON_ALERT_BOX',
  },
];

export const OPTIONS_TYPE_ACCOUNT_PAYMENT_INSTALLMENTS = [
  { text: 'Normal', value: 'normal' },
  { text: 'Cartório', value: 'notary' },
  { text: 'Negociação', value: 'negotiation' },
  { text: 'Devolvido', value: 'returned' },
  { text: 'Protestado', value: 'protested' },
];

export const OPTIONS_TYPE_ACCOUNT_PAYMENT_INSTALLMENTS_STATUS = [
  { text: 'Pago', value: 'settled' },
  { text: 'Não Pago', value: 'unpaid' },
];

export const OPTIONS_TYPE_ACCOUNT_RECEIVABLE_INSTALLMENTS = [
  { text: 'Normal', value: 'normal' },
  { text: 'Cartório', value: 'notary' },
  { text: 'Negociação', value: 'negotiation' },
  { text: 'Devolvido', value: 'returned' },
  { text: 'Protestado', value: 'protested' },
  { text: 'Descontado', value: 'discounted' },
];

export const OPTIONS_TYPE_ACCOUNT_RECEIVABLE_INSTALLMENTS_STATUS = [
  { text: 'Pago', value: 'settled' },
  { text: 'Não Pago', value: 'unpaid' },
];

export const OPTIONS_TYPE_ACCOUNTING_ACCOUNT = [
  { text: 'Ativos', value: 'patrimony' },
  { text: 'Custos fixos', value: 'fixed_cost' },
  { text: 'Custos variáveis', value: 'variable_cost' },
  { text: 'Despesas fixas', value: 'fixed_expense' },
  { text: 'Despesas variáveis', value: 'variable_expense' },
  { text: 'Receitas', value: 'recipe' },
];

export const OPTIONS_TYPE_INVOICES = [
  { text: 'NF-e Produto', value: 'nfe' },
  { text: 'NFS-e Serviço', value: 'nfse' },
  { text: 'NF Entrada', value: 'nfee' },
  { text: 'NFC-e Consumidor', value: 'nfce' },
  { text: 'MDF-e', value: 'mdf' },
];

export const OPTIONS_TYPE_INVOICES_CERTIFICATES = [
  { text: 'PKCS11', value: 'PKCS11' },
  { text: 'PKCS12', value: 'PKCS12' },
];

export const OPTIONS_TYPE_INVOICES_ENVIRONMENTS = [
  { text: 'Homologação', value: 'homologation' },
  { text: 'Produção', value: 'production' },
];

export const OPTIONS_TYPE_INVOICES_REPOSITORIES = [
  { text: 'Arquivo A1', value: 'A1' },
  { text: 'Arquivo A3', value: 'A3' },
];

export const OPTIONS_TYPE_INVOICES_RPS_NATURE_OPERATIONS = [
  { text: '1 - Tributação no munícipio', value: 'taxation_in_the_municipality' },
  { text: '2 - Tributação fora do munícipio', value: 'taxation_outside_the_municipality' },
  { text: '3 - Isenção', value: 'exemption' },
  { text: '4 - Imune', value: 'immune' },
  { text: '5 - Processo judicial', value: 'judicial_process' },
];

export const OPTIONS_TYPE_INVOICES_RPS_SPECIAL_TAX_REGIME = [
  { text: '1 - Microempresa Municipal ', value: 'micro_enterprise' },
  { text: '2 - Estimativa', value: 'estimated' },
  { text: '3 - Sociedade de Profissionais', value: 'society_of_professionals' },
  { text: '4 - Cooperativa', value: 'cooperative' },
  { text: '5 - Microempresário Individual (MEI)', value: 'individual_micro_entrepreneur' },
  { text: '6 - Microempresário e Empresa de Pequeno Porte (ME/EPP)', value: 'micro_entrepreneur_and_small_business' },
  { text: '0 - Não informado', value: 'uninformed' },
];

export const OPTIONS_TYPE_INVOICES_RPS_TAX_REGIME = [
  { text: '1 - Optante pelo Simples Nacional', value: 'opting_for_simple_national' },
  { text: '2 - Simples Nacional - excesso de sublimite de receita bruta', value: 'national_simple_gross_revenue_sub_limit_excess' },
  { text: '3 - Regime normal', value: 'normal_regime' },
];

export const OPTIONS_TYPE_NATURE = [
  { text: 'Compras', value: 'purchase' },
  { text: 'Vendas', value: 'sale' },
  { text: 'Serviços', value: 'nfse' },
  { text: 'Cupom Fiscal', value: 'nfce' },
  { text: 'E-commerce', value: 'ecommerce' },
  { text: 'Devolução de Venda', value: 'devolution_sale' },
  { text: 'Devolução de Compra', value: 'devolution_purchase' },
];

export const OPTIONS_TYPE_PLAN = [
  { text: 'Lucro Real / Presumido', value: 'normal' },
  { text: 'Simples Nacional / MEI', value: 'simple_national' },
  { text: 'Gratuito', value: 'free' },
];

export const OPTIONS_TYPE_PLAN_FREE = [{ text: 'Gratuito', value: 'free' }];

export const OPTIONS_TYPE_PLAN_NEW_FLOW = [
  { text: 'Gratuito', value: 'free' },
  { text: 'Simples Nacional / MEI', value: 'simple_national' },
  { text: 'Lucro Real / Presumido', value: 'normal' },
];

export const OPTIONS_TYPE_REGISTER = [
  { text: 'Cliente', value: 'client' },
  { text: 'Fornecedor', value: 'vendor' },
  { text: 'Prestador', value: 'provider' },
  { text: 'Vendedor', value: 'salesman' },
  { text: 'Transportadora', value: 'shipping_company' },
  { text: 'Colaboradores', value: 'collaborator' },
  { text: 'Outros', value: 'others' },
];

export const OPTIONS_UF = [
  { text: 'Acre - AC', value: 'AC' },
  { text: 'Alagoas - AL', value: 'AL' },
  { text: 'Amapá - AP', value: 'AP' },
  { text: 'Amazonas - AM', value: 'AM' },
  { text: 'Bahia - BA', value: 'BA' },
  { text: 'Ceará - CE', value: 'CE' },
  { text: 'Distrito Federal - DF', value: 'DF' },
  { text: 'Espirito Santo - ES', value: 'ES' },
  { text: 'Goiás - GO', value: 'GO' },
  { text: 'Maranhão - MA', value: 'MA' },
  { text: 'Mato Grosso - MT', value: 'MT' },
  { text: 'Mato Grosso do Sul - MS', value: 'MS' },
  { text: 'Minas Gerais - MG', value: 'MG' },
  { text: 'Pará - PA', value: 'PA' },
  { text: 'Paraíba - PB', value: 'PB' },
  { text: 'Paraná - PR', value: 'PR' },
  { text: 'Pernambuco - PE', value: 'PE' },
  { text: 'Piauí - PI', value: 'PI' },
  { text: 'Rio de Janeiro - RJ', value: 'RJ' },
  { text: 'Rio Grande do Norte - RN', value: 'RN' },
  { text: 'Rio Grande do Sul - RS', value: 'RS' },
  { text: 'Roraima - RO', value: 'RO' },
  { text: 'Rondonia - RR', value: 'RR' },
  { text: 'Santa Catarina - SC', value: 'SC' },
  { text: 'São Paulo - SP', value: 'SP' },
  { text: 'Sergipe - SE', value: 'SE' },
  { text: 'Tocantis - TO', value: 'TO' },
  { text: 'Exterior - EX', value: 'EX' },
];

export const OPTIONS_VEHICLE_BODY_TYPE = [
  { text: 'Não Aplicável', value: '00' },
  { text: 'Aberta', value: '01' },
  { text: 'Fechado/Baú', value: '02' },
  { text: 'Granelera', value: '03' },
  { text: 'Porta-contâiner', value: '04' },
  { text: 'Sider', value: '05' },
];

export const OPTIONS_VEHICLE_TRACTION_TYPE = [
  { text: 'Truck', value: '01' },
  { text: 'Toco', value: '02' },
  { text: 'Cavalo Mecânico', value: '03' },
  { text: 'VAN', value: '04' },
  { text: 'Utilitários', value: '05' },
  { text: 'Outros', value: '06' },
];

export const OPTIONS_VEHICLE_TYPE = [
  { text: 'Tração', value: '01' },
  { text: 'Reboque', value: '02' },
];

export const OPTIONS_WHITE_LABEL_BILLETS_STATUS = [{ text: 'Pago', value: 'paid' }];

export const OPTIONS_WHITE_LABEL_STATUS = [
  { text: 'Ativa', value: 'active' },
  { text: 'Bloqueada', value: 'blocked' },
  { text: 'Pendente', value: 'pending' },
];

export const OPTIONS_WHITE_LABEL_TRASNFERS_STATUS = [
  { text: 'Feito', value: 'done' },
  { text: 'Erro', value: 'error' },
];

export const OPTIONS_WHITE_LABEL_STREET_TYPES = [
  { text: 'Residencial', value: 'residential' },
  { text: 'Trabalho', value: 'work' },
];

export const OPTIONS_ISS_NATURE_OPERATION = [
  { text: '1 - Tributação no município', value: '1' },
  { text: '2 - Tributação fora do município', value: '2' },
  { text: '3 - Isenção', value: '3' },
  { text: '4 - Imune', value: '4' },
  { text: '5 - Processo Judicial', value: '5' },
];

export const options = [
  {
    bank_code: OPTIONS_BANK_CODE,
    bank_layout: OPTIONS_BANK_LAYOUT,
    bank_type: OPTIONS_BANK_TYPE,
    biddings_order_by_filters: OPTIONS_BIDDINGS_ORDER_BY_FILTERS,
    biddings_order_filters: OPTIONS_BIDDINGS_ORDER_FILTERS,
    btg_account_currency: OPTIONS_BTG_ACCOUNT_CURRENCY,
    btg_account_status: OPTIONS_BTG_ACCOUNT_STATUS,
    btg_account_transaction_operation: OPTIONS_BTG_ACCOUNT_TRANSACTION_OPERATION,
    btg_account_transaction_type: OPTIONS_BTG_ACCOUNT_TRANSACTION_TYPE,
    btg_account_type: OPTIONS_BTG_ACCOUNT_TYPE,
    btg_billet_status: OPTIONS_BTG_BILLET_STATUS,
    cashflows_type: OPTIONS_CASHFLOWS_TYPE,
    das_guide: OPTIONS_DAS_GUIDE,
    date_origin: OPTIONS_DATE_ORIGIN,
    destiny_location: OPTIONS_DESTINY_LOCATION,
    demonstrative_accounting_account: OPTIONS_DEMONSTRATIVE_ACCOUNTING_ACCOUNT,
    direct_mails: OPTIONS_DIRECT_MAILS,
    email_layout_origins: OPTIONS_EMAIL_LAYOUT_ORIGINS,
    emmit_invoice_format: OPTIONS_EMMIT_INVOICE_FORMAT,
    emmit_manifest_format: OPTIONS_EMMIT_MANIFEST_FORMAT,
    finally_invoice: OPTIONS_FINALLY_INVOICE,
    format_modality_manifest: OPTIONS_FORMAT_MODALITY_MANIFEST,
    freight: OPTIONS_FREIGHT,
    icms_cst: OPTIONS_ICMS_CST,
    icms_cst_simples: OPTIONS_ICMS_CST_SIMPLES,
    icms_determination_mode: OPTIONS_ICMS_DETERMINATION_MODE,
    icms_exemption_reason: OPTIONS_ICMS_EXEMPTION_REASON,
    icms_origin: OPTIONS_ICMS_ORIGIN,
    icms_st_determination_mode: OPTIONS_ICMS_ST_DETERMINATION_MODE,
    invoice_document_type: OPTIONS_INVOICE_DOCUMENT_TYPE,
    invoice_status: OPTIONS_INVOICE_STATUS,
    invoice_iss_status: OPTIONS_INVOICE_ISS_STATUS,
    invoice_type: OPTIONS_INVOICE_TYPE,
    ipi_cst: OPTIONS_IPI_CST,
    iss_nature_operation: OPTIONS_ISS_NATURE_OPERATION,
    gender: OPTIONS_GENDER,
    history_origin_name: OPTIONS_HISTORY_ORIGIN_NAME,
    launch: OPTIONS_LAUNCH,
    launch_stocks: OPTIONS_LAUNCH_STOCK,
    launch_transaction_types: OPTIONS_LAUNCH_TRANSACTION_TYPES,
    launch_histories_status: OPTIONS_LAUNCH_HISTORIES_STATUS,
    manifest_cargo_type: OPTIONS_MANIFEST_CARGO_TYPE,
    manifest_emitter: OPTIONS_MANIFEST_EMITTER,
    manifest_status: OPTIONS_MANIFEST_STATUS,
    manifest_transporter_type: OPTIONS_MANIFEST_TRANSPORTER_TYPE,
    national_simple_type: OPTIONS_NATIONAL_SIMPLE_TYPE,
    orders_status: OPTIONS_ORDERS_STATUS,
    orders_purchase_sale_status: OPTIONS_ORDERSPURCHASESALE_STATUS,
    order_budget_status: OPTIONS_ORDERBUDGET_STATUS,
    orders_service_status: OPTIONS_ORDERSSERVICE_STATUS,
    orders_production_status: OPTIONS_ORDERSPRODUCTION_STATUS,
    orders_service_address_type: OPTIONS_ORDERSSERVICE_ADDRESS_TYPE,
    page_length: OPTIONS_PAGE_LENGTH,
    period_plans: OPTIONS_PERIOD_PLAN,
    pis_cofins_cst: OPTIONS_PIS_COFINS_CST,
    pix_type: OPTIONS_PIX_TYPE,
    presence_indicator: OPTIONS_PRESENCE_INDICATOR,
    pricelist_apply_about: OPTIONS_PRICELIST_APPLY_ABOUT,
    pricelist_type_launch: OPTIONS_PRICELIST_TYPE_LAUNCH,
    pricelist_type_value: OPTIONS_PRICELIST_TYPE_VALUE,
    print_format_danfe: OPTIONS_PRINT_FORMAT_DANFE,
    register_bank_account_type: OPTIONS_REGISTER_BANK_ACCOUNT_TYPE,
    rps_register_status: OPTIONS_RPS_REGISTER_STATUS,
    situations: OPTIONS_SITUATIONS_TYPES,
    subscriptions_payment_methods: OPTIONS_SUBSCRIPTIONS_PAYMENT_METHODS,
    subscriptions_plans: OPTIONS_SUBSCRIPTIONS_PLANS,
    subscriptions_status: OPTIONS_SUBSCRIPTIONS_STATUS,
    subscriptions_transactions_status: OPTIONS_SUBSCRIPTIONS_TRANSACTIONS_STATUS,
    tax_payer: OPTIONS_TAX_PAYER,
    tax_regime: OPTIONS_TAX_REGIME,
    tax_regime_trib: OPTIONS_TAX_REGIME_TRIB,
    type_account_status: OPTIONS_TYPE_ACCOUNT_STATUS,
    type_account_payment_installments: OPTIONS_TYPE_ACCOUNT_PAYMENT_INSTALLMENTS,
    type_account_payment_installments_status: OPTIONS_TYPE_ACCOUNT_PAYMENT_INSTALLMENTS_STATUS,
    type_account_receivable_installments: OPTIONS_TYPE_ACCOUNT_RECEIVABLE_INSTALLMENTS,
    type_account_receivable_installments_status: OPTIONS_TYPE_ACCOUNT_RECEIVABLE_INSTALLMENTS_STATUS,
    type_accounting_account: OPTIONS_TYPE_ACCOUNTING_ACCOUNT,
    type_invoices: OPTIONS_TYPE_INVOICES,
    type_invoices_certificates: OPTIONS_TYPE_INVOICES_CERTIFICATES,
    type_invoices_environments: OPTIONS_TYPE_INVOICES_ENVIRONMENTS,
    type_invoices_repositories: OPTIONS_TYPE_INVOICES_REPOSITORIES,
    type_invoices_rps_nature_operations: OPTIONS_TYPE_INVOICES_RPS_NATURE_OPERATIONS,
    type_invoices_rps_special_tax_regime: OPTIONS_TYPE_INVOICES_RPS_SPECIAL_TAX_REGIME,
    type_invoices_rps_tax_regime: OPTIONS_TYPE_INVOICES_RPS_TAX_REGIME,
    type_nature: OPTIONS_TYPE_NATURE,
    type_plans: OPTIONS_TYPE_PLAN,
    type_receivable_status: OPTIONS_RECEIVABLE_STATUS,
    type_register: OPTIONS_TYPE_REGISTER,
    uf: OPTIONS_UF,
    vehicle_body_type: OPTIONS_VEHICLE_BODY_TYPE,
    vehicle_traction_type: OPTIONS_VEHICLE_TRACTION_TYPE,
    vehicle_type: OPTIONS_VEHICLE_TYPE,
    white_label_billets_status: OPTIONS_WHITE_LABEL_BILLETS_STATUS,
    white_label_status: OPTIONS_WHITE_LABEL_STATUS,
    white_label_transfers_status: OPTIONS_WHITE_LABEL_TRASNFERS_STATUS,
    white_label_street_types: OPTIONS_WHITE_LABEL_STREET_TYPES,
  },
];
