/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/**
 *  Charts - Plugin for generate charts
 *
 *  Abstraction of the amcharts plugin, aiming at centralizing functions
 *  and customization of graphics
 *
 *  Docs: https://www.amcharts.com/docs/v4/getting-started/
 */

import Vue from 'vue';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_pt_BR from '@amcharts/amcharts4/lang/pt_BR';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

export function BarsWithMovingBullets(ref, data, options = {
  valueName: '',
  categoryName: '',
  tooltipText: '',
  strokeColor: '#fff',
  strokeOpacity: 1,
  paddingLeft: 20,
  paddingRight: 20,
}) {
  const chart = am4core.create(ref, am4charts.XYChart);
  chart.language.locale = am4lang_pt_BR;
  chart.paddingLeft = options.paddingLeft;
  chart.paddingRight = options.paddingRight;

  chart.data = data;

  const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = options.categoryName;
  categoryAxis.renderer.grid.template.strokeOpacity = 0;
  categoryAxis.renderer.minGridDistance = 10;
  categoryAxis.renderer.labels.template.dx = -40;
  categoryAxis.renderer.minWidth = 120;
  categoryAxis.renderer.tooltip.dx = -40;

  const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
  valueAxis.renderer.inside = true;
  valueAxis.renderer.labels.template.fillOpacity = 0.3;
  valueAxis.renderer.grid.template.strokeOpacity = 0;
  valueAxis.min = 0;
  valueAxis.cursorTooltipEnabled = false;
  valueAxis.renderer.baseGrid.strokeOpacity = 0;
  valueAxis.renderer.labels.template.dy = 20;

  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueX = options.valueName;
  series.dataFields.categoryY = options.categoryName;
  series.tooltipText = options.tooltipText;
  series.tooltip.pointerOrientation = 'vertical';
  series.tooltip.dy = -30;
  series.columnsContainer.zIndex = 100;

  const columnTemplate = series.columns.template;
  columnTemplate.height = am4core.percent(50);
  columnTemplate.maxHeight = 50;
  columnTemplate.column.cornerRadius(60, 10, 60, 10);
  columnTemplate.strokeOpacity = 0;

  series.heatRules.push({
    target: columnTemplate, property: 'fill', dataField: 'valueX', min: am4core.color('#e5dc36'), max: am4core.color('#5faa46'),
  });
  series.mainContainer.mask = undefined;

  const cursor = new am4charts.XYCursor();
  chart.cursor = cursor;
  cursor.lineX.disabled = true;
  cursor.lineY.disabled = true;
  cursor.behavior = 'none';

  const bullet = columnTemplate.createChild(am4charts.CircleBullet);
  bullet.circle.radius = 30;
  bullet.valign = 'middle';
  bullet.align = 'left';
  bullet.isMeasured = true;
  bullet.interactionsEnabled = false;
  bullet.horizontalCenter = 'right';
  bullet.interactionsEnabled = false;

  const hoverState = bullet.states.create('hover');
  const outlineCircle = bullet.createChild(am4core.Circle);
  outlineCircle.adapter.add('radius', (radius, target) => {
    const circleBullet = target.parent;
    return circleBullet.circle.pixelRadius + 10;
  });

  const image = bullet.createChild(am4core.Image);
  image.width = 60;
  image.height = 60;
  image.horizontalCenter = 'middle';
  image.verticalCenter = 'middle';
  image.propertyFields.href = 'href';

  image.adapter.add('mask', (mask, target) => {
    const circleBullet = target.parent;
    return circleBullet.circle;
  });

  let previousBullet;
  chart.cursor.events.on('cursorpositionchanged', () => {
    const dataItem = series.tooltipDataItem;

    if (dataItem.column) {
      const bulletItem = dataItem.column.children.getIndex(1);

      if (previousBullet && previousBullet !== bulletItem) {
        previousBullet.isHover = false;
      }

      if (previousBullet !== bulletItem) {
        const hs = bulletItem.states.getKey('hover');
        hs.properties.dx = dataItem.column.pixelWidth;
        bulletItem.isHover = true;

        previousBullet = bulletItem;
      }
    }
  });

  return chart;
}

export function ClusteredColumn(ref, data, options = {
  xValueName: '',
  yValueName: '',
  xLabelName: '',
  yLabelName: '',
  seriesName: '',
  paddingLeft: 20,
  paddingRight: 20,
}) {
  const chart = am4core.create(ref, am4charts.XYChart);
  chart.language.locale = am4lang_pt_BR;
  chart.paddingLeft = options.paddingLeft;
  chart.paddingRight = options.paddingRight;
  chart.colors.step = 2;

  chart.legend = new am4charts.Legend();
  chart.legend.position = 'top';
  chart.legend.paddingBottom = 20;
  chart.legend.labels.template.maxWidth = 95;

  const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  xAxis.dataFields.category = options.categoryName;
  xAxis.renderer.cellStartLocation = 0.1;
  xAxis.renderer.cellEndLocation = 0.9;
  xAxis.renderer.grid.template.location = 0;

  const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
  yAxis.min = 0;

  function arrangeColumns() {
    const series = chart.series.getIndex(0);

    const w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
    if (series.dataItems.length > 1) {
      const x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX');
      const x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX');
      const delta = ((x1 - x0) / chart.series.length) * w;
      if (am4core.isNumber(delta)) {
        const middle = chart.series.length / 2;

        let newIndex = 0;
        chart.series.each((series) => {
          if (!series.isHidden && !series.isHiding) {
            series.dummyData = newIndex;
            newIndex += 1;
          } else {
            series.dummyData = chart.series.indexOf(series);
          }
        });
        const visibleCount = newIndex;
        const newMiddle = visibleCount / 2;

        chart.series.each((series) => {
          const trueIndex = chart.series.indexOf(series);
          const newIndex = series.dummyData;

          const dx = (newIndex - trueIndex + middle - newMiddle) * delta;

          series.animate({ property: 'dx', to: dx }, series.interpolationDuration, series.interpolationEasing);
          series.bulletsContainer.animate({ property: 'dx', to: dx }, series.interpolationDuration, series.interpolationEasing);
        });
      }
    }
  }

  function createSeries(value, name) {
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = value;
    series.dataFields.valueY = value;
    series.dataFields.categoryX = options.categoryName;
    series.name = name;

    series.events.on('shown', arrangeColumns());

    const hoverState = series.columns.template.states.create('hover');
    hoverState.properties.fillOpacity = 1;
    hoverState.properties.tension = 0.8;

    chart.cursor = new am4charts.XYCursor();

    const bullet = series.bullets.push(new am4charts.LabelBullet());
    bullet.interactionsEnabled = true;
    bullet.dy = 30;
    bullet.label.text = options.tooltipText;
    bullet.label.fill = am4core.color('#ffffff');

    return series;
  }

  chart.data = data;

  createSeries(options.xValueName, options.xLabelName);
  createSeries(options.yValueName, options.yLabelName);

  chart.scrollbarX = new am4core.Scrollbar();
  chart.scrollbarY = new am4core.Scrollbar();

  return chart;
}

export function MultipleValueAxis(ref, data, options = {
  categoryName: 'period',
  multipleAxis: true,
  formatDate: 'day',
  series: [
    {
      valueName: 'entries',
      labelName: 'Entrada',
      isOpposite: false,
      markType: 'circle',
      tooltipText: '{name}: R$[bold]{valueY}[/] \n Date: {dateX}',
    },
    {
      valueName: 'exits',
      labelName: 'Saída',
      isOpposite: false,
      markType: 'circle',
      tooltipText: '{name}: R$[bold]{valueY}[/] \n Date: {dateX} ',
    },
  ],
}) {
  am4core.useTheme(am4themes_animated);

  const chart = am4core.create(ref, am4charts.XYChart);
  chart.language.locale = am4lang_pt_BR;
  // Increase contrast by taking evey second color
  chart.colors.step = 2;

  // Add format Date
  if (options.formatDate === 'day') {
    chart.dateFormatter.dateFormat = 'dd/MM/yyyy';
  } else if (options.formatDate === 'month') {
    chart.dateFormatter.dateFormat = 'MM/yyyy';
  } else {
    chart.dateFormatter.dateFormat = 'yyyy';
  }

  // Add data
  chart.data = data;

  // Create Date Axis
  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.minGridDistance = 50;
  dateAxis.renderer.grid.template.location = 0.5;
  dateAxis.dateFormats.setKey('day', 'dd MMM [/]');
  dateAxis.dateFormats.setKey('month', 'MMM yyyy[/]');
  dateAxis.dateFormats.setKey('year', 'yyyy[/]');

  // Create Value Axis
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

  // Create series
  function createAxisAndSeries(field, name, opposite, type, tooltip, multiple) {
    if (options.multiple === true && chart.yAxes.indexOf(valueAxis) !== 0) {
      valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
    } else {
      valueAxis.baseValue = 0;
    }

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = 'date';
    series.strokeWidth = 2;
    series.yAxis = valueAxis;
    series.name = name;
    series.tooltipText = tooltip;
    series.tensionX = 0.95;
    series.tensionY = 0.95;
    series.showOnInit = true;

    const interfaceColors = new am4core.InterfaceColorSet();

    let bullet = '';
    let triangle = '';
    let rectangle = '';
    switch (type) {
      case 'triangle':
        // eslint-disable-next-line no-case-declarations
        bullet = series.bullets.push(new am4charts.Bullet());
        bullet.width = 12;
        bullet.height = 12;
        bullet.horizontalCenter = 'middle';
        bullet.verticalCenter = 'middle';

        triangle = bullet.createChild(am4core.Triangle);
        triangle.stroke = interfaceColors.getFor('background');
        triangle.strokeWidth = 2;
        triangle.direction = 'top';
        triangle.width = 12;
        triangle.height = 12;
        break;
      case 'rectangle':
        bullet = series.bullets.push(new am4charts.Bullet());
        bullet.width = 10;
        bullet.height = 10;
        bullet.horizontalCenter = 'middle';
        bullet.verticalCenter = 'middle';

        rectangle = bullet.createChild(am4core.Rectangle);
        rectangle.stroke = interfaceColors.getFor('background');
        rectangle.strokeWidth = 2;
        rectangle.width = 10;
        rectangle.height = 10;
        break;
      default:
        bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.stroke = interfaceColors.getFor('background');
        bullet.circle.strokeWidth = 2;
        break;
    }

    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.renderer.line.stroke = series.stroke;
    valueAxis.renderer.labels.template.fill = series.stroke;
    valueAxis.renderer.opposite = opposite;
  }

  options.series.forEach((series) => {
    createAxisAndSeries(
      series.valueName,
      series.labelName,
      series.isOpposite,
      series.markType,
      series.tooltipText,
    );
  });

  // Add legend
  chart.legend = new am4charts.Legend();

  // Add cursor
  chart.cursor = new am4charts.XYCursor();

  chart.scrollbarX = new am4core.Scrollbar();
  chart.scrollbarY = new am4core.Scrollbar();

  return chart;
}

export function Pie(ref, data, options = {
  valueName: '',
  categoryName: '',
  strokeColor: '#fff',
  strokeOpacity: 1,
  paddingLeft: 20,
  paddingRight: 20,
}) {
  const chart = am4core.create(ref, am4charts.PieChart);
  chart.language.locale = am4lang_pt_BR;
  chart.paddingLeft = options.paddingLeft;
  chart.paddingRight = options.paddingRight;

  chart.data = data;

  // Add and configure Series
  const pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = options.valueName;
  pieSeries.dataFields.category = options.categoryName;
  pieSeries.slices.template.stroke = am4core.color(options.strokeColor);
  pieSeries.slices.template.strokeOpacity = options.strokeOpacity;

  // This creates initial animation
  pieSeries.hiddenState.properties.opacity = 1;
  pieSeries.hiddenState.properties.endAngle = -90;
  pieSeries.hiddenState.properties.startAngle = -90;

  chart.hiddenState.properties.radius = am4core.percent(0);

  return chart;
}

export function Pie3D(ref, data, options = {
  valueName: '',
  categoryName: '',
  strokeColor: '#fff',
  strokeOpacity: 1,
  paddingLeft: 20,
  paddingRight: 20,
}) {
  const chart = am4core.create(ref, am4charts.PieChart3D);
  chart.language.locale = am4lang_pt_BR;
  chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
  chart.paddingLeft = options.paddingLeft;
  chart.paddingRight = options.paddingRight;

  chart.data = data;

  chart.legend = new am4charts.Legend();

  const series = chart.series.push(new am4charts.PieSeries3D());
  series.dataFields.value = options.valueName;
  series.dataFields.category = options.categoryName;

  return chart;
}

export function SmoothedLineChart(ref, data, options = {
  categoryName: '',
  xValueName: '',
  yValueName: '',
  paddingLeft: 20,
  paddingRight: 20,
}) {
  const chart = am4core.create(ref, am4charts.XYChart);
  chart.language.locale = am4lang_pt_BR;
  chart.paddingLeft = options.paddingLeft;
  chart.paddingRight = options.paddingRight;

  chart.paddingRight = 20;

  chart.data = data;

  // Create axes
  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = options.categoryName;
  categoryAxis.renderer.minGridDistance = 50;
  categoryAxis.renderer.grid.template.location = 0.5;
  categoryAxis.startLocation = 0.5;
  categoryAxis.endLocation = 0.5;

  // Create value axis
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.baseValue = 0;

  // Create series
  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueY = options.yValueName;
  series.dataFields.categoryX = options.xValueName;
  series.strokeWidth = 2;
  series.tensionX = 0.77;

  // bullet is added because we add tooltip to a bullet for it to change color
  const bullet = series.bullets.push(new am4charts.Bullet());
  bullet.tooltipText = '{valueY}';

  bullet.adapter.add('fill', (fill, target) => {
    if (target.dataItem.valueY < 0) {
      return am4core.color('#FF0000');
    }
    return fill;
  });
  const range = valueAxis.createSeriesRange(series);
  range.value = 0;
  range.endValue = -1000;
  range.contents.stroke = am4core.color('#FF0000');
  range.contents.fill = range.contents.stroke;

  // Add scrollbar
  const scrollbarX = new am4charts.XYChartScrollbar();
  scrollbarX.series.push(series);
  chart.scrollbarX = scrollbarX;

  chart.cursor = new am4charts.XYCursor();

  return chart;
}

export function XYColumn(ref, data, options = {
  xValueName: '',
  yValueName: '',
  seriesName: '',
  tooltipText: '',
  paddingLeft: 20,
  paddingRight: 20,
}) {
  const chart = am4core.create(ref, am4charts.XYChart);
  chart.language.locale = am4lang_pt_BR;
  chart.paddingLeft = options.paddingLeft;
  chart.paddingRight = options.paddingRight;

  chart.data = data;

  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = options.xValueName;
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 30;

  categoryAxis.renderer.labels.template.adapter.add('dy', (dy, target) => {
    if (target.dataItem && target.dataItem.index) {
      return dy + 25;
    }
    return dy;
  });

  const valueYAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueYAxis.renderer.minWidth = 35;

  // Create series
  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.valueY = options.yValueName;
  series.dataFields.categoryX = options.xValueName;
  series.name = options.seriesName;
  series.columns.template.tooltipText = options.tooltipText;
  series.columns.template.fillOpacity = 0.8;

  chart.cursor = new am4charts.XYCursor();

  const columnTemplate = series.columns.template;
  columnTemplate.strokeWidth = 2;
  columnTemplate.strokeOpacity = 1;

  chart.scrollbarX = new am4core.Scrollbar();
  chart.scrollbarY = new am4core.Scrollbar();

  return chart;
}

export function XYDateValue(ref, data, options = {
  xValueName: 'x',
  yValueName: 'y',
  paddingLeft: 20,
  paddingRight: 20,
}) {
  const chart = am4core.create(ref, am4charts.XYChart);
  chart.language.locale = am4lang_pt_BR;
  chart.paddingLeft = options.paddingLeft;
  chart.paddingRight = options.paddingRight;

  chart.data = data;

  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0;

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minWidth = 35;

  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.dateX = options.xValueName;
  series.dataFields.valueY = options.yValueName;

  series.tooltipText = '{valueY.value}';
  chart.cursor = new am4charts.XYCursor();

  const scrollbarX = new am4charts.XYChartScrollbar();
  scrollbarX.series.push(series);
  chart.scrollbarX = scrollbarX;

  return chart;
}

export function XYLine(ref, data, options = {
  xValueName: '',
  yValueName: '',
  paddingLeft: 20,
  paddingRight: 20,
}) {
  const chart = am4core.create(ref, am4charts.XYChart);
  chart.language.locale = am4lang_pt_BR;
  chart.paddingLeft = options.paddingLeft;
  chart.paddingRight = options.paddingRight;

  chart.data = data;

  const valueXAxis = chart.xAxes.push(new am4charts.ValueAxis());
  valueXAxis.tooltip.disabled = true;
  valueXAxis.renderer.minWidth = 35;

  const valueYAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueYAxis.tooltip.disabled = true;
  valueYAxis.renderer.minWidth = 35;

  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueX = options.xValueName;
  series.dataFields.valueY = options.yValueName;

  series.tooltipText = '{valueY.value}';
  chart.cursor = new am4charts.XYCursor();

  const scrollbarX = new am4charts.XYChartScrollbar();
  scrollbarX.series.push(series);
  chart.scrollbarX = scrollbarX;

  return chart;
}

export function XYLineDiferentColors(ref, data, options = {
  xValueName: '',
  yValueName: '',
  paddingLeft: 20,
  paddingRight: 20,
}) {
  const chart = am4core.create(ref, am4charts.XYChart);
  chart.language.locale = am4lang_pt_BR;
  chart.paddingLeft = options.paddingLeft;
  chart.paddingRight = options.paddingRight;

  chart.data = data;

  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0;
  dateAxis.renderer.axisFills.template.disabled = true;
  dateAxis.renderer.ticks.template.disabled = true;

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.minWidth = 35;
  valueAxis.renderer.axisFills.template.disabled = true;
  valueAxis.renderer.ticks.template.disabled = true;

  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.dateX = options.xValueName;
  series.dataFields.valueY = options.yValueName;
  series.strokeWidth = 2;
  series.tooltipText = 'value: {valueY}, day change: {valueY.previousChange}';

  // set stroke property field
  series.propertyFields.stroke = 'color';

  chart.cursor = new am4charts.XYCursor();

  const scrollbarX = new am4core.Scrollbar();
  chart.scrollbarX = scrollbarX;

  dateAxis.start = 0.7;
  dateAxis.keepSelection = true;

  return chart;
}

export const Charts = {
  install(vue) {
    vue.prototype.$chartsBarsWithMovingBullets = BarsWithMovingBullets;
    vue.prototype.$chartsClusteredColumn = ClusteredColumn;
    vue.prototype.$chartsMultipleValueAxis = MultipleValueAxis;
    vue.prototype.$chartsPie = Pie;
    vue.prototype.$chartsPie3D = Pie3D;
    vue.prototype.$chartsSmoothedLineChart = SmoothedLineChart;
    vue.prototype.$chartsXYColumn = XYColumn;
    vue.prototype.$chartsXYDateValue = XYDateValue;
    vue.prototype.$chartsXYLine = XYLine;
    vue.prototype.$chartsXYLineDiferentColors = XYLineDiferentColors;
  },
};

Vue.use(Charts);
