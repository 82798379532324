import OrderProductionItemBusiness from '@businesses/OrderProductionItem';

import ProductSchema from './Product';

export default {
  service: 'Production',
  business: OrderProductionItemBusiness,
  icon: ProductSchema.icon,
  routes: {
    list: '',
    edit: '',
    create: '',
  },
  titles: {
    item: 'Ítem da Composição do Produto',
    items: 'Itens da Composição do Produto',
    edit: 'Editar Composição do Produto',
    create: 'Criar Composição do Produto',
    new: 'Adicionar Item',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: '',
  fields: [
    {
      label: 'Produto',
      name: 'product_id',
      formattedName: 'product.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      list: true,
      md: 8,
    },
    {
      label: 'Código',
      name: 'product_code',
      formattedName: 'product_code',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'code',
      itemValue: 'id',
      list: true,
      md: 4,
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      formattedName: 'stock.to_s',
      list: true,
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 6,
    },
    {
      label: 'Quantidade no Estoque',
      name: 'stock_quantity',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      disabled: true,
      precision: 2,
      min: 0,
      md: 3,
    },
    {
      label: 'Unidade de medida',
      name: 'unity_id',
      formattedName: 'unity.to_s',
      type: 'number',
      list: true,
      required: true,
      disabled: true,
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-measuringunits-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['unity'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
    },
    {
      label: 'A Produzir',
      name: 'ordered_quantity',
      formattedName: 'ordered_quantity_formatted',
      type: 'number',
      list: true,
      required: true,
      component: 'dynamic-number-field',
      disabled: true,
      md: 3,
    },
    {
      label: 'Sugerida',
      name: 'suggested_quantity',
      formattedName: 'suggested_quantity_formatted',
      type: 'number',
      list: true,
      required: true,
      disabled: true,
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Retorno',
      name: 'returned_quantity',
      formattedName: 'returned_quantity_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Requerida',
      name: 'required_quantity',
      formattedName: 'required_quantity_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Perda',
      name: 'lost_quantity',
      formattedName: 'lost_quantity_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Total Utilizado',
      name: 'total_quantity',
      formattedName: 'total_quantity_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Fixo',
      name: 'is_fixed',
      formattedName: 'is_fixed_formatted',
      type: 'boolean',
      list: true,
      component: 'dynamic-checkbox',
      md: 3,
    },
  ],
};
