<template>
  <div :id="item.id">
    <GenericCardPanel
      title="Detalhes"
      :fields="detailInfoFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabInvoiceTemplate',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      detailInfoFields: [],
    };
  },
  mounted() {
    this.detailInfoFields = [
      { label: 'Descrição', value: this.item.description, col: 3 },
      { label: 'Tipo do Modelo', value: this.item.type_invoice_formatted, col: 2 },
      { label: 'Ambiente', value: this.item.environment_formatted, col: 2 },
      { label: 'Modelo', value: this.item.model, col: 2 },
      { label: 'Série', value: this.item.series, col: 1 },
      { label: 'Número Atual', value: this.item.current_number, col: 2 },
      { label: 'UF', value: this.item.uf, col: 3 },
      { label: 'Cidade/Município', value: this.item.city, col: 4 },
    ];
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style scoped></style>
