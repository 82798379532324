import Vue from 'vue';
import { filterArray } from '@plugins/app-utils';

export default {
  screenEvents: {
    beforeCreate(formValue) {
      formValue.emission_date = new Date();
    },
  },
  fieldEvents: {
    invoice: {
      change: async (formValue) => {
        let totalNotes = 0;
        const invoices = formValue.invoice.filter((item) => item._destroy !== true) || [];
        invoices.forEach((item) => {
          totalNotes += (parseFloat(item.invoice_total_value) || 0);
        });
        formValue.load.total_value = totalNotes;
        Vue.set(formValue.load, 'total_value', formValue.load.total_value);
      },
    },
    'logistic.course_states': {
      change: async (formValue, fieldValue) => {
        const fieldClean = filterArray(fieldValue, 'uf');
        formValue.logistic.course_states = fieldClean;
      },
    },
  },
};
