import { ICON_CASH_REFUND } from '@constants/icons';

export default {
  devMode: 'Maintenance',
  service: '',
  icon: ICON_CASH_REFUND,
  routes: {
    list: {
      path: '/financial/anticipation',
      name: 'financial-anticipation-list',
    },
  },
  titles: {
    item: 'Antecipação de Recebíveis',
    items: 'Antecipações de Recebíveis',
  },
};
