import DevolutionPurchasesSchema from '@schemas/DevolutionPurchase';

const ListDevolutionPurchases = () => import(/* webpackChunkName: "list-purchases-returns" */'./ListDevolutionPurchases');
const DetailDevolutionPurchases = () => import(/* webpackChunkName: "detail-purchases-returns" */'./DetailDevolutionPurchase');

export default [
  {
    path: DevolutionPurchasesSchema.routes.list.path,
    name: DevolutionPurchasesSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionPurchasesSchema.titles.items },
      ],
    },
    component: ListDevolutionPurchases,
  },
  {
    path: DevolutionPurchasesSchema.routes.create.path,
    name: DevolutionPurchasesSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionPurchasesSchema.titles.items, route: DevolutionPurchasesSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailDevolutionPurchases,
  },
  {
    path: DevolutionPurchasesSchema.routes.edit.path,
    name: DevolutionPurchasesSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionPurchasesSchema.titles.items, route: DevolutionPurchasesSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailDevolutionPurchases,
  },
];
