<template>
  <div class="tab-budget-items">
    <GenericCardPanel>
      <TabListTaxes
        :schema="schema"
        :taxes="itemTaxes" />
    </GenericCardPanel>
  </div>
</template>

<script>

import OrderBudgetSchema from '@schemas/OrderBudget';
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';
import TabListTaxes from '@sharedComponents/tabs/TabListTaxes';

export default {
  name: 'TabOrderSaleItemTax',
  components: {
    GenericCardPanel,
    TabListTaxes,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      itemTaxes: [],
    };
  },
  computed: {
    schema() {
      return OrderBudgetSchema;
    },
  },
  created() {
    this.itemTaxes = this.item.items;
  },
};
</script>
