/* eslint-disable no-param-reassign */
import {
  formatCnpjCpf, formatDate, getOptionText, parseDate, onlyNumbers,
} from '@plugins/app-utils';
import vue from 'vue';
import DynamicService from '@sharedServices/dynamic/DynamicService';
import searchCEP from '@plugins/searchCEP';

const endpoint = 'registers';

const RegistersCommands = ($axios) => ({
  async getStockConsignments(id) {
    const res = await $axios.get(`consignment_stocks/${id}/buyer`);
    res.data.forEach((data) => {
      data.type_transaction_formatted = data.type_transaction === 'entry' ? 'Entrada' : 'Saída';
      data.transaction_date_formatted = formatDate(data.transaction_date);
    });
    return res;
  },
  async getStockIndustrializations(id) {
    const res = await $axios.get(`industrialization_stocks/${id}/buyer`);
    res.data.forEach((data) => {
      data.type_transaction_formatted = data.type_transaction === 'entry' ? 'Entrada' : 'Saída';
      data.transaction_date_formatted = formatDate(data.transaction_date);
    });
    return res;
  },
  async getHistoryObservations(id) {
    const res = await $axios.get(`${endpoint}/${id}/observations`);
    res.data.forEach((data) => {
      data.emission_date_formatted = formatDate(data.emission_date);
    });
    return res;
  },
  async getHistoryObservationsByFilter(id, filters, order = 'ASC') {
    const filtersFormatted = {
      type_observation_id_eq: filters.type_observation_id,
      register_id_eq: id,
      emission_date_lteq: parseDate(filters.start_date),
      emission_date_gteq: parseDate(filters.end_date),
    };

    if (filters.type_observation_id_eq === '') {
      delete filtersFormatted.type_observation_id_eq;
    }
    if (filters.emission_date_lteq === null || filters.is_select_start_date === false) {
      delete filtersFormatted.emission_date_lteq;
    }
    if (filters.emission_date_gteq === null || filters.is_select_end_date === false) {
      delete filtersFormatted.emission_date_gteq;
    }

    const payload = {
      q: filtersFormatted || {},
    };
    // add sort option
    if (order) {
      payload.q.s = order;
    }
    const responseData = await $axios.post(`${endpoint}/observations/filters`, payload);
    responseData.data.forEach((data) => {
      data.emission_date_formatted = formatDate(data.emission_date);
    });
    return responseData;
  },
  async getRegistersByType(type) {
    if (!type) return [];

    let registers = [];
    await vue.prototype.$api.Registers.getByFilterGraphql({
      type_register_array: type,
      query: ['id', 'toS'],
    }).then((response) => {
      registers = response;
    });
    return registers;
  },
  async getByCnpjCpf(value) {
    value = value.replace(/[^0-9]/gi, '');
    const payload = {
      cnpj_cpf: value,
    };
    const { data } = await $axios.post(`${endpoint}/cnpj_cpf.json`, payload);
    data.addresses.forEach(async (address) => {
      if (!address.ibge) {
        const { ibge } = await searchCEP(address.zip_code.replace(/\D/, ''));
        address.ibge = ibge;
      }
    });
    return data;
  },
  getBankInfoAccountType(value) {
    if (!value) return '';
    return getOptionText('register_bank_account_type', value);
  },
  getBankInfoBankCode(value) {
    if (!value) return '';
    return getOptionText('number', value);
  },
  getBankInfoPixType(value) {
    if (!value) return '';
    return getOptionText('pix_type', value);
  },
  getGender(value) {
    if (!value) return '';
    return getOptionText('gender', value);
  },
  getTypeNotification(values) {
    if (!values) return '';

    let result = '';
    values.forEach((element) => {
      result += `${getOptionText('direct_mails', element)}, `;
    });
    return result.replace(/,\s*$/, '');
  },
  getTypeRegisters(values) {
    if (!values) return '';

    let result = '';
    values.forEach((element) => {
      result += `${getOptionText('type_register', element)}, `;
    });
    return result.replace(/,\s*$/, '');
  },
  getTaxRegime(value) {
    if (!value) return '';

    return getOptionText('tax_regime', value);
  },
  getTaxPayer(value) {
    if (!value) return '';

    return getOptionText('tax_payer', value);
  },
});

const formatResponse = async (register) => {
  register.id = parseInt(register.id);
  register.cnpj_cpf_formatted = formatCnpjCpf(register.cnpj_cpf);
  register.type_register_formatted = register.type_register !== null ? await RegistersCommands().getTypeRegisters(register.type_register) : '';
  register.date_register = register.date_register !== null ? formatDate(register.date_register) : '';
  register.inactive_date = register.inactive_date !== null ? formatDate(register.inactive_date) : '';
  register.blocked_date = register.blocked_date !== null ? formatDate(register.blocked_date) : '';
  register.credit_date = register.credit_date !== null ? formatDate(register.credit_date) : '';
  register.tax_regime_formatted = register.tax_regime !== null ? RegistersCommands().getTaxRegime(register.tax_regime) : '';
  register.taxpayer_type_formatted = register.taxpayer_type !== null ? RegistersCommands().getTaxPayer(register.taxpayer_type) : '';

  if (register.addresses) {
    register.addresses.forEach((address) => {
      address.id = parseInt(address.id);
      if (address.main === true) {
        register.address_zip_code = address.zip_code;
        register.address_street = address.street;
        register.address_number = address.number;
        register.address_neighborhood = address.neighborhood;
        register.address_complement = address.complement;
        register.address_city = address.city;
        register.address_uf = address.uf;
      }
    });
  }

  if (register.contacts) {
    register.contacts = register.contacts.map((i) => {
      const formattedData = {
        ...i,
        id: parseInt(i.id),
        type_notification_formatted: i.type_notification !== null ? RegistersCommands().getTypeNotification(i.type_notification) : '',
        gender_formatted: i.gender !== null ? RegistersCommands().getGender(i.gender) : '',
        date_birth: i.date_birth !== null ? formatDate(i.date_birth) : '',
      };
      return formattedData;
    });
  }

  register.preference = register?.preference || {};

  if (register.register_bank_info) {
    register.register_bank_info.forEach((i) => {
      i.account_type_formatted = i?.account_type !== null ? RegistersCommands().getBankInfoAccountType(i.account_type) : '';
      i.number_formatted = i?.number?.bank !== null ? RegistersCommands().getBankInfoBankCode(i.number) : '';
      i.pix_type_formatted = i?.pix_type !== null ? RegistersCommands().getBankInfoPixType(i.pix_type) : '';
    });
  }

  if (register.observations) {
    register.observations.forEach((i) => {
      i.emission_date = formatDate(i.emission_date);
    });
  }

  return register;
};

const formatRequest = (register) => {
  delete register.to_s;
  delete register.id;
  if (register?.addresses) {
    register.addresses.forEach((address) => {
      delete address.to_s;
    });
  }
  register.addresses_attributes = register.addresses || [];
  if (register.register_bank_info) {
    register.register_bank_info.forEach((i) => {
      i.account = i.account !== null ? onlyNumbers(i.account) : '';
    });
  }

  register.register_bank_info_attributes = register.register_bank_info || [];

  register.contacts_attributes = register.contacts || [];
  register.preference_attributes = register.preference || {};
  register.observations_attributes = register.observations || [];
};

const formatResponseDataReport = (data, type = 'table') => {
  const format = (item) => {
    item.cnpj_cpf_formatted = formatCnpjCpf(item.cnpj_cpf);
    item.type_register_formatted = item.type_register !== null ? this.getTypeRegisters(item.type_register) : '';
    item.date_register = item.date_register !== null ? formatDate(item.date_register) : '';
    item.inactive_date = item.inactive_date !== null ? formatDate(item.inactive_date) : '';
    item.blocked_date = item.blocked_date !== null ? formatDate(item.blocked_date) : '';
    item.credit_date = item.credit_date !== null ? formatDate(item.credit_date) : '';
    item.tax_regime_formatted = item.tax_regime !== null ? this.getTaxRegime(item.tax_regime) : '';
    item.taxpayer_type_formatted = item.taxpayer_type !== null ? this.getTaxPayer(item.taxpayer_type) : '';
    if (item.addresses) {
      item.addresses.forEach((address) => {
        if (address.main === true) {
          item.address_zip_code = address.zip_code;
          item.address_street = address.street;
          item.address_number = address.number;
          item.address_neighborhood = address.neighborhood;
          item.address_complement = address.complement;
          item.address_city = address.city;
          item.address_uf = address.uf;
        }
      });
    }
    if (item.contacts) {
      item.contacts.forEach((contact) => {
        item.contact_type_notification_formatted = contact.type_notification !== null ? this.getTypeNotification(contact.type_notification) : '';
        item.contact_gender_formatted = contact.gender !== null ? this.getGender(contact.gender) : '';
        item.contact_date_birth = contact.date_birth !== null ? formatDate(contact.date_birth) : '';
        item.contact_telephone = contact.telephone;
        item.contact_email = contact.email;
        item.contact_name = contact.name;
      });
    }

    item.preference = item?.preference || {};
  };

  let responseData = {};

  if (type === 'table') {
    data.forEach((item) => {
      format(item);
    });
    responseData = { data };
    return responseData;
  }
  format(data);
  return data;
};

const Registers = DynamicService(endpoint, {
  formatResponse,
  formatRequest,
  formatResponseDataReport,
  useDirectData: true,
});

export default { Registers, RegistersCommands };
export { Registers, RegistersCommands };
