import {
  ICON_DESCRIPTION,
  ICON_HANDS_SHAKE,
  ICON_TAXES,
  ICON_TRUCK,
} from '@constants/icons';
import { OPTIONS_FREIGHT, OPTIONS_ORDERSPURCHASESALE_STATUS, OPTIONS_PRESENCE_INDICATOR } from '@constants/options';
import RemittanceConsignmentBusiness from '@businesses/RemittanceConsignment';
import RemittanceConsignmentItem from './RemittanceConsignmentItem';
import RemittanceConsignmentItemTax from './RemittanceConsignmentItemTax';
import RemittanceConsignmentTransporter from './RemittanceConsignmentTransporter';

import TabRemittanceConsignment from '@modules/supply/pages/consignments/components/TabRemittanceConsignment';
import TabRemittanceConsignmentItens from '@modules/supply/pages/consignments/components/TabRemittanceConsignmentItens';
import TabRemittanceConsignmentItensTax from '@modules/supply/pages/consignments/components/TabRemittanceConsignmentItensTax';
import TabRemittanceConsignmentTransporter from '@modules/supply/pages/consignments/components/TabRemittanceConsignmentTransporter';

import OrderPurchaseSchema from './OrderPurchase';

export default {
  service: 'RemittanceConsignments',
  business: RemittanceConsignmentBusiness,
  icon: ICON_HANDS_SHAKE,
  routes: {
    list: { path: '/supply/consignments/remittance', name: 'supply-RemittanceConsignments-list' },
    create: { path: '/supply/consignments/remittance/create', name: 'supply-RemittanceConsignments-create' },
    edit: { path: '/supply/consignments/remittance/edit/:id', name: 'supply-RemittanceConsignments-edit' },
  },
  titles: {
    item: 'Consignação - Saída',
    items: 'Consignações - Saídas',
    edit: 'Editar Consignação - Saída',
    create: 'Criar Consignação - Saída',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Consignação - saída excluida com sucesso!',
    successEdit: 'Consignação - saída alterada com sucesso!',
    successCreate: 'Consignação - saída criada com sucesso!',
    errorSave: 'Erro ao salvar consignação - saída!',
    tryEditCanceled: 'Não é possível editar uma consignação - saída cancelada.',
    confirmCancel: 'Deseja cancelar a consignação - saída?',
    successCancel: 'Consignação - saída cancelada com sucesso',
    errorCancel: 'Erro ao cancelar consignação - saída',
  },
  filterUrl: 'order_purchases/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  statusSettings: {
    options: OPTIONS_ORDERSPURCHASESALE_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  formName: 'RemittanceConsignments',
  fields: [
    {
      label: 'INFORMAÇÕES DA CONSIGNAÇÃO - SAÍDA',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
      tabGroup: 'freight',
    },
    {
      label: 'Status',
      formattedName: 'status',
      name: 'status',
      type: 'select',
      options: 'orders_purchase_sale_status',
      list: true,
      tabGroup: 'info',
    },
    {
      label: 'Cliente',
      name: 'buyer_id',
      formattedName: 'buyer.to_s',
      type: 'number',
      hint: '',
      list: true,
      required: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_register_array: 'client',
          query: ['id', 'name', 'cnpj_cpf', 'addresses', ['id', 'to_s'], 'preference', ['salesman_id', 'shipping_company_id']],
        }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      withDetails: true,
      typeDetails: 'client',
      md: 8,
      tabGroup: 'info',
    },
    {
      label: 'Pedido',
      name: 'consignment_order',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 2,
      tabGroup: 'info',
    },
    {
      label: 'Data Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      list: true,
      tabGroup: 'info',
    },
    {
      label: 'Vendedor',
      name: 'salesman_id',
      formattedName: 'salesman.to_s',
      type: 'number',
      list: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{ type_register_array: 'salesman', query: ['id', 'name'] }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      md: 8,
      tabGroup: 'info',
    },
    {
      label: 'NFe',
      name: 'note',
      formattedName: 'note_formatted',
      list: true,
      type: 'text',
      component: 'dynamic-text-field',
      readOnly: true,
      md: 2,
      tabGroup: 'info',
    },
    {
      label: 'Data Previsão',
      name: 'forecast_date',
      formatteName: 'forecast_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      tabGroup: 'info',
    },
    {
      label: 'Consumidor Final',
      name: 'is_costumer',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
    },
    {
      label: 'Indicador de Presença',
      name: 'presence_indicator',
      formattedName: 'presence_indicator_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_PRESENCE_INDICATOR,
      md: 5,
      required: true,
    },
    {
      label: 'Nº da Ordem',
      name: 'order',
      type: 'text',
      component: 'dynamic-text-field',
      md: 2,
      list: true,
      tabGroup: 'info',
    },
    {
      label: 'Data Entrega',
      name: 'delivery_date',
      formatteName: 'delivery_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      md: 2,
      tabGroup: 'info',
    },
    {
      label: 'Total de Impostos Aproximados',
      formattedName: 'total_tax_aproximates',
      name: 'total_tax_aproximates',
      type: 'number',
      tabGroup: 'info',
    },
    {
      label: 'Valor Total',
      formattedName: 'total_ordered_formatted',
      type: 'number',
      list: true,
      tabGroup: 'info',
    },
    {
      label: 'INFORMAÇÕES DE FRETE',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'freight',
    },
    {
      label: 'Frete',
      name: 'freight',
      formattedName: 'freight_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_FREIGHT,
      required: true,
      md: 3,
      tabGroup: 'freight',
    },
    {
      label: 'Valor do Frete',
      name: 'freight_value',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
      tabGroup: 'freight',
    },
    {
      label: 'Despesas',
      name: 'expense_value',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
      tabGroup: 'freight',
    },
    {
      label: 'Incluir Frete no Total',
      name: 'use_freight',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      tabGroup: 'freight',
    },
    {
      label: 'TRIBUTAÇÕES DO PEDIDO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'tributes',
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      formattedName: 'nature_operation.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_nature_in: ['sale'],
          query: ['id', 'name'],
        }],
      },
      itemText: 'name',
      itemValue: 'id',
      list: true,
      required: true,
      md: 8,
      tabGroup: 'tributes',
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      formattedName: 'stock.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 4,
      tabGroup: 'tributes',
    },
  ],
  childSchemas: [
    {
      label: 'Itens da Consignação - Saída',
      name: 'items',
      schema: RemittanceConsignmentItem,
    },
    {
      label: 'Conferência de Impostos',
      name: 'taxes',
      noCreate: true,
      noEdit: true,
      noDelete: true,
      noActions: true,
      schema: RemittanceConsignmentItemTax,
      hideDefaultHeader: true,
      customHeader: {
        customClass: 'custom-child-header-order-purchase',
        ungroupedHeadersFront: 2,
        groupHeaders: [
          { text: 'SIMPLES NACIONAL', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS', colspan: 3, class: 'order-purchase-header' },
          { text: 'ICMS ST', colspan: 3, class: 'order-purchase-header' },
          { text: 'IPI', colspan: 3, class: 'order-purchase-header' },
          { text: 'PIS', colspan: 3, class: 'order-purchase-header' },
          { text: 'COFINS', colspan: 3, class: 'order-purchase-header' },
        ],
      },
    },
    {
      label: 'Transportadora',
      name: 'transporter',
      schema: RemittanceConsignmentTransporter,
      hasOne: true,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabRemittanceConsignment,
    },
    {
      icon: OrderPurchaseSchema.icon,
      title: 'Itens do Pedido de Compra',
      tabContent: TabRemittanceConsignmentItens,
    },
    {
      icon: ICON_TAXES,
      title: 'Conferência de Impostos',
      tabContent: TabRemittanceConsignmentItensTax,
    },
    {
      icon: ICON_TRUCK,
      title: 'Transportadora',
      tabContent: TabRemittanceConsignmentTransporter,
    },
  ],
};
