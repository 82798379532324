import * as AppUtils from '@plugins/app-utils';

const endpoint = 'white_labels';

export default ($axios) => ({
  /** get all objects */
  async index() {
    let responseData = {};
    await $axios.get(`${endpoint}.json`).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },
  /** show on object with id */
  show(id) {
    return $axios.get(`${endpoint}/${id}.json`);
  },
  /** create one object */
  create(payload) {
    return $axios.post(`${endpoint}.json`, payload);
  },
  /** update one object */
  update(id, payload) {
    return $axios.put(`${endpoint}/${id}.json`, payload);
  },
  /** delete one object */
  delete(id) {
    return $axios.delete(`${endpoint}/${id}.json`);
  },
  activate(id) {
    return $axios.post(`${endpoint}/${id}/activate.json`);
  },
  async situation(id) {
    let responseData = {};
    await $axios.get(`${endpoint}/${id}/account.json`).then((response) => {
      responseData = this.formatResponseDataSituation(response.data);
    });
    return responseData;
  },
  /** get all objects */
  async statements(id, queryParams) {
    let responseData = {};
    await $axios.get(`${endpoint}/${id}/statement.json?${queryParams}`).then((response) => {
      responseData = this.formatResponseDataStatements(response.data);
    });
    return responseData;
  },
  /** get all objects */
  async transfers(id) {
    let responseData = {};
    await $axios.get(`${endpoint}/${id}/transfers.json`).then((response) => {
      responseData = this.formatResponseDataTransfers(response.data);
    });
    return responseData;
  },
  /** create one object */
  createTransfer(id, payload) {
    return $axios.post(`${endpoint}/${id}/transfers.json`, payload);
  },
  /** get all objects */
  async billets(id) {
    let responseData = {};
    await $axios.get(`${endpoint}/${id}/billets.json`).then((response) => {
      responseData = this.formatResponseDataBillets(response.data);
    });
    return responseData;
  },
  payBillet(id, payload) {
    return $axios.post(`${endpoint}/${id}/billets/pay.json`, payload);
  },
  async balance(id) {
    let responseData = {};
    await $axios.get(`${endpoint}/${id}/balance.json`).then((response) => {
      responseData = this.formatResponseDataBalance(response.data, 'row');
    });
    return responseData;
  },
  getCompany() {
    return $axios.get(`${endpoint}/company.json`);
  },
  formatRequestActivate(data) {
    return data;
  },
  formatResponseData(data, type = 'table') {
    const format = (item) => {
      item.status_formatted = this.getWhiteLabelsStatus(item.status);
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  formatResponseDataSituation(data) {
    data.situation = this.getWhiteLabelsStatus(data.situation);
    return data;
  },
  formatResponseDataTransfers(data, type = 'table') {
    const format = (item) => {
      item.status_formatted = this.getWhiteLabelsTransfersStatus(item.status);
      item.value_formatted = AppUtils.formatCurrency(parseFloat(item.value));
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  formatResponseDataBillets(data, type = 'table') {
    const format = (item) => {
      item.due_date = AppUtils.formatDate(item.due_date);
      item.payment_value_formatted = AppUtils.formatCurrency(parseFloat(item.payment_value));
      item.status_formatted = this.getWhiteLabelsBilletsStatus(item.status);
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  formatResponseDataStatements(data, type = 'table') {
    const format = (item) => {
      item.movement_date_formatted = AppUtils.formatDate(item.movement_date);
      item.value_formatted = AppUtils.formatCurrency(parseFloat(item.value)) || 'R$ 0,00';
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });

      const totalRegisters = data.length;

      responseData = { data };
      responseData.total_available_initial = responseData.data[0].value_formatted;
      responseData.total_blocked_initial = responseData.data[1].value_formatted;
      responseData.total_available_final = responseData.data[totalRegisters - 1].value_formatted;
      responseData.total_blocked_final = responseData.data[totalRegisters - 2].value_formatted;
      responseData.data.splice(1, 1);
      responseData.data.splice(0, 1);
      responseData.data.pop();
      responseData.data.pop();
      responseData.data.pop();

      return responseData;
    }
    format(data);
    return data;
  },
  formatResponseDataBalance(data, type = 'table') {
    const format = (item) => {
      item.balance_value_formatted = AppUtils.formatCurrency(parseFloat(item.balance_value)) || 'R$ 0,00';
      item.balance_blocked_formatted = AppUtils.formatCurrency(parseFloat(item.balance_blocked)) || 'R$ 0,00';
      item.balance_schedule_formatted = AppUtils.formatCurrency(parseFloat(item.balance_schedule)) || 'R$ 0,00';
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  getWhiteLabelsStatus(value) {
    if (!value) return '';
    return AppUtils.getOptionText('white_label_status', value);
  },
  getWhiteLabelsTransfersStatus(value) {
    if (!value) return '';
    return AppUtils.getOptionText('white_label_transfers_status', value);
  },
  getWhiteLabelsBilletsStatus(value) {
    if (!value) return '';
    return AppUtils.getOptionText('white_label_billets_status', value);
  },
});
