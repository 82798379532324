<template>
  <v-list expand
          color="#F4F4F4"
          :dense="dense">
    <template v-for="item in items">
      <ListGroup
        v-if="item.items"
        :key="item.title"
        :item="item"
        @click="onItemGroupClick" />

      <ListItem
        v-else
        :key="item.title"
        :item="item"
        @click="onItemClick" />
    </template>
  </v-list>
</template>

<script>
import ListGroup from './ListGroup';
import ListItem from './ListItem';

export default {
  name: 'DefaultList',
  components: {
    ListGroup,
    ListItem,
  },

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onItemClick() {
      this.$emit('hideSubMenu', false);
      this.$emit('click', this.item);
    },
    onItemGroupClick() {
      this.$emit('click', this.item);
    },
  },
};
</script>
