<template>
  <v-list-item :href="item.href"
               :rel="item.href ? 'nofollow' : undefined"
               :target="item.href ? '_blank' : undefined"
               :to="{name: item.to}"
               active-class="indigo lighten-1 white--text"
               :class="child ? 'mb-2 ml-n8' : 'mb-2'"
               @click="onItemClick">
    <v-list-item-avatar v-if="item.avatar">
      <v-img :src="item.avatar" />
    </v-list-item-avatar>

    <v-list-item-icon v-if="item.icon" class="item-icon">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>
    <v-list-item-title v-if="item.title" class="ml-n4 item-title">
      {{ item.title }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'ListItem',

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    child: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onItemClick() {
      this.$emit('click', this.item);
    },
  },
};
</script>
