import { ICON_PRODUCT } from '@constants/icons';

export default {
  service: 'Assignors',
  icon: ICON_PRODUCT,
  routes: {
    list: {
      path: '/anticipation/assignors',
      name: 'anticipation-assignors-list',
    },
    show: {
      path: '/anticipation/assignors/:id',
      name: 'anticipation-assignors-show',
    },
    create: '',
    edit: '',
  },
  titles: {
    item: '',
    items: 'Cedentes Disponíveis',
    edit: '',
    create: '',
    new: '',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'assignors/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'CNPJ',
      name: 'cnpj',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Cedente',
      name: 'name',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'R$ Faturado',
      name: 'billed_amount_formatted',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Disponível para Antecipar',
      name: 'available',
      formattedName: 'available_anticipated_formatted',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: 'Classificação Certus',
      name: 'classification',
      type: 'text',
      list: true,
      noFilter: true,
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
  ],
};
