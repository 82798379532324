import searchCEP from '@plugins/searchCEP';

const CnpjMap = ($axios) => ({
  async getCompanieByCnpj(cnpj) {
    cnpj = cnpj.replace(/[^0-9]/gi, '');
    const { data } = await $axios.get(`cnpj_map/companies/${cnpj}`);
    const address = await searchCEP(data.address.cep);
    let main_address = {};
    if (!data.address) {
      main_address = {
        main: true,
        zip_code: address.cep ?? '',
        street: address.logradouro ?? '',
        number: address.number ?? '',
        complement: address.complemento ?? '',
        neighborhood: address.bairro ?? '',
        city: address.localidade ?? '',
        ibge: address.ibge ?? '',
        uf: address.uf ?? '',
      };
    } else {
      main_address = {
        main: true,
        zip_code: data.address.cep,
        street: data.address.street,
        number: data.address.number,
        complement: data.address.complement,
        neighborhood: data.address.neighborhood,
        city: data.address.city.name,
        ibge: address.ibge,
        uf: data.address.state.uf,
      };
    }
    data.contact = {
      landline: data.contact.ddd_1 || data.contact.ddd_2,
      cellphone: data.contact.phone_1 || data.contact.phone_2,
      email: data.contact.email,
    };
    data.address = main_address;
    return data;
  },
});

export { CnpjMap };
export default { CnpjMap };
