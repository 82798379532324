import Vue from 'vue';

export default {
  screenEvents: {
    beforeSave(formValue) {
      const { length } = formValue.barcode;
      switch (length) {
        case 0:
          return true;
        case 8:
          return true;
        case 12:
          return true;
        case 13:
          return true;
        case 14:
          return true;
        default:
          return 'O codigo de barras deve conter 8, 12, 13 ou 14 digitos';
      }
    },
  },
  fieldEvents: {
    product_type_id: {
      change: async (formValue, fieldValue) => {
        const response = await Vue.prototype.$api.AllTypes.show(formValue.product_type_id);
        if (response.description === 'Serviço') {
          return { fieldAttributes: { disabled: !fieldValue }, fieldNames: ['service_id'] };
        }
        return { fieldAttributes: { disabled: fieldValue }, fieldNames: ['service_id'] };
      },
    },
    barcode: {
      change: async (formValue) => {
        const { length } = formValue.barcode;
        switch (length) {
          case 8:
            return true;
          case 12:
            return true;
          case 13:
            return true;
          case 14:
            return true;
          default:
            return 'O codigo de barras pode conter 0, 8, 12, 13 ou 14 digitos';
        }
      },
    },
  },
};
