import { ICON_TAXES } from '@constants/icons';

import itemTaxFields from './taxes/itemTaxFields';

export default {
  service: 'DevolutionSalesItemTaxes',
  icon: ICON_TAXES,
  routes: {
    list: '',
    edit: '',
    create: '',
  },
  titles: {
    item: 'Conferência de Imposto',
    items: 'Conferência de Impostos',
    new: 'Adicionar Item',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: '',
  fields: itemTaxFields,
};
