import Vue from 'vue';
import { AclInstaller, AclCreate, AclRule } from 'vue-acl';
import router from '../router';

Vue.use(AclInstaller);

const profileLocalStorage = JSON.parse(localStorage.getItem('profile'));
const functionsLocalStorage = JSON.parse(localStorage.getItem('functions'));
const rulesGlobals = {};

rulesGlobals.Admin = new AclRule('Admin').generate();
rulesGlobals.isPublic = new AclRule('public').or('Admin').generate();
rulesGlobals.isLogged = new AclRule('isLogged').or('Admin').generate();
rulesGlobals.isInitialRule = new AclRule('isInitialRule').or('Admin').generate();

if (functionsLocalStorage) {
  functionsLocalStorage.forEach((item) => {
    rulesGlobals[item.name] = new AclRule(item.name).or('Admin').generate();
  });
}

export default new AclCreate({
  initial: 'isInitialRule',
  notfound: {
    path: '/notpermitted',
    forwardQueryParams: true,
  },
  router,
  acceptLocalRules: true,
  globalRules: rulesGlobals,
  middleware: async (acl) => {
    const changes = ['isLogged', 'isInitialRule'];

    if (profileLocalStorage) {
      profileLocalStorage.menus.forEach((item) => {
        changes.push(item.name);
      });
    }

    acl.change(changes);
  },
});
