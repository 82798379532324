<template>
  <div :id="item.id">
    <GenericCardPanel
      title="Informações da Nota Fiscal"
      :fields="invoiceInfoFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabInvoice',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      invoiceInfoFields: [],
      recipientInfoFields: [],
      additionalDataFields: [],
    };
  },
  mounted() {
    this.invoiceInfoFields = [
      { label: 'NF-e', value: this.item.invoice_number, col: 2 },
      { label: 'Chave de acesso', value: this.item.invoice_key, col: 4 },
      { label: 'Modelo', value: this.item.model, col: 2 },
      { label: 'Tipo', value: this.item.document_type_formatted, col: 2 },
      { label: 'Série', value: this.item.series, col: 2 },
      { label: 'Data de Emissão', value: this.item.emission_date_formatted, col: 2 },
      { label: 'Hora da Emissão', value: this.item.emission_date_hour, col: 2 },
      { label: 'Data de Saída', value: this.item.exit_date_formatted, col: 2 },
      { label: 'Hora de Saída', value: this.item.exit_date_hour, col: 2 },
    ];
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style scoped></style>
