import { ICON_CASH_PLUS } from '@constants/icons';

export default {
  service: 'AccountReceivables',
  type: 'receive',
  icon: ICON_CASH_PLUS,
  routes: {
    edit: {
      path: '/financial/accountreceivables/edit/:id',
      name: 'financial-accountreceivables-edit',
    },
    clone: {
      path: '/financial/accountreceivables/:id/clone',
      name: 'financial-accountreceivables-clone',
    },
    list: {
      path: '/financial/accountreceivables',
      name: 'financial-accountreceivables-list',
    },
  },
  titles: {
    item: 'Conta a Receber',
    items: 'Contas a Receber',
  },
  filterUrl: 'cost_centers/filters',
  fields: [
    {
      label: 'Status',
      name: 'status',
      list: true,
    },
    {
      label: 'N° Título',
      name: 'title',
      list: true,
    },
    {
      label: 'Cadastro',
      align: 'start',
      name: 'register.to_s',
      list: true,
    },
    {
      label: 'Data de Emissão',
      name: 'issuance_date',
      list: true,
    },
    {
      label: 'Valor',
      name: 'total_title_value',
      formattedName: 'total_title_value_formatted',
      list: true,
    },
  ],
};
