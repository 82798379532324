export default [
  {
    path: '/auth/login',
    name: 'auth-login',
    meta: {
      rule: 'isInitialRule',
      layout: 'registerbasic',
      guest: true,
    },
    component: () => import(/* webpackChunkName: "login" */'./pages/Login'),
  },
  {
    path: '/auth/redirect',
    name: 'auth-redirect',
    meta: {
      rule: 'isInitialRule',
      layout: 'registerbasic',
      guest: true,
    },
    component: () => import(/* webpackChunkName: "login-redirect" */'./pages/LoginRedirect'),
  },
  {
    path: '/auth/register',
    name: 'auth-register-basic',
    meta: {
      rule: 'isInitialRule',
      guest: true,
      layout: 'registerbasic',
    },
    component: () => import(/* webpackChunkName: "register" */'./pages/Register'),
    props: true,
  },
  {
    path: '/auth/resetpassword/:type/:token',
    name: 'auth-resetpassword',
    meta: {
      rule: 'isInitialRule',
      guest: true,
      layout: 'registerbasic',
    },
    component: () => import(/* webpackChunkName: "reset-password" */'./pages/ResetPassword'),
  },
  {
    path: '/auth/resetpassword/:type',
    name: 'auth-newpassword',
    meta: {
      rule: 'isInitialRule',
      guest: true,
      layout: 'registerbasic',
    },
    component: () => import(/* webpackChunkName: "reset-password" */'./pages/ResetPassword'),
  },
  {
    path: '/auth/logout',
    name: 'auth-logout',
    meta: {
      rule: 'isLogged',
      requiresAuth: true,
      layout: 'registerbasic',
    },
    component: () => import(/* webpackChunkName: "logout" */'./pages/Logout'),
  },
];
