import DevolutionIndustrializationSchema from '@schemas/DevolutionIndustrialization';
import RemittanceIndustrializationSchema from '@schemas/RemittanceIndustrialization';
import TransactionIndustrializationSchema from '@schemas/TransactionIndustrialization';

const ListRemittanceIndustrialization = () => import(/* webpackChunkName: "list-remittance-industrialization" */'./ListRemittanceIndustrialization');
const DetailRemittanceIndustrialization = () => import(/* webpackChunkName: "detail-remittance-industrialization" */'./DetailRemittanceIndustrialization');
const ListDevolutionIndustrialization = () => import(/* webpackChunkName: "list-devolution-industrialization" */'./ListDevolutionIndustrialization');
const DetailDevolutionIndustrialization = () => import(/* webpackChunkName: "detail-devolution-industrialization" */'./DetailDevolutionIndustrialization');
const ListTransactionIndustrialization = () => import(/* webpackChunkName: "list-transaction-industrialization" */'./ListTransactionIndustrialization');

export default [
  {
    path: RemittanceIndustrializationSchema.routes.list.path,
    name: RemittanceIndustrializationSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      // @TODO - change rule when it created on backend
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: RemittanceIndustrializationSchema.titles.items },
      ],
    },
    component: ListRemittanceIndustrialization,
  },
  {
    path: RemittanceIndustrializationSchema.routes.create.path,
    name: RemittanceIndustrializationSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: RemittanceIndustrializationSchema.titles.items, route: RemittanceIndustrializationSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailRemittanceIndustrialization,
  },
  {
    path: RemittanceIndustrializationSchema.routes.edit.path,
    name: RemittanceIndustrializationSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: RemittanceIndustrializationSchema.titles.items, route: RemittanceIndustrializationSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailRemittanceIndustrialization,
  },
  {
    path: DevolutionIndustrializationSchema.routes.list.path,
    name: DevolutionIndustrializationSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      // @TODO - change rule when it created on backend
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionIndustrializationSchema.titles.items },
      ],
    },
    component: ListDevolutionIndustrialization,
  },
  {
    path: DevolutionIndustrializationSchema.routes.create.path,
    name: DevolutionIndustrializationSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionIndustrializationSchema.titles.items, route: DevolutionIndustrializationSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    props: true,
    component: DetailDevolutionIndustrialization,
  },
  {
    path: DevolutionIndustrializationSchema.routes.edit.path,
    name: DevolutionIndustrializationSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: DevolutionIndustrializationSchema.titles.items, route: DevolutionIndustrializationSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailDevolutionIndustrialization,
  },
  {
    path: TransactionIndustrializationSchema.routes.list.path,
    name: TransactionIndustrializationSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      // @TODO - change rule when it created on backend
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: TransactionIndustrializationSchema.titles.items },
      ],
    },
    component: ListTransactionIndustrialization,
  },
];
