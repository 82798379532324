import { ICON_CASH_MINUS } from '@constants/icons';

export default {
  service: 'AccountPayments',
  type: 'payment',
  icon: ICON_CASH_MINUS,
  routes: {
    list: {
      path: '/financial/accountpayments',
      name: 'financial-accountpayments-list',
    },
    clone: {
      path: '/financial/accountpayments/:id/clone',
      name: 'financial-accountpayments-clone',
    },
    edit: {
      path: '/financial/accountpayments/edit/:id',
      name: 'financial-accountpayments-edit',
    },
  },
  titles: {
    item: 'Conta a Pagar',
    items: 'Contas a Pagar',
  },
  filterUrl: 'cost_centers/filters',
  fields: [
    {
      label: 'Status',
      name: 'status',
      list: true,
    },
    {
      label: 'N° Título',
      name: 'title',
      list: true,
    },
    {
      label: 'Cadastro',
      align: 'start',
      name: 'register.to_s',
      list: true,
    },
    {
      label: 'Data de Emissão',
      name: 'issuance_date',
      list: true,
    },
    {
      label: 'Valor',
      name: 'total_title_value',
      formattedName: 'total_title_value_formatted',
      list: true,
    },
  ],
};
