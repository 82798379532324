<template>
  <v-list dense nav>
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-list-item v-shortkey="['ctrl', 'k']"
                     :ripple="false"
                     class="menu-main-buttons"
                     @shortkey="onClick"
                     @click="onClick"
                     v-on="on">
          <v-list-item-action :class="!fatherCallBack && !sonCallBack ? 'ml-4' : ''">
            <v-icon>{{ iconSwitch }}</v-icon>
          </v-list-item-action>
          <v-list-item-title>
            Atalhos
          </v-list-item-title>
        </v-list-item>
      </template>
      <span>(CTRL + K)</span>
    </v-tooltip>
    <QuickSwitchDialog ref="dialog" />
  </v-list>
</template>

<script>
import QuickSwitchDialog from '../QuickSwitchDialog';
import { ICON_SHARE_ALL } from '@constants/icons';

export default {
  name: 'DefaultAccount',
  components: {
    QuickSwitchDialog,
  },
  props: {
    fatherCallBack: Boolean,
    sonCallBack: Boolean,
  },
  data() {
    return {
      iconSwitch: ICON_SHARE_ALL,
    };
  },
  methods: {
    onClick() {
      this.$refs.dialog.open();
    },
  },
};
</script>
<style lang="scss" scoped>
.v-menu__content--fixed {
  bottom:0;
  top:unset !important;
}
.menu-main-buttons {
  transition: all 0.1s ease-out;
}
.menu-main-buttons:hover,
.menu-main-buttons:focus {
  background-color: #5C6BC0 !important;
  border-radius: 30px !important;
  opacity: unset;
  transition: all 0.1s ease-in;
}

.theme--dark.v-list-item:hover:before {
  opacity: 0 !important;
}
.theme--dark.v-list-item--active:before {
  opacity: 0 !important;
}
</style>
