import { ICON_BANK_PLUS } from '@constants/icons';

import TabDasGuides from '@modules/integrations/pages/das/components/TabDasGuides';

export default {
  service: 'DasGuides',
  icon: ICON_BANK_PLUS,
  noCreate: true,
  noEdit: true,
  noDelete: true,
  hideFilters: true,
  useExternalItems: true,
  routes: {
    list: {
      path: 'integrations/das/guides',
      name: 'integrations-das-guides',
    },
  },
  titles: {
    item: 'Meu imposto Mei',
    items: 'Meus impostos Mei',
    list: 'Listar Guias',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'Status',
      name: 'status',
      list: true,
      md: 3,
    },
    {
      label: 'Periodo',
      name: 'period',
      list: true,
      md: 2,
    },
    {
      label: 'Vencimento',
      name: 'due_date',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
    },
    {
      label: 'Data Pagamento',
      name: 'paid_at',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
      md: 2,
    },
    {
      label: 'Valor',
      name: 'total',
      formattedName: 'total_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 2,
    },
    {
      label: 'FINES',
      name: 'fines',
      formattedName: 'fines_formatted',
      type: 'text',
      component: 'dynamic-text-field',
      tabGroup: 'details',
      md: 2,
    },
    {
      label: 'Taxas',
      name: 'fees',
      formattedName: 'fees_formatted',
      type: 'text',
      component: 'dynamic-text-field',
      tabGroup: 'details',
      md: 2,
    },
    {
      label: 'ICMS',
      name: 'icms',
      formattedName: 'icms_formatted',
      type: 'text',
      component: 'dynamic-text-field',
      tabGroup: 'details',
      md: 2,
    },
    {
      label: 'ISS',
      name: 'iss',
      formattedName: 'iss_formatted',
      type: 'text',
      component: 'dynamic-text-field',
      tabGroup: 'details',
      md: 2,
    },
    {
      label: 'INSS',
      name: 'inss',
      formattedName: 'inss_formatted',
      type: 'text',
      component: 'dynamic-text-field',
      tabGroup: 'details',
      md: 2,
    },
    {
      label: 'Valor',
      name: 'total',
      formattedName: 'total_formatted',
      type: 'text',
      component: 'dynamic-text-field',
      tabGroup: 'details',
      md: 2,
    },
  ],
  tabs: [
    {
      icon: ICON_BANK_PLUS,
      title: 'Detalhes',
      tabContent: TabDasGuides,
    },
  ],
};
