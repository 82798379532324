<template>
  <div>
    <v-file-input
      v-if="!readOnly && !field.readOnly"
      :value="selectedFiles"
      :required="checkRequired()"
      :prefix="field.prefix"
      :suffix="field.suffix"
      :hint="field.hint"
      :persistent-hint="true"
      :disabled="checkDisabled()"
      :rules="validationRules"
      :label="field.label"
      :class="field.class"
      :accept="field.accept"
      :clearable="field.clearable"
      :clear-icon="icons.close"
      :autofocus="field.autofocus"
      :show-size="field.showSize"
      :counter="field.counter"
      :rounded="field.rounded"
      :filled="field.filled"
      :prepend-icon="innerIcon ? '' : prependIcon"
      :prepend-inner-icon="innerIcon ? prependIcon : ''"
      v-bind="$attrs"
      v-on="$listeners"
      @change="onFileSelectChange" />
    <div v-else class="dm-information">
      <label>{{ field.label }}</label>
      <p>{{ fieldValue }}</p>
    </div>
  </div>
</template>

<script>
import { ICON_ATTACHMENT, ICON_CLOSE } from '@constants/icons';
import DynamicFieldMixin from './DynamicFieldMixin';

export default {
  name: 'DynamicFileInput',

  mixins: [DynamicFieldMixin],

  data() {
    return {
      selectedFiles: null,
      fieldValue: {},
      icons: {
        default: ICON_ATTACHMENT,
        close: ICON_CLOSE,
      },
    };
  },

  computed: {
    maxLength() {
      if (this.field.maxLength && String(this.field.maxLength) !== '0') {
        return parseInt(this.field.maxLength);
      }

      return undefined;
    },
    prependIcon() {
      return this.field.prependIcon === undefined ? this.icons.default : this.field.prependIcon;
    },
    appendIcon() {
      return this.field.appendIcon;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
  },
  methods: {
    onFileSelectChange(file) {
      this.setFieldValue(file);
    },
  },
};
</script>

<style lang="scss"></style>
