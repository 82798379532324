import {
  ICON_DESCRIPTION,
  ICON_MEASURING_UNIT,
  ICON_TAXES,
  ICON_TRUCK,
  ICON_SHOPPING,
} from '@constants/icons';

import { OPTIONS_PRESENCE_INDICATOR, OPTIONS_FREIGHT, OPTIONS_ORDERSPURCHASESALE_STATUS } from '@constants/options';

import OrderSaleBusiness from '@businesses/OrderSale'; // Use same business rules
import OrderSaleItemSchema from './OrderSaleItem';
import OrderSaleItemTaxSchema from './OrderSaleItemTax';
import OrderSaleTransporterSchema from './OrderSaleTransporter';

import TabOrderSale from '@modules/supply/pages/orderSales/components/TabOrderSale';
import TabOrderSaleItens from '@modules/supply/pages/orderSales/components/TabOrderSaleItens';
import TabOrderSaleItensTax from '@modules/supply/pages/orderSales/components/TabOrderSaleItensTax';
import TabOrderSaleTransporter from '@modules/supply/pages/orderSales/components/TabOrderSaleTransporter';
import TabOrderSaleDevolutions from '@modules/supply/pages/orderSales/components/TabOrderSaleDevolutions';

import ObservationSchema from './Observation';
import PriceListSchema from './PriceList';
import DevolutionSalesSchema from './DevolutionSales';

export default {
  service: 'OrderSales',
  formName: 'ordersales',
  business: OrderSaleBusiness,
  icon: ICON_SHOPPING,
  routes: {
    list: { path: '/supply/order-sales', name: 'supply-ordersales-list' },
    create: { path: '/supply/order-sales/create', name: 'supply-ordersales-create' },
    edit: { path: '/supply/order-sales/edit/:id', name: 'supply-ordersales-edit' },
  },
  titles: {
    item: 'Pedido de Vendas',
    items: 'Pedidos de Vendas',
    edit: 'Editar Pedido de Vendas',
    create: 'Criar Pedido de Vendas',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Ordem de vendas excluida com sucesso!',
    successEdit: 'Ordem de vendas alterada com sucesso!',
    successCreate: 'Ordem de vendas criada com sucesso!',
    errorSave: 'Erro ao salvar ordem de vendas!',
    tryEditCanceled: 'Não é possível editar uma ordem de vendas cancelada.',
    confirmCancel: 'Deseja cancelar a ordem de vendas?',
    successCancel: 'Ordem de vendas cancelada com sucesso',
    errorCancel: 'Erro ao cancelar ordem de vendas',
  },
  filterUrl: 'order_sales/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  canClone: true,
  statusSettings: {
    options: OPTIONS_ORDERSPURCHASESALE_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
    statusDetails: true,
  },
  fields: [
    {
      label: 'INFORMAÇÕES DA VENDA',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
      tabGroup: 'infoSales',
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options: 'orders_purchase_sale_status',
      list: true,
    },
    {
      label: 'Cliente',
      name: 'buyer_id',
      formattedName: 'buyer.to_s',
      type: 'number',
      hint: '',
      list: true,
      required: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [
          {
            use_inactive_eq: false,
            type_register_array: 'client',
            query: ['id', 'name', 'cnpj_cpf', 'addresses', ['id', 'to_s'], 'preference', ['salesman_id', 'shipping_company_id']],
          },
        ],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      withDetails: true,
      typeDetails: 'client',
      md: 8,
      tabGroup: 'infoSales',
    },
    {
      label: 'Pedido',
      name: 'sale_order',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 2,
      tabGroup: 'infoSales',
    },
    {
      label: 'Devolução',
      formattedName: 'has_devolution_formatted',
      md: 2,
      tabGroup: 'infoSales',
    },
    {
      label: 'NFe',
      name: 'note',
      formattedName: 'note_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      readOnly: true,
      md: 2,
      tabGroup: 'infoSales',
    },
    {
      label: 'Vendedor',
      name: 'salesman_id',
      formattedName: 'salesman.to_s',
      type: 'number',
      list: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_register_array: 'salesman',
          query: ['id', 'name'],
        }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      md: 8,
      tabGroup: 'infoSales',
    },
    {
      label: 'Data Previsão',
      name: 'forecast_date',
      formattedName: 'forecast_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      tabGroup: 'infoSales',
    },
    {
      label: 'Data Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      list: true,
      tabGroup: 'infoSales',
    },
    {
      label: 'Consumidor Final',
      name: 'is_costumer',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      tabGroup: 'infoSales',
    },
    {
      label: 'Indicador de Presença',
      name: 'presence_indicator',
      formattedName: 'presence_indicator_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_PRESENCE_INDICATOR,
      required: true,
      md: 5,
      tabGroup: 'infoSales',
    },
    {
      label: 'Nº da Ordem',
      name: 'order',
      type: 'text',
      component: 'dynamic-text-field',
      md: 2,
      tabGroup: 'infoSales',
    },
    {
      label: 'Data Entrega',
      name: 'delivery_date',
      formatteName: 'delivery_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      md: 2,
      tabGroup: 'infoSales',
    },
    {
      label: 'Forma de Pagamento',
      name: 'payment_method_id',
      formattedName: 'payment_method.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-paymentmethod-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['payment_method'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      tabGroup: 'infoSales',
    },
    {
      label: 'Parcelamento',
      name: 'type_installment_id',
      formattedName: 'type_installment.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-typeinstallment-create',
      dataSource: {
        service: 'TypeInstallment',
        method: 'getByFilter',
        props: [{ inactive_eq: false }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      tabGroup: 'infoSales',
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      formattedName: 'stock.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 3,
      tabGroup: 'infoSales',
    },
    {
      label: 'Lista de Preço',
      name: 'price_list_id',
      formattedName: 'price_list.to_s',
      type: 'number',
      component: 'dynamic-select',
      clearable: true,
      dataSource: {
        service: 'PriceList',
        method: 'getByFilter',
        props: [{
          is_inactive_eq: false,
        }, 'name asc'],
      },
      itemText: 'name',
      itemValue: 'id',
      md: 3,
      redirectCreateRoute: PriceListSchema.routes.create.name,
      tabGroup: 'infoSales',
    },
    {
      label: 'Total de Impostos Aproximados',
      formattedName: 'total_tax_aproximates',
      name: 'total_tax_aproximates',
      type: 'number',
      noFilter: true,
    },
    {
      label: 'Valor Total',
      formattedName: 'total_ordered_formatted',
      type: 'number',
      list: true,
      tabGroup: 'infoSales',
    },
    {
      label: 'INFORMAÇÕES DE FRETE',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'freight',
    },
    {
      label: 'Frete',
      name: 'freight',
      formattedName: 'freight_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_FREIGHT,
      required: true,
      md: 3,
      tabGroup: 'freight',
    },
    {
      label: 'Valor do Frete',
      name: 'freight_value',
      formattedName: 'freight_value_formatted',
      type: 'number',
      preference: ['General', 'number_precision_money'],
      component: 'dynamic-number-field',
      md: 3,
      tabGroup: 'freight',
      noFilter: true,
    },
    {
      label: 'Despesas',
      name: 'expense_value',
      formattedName: 'expense_value_formatted',
      type: 'number',
      preference: ['General', 'number_precision_money'],
      component: 'dynamic-number-field',
      md: 3,
      tabGroup: 'freight',
      noFilter: true,
    },
    {
      label: 'Incluir Frete no Total',
      name: 'use_freight',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      tabGroup: 'freight',
    },
    {
      label: 'TRIBUTAÇÕES DO PEDIDO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      formattedName: 'nature_operation.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_nature_in: ['sale', 'ecommerce', 'nfce'],
          query: ['id', 'name'],
        }],
      },
      itemText: 'name',
      itemValue: 'id',
      list: true,
      required: true,
      md: 12,
    },
  ],
  childSchemas: [
    {
      label: 'Itens do Pedido de Vendas',
      name: 'items',
      schema: OrderSaleItemSchema,
    },
    {
      label: 'Conferência de Impostos',
      name: 'taxes',
      noCreate: true,
      noEdit: true,
      noDelete: true,
      noActions: true,
      schema: OrderSaleItemTaxSchema,
      hideDefaultHeader: true,
      customHeader: {
        customClass: 'custom-child-header-order-sale',
        ungroupedHeadersFront: 2,
        groupHeaders: [
          { text: 'SIMPLES NACIONAL', colspan: 3, class: 'order-sale-header' },
          { text: 'ICMS', colspan: 3, class: 'order-sale-header' },
          { text: 'ICMS ST', colspan: 3, class: 'order-sale-header' },
          { text: 'IPI', colspan: 3, class: 'order-sale-header' },
          { text: 'PIS', colspan: 3, class: 'order-sale-header' },
          { text: 'COFINS', colspan: 3, class: 'order-sale-header' },
          { text: 'ICMS CONSUMIDOR FINAL', colspan: 3, class: 'order-sale-header' },
          { text: 'VALOR APROXIMADOS', colspan: 3, class: 'order-sale-header' },
        ],
      },
    },
    {
      label: 'Transportadora',
      name: 'transporter',
      schema: OrderSaleTransporterSchema,
      hasOne: true,
    },
    {
      label: 'Observações',
      name: 'observations',
      schema: ObservationSchema,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabOrderSale,
    },
    {
      icon: ICON_MEASURING_UNIT,
      title: 'Itens do Pedido de Vendas',
      tabContent: TabOrderSaleItens,
    },
    {
      icon: ICON_TAXES,
      title: 'Conferência de Impostos',
      tabContent: TabOrderSaleItensTax,
    },
    {
      icon: ICON_TRUCK,
      title: 'Transportadora',
      tabContent: TabOrderSaleTransporter,
    },
    {
      icon: DevolutionSalesSchema.icon,
      title: 'Devoluções',
      tabContent: TabOrderSaleDevolutions,
    },
  ],
};
