import config from '@src/core/config';
import {
  formatCurrency,
  formatNumber,
  convertMinutesToHourString,
  convertHourStringToMinutes,
} from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';
import { validateHour } from '@businesses/ProductCompositionFactory';

const Products = DynamicService('products', {
  formatResponse: (item) => {
    item.id = parseInt(item.id);
    item.use_inactive_formatted = item.use_inactive ? 'Sim' : 'Não';
    item.use_nickname_formatted = item.use_nickname ? 'Sim' : 'Não';
    item.use_integration_marketplace_formatted = item.use_integration_marketplace ? 'Sim' : 'Não';
    item.purchase_cost_formatted = formatCurrency(item.purchase_cost);
    item.sale_value_formatted = formatCurrency(item.sale_value);
  },
  formatRequest: (item) => {
    if (!item.unity_id) {
      delete item.unity_id;
    }
  },
});

const Cests = DynamicService('cests');
const Ncms = DynamicService('ncms', { readonly: true });

const convertItemMinutesToHour = (value, formatted) => {
  if (formatted) value = value ?? '00:00';
  const result = validateHour(value) ? value : convertMinutesToHourString(value);
  return result;
};

const convertItemHourToMinutes = (value = 0) => {
  const result = validateHour(value) ? convertHourStringToMinutes(value) : value;
  return result;
};

const formatResponse = (order, recalcCostTotal = false) => {
  order.stock_id = order.stock_id || (order.stock && order.stock.id) || null;
  order.cost_feedstock_formatted = formatCurrency(order.cost_feedstock || 0);
  order.cost_composition_formatted = formatCurrency(order.cost_composition || 0);
  order.cost_procedures_formatted = formatCurrency(order.cost_procedures || 0);
  order.cost_processes_formatted = formatCurrency(order.cost_processes || 0);
  order.cost_supplies_formatted = formatCurrency(order.cost_supplies || 0);
  order.compositions = order.compositions || [];

  if (order?.compositions.length > 0) {
    let totalFeedstockComposition = 0;
    const compositionItems = order.compositions.map((item) => {
      const returnedItem = {
        ...item,
        product_id: item.product.id,
        unity_id: (item.unity && item.unity.id) || '',
        quantity_formatted: formatNumber(item.quantity),
        product_purchase_cost_formatted: formatCurrency(item.product_purchase_cost || 0),
        purchase_cost_formatted: formatCurrency(item.purchase_cost || 0),
        total_purchase_cost_formatted: formatCurrency(item.total_purchase_cost || 0),
        sale_value_formatted: formatCurrency(item.sale_value || 0),
        total_sale_value_formatted: formatCurrency(item.total_sale_value || 0),
        is_fixed_formatted: item.is_fixed ? 'Sim' : 'Não',
      };
      totalFeedstockComposition += item.total_purchase_cost;
      return returnedItem;
    });
    order.cost_feedstock = totalFeedstockComposition;
    order.cost_feedstock_formatted = formatCurrency(totalFeedstockComposition);

    order.compositions = compositionItems || [];
  }

  order.procedures = order.procedures || [];

  if (order?.procedures.length > 0) {
    let totalTimeProcedures = 0;
    let totalProcedureCost = 0;
    const proceduresItems = order.procedures.map((procedure, index) => {
      const returnedItem = {
        ...procedure,
        step: procedure.step ?? index + 1,
        product_service_id: procedure.product_service.id,
        time_process: convertItemMinutesToHour(procedure.time_process),
        time_process_formatted: convertItemMinutesToHour(procedure.time_process, true),
        time_machine: convertItemMinutesToHour(procedure.time_machine),
        time_machine_formatted: convertItemMinutesToHour(procedure.time_machine, true),
        time_setup: convertItemMinutesToHour(procedure.time_setup),
        time_setup_formatted: convertItemMinutesToHour(procedure.time_setup, true),
        time_total: convertItemMinutesToHour(procedure.time_total),
        time_total_formatted: convertItemMinutesToHour(procedure.time_total, true),

        unit_cost_formatted: formatCurrency(procedure?.unit_cost || 0),
        total_cost_formatted: formatCurrency(procedure?.total_cost || 0),
      };
      totalTimeProcedures += convertItemHourToMinutes(procedure.time_total);
      totalProcedureCost += procedure.total_cost ?? 0;
      return returnedItem;
    });
    order.total_time_procedures = convertMinutesToHourString(totalTimeProcedures);
    order.cost_procedures = totalProcedureCost;
    order.cost_procedures_formatted = formatCurrency(totalProcedureCost);

    order.procedures = proceduresItems || [];
  }
  if (recalcCostTotal) {
    const items_total_cost = order.compositions.reduce((a, composition) => {
      a += composition.quantity * composition.purchase_cost;
      return a;
    }, 0);
    order.cost_total = items_total_cost + order.cost_procedures;
  }
  order.cost_total_formatted = formatCurrency(order.cost_total || 0);

  return order;
};

const formatRequest = (composition) => {
  if (composition?.compositions.length > 0) {
    composition.compositions.forEach((item) => {
      item.product_id = item.product.id;
      delete item._ulid;
    });
  }
  if (composition?.procedures.length > 0) {
    composition.procedures.forEach((procedure) => {
      procedure.time_process = convertItemHourToMinutes(procedure.time_process);
      procedure.time_machine = convertItemHourToMinutes(procedure.time_machine);
      procedure.time_setup = convertItemHourToMinutes(procedure.time_setup);
      procedure.time_total = convertItemHourToMinutes(procedure.time_total);
    });

    delete composition._ulid;
    delete composition.stock_id;
  }
};

const Compositions = DynamicService('composition', {
  formatResponse,
  formatRequest,
  baseURL: config.API_GATEWAY_PCP,
});

const ProductCost = DynamicService('cost', {
  formatResponse,
  formatRequest,
  baseURL: config.API_GATEWAY_PCP,
});

const ProductCostCommands = ($axios) => ({
  async updateProductCosts(payload) {
    const res = await $axios.put(`${config.API_GATEWAY_PCP}/cost/product/${payload.id}.json`, payload.data);
    return res;
  },
  async updateCompositionsCosts(payload) {
    const res = await $axios.put(`${config.API_GATEWAY_PCP}/cost/composition/${payload.id}.json`, payload.data);
    return res;
  },
});

const ProductCommands = ($axios) => ({
  async createMultiple(payload) {
    const res = await $axios.post('invoices/mirror/create_product', payload.data);
    return res;
  },
  async uploadProductsFile(payload) {
    const res = await $axios.post('products/read_xlsx.json', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  },
  async importProducts(payload) {
    const res = await $axios.post('products/import.json', { products: payload });
    return res;
  },
});

const productLinkEndpoint = 'products_links';

const ProductLinkCommands = ($axios) => ({
  async index(payload) {
    const res = $axios.post(`${productLinkEndpoint}/index`, payload);
    return res;
  },
  async show(payload) {
    const res = $axios.post(`${productLinkEndpoint}/show`, payload);
    return res;
  },
  async create(payload) {
    const res = $axios.post(`${productLinkEndpoint}/create`, payload);
    return res;
  },
  async update(payload) {
    const res = $axios.put(`${productLinkEndpoint}`, payload);
    return res;
  },
  async delete(payload) {
    const res = $axios.delete(`${productLinkEndpoint}/${payload.id}`);
    return res;
  },
});

const CompositionFactory = DynamicService('composition/procedure.json', {
  formatResponse,
  formatRequest,
  baseURL: config.API_GATEWAY_PCP,
});

export {
  Products,
  Cests,
  Ncms,
  Compositions,
  ProductCost,
  ProductCostCommands,
  ProductCommands,
  ProductLinkCommands,
  CompositionFactory,
};
export default {
  Products,
  Cests,
  Ncms,
  Compositions,
  ProductCost,
  ProductCostCommands,
  ProductCommands,
  ProductLinkCommands,
  CompositionFactory,
};
