<template>
  <div v-if="$vuetify.breakpoint.mdAndUp" class="breadcrumb mt-3 pb-4 pl-0">
    <v-breadcrumbs class="pl-0 ml-6">
      <v-breadcrumbs-item v-for="(breadcrumb, idx) in breadcrumbList" :key="idx" @click="routeTo(idx)">
        <v-breadcrumbs-divider v-if="idx !== 0" class="mr-2">
          <v-icon>{{ icons.breadcrumb }}</v-icon>
        </v-breadcrumbs-divider>
        <v-breadcrumbs-divider v-else class="mr-2">
          <router-link to="/">
            <v-icon>{{ icons.dashboard }}</v-icon>
          </router-link>
        </v-breadcrumbs-divider>
        <router-link v-if="breadcrumb.link" :to="{ path: breadcrumb.link }" style="text-decoration: none">
          {{ breadcrumb.name }}
        </router-link>
        <router-link v-else-if="breadcrumb.route" :to="{ name: breadcrumb.route }" style="text-decoration: none">
          {{ breadcrumb.name }}
        </router-link>
        <span v-else>{{ breadcrumb.name }}</span>
      </v-breadcrumbs-item>
    </v-breadcrumbs>
  </div>
</template>

<script>
import { ICON_BREADCRUMB, ICON_DASHBOARD } from '@constants/icons';

export default {
  data: () => ({
    breadcrumbList: [],
    icons: {
      breadcrumb: ICON_BREADCRUMB,
      dashboard: ICON_DASHBOARD,
    },
  }),
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    routeTo(route) {
      if (this.breadcrumbList[route].link) this.$router.push(this.breadcrumbList[route].link);
    },
    update() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  padding: 10px 0px 0px 0px !important;
}
</style>
