import {
  DevolutionPurchases,
  DevolutionPurchasesItems,
} from './pages/devolutionPurchases/services';
import {
  DevolutionSales,
  DevolutionSalesItems,
  DevolutionSalesTaxes,
} from './pages/devolutionSales/services';
import {
  Invoices,
  InvoicesCommands,
  InvoicesPickupDelivery,
} from './pages/invoices/services';
import {
  InvoicesISS,
  InvoiceISSCommands,
} from './pages/invoicesISS/services';

import {
  Manifests,
  ManifestsCommands,
} from './pages/manifests/services';

import {
  OrderBudgets,
  OrderBudgetsItems,
  OrderBudgetsTaxes,
} from './pages/orderbudgets/services';

import {
  OrderPurchases,
  OrderPurchasesCommands,
  OrderPurchasesItems,
  OrderPurchasesTaxes,
} from './pages/orderPurchases/services.js';

import {
  OrderSales,
  OrderSalesCommands,
  OrderSalesItems,
  OrderSalesTaxes,
} from './pages/orderSales/services.js';

import {
  OrderServices,
  OrderServicesItems,
  OrderServicesISS,
  OrderServicesCommands,
} from './pages/orderServices/services.js';

import {
  Cests,
  Compositions,
  Ncms,
  Products,
  ProductCommands,
  ProductLinkCommands,
  ProductCost,
  ProductCostCommands,
  CompositionFactory,
} from './pages/products/services';

import {
  PartialPurchases,
  PartialPurchasesCommands,
  PartialPurchasesItems,
  PartialPurchasesTaxes,
} from './pages/partialPurchases/services';

import {
  PartialSales,
  PartialSalesItems,
  PartialSalesCommands,
  PartialSalesTaxes,
} from './pages/partialSales/services';

import {
  DevolutionConsignments,
  DevolutionConsignmentsTaxes,
  RemittanceConsignments,
  RemittanceConsignmentsItems,
  RemittanceConsignmentsCommands,
  RemittanceConsignmentsTaxes,
  StockConsignments,
  TransactionsConsignments,
} from './pages/consignments/services';

import {
  RemittanceIndustrialization,
  RemittanceIndustrializationItems,
  RemittanceIndustrializationCommands,
  RemittanceIndustrializationTaxes,
  StockIndustrialization,
  DevolutionIndustrialization,
  DevolutionIndustrializationTaxes,
  TransactionsIndustrializations,
} from './pages/industrialization/services';

import {
  Stocks,
  StocksSimplified,
  StockHistories,
  StockItems,
  StockItemsSimplified,
} from './pages/stocks/service';

export { default as routes } from './routes';

export const globalServices = {
  Cests,
  Compositions,
  CompositionFactory,
  DevolutionConsignments,
  DevolutionConsignmentsTaxes,
  DevolutionIndustrialization,
  DevolutionIndustrializationTaxes,
  DevolutionPurchases,
  DevolutionPurchasesItems,
  DevolutionSales,
  DevolutionSalesItems,
  DevolutionSalesTaxes,
  Invoices,
  InvoicesCommands,
  InvoicesPickupDelivery,
  InvoicesISS,
  InvoiceISSCommands,
  Manifests,
  ManifestsCommands,
  Ncms,
  OrderBudgets,
  OrderBudgetsItems,
  OrderBudgetsTaxes,
  OrderPurchases,
  OrderPurchasesCommands,
  OrderPurchasesItems,
  OrderPurchasesTaxes,
  OrderSales,
  OrderSalesItems,
  OrderSalesCommands,
  OrderSalesTaxes,
  OrderServices,
  OrderServicesItems,
  OrderServicesISS,
  OrderServicesCommands,
  PartialPurchases,
  PartialPurchasesCommands,
  PartialPurchasesItems,
  PartialPurchasesTaxes,
  PartialSales,
  PartialSalesCommands,
  PartialSalesItems,
  PartialSalesTaxes,
  Products,
  ProductCommands,
  ProductLinkCommands,
  ProductCost,
  ProductCostCommands,
  RemittanceConsignments,
  RemittanceConsignmentsItems,
  RemittanceConsignmentsCommands,
  RemittanceConsignmentsTaxes,
  RemittanceIndustrialization,
  RemittanceIndustrializationItems,
  RemittanceIndustrializationCommands,
  RemittanceIndustrializationTaxes,
  Stocks,
  StocksSimplified,
  StockConsignments,
  StockHistories,
  StockIndustrialization,
  StockItems,
  StockItemsSimplified,
  TransactionsConsignments,
  TransactionsIndustrializations,
};
