import { ICON_PRODUCT } from '@constants/icons';
import { OPTIONS_UF, OPTIONS_FREIGHT, OPTIONS_ORDERS_STATUS } from '@constants/options';

export default {
  service: 'OrderPurchases',
  icon: ICON_PRODUCT,
  route: {
    path: '/reports/purchases/general',
    name: 'reports-purchases-general',
  },
  title: 'Relatórios de Compras',
  filtersLayout: [
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'v-col',
          cols: '8',
          noGutters: true,
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [
                {
                  label: 'Fornecedor',
                  name: 'vendor_id',
                  type: 'number',
                  hint: '',
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'Registers',
                    method: 'getByFilter',
                    props: [{ type_register_array: 'client' }, 'name asc'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 12,
                  clearable: true,
                },
                {
                  label: 'Colaborador',
                  name: 'collaborator_id',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'Registers',
                    method: 'getByFilter',
                    props: [{ type_register_array: 'salesman' }, 'name asc'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 12,
                  clearable: true,
                },
                {
                  label: 'Transportadora',
                  name: 'shipping_company_id',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'Registers',
                    method: 'getByFilter',
                    props: [{ type_register_array: 'shipping_company' }, 'name asc'],
                  },
                  itemText: 'name',
                  itemValue: 'id',
                  md: 12,
                  clearable: true,
                },
              ],
            },
          ],
        },
        {
          component: 'v-col',
          cols: '4',
          noGutters: true,
          containerClass: 'ml-2',
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [{
                label: 'Datas',
                name: 'dynamic-dates',
                fields: [{
                  name: 'emission_date',
                  label: 'Data de Emissão',
                },
                {
                  name: 'forecast_date',
                  label: 'Data de Previsão',
                },
                {
                  name: 'delivery_date',
                  label: 'Data de Entrega',
                }],
                component: 'dynamic-multi-date-filter',
                md: 12,
                clearable: true,
              }],
            }],
        },
        {
          component: 'v-col',
          cols: '12',
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [
                {
                  label: 'Produto',
                  name: 'product_id',
                  formattedName: 'product.to_s',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'Products',
                    method: 'getByFilter',
                    props: [{ use_inactive_eq: false }, 'name asc'],
                  },
                  itemText: 'name',
                  itemValue: 'id',
                  md: 8,
                  clearable: true,
                },
                {
                  label: 'Código',
                  name: 'items.product.code',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-text-field',
                  condition: '_matches',
                  notCondition: '_does_not_match',
                  md: 4,
                },
                {
                  label: 'FILTROS DO FORNECEDOR',
                  component: 'dynamic-title',
                  md: 2,
                  size: 'xl',
                  propTag: 'h6',
                },
                {
                  component: 'dynamic-divider',
                  md: 10,
                },
                {
                  label: 'Bairro',
                  name: 'vendor.addresses.neighborhood',
                  type: 'text',
                  component: 'dynamic-text-field',
                  hideNot: true,
                  condition: '_matches',
                  notCondition: '_does_not_match',
                  md: 4,
                },
                {
                  label: 'Cidade',
                  name: 'vendor.addresses.city',
                  type: 'text',
                  hideNot: true,
                  component: 'dynamic-text-field',
                  condition: '_matches',
                  notCondition: '_does_not_match',
                  md: 4,
                },
                {
                  label: 'UF',
                  name: 'vendor.addresses.uf',
                  type: 'text',
                  hideNot: true,
                  component: 'dynamic-select',
                  multiple: true,
                  clearable: true,
                  items: OPTIONS_UF,
                  md: 4,
                },
                {
                  label: 'Avaliação',
                  name: 'vendor.evaluation_id',
                  type: 'text',
                  hideNot: true,
                  component: 'dynamic-select',
                  multiple: true,
                  clearable: true,
                  dataSource: {
                    service: 'AllTypesCommands',
                    method: 'getManyByTypeExcludeDisables',
                    props: ['evaluation'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'Tipo',
                  name: 'vendor.type_id',
                  type: 'text',
                  hideNot: true,
                  component: 'dynamic-select',
                  multiple: true,
                  clearable: true,
                  dataSource: {
                    service: 'AllTypesCommands',
                    method: 'getManyByTypeExcludeDisables',
                    props: ['type'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'Atividade / Ocupação',
                  name: 'vendor.occupation_id',
                  type: 'text',
                  hideNot: true,
                  component: 'dynamic-select',
                  multiple: true,
                  clearable: true,
                  dataSource: {
                    service: 'AllTypesCommands',
                    method: 'getManyByTypeExcludeDisables',
                    props: ['occupation'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'FILTROS DA ORDEM DE COMPRAS',
                  component: 'dynamic-title',
                  md: 3,
                  size: 'xl',
                  propTag: 'h6',
                },
                {
                  component: 'dynamic-divider',
                  md: 12,
                },
                {
                  label: 'Parcelamento',
                  name: 'type_installment_id',
                  formattedName: 'type_installment.to_s',
                  type: 'number',
                  multiple: true,
                  clearable: true,
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  redirectCreateRoute: 'tables-typeinstallment-create',
                  dataSource: {
                    service: 'TypeInstallment',
                    method: 'getByFilter',
                    props: [{ inactive_eq: false }],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'Estoque',
                  name: 'stock_id',
                  formattedName: 'stock.to_s',
                  type: 'number',
                  multiple: true,
                  clearable: true,
                  hideNot: true,
                  component: 'dynamic-select',
                  dataSource: {
                    service: 'Stocks',
                    method: 'getByFilter',
                    props: [{
                      use_inactive_eq: false,
                      use_main_eq: true,
                    }],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'Frete',
                  name: 'freight',
                  formattedName: 'freight_formatted',
                  type: 'string',
                  multiple: true,
                  clearable: true,
                  hideNot: true,
                  component: 'dynamic-select',
                  items: OPTIONS_FREIGHT,
                  md: 4,
                },
                {
                  label: 'Forma de Pagamento',
                  name: 'payment_method_id',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-select',
                  redirectCreateRoute: 'tables-paymentmethod-create',
                  multiple: true,
                  clearable: true,
                  dataSource: {
                    service: 'AllTypesCommands',
                    method: 'getManyByTypeExcludeDisables',
                    props: ['payment_method'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'Natureza da Operação',
                  name: 'nature_operation_id',
                  formattedName: 'nature_operation.to_s',
                  type: 'number',
                  multiple: true,
                  clearable: true,
                  hideNot: true,
                  component: 'dynamic-select',
                  dataSource: {
                    service: 'NatureOperations',
                    method: 'getByFilterGraphql',
                    props: [{
                      use_inactive_eq: false,
                      type_nature_eq: 'purchase',
                      query: ['id', 'name'],
                    }],
                  },
                  itemText: 'name',
                  itemValue: 'id',
                  md: 4,
                },
                {
                  label: 'Status',
                  name: 'status',
                  type: 'string',
                  multiple: true,
                  clearable: true,
                  hideNot: true,
                  component: 'dynamic-select',
                  items: OPTIONS_ORDERS_STATUS,
                  md: 4,
                }],
            },
          ],
        },

      ],
    },
  ],
};
