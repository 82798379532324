import DynamicService from '@sharedServices/dynamic/DynamicService';

const Users = DynamicService('users');

const UsersCommands = ($axios) => ({
  async uploadAvatarFile(payload) {
    const formData = new FormData();
    formData.append('avatar', payload.avatar);
    const res = await $axios.post(`/api/v1/users/${payload.user_id}.json`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  },
  async updateUserInformations(payload) {
    const res = await $axios.put(`/users/${payload.id}.json`, {
      name: payload.name,
      nickname: payload.nickname,
    });
    return res;
  },
});

export { Users, UsersCommands };
