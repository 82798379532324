import OrderPurchaseSchema from '@schemas/OrderPurchase';

const ListOrderPurchases = () => import(/* webpackChunkName: "order-purchases-list" */'./ListOrderPurchases');
const DetailOrderPurchase = () => import(/* webpackChunkName: "order-purchases-detail" */'./DetailOrderPurchase');

export default [
  {
    path: OrderPurchaseSchema.routes.list.path,
    name: OrderPurchaseSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: OrderPurchaseSchema.titles.items },
      ],
    },
    component: ListOrderPurchases,
  },
  {
    path: OrderPurchaseSchema.routes.create.path,
    name: OrderPurchaseSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: OrderPurchaseSchema.titles.items, route: OrderPurchaseSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailOrderPurchase,
  },
  {
    path: OrderPurchaseSchema.routes.edit.path,
    name: OrderPurchaseSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'order_purchases',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: OrderPurchaseSchema.titles.items, route: OrderPurchaseSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailOrderPurchase,
  },
];
