import vuetify from '@plugins/vuetify';
import ConfirmTemplate from './ConfirmTemplate';
import SaveOrDiscardChangesDialog from './SaveOrDiscardChangesDialog';

export default {
  install(Vue) {
    const ConfirmConstructor = Vue.extend(ConfirmTemplate);
    let confirmInstance = null;
    Vue.prototype.$confirm = (config) => new Promise((resolve, reject) => {
      if (confirmInstance) return;
      confirmInstance = new ConfirmConstructor({
        vuetify,
        propsData: {
          btnsWidth: config.btnsWidth,
          width: config.width,
          title: config.title,
          titleIcon: config.titleIcon,
          message: config.message,
          confirmText: config.confirmText,
          confirmIcon: config.confirmIcon,
          confirmColor: config.confirmColor,
          confirmTextColor: config.confirmTextColor,
          cancelText: config.cancelText,
          cancelTextColor: config.cancelTextColor,
          cancelIcon: config.cancelIcon,
          cancelColor: config.cancelColor,
          typeToConfirm: config.typeToConfirm,
          showClose: config.showClose,
          textToCompare: config.textToCompare,
        },
      });
      confirmInstance.$mount();
      confirmInstance.$on('confirm', () => {
        confirmInstance.$destroy();
        confirmInstance = null;
        resolve();
      });

      confirmInstance.$on('cancel', () => {
        confirmInstance.$destroy();
        confirmInstance = null;
        reject();
      });

      confirmInstance.$on('close', () => {
        confirmInstance = null;
        confirmInstance.open = false;
      });

      confirmInstance.open = true;
      document.body.appendChild(confirmInstance.$el);
    });

    Vue.confirm = Vue.prototype.$confirm;

    // Save or Discard Changes
    const SaveOrDiscardChangesDialogConstructor = Vue.extend(SaveOrDiscardChangesDialog);
    let saveOrDiscardChangesInstance = null;

    Vue.prototype.$checkUnsavedChanges = (unsavedData, saveFunction, discardFunction, cancelFunction, saveBDFunction) => {
      if (!saveOrDiscardChangesInstance) {
        saveOrDiscardChangesInstance = new SaveOrDiscardChangesDialogConstructor({
          vuetify,
        });

        saveOrDiscardChangesInstance.$mount();
        document.body.appendChild(saveOrDiscardChangesInstance.$el);
      }

      const execFunction = (_function) => {
        if (_function && typeof _function === 'function') {
          _function();
        }
      };

      if (unsavedData) {
        saveOrDiscardChangesInstance.$once('action', (action) => {
          if (action === 'save') {
            execFunction(saveFunction);
          } else if (action === 'saveBD') {
            execFunction(saveBDFunction);
          } else if (action === 'discart') {
            execFunction(discardFunction);
          } else {
            execFunction(cancelFunction);
          }
        });
        saveOrDiscardChangesInstance.open = true;
      } else {
        execFunction(discardFunction);
      }
    };
  },
};
