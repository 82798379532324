<template>
  <v-row cols="12">
    <v-col v-for="(field, index) in fields"
           :key="index"
           xs="12"
           sm="6"
           :md="field.col"
           :offset-md="field.offsetMd"
           :offset-lg="field.offsetLg"
           :offset-xl="field.offsetXl"
           :lg="field.col"
           :xl="field.col">
      <span class="fieldlist-label">
        {{ field.label }}:
      </span>
      <br>
      <span>{{ field.value || '' }}</span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FieldsList',
  props: {
    fields: {
      required: true,
      type: Array,
      default: () => ([]),
    },
  },
};
</script>
<style lang="scss" scoped>
.fieldlist-label {
    font-family: 'Montserrat';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0.01em;
    text-align: left;
}
</style>
