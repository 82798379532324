<template>
  <div :id="item.id">
    <GenericCardPanel
      title="Informações da Lista de Preços"
      :fields="priceListFields" />
  </div>
</template>

<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabPriceList',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      priceListFields: [],
    };
  },
  mounted() {
    this.priceListFields = [
      { label: 'Nome', value: this.item.name, col: 6 },
      { label: 'Categorias dos Produtos:', value: this.item.categories, col: 4 },
      { label: 'Inativo:', value: this.item.is_inactive_formatted, col: 2 },
    ];
  },
};
</script>

<style scoped>
.red-color {
  color: red;
}
.green-color {
  color: green;
}
.gray-color {
  color: gray;
}</style>
