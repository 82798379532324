import { ICON_PRODUCT } from '@constants/icons';
import { OPTIONS_UF, OPTIONS_TYPE_ACCOUNT_RECEIVABLE_INSTALLMENTS_STATUS } from '@constants/options';

const GeneralAccountReceivablesSchema = {
  service: 'InstallmentsReceivables',
  icon: ICON_PRODUCT,
  route: {
    path: '/reports/accounts/receivable/general',
    name: 'reports-account-receivable-general',
  },
  title: 'Relatórios de Contas a Receber',
  filtersLayout: [
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'v-col',
          cols: '8',
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [
                {
                  label: 'Nome / Prestador',
                  name: 'register_id',
                  type: 'number',
                  hint: '',
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'Registers',
                    method: 'getByFilter',
                    props: [{ type_register_array: 'client' }, 'created_at asc'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 12,
                  clearable: true,
                },
                {
                  label: 'Parcelamento',
                  name: 'type_installment_id',
                  formattedName: 'type_installment.to_s',
                  type: 'number',
                  multiple: true,
                  clearable: true,
                  hideNot: true,
                  component: 'dynamic-select',
                  dataSource: {
                    service: 'TypeInstallment',
                    method: 'getByFilter',
                    props: [{ inactive_eq: false }],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 12,
                },
                {
                  label: 'Conta Contábil',
                  name: 'accounting_account_id',
                  formattedName: 'accounting_account.to_s',
                  type: 'number',
                  clearable: true,
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'AccountingAccounts',
                    method: 'getByFilter',
                    props: [],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 6,
                },
                {
                  label: 'Centro de Custo',
                  name: 'cost_center_id',
                  formattedName: 'cost_center.to_s',
                  type: 'number',
                  clearable: true,
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'CostCenters',
                    method: 'getByFilter',
                    props: [],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 6,
                },
              ],
            },
          ],
        },
        {
          component: 'v-col',
          cols: '4',
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [
                {
                  label: 'Datas',
                  name: 'dynamic-dates',
                  fields: [
                    {
                      name: 'issuance_date',
                      label: 'Data de Emissão',
                    },
                    {
                      name: 'discharge_date',
                      label: 'Data de Quitação',
                    },
                    {
                      name: 'due_date',
                      label: 'Data de Vencimento',
                    },
                    {
                      name: 'payment_date',
                      label: 'Data de Pagamento',
                    },
                    {
                      name: 'compensation_date',
                      label: 'Data de Compensação',
                    },
                    {
                      name: 'emission_date',
                      label: 'Data de Vencimento Original',
                    },
                  ],
                  component: 'dynamic-multi-date-filter',
                  md: 12,
                  clearable: true,
                },
              ],
            },
          ],
        },
        {
          component: 'dynamic-filters',
          containerClass: 'pt-0',
          fields: [
            {
              label: 'FILTROS DO PRESTADOR',
              component: 'dynamic-title',
              md: 2,
              size: 'xl',
              propTag: 'h6',
              hideNot: true,
            },
            {
              component: 'dinamic-divider',
              md: 10,
            },
            {
              label: 'Bairro',
              name: 'register.addresses.neighborhood',
              type: 'text',
              hideNot: true,
              component: 'dynamic-text-field',
              condition: '_matches',
              notCondition: '_does_not_match',
              md: 4,
            },
            {
              label: 'Cidade',
              name: 'register.addresses.city',
              type: 'text',
              hideNot: true,
              component: 'dynamic-text-field',
              condition: '_matches',
              notCondition: '_does_not_match',
              md: 4,
            },
            {
              label: 'UF',
              name: 'register.addresses.uf',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              items: OPTIONS_UF,
              md: 4,
            },
            {
              label: 'Avaliação',
              name: 'register.evaluation_id',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['evaluation'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Tipo',
              name: 'register.type_id',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['type'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Atividade / Ocupação',
              name: 'register.occupation_id',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['occupation'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            // {
            //   label: 'Banco',
            //   name: 'installments.bank_id',
            //   type: 'number',
            //   component: 'dynamic-select',
            //   multiple: true,
            //   clearable: true,
            //   dataSource: {
            //     service: 'Banks',
            //     method: 'index',
            //     props: ['payment_method'],
            //   },
            //   itemText: 'to_s',
            //   itemValue: 'id',
            //   md: 4,
            // },
            {
              label: 'Tipo do Pagamento',
              name: 'payment_method_id',
              type: 'number',
              hideNot: true,
              component: 'dynamic-select',
              redirectCreateRoute: 'tables-paymentmethod-create',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['payment_method'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            // {
            //   label: 'Status do Título',
            //   name: 'status',
            //   type: 'string',
            //   multiple: true,
            //   clearable: true,
            //   component: 'dynamic-select',
            //   items: OPTIONS_ORDERS_STATUS,
            //   md: 4,
            // },
            {
              label: 'Status da Parcela',
              name: 'status',
              type: 'string',
              clearable: true,
              hideNot: true,
              component: 'dynamic-select',
              items: OPTIONS_TYPE_ACCOUNT_RECEIVABLE_INSTALLMENTS_STATUS,
              md: 4,
            },

          ],
        },
      ],
    },
  ],
};

export default GeneralAccountReceivablesSchema;
