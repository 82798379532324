import * as AppUtils from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';
import { calculateTotals } from '@businesses/Common/CalculateTotals';
import { ulid } from 'ulid';

const formatResponse = (order) => {
  order.buyer_id = order.buyer_id || (order.buyer && order.buyer.id) || null;
  order.collaborator_id = order.collaborator_id || (order.collaborator && order.collaborator.id) || null;

  order.type_installment_id = order.type_installment_id || (order.type_installment && order.type_installment.id) || null;
  order.nature_operation_id = order.nature_operation_id || (order.nature_operation && order.nature_operation.id) || null;
  order.shipping_company_id = order.shipping_company_id || (order.shipping_company && order.shipping_company.id) || null;

  order.emission_date_formatted = AppUtils.formatDate(order.emission_date);
  order.delivery_date_formatted = AppUtils.formatDate(order.delivery_date);
  order.forecast_date_formatted = AppUtils.formatDate(order.forecast_date);

  order.status = order.status || 'initial';
  order.status_formatted = AppUtils.getOptionText('orders_status', order.status);
  order.note_formatted = order.note;

  order.presence_indicator_formatted = AppUtils.getOptionText('presence_indicator', order.presence_indicator);
  order.freight_formatted = AppUtils.getOptionText('freight', order.freight);
  order.freight_value_formatted = AppUtils.formatCurrency(parseFloat(order.freight_value || 0));
  order.expense_value_formatted = AppUtils.formatCurrency(parseFloat(order.expense_value || 0));

  /* eslint-disable no-unused-vars */
  let totalDiscount = 0;
  let totalTaxes = 0;
  let totalTaxApproximates = 0;
  let totalProducts = 0;

  if (order.items && order.items.length > 0) {
    const itemsFormatted = order.items.map((item) => {
      item.id = parseInt(item.id);
      const HAS_ICMS_ST_TAXES = item.icms_st;
      const HAS_ICMS_TAXES = item.icms;
      const HAS_IPI_TAXES = item.ipi;
      const HAS_PIS_TAXES = item.pis;
      const HAS_COFINS_TAXES = item.cofins;
      const HAS_SIMPLES_NACIONAL_TAXES = item.simple_national;
      const HAS_ICMS_FINAL_COSTUMER_TAXES = item.icms_final_costumer;

      item.product_id = item.product_id || (item.product && item.product.id) || null;
      item.product_code = item.product_id || (item.product && item.product.id) || null;
      item.nature_operation_id = (item.nature_operation && item.nature_operation_id) || null;
      item.unity_id = (item.unity && item.unity_id) || null;
      item.ncm_id = item.ncm_id || (item.ncm && item.ncm.id) || null;
      item.cest_id = (item.cest && item.cest_id) || null;

      const HAS_CEST = item?.cest;
      const cestItem = HAS_CEST ? { ...item.cest, to_s: item.cest.description ?? item.cest.to_s } : {};

      const returnedItem = {
        ...item,
        cest: cestItem,
        icms: {
          base: HAS_ICMS_TAXES ? item.icms.base : 0,
          aliquot: HAS_ICMS_TAXES ? item.icms.aliquot : 0,
          value: HAS_ICMS_TAXES ? item.icms.value : 0,
          base_formatted: HAS_ICMS_TAXES ? AppUtils.formatNumber(item.icms.base) : '0,00',
          aliquot_formatted: HAS_ICMS_TAXES ? AppUtils.formatNumber(item.icms.aliquot) : '0,00',
          value_formatted: HAS_ICMS_TAXES ? AppUtils.formatNumber(item.icms.value) : '0,00',
        },
        icms_st: {
          base: HAS_ICMS_ST_TAXES ? item.icms_st.base : 0.0,
          aliquot: HAS_ICMS_ST_TAXES ? item.icms_st.aliquot : 0.0,
          value: HAS_ICMS_ST_TAXES ? item.icms_st.value : 0.0,
          base_formatted: HAS_ICMS_ST_TAXES ? AppUtils.formatNumber(item.icms_st.base) : '0,00',
          aliquot_formatted: HAS_ICMS_ST_TAXES ? AppUtils.formatNumber(item.icms_st.aliquot) : '0,00',
          value_formatted: HAS_ICMS_ST_TAXES ? AppUtils.formatNumber(item.icms_st.value) : '0,00',
        },
        ipi: {
          base: HAS_IPI_TAXES ? item.ipi.base : 0.0,
          aliquot: HAS_IPI_TAXES ? item.ipi.aliquot : 0.0,
          value: HAS_IPI_TAXES ? item.ipi.value : 0.0,
          base_formatted: HAS_IPI_TAXES ? AppUtils.formatNumber(item.ipi.base) : '0,00',
          aliquot_formatted: HAS_IPI_TAXES ? AppUtils.formatNumber(item.ipi.aliquot) : '0,00',
          value_formatted: HAS_IPI_TAXES ? AppUtils.formatNumber(item.ipi.value) : '0,00',
        },
        pis: {
          base: HAS_PIS_TAXES ? item.pis.base : 0.0,
          aliquot: HAS_PIS_TAXES ? item.pis.aliquot : 0.0,
          value: HAS_PIS_TAXES ? item.pis.value : 0.0,
          base_formatted: HAS_PIS_TAXES ? AppUtils.formatNumber(item.pis.base) : '0,00',
          aliquot_formatted: HAS_PIS_TAXES ? AppUtils.formatNumber(item.pis.aliquot) : '0,00',
          value_formatted: HAS_PIS_TAXES ? AppUtils.formatNumber(item.pis.value) : '0,00',
        },
        cofins: {
          base: HAS_COFINS_TAXES ? item.cofins.base : 0.0,
          aliquot: HAS_COFINS_TAXES ? item.cofins.aliquot : 0.0,
          value: HAS_COFINS_TAXES ? item.cofins.value : 0.0,
          base_formatted: HAS_COFINS_TAXES ? AppUtils.formatNumber(item.cofins.base) : '0,00',
          aliquot_formatted: HAS_COFINS_TAXES ? AppUtils.formatNumber(item.cofins.aliquot) : '0,00',
          value_formatted: HAS_COFINS_TAXES ? AppUtils.formatNumber(item.cofins.value) : '0,00',
        },
        simple_national: {
          base: HAS_SIMPLES_NACIONAL_TAXES ? item.simple_national.base : 0.0,
          aliquot: HAS_SIMPLES_NACIONAL_TAXES ? item.simple_national.aliquot : 0.0,
          value: HAS_SIMPLES_NACIONAL_TAXES ? item.simple_national.value : 0.0,
          base_formatted: HAS_SIMPLES_NACIONAL_TAXES ? AppUtils.formatNumber(item.simple_national.base) : '0,00',
          aliquot_formatted: HAS_SIMPLES_NACIONAL_TAXES ? AppUtils.formatNumber(item.simple_national.aliquot) : '0,00',
          value_formatted: HAS_SIMPLES_NACIONAL_TAXES ? AppUtils.formatNumber(item.simple_national.value) : '0,00',
        },
        icms_final_costumer: {
          base: HAS_ICMS_FINAL_COSTUMER_TAXES ? item.icms_final_costumer.base : 0.0,
          aliquot: HAS_ICMS_FINAL_COSTUMER_TAXES ? item.icms_final_costumer.aliquot : 0.0,
          value: HAS_ICMS_FINAL_COSTUMER_TAXES ? item.icms_final_costumer.value : 0.0,
          base_formatted: HAS_ICMS_FINAL_COSTUMER_TAXES ? AppUtils.formatNumber(item.icms_final_costumer.base) : '0,00',
          aliquot_formatted: HAS_ICMS_FINAL_COSTUMER_TAXES ? AppUtils.formatNumber(item.icms_final_costumer.aliquot) : '0,00',
          value_formatted: HAS_ICMS_FINAL_COSTUMER_TAXES ? AppUtils.formatNumber(item.icms_final_costumer.value) : '0,00',
        },
        unit_value_formatted: AppUtils.formatCurrency(parseFloat(item.unit_value || 0)),
        discount_value_formatted: AppUtils.formatCurrency(parseFloat(item.discount_value || 0)),
        discount_percentage_formatted: `${AppUtils.formatNumber(parseFloat(item.discount_percentage_value))}%`,
        total_value_formatted: AppUtils.formatCurrency(parseFloat(item.total_value || 0)),
        total_value_tmp: parseFloat(item.total_value || 0),
        total_value_tmp_formatted: AppUtils.formatCurrency(parseFloat(item.total_value || 0)),
        tax_approximate_formatted: AppUtils.formatNumber(item.tax_approximate || 0),
        tax_approximate_percentage_formatted: AppUtils.formatNumber(item.tax_approximate_percentage || 0),
        quantity_formatted: AppUtils.formatNumber(item.quantity),
      };

      totalDiscount += parseFloat(item.discount_value);
      if (HAS_ICMS_ST_TAXES && HAS_IPI_TAXES) {
        totalTaxes += parseFloat(item.icms_st.value) + parseFloat(item.ipi.value);
      }
      totalTaxApproximates += parseFloat(item.tax_approximate);
      totalProducts += (parseFloat(item.quantity) * parseFloat(item.unit_value)) - totalDiscount;

      return returnedItem;
    });

    order.items = itemsFormatted;
    order.taxes = itemsFormatted;

    calculateTotals(order);
  }

  order.total_tax_approximates = AppUtils.formatNumber(parseFloat(order.total_tax_approximates || totalTaxApproximates || 0));
  order.total_taxes_formatted = AppUtils.formatCurrency(order.total_taxes || totalTaxes || 0);
  order.total_discount_formatted = AppUtils.formatCurrency(order.total_discount || totalDiscount || 0);
  order.total_products_formatted = AppUtils.formatCurrency(order.total_products || totalProducts || 0);
  order.total_ordered_formatted = AppUtils.formatCurrency(order.total_ordered || (totalProducts - totalDiscount || 0));

  return order;
};

const formatRequest = (order) => {
  const formattedItems = order.items.map((item) => {
    delete item._ulid;
    delete item.product_code;
    delete item.icms;
    delete item.icms_st;
    delete item.ipi;
    delete item.pis;
    delete item.cofins;
    delete item.icms_base;
    delete item.icms_aliquot;
    delete item.icms_value;
    delete item.icms_st_base;
    delete item.icms_st_aliquot;
    delete item.icms_st_value;
    delete item.pis_base;
    delete item.pis_aliquot;
    delete item.pis_value;
    delete item.cofins_base;
    delete item.cofins_aliquot;
    delete item.cofins_value;
    delete item.simple_national;
    delete item.simple_national_st;
    return item;
  });

  order.items = formattedItems;
  order.items_attributes = order.items || [];
  order.taxes_attributes = order.taxes || [];
  order.export_attributes = order.export || null;
};

const formatClone = (item) => {
  delete item.id;
  item.items = item.items || [];
  item.items.forEach((i) => {
    delete i.id;
    i._ulid = ulid();
  });
};

const RemittanceConsignments = DynamicService('remittance_consignments', {
  formatClone,
  formatResponse,
  formatRequest,
});
const RemittanceConsignmentsItems = DynamicService('remittance_consignments/remittance_consignments_items');

const RemittanceConsignmentsCommands = ($axios) => ({
  async getOrdersDeliveredByVendorId(buyer_id) {
    const payload = {
      q: {
        buyer_id_eq: buyer_id,
      },
      out: 'order_purchases/filters',
    };
    const res = await $axios.post('order_purchases/filters.json', payload);
    return res;
    // const responseformatted = res.data.forEach((order) => {
    //   formatResponse(order);
    // });
    // return responseformatted;
  },
  async createDevolutionConsignment(remittance) {
    const payload = {
      remittance_id: remittance.id,
      product_id: remittance.product_id,
      to_return_quantity: remittance.to_return_quantity,
    };
    const res = await $axios.post('/remittance_movements', payload);
    return res;
  },
  async createStockConsignment(buyer_id) {
    const payload = {
      buyer_id,
      name: 'Principal',
      use_main: true,
    };
    const res = await $axios.post('/consignment_stocks', payload);
    return res;
  },
});

const RemittanceConsignmentsTaxes = ($axios) => ({
  async getTotalizer(id) {
    const response = await $axios.get(`/taxes/remittance_consignments/${id}/totalizers.json`);
    return response;
  },
  async calculateRealTime(data) {
    try {
      const response = await $axios.post('/taxes/remittance_consignments/real_time.json', data);
      const responseFormatted = {};
      let totalizersFormatted = {};
      const { totalizers } = response.data;

      if (totalizers) {
        totalizersFormatted = {
          total_icms_base: (totalizers.total_icms_base || 0),
          total_icms: (totalizers.total_icms || 0),
          total_icms_formatted: AppUtils.formatCurrency(totalizers.total_icms || 0),
          total_icms_st: (totalizers.total_icms_st || 0),
          total_icms_st_base: (totalizers.total_icms_st_base || 0),
          total_icms_st_formatted: AppUtils.formatCurrency(totalizers.total_icms_st || 0),
          total_icms_st_and_ipi: (totalizers.total_icms_st_and_ipi || 0),
          total_icms_st_and_ipi_formatted: AppUtils.formatCurrency(totalizers.total_icms_st_and_ipi || 0),
          total_ipi_base: (totalizers.total_ipi_base || 0),
          total_ipi: (totalizers.total_ipi || 0),
          total_ipi_formatted: AppUtils.formatCurrency(totalizers.total_ipi || 0),
          total_pis_base: (totalizers.total_pis_base || 0),
          total_pis: (totalizers.total_pis || 0),
          total_pis_formatted: AppUtils.formatCurrency(totalizers.total_pis || 0),
          total_cofins_base: (totalizers.total_cofins_base || 0),
          total_cofins: (totalizers.total_cofins || 0),
          total_cofins_formatted: AppUtils.formatCurrency(totalizers.total_cofins || 0),
          total_simple_national: (totalizers.total_simple_national || 0),
          total_simple_national_formatted: AppUtils.formatCurrency(totalizers.total_simple_national || 0),
        };
      }
      responseFormatted.data = {
        ...response.data,
        totalizers: totalizersFormatted,
      };

      return responseFormatted;
    } catch (error) {
      console.warn(error);
    }
  },
});

const StockConsignments = DynamicService('consignment_stocks', {
  formatClone,
  formatResponse,
  formatRequest,
});

const DevolutionConsignments = DynamicService('consignment_devolution', {
  formatClone,
  formatResponse,
  formatRequest,
});

const DevolutionConsignmentsTaxes = ($axios) => ({
  async getTotalizer(id) {
    const response = await $axios.get(`/taxes/consignment_devolution/${id}/totalizers.json`);
    return response;
  },
  async calculateRealTime(data) {
    try {
      const response = await $axios.post('/taxes/consignment_devolution/real_time.json', data);
      const responseFormatted = {};
      let totalizersFormatted = {};
      const { totalizers } = response.data;

      if (totalizers) {
        totalizersFormatted = {
          total_icms_base: (totalizers.total_icms_base || 0),
          total_icms: (totalizers.total_icms || 0),
          total_icms_formatted: AppUtils.formatCurrency(totalizers.total_icms || 0),
          total_icms_st: (totalizers.total_icms_st || 0),
          total_icms_st_base: (totalizers.total_icms_st_base || 0),
          total_icms_st_formatted: AppUtils.formatCurrency(totalizers.total_icms_st || 0),
          total_icms_st_and_ipi: (totalizers.total_icms_st_and_ipi || 0),
          total_icms_st_and_ipi_formatted: AppUtils.formatCurrency(totalizers.total_icms_st_and_ipi || 0),
          total_ipi_base: (totalizers.total_ipi_base || 0),
          total_ipi: (totalizers.total_ipi || 0),
          total_ipi_formatted: AppUtils.formatCurrency(totalizers.total_ipi || 0),
          total_pis_base: (totalizers.total_pis_base || 0),
          total_pis: (totalizers.total_pis || 0),
          total_pis_formatted: AppUtils.formatCurrency(totalizers.total_pis || 0),
          total_cofins_base: (totalizers.total_cofins_base || 0),
          total_cofins: (totalizers.total_cofins || 0),
          total_cofins_formatted: AppUtils.formatCurrency(totalizers.total_cofins || 0),
          total_simple_national: (totalizers.total_simple_national || 0),
          total_simple_national_formatted: AppUtils.formatCurrency(totalizers.total_simple_national || 0),
        };
      }
      responseFormatted.data = {
        ...response.data,
        totalizers: totalizersFormatted,
      };

      return responseFormatted;
    } catch (error) {
      console.warn(error);
    }
  },
});

const TransactionsConsignments = DynamicService('remittance_movements', {
  formatClone,
  formatResponse,
  formatRequest,
});

export {
  DevolutionConsignments,
  DevolutionConsignmentsTaxes,
  RemittanceConsignments,
  RemittanceConsignmentsItems,
  RemittanceConsignmentsCommands,
  RemittanceConsignmentsTaxes,
  TransactionsConsignments,
  StockConsignments,
};
