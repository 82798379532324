<template>
  <div class="tab-order-service-items">
    <GenericCardForm>
      <DataList :headers="headers"
                :items="setOrderServicesItems(item)"
                item-key="id"
                no-edit
                no-delete
                no-check
                :sort-by="['id']"
                :sort-desc="[false, true]" />
    </GenericCardForm>
  </div>
</template>

<script>
import { ICON_REFRESH } from '@constants/icons';
import GenericCardForm from '@sharedComponents/dynamic/GenericCardForm';
import DataList from '@sharedComponents/dynamic/DataList';

export default {
  name: 'TabOrderServiceItems',
  components: {
    DataList,
    GenericCardForm,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      icons: {
        refresh: ICON_REFRESH,
      },
      headers: [
        { text: 'Código', value: 'product.code' },
        { text: 'Serviço', value: 'product.to_s' },
        { text: 'Nome', value: 'name' },
        { text: 'Quantidade', value: 'quantity_formatted' },
        { text: 'Valor Unitário', value: 'unit_value_formatted' },
        { text: 'Valor Desconto', value: 'discount_value_formatted' },
        { text: 'Valor Total', value: 'total_value_formatted' },
        { text: 'Natureza da Operação', value: 'nature_operation.to_s' },
        { text: 'Código de Serviço', value: 'service_code.to_s' },
        { text: 'Observações', value: 'observation' },
      ],
      actions: [],
      filterParams: {},
    };
  },
  computed: {},
  methods: {
    setOrderServicesItems(item) {
      const OrderServicesItems = item.items;
      return OrderServicesItems;
    },
  },
};
</script>
