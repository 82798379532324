import InvoiceSSSchema from '@schemas/InvoiceISS';

const ListInvoicesISS = () => import(/* webpackChunkName: "list-invoicesiss" */'./ListInvoicesISS');
const DetailsInvoicesISS = () => import(/* webpackChunkName: "details-invoicesiss" */'./DetailsInvoicesISS');

export default [
  {
    path: InvoiceSSSchema.routes.list.path,
    name: InvoiceSSSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'invoices',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: InvoiceSSSchema.titles.items },
      ],
    },
    component: ListInvoicesISS,
  },
  {
    path: InvoiceSSSchema.routes.create.path,
    name: InvoiceSSSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'invoices',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: InvoiceSSSchema.titles.items, route: InvoiceSSSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailsInvoicesISS,
  },
  {
    path: InvoiceSSSchema.routes.edit.path,
    name: InvoiceSSSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'invoices',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: InvoiceSSSchema.titles.items, route: InvoiceSSSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailsInvoicesISS,
  },
];
