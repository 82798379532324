import AnticipationDraweeSchema from '@schemas/AnticipationDrawee';

const DetailsDrawee = () => import(/* webpackChunkName: "Drawees-detailsdrawee-details" */'./DetailsDrawee');

export default [
  {
    path: AnticipationDraweeSchema.routes.show.path,
    name: AnticipationDraweeSchema.routes.show.name,
    meta: {
      requiresAuth: false,
      antecipation: true,
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Antecipação de Recebíveis' },
        { name: 'Notas Fiscais' },
      ],
    },
    component: DetailsDrawee,
  },
];
