<template>
  <v-dialog v-model="open" persistent :fullscreen="fullscreen" :max-width="maxWidth">
    <v-card class="reportDialog card-scroll">
      <v-card-title :class="editReport ? 'my-n4' : ''" style="background: #F5F5F5" card prominent>
        <v-icon color="primary" class="mr-2">
          {{ icons.print }}
        </v-icon>
        <template v-if="editReport">
          <v-text-field v-model="reportName" :rules="[$rules.required()]" />
          <v-switch v-model="isDraft" class="ml-2" label="Rascunho" />
        </template>
        <span v-else class="grey--text text--darken-4"><b>{{ reportName }}</b></span>
        <v-spacer />
        <template v-if="(editable || newReport) && editReport">
          <input ref="fileUploader"
                 type="file"
                 style="display:none"
                 multiple="false"
                 accept=".rdlx-json,.json"
                 @change="onFileSelect">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon
                     color="primary"
                     class="mr-2"
                     v-on="on"
                     @click="onUploadReport">
                <v-icon>{{ icons.upload }}</v-icon>
              </v-btn>
            </template>
            <span>Upload</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon
                     color="primary"
                     class="mr-2"
                     v-on="on"
                     @click="onDownloadReport">
                <v-icon>{{ icons.download }}</v-icon>
              </v-btn>
            </template>
            <span>Download</span>
          </v-tooltip>
        </template>
        <v-btn v-if="report.id && editReport" color="error" class="mr-2" @click="onDeleteReport">
          <v-icon>{{ icons.delete }}</v-icon>
        </v-btn>
        <v-btn v-if="editReport" color="primary" class="mr-2" @click="onSaveReport">
          <v-icon>{{ icons.save }}</v-icon>
          Salvar
        </v-btn>

        <v-btn v-if="(editable || newReport) && !editReport" icon text @click="onEdit">
          <v-icon>{{ icons.edit }}</v-icon>
        </v-btn>
        <v-btn v-else-if="(editable || newReport) && editReport" icon text @click="onPreviewReport">
          <v-icon>{{ icons.preview }}</v-icon>
        </v-btn>

        <v-btn icon text @click="fullscreen = !fullscreen">
          <v-icon>{{ fullscreen ? icons.fullScreenExit : icons.fullScreen }}</v-icon>
        </v-btn>
        <v-btn icon text @click="onClose">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>
      <!-- <v-divider /> -->
      <ReportViewer v-if="!editReport"
                    ref="reportViewer"
                    :report-parameters="reportParameters"
                    :report-definition="reportDefinition"
                    :report-data="reportData" />
      <ReportDesigner v-else
                      ref="reportDesigner"
                      :report-definition="reportDefinition"
                      :report-data="reportData" />
    </v-card>
  </v-dialog>
</template>

<script>
import '@plugins/rules';
import LandscapeDefaultReportLayout from './templates/LandscapeDefaultReportLayout.js';
import PortraitDefaultReportLayout from './templates/PortraitDefaultReportLayout.js';
import router from '@src/core/router';
import {
  ICON_CLOSE, ICON_EDIT, ICON_FULLSCREEN, ICON_FULLSCREEN_EXIT,
  ICON_EYE, ICON_SAVE, ICON_PRINTER, ICON_DOWNLOAD, ICON_UPLOAD,
  ICON_TRASH,
} from '@constants/icons';
import ReportViewer from './ReportViewer.vue';
import ReportDesigner from './ReportDesigner.vue';
import * as AppUtils from '@plugins/app-utils';
import _ from 'lodash';

export default {
  components: {
    ReportViewer,
    ReportDesigner,
  },
  props: {
    reportId: {
      type: Number,
      default: null,
    },
    schema: {
      type: Object,
      default: () => ({}),
    },
    reportData: {
      type: [Object, Array],
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    newReport: {
      type: Boolean,
      default: false,
    },
    orientation: {
      type: String,
      default: 'portrait',
    },
  },
  data() {
    return {
      valid: false,
      open: false,
      iCertusLogo: require('@assets/iCertus.png'),
      icons: {
        close: ICON_CLOSE,
        edit: ICON_EDIT,
        fullScreen: ICON_FULLSCREEN,
        fullScreenExit: ICON_FULLSCREEN_EXIT,
        preview: ICON_EYE,
        save: ICON_SAVE,
        print: ICON_PRINTER,
        download: ICON_DOWNLOAD,
        upload: ICON_UPLOAD,
        delete: ICON_TRASH,
      },
      editReport: false,
      fullscreen: false,
      report: {},
      reportDefinition: {},
      reportName: '',
      isDraft: false,
      userData: '',
      companyData: '',
      mainAddress: '',
      mainContact: '',
      companyLogo: '',
    };
  },
  computed: {
    maxWidth() {
      return this.editReport ? '1200px' : '780px';
    },
    reportParameters() {
      return [
        { Name: 'ReportName', Value: [this.reportName ?? 'Relatório'] },
      ];
    },
  },
  watch: {
    reportId: {
      handler: 'onReportIdChange',
      immediate: true,
      deep: true,
      flush: 'post',
    },
  },
  async mounted() {
    await this.getUserInfo();
    await this.setDefaultLogo(this.companyData.logo_url);
    this.setDefaultDataSource();
  },
  methods: {
    addOrRefreshDatasource(name, data) {
      this.reportDefinition.DataSources = this.reportDefinition.DataSources || [];
      const dss = this.reportDefinition.DataSources || [];
      const companyDs = dss.find((ds) => ds.Name === name);
      const jsonData = `jsondata=${JSON.stringify(data)}`;
      if (companyDs) {
        companyDs.ConnectionProperties.ConnectString = jsonData;
      } else {
        this.reportDefinition.DataSources.push({ Name: name, ConnectionProperties: { DataProvider: 'JSONEMBED', ConnectString: jsonData } });
      }
    },
    async getEmbeddedLogo(name, path) {
      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', path, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function (event) {
            const res = event.target.result;
            const base64Pos = res.indexOf(';base64,');
            const mimeType = res.substring(5, base64Pos);
            const base64 = res.substring(base64Pos + 8, res.length);
            resolve({
              Name: name,
              ImageData: base64,
              MIMEType: mimeType,
            });
          };
          const file = this.response;
          reader.readAsDataURL(file);
        };
        xhr.send();
      });
    },
    async getUserInfo() {
      const { data } = await this.$api.Me.index();
      if (data) {
        this.userData = data;
        this.companyData = data.company;
        if (data.company.addresses && data.company.addresses.length > 0) {
          this.mainAddress = data.company.addresses.find((address) => address.main === true);
        }
        if (data.company.contacts && data.company.contacts.length > 0) {
          this.mainContact = data.company.contacts.find((contact) => contact.main === true);
        }
      }
    },
    setDefaultDataSource() {
      this.addOrRefreshDatasource('DefaultDataSource', this.reportData);
      this.addOrRefreshDatasource('User', this.userData);
      this.addOrRefreshDatasource('Company', this.companyData);
      this.addOrRefreshDatasource('MainAddress', this.mainAddress);
      this.addOrRefreshDatasource('MainContact', this.mainContact);
    },
    async setDefaultLogo(logoURL) {
      this.companyLogo = await this.getEmbeddedLogo('Logo', logoURL ?? this.iCertusLogo);
      this.reportDefinition.EmbeddedImages = [this.companyLogo];
    },
    async onReportIdChange(newId) {
      if (this.newReport) {
        if (this.orientation === 'portrait') {
          this.reportDefinition = _.cloneDeep(PortraitDefaultReportLayout);
        } else {
          this.reportDefinition = _.cloneDeep(LandscapeDefaultReportLayout);
        }

        this.reportName = '';
        this.isDraft = true;
      } else {
        const { data } = await this.$api.Reports.show(newId);
        this.report = data;
        this.reportDefinition = JSON.parse(this.report.definition);
        this.reportName = this.report.name || 'Relatório';
        this.isDraft = this.report.draft;
      }

      if (this.reportData === '') {
        await this.setDefaultDataSource();
      }

      if (this.newReport) {
        // Try add DefaultDataset based on schema
        if (this.schema && this.schema.fields) {
          const isListReport = _.isArray(this.reportData);
          const selector = isListReport ? 'jpath=$.*' : 'jpath=$';
          const datasetName = AppUtils.slugify(isListReport ? this.schema.titles.items : this.schema.titles.item) || 'DefaultDataSet';
          const dataSetItems = this.getDatasetFields(this.schema);

          if (this.schema.childSchemas) {
            const uniqChieldSchemas = [];

            this.schema.childSchemas.forEach((childSchema) => {
              const existingIndex = uniqChieldSchemas.findIndex((item) => item.name === childSchema.name);
              if (existingIndex === -1) {
                const childDatasetName = childSchema.name;
                const childDataSourceName = `$dataset:${datasetName}/${childDatasetName}`;
                const fields = this.getDatasetFields(childSchema.schema);
                uniqChieldSchemas.push({
                  name: childDatasetName,
                  datasource: childDataSourceName,
                  fields,
                });
              } else {
                const fields = this.getDatasetFields(childSchema.schema);
                uniqChieldSchemas[existingIndex].fields.push(...fields);
              }
            });

            uniqChieldSchemas.forEach((childSchema) => {
              this.addDataset(childSchema.datasource, childSchema.name, childSchema.fields, '');
              dataSetItems.push({
                Name: childSchema.name,
                DataField: childSchema.name,
              });
            });
          }

          this.addDataset('DefaultDataSource', datasetName, dataSetItems, selector);

          //
        }

        this.addDataset('MainAddress', 'MainAddressDataSet', [
          'to_s',
          'addressable_type',
          'city',
          'complement',
          'ibge',
          'main',
          'neighborhood',
          'number',
          'street',
          'uf',
          'zip_code',
          'addressable_id',
          'type_address_id',
        ]);
        this.addDataset('MainContact', 'MainContactDataSet', [
          'id',
          'contactable_type',
          'date_birth',
          'email',
          'gender',
          'main',
          'name',
          'posts',
          'sector',
          'site',
          'telephone',
          'type_notification',
        ]);
        this.addDataset('User', 'UserDataSet', [
          'to_s',
          'id',
          'name',
          'nickname',
          'email',
          'company.to_s',
          'company.id',
          'company.cnpj',
          'company.name',
          'company.logo_url',
          'company.tax_regime',
          'customer.to_s',
          'customer.id',
          'customer.name',
          'customer.email',
          'customer.phone',
          'customer.address',
          'customer.contacts',
          'company.subscription.user_limit_exceeded',
          'company.subscription.invoice_limit_exceeded',
          'company.subscription.plan.name',
          'company.subscription.plan.description',
          'admin',
          'confirmed',
        ]);
        this.addDataset('Company', 'CompanyDataSet', [
          'to_s',
          'id',
          'cnpj',
          'name',
          'logo_url',
          'customer_id',
          'code',
          'im',
          'nickname',
          'activity_code',
          'tax_regime',
          'is_industry',
          'is_trade',
          'is_service',
          'printer_header',
          'printer_footer',
          'addresses',
          'contacts',
          'iests',
          'tax_regime_formatted',
          'is_industry_formatted',
          'is_trade_formatted',
          'is_service_formatted',
        ]);

        this.onEdit();
      }
    },
    getDatasetFields(schema) {
      return schema.fields.map((field) => ({
        Name: field.label || field.name,
        DataField: field.formattedName || field.name,
      }));
    },

    addDataset(datasourceName, datasetName, fields, commandText = 'jpath=$') {
      this.reportDefinition.DataSets = this.reportDefinition.DataSets || [];
      const dataset = {
        Name: datasetName,
        Fields: fields.map((field) => {
          if (typeof field === 'string') {
            return {
              Name: field,
              DataField: field,
            };
          }
          return field;
        }),
        Query: {
          DataSourceName: datasourceName,
          CommandText: commandText,
        },
        CaseSensitivity: 'Auto',
        KanatypeSensitivity: 'Auto',
        AccentSensitivity: 'Auto',
        WidthSensitivity: 'Auto',
      };

      if (!commandText) {
        delete dataset.Query.CommandText;
      }
      this.reportDefinition.DataSets.push(dataset);
    },
    onClose() {
      this.$emit('close');
    },
    onEdit() {
      this.editReport = true;
    },
    async onPreviewReport() {
      const reportInfo = await this.$refs.reportDesigner.getReport();
      this.reportDefinition = reportInfo.definition;
      this.editReport = false;
    },
    async onDownloadReport() {
      const reportInfo = await this.$refs.reportDesigner.getReport();
      const saveDefinition = reportInfo.definition;
      const definitionString = JSON.stringify(saveDefinition);
      AppUtils.downloadText(`${this.reportName}.rdlx-json`, definitionString);
    },
    onUploadReport() {
      this.$refs.fileUploader.click();
    },
    onFileSelect(event) {
      const fileList = event.target.files;
      const that = this;
      if (fileList.length) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const parsedReport = JSON.parse(e.target.result);
          that.$set(that, 'reportDefinition', parsedReport);
        };
        reader.readAsText(fileList[0]);
      }
    },
    onDeleteReport() {
      this.$confirm({
        title: 'Atenção',
        message: `Deseja realmente apagar o relatório '${this.reportName}'?`,
        confirmText: 'Sim',
        cancelText: 'Não',
      }).then(() => {
        const reportId = this.report.id;
        this.$api.Reports.delete(reportId);
        this.$emit('close');
      });
    },
    async onSaveReport() {
      const reportInfo = await this.$refs.reportDesigner.getReport();
      const saveDefinition = reportInfo.definition;

      // Clean Data
      const dss = saveDefinition.DataSources || [];
      const defaultDs = dss.find((ds) => ds.Name === 'DefaultDataSource');
      if (defaultDs) {
        defaultDs.ConnectionProperties.ConnectString = `jsondata=${JSON.stringify({})}`;
      }
      // Clean emebeded Images
      delete saveDefinition.EmbeddedImages;

      const definitionString = JSON.stringify(saveDefinition);

      const payload = {
        report: {
          name: this.reportName,
          route: this.report.route ?? router.currentRoute.name,
          definition: definitionString,
          draft: this.isDraft,
        },
      };
      const reportId = this.report.id;
      if (reportId) {
        await this.$api.Reports.update({ id: reportId, data: payload });
      } else {
        const response = await this.$api.Reports.create({ data: payload });
        this.report = response.data;
      }
    },
  },
};
</script>

<style lang="scss">
  .reportDialog {
    min-height: calc(100vh - 150px);
    height: calc(100vh - 150px);
  }
  .card-scroll {
  position: relative;
  overflow-y: clip;
  }
</style>
