import * as AppUtils from '@plugins/app-utils';
import Config from '@src/core/config';
import DynamicService from '@sharedServices/dynamic/DynamicService';

const BtgIntegrationBillets = DynamicService('btg/billets', {
  // formatRequest,
  // formatResponse,
  // baseURL: config.API_GATEWAY_FINANCIAL,
});

const BtgIntegrationCommands = ($axios) => ({
  async Authorization() {
    const redirectURI = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/integrations/btg/auth`;
    const queryParams = `client_id=${Config.BTG_APP_CLIENT_ID}&response_type=code&redirect_uri=${redirectURI}&scope=${Config.BTG_APP_CLIENT_SCOPE}&prompt=login%20consent`;
    const response = `${Config.BTG_APP_AUTH_URI}/oauth2/authorize?${queryParams}`;
    return response;
  },
  async AccessToken(payload) {
    const response = await $axios.post(`${Config.BTG_APP_AUTH_URI}/oauth2/token`, payload);
    return response;
  },
  async SendAuthorizationCode(payload) {
    const authorization_uri = `${Config.API_ROOT}/btg/auth_callback`;
    const response = await $axios.post(authorization_uri, payload);
    return response;
  },
  async getAccounts() {
    const authorization_uri = `${Config.API_ROOT}/btg/accounts`;
    const res = await $axios.get(authorization_uri);
    const resFormatted = res.data.map((item) => ({
      ...item,
      currency_formatted: AppUtils.getOptionText('btg_account_currency', item?.currency),
      type_formatted: AppUtils.getOptionText('btg_account_type', item?.type),
      status_formatted: AppUtils.getOptionText('btg_account_status', item?.status),
      balance: {
        ...item.balance,
        available_formatted: AppUtils.formatCurrency(item.balance.available, 'pt-BR', item.currency),
        blocked_formatted: AppUtils.formatCurrency(item.balance.blocked, 'pt-BR', item.currency),
      },
      transactions: item.transactions.map((transaction) => ({
        ...transaction,
        amount_formatted: AppUtils.formatCurrency(transaction.amount, 'pt-BR', transaction.currency),
        credit_debit_indicator_formatted: AppUtils.getOptionText('btg_account_transaction_operation', transaction?.credit_debit_indicator),
        currency_formatted: AppUtils.getOptionText('btg_account_currency', item?.currency),
        date_formatted: AppUtils.formatDate(transaction?.date),
        type_formatted: AppUtils.getOptionText('btg_account_transaction_type', transaction?.type),
      })),
    }));

    return resFormatted;
  },
  async getAccountStatements() {
    const authorization_uri = `${Config.API_ROOT}/btg/accounts`;
    const res = await $axios.get(authorization_uri);

    return res;
  },
  async formatBillets(billets) {
    return billets.forEach((billet) => {
      billet.due_date_formatted = AppUtils.formatDate(billet.due_date);
    });
  },
  async getBillets() {
    const billets_uri = `${Config.API_ROOT}/btg/billets`;
    const res = await $axios.get(billets_uri);
    return this.formatBillets(res);
  },
  async createTransfer(payload) {
    const transfers_uri = `${Config.API_ROOT}/btg/transfers`;

    const res = await $axios.post(transfers_uri, payload);
    return res;
  },
  async getTransferReceipt(installmentId) {
    const transfers_uri = `${Config.API_ROOT}/btg/transfers`;

    const res = $axios.get(`${transfers_uri}/receipt/${installmentId}.json`);
    return res;
  },
  async getBilletReceipt(installmentId) {
    const billet_uri = `${Config.API_ROOT}/btg/billets`;

    const res = $axios.get(`${billet_uri}/pdf/${installmentId}.json`);
    return res;
  },

});

export { BtgIntegrationBillets, BtgIntegrationCommands };
