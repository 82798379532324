import AccountPaymentRoutes from './pages/payments/routes';
import AnticipationRoutes from './pages/anticipation/routes';
import BanksRoutes from './pages/banks/routes';
import BilletsRoutes from './pages/billets/routes';
import CashFlowsRoutes from './pages/cashflows/routes';
import OpenTitlesRoutes from './pages/opentitles/routes';
/*
  @author: alvz
  Route Documentation:
    Look into route tree, note that nested routes are inside parent's scope.
    To change, apply this pattern to future routes.
  Example:
  "financial-opentitles-payments"
    module -  domain  - action

    */
export default [
  ...AccountPaymentRoutes,
  ...AnticipationRoutes,
  ...BanksRoutes,
  ...BilletsRoutes,
  ...CashFlowsRoutes,
  ...OpenTitlesRoutes,
];
