import ProductCompositionItem from './ProductCompositionItem';
import ProductCompositionBusiness from '@businesses/ProductComposition';
import ProductCompositionFactoryItem from './ProductCompositionFactoryItem';

import ProductSchema from './Product';

export default {
  service: 'Compositions',
  business: ProductCompositionBusiness,
  icon: ProductSchema.icon,
  routes: {
    list: {
      name: 'supply-products-list',
    },
    edit: {
      path: '/supply/products/composition/:id',
      name: 'supply-products-editcomposition',
    },
  },
  titles: {
    item: 'Composição do Produto',
    items: 'Composições dos Produtos',
    edit: 'Editar Composição do Produto',
    create: 'Criar Composição do Produto',
    new: 'Novo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'products/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [

  ],
  childSchemas: [
    {
      label: 'Matéria-Prima',
      name: 'compositions',
      schema: ProductCompositionItem,
      list: true,
    },
    {
      label: 'Procedimento de Fabricação',
      name: 'procedures',
      schema: ProductCompositionFactoryItem,
      list: true,
    },
  ],
};
