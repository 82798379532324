import DynamicService from '@sharedServices/dynamic/DynamicService';

const endpoint = 'all_types';

const formatResponse = (data) => {
  const translateBoolean = (boolen) => (boolen === true ? 'Sim' : 'Não');

  if (Array.isArray(data)) {
    data.forEach((item) => {
      item.disable_formatted = translateBoolean(item.disable);
      item.use_nickname_formatted = translateBoolean(item.use_nickname);
      item.ncm_id = item.id;
    });
  } else {
    data.disable_formatted = translateBoolean(data.disable);
    data.use_nickname_formatted = translateBoolean(data.use_nickname);
    data.ncm_id = data.id;
  }
  return { data };
};

const getPaginator = (response) => ({
  page: response.data?.page ?? response.headers['icertusweb-current-page'],
  per_page: response.data?.per_page ?? response.headers['icertusweb-items-per-page'],
  total_items: response.data?.total_items ?? response.headers['icertusweb-total-items'],
  total_pages: response.data?.total_pages ?? response.headers['icertusweb-total-pages'],
});

const AllTypesCommands = ($axios) => ({

  async getManyByType(type, excludeDisables = false, paginationParams = false) {
    let response = {};
    let paginationQuery = '';

    if (paginationParams) {
      paginationQuery = `&page=${paginationParams.page}&per_page=${paginationParams.per_page}`;
    }

    if (excludeDisables) {
      response = await $axios.get(`${endpoint}.json?type_all_type=${type}&disable=false${paginationQuery}`);
    } else {
      response = await $axios.get(`${endpoint}.json?type_all_type=${type}${paginationQuery}`);
    }
    const { data } = formatResponse(response.data);
    const paginator = getPaginator(response);

    return { data, paginator };
  },

  async getManyByTypeExcludeDisables(type) {
    const response = await $axios.get(`${endpoint}.json?type_all_type=${type}&disable=false`);
    const { data } = formatResponse(response.data);
    const paginator = getPaginator(response);

    return { data, paginator };
  },
});

const AllTypes = DynamicService(endpoint, {
  formatResponse,
  sendCustomPayload: true,
  useDirectData: true,
});

const Countries = DynamicService('countries', {
  sendCustomPayload: true,
});

export { AllTypes, AllTypesCommands, Countries };
export default { AllTypes, AllTypesCommands, Countries };
