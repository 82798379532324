import AlltypesSchema from '@schemas/Alltypes';

export default [
  {
    path: AlltypesSchema.routes.addressTypes.list.path,
    name: AlltypesSchema.routes.addressTypes.list.name,
    meta: AlltypesSchema.routes.addressTypes.list.meta,
    component: () => import(/* webpackChunkName: 'list-address-types' */ `${AlltypesSchema.routes.addressTypes.list.component}`),
  },
  {
    path: AlltypesSchema.routes.addressTypes.create.path,
    name: AlltypesSchema.routes.addressTypes.create.name,
    meta: AlltypesSchema.routes.addressTypes.create.meta,
    component: () => import(/* webpackChunkName: 'create-address-types' */ `${AlltypesSchema.routes.addressTypes.create.component}`),
  },
  {
    path: AlltypesSchema.routes.addressTypes.edit.path,
    name: AlltypesSchema.routes.addressTypes.edit.name,
    meta: AlltypesSchema.routes.addressTypes.edit.meta,
    component: () => import(/* webpackChunkName: 'edite-address-types' */ `${AlltypesSchema.routes.addressTypes.edit.component}`),
  },
];
