import AssignorSchema from '@schemas/Assignor';

const ListAssignors = () => import(/* webpackChunkName: "assignors-list" */'./ListAssignors');
const DetailsAssignor = () => import(/* webpackChunkName: "assignors-detailsassignors-details" */'./DetailsAssignor');

export default [
  {
    path: AssignorSchema.routes.list.path,
    name: AssignorSchema.routes.list.name,
    meta: {
      requiresAuth: false,
      antecipation: true,
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Antecipação de Recebíveis' },
        { name: 'Cedentes' },
      ],
    },
    component: ListAssignors,
  },
  {
    path: AssignorSchema.routes.show.path,
    name: AssignorSchema.routes.show.name,
    meta: {
      requiresAuth: false,
      antecipation: true,
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Antecipação de Recebíveis' },
        { name: 'Detalhes do Cedente' },
      ],
    },
    component: DetailsAssignor,
  },
];
