import config from '@src/core/config';

function formatRequest(payload) {
  return {
    period: payload.period,
    start_date: payload.start_date.split('/').reverse().join('-'),
    end_date: payload.end_date.split('/').reverse().join('-'),
  };
}

function formatRequestBank(payload) {
  return {
    current_year: payload.current_year,
    bank_ids: payload.bank_ids,
    use_inactive: payload.use_inactive,
    period: payload.period,
    start_date: payload.start_date.split('/').reverse().join('-'),
    end_date: payload.end_date.split('/').reverse().join('-'),
  };
}
export default ($axios) => ({
  async stockItems(payload) {
    return $axios.post(`${config.API_GATEWAY_CHARTS}/stocks/item`, payload);
  },
  async accountsComparative(payload) {
    const payloadFormatted = formatRequest(payload);
    const response = await $axios.post(`${config.API_GATEWAY_CHARTS}/accounts/period`, payloadFormatted);
    return response || {};
  },
  async accountsSalesPurchase(payload) {
    const payloadFormatted = formatRequest(payload);
    const response = await $axios.post(`${config.API_GATEWAY_CHARTS}/orders/period`, payloadFormatted);
    return response || {};
  },
  async openTitlesPaymentsReceivable(payload) {
    const payloadFormatted = formatRequest(payload);
    const response = await $axios.post(`${config.API_GATEWAY_CHARTS}/open_titles/period`, payloadFormatted);
    return response || {};
  },
  async buyersQuantity(payload) {
    const payloadFormatted = formatRequest(payload);
    const response = await $axios.post(`${config.API_GATEWAY_CHARTS}/sales/buyer/period`, payloadFormatted);
    return response || {};
  },
  async salesmanQuantity(payload) {
    const payloadFormatted = formatRequest(payload);
    const response = await $axios.post(`${config.API_GATEWAY_CHARTS}/sales/vendor/period`, payloadFormatted);
    return response || {};
  },
  async launchsBank(payload) {
    const payloadFormatted = formatRequestBank(payload);
    const response = await $axios.post(`${config.API_GATEWAY_CHARTS}/banks/launch/period`, payloadFormatted);
    return response || {};
  },
  async productsQuantity(payload) {
    const payloadFormatted = formatRequest(payload);
    const response = await $axios.post(`${config.API_GATEWAY_CHARTS}/sales/product/period`, payloadFormatted);
    return response || {};
  },
});
