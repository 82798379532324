import { MASK_CEP } from '@constants/masks';
import { OPTIONS_LOAD_TYPE } from '@constants/options';

export default [
  // Line 1
  {
    label: 'Tipo de Carga *',
    name: 'cargo_type',
    prependIcon: '',
    colClass: 'nf-field top left bottom ma-0',
    component: 'dynamic-select',
    items: OPTIONS_LOAD_TYPE,
    type: 'text',
    required: true,
    md: 4,
  },
  {
    label: 'CEP de Carregamento *',
    type: 'number',
    mask: MASK_CEP,
    name: 'load_cep',
    prependIcon: '',
    colClass: 'nf-field top left bottom right ma-0',
    component: 'dynamic-text-field',
    required: true,
    md: 4,
  },
  {
    label: 'CEP de descarregamento *',
    type: 'number',
    mask: MASK_CEP,
    name: 'unload_cep',
    prependIcon: '',
    colClass: 'nf-field top bottom right ma-0',
    component: 'dynamic-text-field',
    required: true,
    md: 4,
  },
  {
    label: 'Descrição do Produto Predominante *',
    type: 'text',
    name: 'product_description',
    prependIcon: '',
    colClass: 'nf-field right left bottom',
    component: 'dynamic-text-field',
    required: true,
    md: 8,
  },
  {
    label: 'Produto',
    name: 'product_id',
    formattedName: 'product_name',
    type: 'number',
    component: 'dynamic-autocomplete',
    colClass: 'nf-field right bottom',
    dataSource: {
      service: 'Products',
      method: 'getByFilter',
      props: [{ use_inactive_eq: false }, 'name asc'],
    },
    itemText: 'to_s',
    itemValue: 'id',
    required: true,
    md: 4,
  },
];
