<template>
  <v-textarea
    v-if="!readOnly && !field.readOnly"
    v-model="fieldValue"
    :required="checkRequired()"
    :prefix="field.prefix"
    :counter="maxLength"
    :maxlength="maxLength"
    :suffix="field.suffix"
    :multi-line="field.multiLine"
    :hint="field.hint"
    :persistent-hint="true"
    :disabled="checkDisabled()"
    :rules="validationRules"
    :label="field.label"
    :class="field.class"
    :auto-grow="field.autoGrow"
    :cleareable="field.cleareable"
    :clear-icon="icons.close"
    :rounded="field.rounded"
    :filled="field.filled"
    :prepend-icon="innerIcon ? '' : prependIcon"
    :prepend-inner-icon="innerIcon ? prependIcon : ''"
    v-bind="$attrs"
    v-on="$listeners"
    @change="fieldChanged"
    @keyup.enter.native.stop />
  <div v-else class="dm-information">
    <label>{{ field.label }}</label>
    <p>{{ fieldValue }}</p>
  </div>
</template>

<script>
import { ICON_DESCRIPTION, ICON_CLOSE } from '@constants/icons';
import DynamicFieldMixin from './DynamicFieldMixin';
import { slugify } from '@plugins/app-utils';

export default {
  name: 'DynamicTextArea',

  mixins: [DynamicFieldMixin],

  data() {
    return {
      fieldValue: '',
      icons: {
        close: ICON_CLOSE,
        default: ICON_DESCRIPTION,
      },
      emptyValue: '',
    };
  },
  computed: {
    maxLength() {
      if (this.field.maxLength && String(this.field.maxLength) !== '0') {
        return parseInt(this.field.maxLength);
      }

      return undefined;
    },
    prependIcon() {
      return this.field.prependIcon === undefined ? this.icons.default : this.field.prependIcon;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
  },
  methods: {
    onBlurTextField() {
      if (this.field.slug) {
        this.fieldValue = slugify(this.fieldValue);
        if (this.maxLength) {
          this.fieldValue = this.fieldValue.substr(0, this.maxLength);
        }
        this.fieldChanged(this.fieldValue);
      }
    },
  },
};
</script>

<style lang="scss"></style>
