import { ICON_ACCOUNT, ICON_DESCRIPTION } from '@constants/icons';
import UserBusiness from '@businesses/User';
import tabDetail from '@modules/settings/pages/users/components/TabDetail';

export default {
  service: 'Users',
  business: UserBusiness,
  icon: ICON_ACCOUNT,
  routes: {
    list: 'settings-users-list',
    edit: 'settings-users-edit',
  },
  titles: {
    item: 'Usuário',
    items: 'Usuários',
    create: 'Criar Usuário',
    edit: 'Editar Usuário',
    new: 'Novo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'users/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      // label: 'Image',
      name: 'image',
      component: 'dynamic-image',
      type: 'image',
      list: true,
    },
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 12,
    },
    /* @TODO - Commented to Sebrae Launch
    {
      label: 'Banco padrão p/ usuário',
      name: 'bank_id',
      formattedName: 'bank_id.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Banks',
        method: 'index',
      },
      itemText: 'description',
      itemValue: 'id',
      md: 4,
    },
    */
    {
      label: 'Tipo',
      name: 'type_user',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Setor',
      name: 'sector_id',
      formattedName: 'sector_id.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['user_sector'],
      },
      itemText: 'description',
      itemValue: 'id',
      md: 4,
    },
    {
      label: 'Inativo',
      name: 'is_inactive',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    {
      label: 'Administrador',
      name: 'is_admin',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    /* @TODO - Commented to Sebrae Launch
    {
      label: 'Acesso:',
      component: 'dynamic-divider',
      md: 12,
    },
    {
      label: 'Empresas',
      name: 'shareds',
      type: 'array',
      component: 'dynamic-data-list-select',
      service: 'Companies',
      relatedIdField: 'company_id',
      formattedField: 'name',
      headers: [{
        text: 'CNPJ / CPF',
        value: 'cnpj',
      },
      {
        text: 'Nome/Empresa',
        value: 'name',
      }],
      md: 3,
    },
    {
      label: 'Email',
      name: 'email',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 3,
    },
    {
      label: 'Senha',
      name: 'password',
      type: 'text',
      list: true,
      component: 'dynamic-input-password',
      required: true,
      md: 3,
    },
    {
      label: 'Confirmar Senha',
      name: 'confirme_password',
      type: 'text',
      list: true,
      component: 'dynamic-input-password',
      required: true,
      md: 3,
    },
    */
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: tabDetail,
    },
  ],
};
