import GeneralAccountPaymentsSchema from './GeneralAccountPaymentReportsSchema';

const GeneralAccountPayments = () => import(/* webpackChunkName: "reports-invoices-general" */'./GeneralAccountPaymentsReports');
export const AccountPaymentRoutes = [
  {
    path: GeneralAccountPaymentsSchema.route.path,
    name: GeneralAccountPaymentsSchema.route.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Relatórios' },
        { name: 'Contas a Pagar' },
      ],
    },
    component: GeneralAccountPayments,
  },
];
