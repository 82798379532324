import { OPTIONS_UF } from '@constants/options';
import { ICON_FLAG_VARIANT } from '@constants/icons';

export default {
  icon: ICON_FLAG_VARIANT,
  titles: {
    item: 'Estado de Percurso',
    items: 'Estados de Percurso',
    edit: 'Editar Estado de Percurso',
    new: 'Novo Estado de Percurso',
    create: 'Novo Estado de Percurso',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'UF',
      name: 'uf',
      type: 'text',
      component: 'dynamic-select',
      items: OPTIONS_UF,
      cols: 12,
      list: true,
    },
  ],
};
