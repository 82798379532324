import * as AppUtils from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';

const endpoint = 'stocks';
// @TODO Removes when backend returns to_s in graphQL
const formatResponse = (stock) => {
  stock.id = parseInt(stock.id);
  stock.use_main_formatted = stock.use_main === true ? 'Sim' : 'Não';
  if (stock.histories) {
    stock.histories.forEach((element) => {
      element.origin_formatted = `${element.origin.to_s} - ${element.origin.id}`;
      element.origin_status = element.origin.status;
      element.description_formatted = `${element.product.code} - ${element.product.to_s}`;
      element.date_launch_formatted = element.date_launch !== null ? AppUtils.formatDate(element.date_launch) : '';
    });
  }

  stock.date_launch_formatted = stock.date_launch !== null ? AppUtils.formatDate(stock.date_launch) : '';

  return stock;
};

const getPaginator = (response) => ({
  page: response.data?.page ?? response.headers['icertusweb-current-page'],
  per_page: response.data?.per_page ?? response.headers['icertusweb-items-per-page'],
  total_items: response.data?.total_items ?? response.headers['icertusweb-total-items'],
  total_pages: response.data?.total_pages ?? response.headers['icertusweb-total-pages'],
});

function originFormatted(item) {
  const { origin, sale_number, origin_number } = item;
  const to_s = AppUtils.getOptionText('history_origin_name', origin?.name) ?? origin?.to_s;

  if (origin_number) {
    if (item?.status === 'deleted') {
      return `${to_s} - ${origin_number} - Cancelado/Excluído`;
    }
    if (item?.status === 'canceled') {
      return `${to_s} - ${origin_number} - Cancelado`;
    }
    if (item?.status === 'delivered') {
      return `${to_s} - ${origin_number} - Entregue`;
    }
  }

  if (!origin.to_s) {
    return `${to_s} - Cancelado / Excluído`;
  }
  if (!sale_number) {
    return to_s;
  }

  return `${to_s} - ${sale_number}`;
}

const action = 'stock_histories';
const StockHistories = ($axios) => ({
  formatResponse(data) {
    if (!data && !data.length) return [];

    return data.map((item) => ({
      ...item,
      date_launch_formatted: item.date_launch !== null ? AppUtils.formatDate(item.date_launch) : '',
      type_launch_formatted: item.type_launch === 'automatic' ? 'Automático' : AppUtils.getOptionText('launch_stocks', item.type_launch),
      origin_formatted: originFormatted(item),
      description_formatted: `${item.product.code} - ${item.product.to_s}`,
    }));
  },

  /** get all objects */
  async index(stockId) {
    let responseData = {};
    await $axios.get(`${endpoint}/${stockId}/${action}.json`).then((response) => {
      responseData = this.formatResponse(response.data);
    });
    return responseData;
  },

  async indexPaginable(params) {
    const {
      optionsPaginable,
      stockId,
    } = params;
    const payloadPaginable = `?page=${optionsPaginable.page}&per_page=${optionsPaginable.per_page}`;
    let responseData = {};
    let paginator = {};
    await $axios.get(`${endpoint}/${stockId}/${action}.json${payloadPaginable}`).then((response) => {
      paginator = getPaginator(response);
      responseData = response.data;
    });
    return { data: [...responseData], paginator };
  },

  /** show on object with id */
  async show(payload) {
    const { stockId, id } = payload;
    return $axios.get(`${endpoint}/${stockId}/${action}/${id}.json`);
  },
  /** create one object */
  create(payload) {
    const { stockId, data } = payload;
    return $axios.post(`${endpoint}/${stockId}/${action}.json`, data);
  },
  /** update one object */
  update(id, payload) {
    const { stockId } = payload;
    return $axios.put(`${endpoint}/${stockId}/${action}/${id}.json`, payload);
  },
  /** delete one object */
  delete(id, payload) {
    const { stockId } = payload;
    return $axios.delete(`${endpoint}/${stockId}/${action}/${id}.json`);
  },
  /** cancel one object */
  cancel(id, payload) {
    const { stockId } = payload;
    return $axios.post(`${endpoint}/${stockId}/${action}/${id}/canceled.json`);
  },
  /**
   * Get all objects by filters
   * */
  async getByFilter(filters, order = 'created_at desc') {
    const payload = {
      q: filters || {},
    };
    if (order) {
      payload.q.s = order;
    }

    let responseData = {};
    await $axios.post(`${endpoint}/filters.json`, payload)
      .then((response) => {
        responseData = formatResponse(response.data);
      });
    return responseData;
  },

  async getHistoriesByFilter(stockId, filters, order = 'created_at desc') {
    const { q, optionsPaginable } = filters;
    const payload = {
      q: q || {},
    };
    if (order) {
      payload.q.s = order;
    }
    const payloadPaginable = `?page=${optionsPaginable.page}&per_page=${optionsPaginable.per_page}`;

    let responseData = {};
    let paginator = {};
    const postUrl = `${endpoint}/${stockId}/${action}/filters.json${payloadPaginable}`;
    await $axios.post(postUrl, payload).then((response) => {
      responseData = response.data;
      paginator = getPaginator(response);
    });
    return { data: [...responseData], paginator };
  },
});

const actionItems = 'stock_items';
const StockItems = ($axios) => {
  const requestFormat = (data) => {
    if (Array.isArray(data)) {
      const formattedData = data.forEach((item) => {
        delete item.to_s;
        delete item.use_lot;
        delete item.quantity_reserved;
        delete item.quantity_total;
        delete item.stock;
        delete item.product;
        delete item.use_inactive;

        item.quantity_available = Number(item.quantity_available);

        return formattedData;
      });
    }

    delete data.to_s;
    delete data.use_lot;
    delete data.quantity_reserved;
    delete data.quantity_total;
    delete data.stock;
    delete data.product;
    delete data.use_inactive;

    data.quantity_available = Number(data.quantity_available);
    return data;
  };

  /** get all objects */
  async function index(stockId) {
    let responseData = {};
    await $axios.get(`${endpoint}/${stockId}/${actionItems}.json`).then((response) => {
      responseData = response.data;
    });
    return responseData;
  }

  async function indexPaginable(params) {
    const {
      optionsPaginable,
      stockId,
    } = params;
    const payloadPaginable = `?page=${optionsPaginable.page}&per_page=${optionsPaginable.per_page}`;
    let responseData = {};
    let paginator = {};
    await $axios.get(`${endpoint}/${stockId}/${actionItems}.json${payloadPaginable}`).then((response) => {
      const { headers } = response;
      paginator = {
        page: response.data?.page ?? headers['icertusweb-current-page'],
        per_page: response.data?.per_page ?? headers['icertusweb-items-per-page'],
        total_items: response.data?.total_items ?? headers['icertusweb-total-items'],
        total_pages: response.data?.total_pages ?? headers['icertusweb-total-pages'],
      };
      responseData = response.data;
    });
    return { data: [...responseData], paginator };
  }

  /** create one object */
  function create(payload) {
    const { stockId, data } = payload;
    const requestDataFormatted = requestFormat(data);
    return $axios.put(`${endpoint}/${stockId}/${actionItems}.json`, { items_attributes: requestDataFormatted });
  }
  /** create one object */
  function update(payload) {
    const { stockId, data } = payload;
    const requestDataFormatted = requestFormat(data);
    return $axios.put(`${endpoint}/${stockId}/${actionItems}/${data.id}.json`, { stock_item: requestDataFormatted });
  }
  /**
 * Get all objects by filters
 * */
  async function getByFilter(filters, order = 'created_at desc', filterUrl) {
    const payload = {
      q: filters || {},
    };
    if (order) {
      payload.q.s = order;
    }
    if (!filterUrl) {
      filterUrl = `${endpoint}/filters`;
    }
    let responseData = {};
    await $axios.post(`${filterUrl}.json`, payload)
      .then((response) => {
        responseData = response;
      });
    return responseData;
  }

  async function uploadStockItemsFile(payload) {
    const res = await $axios.post(`${endpoint}/import.json`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  }

  return {
    index,
    indexPaginable,
    create,
    update,
    getByFilter,
    uploadStockItemsFile,
  };
};

const actionSimpleItems = 'stock_items_simplified';
const StockItemsSimplified = ($axios) => {
  /** get all objects */
  async function index(stockId) {
    let responseData = {};
    await $axios.get(`${endpoint}/${stockId}/${actionSimpleItems}.json?active=true`).then((response) => {
      responseData = response.data;
    });
    return responseData;
  }
  return { index };
};

const Stocks = DynamicService(endpoint, {
  formatResponse,
});

const StocksSimplified = DynamicService('stocks_simplified', {
  formatResponse,
});

export default {
  Stocks, StocksSimplified, StockHistories, StockItems, StockItemsSimplified,
};
export {
  Stocks, StocksSimplified, StockHistories, StockItems, StockItemsSimplified,
};
