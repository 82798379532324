import Vue from 'vue';

function setTotals(formValue) {
  const formattedformValue = Vue.prototype.$api.Compositions.options.formatResponse(formValue, true);
  formValue = formattedformValue;
}

export default {
  screenEvents: {},
  fieldEvents: {
    stock_id: {
      change: async (formValue) => {
        const stockResponse = await Vue.prototype.$api.Stocks.show(formValue.stock_id);
        formValue.stock = stockResponse.data;
      },
    },
    compositions: {
      change: (formValue) => {
        setTotals(formValue);
      },
      beforeCreate: async (formValue) => {
        if (!formValue.product_id) return 'Não é possível adicionar ítens sem selecionar um produto';
        setTotals(formValue);
        return {
          mutateChildSchema: {
            fields: [
              {
                name: 'product_id',
                dataSource: {
                  service: 'Products',
                  method: 'getByFilterGraphql',
                  props: [{ use_inactive_eq: false, id_not_eq: formValue.product_id, query: ['id', 'code', 'to_s'] }, 'name asc'],
                },
              },
              {
                name: 'product_code',
                dataSource: {
                  service: 'Products',
                  method: 'getByFilterGraphql',
                  props: [{ use_inactive_eq: false, id_not_eq: formValue.product_id, query: ['id', 'code', 'to_s'] }, 'name asc'],
                },
              },
            ],
          },
        };
      },
      beforeUpdate: async (formValue) => {
        setTotals(formValue);
        return {
          mutateChildSchema: {
            fields: [
              {
                name: 'product_id',
                dataSource: {
                  service: 'Products',
                  method: 'getByFilterGraphql',
                  props: [{ use_inactive_eq: false, id_not_eq: formValue.product_id, query: ['id', 'code', 'to_s'] }, 'name asc'],
                },
              },
              {
                name: 'product_code',
                dataSource: {
                  service: 'Products',
                  method: 'getByFilterGraphql',
                  props: [{ use_inactive_eq: false, id_not_eq: formValue.product_id, query: ['id', 'code', 'to_s'] }, 'name asc'],
                },
              },
            ],
          },
        };
      },
    },
    procedures: {
      beforeCreate: async (formValue, newItem) => {
        if (!formValue.product_id) return 'Não é possível adicionar ítens sem selecionar um produto';
        const proceduresLength = formValue.procedures.filter((item) => !item._destroy).length;
        newItem.step = proceduresLength + 1;
      },
      beforeUpdate: async (formValue) => {
        Vue.set(formValue, 'procedures', formValue.procedures);
      },
      change: async (formValue) => {
        let counter = 1;
        const procedures = formValue.procedures.map((procedure) => {
          const currentStep = procedure?._destroy ? 0 : counter;
          if (currentStep !== 0) counter += 1;
          return {
            ...procedure,
            step: currentStep,
          };
        });
        Vue.set(formValue, 'procedures', procedures);
        setTotals(formValue);
      },
    },
  },
};
