export function required() {
  return (value) => !!value || 'Esse campo é obrigatório.';
}

export function equal(chars) {
  return (v) => (v && v.length === chars ? true : `${chars} caracteres`);
}
export function min(chars) {
  return (v) => (v && v.length >= chars ? true : `Mínimo ${chars} caracteres`);
}

export function max(chars) {
  return (v) => (v && v.length <= chars ? true : `Máximo ${chars} caracteres`);
}

export function minSelect(items) {
  return (v) => (v && v.length >= items ? true : `Escolha no mínimo ${items} ${items > 1 ? 'itens' : 'item'}`);
}

export function minValue(minNumber = 0) {
  return (value) => (value && parseFloat(value.replace('.', '').replace(',', '.')) > minNumber ? true : `O valor deve ser maior que ${minNumber}`);
}

export function minOrEqualValue(minNumber = 0) {
  return (value) => (value && parseFloat(value.replace('.', '').replace(',', '.')) >= minNumber ? true : `O valor deve ser maior ou igual a ${minNumber}`);
}

export function maxValue(maxNumber) {
  return (value) => (value && parseFloat(value.replace('.', '').replace(',', '.')) < maxNumber ? true : `O valor deve ser menor que ${maxNumber}`);
}

export function maxOrEqualValue(maxNumber) {
  return (value) => (value && parseFloat(value.replace('.', '').replace(',', '.')) < maxNumber ? true : `O valor deve ser menor ou igual a ${maxNumber}`);
}

export function testPattern(pattern, error = false) {
  return (v) => (v && pattern.test(v) ? true : error);
}

export function onlyNumbers() {
  const pattern = /[0-9]$/;
  return testPattern(pattern, 'Entrada inválida, digite somente números.');
}

export function email() {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return testPattern(pattern, 'E-mail inválido.');
}

export function telephone() {
  const pattern = /^\\(((1[1-9])|([2-9][0-9]))\\)((3[0-9]{3}-[0-9]{4})|(9[0-9]{3}-[0-9]{5}))$/;
  return testPattern(pattern, 'Número inválido.');
}

export function phone() {
  const pattern = /^\s*[(. ]?(\d{2}|\d{0})[). ][-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/;
  return testPattern(pattern, 'Número inválido.');
}

export function fullName() {
  const pattern = /^(?![ ])(?!.*[ ]{2})((?:e|da|do|das|dos|de|d'|D'|la|las|el|los)\s*?|(?:[A-Z][^\s]*\s*?)(?!.*[ ]$))/;
  return testPattern(
    pattern,
    "Formato de nome inválido (o primeiro nome deve começar com letra maiúscula, se o nome conter um pronome (e|da|do|das|dos|de|d'|D'|la|las|el|los) é necessário um segundo nome.",
  );
}

export function cep() {
  const pattern = /[0-9]{5}[-]?[\d]{3}/;
  return testPattern(pattern, 'Cep Inválido.');
}

export function cpfCnpj() {
  // eslint-disable-next-line max-len
  const pattern = /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9][.]?[0-9][.]?[0-9]{3}[-]?[0-9]{2})/;
  return testPattern(pattern, 'CPF ou CNPJ inválido');
}

export function cpf() {
  // eslint-disable-next-line max-len
  const pattern = /([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/;
  return testPattern(pattern, 'CPF inválido');
}

export function cnpj() {
  // eslint-disable-next-line max-len
  const pattern = /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})/;
  return testPattern(pattern, 'CNPJ inválido');
}

export function ie() {
  const pattern = /^\d{8,14}$/;
  return testPattern(pattern, 'Inscrição Estadual inválida');
}

export function match(target, targetName, msgError = 'Os valores não conferem') {
  return (v) => (v && v === target ? true : msgError);
}

export function brCarPlate() {
  const pattern = /([A-Z]{3}[' '][0-9]{1}[0-9A-Z]{1}[0-9]{2})/;
  return testPattern(pattern, 'Formato de placa inválido, utilize letras maiúsculas e números.');
}

export function hours() {
  const pattern = /^[0-9]*:[0-5][0-9]/;
  return testPattern(pattern, 'Hora inválida');
}

export function password() {
  const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*();-]).{8,}/;
  return testPattern(pattern, 'Senha inválida, a senha deve conter: uma letra maiúscula, uma letra minúscula, um número e um caractere especial(#?!@$%^&*;-).');
}

export function barcode() {
  const pattern = /^$|^\d{8}$|^\d{12,14}$/;
  return testPattern(pattern, 'O codigo de barras pode conter 0, 8, 12, 13 ou 14 digitos');
}

export function lastWhiteSpace() {
  const pattern = /\s$/gm;
  const error = 'Remova o espaço em branco do final.';
  return (v) => (v && !pattern.test(v) ? true : error);
}
