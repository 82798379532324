import * as AppUtils from '@plugins/app-utils';

const endpoint = 'billets';

export default ($axios) => ({
  async remittance(data) {
    const params = this.formatRequestData(data);
    let responseData = {};
    await $axios.post(`${endpoint}/remittance.json`, params).then((response) => {
      responseData = response.data;
    });

    this.download('ARQ_REMESSA.txt', responseData);
  },
  async print(data) {
    const params = this.formatRequestData(data);
    return $axios.post(`${endpoint}/print.json`, params);
  },
  async sendFileReturn(params) {
    const responseData = {};

    const formData = new FormData();
    formData.append('billet_layout_id', params.billet_layout_id);
    formData.append('return_file', params.file);

    await $axios.post(`${endpoint}/return.json`, formData).then((response) => {
      responseData.data = {
        bank: response.data.bank,
        billets: this.formatResponseData(response.data.billets),
      };
    });

    return responseData;
  },
  formatResponseData(data, type = 'table') {
    const format = (item) => {
      item.emission_date = AppUtils.formatDate(item.emission_date);
      item.payment_date = AppUtils.formatDate(item.payment_date);
      item.compensation_date = AppUtils.formatDate(item.compensation_date);
      item.document_value_formatted = AppUtils.formatCurrency(item.document_value) || 'R$ 0,00';
      item.fine_value_formatted = AppUtils.formatCurrency(item.fine_value) || 'R$ 0,00';
      item.daily_fine_value_formatted = AppUtils.formatCurrency(item.daily_fine_value) || 'R$ 0,00';
      item.amount_charged_formatted = AppUtils.formatCurrency(item.amount_charged) || 'R$ 0,00';
      item.isSelected = false;

      if (item.origin) {
        item.origin.url = this.getParseDomain(item.origin.domain);
      }
    };

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });

      return data;
    }
    format(data);
    return data;
  },
  formatRequestData(data) {
    const request = {
      billets: [],
    };
    data.forEach((item) => {
      request.billets.push({
        id: item.id,
      });
    });
    return request;
  },
  getParseDomain(text) {
    if (!text) return '';
    return AppUtils.getDomainParse('account_receivable', text);
  },
  download(filename, text) {
    const pom = document.createElement('a');
    pom.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
    pom.setAttribute('download', filename);

    if (document.createEvent) {
      const event = document.createEvent('MouseEvents');
      event.initEvent('click', true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  },
});
