export const DefaultScreenEvents = {
  beforeSave(formValue) {
    formValue.items = formValue.items || [];
    // const visibleItems = formValue.items.filter((item) => !item._destroy);
    // if (!visibleItems.length) {
    //   return 'Não é possível gravar  sem ítens!';
    // }
    const action = formValue.id ? 'update' : 'create';

    if (action === 'update' && formValue.status === 'canceled') {
      return 'Não é possível alterar uma ordem cancelada.';
    }

    if (action === 'update' && formValue.status === 'finished') {
      return 'Não é possível alterar uma ordem finalizada.';
    }

    if (!formValue.status || formValue.status === 'initial') {
      formValue.status = 'pending';
    }

    if (formValue.is_enabled_end === true) {
      formValue.status = 'finished';
    }
  },
  beforeDelete(formValue) {
    if (formValue.status !== 'canceled') {
      return 'Primeiro efetue o cancelamento da ordem.';
    }
  },

};
