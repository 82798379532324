import Vue from 'vue';
import { setCbenef } from './Common/ItemCbenef';
import { formatCurrency, formatNumber } from '@plugins/app-utils';
/** *
 * Sample of Business Rules Layer
 * TODO: Documentar camada de negócios do front
 * TODO: implementar regras para lidar com childSchemas na camada de negócios
 * Available functions:
 *  - for simple fields: change
 *  - for arrays: beforeUpdate, beforeCreate, update, create, select
 */

const calcTotalAndAbsoluteDiscount = (formValue) => {
  const discount = parseFloat(formValue.discount_percentage_value ?? 0) / 100;
  const unit = parseFloat(formValue.unit_value) || 0;
  const quantity = parseFloat(formValue.comercial_quantity) || 0;
  Vue.set(formValue, 'unit_value', formValue.unit_value);
  Vue.set(formValue, 'discount', formValue.discount);
  Vue.set(formValue, 'comercial_quantity', formValue.comercial_quantity);

  const total = unit * quantity;
  const totalWithDiscount = total - total * discount;
  const discountValue = total - totalWithDiscount;

  Vue.set(formValue, 'discount_value', discountValue);
  Vue.set(formValue, 'total_without_discount_value', total);
  Vue.set(formValue, 'total_value', totalWithDiscount);

  Vue.set(formValue, 'discount_value_formatted', formatCurrency(parseFloat(discountValue) || 0));
  Vue.set(formValue, 'discount_percentage_value_formatted', `${formatNumber(parseFloat(formValue.discount_percentage_value) || 0)}%`);
  Vue.set(formValue, 'total_value_formatted', formatCurrency(parseFloat(totalWithDiscount) || 0));
};

const calcItemTaxes = (formValue) => {
  const icms = { value: 0, aliquot: 0, base: 0 };
  const icms_st = { value: 0, aliquot: 0, base: 0 };
  const ipi = { value: 0, aliquot: 0, base: 0 };
  const pis = { value: 0, aliquot: 0, base: 0 };
  const cofins = { value: 0, aliquot: 0, base: 0 };

  Vue.set(formValue, 'icms', icms);
  Vue.set(formValue, 'icms_st', icms_st);
  Vue.set(formValue, 'ipi', ipi);
  Vue.set(formValue, 'pis', pis);
  Vue.set(formValue, 'cofins', cofins);

  return {
    icms,
    icms_st,
    ipi,
    pis,
    cofins,
  };
};

const setUnity = async (formValue) => {
  if (formValue.unity_id) {
    const unity = await Vue.prototype.$api.AllTypes.show(formValue.unity_id);
    Vue.set(formValue, 'unity_name', unity.to_s);
    Vue.set(formValue, 'comercial_um', unity.nickname);
    Vue.set(formValue, 'tributary_um', unity.nickname);
  }
};

const setProductFields = (formValue, product) => {
  formValue.product = product;
  Vue.set(formValue, 'product', product);
  formValue.product_id = product.id;
  Vue.set(formValue, 'product_id', product.id);
  formValue.product_code = product.id;
  Vue.set(formValue, 'product_code', product.id);
  formValue.name = product.to_s;
  Vue.set(formValue, 'name', product.to_s);
  Vue.set(formValue, 'product_name', product.to_s);
  formValue.unity_id = product.unity_id;
  Vue.set(formValue, 'unity_id', product.unity_id);
  formValue.unit_cost_value = product.unit_cost_value;
  Vue.set(formValue, 'unit_cost_value', product.purchase_cost);
  formValue.unit_value = product.sale_value ?? 0;
  Vue.set(formValue, 'unit_value', formValue.unit_value);
  Vue.set(formValue, 'unit_value_formatted', formatCurrency(formValue.unit_value || 0));
  Vue.set(formValue, 'total_value', formValue.unit_value);
  Vue.set(formValue, 'total_value_formatted', formatCurrency(formValue.unit_value || 0));
  formValue.ncm_id = product.ncm_id;
  Vue.set(formValue, 'ncm_id', product.ncm_id);
  Vue.set(formValue, 'ncm_name', product.ncm_name);
  formValue.cest_id = product.cest_id;
  Vue.set(formValue, 'cest_id', product?.cest_id);
  Vue.set(formValue, 'cest_name', product?.cest_name);
  formValue.tributary_value = product.unit_value;
  Vue.set(formValue, 'tributary_value', formValue.unit_value);
  formValue.barcode = product.barcode;
  Vue.set(formValue, 'barcode', product.barcode);
  formValue.comercial_quantity = product.comercial_quantity ?? 1;
  Vue.set(formValue, 'comercial_quantity', formValue.comercial_quantity);
  Vue.set(formValue, 'comercial_quantity_formatted', formatNumber(formValue.comercial_quantity ?? 1));
};

const setNatureOperation = async (formValue) => {
  const { data } = (await Vue.prototype.$api.NatureOperations.showGraphql(formValue.nature_operation_id, ['id', 'to_s', 'natureOperationStates', ['uf_state']])) ?? { data: {} };
  const { id, to_s } = data;
  const nature_operation = { id, name: to_s || '', to_s };

  Vue.set(formValue, 'nature_operation', nature_operation);
  Vue.set(formValue, 'nature_operation_name', to_s || '');
  Vue.set(formValue, 'nature_operation_id', id);

  await setCbenef(formValue, data);
};

export default {
  screenEvents: {
    async beforeSave(formValue) {
      const taxes = calcItemTaxes(formValue);
      if (!formValue.nature_operation) {
        setNatureOperation(formValue);
      }

      if (formValue.stock_id) {
        const { data: stockData } = await Vue.prototype.$api.Stocks.showGraphql(formValue.stock_id, ['id', 'to_s']);
        formValue.stock = { id: stockData.id, name: stockData.to_s, to_s: stockData.to_s };
      }

      if (formValue.ncm_id) {
        const responseNcm = await Vue.prototype.$api.AllTypes.show(formValue.ncm_id);
        formValue.ncm = responseNcm;
        formValue.ncm_name = responseNcm.description;
      }

      if (formValue.cest_id) {
        const responseCest = await Vue.prototype.$api.AllTypes.show(formValue.cest_id);
        formValue.cest = responseCest;
        formValue.cest_name = responseCest.description;
      }

      if (formValue.unity_id) {
        const responseUnity = await Vue.prototype.$api.AllTypes.show(formValue.unity_id);
        formValue.unity = responseUnity;
      }

      formValue = { ...formValue, ...taxes };
    },
  },
  fieldEvents: {
    product_code: {
      change: async (formValue, fieldValue) => {
        await Vue.prototype.$api.Products.showGraphql(fieldValue, ['id', 'to_s', 'code', 'unity_id', 'ncm_id', 'cest_id', 'purchase_cost', 'sale_value']).then(async (response) => {
          const product = response.data;

          if (product) {
            setProductFields(formValue, product);
            calcTotalAndAbsoluteDiscount(formValue);
            setUnity(formValue);
          }
        });
      },
    },
    product_id: {
      change: async (formValue, fieldValue) => {
        await Vue.prototype.$api.Products.showGraphql(fieldValue, ['id', 'to_s', 'code', 'unity_id', 'ncm_id', 'cest_id', 'purchase_cost', 'sale_value']).then(async (response) => {
          const product = response.data;

          if (product) {
            setProductFields(formValue, product);
            calcTotalAndAbsoluteDiscount(formValue);
            setUnity(formValue);
          }
        });
      },
    },
    nature_operation_id: {
      change: async (formValue, fieldValue, fieldObjectValue) => {
        const nature_operation_id = fieldObjectValue;
        const { data } = nature_operation_id
          ? await Vue.prototype.$api.NatureOperations.showGraphql(nature_operation_id, ['id', 'to_s', 'natureOperationStates', ['uf_state', 'cbenef']])
          : { data: {} };
        formValue.nature_operation = { id: data.id, name: data.to_s, to_s: data.to_s };
        formValue.nature_operation_name = data.to_s ?? '';
        await setCbenef(formValue, data);
      },
    },
    ncm_id: {
      change: async (formValue, fieldValue, fieldObjectValue) => {
        const ncm_id = fieldObjectValue;
        const ncm = ncm_id ? await Vue.prototype.$api.AllTypes.show(ncm_id) : {};
        formValue.ncm = ncm;
        formValue.ncm_name = ncm.description ?? '';
      },
    },
    unity_id: {
      change: async (formValue, fieldValue, fieldObjectValue) => {
        const unity_id = fieldObjectValue;
        const unity = unity_id ? await Vue.prototype.$api.AllTypes.show(unity_id) : {};
        formValue.unity = unity;
        formValue.unity_name = unity.to_s;
        formValue.comercial_um = unity.nickname;
        formValue.tributary_um = unity.nickname;
      },
    },
    unit_value: {
      change: (formValue) => {
        calcTotalAndAbsoluteDiscount(formValue);
        Vue.set(formValue, 'comercial_value', formValue.unit_value);
        Vue.set(formValue, 'tributary_value', formValue.unit_value);
      },
    },
    comercial_quantity: {
      change: (formValue) => {
        calcTotalAndAbsoluteDiscount(formValue);
        Vue.set(formValue, 'tributary_quantity', formValue.comercial_quantity);
      },
    },
    total_value: {
      change: (formValue, fieldValue) => {
        const quantity = parseFloat(formValue.comercial_quantity) || 0;
        const discount = parseFloat(formValue.discount_percentage_value) || 0;
        if (quantity > 0) {
          const totalWithoutDiscount = (parseFloat(fieldValue) * 100) / (100 - discount);
          const unit = totalWithoutDiscount / quantity;
          Vue.set(formValue, 'unit_value', unit);
          Vue.set(formValue, 'total_without_discount_value', totalWithoutDiscount);
          Vue.set(formValue, 'discount_value', totalWithoutDiscount - fieldValue);

          Vue.set(formValue, 'total_value_formatted', formatCurrency(parseFloat(fieldValue || 0)));
        }
      },
    },
    discount_value: {
      change: (formValue, fieldValue) => {
        const total = (parseFloat(formValue.unit_value) || 0) * (parseFloat(formValue.comercial_quantity) || 0);
        const totalWithDiscount = total - (parseFloat(fieldValue) || 0);
        const discountPercent = total > 0 ? (parseFloat(fieldValue) / total) * 100 : 0;
        Vue.set(formValue, 'discount_percentage_value', discountPercent);
        Vue.set(formValue, 'discount_percentage_value_formatted', `${formatNumber(parseFloat(discountPercent || 0))}%`);
        Vue.set(formValue, 'total_without_discount_value', total);
        Vue.set(formValue, 'total_value', totalWithDiscount);
        Vue.set(formValue, 'total_value_formatted', formatCurrency(parseFloat(totalWithDiscount || 0)));
        Vue.set(formValue, 'discount_value_formatted', formatCurrency(parseFloat(fieldValue)));
      },
    },
    discount_percentage_value: {
      change: (formValue, fieldValue) => {
        const total = (parseFloat(formValue.unit_value) || 0) * (parseFloat(formValue.comercial_quantity) || 0);
        const totalWithDiscount = total - total * ((parseFloat(fieldValue) || 0) / 100);
        const discountValue = total - totalWithDiscount;
        Vue.set(formValue, 'discount_percentage_value_formatted', `${formatNumber(parseFloat(fieldValue || 0))}%`);
        Vue.set(formValue, 'discount_value', discountValue);
        Vue.set(formValue, 'discount_value_formatted', formatCurrency(parseFloat(discountValue)));
        Vue.set(formValue, 'total_without_discount_value', total);
        Vue.set(formValue, 'total_value', totalWithDiscount);
        Vue.set(formValue, 'total_value_formatted', formatCurrency(parseFloat(totalWithDiscount || 0)));
      },
    },
    cest_id: {
      change: async (formValue, fieldValue, fieldObjectValue) => {
        const cest_id = fieldObjectValue;
        const cest = cest_id ? await Vue.prototype.$api.AllTypes.show(cest_id) : {};
        formValue.cest = cest;
        formValue.cest_id = cest.id;
        formValue.cest_name = cest.description;
      },
    },
  },
  calcTotalAndAbsoluteDiscount,
};
