import {
  ICON_DESCRIPTION,
  ICON_MEASURING_UNIT,
  ICON_TAXES,
  ICON_TRUCK,
} from '@constants/icons';
import { OPTIONS_PRESENCE_INDICATOR, OPTIONS_FREIGHT, OPTIONS_ORDERSPURCHASESALE_STATUS } from '@constants/options';
import PartialSaleBusiness from '@businesses/PartialSale';
import PartialSaleItem from './PartialSaleItem';
import PartialSaleItemTax from './PartialSaleItemTax';
import PartialSaleTransporter from './PartialSaleTransporter';

import TabPartialSale from '@modules/supply/pages/partialSales/components/TabPartialSale';
import TabPartialSaleItems from '@modules/supply/pages/partialSales/components/TabPartialSaleItems';
import TabPartialSaleItemsTax from '@modules/supply/pages/partialSales/components/TabPartialSaleItemsTax';
import TabPartialSaleTransporter from '@modules/supply/pages/partialSales/components/TabPartialSaleTransporter';

import OrderSale from './OrderSale';

const orderSaleItems = [];

export function getOrderSaleItems() { return orderSaleItems; }
export function setOrderSaleItems(param) { orderSaleItems.push(param); }
export function resetOrderSaleItems() { orderSaleItems.splice(0); }

export default {
  service: 'PartialSales',
  business: PartialSaleBusiness,
  icon: OrderSale.icon,
  routes: {
    list: { path: '/supply/sales/partials', name: 'supply-salepartials-list' },
    create: { path: '/supply/sales/partials/create', name: 'supply-salepartials-create' },
    edit: { path: '/supply/sales/partials/edit/:id', name: 'supply-salepartials-edit' },
  },
  titles: {
    item: 'Entrega Parcial de Vendas',
    items: 'Entregas Parciais de Vendas',
    edit: 'Editar Entrega Parcial de Vendas',
    create: 'Criar Entrega Parcial de Vendas',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Entrega parcial de vendas excluida com sucesso!',
    successEdit: 'Entrega parcial de vendas alterada com sucesso!',
    successCreate: 'Entrega parcial de vendas criada com sucesso!',
    errorSave: 'Erro ao salvar entrega parcial de vendas!',
    tryEditCanceled: 'Não é possível editar uma entrega parcial de vendas cancelada.',
    confirmCancel: 'Deseja cancelar a entrega parcial de vendas?',
    successCancel: 'Entrega parcial de vendas cancelada com sucesso',
    errorCancel: 'Erro ao cancelar entrega parcial de vendas',
  },
  filterUrl: 'sale_partials/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  canClone: false,
  statusSettings: {
    options: OPTIONS_ORDERSPURCHASESALE_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  formName: 'salespartials',
  fields: [
    {
      label: 'INFORMAÇÕES DA ENTREGA PARCIAL',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
      tabGroup: 'infoPartialSale',
    },
    {
      label: 'Status',
      formattedName: 'status',
      name: 'status',
      type: 'select',
      options: 'orders_sale_sale_status',
      list: true,
    },
    {
      label: 'Cliente',
      name: 'buyer_id',
      formattedName: 'buyer.to_s',
      type: 'number',
      hint: '',
      list: true,
      required: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_register_array: 'client',
          query: ['id', 'name', 'cnpj_cpf', 'addresses', ['id', 'to_s'], 'preference', ['salesman_id', 'shipping_company_id']],
        }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      withDetails: true,
      typeDetails: 'buyer',
      md: 8,
      disabled: (formValue) => {
        if (formValue?.id) {
          return true;
        }
        return false;
      },
      tabGroup: 'infoPartialSale',
    },
    {
      label: 'Pedido de Venda',
      name: 'order_sale_id',
      formattedName: 'order_sale_id_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: orderSaleItems,
      disabled: (formValue) => {
        if (formValue?.id) {
          return true;
        }
        if (formValue?.buyer_id) {
          return false;
        }
        return false;
      },
      md: 2,
      required: true,
      tabGroup: 'infoPartialSale',
    },
    {
      label: 'Data Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      list: true,
      tabGroup: 'infoPartialSale',
    },
    {
      label: 'Consumidor Final',
      name: 'is_costumer',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPartialSale',
    },
    {
      label: 'Indicador de Presença',
      name: 'presence_indicator',
      formattedName: 'presence_indicator_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_PRESENCE_INDICATOR,
      md: 4,
      required: true,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPartialSale',
    },
    {
      label: 'Entrega Parcial',
      name: 'partial_number',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 2,
      disabled: (formValue) => {
        if (formValue?.partial_number) {
          return true;
        }
        return false;
      },
    },
    {
      label: 'NFe',
      name: 'note',
      formattedName: 'note_formatted',
      list: true,
      type: 'text',
      component: 'dynamic-text-field',
      readOnly: true,
      md: 2,
      disabled: true,
      tabGroup: 'infoPartialSale',
    },
    {
      label: 'Data Entrega',
      name: 'delivery_date',
      formatteName: 'delivery_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      md: 2,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
    },
    {
      label: 'Forma de Pagamento',
      name: 'payment_method_id',
      formattedName: 'payment_method.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-paymentmethod-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['payment_method'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPartialSale',
    },
    {
      label: 'Parcelamento',
      name: 'type_installment_id',
      formattedName: 'type_installment.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-typeinstallment-create',
      dataSource: {
        service: 'TypeInstallment',
        method: 'getByFilter',
        props: [{ inactive_eq: false }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPartialSale',
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      formattedName: 'stock.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'infoPartialSale',
    },
    {
      label: 'Total de Impostos Aproximados',
      formattedName: 'total_tax_aproximates',
      name: 'total_tax_aproximates',
      type: 'number',
    },
    {
      label: 'Valor Total',
      formattedName: 'total_ordered_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'INFORMAÇÕES DE FRETE',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'freight',
    },
    {
      label: 'Frete',
      name: 'freight',
      formattedName: 'freight_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_FREIGHT,
      required: true,
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'freight',
    },
    {
      label: 'Valor do Frete',
      name: 'freight_value',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'freight',
    },
    {
      label: 'Despesas',
      name: 'expense_value',
      type: 'number',
      component: 'dynamic-number-field',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'freight',
    },
    {
      label: 'Incluir Frete no Total',
      name: 'use_freight',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
      tabGroup: 'freight',
    },
    {
      label: 'TRIBUTAÇÕES DA DEVOLUÇÃO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      formattedName: 'nature_operation.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_nature_in: ['sale', 'ecommerce'],
          query: ['id', 'name'],
        }],
      },
      itemText: 'name',
      itemValue: 'id',
      list: true,
      required: true,
      md: 12,
      disabled: (formValue) => {
        if (formValue?.order_sale_id) {
          return false;
        }
        return true;
      },
    },
  ],
  childSchemas: [
    {
      label: 'Itens da Entrega Parcial de Vendas',
      name: 'items',
      schema: PartialSaleItem,
      noCreate: true,
    },
    {
      label: 'Conferência de Impostos',
      name: 'taxes',
      noCreate: true,
      noEdit: true,
      noDelete: true,
      noActions: true,
      schema: PartialSaleItemTax,
      hideDefaultHeader: true,
      customHeader: {
        customClass: 'custom-child-header-order-sale',
        ungroupedHeadersFront: 2,
        groupHeaders: [
          { text: 'SIMPLES NACIONAL', colspan: 3, class: 'order-sale-header' },
          { text: 'ICMS', colspan: 3, class: 'order-sale-header' },
          { text: 'ICMS ST', colspan: 3, class: 'order-sale-header' },
          { text: 'IPI', colspan: 3, class: 'order-sale-header' },
          { text: 'PIS', colspan: 3, class: 'order-sale-header' },
          { text: 'COFINS', colspan: 3, class: 'order-sale-header' },
          { text: 'ICMS CONSUMIDOR FINAL', colspan: 3, class: 'order-sale-header' },
          { text: 'VALOR APROXIMADOS', colspan: 3, class: 'order-sale-header' },
        ],
      },
    },
    {
      label: 'Transportadora',
      name: 'transporter',
      schema: PartialSaleTransporter,
      hasOne: true,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabPartialSale,
    },
    {
      icon: ICON_MEASURING_UNIT,
      title: 'Itens do Entrega Parcial de Venda',
      tabContent: TabPartialSaleItems,
    },
    {
      icon: ICON_TAXES,
      title: 'Conferência de Impostos',
      tabContent: TabPartialSaleItemsTax,
    },
    {
      icon: ICON_TRUCK,
      title: 'Transportadora',
      tabContent: TabPartialSaleTransporter,
    },
  ],
};
