import UsersSchema from '@schemas/User';

const ListUsers = () => import(/* webpackChunkName: "list-users" */'./ListUsers');
const DetailsUsers = () => import(/* webpackChunkName: "details-users" */'./DetailsUsers');
const ListUserProfiles = () => import(/* webpackChunkName: "list-users-profiles" */ './components/ListUserProfiles');

export default [
  {
    path: '/settings/users',
    name: 'settings-users-list',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: UsersSchema.titles.items },
      ],
    },
    component: ListUsers,
  },
  {
    path: '/settings/users/create',
    name: 'settings-users-create',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: UsersSchema.titles.items, route: 'settings-users-list' },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailsUsers,
  },
  {
    path: '/settings/users/edit/:id',
    name: 'settings-users-edit',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: UsersSchema.titles.items, route: 'settings-users-list' },
        { name: 'Editar' },
      ],
    },
    component: DetailsUsers,
  },
  {
    path: '/settings/users/:user_id/profiles',
    name: 'settings-userprofiles-list',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: UsersSchema.titles.items },
      ],
    },
    component: ListUserProfiles,
  },
];
