import { OPTIONS_EMMIT_INVOICE_FORMAT, OPTIONS_FINALLY_INVOICE } from '@constants/options';

export default [
  // Line 1
  {
    label: 'Natureza da Operação',
    name: 'nature_operation_id',
    formattedName: 'nature_operation.to_s',
    type: 'number',
    component: 'dynamic-select',
    dataSource: {
      service: 'NatureOperations',
      method: 'getByFilterGraphql',
      props: [{
        use_inactive_eq: false,
        type_nature_in: ['sale', 'purchase'],
        query: ['id', 'name'],
      }],
    },
    itemText: 'name',
    itemValue: 'id',
    colClass: 'nf-field top left right',
    prependIcon: '',
    md: 3,
  },
  {
    label: 'Forma de Emissão',
    name: 'form_issuance',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_EMMIT_INVOICE_FORMAT,
    colClass: 'nf-field top left right',
    md: 3,
  },
  {
    label: 'Finalidade',
    name: 'purpose_issue',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_FINALLY_INVOICE,
    colClass: 'nf-field top left right',
    md: 3,
  },
  {
    label: 'Protocolo de Autorização de Uso',
    name: 'protocol_formatted',
    type: 'text',
    component: 'dynamic-text-field',
    md: 3,
    disabled: true,
    colClass: 'nf-field top left right',
    prependIcon: '',
  },
  // Line 2
  {
    label: 'Inscrição Estadual',
    name: 'emitter.ie',
    type: 'date',
    component: 'dynamic-text-field',
    disabled: false,
    md: 3,
    readOnly: false,
    colClass: 'nf-field top bottom left right',
    prependIcon: '',
  },
  {
    label: 'Inscrição Estadual do Subst. Tributário',
    name: 'emitter.iest',
    type: 'text',
    component: 'dynamic-text-field',
    md: 3,
    readOnly: false,
    prependIcon: '',
    colClass: 'nf-field top bottom left right',
  },
  {
    label: 'SUFRAMA',
    name: 'buyer.suframa',
    type: 'text',
    component: 'dynamic-text-field',
    md: 3,
    prependIcon: '',
    colClass: 'nf-field top bottom left right',
  },
  {
    label: 'CNPJ',
    name: 'emitter.cnpj',
    type: 'text',
    component: 'dynamic-text-field',
    md: 3,
    readOnly: true,
    prependIcon: '',
    colClass: 'nf-field top left bottom right',
  },
];
