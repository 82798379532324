import GeneralProductsReportsSchema from './GeneralProductsReportsSchema';

const GeneralProductsReports = () => import(/* webpackChunkName: "general-products-reports" */'./GeneralProductsReports');
export const ProductsRoutes = [
  {
    path: GeneralProductsReportsSchema.route.path,
    name: GeneralProductsReportsSchema.route.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Relatórios' },
        { name: 'Produtos' },
      ],
    },
    component: GeneralProductsReports,
  },
];
