import {
  ICON_CANCEL_CIRCLE,
  ICON_DESCRIPTION,
  ICON_INVOICE,
} from '@constants/icons';
import { OPTIONS_MANIFEST_STATUS } from '@constants/options';

import ListFields from './manifest/ListFields';
import ManifestFields from './manifest/ManifestFields';
import EmitterFields from './manifest/EmitterFields';
import AnttFields from './manifest/AnttFields';
import LoadFields from './manifest/LoadFields';
import ManifestLogisticFields from './manifest/ManifestLogisticFields';
import PredominantItemFields from './manifest/PredominantItemFields';

import ManifestCourseSchema from './manifest/ManifestCourse';
import ManifestVehicleSchema from './manifest/ManifestVehicle';
import ManifestConductorSchema from './manifest/ManifestConductor';
import ManifestInvoiceSchema from './manifest/ManifestInvoice';

import ManifestBusiness from '@businesses/Manifest';

import TabManifestErrors from '@modules/supply/pages/manifests/components/TabManifestErrors';
import TabManifest from '@modules/supply/pages/manifests/components/TabManifest';

export default {
  service: 'Manifests',
  business: ManifestBusiness,
  icon: ICON_INVOICE,
  routes: {
    list: {
      path: '/supply/manifests',
      name: 'supply-manifests-list',
    },
    create: {
      path: '/supply/manifests/create',
      name: 'supply-manifests-create',
    },
    edit: {
      path: '/supply/manifests/edit/:id',
      name: 'supply-manifests-edit',
    },
  },
  titles: {
    item: 'Espelho MDF-e',
    items: 'MDF-e',
    create: 'Criar MDF-e',
    edit: 'Editar MDF-e',
    new: 'Novo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    ...ListFields,
  ],
  statusSettings: {
    options: OPTIONS_MANIFEST_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  layout: [
    {
      component: 'slot',
      name: 'nf-header',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          containerClass: 'pt-0',
          fields: [...EmitterFields],
        },
      ],
    },
    {
      label: 'DADOS MDF-e',
      class: 'mb-0 mx-3 pb-0',
      component: 'dynamic-divider',
      md: 12,
      size: 'md',
      propTag: 'h5',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          fields: [...ManifestFields],
        },
      ],
    },
    {
      label: 'DADOS ANTT',
      class: 'mb-0 mx-3 pb-0',
      component: 'dynamic-divider',
      md: 12,
      size: 'md',
      propTag: 'h5',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          fields: [...AnttFields],
        },
      ],
    },
    {
      label: 'LOGÍSTICA',
      class: 'mb-0 mx-3 pb-0',
      component: 'dynamic-divider',
    },
    {
      component: 'generic-card-form',
      class: 'mx-3 my-6',
      children: [
        {
          component: 'v-row',
          noGutters: true,
          class: 'nf-field top right bottom left ma-0',
          children: [
            {
              component: 'v-col',
              class: 'nf-field right',
              cols: 6,
              children: [
                {
                  component: 'dynamic-form',
                  containerClass: 'pa-0',
                  fields: [...ManifestLogisticFields],
                },
              ],
            },
            {
              component: 'v-col',
              cols: 6,
              children: [
                {
                  component: 'dynamic-child-schema',
                  fieldName: 'logistic.course_states',
                  containerClass: 'pa-0',
                  childSchema: ManifestCourseSchema,
                  noCheck: true,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'PRODUTO PREDOMINANTE',
      component: 'dynamic-divider',
      class: 'mb-0 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          containerClass: 'pa-0',
          fields: [...PredominantItemFields],
        },
      ],
    },
    {
      label: 'TOTAIS DA CARGA',
      component: 'dynamic-divider',
      class: 'mb-0 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          fields: [...LoadFields],
        },
      ],
    },
    {
      label: 'DADOS DO(S) VEÍCULO(S)',
      component: 'dynamic-divider',
      class: 'mb-4 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'generic-card-form',
      class: 'mx-3 my-3',
      children: [
        {
          component: 'v-row',
          noGutters: true,
          class: 'nf-field top right bottom left ma-0',
          children: [
            {
              component: 'dynamic-tabs',
              tabs: [
                {
                  label: 'Veículos',
                  children: [
                    {
                      component: 'dynamic-child-schema',
                      noCheck: true,
                      fieldName: 'vehicle',
                      containerClass: 'pa-0',
                      childSchema: ManifestVehicleSchema,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'CONDUTOR',
      component: 'dynamic-divider',
      class: 'mb-4 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'generic-card-form',
      class: 'mx-3 my-3',
      children: [
        {
          component: 'v-row',
          noGutters: true,
          class: 'nf-field top right bottom left ma-0',
          children: [
            {
              component: 'dynamic-tabs',
              tabs: [
                {
                  label: 'Condutor',
                  children: [
                    {
                      component: 'dynamic-child-schema',
                      noCheck: true,
                      fieldName: 'conductor',
                      containerClass: 'pa-0',
                      childSchema: ManifestConductorSchema,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'DADOS DA NF-e',
      component: 'dynamic-divider',
      class: 'mb-4 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'generic-card-form',
      class: 'mx-3 my-3',
      children: [
        {
          component: 'v-row',
          noGutters: true,
          class: 'nf-field top right bottom left ma-0',
          children: [
            {
              component: 'dynamic-tabs',
              tabs: [
                {
                  label: 'NF-e',
                  children: [
                    {
                      component: 'dynamic-child-schema',
                      noCheck: true,
                      fieldName: 'invoice',
                      containerClass: 'pa-0',
                      childSchema: ManifestInvoiceSchema,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabManifest,
    },
    {
      icon: ICON_CANCEL_CIRCLE,
      iconColor: 'red',
      title: 'Erros de transmissão',
      tabContent: TabManifestErrors,
    },
  ],
};
