import { ICON_PRODUCT } from '@constants/icons';

export default {
  service: 'Products',
  icon: ICON_PRODUCT,
  route: {
    path: '/reports/products/general',
    name: 'reports-products-general',
  },
  title: 'Relatórios de Produtos',
  filtersLayout: [
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-filters',
          containerClass: 'pt-0',
          fields: [
            {
              label: 'Produto',
              name: 'id',
              formattedName: 'product.to_s',
              type: 'number',
              hideNot: true,
              component: 'dynamic-autocomplete',
              dataSource: {
                service: 'Products',
                method: 'getByFilter',
                props: [{ use_inactive_eq: false }, 'name asc'],
              },
              itemText: 'name',
              itemValue: 'id',
              md: 8,
              clearable: true,
            },
            {
              label: 'Código',
              name: 'items.product.code',
              type: 'number',
              hideNot: true,
              component: 'dynamic-text-field',
              condition: '_matches',
              notCondition: '_does_not_match',
              md: 4,
            },
            {
              label: 'Tipo do Produto ou Serviço',
              name: 'product_type_id',
              type: 'number',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['product_type'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Unidade de Medida',
              name: 'unity_id',
              type: 'number',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['unity'],
              },
              itemText: 'description',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Categoria do Produto',
              name: 'product_category_id',
              type: 'number',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['product_category'],
              },
              itemText: 'description',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'NCM',
              name: 'ncm_id',
              type: 'number',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['ncm'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'CEST',
              name: 'cest_id',
              type: 'number',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['cest'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Status do Produto',
              name: 'use_inactive',
              type: 'string',
              multiple: true,
              clearable: true,
              hideNot: true,
              component: 'dynamic-select',
              items: [
                { text: 'Ativo', value: false },
                { text: 'Inativo', value: true },
              ],
              md: 4,
            },

          ],
        },
      ],
    },
  ],
};
