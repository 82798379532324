import { ICON_BOOK_OUTLINE, ICON_BANK } from '@constants/icons';
import { MASK_CPF_CNPJ, MASK_BANK_AGENCY, MASK_DV } from '@constants/masks';
// import { OPTIONS_BANK_LAYOUT } from '@constants/options';
import CnabsBusiness from '@businesses/Cnabs';
import CnabsTicketSchema from './CnabsTicket';
import TabCnabs from '@modules/settings/pages/cnabs/components/TabCnabs.vue';

/**
 *  @TODO remove this constant and add the OPTIONS_BANK_LAYOUT
 *  when the task of cnabs supports the remaning layouts
 */
const TEMPORARY_BANKS_LAYOUTS = [
  { text: 'BTG Pactual (Digital Bank)', value: 'btg_pactual_208' },
];

function disabledWhenBankLayoutIsBTG(formValue) {
  if (formValue?.bank_layout === 'btg_pactual_208') {
    return true;
  }
  return false;
}

export default {
  service: 'Cnabs',
  business: CnabsBusiness,
  formName: 'cnabs',
  icon: ICON_BOOK_OUTLINE,
  routes: {
    list: { path: '/settings/cnabs', name: 'settings-cnab-list' },
    create: { path: '/settings/cnabs/create', name: 'settings-cnab-create' },
    edit: { path: '/settings/cnabs/edit/:id', name: 'settings-cnab-edit' },
  },
  titles: {
    item: 'CNAB',
    items: 'CNABs - Boletos Bancários',
    edit: 'Editar CNAB',
    create: 'Criar CNAB',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Cnab excluido com sucesso!',
    successEdit: 'Cnab alterado com sucesso!',
    successCreate: 'Cnab criado com sucesso!',
    errorSave: 'Erro ao salvar Cnab!',
    tryEditCanceled: 'Não é possível editar um Cnab cancelado.',
    confirmCancel: 'Deseja cancelar o Cnab?',
    successCancel: 'Cnab cancelado com sucesso',
    errorCancel: 'Erro ao cancelar Cnab',
  },
  hideReportsOnCreate: true,
  hideReportsOnUpdate: true,
  hideReportsOnList: true,
  fields: [
    {
      label: 'Informações do Banco',
      component: 'dynamic-title',
      size: 'md',
      propTag: 'h5',
      md: 12,
      tabGroup: 'bankInfo',
    },
    {
      label: 'Layout Bancário',
      name: 'bank_layout',
      formattedName: 'bank_layout_formatted',
      type: 'text',
      list: true,
      component: 'dynamic-select',
      items: TEMPORARY_BANKS_LAYOUTS,
      required: true,
      md: 4,
      tabGroup: 'bankInfo',
    },
    {
      label: 'Conta Bancária',
      name: 'bank_id',
      formattedName: 'bank.to_s',
      type: 'text',
      list: true,
      hint: '',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'financial-banks-create',
      dataSource: {
        service: 'Banks',
        method: 'getByFilterGraphql',
        props: [{ inactive_eq: false, query: ['id', 'description'] }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'description',
      itemValue: 'id',
      md: 4,
      tabGroup: 'bankInfo',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Forma de Pagamento',
      name: 'payment_method_id',
      formattedName: 'payment_method.to_s',
      type: 'text',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-paymentmethod-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['payment_method'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 4,
      tabGroup: 'bankInfo',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'CNPJ/CPF',
      name: 'assignor_cnpj_cpf',
      type: 'number',
      component: 'dynamic-text-field',
      md: 3,
      mask: MASK_CPF_CNPJ,
      tabGroup: 'bankInfo',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Cedente',
      name: 'assignor_name',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 6,
      tabGroup: 'bankInfo',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Nº do Convênio',
      name: 'convention_code',
      type: 'number',
      component: 'dynamic-text-field',
      md: 2,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Agência',
      name: 'agency',
      type: 'number',
      mask: [...MASK_BANK_AGENCY],
      component: 'dynamic-text-field',
      md: 2,
      tabGroup: 'bankInfo',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'DV',
      name: 'agency_digit',
      type: 'number',
      mask: MASK_DV,
      component: 'dynamic-text-field',
      md: 1,
      tabGroup: 'bankInfo',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Conta',
      name: 'account',
      type: 'number',
      mask: [...MASK_BANK_AGENCY],
      component: 'dynamic-text-field',
      md: 2,
      tabGroup: 'bankInfo',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'DV',
      name: 'account_digit',
      type: 'number',
      mask: MASK_DV,
      component: 'dynamic-text-field',
      md: 1,
      tabGroup: 'bankInfo',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Código Cedente:',
      name: 'assignor_code',
      type: 'number',
      component: 'dynamic-text-field',
      md: 2,
      tabGroup: 'bankInfo',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'DV',
      name: 'check_digit',
      type: 'number',
      mask: MASK_DV,
      component: 'dynamic-text-field',
      md: 1,
      tabGroup: 'bankInfo',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Código Operação:',
      name: 'operation_code',
      type: 'number',
      component: 'dynamic-text-field',
      md: 3,
      tabGroup: 'bankInfo',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
  ],
  childSchemas: [
    {
      label: 'Informações do Boleto',
      name: 'ticket',
      schema: CnabsTicketSchema,
      hasOne: true,
    },
  ],
  tabs: [
    {
      icon: ICON_BANK,
      title: 'Informações do Banco',
      tabContent: TabCnabs,
    },
  ],
};
