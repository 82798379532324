// import * as AppUtils from '@plugins/app-utils';
const endpoint = 'anticipation/invoices';

const AvailableInvoices = ($axios) => ({
  async index() {
    let responseData = {};

    await $axios.get(`${endpoint}.json`).then((response) => {
      responseData = this.formatResponseData(response.data);
    });

    return responseData;
  },
  async downloadXML(id) {
    let responseData = {};

    await $axios.get(`${endpoint}/${id}/download.json`).then((response) => {
      responseData = response.data;
    });

    return responseData;
  },
  async getByFilter(filters, order) {
    const payload = {
      q: filters || {},
    };
    // add sort option
    if (order) {
      payload.q.s = order;
    }

    let responseData = {};
    await $axios.post(`${endpoint}/filters.json`, payload).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },
  formatResponseData(data, type = 'table') {
    const format = (item) => {
      item.cedente_formatted = `${item.emitter.cnpj}/${item.emitter.name}/${item.emitter.city}/${item.emitter.uf}`;
      item.buyer_formatted = `${item.buyer.cnpj_cpf}/${item.buyer.name}/${item.buyer.city}/${item.buyer.uf}`;
      item.date_emition = '11/04/2021';
      item.nf_value = 'R$5.600,00';
      item.installment = '30/60/90';
      item.media = '60';
      item.approved = '-';
      item.certus = 'A1';
      item.key = '897521387984351497851465735249';
      item.title = 'Título';
      item.value_title = 'R$15.000,00';
      item.certus = 'A1';
      item.isSelected = false;
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
});

export { AvailableInvoices };
export default { AvailableInvoices };
