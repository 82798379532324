<template>
  <v-container class="justify-center text-center align-center">
    <v-col cols="12" class="pa-2">
      <h1 class="error-back-text">
        <span>Manutenção</span>
      </h1>
    </v-col>
    <div class="pa-2">
      <v-row color="indigo lighten-1">
        <v-col cols="12">
          <h1 class="heading--text indigo--text text--darken-2">
            <span>iCertus ERP - Manutenção</span>
          </h1>
        </v-col>
      </v-row>
      <v-row class="pa-2">
        <v-col cols="12">
          <h2 class="heading--text indigo--text text--lighten-1">
            <span>No momento o sistema se encontra em manutenção, tente novamente mais tarde.</span>
          </h2>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import '@plugins/charts';
import '@plugins/custom-charts';
import '@plugins/notify';

export default {
  name: 'NotFoundLayout',
};
</script>

<style scoped>
.error-back-text {
  display: flex;
  position: fixed;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-style: bold;
  font-size: 44em;
  margin-left: -30px;
  opacity: 30%;
  background: -webkit-linear-gradient(rgb(152, 151, 151), rgb(196, 195, 195), rgb(239, 239, 239), rgb(255, 255, 255));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media only screen and (min-width: 1024px) and (max-width: 1010px) {
  .error-back-text {
    margin-top: 58vh;
    font-size: 13em;
  }
  @media only screen and (min-width: 320px) and (max-width: 757px) {
    .error-back-text {
      margin-top: 58vh;
      font-size: 12em;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .error-back-text {
      margin-top: 28vh;
      font-size: 24em;
    }
  }
}
</style>
