import Alltypes from '@schemas/Alltypes';

export default [
  {
    path: Alltypes.routes.addressClientTypes.list.path,
    name: Alltypes.routes.addressClientTypes.list.name,
    meta: Alltypes.routes.addressClientTypes.list.meta,
    component: () => import(/* webpackChunkName: 'list-address-client-types' */ `${Alltypes.routes.addressClientTypes.list.component}`),
  },
  {
    path: Alltypes.routes.addressClientTypes.create.path,
    name: Alltypes.routes.addressClientTypes.create.name,
    meta: Alltypes.routes.addressClientTypes.create.meta,
    component: () => import(/* webpackChunkName: 'create-address-client-types' */ `${Alltypes.routes.addressClientTypes.create.component}`),
  },
  {
    path: Alltypes.routes.addressClientTypes.edit.path,
    name: Alltypes.routes.addressClientTypes.edit.name,
    meta: Alltypes.routes.addressClientTypes.edit.meta,
    component: () => import(/* webpackChunkName: 'edit-address-client-edit' */ `${Alltypes.routes.addressClientTypes.edit.component}`),
  },
];
