import {
  ICON_BANK,
  ICON_BANK_PLUS,
  ICON_HAND_COIN,
  ICON_CASH_REFUND,
  ICON_CASH_CLOCK,
  ICON_CASH_EDIT,
  ICON_CASH_REGISTER,
  ICON_CHART_BAR,
  ICON_CALCULATOR,
  ICON_CIRCLE_SMALL,
  ICON_FINANCE,
  ICON_NOTEBOOK_MULTIPLE,
  ICON_FOLDER_MULTIPLE,
  ICON_TAG_PLUS,
  ICON_STORE,
  ICON_PRINTER,
  ICON_STOCK,
} from '@constants/icons';

// import AccountSchema from '@schemas/Account';
import AccountPaymentSchema from '@schemas/AccountPayment';
import AccountReceivableSchema from '@schemas/AccountReceivable';
import AccountingAccountSchema from '@schemas/AccountingAccount';
import AlltypesSchemas from '@schemas/Alltypes';
import AnticipationSchema from '@schemas/Anticipation';
import AnticipationCreditCardSchema from '@schemas/AnticipationCreditCard';
import BiddingSchema from '@schemas/Bidding';
import BankSchema from '@schemas/Bank';
import BankLaunchesSchema from '@schemas/BankLaunches';
import BtgAccountSchema from '@schemas/BtgAccount';
// import BtgAccountStatementSchema from '@schemas/BtgAccountStatement';
import BtgBilletSchema from '@schemas/BtgBillet';
import CashFlowSchema from '@schemas/CashFlow';
import CostCenterSchema from '@schemas/CostCenter';
import DasGuidesSchema from '@schemas/DasGuides';
import DevolutionConsignmentSchema from '@schemas/DevolutionConsignment';
import DevolutionIndustrializationSchema from '@schemas/DevolutionIndustrialization';
import DevolutionPurchaseSchema from '@schemas/DevolutionPurchase';
import DevolutionSaleSchema from '@schemas/DevolutionSales';
import GeneralAccountPaymentReportsSchema from '@modules/reports/pages/accountPayment/GeneralAccountPaymentReportsSchema';
import GeneralAccountReceivablesSchema from '@modules/reports/pages/accountReceivable/GeneralAccountReceivableReportsSchema';
import GeneralBanksReportsSchema from '@modules/reports/pages/banks/GeneralBanksReportsSchema';
import GeneralInvoicesReportsSchema from '@modules/reports/pages/invoices/GeneralInvoicesReportsSchema';
import GeneralProductsReportsSchema from '@modules/reports/pages/products/GeneralProductsReportsSchema';
import GeneralPurchasesReportsSchema from '@modules/reports/pages/purchases/GeneralPurchasesReportsSchema';
import GeneralRegistersReportsSchema from '@modules/reports/pages/Registers/GeneralRegistersReportsSchema';
import GeneralStockItemsReportsSchema from '@modules/reports/pages/stockItems/GeneralStockItemsReportsSchema';
import GeneralStocksHistoriesReportsSchema from '@modules/reports/pages/stockHistory/GeneralStocksHistoriesReportsSchema';
import GeneralSalesReportsSchema from '@modules/reports/pages/sales/GeneralSalesReportsSchema';
import InvoiceISSSchema from '@schemas/InvoiceISS';
import InvoiceSchema from '@schemas/Invoice';
import InstallmentMethodSchema from '@schemas/InstallmentMethod';
import ManifestSchema from '@schemas/Manifest';
import NationalSimpleSchema from '@schemas/NationalSimple';
import NatureOperationSchema from '@schemas/NatureOperation';
import Magis5Schema from '@schemas/Magis5';
import OrderOrderBudgetSchema from '@schemas/OrderBudget';
import OrderProductionSchema from '@schemas/OrderProduction';
import OrderPurchaseSchema from '@schemas/OrderPurchase';
import OrderSaleSchema from '@schemas/OrderSale';
import OrderServiceSchema from '@schemas/OrderService';
import OpenTitlePaymentSchema from '@schemas/OpenTitlePayment';
import OpenTitleReceivableSchema from '@schemas/OpenTitleReceivable';
import PartialPurchaseSchema from '@schemas/PartialPurchase';
import PartialSaleSchema from '@schemas/PartialSale';
import PaymentMethodSchema from '@schemas/PaymentMethod';
import ProductSchema from '@schemas/Product';
import PriceListSchema from '@schemas/PriceList';
import RegisterSchema from '@schemas/Register';
import RemittanceConsignmentSchema from '@schemas/RemittanceConsignment';
import RemittanceIndustrializationSchema from '@schemas/RemittanceIndustrialization';
import StockHistorySchema from '@schemas/StockHistory';
import StockSchema from '@schemas/Stock';
import TransactionConsignmentSchema from '@schemas/TransactionConsignment';
import TransactionIndustrializationSchema from '@schemas/TransactionIndustrialization';

import Vue from 'vue';

async function Me() {
  const token = localStorage.getItem('token');
  if (!token) return;

  const { $api } = await Vue.prototype;
  const { data } = await $api.Me.index();
  return data;
}

const items = [
  {
    title: 'Cadastros',
    icon: ICON_FOLDER_MULTIPLE,
    items: [
      { title: RegisterSchema.titles.items, to: RegisterSchema.routes.list.name, icon: RegisterSchema.icon },
      { title: ProductSchema.titles.items, to: ProductSchema.routes.list.name, icon: ProductSchema.icon },
      {
        title: 'Impostos',
        icon: ICON_CALCULATOR,
        initialExpanded: false,
        items: [
          { title: NatureOperationSchema.titles.items, to: NatureOperationSchema.routes.list, icon: ICON_CIRCLE_SMALL },
          { title: NationalSimpleSchema.titles.items, to: NationalSimpleSchema.routes.edit.name, icon: ICON_CIRCLE_SMALL },
          { title: 'Situação Tributária de Serviço', to: AlltypesSchemas.routes.serviceCst.list.name, icon: ICON_CIRCLE_SMALL },
          { title: 'Situação Tributária de Serviço ISSQN', to: AlltypesSchemas.routes.serviceIssqn.list.name, icon: ICON_CIRCLE_SMALL },
        ],
      },
      { title: PriceListSchema.titles.items, to: PriceListSchema.routes.list.name, icon: PriceListSchema.icon },
      { title: CostCenterSchema.titles.items, to: CostCenterSchema.routes.list.name, icon: CostCenterSchema.icon },
      { title: AccountingAccountSchema.titles.items, to: AccountingAccountSchema.routes.list.name, icon: AccountingAccountSchema.icon },
      {
        title: 'Tipos Gerais',
        icon: ICON_NOTEBOOK_MULTIPLE,
        items: [
          { title: AlltypesSchemas.titles.evaluations.items, to: AlltypesSchemas.routes.evaluations.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.productCategories.items, to: AlltypesSchemas.routes.productCategories.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.cests.items, to: AlltypesSchemas.routes.cests.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.serviceCodes.items, to: AlltypesSchemas.routes.serviceCodes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: PaymentMethodSchema.titles.items, to: PaymentMethodSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: InstallmentMethodSchema.titles.items, to: InstallmentMethodSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.coins.items, to: AlltypesSchemas.routes.coins.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.ncms.items, to: AlltypesSchemas.routes.ncms.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.occupations.items, to: AlltypesSchemas.routes.occupations.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.sectors.items, to: AlltypesSchemas.routes.sectors.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.types.items, to: AlltypesSchemas.routes.types.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.addressTypes.items, to: AlltypesSchemas.routes.addressTypes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.stockTypes.items, to: AlltypesSchemas.routes.stockTypes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.observations.items, to: AlltypesSchemas.routes.observations.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.productTypes.items, to: AlltypesSchemas.routes.productTypes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AlltypesSchemas.titles.measuringUnits.items, to: AlltypesSchemas.routes.measuringUnits.list.name, icon: ICON_CIRCLE_SMALL },
        ],
      },
    ],
  },
  {
    title: 'Movimentações',
    icon: ICON_STORE,
    items: [
      {
        title: 'Compras',
        icon: OrderPurchaseSchema.icon,
        initialExpanded: false,
        items: [
          { title: OrderPurchaseSchema.titles.items, to: OrderPurchaseSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: PartialPurchaseSchema.titles.items, to: PartialPurchaseSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: DevolutionPurchaseSchema.titles.items, to: DevolutionPurchaseSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
        ],
      },
      {
        title: 'Vendas',
        icon: OrderSaleSchema.icon,
        initialExpanded: false,
        items: [
          { title: OrderOrderBudgetSchema.titles.items, to: OrderOrderBudgetSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: OrderSaleSchema.titles.items, to: OrderSaleSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: PartialSaleSchema.titles.items, to: PartialSaleSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: DevolutionSaleSchema.titles.items, to: DevolutionSaleSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: 'Frente de Caixa (PDV)', to: '/supply/pdv', icon: ICON_CIRCLE_SMALL },
        ],
      },
      {
        title: 'Serviços',
        icon: OrderServiceSchema.icon,
        initialExpanded: false,
        items: [{ title: OrderServiceSchema.titles.items, to: OrderServiceSchema.routes.list.name, icon: ICON_CIRCLE_SMALL }],
      },
      {
        title: 'Remessas / Consignações',
        icon: RemittanceConsignmentSchema.icon,
        initialExpanded: false,
        items: [
          { title: RemittanceConsignmentSchema.titles.items, to: RemittanceConsignmentSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: DevolutionConsignmentSchema.titles.items, to: DevolutionConsignmentSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: TransactionConsignmentSchema.titles.items, to: TransactionConsignmentSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
        ],
      },
      {
        title: 'Remessas / Industrializações',
        icon: RemittanceIndustrializationSchema.icon,
        initialExpanded: false,
        items: [
          { title: RemittanceIndustrializationSchema.titles.items, to: RemittanceIndustrializationSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: DevolutionIndustrializationSchema.titles.items, to: DevolutionIndustrializationSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: TransactionIndustrializationSchema.titles.items, to: TransactionIndustrializationSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
        ],
      },
      {
        title: 'Produção',
        icon: OrderProductionSchema.icon,
        initialExpanded: false,
        items: [{ title: OrderProductionSchema.titles.items, to: OrderProductionSchema.routes.list.name, icon: ICON_CIRCLE_SMALL }],
      },
      {
        title: 'Estoques',
        icon: ICON_STOCK,
        initialExpanded: false,
        items: [
          { title: StockSchema.titles.list, to: StockSchema.routes.list, icon: ICON_CIRCLE_SMALL },
          { title: StockHistorySchema.titles.items, to: StockHistorySchema.routes.list, icon: ICON_CIRCLE_SMALL },
        ],
      },
      {
        title: 'Notas Fiscais',
        icon: InvoiceSchema.icon,
        initialExpanded: false,
        items: [
          {
            title: InvoiceSchema.titles.items,
            to: InvoiceSchema.routes.list.name,
            icon: ICON_CIRCLE_SMALL,
          },
          {
            title: InvoiceISSSchema.titles.items,
            to: InvoiceISSSchema.routes.list.name,
            icon: ICON_CIRCLE_SMALL,
          },
          {
            title: ManifestSchema.titles.items,
            to: ManifestSchema.routes.list.name,
            icon: ICON_CIRCLE_SMALL,
          },
        ],
      },
    ],
  },
  {
    title: 'Venda Mais',
    icon: ICON_CASH_REGISTER,
    items: [
      {
        title: BiddingSchema.titles.items,
        to: '/bidding/national',
        icon: BiddingSchema.icon,
      },
      {
        title: 'Integrações ',
        initialExpanded: true,
        icon: ICON_TAG_PLUS,
        items: [
          {
            title: Magis5Schema.titles.item,
            to: Magis5Schema.routes.list.name,
            icon: ICON_CIRCLE_SMALL,
          },
        ],
      },
    ],
  },
  {
    title: 'Financeiro',
    icon: ICON_HAND_COIN,
    items: [
      {
        title: 'Bancos',
        icon: ICON_BANK,
        items: [
          { title: BankSchema.titles.list, to: BankSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: BankLaunchesSchema.titles.bankHistory, to: BankLaunchesSchema.routes.launches.name, icon: ICON_CIRCLE_SMALL },
        ],
      },
      {
        title: 'Integrações',
        icon: ICON_BANK_PLUS,
        items: [
          {
            title: 'Btg Pactual',
            icon: ICON_CIRCLE_SMALL,
            items: [
              { title: BtgAccountSchema.titles.list, to: BtgAccountSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
              { title: BtgBilletSchema.titles.list, to: BtgBilletSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
              // { title: BtgAccountStatementSchema.titles.list, to: BtgAccountStatementSchema.routes.list.name, icon: ICON_CIRCLE_SMALL},
            ],
          },
          {
            title: 'Guias do DAS (MEI)',
            icon: ICON_CIRCLE_SMALL,
            to: DasGuidesSchema.routes.list.name,
          },
        ],
      },
      { title: AccountPaymentSchema.titles.items, to: AccountPaymentSchema.routes.list.name, icon: AccountPaymentSchema.icon },
      { title: AccountReceivableSchema.titles.items, to: AccountReceivableSchema.routes.list.name, icon: AccountReceivableSchema.icon },
      {
        title: 'Títulos em Aberto',
        icon: ICON_CASH_CLOCK,
        items: [
          { title: OpenTitlePaymentSchema.titles.items, to: OpenTitlePaymentSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: OpenTitleReceivableSchema.titles.items, to: OpenTitleReceivableSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
        ],
      },
      {
        title: 'Planejamento',
        icon: ICON_CASH_EDIT,
        items: [{ title: CashFlowSchema.titles.items, to: CashFlowSchema.routes.list.name, icon: ICON_CIRCLE_SMALL }],
      },
      {
        title: 'Antecipação de Recebíveis',
        icon: ICON_CASH_REFUND,
        items: [
          { title: AnticipationSchema.titles.item, to: AnticipationSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
          { title: AnticipationCreditCardSchema.titles.items, to: AnticipationCreditCardSchema.routes.list.name, icon: ICON_CIRCLE_SMALL },
        ],
      },
    ],
  },
  {
    title: 'Analítico',
    icon: ICON_FINANCE,
    items: [
      { title: 'Listar Gráficos', to: 'analytics-charts-list', icon: ICON_CHART_BAR },
      {
        title: 'Estoque',
        icon: ICON_STOCK,
        items: [
          { title: 'Itens do Estoque', to: 'analytics-charts-stockitems', icon: ICON_CIRCLE_SMALL },
        ],
      },
      {
        title: 'Movimentações',
        icon: ICON_STORE,
        items: [
          { title: 'Comparativo Compras / Vendas', to: 'analytics-charts-comparativesalespurchase', icon: ICON_CIRCLE_SMALL },
          { title: 'Desempenho dos Vendedores', to: 'analytics-charts-salesmenperformance', icon: ICON_CIRCLE_SMALL },
          { title: 'Produtos Mais Vendidos', to: 'analytics-charts-topsellingproducts', icon: ICON_CIRCLE_SMALL },
          { title: 'Compras dos Clientes', to: 'analytics-charts-buyerspurchase', icon: ICON_CIRCLE_SMALL },
        ],
      },
      {
        title: 'Financeiro',
        icon: ICON_HAND_COIN,
        items: [
          { title: 'Comparativo Contas a Pagar / Receber', to: 'analytics-charts-comparativepaymentsreceivables', icon: ICON_CIRCLE_SMALL },
          { title: 'Comparativo Títulos em Aberto - Pagar / Receber', to: 'analytics-charts-comparativeopentitlespaymentsreceivables', icon: ICON_CIRCLE_SMALL },
          { title: 'Lançamentos dos Bancos', to: 'analytics-charts-bankslaunchs', icon: ICON_CIRCLE_SMALL },
        ],
      },
    ],
  },
  {
    title: 'Relatórios',
    icon: ICON_PRINTER,
    items: [
      {
        title: 'Cadastros',
        icon: ICON_FOLDER_MULTIPLE,
        items: [
          {
            title: 'Cadastros',
            icon: ICON_CIRCLE_SMALL,
            to: GeneralRegistersReportsSchema.route.name,
          },
          {
            title: 'Produtos',
            icon: ICON_CIRCLE_SMALL,
            to: GeneralProductsReportsSchema.route.name,
          },
        ],
      },
      {
        title: 'Movimentações',
        icon: ICON_STORE,
        items: [
          {
            title: 'Vendas',
            icon: ICON_CIRCLE_SMALL,
            to: GeneralSalesReportsSchema.route.name,
          },
          {
            title: 'Compras',
            icon: ICON_CIRCLE_SMALL,
            to: GeneralPurchasesReportsSchema.route.name,
          },
          {
            title: 'Notas Fiscais',
            icon: ICON_CIRCLE_SMALL,
            to: GeneralInvoicesReportsSchema.route.name,
          },
        ],
      },
      {
        title: 'Estoques',
        icon: ICON_STOCK,
        items: [
          {
            title: 'Itens do Estoque',
            icon: ICON_CIRCLE_SMALL,
            to: GeneralStockItemsReportsSchema.route.name,
          },
          {
            title: 'Histórico de Estoque',
            icon: ICON_CIRCLE_SMALL,
            to: GeneralStocksHistoriesReportsSchema.route.name,
          },
        ],
      },
      {
        title: 'Financeiro',
        icon: ICON_HAND_COIN,
        items: [
          {
            title: 'Contas a Pagar',
            icon: ICON_CIRCLE_SMALL,
            to: GeneralAccountPaymentReportsSchema.route.name,
          },
          {
            icon: ICON_CIRCLE_SMALL,
            title: 'Contas a Receber',
            to: GeneralAccountReceivablesSchema.route.name,
          },
          {
            title: 'Bancos',
            icon: ICON_CIRCLE_SMALL,
            to: GeneralBanksReportsSchema.route.name,
          },
        ],
      },
    ],
  },
  /*
  {
    title: 'Banco Digital',dddd
    icon: ICON_BANK,
    items: [
      { title: 'Contas', to: AccountSchema.routes.list.name },
    ],
  },
  */
];

const skinByTaxRegime = {
  mei: [
    NationalSimpleSchema.titles.items,
    DevolutionPurchaseSchema.titles.items,
    PartialPurchaseSchema.titles.items,
    DevolutionSaleSchema.titles.items,
    PartialSaleSchema.titles.items,
    'Remessas / Consignações',
    'Remessas / Industrializações',
    'Produção',
    OrderProductionSchema.titles.items,
    'Integrações ', // referente ao venda mais
    'Listar Gráficos',
    'Comparativo Contas a Pagar / Receber',
    'Comparativo Títulos em Aberto - Pagar / Receber',
    'Compras dos Clientes',
    'Desempenho dos Vendedores',
    'Lançamentos dos Bancos',
    'Produtos Mais Vendidos',
  ],
  not_mei: [
    'Guias do DAS (MEI)',
  ],
};

function removeObjects(arr, titlesToRemove) {
  arr = arr.filter((obj) => !titlesToRemove.includes(obj.title));
  arr.forEach((obj) => {
    if (obj.items && Array.isArray(obj.items)) {
      obj.items = removeObjects(obj.items, titlesToRemove);
    }
  });

  return arr;
}

const itemsByTaxRegime = async (data) => {
  const token = localStorage.getItem('token');
  if (!token) return;
  const { company: { tax_regime } } = await Me();
  const skin = skinByTaxRegime[tax_regime];
  if (tax_regime !== 'mei') removeObjects(data, skinByTaxRegime.not_mei);
  if (!skin) return data;
  removeObjects(data, skin);
};
itemsByTaxRegime(items);

export default { items, skinByTaxRegime };
export { items, skinByTaxRegime };
