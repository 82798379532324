import NatureOperationSchema from '@schemas/NatureOperation';

const ListNatureOperations = () => import(/* webpackChunkName: "nature-operations-list" */'./ListNatureOperations');
const DetailsNatureOperations = () => import(/* webpackChunkName: "nature-operations-detail" */'./DetailsNatureOperations');
const ListNatureOperationsStates = () => import(/* webpackChunkName: "nature-operations-states-list" */'./components/ListNatureOperationsStates');
const DetailsNatureOperationsStates = () => import(/* webpackChunkName: "nature-operations-states-detail" */'./components/DetailsNatureOperationsStates');

export default [
  {
    path: '/tables/natureoperations',
    name: 'tables-natureoperations-list',
    meta: {
      requiresAuth: true,
      rule: 'nature_operations',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: NatureOperationSchema.titles.items },
      ],
    },
    component: ListNatureOperations,
  },
  {
    path: '/tables/natureoperations/create',
    name: 'tables-natureoperations-create',
    meta: {
      requiresAuth: true,
      rule: 'nature_operations',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: NatureOperationSchema.titles.items, route: 'tables-natureoperations-list' },
        { name: 'Criar Natureza de Operação' },
      ],
    },
    component: DetailsNatureOperations,
  },
  {
    path: '/tables/natureoperations/edit/:id',
    name: 'tables-natureoperations-edit',
    meta: {
      requiresAuth: true,
      rule: 'nature_operations',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: NatureOperationSchema.titles.items, route: 'tables-natureoperations-list' },
        { name: 'Editar Natureza de Operação' },
      ],
    },
    component: DetailsNatureOperations,
  },
  {
    path: '/tables/natureoperations/:natureOperationId/states',
    name: 'tables-natureoperationsstates-list',
    meta: {
      requiresAuth: true,
      rule: 'nature_operations',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: NatureOperationSchema.titles.items, route: 'tables-natureoperations-list' },
        { name: 'Estados' },
      ],
    },
    component: ListNatureOperationsStates,
  },
  {
    path: '/tables/natureoperations/:natureOperationId/states/edit/:id',
    name: 'tables-natureoperationsstates-edit',
    meta: {
      requiresAuth: true,
      rule: 'nature_operations',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: NatureOperationSchema.titles.items },
        { name: 'Estados', route: 'tables-natureoperationsstates-list' },
        { name: 'Editar' },
      ],
    },
    component: DetailsNatureOperationsStates,
  },
];
