import GeneralSalesReportsSchema from './GeneralSalesReportsSchema';

const GeneralSalesReports = () => import(/* webpackChunkName: "reports-sales-general" */'./GeneralSalesReports');

export default [
  {
    path: GeneralSalesReportsSchema.route.path,
    name: GeneralSalesReportsSchema.route.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Relatórios' },
        { name: 'Vendas' },
      ],
    },
    component: GeneralSalesReports,
  },
];
