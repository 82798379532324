import { ICON_CLIPBOARD_CHECK_MULTIPLE_OUTLINE } from '@constants/icons';
import { OPTIONS_RECEIVABLE_STATUS } from '@constants/options';

export default {
  devMode: 'Maintenance',
  service: 'AnticipationsCreditCards',
  icon: ICON_CLIPBOARD_CHECK_MULTIPLE_OUTLINE,
  routes: {
    list: {
      path: '/financial/anticipations/creditcard',
      name: 'financial-anticipationcreditcard-list',
    },
  },
  titles: {
    item: 'Antecipação de Recebíveis de Cartão de Crédito',
    items: 'Antecipações de Recebíveis de Cartão de Crédito',
  },
  statusSettings: {
    options: OPTIONS_RECEIVABLE_STATUS,
    statusField: 'state',
    initialStatus: 'Aberto',
    controlCanceled: true,
  },
};
