import DynamicService from '@sharedServices/dynamic/DynamicService';
import * as AppUtils from '@plugins/app-utils';

const formatResponse = (item) => {
  item.type_nature_formatted = AppUtils.getOptionText('type_nature', item.type_nature);
  item.current_month_total_value = (item.current_month_total_value || 0);
  item.current_year_total_value = (item.current_year_total_value || 0);
  item.billing_total_value = (item.billing_total_value || 0);
  item.current_month_total_value_formatted = AppUtils.formatCurrency(item.current_month_total_value || 0);
  item.current_year_total_value_formatted = AppUtils.formatCurrency(item.current_year_total_value || 0);
  item.billing_total_value_formatted = AppUtils.formatCurrency(item.billing_total_value || 0);

  if (item.commerce_product_taxes && item.commerce_product_taxes.length > 0) {
    const commerceProductsFormatted = item.commerce_product_taxes.map((commerce_product_tax) => {
      const returnedItem = {
        ...commerce_product_tax,
        min_value: commerce_product_tax.min_value || 0,
        min_value_formatted: AppUtils.formatCurrency(commerce_product_tax.min_value || 0),
        max_value_formatted: AppUtils.formatCurrency(commerce_product_tax.max_value || 0),
        aliquot_formatted: AppUtils.formatCurrency(commerce_product_tax.aliquot || 0),
        irpj_aliquot_formatted: AppUtils.formatCurrency(commerce_product_tax.irpj_aliquot || 0),
        csll_aliquot_formatted: AppUtils.formatCurrency(commerce_product_tax.csll_aliquot || 0),
        cofins_aliquot_formatted: AppUtils.formatCurrency(commerce_product_tax.cofins_aliquot || 0),
        pis_aliquot_formatted: AppUtils.formatCurrency(commerce_product_tax.pis_aliquot || 0),
        cpp_aliquot_formatted: AppUtils.formatCurrency(commerce_product_tax.cpp_aliquot || 0),
        icms_aliquot_formatted: AppUtils.formatCurrency(commerce_product_tax.icms_aliquot || 0),
        ipi_aliquot_formatted: AppUtils.formatCurrency(commerce_product_tax.ipi_aliquot || 0),
      };
      return returnedItem;
    });
    item.commerce_product_taxes = commerceProductsFormatted;
  }
  if (item.industry_product_taxes && item.industry_product_taxes.length > 0) {
    const industryProductsFormatted = item.industry_product_taxes.map((industry_product_tax) => {
      const returnedItem = {
        ...industry_product_tax,
        min_value_formatted: AppUtils.formatCurrency(industry_product_tax.min_value || 0),
        max_value_formatted: AppUtils.formatCurrency(industry_product_tax.max_value || 0),
        aliquot_formatted: AppUtils.formatCurrency(industry_product_tax.aliquot || 0),
        irpj_aliquot_formatted: AppUtils.formatCurrency(industry_product_tax.irpj_aliquot || 0),
        csll_aliquot_formatted: AppUtils.formatCurrency(industry_product_tax.csll_aliquot || 0),
        cofins_aliquot_formatted: AppUtils.formatCurrency(industry_product_tax.cofins_aliquot || 0),
        pis_aliquot_formatted: AppUtils.formatCurrency(industry_product_tax.pis_aliquot || 0),
        cpp_aliquot_formatted: AppUtils.formatCurrency(industry_product_tax.cpp_aliquot || 0),
        icms_aliquot_formatted: AppUtils.formatCurrency(industry_product_tax.icms_aliquot || 0),
        ipi_aliquot_formatted: AppUtils.formatCurrency(industry_product_tax.ipi_aliquot || 0),
      };
      return returnedItem;
    });
    item.industry_product_taxes = industryProductsFormatted;
  }
  if (item.billings && item.billings.length > 0) {
    const billingsFormatted = item.billings.map((bill) => {
      const returnedItem = {
        ...bill,
        billing_total_value_formatted: AppUtils.formatCurrency(bill.billing_total_value || 0),
      };
      return returnedItem;
    });
    item.billings = billingsFormatted;
  }
};

const formatRequest = (item) => {
  item.commerce_product_taxes = item.commerce_product_taxes || [];
  item.commerce_product_taxes.forEach((i) => {
    i.type_tax = 'commerce_product';
  });

  item.industry_product_taxes = item.industry_product_taxes || [];
  item.industry_product_taxes.forEach((i) => {
    i.type_tax = 'industry_product';
  });

  item.service_taxes = item.service_taxes || [];
  item.service_taxes.forEach((i) => {
    i.type_tax = 'service_tax';
  });

  item.billings = item.billings || [];

  item.simple_national = {
    commerce_product_taxes_attributes: item.commerce_product_taxes,
    industry_product_taxes_attributes: item.industry_product_taxes,
    service_taxes_attributes: item.service_taxes,
    billings_attributes: item.billings,
    billing_total_value: item.billing_total_value,
    current_month_total_value: item.current_month_total_value,
    current_year_total_value: item.current_year_total_value,
  };
};

const NationalSimple = DynamicService('simple_national', {
  formatResponse,
  formatRequest,
  ignoreItemId: true,
  usePutOnCreate: 'put',
});

export default NationalSimple;
