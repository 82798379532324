import * as AppUtils from '@plugins/app-utils';
import { numberInFullMonetary } from '@plugins/transcribeNumberToWord';

const endpoint = 'account_payments';

export default ($axios) => ({
  async index() {
    let responseData = {};
    await $axios.get(`${endpoint}.json`).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },
  async indexPaginable(params) {
    const {
      optionsPaginable,
    } = params;
    const payloadPaginable = `?page=${optionsPaginable.page}&per_page=${optionsPaginable.per_page}`;
    let responseData = {};
    let paginator = {};
    await $axios.get(`${endpoint}.json${payloadPaginable}`).then((response) => {
      const { headers } = response;
      paginator = {
        page: response.data?.page ?? headers['icertusweb-current-page'],
        per_page: response.data?.per_page ?? headers['icertusweb-items-per-page'],
        total_items: response.data?.total_items ?? headers['icertusweb-total-items'],
        total_pages: response.data?.total_pages ?? headers['icertusweb-total-pages'],
      };
      const { data } = this.formatResponseData(response.data);
      responseData = data;
    });
    return { data: [...responseData], paginator };
  },

  create(data) {
    const payload = this.formatRequestData(data);
    return $axios.post(`${endpoint}.json`, this.formatRequestData(payload));
  },
  async show(id) {
    let responseData = {};
    await $axios.get(`${endpoint}/${id}.json`).then((response) => {
      responseData = this.formatResponseData(response.data, 'row');
      responseData.installments_attributes = responseData.installments;
    });
    return responseData;
  },
  async clone(id) {
    let responseData = {};
    await $axios.get(`${endpoint}/${id}/clone.json`).then((response) => {
      responseData = this.formatResponseData(response.data, 'row');
      responseData.installments_attributes = this.formatResponseCloneInstallments(responseData.installments);
    });
    return responseData;
  },
  async update(id, params) {
    let responseData = {};
    const payload = this.formatRequestData(params, 'update');
    await $axios.put(`${endpoint}/${id}.json`, payload).then((response) => {
      responseData = this.formatResponseData(response.data, 'row');
      responseData.installments_attributes = responseData.installments;
    });
    return responseData;
  },
  delete(id) {
    return $axios.delete(`${endpoint}/${id}.json`);
  },
  async cancel(id) {
    let responseData = {};
    await $axios.get(`${endpoint}/${id}/cancel.json`).then((response) => {
      responseData = this.formatResponseAccountStatus(response.data);
    });
    return responseData;
  },
  async getByFilter(filters, order) {
    const payload = {
      q: filters || {},
    };
    // add sort option
    if (order) {
      payload.q.s = order;
    }

    let responseData = {};
    await $axios.post(`${endpoint}/filters.json`, payload).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },

  async pay(data) {
    const payload = this.formatRequestPayOrReverse(data);
    return $axios.post(`${endpoint}/installments/pay.json`, payload);
  },

  async transfer(data) {
    const payload = this.formatRequestPayOrReverse(data);
    return $axios.post(`${endpoint}/installments/transfer.json`, payload);
  },

  async reverse(data) {
    const payload = this.formatRequestPayOrReverse(data);
    return $axios.post(`${endpoint}/installments/reverse.json`, payload);
  },

  async getTransfersInfo() {
    return $axios.get(`${endpoint}/installments/transfers_info.json`);
  },

  async getTransferScheduled() {
    return $axios.get(`${endpoint}/installments/transfer_scheduled.json`);
  },

  async getTransferApprovals() {
    return $axios.get(`${endpoint}/installments/transfer_approvals.json`);
  },

  async getReceipt(installmentId) {
    return $axios.get(`${endpoint}/installments/transfer/receipt/${installmentId}.json`);
  },

  async payWithDigitableLine(data) {
    const payload = this.formatRequestPayOrReverse(data);
    return $axios.post(`${endpoint}/installments/digitable_line_payment.json`, payload);
  },

  /**
   * Format Functions
   */
  formatRequestPayOrReverse(data) {
    const formattedPayload = {
      account_payment_installment: {
        bank_id: data.bank_id,
        account_payment_id: data.account_payment_id,
        id: data.id,
        title: data.title,
        due_date: data.due_date,
        payment_date: data.payment_date,
        compensation_date: data.compensation_date,
        payment_method_id: data.payment_method_id,
        source_due_date: data.source_due_date,
        value: data.value,
        total_value: data.total_value,
        difference_value: data.difference_value,
        type_account_payment_installment: data.type_account_payment_installment,
        day_delay: data.day_delay,
        digitable_line: data.digitable_line,
      },
    };

    if (data?.pix_key !== '' && data?.pix_type !== '') {
      formattedPayload.account_payment_installment.pix_installment = {
        pix_key: data.pix_key,
        pix_type: data.pix_type,
        save_pix: data.save_pix,
      };
    }
    if (data?.bank_info_installment?.id !== '' && data?.bank_info_installment?.type !== '') {
      formattedPayload.account_payment_installment.bank_info_installment = { ...data.bank_info_installment };
    }

    return formattedPayload;
  },
  formatResponseData(data, type = 'table') {
    const format = (item) => {
      item.issuance_date = AppUtils.formatDate(item.issuance_date);
      item.discharge_date = AppUtils.formatDate(item.discharge_date);
      item.total_title_value_formatted = AppUtils.formatCurrency(item.total_title_value) || 'R$ 0,00';
      item.total_installment_value_formatted = AppUtils.formatCurrency(item.total_installment_value) || 'R$ 0,00';
      item.total_paid_value_formatted = AppUtils.formatCurrency(item.total_paid_value) || 'R$ 0,00';
      item.total_difference_value_formatted = AppUtils.formatCurrency(item.total_difference_value) || 'R$ 0,00';
      item.total_leftover_formatted = AppUtils.formatCurrency(item.total_leftover_value) || 'R$ 0,00';
      item.competency_date = AppUtils.formatDate(item.competency_date);
      item.status_formatted = this.getTypeAccountPaymentStatus(item.status);
      item.register.cnpj_cpf_formatted = AppUtils.formatCnpjCpf(item.register.cnpj_cpf);

      if (item.installments) {
        item.installments.forEach((i) => {
          i.register = item.register;
          i.account_payment_id = item.id;
          i.compensation_date = AppUtils.formatDate(i.compensation_date) || '';
          i.bank.to_s = i.bank.to_s || '-';
          i.payment_method.to_s = i.payment_method.to_s || '-';
          i.day_delay = i.day_delay > 0 ? `${i.day_delay} dias atrasado` : '-';
          i.due_date = AppUtils.formatDate(i.due_date);
          i.due_date_tmp = i.due_date;
          i.source_due_date = AppUtils.formatDate(i.source_due_date);
          i.payment_date = AppUtils.formatDate(i.payment_date) || '';
          i.value_formatted = AppUtils.formatCurrency(i.value);
          i.value_number_in_full = numberInFullMonetary(i.value);
          i.total_value_number_in_full = numberInFullMonetary(i.total_value);
          i.difference_value_formatted = AppUtils.formatCurrency(i.difference_value) || 'R$ 0,00';
          i.difference_value_tmp = i.difference_value_formatted;
          i.total_value_formatted = AppUtils.formatCurrency(i.total_value) || 'R$ 0,00';
          i.type_installment = this.getTypeAccountPaymentInstallment(i.type_account_payment_installment) || '-';
          i.paid_off = i.status === 'settled';
          i.status_formatted = this.getTypeAccountPaymentInstallmentStatus(i.status);
        });
      }
    };

    let responseData = {};

    if (type === 'table') {
      data.forEach((item) => {
        format(item);
      });
      responseData = { data };
      return responseData;
    }
    format(data);
    return data;
  },
  formatRequestData(data, action = 'create') {
    const accountPayment = data;

    if (data.installments_attributes) {
      const sum = data.installments_attributes.reduce((accumulator, object) => accumulator + object.total_value, 0);
      data.total_title_value = sum;
    }

    data.total_installment_value = data.total_title_value;
    data.status = this.getTypeAccountPaymentValueStatus(data.status);

    if (action === 'create') {
      data.total_leftover_value = data.total_title_value;
      delete data.id;

      if (data.installments_attributes) {
        data.installments_attributes.forEach((item) => {
          delete item.id;
          delete item.total_installment_value_formatted;
          delete item.to_s;
          delete item.bank;
          delete item.payment_method;
          delete item.value_formatted;
          delete item.difference_value_formatted;
          delete item.total_value_formatted;
          delete item.type_installment;
          delete item.status;
          delete item.status_formatted;
          item.total_value = item.value;
        });
      }
    } else {
      delete data.id;
      delete data.accounting_account;
      delete data.cost_center;
      delete data.installments;
      delete data.paid_value_formatted;
      delete data.register;
      delete data.status;
      delete data.status_formatted;

      if (data.installments_attributes) {
        data.installments_attributes.forEach((item) => {
          delete item.total_installment_value_formatted;
          delete item.to_s;
          delete item.bank;
          delete item.payment_method;
          delete item.value_formatted;
          delete item.difference_value_formatted;
          delete item.total_value_formatted;
          delete item.type_installment;
          delete item.status;
          delete item.status_formatted;
        });
      }
    }
    return accountPayment;
  },
  formatResponseAccountStatus(data) {
    data.status = this.getTypeAccountPaymentStatus(data.status);
    return data;
  },

  formatResponseCloneInstallments(data) {
    if (data) {
      data.forEach((item) => {
        item.compensation_date = '-';
        item.payment_date = '-';
        item.difference_value = 0;
        item.bank = [];
        item.payment_method = [];
        item.total_value = item.value;
        item.status = this.getTypeAccountPaymentInstallmentStatus(item.status);

        delete item.bank_id;
        delete item.payment_method_id;
      });
    }

    return data;
  },
  getTypeAccountPaymentStatus(value) {
    if (!value) return '';
    return AppUtils.getOptionText('type_account_status', value);
  },
  getTypeAccountPaymentValueStatus(text) {
    if (!text) return '';
    return AppUtils.getOptionValue('type_account_status', text);
  },
  getTypeAccountPaymentInstallment(value) {
    if (!value) return '';
    return AppUtils.getOptionText('type_account_payment_installments', value);
  },
  getTypeAccountPaymentInstallmentStatus(value) {
    if (!value) return '';
    return AppUtils.getOptionText('type_account_payment_installments_status', value);
  },
  getTypeAccountPaymentInstallmentStatusValue(text) {
    if (!text) return '';
    return AppUtils.getOptionValue('type_account_payment_installments_status', text);
  },
});
