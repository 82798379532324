<template>
  <v-combobox
    v-model="fieldValue"
    :hint="field.hint"
    :persistent-hint="true"
    :disabled="checkDisabled()"
    :label="field.label"
    :class="field.class"
    :rules="validationRules"
    multiple
    append-icon=""
    clearable
    :rounded="field.rounded"
    :filled="field.filled"
    :prepend-icon="innerIcon?'':prependIcon"
    :prepend-inner-icon="innerIcon?prependIcon:''"
    v-bind="$attrs"
    v-on="$listeners"
    @input="fieldChanged">
    <template slot="selection" slot-scope="data">
      <v-chip :selected="data.selected" close @input="removeChip(data.item, field)">
        <strong>{{ data.item }}</strong>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import DynamicFieldMixin from './DynamicFieldMixin';

export default {
  name: 'DynamicChips',

  mixins: [DynamicFieldMixin],

  data() {
    return {
      valid: true,
      fieldValue: [],
    };
  },
  computed: {
    prependIcon() {
      return this.field.prependIcon === undefined ? this.defaultIcon : this.field.prependIcon;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
  },
  methods: {
    removeChip(item) {
      this.fieldValue.splice(this.fieldValue.indexOf(item), 1);
      this.fieldValue = [...this.fieldValue];
      this.$emit('input', this.fieldValue);
    },
  },
};
</script>

<style lang="scss">
</style>
