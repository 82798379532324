import CnabsSchema from '@schemas/Cnabs';

export default [
  {
    path: CnabsSchema.routes.list.path,
    name: CnabsSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'all_types',
      breadcrumb: [
        { name: 'Configurações' },
        { name: CnabsSchema.titles.items },
      ],
    },
    component: () => import(/* webpackChunkName: "list-cnabs" */'./ListCnabs'),
  },
  {
    path: CnabsSchema.routes.create.path,
    name: CnabsSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'all_types',
      breadcrumb: [
        { name: 'Configurações' },
        { name: 'CNABs - Boletos Bancários', link: '/settings/cnabs' },
        { name: 'Criar CNAB' },
      ],
    },
    component: () => import(/* webpackChunkName: "details-cnabs" */'./DetailCnabs'),
  },
  {
    path: CnabsSchema.routes.edit.path,
    name: CnabsSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'all_types',
      breadcrumb: [
        { name: 'Configurações' },
        { name: CnabsSchema.titles.items, link: '/settings/cnabs' },
        { name: 'Editar CNAB' },
      ],
    },
    component: () => import(/* webpackChunkName: "detail-cnabs" */'./DetailCnabs'),
  },
];
