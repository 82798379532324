<template>
  <div :id="item.id">
    <GenericCardPanel
      title="INFORMAÇÕES DA NOTA FISCAL"
      :fields="invoiceInfoFields" />

    <GenericCardPanel
      title="INFORMAÇÕES DE DESTINATÁRIO / REMETENTE"
      :fields="recipientInfoFields" />

    <GenericCardPanel
      title="DADOS ADICIONAIS"
      :fields="additionalDataFields" />
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabInvoice',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      invoiceInfoFields: [],
      recipientInfoFields: [],
      additionalDataFields: [],
    };
  },
  mounted() {
    this.invoiceInfoFields = [
      { label: 'NF-e', value: this.item.invoice_number, col: 2 },
      { label: 'Chave de acesso', value: this.item.invoice_key, col: 4 },
      { label: 'Modelo', value: this.item.model, col: 2 },
      { label: 'Tipo', value: this.item.document_type_formatted, col: 2 },
      { label: 'Série', value: this.item.series, col: 2 },
      { label: 'Data de Emissão', value: this.item.emission_date_formatted, col: 2 },
      { label: 'Hora da Emissão', value: this.item.emission_date_hour, col: 2 },
      { label: 'Data de Saída', value: this.item.exit_date_formatted, col: 2 },
      { label: 'Hora de Saída', value: this.item.exit_date_hour, col: 2 },
    ];

    this.recipientInfoFields = [
      { label: 'Nome / Razão Social', value: this.item.buyer.name, col: 4 },
      { label: 'CNPJ/CPF', value: this.item.buyer.cnpj_cpf, col: 2 },
      { label: 'IE/RG', value: this.item.buyer.ie, col: 2 },
      { label: 'Consumidor Final', value: this.item.is_costumer_formatted, col: 2 },
      { label: 'Logradouro', value: this.item.buyer.street, col: 4 },
      { label: 'Número', value: this.item.buyer.street, col: 2 },
      { label: 'Complemento', value: this.item.buyer.complement, col: 2 },
      { label: 'CEP', value: this.item.buyer.zip_code, col: 2 },
      { label: 'Bairro', value: this.item.buyer.neighborhood, col: 4 },
      { label: 'Município', value: this.item.buyer.city, col: 2 },
      { label: 'UF', value: this.item.buyer.uf, col: 2 },
      { label: 'País', value: this.item.buyer.country, col: 2 },
      { label: 'Tipo de Contribuinte', value: this.item.buyer.taxpayer_type_formatted, col: 4 },
      { label: 'Telefone/Fax', value: this.item.buyer.telephone, col: 2 },
      { label: 'Indicador de Presença', value: this.item.presence_indicator_formatted, col: 2 },

    ];

    this.additionalDataFields = [
      { label: 'Informações para o Contribuinte', value: this.item.information_contribution, col: 4 },
      { label: 'Informações para o Fisco', value: this.item.information_fisco, col: 4 },
    ];
  },
  methods: {
    print(id) {
      this.$printer(id);
    },
  },
};
</script>

<style scoped></style>
