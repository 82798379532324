const endpoint = 'magis';

const Magis5Commands = ($axios) => ({
  async validateToken(token) {
    const payload = {
      token,
    };
    const res = await $axios.post(`${endpoint}/login`, payload);
    return res.data;
  },
  async getTokenStatus() {
    const res = await $axios.get(`${endpoint}/login`);
    return res.data;
  },
  async refreshOrders() {
    const res = await $axios.get(`${endpoint}/orders`);
    return res.data;
  },
});

export { Magis5Commands };
export default { Magis5Commands };
