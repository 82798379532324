import {
  ICON_DESCRIPTION, ICON_KEY_LINK, ICON_TRANSFER,
} from '@constants/icons';
import { OPTIONS_BTG_ACCOUNT_TYPE, OPTIONS_BTG_ACCOUNT_CURRENCY, OPTIONS_BTG_ACCOUNT_STATUS } from '@constants/options';
import TabBtgAccount from '@modules/integrations/pages/btg/components/TabBtgAccount';
import TabBtgAccountStatements from '@modules/integrations/pages/btg/components/TabBtgAccountStatements';

export default {
  service: 'BtgIntegrationAccounts',
  icon: ICON_KEY_LINK,
  noActions: true,
  noCreate: true,
  noEdit: true,
  noDelete: true,
  disabled: true,
  hideFilters: true,
  hideSaveButton: true,
  useExternalItems: true,
  hideReportsOnList: true,
  hideReportsOnCreate: true,
  hideReportsOnUpdate: true,
  routes: {
    list: {
      name: 'integrations-btg-accounts',
      path: '/integrations/btg/accounts',
    },
  },
  titles: {
    item: 'Conta BTG',
    items: 'Contas BTG',
    list: 'Listar Contas',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    {
      label: 'ID da Conta',
      name: 'account_id',
      type: 'text',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 8,
      tabGroup: 'info',
    },
    // {
    //   label: 'CPF da Conta',
    //   name: 'tax_id',
    //   type: 'text',
    //   list: true,
    //   required: true,
    //   component: 'dynamic-text-field',
    //   md: 4,
    //   tabGroup: 'info',
    // },
    {
      label: 'Código do Banco',
      name: 'bank_code',
      type: 'text',
      required: true,
      component: 'dynamic-text-field',
      md: 4,
      tabGroup: 'info',
    },
    {
      label: 'Tipo',
      name: 'type',
      formattedName: 'type_formatted',
      type: 'select',
      component: 'dynamic-select',
      items: OPTIONS_BTG_ACCOUNT_TYPE,
      options_key: 'btg_account_type',
      required: true,
      list: true,
      md: 3,
      tabGroup: 'info',
    },
    {
      label: 'Moeda',
      name: 'currency',
      formattedName: 'currency_formatted',
      type: 'select',
      component: 'dynamic-select',
      items: OPTIONS_BTG_ACCOUNT_CURRENCY,
      options_key: 'btg_account_currency',
      required: true,
      md: 3,
      tabGroup: 'info',
    },
    {
      label: 'Código Branch',
      name: 'branch_code',
      type: 'text',
      required: true,
      component: 'dynamic-text-field',
      md: 4,
      tabGroup: 'info',
    },
    {
      label: 'Número',
      name: 'number',
      type: 'text',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
      tabGroup: 'info',
    },
    {
      label: 'Status',
      name: 'status',
      formattedName: 'status_formatted',
      type: 'select',
      component: 'dynamic-select',
      items: OPTIONS_BTG_ACCOUNT_STATUS,
      options_key: 'btg_account_status',
      required: true,
      list: true,
      md: 3,
      tabGroup: 'info',
    },
    {
      label: 'Saldo Disponível',
      name: 'balance.available',
      formattedName: 'balance.available_formatted',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Saldo Bloqueado',
      name: 'balance.blocked',
      formattedName: 'balance.blocked_formatted',
      list: true,
      required: true,
      component: 'dynamic-text-field',
      md: 4,
    },
  ],
  // actions: [
  //   {
  //     type: 'route',
  //     icon: ICON_DESCRIPTION,
  //     color: 'secondary',
  //     route: 'integrations-btg-listaccount',
  //     target: '_blank',
  //     ariaLabel: 'Detalhes',
  //   },
  //   {
  //     type: 'route',
  //     icon: ICON_TRANSFER,
  //     route: 'integrations-btg-accountstatements',
  //     ariaLabel: 'Transferências',
  //     color: 'secondary',
  //   },
  //   {
  //     type: 'route',
  //     icon: ICON_BOOK_OUTLINE,
  //     route: 'integrations-btg-detailbillets',
  //     ariaLabel: 'Boletos',
  //     color: 'secondary',
  //   },
  // ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes da Conta',
      tabContent: TabBtgAccount,
    },
    {
      icon: ICON_TRANSFER,
      title: 'Lançamentos',
      tabContent: TabBtgAccountStatements,
    },
  ],
};
