import {
  OPTIONS_UF,
  OPTIONS_FORMAT_MODALITY_MANIFEST,
  OPTIONS_EMMIT_MANIFEST_FORMAT,
  OPTIONS_EMMIT_TYPE,
  OPTIONS_MANIFEST_TRANSPORTER_TYPE,
} from '@constants/options';

export default [
  // line 1
  {
    label: 'Data Emissão',
    name: 'emission_date',
    formattedName: 'emission_date_formatted',
    type: 'date',
    component: 'dynamic-date-field',
    list: true,
    cols: 2,
    colClass: 'nf-field top left',
    prependIcon: '',
  },
  {
    label: 'Hora Emissão',
    name: 'emission_date_hour',
    component: 'dynamic-time-picker',
    format: '24hr',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Data Início Viagem',
    name: 'exit_date',
    formattedName: 'exit_date_formatted',
    type: 'date',
    component: 'dynamic-date-field',
    list: true,
    clearable: true,
    cols: 2,
    colClass: 'nf-field top left',
    prependIcon: '',
  },
  {
    label: 'Hora Início Viagem',
    name: 'exit_date_hour',
    component: 'dynamic-time-picker',
    format: '24hr',
    clearable: true,
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Código Númérico',
    name: 'manifest.code_number',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Dígito Verificador',
    name: 'manifest.verification_digit',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left right',
  },
  // line 2
  {
    label: 'UF',
    name: 'manifest_uf',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_UF,
    cols: 1,
    prependIcon: '',
    required: true,
    colClass: 'nf-field top left bottom',
  },
  {
    label: 'Tipo de Emitente',
    name: 'emmit_type',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_EMMIT_TYPE,
    cols: 3,
    prependIcon: '',
    required: true,
    colClass: 'nf-field top left bottom',
  },
  {
    label: 'Tipo de Transportador',
    name: 'transporter_type',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_MANIFEST_TRANSPORTER_TYPE,
    cols: 3,
    prependIcon: '',
    required: true,
    colClass: 'nf-field top left bottom',
  },
  {
    label: 'Modalidade',
    name: 'modality',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_FORMAT_MODALITY_MANIFEST,
    cols: 2,
    prependIcon: '',
    required: true,
    colClass: 'nf-field top left bottom',
  },
  {
    label: 'Forma de Emissão',
    name: 'form_issuance',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_EMMIT_MANIFEST_FORMAT,
    cols: 3,
    prependIcon: '',
    required: true,
    colClass: 'nf-field top right left bottom',
  },
];
