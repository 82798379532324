import { ICON_GAVEL, ICON_DESCRIPTION, ICON_MEASURING_UNIT } from '@constants/icons';
import BiddingItemSchema from './BiddingItem';
import BiddingFileSchema from './BiddingFile';

import TabBiddings from '@modules/sellmore/pages/biddings/components/TabBiddings';
import TabBiddingsItems from '@modules/sellmore/pages/biddings/components/TabBiddingsItems';
import TabBiddingsFiles from '@modules/sellmore/pages/biddings/components/TabBiddingsFiles';

export default {
  service: 'Biddings',
  icon: ICON_GAVEL,
  routes: {
    list: {
      name: 'sellmore-biddings-list',
      path: '/sellmore/biddings',
    },

  },
  noCreate: true,
  noEdit: true,
  noDelete: true,
  titles: {
    item: 'Licitação',
    items: 'Licitações',
    new: 'Novo',
  },
  fields: [
    {
      label: 'Orgão',
      name: 'organ',
      type: 'text',
      list: true,
    },
    {
      label: 'Estado',
      name: 'state',
      type: 'boolean',
      list: true,
    },
    {
      label: 'Código',
      name: 'code',
      type: 'text',
    },
    {
      label: 'Licitação',
      name: 'number_bidding',
      type: 'text',
      list: true,
    },
    {
      label: 'Data Início',
      name: 'start_date',
      type: 'text',
      list: true,
    },
    {
      label: 'Data Edição',
      name: 'edition_date',
      type: 'text',
      list: true,
    },
    {
      label: 'Modalidade',
      name: 'modality',
      type: 'text',
      list: true,
    },
    {
      label: 'Link',
      name: 'link',
      type: 'text',
    },
  ],
  childSchemas: [
    {
      label: 'Itens',
      name: 'items_bidding',
      schema: BiddingItemSchema,
      noCreate: true,
      noEdit: true,
      noDelete: true,
    },
    {
      label: 'Outros links',
      name: 'file_links',
      schema: BiddingFileSchema,
      noCreate: true,
      noEdit: true,
      noDelete: true,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabBiddings,
    },
    {
      icon: ICON_MEASURING_UNIT,
      title: 'Itens da licitação',
      tabContent: TabBiddingsItems,
    },
    {
      icon: ICON_MEASURING_UNIT,
      title: 'Arquivos da licitação',
      tabContent: TabBiddingsFiles,
    },
  ],
};
