import {
  ICON_DESCRIPTION,
  ICON_MEASURING_UNIT,
  ICON_TAXES, ICON_TRUCK,
} from '@constants/icons';
import { OPTIONS_PRESENCE_INDICATOR, OPTIONS_FREIGHT, OPTIONS_ORDERBUDGET_STATUS } from '@constants/options';
import BudgetBusiness from '@businesses/OrderBudget'; // Use same business rules
import BudgetItem from './OrderBudgetItem';
import BudgetItemTax from './OrderBudgetItemTax';
import BudgetTransporter from './BudgetTransporter';

import TabBudget from '@modules/supply/pages/orderbudgets/components/TabBudget';
import TabBudgetItems from '@modules/supply/pages/orderbudgets/components/TabBudgetItems';
import TabBudgetItemsTax from '@modules/supply/pages/orderbudgets/components/TabBudgetItemsTax';
import TabBudgetTransporter from '@modules/supply/pages/orderbudgets/components/TabBudgetTransporter';

import ObservationSchema from './Observation';
import PriceListSchema from './PriceList';
import OrderSale from './OrderSale';

export default {
  service: 'OrderBudgets',
  formName: 'budget',
  business: BudgetBusiness,
  icon: OrderSale.icon,
  canClone: true,
  statusSettings: {
    options: OPTIONS_ORDERBUDGET_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
    statusDetails: true,
  },
  routes: {
    list: { path: '/supply/budget', name: 'supply-budget-list' },
    create: { path: '/supply/budget/create', name: 'supply-budget-create' },
    edit: { path: '/supply/budget/edit/:id', name: 'supply-budget-edit' },
  },
  titles: {
    item: 'Orçamento',
    items: 'Orçamentos',
    edit: 'Editar Orçamento',
    create: 'Criar Orçamento',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Orçamento excluido com sucesso!',
    confirmDelete: 'Deseja excluir o Orçamento?',
    successEdit: 'Orçamento alterado com sucesso!',
    successCreate: 'Orçamento criado com sucesso!',
    errorSave: 'Erro ao salvar Orçamento!',
    confirmCancel: 'Deseja cancelar o Orçamento?',
    successCancel: 'Orçamento cancelada com sucesso',
    errorCancel: 'Erro ao cancelar Orçamento',
    temporaryLaunch: 'Atualmente esse módulo se encontra em fase de testes, em breve disponibilizaremos o acesso total.',
  },
  fields: [
    {
      label: 'INFORMAÇÕES DO ORÇAMENTO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'infoBudget',
    },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options_key: 'order_budget_status',
      list: true,
    },
    {
      label: 'Cliente',
      name: 'buyer_id',
      formattedName: 'buyer.to_s',
      type: 'number',
      list: true,
      required: true,
      hint: '',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_register_array: 'client',
          query: ['id', 'name', 'cnpj_cpf', 'addresses', ['id', 'to_s'], 'preference', ['salesman_id', 'shipping_company_id']],
        }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      withDetails: true,
      typeDetails: 'client',
      md: 8,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Pedido',
      name: 'budget_order',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 2,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Data Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      list: true,
      md: 2,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Vendedor',
      name: 'salesman_id',
      formattedName: 'salesman.to_s',
      type: 'number',
      list: true,
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{ type_register_array: 'salesman', query: ['id', 'name'] }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      md: 8,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Data Previsão',
      name: 'forecast_date',
      formattedName: 'forecast_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Consumidor Final',
      name: 'is_costumer',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Indicador de Presença',
      name: 'presence_indicator',
      formattedName: 'presence_indicator_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_PRESENCE_INDICATOR,
      required: true,
      md: 5,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Nº da Ordem',
      name: 'order',
      type: 'text',
      component: 'dynamic-text-field',
      md: 2,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Data Entrega',
      name: 'delivery_date',
      formattedName: 'delivery_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      md: 2,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Forma de Pagamento',
      name: 'payment_method_id',
      formattedName: 'payment_method.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-paymentmethod-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['payment_method'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Parcelamento',
      name: 'type_installment_id',
      formattedName: 'type_installment.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      redirectCreateRoute: 'tables-typeinstallment-create',
      dataSource: {
        service: 'TypeInstallment',
        method: 'getByFilter',
        props: [{ inactive_eq: false }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      formattedName: 'stock.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      md: 3,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Lista de Preço',
      name: 'price_list_id',
      formattedName: 'price_list.to_s',
      type: 'number',
      component: 'dynamic-select',
      clearable: true,
      dataSource: {
        service: 'PriceList',
        method: 'getByFilter',
        props: [{
          is_inactive_eq: false,
        }, 'name asc'],
      },
      itemText: 'name',
      itemValue: 'id',
      md: 3,
      redirectCreateRoute: PriceListSchema.routes.create.name,
      tabGroup: 'infoBudget',
    },
    {
      label: 'Total de Impostos Aproximados',
      formattedName: 'total_tax_aproximates',
      name: 'total_tax_aproximates',
      type: 'number',
    },
    {
      label: 'Valor Total',
      formattedName: 'total_ordered_formatted',
      type: 'number',
      list: true,
      tabGroup: 'infoBudgetTax',
    },
    {
      label: 'INFORMAÇÕES DO FRETE',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'infoFreight',
    },
    {
      label: 'Frete',
      name: 'freight',
      formattedName: 'freight_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_FREIGHT,
      required: true,
      md: 3,
      tabGroup: 'infoFreight',
    },
    {
      label: 'Valor do Frete',
      name: 'freight_value',
      formattedName: 'freight_value_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
      tabGroup: 'infoFreight',
    },
    {
      label: 'Despesas',
      name: 'expense_value',
      formattedName: 'expense_value_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
      tabGroup: 'infoFreight',
    },
    {
      label: 'Incluir Frete no Total',
      name: 'use_freight',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 3,
      tabGroup: 'infoFreight',
    },
    {
      label: 'TRIBUTAÇÕES DO ORÇAMENTO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'infoBudgetTax',
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      formattedName: 'nature_operation.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_nature_in: ['sale'],
          query: ['id', 'name'],
        }],
      },
      itemText: 'name',
      itemValue: 'id',
      list: true,
      required: true,
      md: 12,
      tabGroup: 'infoBudgetTax',
    },
  ],
  childSchemas: [
    {
      label: 'Itens do Orçamento',
      name: 'items',
      schema: BudgetItem,
    },
    {
      label: 'Conferência de Impostos',
      name: 'taxes',
      noCreate: true,
      noEdit: true,
      noDelete: true,
      noActions: true,
      schema: BudgetItemTax,
      hideDefaultHeader: true,
      customHeader: {
        customClass: 'custom-child-header-order-sale',
        ungroupedHeadersFront: 2,
        groupHeaders: [
          { text: 'SIMPLES NACIONAL', colspan: 3, class: 'order-sale-header' },
          { text: 'ICMS', colspan: 3, class: 'order-sale-header' },
          { text: 'ICMS ST', colspan: 3, class: 'order-sale-header' },
          { text: 'IPI', colspan: 3, class: 'order-sale-header' },
          { text: 'PIS', colspan: 3, class: 'order-sale-header' },
          { text: 'COFINS', colspan: 3, class: 'order-sale-header' },
          { text: 'ICMS CONSUMIDOR FINAL', colspan: 3, class: 'order-sale-header' },
          { text: 'VALOR APROXIMADOS', colspan: 3, class: 'order-sale-header' },
        ],
      },
    },
    {
      label: 'Transportadora',
      name: 'transporter',
      schema: BudgetTransporter,
      hasOne: true,
    },
    {
      label: 'Observações',
      name: 'observations',
      schema: ObservationSchema,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabBudget,
    },
    {
      icon: ICON_MEASURING_UNIT,
      title: 'Itens do Orçamento',
      tabContent: TabBudgetItems,
    },
    {
      icon: ICON_TAXES,
      title: 'Conferência de Impostos',
      tabContent: TabBudgetItemsTax,
    },
    {
      icon: ICON_TRUCK,
      title: 'Transportadora',
      tabContent: TabBudgetTransporter,
    },
  ],
};
