export default [
  // Line 1
  {
    label: 'Informações para o Contribuinte',
    name: 'information_contribution',
    type: 'text',
    component: 'dynamic-text-area',
    cols: 6,
    colClass: 'nf-field top left bottom',
    prependIcon: '',
  },
  {
    label: 'Informações para o Fisco',
    name: 'information_fisco',
    type: 'text',
    component: 'dynamic-text-area',
    cols: 6,
    colClass: 'nf-field top left right bottom',
    prependIcon: '',
  },
];
