const ListBanksSimplified = () => import(/* webpackChunkName: "list-banks" */'./ListBanksSimplified');
const CreateBanks = () => import(/* webpackChunkName: "create-banks" */'./CreateBanks');
const EditBanks = () => import(/* webpackChunkName: "edit-banks" */'./EditBanks');
const FormLaunchs = () => import(/* webpackChunkName: "form-launchs" */ './components/FormLaunchs');
const ListBanksHistories = () => import(/* webpackChunkName: "list-banks-histories" */ './ListBanksHistories');

export default [
  {
    path: '/financial/banks',
    name: 'financial-banks-list',
    meta: {
      requiresAuth: true,
      rule: 'banks',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Bancos' },
      ],
    },
    component: ListBanksSimplified,
  },
  {
    path: '/financial/banks/create',
    name: 'financial-banks-create',
    meta: {
      requiresAuth: true,
      rule: 'banks',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Bancos', link: '/financial/banks' },
        { name: 'Criar Banco' },
      ],
    },
    component: CreateBanks,
  },
  {
    path: '/financial/banks/edit/:id',
    name: 'financial-banks-edit',
    meta: {
      requiresAuth: true,
      rule: 'banks',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Bancos', link: '/financial/banks' },
        { name: 'Editar Banco' },
      ],
    },
    component: EditBanks,
  },
  {
    path: '/financial/banks/launchs',
    name: 'financial-banks-launchs',
    meta: {
      requiresAuth: true,
      rule: 'banks',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Bancos', link: '/financial/banks' },
        { name: 'Histórico de Lançamentos' },
      ],
    },
    component: ListBanksHistories,
  },
  {
    path: '/financial/banks/newlaunch/:id',
    name: 'financial-banks-newlaunch',
    meta: {
      requiresAuth: true,
      rule: 'banks',
      breadcrumb: [
        { name: 'Financeiro' },
        { name: 'Bancos', link: '/financial/banks' },
        { name: 'Histórico de Lançamentos' },
      ],
    },
    component: FormLaunchs,
  },
];
