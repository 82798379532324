export default [
  {
    path: '/tables/accountingaccount/list',
    name: 'tables-accountingaccounts-list',
    meta: {
      requiresAuth: true,
      rule: 'accounting_accounts',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Conta Contábil' },
      ],
    },
    component: () => import(/* webpackChunkName: "list-accounting-account" */'./ListAccountingAccount'),
  },
  {
    path: '/tables/accountingaccount/create',
    name: 'tables-accountingaccounts-create',
    meta: {
      requiresAuth: true,
      rule: 'accounting_accounts',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Conta Contábil', link: '/tables/accountingaccount/list' },
        { name: 'Criar Conta Contábil' },
      ],
    },
    component: () => import(/* webpackChunkName: "create-accounting-account" */'./CreateAccountingAccount'),
  },
  {
    path: '/tables/accountingaccount/edit/:id',
    name: 'tables-accountingaccounts-edit',
    meta: {
      requiresAuth: true,
      rule: 'accounting_accounts',
      breadcrumb: [
        { name: 'Cadastros' },
        { name: 'Conta Contábil', link: '/tables/accountingaccount/list' },
        { name: 'Editar Conta Contábil' },
      ],
    },
    component: () => import(/* webpackChunkName: "create-accounting-account" */'./EditAccountingAccount'),
  },
];
