import {
  ICON_COGS,
} from '@constants/icons';
import { OPTIONS_ORDERSPRODUCTION_STATUS } from '@constants/options';
import OrderProductionBusiness from '@businesses/OrderProduction';

import OrderProductionFactory from './OrderProductionFactory';
import OrderProductionItem from './OrderProductionItem';

export default {
  service: 'OrderProduction',
  business: OrderProductionBusiness,
  icon: ICON_COGS,
  routes: {
    list: { path: '/production/order', name: 'production-order-list' },
    create: { path: '/production/order/create', name: 'production-order-create' },
    edit: { path: '/production/order/edit/:id', name: 'production-order-edit' },
  },
  titles: {
    item: 'Ordem de Produção',
    items: 'Ordens de Produção',
    edit: 'Editar Ordem de Produção',
    create: 'Criar Ordem de Produção',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Ordem de produção excluida com sucesso!',
    successEdit: 'Ordem de produção alterada com sucesso!',
    successCreate: 'Ordem de produção criada com sucesso!',
    errorSave: 'Erro ao salvar ordem de produção!',
    tryEditCanceled: 'Não é possível editar uma ordem de produção cancelada.',
    tryEditFinished: 'Não é possível editar uma ordem de produção finalizada.',
    confirmCancel: 'Deseja cancelar a ordem de produção?',
    confirmIsConsumedFeedstock: 'Deseja confirmar o estorno do produto da ordem de produção?',
    confirmIsEnteredStockItems: 'Deseja confirmar o estorno dos itens da composição do produto da ordem de produção?',
    successCancel: 'Ordem de produção cancelada com sucesso',
    errorCancel: 'Erro ao cancelar ordem de produção',
  },
  filterUrl: 'order_purchases/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  statusSettings: {
    options: OPTIONS_ORDERSPRODUCTION_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  formName: 'orderproduction',
  fields: [
    {
      label: 'ORDEM DE PRODUÇÃO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
    },
    {
      label: 'Status',
      formattedName: 'status',
      name: 'status',
      type: 'select',
      options_key: 'orders_production_status',
      list: true,
    },
    {
      label: 'Produto',
      name: 'product_id',
      formattedName: 'product.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      disabled: false,
      list: true,
      order: 3,
      md: 8,
      columnWidth: 300,
    },
    {
      label: 'Código',
      name: 'product_code',
      formattedName: 'product.code',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'code',
      itemValue: 'id',
      list: true,
      disabled: false,
      md: 4,
      order: 6,
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      formattedName: 'stock.to_s',
      list: true,
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      required: true,
      itemText: 'to_s',
      itemValue: 'id',
      disabled: false,
      md: 4,
      order: 6,
    },
    {
      label: 'Qtd em Estoque',
      name: 'stock_quantity',
      type: 'text',
      component: 'dynamic-text-field',
      disabled: true,
      md: 2,
    },
    {
      label: 'Nº da Ordem',
      name: 'order_number',
      type: 'text',
      component: 'dynamic-text-field',
      required: true,
      list: true,
      md: 3,
      order: 2,
    },
    {
      label: 'Nº OS',
      name: 'os_number',
      type: 'text',
      component: 'dynamic-text-field',
      md: 3,
      list: true,
    },
    {
      label: 'Qtd à Produzir',
      name: 'ordered_quantity',
      type: 'text',
      component: 'dynamic-text-field',
      required: true,
      default: 0,
      list: true,
      md: 2,
      order: 4,
    },
    {
      label: 'Qtd de Perda',
      name: 'lost_quantity',
      type: 'text',
      component: 'dynamic-text-field',
      default: 0,
      md: 2,
      list: true,
    },
    {
      label: 'Qtd Produzida',
      name: 'produced_quantity',
      type: 'text',
      component: 'dynamic-text-field',
      md: 2,
      list: true,
      disabled: true,
    },
    {
      label: 'Saída de Mat. Prima do Estoque',
      name: 'is_consumed_feedstock',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    {
      label: 'Entrada no Estoque',
      name: 'is_entered_stock',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    {
      label: 'Salvar Alterações na Composição',
      name: 'is_enabled_save_composition',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    {
      label: 'DATAS',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Data Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      offsetMd: 2,
      list: true,
      required: true,
      clearable: true,
      md: 2,
      order: 1,
    },
    {
      label: 'Hora Emissão',
      name: 'emission_time',
      formattedName: 'emission_time',
      component: 'dynamic-time-picker',
      format: '24hr',
      required: true,
      list: true,
      clearable: true,
      md: 2,
      order: 1,
    },
    {
      label: 'Previsão',
      name: 'is_enabled_forecast',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    {
      label: 'Data Previsão',
      name: 'forecast_date',
      formattedName: 'forecast_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
      disabled: true,
      md: 2,
    },
    {
      label: 'Hora Previsão',
      name: 'forecast_time',
      formattedName: 'forecast_time',
      component: 'dynamic-time-picker',
      format: '24hr',
      clearable: true,
      list: true,
      disabled: true,
      md: 2,
    },
    {
      label: 'Início',
      name: 'is_enabled_start',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    {
      label: 'Data Início',
      name: 'start_date',
      formattedName: 'start_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
      disabled: true,
      md: 2,
    },
    {
      label: 'Hora Início',
      name: 'start_time',
      formattedName: 'start_time',
      component: 'dynamic-time-picker',
      format: '24hr',
      clearable: true,
      list: true,
      disabled: true,
      md: 2,
    },
    {
      label: 'Final',
      name: 'is_enabled_end',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
      disabled: true,
    },
    {
      label: 'Data Final',
      name: 'end_date',
      formattedName: 'end_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
      disabled: true,
      md: 2,
    },
    {
      label: 'Hora Final',
      name: 'end_time',
      formattedName: 'end_time',
      component: 'dynamic-time-picker',
      format: '24hr',
      disabled: true,
      list: true,
      clearable: true,
      md: 2,
    },
  ],
  childSchemas: [
    {
      label: 'Composição',
      name: 'compositions',
      schema: OrderProductionItem,
    },
    {
      label: 'Processos',
      name: 'procedures',
      noCreate: true,
      schema: OrderProductionFactory,
    },
  ],
};
