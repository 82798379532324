<template>
  <v-text-field
    ref="numberInput"
    v-model.number.lazy="stringFieldValue"
    v-money="numericMask"
    :min="field.min"
    :max="field.max"
    :step="field.step"
    :required="checkRequired()"
    :disabled="checkDisabled()"
    :hint="field.hint"
    :persistent-hint="true"
    :label="field.label"
    :class="field.class"
    :autofocus="field.autofocus"
    :rules="numberValidationRules"
    :readonly="readOnly || field.readOnly"
    :rounded="field.rounded"
    :filled="field.filled"
    :prefix="field.prefix"
    :prepend-icon="innerIcon ? '' : prependIcon"
    :prepend-inner-icon="innerIcon ? prependIcon : ''"
    :loading="loading"
    v-bind="$attrs"
    v-on="$listeners"
    @change="parseBeforeChange" />
</template>

<script>
import { ICON_FORM_NUMERIC } from '@constants/icons';
import { set, get } from '@sharedServices/dynamic/LocalForage';
import DynamicFieldMixin from './DynamicFieldMixin';
import FormatNumber from '@filters/FormatNumber';

export default {
  name: 'DynamicNumberField',
  mixins: [DynamicFieldMixin],
  data() {
    return {
      fieldValue: 0,
      stringFieldValue: '',
      emptyValue: 0,
      defaultIcon: ICON_FORM_NUMERIC,
      precision: 0,
      loading: false,
    };
  },
  computed: {
    numericMask() {
      return {
        decimal: this.field.decimal || ',',
        thousands: this.field.thousands || '.',
        prefix: this.field.prefix || '',
        suffix: this.field.suffix || '',
        precision: this.precision,
        masked: false,
      };
    },
    prependIcon() {
      return this.field.prependIcon === undefined ? this.defaultIcon : this.field.prependIcon;
    },
    appendIcon() {
      return this.field.appendIcon;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
    numberValidationRules() {
      const rules = this.validationRules || [];
      const { min, max } = this.field;
      if (min) {
        rules.push((v) => (v && parseFloat(v.replace('.', '').replace(',', '.')) >= min ? true : `O valor deve ser maior ou igual a ${min}`));
      }
      if (max) {
        rules.push((v) => (v && parseFloat(v.replace('.', '').replace(',', '.')) <= max ? true : `O valor deve ser menor ou igual a ${max}`));
      }
      return rules;
    },
  },
  watch: {
    fieldValue(value) {
      this.fieldValueChanged(value);
    },
  },
  mounted() {
    this.getPrecision();
  },
  methods: {
    parseBeforeChange(value) {
      const unformatted = typeof value === 'string' ? FormatNumber.unformat(value, this.numericMask) : value;
      this.fieldChanged(unformatted);
    },
    async getPrecision() {
      this.precision = (await get(this.field.name)) ?? 2;
      this.loading = true;
      if (!this.field?.preference) {
        this.precision = this.field?.precision ?? 2;
        set(this.field.name, this.precision);
        this.loading = false;
        return;
      }

      const preference = {
        service: this.field.preference[0],
        field: this.field.preference[1],
      };
      await this.$api.CompanyPreferencesCommands.showTemplate(preference.service, preference.field)
        .then((response) => {
          this.precision = Object.values(response)['0'];
          set(this.field.name, this.precision);
          this.loading = false;
          this.fieldValueChanged(this.fieldValue);
        });
    },
    fieldValueChanged(value) {
      this.stringFieldValue = FormatNumber.format(value || 0, this.numericMask);
      // bug https://github.com/vuejs-tips/v-money/issues/44
      if (this.$refs.numberInput && this.$refs.numberInput.$el) {
        this.$refs.numberInput.$el.getElementsByTagName('input')[0].value = this.stringFieldValue || '0';
      }
    },
  },
};
</script>

<style lang="scss"></style>
