import InvoiceSchema from '@schemas/Invoice';

const ListInvoices = () => import(/* webpackChunkName: "list-invoices" */'./ListInvoices');
const DetailsInvoices = () => import(/* webpackChunkName: "details-invoices" */'./DetailsInvoices');

export default [
  {
    path: InvoiceSchema.routes.list.path,
    name: InvoiceSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'invoices',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: InvoiceSchema.titles.items },
      ],
    },
    component: ListInvoices,
    props: true,
  },
  {
    path: InvoiceSchema.routes.create.path,
    name: InvoiceSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'invoices',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: InvoiceSchema.titles.items, route: InvoiceSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailsInvoices,
  },
  {
    path: InvoiceSchema.routes.edit.path,
    name: InvoiceSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'invoices',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: InvoiceSchema.titles.items, route: InvoiceSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailsInvoices,
  },
  {
    path: InvoiceSchema.routes.import.path,
    name: InvoiceSchema.routes.import.name,
    meta: {
      requiresAuth: true,
      rule: 'invoices',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: InvoiceSchema.titles.items, route: InvoiceSchema.routes.list.name },
        { name: 'Importar XML' },
      ],
    },
    component: DetailsInvoices,
    props: true,
  },
];
