<template>
  <div>
    <template v-for="(tab, index) in tabs">
      <div v-if="tab" :key="index" :class="customClass">
        <v-expand-transition>
          <GenericCardPanel
            class="justify-center"
            :bg-color="bgColor"
            :title="tab.title"
            :fields="tab.fields" />
        </v-expand-transition>
      </div>
    </template>
  </div>
</template>
<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';
import SchemaUtil from '@schemas/Util';

export default {
  name: 'GenericTab',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
    schema: {
      type: Object,
    },
    bgColor: {
      type: String,
      default: () => ('grey lighten-4'),
    },
    customClass: {
      type: String,
      default: () => (''),
    },
  },
  data() {
    return {
      tabs: [],
    };
  },
  mounted() {
    this.solveTabs();
  },
  methods: {
    solveTabs() {
      this.tabs = SchemaUtil.getListFieldsByTabGroup(this.schema, this.item);
    },
  },
};
</script>

<style scoped></style>
