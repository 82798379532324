<template>
  <div :id="item.id">
    <GenericTab
      :schema="schema"
      :item="item"
      bg-color="white"
      custom-class="ml-16 mt-n8" />
  </div>
</template>
<script>
import GenericTab from '@sharedComponents/tabs/GenericTab';
import DasGuidesSchema from '@schemas/DasGuides';

export default {
  name: 'TabDasGuides',
  components: {
    GenericTab,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      schema: DasGuidesSchema,
    };
  },
};
</script>

<style scoped></style>
