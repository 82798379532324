import RegisterImportSchema from '@schemas/RegisterImport';

const ListImportsProducts = () => import(/* webpackChunkName: "list-imports-product" */'./ListImportsProducts');
const ListImportsRegisters = () => import(/* webpackChunkName: "list-imports-registers" */'./ListImportsRegisters');

export default [
  {
    path: '/settings/imports/products',
    name: 'settings-imports-products',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: RegisterImportSchema.titles.items },
      ],
    },
    component: ListImportsProducts,
  },
  {
    path: '/settings/imports/registers',
    name: 'settings-imports-registers',
    meta: {
      requiresAuth: true,
      rule: 'Admin',
      breadcrumb: [
        { name: 'Configurações' },
        { name: RegisterImportSchema.titles.items },
      ],
    },
    component: ListImportsRegisters,
  },
];
