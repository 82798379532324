import { formatCurrency } from '@plugins/app-utils';

export const calculateTotals = (order, { total }) => {
  order.total = total || order.total;

  order.total_items = parseFloat(order.total?.items || 0);
  order.total_items_formatted = formatCurrency(order.total_items);

  order.total_services = parseFloat(order.total?.services || 0);
  order.total_services_formatted = formatCurrency(order.total_services);

  order.total_discount = parseFloat(order.total?.discount || 0);
  order.total_discount_formatted = formatCurrency(order.total_discount);

  order.total_taxes = parseFloat(order.total?.taxes || 0);
  order.total_taxes_formatted = formatCurrency(order.total_taxes);

  order.total_withheld_taxes = parseFloat(order.total?.withheld_taxes || 0);
  order.total_withheld_taxes_formatted = formatCurrency(order.total_withheld_taxes);

  order.total_order = parseFloat(order.total?.order || 0);
  order.total_order_formatted = formatCurrency(order.total_order);
};
