<template>
  <div>
    <v-data-table
      v-model="selected"
      v-bind="getProps"
      :headers="headers"
      :disabled="disabled"
      :header-props="headerProps"
      :items="visibleItems"
      :items-per-page="itemsPerPage"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :show-expand="!!tabs"
      :show-select="!noCheck"
      :disable-pagination="disablePagination"
      :footer-props="footerProps"
      :loading="loading"
      :hide-default-header="hideDefaultHeader"
      :hide-default-footer="hideDefaultFooter"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      item-key="id"
      class="certus-data-list"
      expand-icon="expand_more"
      selectable-key="isSelectable"
      v-on="$listeners"
      @input="onSelectedItemsChange">
      <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
        <slot :name="name" v-bind="item" />
      </template>
      <template v-if="hideDefaultHeader" v-slot:header="{}">
        <slot name="customHeader" />
      </template>
      <template v-if="!noCheck && selectAllItems" v-slot:header.data-table-select="{}">
        <v-checkbox v-model="allSelected"
                    aria-label="Selecionar todos"
                    color="secondary"
                    :on-icon="icons.checkBoxOn"
                    :off-icon="icons.checkBoxOff"
                    @change="onSelectAll()" />
      </template>

      <template v-if="!noCheck" v-slot:item.data-table-select="{ item }">
        <v-checkbox v-model="item.isSelected"
                    color="secondary"
                    :on-icon="icons.checkBoxOn"
                    :off-icon="icons.checkBoxOff"
                    aria-label="Selecionar"
                    @click="onSelectItem(item)" />
      </template>

      <template v-if="!noActions" v-slot:item.actions="{ item }">
        <template v-for="action in actions">
          <Btn v-if="action.type === 'route'"
               :id="item.id.toString()"
               :key="action.route"
               :route="action.route"
               :aria-label="action.ariaLabel"
               :color="action.color"
               icon>
            <v-icon>
              {{ action.icon }}
            </v-icon>
          </Btn>
          <Btn
            v-else
            :id="getMainId(item, action.main_id)"
            :key="action.id"
            :type="action.type"
            :route="action.route"
            :aria-label="action.ariaLabel"
            :color="action.color"
            :target="action.target"
            icon>
            <v-icon>
              {{ action.icon }}
            </v-icon>
          </Btn>
        </template>
        <slot name="actions" v-bind="item" />
        <Btn v-if="!noEdit"
             color="secondary"
             icon
             :aria-label="editAriaLabel"
             :disabled="disabled"
             @click="onEditClick(item)">
          <v-icon>{{ editIcon }}</v-icon>
        </Btn>
        <Btn v-if="!noDelete"
             color="accent"
             icon
             aria-label="Deletar"
             :disabled="disabled"
             @click="onDeleteClick(item)">
          <v-icon>{{ icons.delete }}</v-icon>
        </Btn>
        <slot name="post-actions" v-bind="item" />
      </template>
      <template v-if="tabs" v-slot:expanded-item="{ headers, item }" color="indigo lighten-1">
        <td :colspan="headers.length" class="pt-1 px-0 no-box-shadow">
          <div style="width: 100%; display: flex; justify-content: center">
            <v-row class="expanded-row py-3 px-2 mx-2 my-2 mt-n2">
              <Tabs color="indigo lighten-1"
                    :item="item"
                    item-key="id"
                    :sub-table-headers="subTableHeaders"
                    :tabs="tabs"
                    :active-tab="item.activeTab"
                    :title-type="titleType" />
            </v-row>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  ICON_ARROW_LEFT,
  ICON_ARROW_RIGHT,
  ICON_ARROW_DOWN,
  ICON_CHECKBOX_BLANK,
  ICON_CHECKBOX_MARKED,
  ICON_DELETE,
  ICON_EDIT,
  ICON_FILE_SEND,
  ICON_MINUS,
  ICON_PLUS,
  ICON_FORM_SELECT,
} from '@constants/icons';
import Btn from '../app/Btn';
import Tabs from './Tabs';

export default {
  name: 'DataList',
  components: {
    Btn,
    Tabs,
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    subTableHeaders: {
      type: Array,
      default: () => [],
    },
    tabs: {
      type: Array,
    },
    panels: {
      type: Array,
      default: () => [],
    },
    noCheck: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
    noDelete: {
      type: Boolean,
      default: false,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    editAriaLabel: {
      type: String,
      default: 'Editar',
    },
    editIcon: {
      type: String,
      default: ICON_EDIT,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectAllItems: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    hideDefaultAction: {
      type: Boolean,
      default: false,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    paginable: {
      type: Boolean,
      default: false,
    },
    serverItemsLength: {
      type: Number,
      default: 0,
    },
    pageLength: {
      type: Number,
      default: 10,
    },
    sortBy: {
      type: Array,
      default: () => ([]),
    },
    sortDesc: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      icons: {
        arrowLeft: ICON_ARROW_LEFT,
        arrowRight: ICON_ARROW_RIGHT,
        arrowUp: ICON_ARROW_DOWN,
        checkBoxOff: ICON_CHECKBOX_BLANK,
        checkBoxOn: ICON_CHECKBOX_MARKED,
        delete: ICON_DELETE,
        edit: ICON_EDIT,
        fileSend: ICON_FILE_SEND,
        minus: ICON_MINUS,
        plus: ICON_PLUS,
        select: ICON_FORM_SELECT,
      },
      item: {},
      expanded: [],
      selected: [],
      singleExpand: false,
      titleType: '',
      dialogEdit: false,
      editItem: {},
      allSelected: false,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
      },
      localServerItemsLength: 0,
    };
  },
  computed: {
    visibleItems() {
      return this.items.filter((item) => !item?._destroy);
    },
    getProps() {
      return { ...this.$attrs, ...(this.serverItemsLength && { serverItemsLength: this.serverItemsLength }) };
    },
    headerProps() {
      return {
        sortIcon: this.icons.arrowUp,
      };
    },
    footerProps() {
      return {
        showFirstLastPage: true,
        firstIcon: this.icons.arrowLeft,
        lastIcon: this.icons.arrowRight,
        prevIcon: this.icons.minus,
        nextIcon: this.icons.plus,
      };
    },
    itemsPerPage() {
      return parseInt(this.$route.query?.per_page) || this.pageLength;
    },
  },
  mounted() {
    this.setDescriptionColumnWidth();
  },
  methods: {
    filterAction(action, item) {
      const { id } = item;
      action.method(action, id);
    },
    getMainId(item, prop) {
      if (!item) return null;

      if (typeof item[prop].id !== 'undefined') {
        return item[prop].id.toString();
      }
      return item.id.toString();
    },
    onSelectItem(item) {
      if (item.isSelected) {
        this.selected.push(item);
      } else {
        this.allSelected = false;
        this.selected.forEach((selectedItem, index) => {
          if (selectedItem.id === item.id) {
            this.selected.splice(index, 1);
          }
        });
      }

      if (this.selected.length === this.items.length) this.allSelected = true;
      this.$emit('select-item', item);
    },
    onSelectAll() {
      if (this.allSelected) {
        this.selected = this.items.map((item) => {
          item.isSelected = true;
          return item;
        });
      }
      if (!this.allSelected) {
        this.selected = this.items.map((item) => {
          item.isSelected = false;
          return item;
        });
        this.selected = [];
      }

      this.$emit('select-all', this.allSelected, this.selected);
    },
    unSelectAll() {
      this.selected = [];
      this.items = this.items.map((item) => {
        item.isSelected = false;
        return item;
      });
    },
    onDeleteClick(item) {
      this.$emit('delete-item', item);
    },
    onEditClick(item) {
      this.$emit('edit-item', item);
    },
    onSelectedItemsChange(selectedItems) {
      this.$emit('update:selectedItems', selectedItems);
    },
    setDescriptionColumnWidth() {
      const allTds = document.getElementsByClassName('description');
      if (allTds?.length > 0) {
        Object.keys(allTds).forEach((key) => {
          const td = allTds[key];
          const tdText = td.innerHTML;
          td.style.minWidth = '300px';
          td.innerHTML = '';
          const contentSpan = document.createElement('span');
          contentSpan.innerHTML = tdText;
          contentSpan.style.width = '300px';
          contentSpan.style.wordBreak = 'word-break';
          contentSpan.style.whiteSpace = 'normal';
          td.appendChild(contentSpan);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.expanded-row {
  background-color: white;
  border-radius: 24px !important;
  box-shadow: unset !important;
}
.no-box-shadow {
  box-shadow: unset !important;
}
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  background-color: white;
  border-radius: 24px !important;
  box-shadow: unset !important;
}
</style>

<style>
  th:first-child:hover > span:first-child::before {
    display: none;
  }
  th:first-child[aria-label]:hover::after {
    display: none;
  }
  th:last-child:hover > span:first-child::before {
    display: none;
  }
  th:last-child[aria-label]:hover::after {
    display: none;
  }
</style>
