<template>
  <div class="tab-cash-flow-launchs">
    <GenericCardPanel title="Parcelas">
      <DataList :headers="headers"
                :items="localItems"
                no-actions
                no-edit
                no-delete
                no-check
                item-key="id"
                class="elevation-1"
                :sort-by="['id']"
                :sort-desc="[false, true]">
        <template v-slot:actions="item">
          <BtnOpenOrigin color="secondary" :origin="item.origin" />
        </template>
      </DataList>
    </GenericCardPanel>
  </div>
</template>

<script>
import { ICON_ACTION_OPEN_ORIGIN, ICON_REFRESH } from '@constants/icons';
import SchemaUtil from '@schemas/Util';
import BtnOpenOrigin from '@sharedComponents/app/BtnOpenOrigin';
import CashFlowItemSchema from '@schemas/CashFlowItem';
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';
import DataList from '@sharedComponents/dynamic/DataList';

export default {
  name: 'TabCashFlowLaunchs',
  components: {
    BtnOpenOrigin,
    GenericCardPanel,
    DataList,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      icons: {
        origin: ICON_ACTION_OPEN_ORIGIN,
        refresh: ICON_REFRESH,
      },
      schema: CashFlowItemSchema,
      localItems: [],
    };
  },
  computed: {
    headers() {
      return SchemaUtil.getHeaders(this.schema, true, this.actionsWidth);
    },
  },
  mounted() {
    this.getItems(this.item.id).then((response) => {
      this.localItems = [...response];
    });
  },
  methods: {
    async getItems(id) {
      const items = await this.getLaunchs(id);
      return items || [];
    },
    async getLaunchs(id) {
      const response = await this.$api.CashFlows.show(id);
      const { items } = response.data;
      return items;
    },
  },
};
</script>
