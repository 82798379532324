<template>
  <div :id="item.id">
    <GenericTab :schema="schema" :item="item" />
  </div>
</template>

<script>
import GenericTab from '@sharedComponents/tabs/GenericTab';
import BtgAccountSchema from '@schemas/BtgAccount';

export default {
  name: 'TabBtgAccount',
  components: {
    GenericTab,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      schema: BtgAccountSchema,
    };
  },
  mounted() {},
};
</script>

<style scoped>
.red-color {
  color: red;
}
.green-color {
  color: green;
}
.gray-color {
  color: gray;
}
</style>
