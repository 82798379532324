<template>
  <div class="tab-order-sale-itens">
    <GenericCardPanel title="Itens da licitação">
      <DataList :headers="headers"
                :no-check="true"
                :items="setBiddingsItems(item)"
                item-key="id"
                class="mx-2"
                :sort-by="['id']"
                :sort-desc="[false, true]" />
    </GenericCardPanel>
  </div>
</template>

<script>
import { ICON_REFRESH } from '@constants/icons';
import DataList from '@sharedComponents/dynamic/DataList';
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabBiddingsItems',
  components: {
    DataList,
    GenericCardPanel,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      icons: {
        refresh: ICON_REFRESH,
      },
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Número', value: 'number' },
        {
          text: 'Descrição', value: 'description', cellClass: 'description',
        },
        { text: 'Quantidade', value: 'qtd' },
        { text: 'Unidade de Medida', value: 'unit_measurement' },
        { text: 'Valor Unitário', value: 'unitary_value' },
        { text: 'Valor Total', value: 'total_value' },
      ],
      actions: [],
      filterParams: {},
    };
  },
  computed: {},
  methods: {
    setBiddingsItems(item) {
      const BiddingsItems = item.items_bidding;
      return BiddingsItems;
    },
  },
};
</script>
