<template>
  <div class="tab-order-purchase-itens">
    <GenericCardForm>
      <TabListTaxes
        :taxes="itemTaxes"
        :schema="schema" />
    </GenericCardForm>
  </div>
</template>

<script>
import OrderPurchaseSchema from '@schemas/OrderPurchase';
import GenericCardForm from '@sharedComponents/dynamic/GenericCardForm';
import TabListTaxes from '@sharedComponents/tabs/TabListTaxes';

export default {
  name: 'TabOrderPurchaseItemTax',
  components: {
    GenericCardForm,
    TabListTaxes,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      itemTaxes: [],
    };
  },
  computed: {
    schema() {
      return OrderPurchaseSchema;
    },
  },
  created() {
    this.itemTaxes = this.item.items;
  },
};
</script>
