import Vue from 'vue';
import router from '@src/core/router';
import axios from 'axios';
import { provide, inject } from '@vue/composition-api';
import config from '@src/core/config';
import initializeServices from './initializeServices';

import { globalServices as sharedServices } from '@sharedServices/index';

import { globalServices as analyticsServices } from '../analytics';
import { globalServices as authServices } from '../auth';
import { globalServices as assignorsServices } from '../anticipation';
import { globalServices as integrationsServices } from '../integrations';
import { globalServices as digitalBankServices } from '../digitalbank';
import { globalServices as clientServices } from '../entries';
import { globalServices as financialServices } from '../financial';
import { globalServices as productionServices } from '../production';
import { globalServices as settingsServices } from '../settings';
import { globalServices as sellmoreServices } from '../sellmore';
import { globalServices as supplyServices } from '../supply';
import { globalServices as tablesServices } from '../tables';
import { globalServices as reportsServices } from '../reports/index.js';

import '@plugins/notify';

export const ApiSymbol = Symbol('CERTUS API provider');
// Inject axios and config
export const axiosInstance = axios.create({
  baseURL: config.API_ROOT,
  timeout: 300000,
  // headers: {'X-Custom-Header': 'certus_fintech'},
  reqType: 'json',
  reqEncoding: 'utf8',
  resType: 'json',
  resEncoding: 'utf8',
  validateStatus(status) {
    return status >= 200 && status < 300; // default
  },
});

axiosInstance.interceptors.request.use((localConfig) => {
  const token = localStorage.getItem('token');

  if (token) {
    localConfig.headers.Authorization = `Bearer ${token}`;
  }
  return localConfig;
}, (err) => {
  // console.error('Erro na request: ', err);
  Promise.reject(err);
});

axiosInstance.interceptors.response.use((response) => response, (error) => {
  let errorMessage = 'Ops, aconteceu algo inesperado, tente novamente mais tarde';
  // network error
  if (!error.response) {
    Vue.prototype.$notifyError(errorMessage);
    // router.push({ name: 'auth-logout' });
  }
  /*   OS PARAMETROS DE RETORNO DE ERROS DO BACKEND SÃO */
  /*      error.response.data.errors.base               */
  /*      error.response.data.errors                    */
  /*      error.response.data.errors.messages           */
  /*      error.response.data.msg                       */
  /*  Caso a rota não esteja vindo no padrão,           */
  /*  Avise ao backend responsável para padroniza-la    */
  /*  FAVOR NÃO ALTERAR AS OPÇÕES ABAIXO                */
  const errorResponse = error.response.data.errors || {};
  const errorResponseBase = error.response.data?.errors?.base || {};
  const errorResponseMessages = error.response.data?.errors?.messages || {};
  const errorResponseMsg = error.response.data?.msg || {};

  // Verifica se existe mensagens de erros vindas do backend
  if (Array.isArray(errorResponseBase)) {
    errorMessage = errorResponseBase.toString() || errorResponseBase[0].toString();
  } else if (Array.isArray(errorResponse)) {
    errorMessage = errorResponse.toString() || errorResponse[0].toString();
  } else if (Array.isArray(errorResponseMessages)) {
    errorMessage = errorResponseMessages.toString() || errorResponseMessages[0].toString();
  } else if (errorResponseMsg) {
    errorMessage = errorResponseMsg.toString() || errorResponseMsg[0].toString();
  }

  // error 401
  if (error.response.status === 401) {
    Vue.prototype.$notifyError(errorMessage);
    router.push({ name: 'auth-logout' });
  }

  if (error.response.status === 403) {
    Vue.prototype.$notifyError(errorMessage?.toString());
    router.push({ name: 'dashboard' });
  }

  // error 422
  if (error.response.status === 422) {
    Vue.prototype.$notifyError(errorMessage?.toString());
    // router.push({ name: 'dashboard' });
  }

  // error 500
  if (error.response.status === 500) {
    Vue.prototype.$notifyError(errorMessage);
    // router.push({ name: 'auth-logout' });
  }
  return Promise.reject(error);
});

// Inject api
const services = {
  ...initializeServices(axiosInstance, analyticsServices),
  ...initializeServices(axiosInstance, authServices),
  ...initializeServices(axiosInstance, assignorsServices),
  ...initializeServices(axiosInstance, integrationsServices),
  ...initializeServices(axiosInstance, clientServices),
  ...initializeServices(axiosInstance, digitalBankServices),
  ...initializeServices(axiosInstance, financialServices),
  ...initializeServices(axiosInstance, productionServices),
  ...initializeServices(axiosInstance, sharedServices),
  ...initializeServices(axiosInstance, settingsServices),
  ...initializeServices(axiosInstance, sellmoreServices),
  ...initializeServices(axiosInstance, supplyServices),
  ...initializeServices(axiosInstance, reportsServices),
  ...initializeServices(axiosInstance, tablesServices),
};

export function provideApi() {
  provide(ApiSymbol, services);
}

export function useApi() {
  return inject(ApiSymbol);
}

// remove this when all components were composable
export const pluginApi = {
  install(vue) {
    vue.prototype.$api = services;
  },
};
