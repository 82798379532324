import Vue from 'vue';
import Vuetify from 'vuetify';
import ripple from 'vuetify/lib/directives/ripple';
import 'vuetify/dist/vuetify.min.css';

import pt from 'vuetify/es5/locale/pt';

Vue.use(Vuetify, { directives: { ripple } });

const theme = {
  primary: '#5C6BC0',
  secondary: '#4dd0e1',
  accent: '#e91e63',
  info: '#00CAE3',
  success: '#1de9b6',
  warning: '#FB8C00',
  error: '#FF5252',
};

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-menu-down' },
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
