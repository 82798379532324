export default {
  service: 'Account',
  icon: '',
  routes: {
    list: {
      path: '/digitalbank/accounts/billets/:id',
      name: 'digitalbank-accountsbillets-list',
    },
    create: {
      path: '/digitalbank/accounts/billets/:id/pay',
      name: 'digitalbank-accountsbillets-pay',
    },
  },
};
