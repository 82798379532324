<template>
  <div class="tab-price-list-items">
    <GenericCardPanel title="Informações da Lista de Preços">
      <DataList :headers="headers"
                :items="localItems"
                item-key="id"
                class="elevation-1"
                no-edit
                no-delete
                no-check
                :sort-by="['id']"
                :sort-desc="[false, true]" />
    </GenericCardPanel>
  </div>
</template>

<script>
import SchemaUtil from '@schemas/Util';
import PriceListItemSchema from '@schemas/PriceListItem';
import DataList from '@sharedComponents/dynamic/DataList';
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabPriceListItems',
  components: {
    DataList,
    GenericCardPanel,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      schema: PriceListItemSchema,
      localItems: [],
      main: '',
    };
  },
  computed: {
    headers() {
      return SchemaUtil.getHeaders(this.schema, false, this.actionsWidth);
    },
  },
  mounted() {
    this.getProducts(this.item.id).then((response) => {
      this.localItems = [...response];
    });
  },
  methods: {
    async getProducts(id) {
      const response = await this.$api.PriceList.show(id);
      const { items } = response.data;
      return items || [];
    },
  },
};
</script>
