<template>
  <div>
    <div id="printer-header" class="d-none d-print-inline-block">
      <h5>{{ company.name || '' }}</h5>
    </div>
    <v-app-bar
      id="default-app-bar"
      app
      class="v-bar--underline d-print-none fixed-bar default-app-bar"
      color="transparent"
      :clipped-left="$vuetify.rtl"
      :clipped-right="!$vuetify.rtl"
      height="50"
      flat>
      <Breadcrumb />
      <v-spacer />
      <h3 v-if="!$vuetify.breakpoint.mobile" class="indigo--text text--lighten-1 mt-1 font--montserrat company-name--size">
        {{ companyInfoLabel }}
      </h3>
      <default-account />
    </v-app-bar>
  </div>
</template>

<script>

import Breadcrumb from '@sharedComponents/dynamic/Breadcrumb';
import DefaultAccount from './widgets/Account';

export default {
  name: 'Header',
  components: {
    Breadcrumb,
    DefaultAccount,
  },
  data() {
    return {
      drawer: false,
      iCertusLogo: require('@assets/iCertus.png'),
      user: '',
      company: {
        addresses: [],
      },
      app: false,
      mini: false,
      companyInfoLabel: 'Carregando...',
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      const customer_id = localStorage.getItem('customer_id');
      if (typeof customer_id === 'string') {
        this.$api.Me.index().then((response) => {
          this.user = response.data;
          this.company = this.user.company;
          this.addresses = this.user.company.addresses;
          this.mountCompanyInfoLabel();
          localStorage.setItem('with_anticipation', this.user.company.with_anticipation);
        });
      }
    },
    mountCompanyInfoLabel() {
      if (!this.company) this.companyInfoLabel = '';
      const ifen = this.company.cnpj ? '-' : ' ';
      this.companyInfoLabel = `${this.company?.to_s} ${ifen} ${this.company?.cnpj || ''}` || '';
    },
  },
};
</script>
<style lang="scss" scoped>
.default-app-bar {
    left: 60px !important;
    position: relative !important;
    max-width: 94vw !important;
}

.font--montserrat {
  font-family: 'Montserrat';
}

.company-name--size {
  font-size: 1.2rem;
}
</style>
