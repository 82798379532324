import { ICON_MEASURING_UNIT } from '@constants/icons';
// import DevolutionSalesItemBusiness from '@businesses/DevolutionPurchaseItem';

export default {
  // service: 'DevolutionSalesItems',
  // business: DevolutionSalesItemBusiness,
  icon: ICON_MEASURING_UNIT,
  routes: {
    list: '',
    edit: '',
    create: '',
  },
  titles: {
    item: 'Item da Devolução de Vendas',
    items: 'Itens da Devolução de Vendas',
    edit: 'Editar Item',
    create: 'Criar Item',
    new: 'Adicionar Item',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: '',
  fields: [
    {
      label: 'Código',
      name: 'product_code',
      formattedName: 'product.code',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'code',
      itemValue: 'id',
      list: true,
      disabled: true,
      md: 5,
    },
    {
      label: 'Produto',
      name: 'product_id',
      formattedName: 'product.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      disabled: true,
      md: 7,
    },
    {
      label: 'Nome',
      columnWidth: '300px',
      name: 'name',
      type: 'string',
      list: true,
      disabled: true,
      component: 'dynamic-text-field',
      required: true,
      md: 7,
    },
    {
      label: 'Unidade de medida',
      name: 'unity_id',
      formattedName: 'unity.to_s',
      type: 'number',
      list: true,
      disabled: true,
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-measuringunits-create',
      dataSource: {
        service: 'AllTypes',
        method: 'getManyByTypeExcludeDisables',
        props: ['unity'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 5,
    },
    {
      label: 'Quantidade',
      name: 'quantity',
      formattedName: 'quantity_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      preference: ['General', 'number_precision_quantity'],
      mmin: 0.001,
      max: 99999999999999,
      step: '0.01',
      default: 1,
      md: 6,
    },
    {
      label: 'Valor unitário',
      name: 'unit_value',
      formattedName: 'unit_value_formatted',
      type: 'number',
      list: true,
      disabled: true,
      component: 'dynamic-number-field',
      required: true,
      md: 6,
    },
    {
      label: 'Valor desconto',
      name: 'discount_value',
      formattedName: 'discount_value_formatted',
      type: 'number',
      list: true,
      disabled: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      step: '0.01',
      md: 4,
    },
    {
      label: '% Desconto',
      name: 'discount_percentage_value',
      formattedName: 'discount_percentage_formatted',
      type: 'number',
      list: true,
      disabled: true,
      component: 'dynamic-number-field',
      max: 100,
      md: 4,
    },
    {
      label: 'Valor Total',
      name: 'total_value',
      formattedName: 'total_value_formatted',
      type: 'number',
      list: true,
      disabled: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      min: 0.001,
      max: 99999999999999,
      step: '0.01',
      md: 4,
    },
    {
      label: 'Natureza da Operação',
      name: 'nature_operation_id',
      type: 'number',
      list: true,
      disabled: true,
      formattedName: 'nature_operation.to_s',
      component: 'dynamic-select',
      dataSource: {
        service: 'NatureOperations',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_nature_in: ['sale'],
          query: ['id', 'name'],
        }],
      },
      itemText: 'name',
      itemValue: 'id',
      md: 6,
    },
    {
      label: 'Estoque',
      name: 'stock_id',
      list: true,
      disabled: true,
      formattedName: 'stock.to_s',
      type: 'number',
      component: 'dynamic-select',
      dataSource: {
        service: 'Stocks',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          use_main_eq: true,
          query: ['id', 'to_s'],
        }],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 6,
    },
    {
      label: 'NCM',
      name: 'ncm_id',
      list: true,
      disabled: true,
      formattedName: 'ncm.to_s',
      type: 'number',
      redirectCreateRoute: 'tables-ncms-create',
      component: 'dynamic-select',
      dataSource: {
        service: 'AllTypes',
        method: 'getManyByTypeExcludeDisables',
        props: ['ncm'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 6,
    },
    {
      label: 'CEST',
      name: 'cest_id',
      formattedName: 'cest.to_s',
      list: true,
      disabled: true,
      clearable: true,
      type: 'number',
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-cests-create',
      dataSource: {
        service: 'AllTypes',
        method: 'getManyByTypeExcludeDisables',
        props: ['cest'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 6,
    },
    {
      label: 'Número da Ordem',
      name: 'order_number',
      type: 'text',
      list: true,
      disabled: true,
      component: 'dynamic-text-field',
      md: 6,
    },
    {
      label: 'Número do Item',
      name: 'item_number',
      type: 'text',
      list: true,
      disabled: true,
      component: 'dynamic-text-field',
      md: 6,
    },
    {
      label: 'Código de Benefício Fiscal',
      name: 'cbenef',
      type: 'text',
      list: true,
      disabled: true,
      component: 'dynamic-text-field',
      md: 12,
    },
    {
      label: 'Observações',
      name: 'observation',
      type: 'text',
      component: 'dynamic-text-area',
      md: 12,
    },
  ],
};
