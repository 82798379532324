export default {
  service: 'Account',
  icon: '',
  routes: {
    list: {
      path: '/digitalbank/accounts',
      name: 'digitalbank-accounts-list',
    },
    create: {
      path: '/digitalbank/accounts/create',
      name: 'digitalbank-accounts-create',
    },
    edit: {
      path: '/digitalbank/accounts/edit/:id',
      name: 'digitalbank-accounts-edit',
    },
  },
};
