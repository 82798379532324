<template>
  <div :class="alignmentClass">
    <template v-for="(button, index) in buttons">
      <v-tooltip v-if="!button.menu && !button.spacer && checkVisible(button, scope)" :key="index" :disabled="!button.tooltip" top>
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="checkDisabled(button, scope)"
            :color="getColor(button, scope)"
            :text="button.text"
            :large="button.large"
            :depressed="button.depressed"
            :outlined="button.outlined"
            :small="button.small"
            :width="button.width"
            :icon="!button.buttonLabel"
            :class="[button.class, button.lower ? 'dm-btn-lower' : '']"
            :loading="$isLoading(getLoader(button, scope))"
            :rounded="button.rounded"
            v-on="on"
            @click.stop="onButtonClickHandler(button, scope)">
            <v-progress-circular v-if="button.spinIcon"
                                 :color="getColor(button, scope)"
                                 :size="18"
                                 :width="2"
                                 indeterminate />
            <v-icon v-else-if="button.icon" :small="button.small" :class="button.buttonLabel ? 'mr-2' : ''">
              {{ getIcon(button, scope) }}
            </v-icon>
            <span v-if="button.buttonLabel" :class="button.loadingText ? 'loading-text' : ''">
              {{ button.buttonLabel }}
            </span>
          </v-btn>
        </template>
        <span>{{ button.tooltip }}</span>
      </v-tooltip>
      <v-spacer v-else-if="button.spacer" :key="index" />
      <v-menu
        v-else-if="button.menu && menuItems(button.items, scope).length"
        :key="index"
        :right="button.menuDirection === 'right'"
        :left="button.menuDirection === 'left' || !button.menuDirection"
        :offset-x="button.menuOffsetX === true"
        :offset-y="button.menuOffsetY === true"
        @click.native.stop>
        <v-btn
          slot="activator"
          :color="getColor(button, scope)"
          :text="button.text"
          :large="button.large"
          :depressed="button.depressed"
          :outlined="button.outlined"
          :small="button.small"
          :width="button.width"
          :icon="!button.buttonLabel"
          :class="button.class"
          :rounded="button.rounded"
          :loading="$isLoading(getLoader(button, scope))">
          <v-icon v-if="button.icon" :small="button.small" :class="button.buttonLabel ? 'mr-2' : ''">
            {{ getIcon(button, scope) }}
          </v-icon>
          <span v-if="button.buttonLabel">
            {{ button.buttonLabel }}
          </span>
        </v-btn>
        <v-list>
          <v-list-tile v-for="(item, subIndex) in menuItems(button.items, scope)" :key="subIndex" :disabled="checkDisabled(item, scope)" @click.stop="onButtonClickHandler(item, scope)">
            <v-list-tile-content>
              <v-list-tile-title class="pr-2">
                {{ item.text }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-menu>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'DynamicButtons',
  props: {
    scope: {
      type: Object,
      default: () => ({}),
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    align: {
      type: String,
      default: 'right',
    },
    scopeItemProp: {
      type: String,
      default: '',
    },
  },
  computed: {
    alignmentClass() {
      if (this.align === 'right') {
        return 'justify-end layout pa-0 ma-0';
      }
      if (this.align === 'center') {
        return 'justify-center layout pa-0 ma-0';
      }
      return 'justify-start layout pa-0 ma-0';
    },
  },
  methods: {
    onButtonClickHandler(button, scope) {
      this.$emit('click', { button, scope });
      if (button.click) {
        button.click(this.scopeItemProp ? scope[this.scopeItemProp] : scope, scope);
      }
    },
    checkDisabled(item, scope) {
      if (typeof item.disabled === 'function') {
        return item.disabled(this.scopeItemProp ? scope[this.scopeItemProp] : scope, scope);
      }
      if (item.disabled !== undefined) {
        return Boolean(item.disabled);
      }

      return false;
    },
    checkVisible(item, scope) {
      if (typeof item.visible === 'function') {
        return item.visible(this.scopeItemProp ? scope[this.scopeItemProp] : scope, scope);
      }
      if (item.visible !== undefined) {
        return Boolean(item.visible);
      }

      return true;
    },
    menuItems(items, scope) {
      return _.filter(items, (item) => this.checkVisible(item, scope));
    },
    getLoader(item, scope) {
      if (!item.loader) return '';

      if (typeof item.loader === 'function') {
        return item.loader(this.scopeItemProp ? scope[this.scopeItemProp] : scope, scope);
      }

      return String(item.icon);
    },
    getIcon(item, scope) {
      if (typeof item.icon === 'function') {
        return item.icon(this.scopeItemProp ? scope[this.scopeItemProp] : scope, scope);
      }
      if (item.icon !== undefined) {
        return String(item.icon);
      }

      return '';
    },
    getColor(item, scope) {
      if (typeof item.color === 'function') {
        return item.color(this.scopeItemProp ? scope[this.scopeItemProp] : scope, scope);
      }
      if (item.color !== undefined) {
        return String(item.color);
      }

      return 'primary';
    },
  },
};
</script>

<style lang="scss">
.selected-items {
  display: flex;
}
</style>
