import DynamicService from '@sharedServices/dynamic/DynamicService';

const endpoint = 'cost_centers';

const formatResponse = (item) => {
  item.disable = item.disable ? 'Inativo' : 'Ativo';
  item.use_nickname = item.use_nickname ? 'Sim' : 'Não';

  return { data: item };
};

const CostCenters = DynamicService(endpoint, {
  formatResponse,
  sendCustomPayload: true,
});

export { CostCenters };
export default { CostCenters };
