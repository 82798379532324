<template>
  <div v-if="!readOnly && !field.readOnly">
    <v-subheader v-if="field.label" class="pl-0">
      {{ field.label }}
      <strong v-if="field.quantity.show">
        : ({{ fieldValue || 0 }}) {{ field.quantity.label }}
      </strong>
    </v-subheader>
    <v-slider
      v-model="fieldValue"
      :required="checkRequired()"
      :thumb-label="field.thumbLabel"
      :max="field.max"
      :inverse-label="field.inverseLabel"
      :height="field.height"
      :always-dirty="field.alwaysDirty"
      :hint="field.hint"
      :persistent-hint="true"
      :rules="validationRules"
      v-bind="$attrs"
      v-on="$listeners"
      @input="fieldChanged"
      @change="fieldChanged" />
  </div>
  <div v-else class="dm-information">
    <label>{{ field.label }}</label>
    <p>{{ fieldValue }}</p>
  </div>
</template>

<script>
import DynamicFieldMixin from './DynamicFieldMixin';

export default {
  name: 'DynamicSlider',
  mixins: [DynamicFieldMixin],
  data() {
    return {
      fieldValue: '',
    };
  },
};
</script>

<style lang="scss">
.input-group.input-group--slider {
  padding-right: 0;
}
</style>
