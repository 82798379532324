import MeasuringUnitsSchema from '@schemas/MeasuringUnit';

const ListMeasuringUnits = () => import(/* webpackChunkName: "measuring-units-list" */'./ListMeasuringUnits');
const DetailMeasuringUnit = () => import(/* webpackChunkName: "measuring-units-detail" */'./DetailMeasuringUnit');

export default [
  {
    path: '/supply/measuring-units',
    name: 'measuring-units-list',
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Movimentações' },
        { name: MeasuringUnitsSchema.titles.items },
      ],
    },
    component: ListMeasuringUnits,
  },
  {
    path: '/supply/measuring-units/create',
    name: 'measuring-units-create',
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Movimentações' },
        { name: MeasuringUnitsSchema.titles.items, route: 'measuring-units-list' },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailMeasuringUnit,
  },
  {
    path: '/supply/measuring-units/edit/:id',
    name: 'measuring-units-edit',
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: 'Movimentações' },
        { name: MeasuringUnitsSchema.titles.items, route: 'measuring-units-list' },
        { name: 'Editar' },
      ],
    },
    component: DetailMeasuringUnit,
  },
];
