<template>
  <div v-if="!readOnly && !field.readOnly">
    <div class="input-group">
      <label>{{ field.label }} {{ field.required ? '*' : '' }}</label>
    </div>
    <v-radio-group v-model="fieldValue"
                   :disabled="checkDisabled()"
                   :class="field.class"
                   class="pt-2"
                   v-bind="$attrs"
                   v-on="$listeners"
                   @change="fieldChanged">
      <v-layout row wrap>
        <v-flex v-for="(item, index) in fieldItems(field)" :key="index" :class="field.row ? 'xs6 sm4 md3 lg3' : 'xs12'">
          <v-radio :label="item[field.itemText] || item" :value="item[field.itemValue] || item" />
        </v-flex>
      </v-layout>
    </v-radio-group>
    <p v-if="field.hint" class="grey--text mb-0">
      {{ field.hint }}
    </p>
  </div>
  <div v-else class="dm-information">
    <label>{{ field.label }}</label>
    <p>{{ fieldValue }}</p>
  </div>
</template>

<script>
import DynamicFieldMixin from './DynamicFieldMixin';

export default {
  name: 'DynamicRadioGroup',
  mixins: [DynamicFieldMixin],
  data() {
    return {
      valid: true,
      fieldValue: this.value,
    };
  },
  methods: {
    fieldItems(field) {
      if (field.items) {
        return field.items;
      } if (field.dataSource && field.dataSource.modelName) {
        return this.$store.state[field.dataSource.state || 'dynamicModule'][field.dataSource.modelName];
      }

      return [];
    },
  },
};
</script>

<style lang="scss">
</style>
