/* Vuex Store that contains also modules */

import Vue from 'vue';
import Vuex from 'vuex';

import { app } from './app';
import loadingModule from './loading/store';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    loadingModule,
  },
});
