<template>
  <v-text-field
    v-if="!currentMask && visible"
    v-model="fieldValue"
    :required="checkRequired()"
    :prefix="field.prefix"
    :counter="maxLength"
    :maxlength="maxLength"
    :suffix="field.suffix"
    :rounded="field.rounded"
    :filled="field.filled"
    :multi-line="field.multiLine"
    :hint="field.hint"
    :persistent-hint="true"
    :disabled="checkDisabled()"
    :rules="validationRules"
    :label="field.label"
    :type="fieldType"
    :class="field.class"
    :prepend-icon="innerIcon ? '' : prependIcon"
    :prepend-inner-icon="innerIcon ? prependIcon : ''"
    :autofocus="field.autofocus"
    :readonly="readOnly || field.readOnly"
    :clearable="field.clearable"
    :clear-icon="iconClose"
    v-bind="$attrs"
    v-on="$listeners"
    @change="fieldChanged"
    @blur="onBlurTextField">
    <template v-slot:append-outer>
      <slot name="append-outer" />
    </template>
  </v-text-field>

  <v-text-field
    v-else-if="visible"
    v-model="fieldValue"
    v-mask="currentMask"
    :required="checkRequired()"
    :prefix="field.prefix"
    :counter="maxLength"
    :maxlength="maxLength"
    :suffix="field.suffix"
    :rounded="field.rounded"
    :filled="field.filled"
    :multi-line="field.multiLine"
    :hint="field.hint"
    :persistent-hint="true"
    :disabled="checkDisabled()"
    :rules="validationRules"
    :label="field.label"
    :type="fieldType"
    :class="field.class"
    :prepend-icon="innerIcon ? '' : prependIcon"
    :prepend-inner-icon="innerIcon ? prependIcon : ''"
    :autofocus="field.autofocus"
    :readonly="readOnly || field.readOnly"
    :validate-on-blur="field.validateOnBlur"
    v-bind="$attrs"
    v-on="$listeners"
    @change="fieldChanged"
    @blur="onBlurTextField">
    <template v-slot:append-outer>
      <slot name="append-outer" />
    </template>
  </v-text-field>
</template>

<script>
import Vue from 'vue';
import { ICON_DESCRIPTION, ICON_CLOSE } from '@constants/icons';
import { slugify, formatNumber } from '@plugins/app-utils';
import VueTheMask from 'vue-the-mask';
import DynamicFieldMixin from './DynamicFieldMixin';

Vue.use(VueTheMask);

export default {
  name: 'DynamicTextField',

  mixins: [DynamicFieldMixin],

  data() {
    return {
      fieldValue: '',
      defaultIcon: ICON_DESCRIPTION,
      iconClose: ICON_CLOSE,
      emptyValue: '',
    };
  },

  computed: {
    fieldType() {
      if (this.field.password) {
        return 'password';
      }
      if (this.field.numericKeyboard) {
        return 'tel';
      }
      return 'text';
    },
    maxLength() {
      if (this.field.maxLength && String(this.field.maxLength) !== '0') {
        return parseInt(this.field.maxLength);
      }

      return undefined;
    },
    prependIcon() {
      return this.field.prependIcon === undefined ? this.defaultIcon : this.field.prependIcon;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
    visible() {
      return this.checkVisible();
    },
    currentMask() {
      if (!this.field?.mask) return '';
      if (typeof this.field?.mask === 'string') return this.field.mask;
      return [...this.field.mask];
    },
  },
  watch: {
    fieldValue() {
      this.dynamicHint();
    },
  },
  methods: {
    onBlurTextField() {
      if (this.field.slug) {
        this.fieldValue = slugify(this.fieldValue);
        if (this.maxLength) {
          this.fieldValue = this.fieldValue.substr(0, this.maxLength);
        }
        this.fieldChanged(this.fieldValue);
      }
    },
    dynamicHint() {
      const fieldValue = Number(this.fieldValue);
      if (this.field?.dynamicHint && typeof fieldValue === 'number' && this.field?.type === 'number') {
        this.field.hint = `Exemplo: ${this.field?.dynamicHint?.prefix} ${formatNumber(1, fieldValue)}` ?? '';
      }
    },
  },
};
</script>

<style lang="scss"></style>
