import AlltypesSchema from '@schemas/Alltypes';

export default [
  {
    path: AlltypesSchema.routes.productTypes.list.path,
    name: AlltypesSchema.routes.productTypes.list.name,
    meta: AlltypesSchema.routes.productTypes.list.meta,
    component: () => import(/* webpackChunkName: 'list-product-types' */ `${AlltypesSchema.routes.productTypes.list.component}`),
  },
  {
    path: AlltypesSchema.routes.productTypes.create.path,
    name: AlltypesSchema.routes.productTypes.create.name,
    meta: AlltypesSchema.routes.productTypes.create.meta,
    component: () => import(/* webpackChunkName: 'create-product-types' */ `${AlltypesSchema.routes.productTypes.create.component}`),
  },
  {
    path: AlltypesSchema.routes.productTypes.edit.path,
    name: AlltypesSchema.routes.productTypes.edit.name,
    meta: AlltypesSchema.routes.productTypes.edit.meta,
    component: () => import(/* webpackChunkName: 'edit-product-types' */ `${AlltypesSchema.routes.productTypes.edit.component}`),
  },
];
