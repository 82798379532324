<template>
  <GenericTotalizerNew
    :class="field.class || 'mr-12'"
    :title="field.label"
    :aria-label="field.label"
    :total="fieldValue"
    :icon="icon"
    :border="field.border || 'left'"
    :colored-border="field.coloredBorder || true"
    :disabled="checkDisabled() || false"
    :large="field.large || true"
    :color="field.color || 'indigo lighten-1'"
    :rounded="field.rounded"
    :elevation="field.elevation" />
</template>

<script>
import { ICON_DESCRIPTION } from '@constants/icons';
import DynamicFieldMixin from './DynamicFieldMixin';
import GenericTotalizerNew from '@sharedComponents/dynamic/GenericTotalizerNew';

export default {
  name: 'DynamicTotalizerNew',
  components: { GenericTotalizerNew },
  mixins: [DynamicFieldMixin],
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fieldValue: '',
      icons: {
        default: ICON_DESCRIPTION,
      },
    };
  },
  computed: {
    icon() {
      return this.field.icon === undefined ? this.icons.default : this.field.icon;
    },
  },
  methods: {
    emitClick() {
      this.$emit('handleCustomButtonClick', true);
    },
  },
};
</script>
