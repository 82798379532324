import _ from 'lodash';

const uniq = (array) => array.filter((el, index, arr) => index === arr.indexOf(el));

export default {
  ADD_LOADING(state, loaderMessage) {
    if (_.isArray(loaderMessage)) {
      state.activeLoaders.push(...loaderMessage);
    } else {
      state.activeLoaders.push(loaderMessage);
    }

    state.activeLoaders = uniq(state.activeLoaders);
  },
  REMOVE_LOADING(state, loaderMessage) {
    state.activeLoaders = uniq(state.activeLoaders).filter((p) => {
      if (_.isArray(loaderMessage)) {
        return !_.includes(loaderMessage, p);
      }
      return p !== loaderMessage;
    });
  },
};
