import {
  ICON_HANDS_SHAKE,
} from '@constants/icons';
import { OPTIONS_ORDERSPURCHASESALE_STATUS } from '@constants/options';

export default {
  service: 'TransactionsConsignments',
  // business: DevolutionConsignmentBusiness,
  icon: ICON_HANDS_SHAKE,
  routes: {
    list: { path: '/supply/consignments/transactions', name: 'supply-transactionsconsignments-list' },
    create: { path: '/supply/consignments/transactions/create', name: 'supply-transactionsconsignments-create' },
    edit: { path: '/supply/consignments/transactions/edit/:id', name: 'supply-transactionsconsignments-edit' },
  },
  titles: {
    item: 'Consignação - Movimentação',
    items: 'Consignações - Movimentações',
    edit: 'Editar Consignação - Movimentação',
    create: 'Criar Consignação - Movimentação',
    new: 'Novo',
  },
  messages: {
    successDelete: 'Consignação - movimentação excluida com sucesso!',
    successEdit: 'Consignação - movimentação alterada com sucesso!',
    successCreate: 'Consignação - movimentação criada com sucesso!',
    errorSave: 'Erro ao salvar consignação - movimentação!',
    tryEditCanceled: 'Não é possível editar uma consignação - movimentação cancelada.',
    confirmCancel: 'Deseja cancelar a consignação - movimentação?',
    successCancel: 'Consignação - movimentação cancelada com sucesso',
    errorCancel: 'Erro ao cancelar consignação - movimentação',
  },
  noCreate: true,
  noEdit: true,
  noDelete: true,
  filterUrl: 'order_purchases/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  statusSettings: {
    options: OPTIONS_ORDERSPURCHASESALE_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  hideReportsOnList: true,
  formName: 'TransactionConsignments',
  fields: [
    {
      label: 'INFORMAÇÕES DA CONSIGNAÇÃO - MOVIMENTAÇÃO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
    },
    {
      label: 'Status',
      formattedName: 'status',
      name: 'status',
      type: 'select',
      options: 'orders_purchase_sale_status',
      list: true,
    },
    {
      label: 'Cliente',
      name: 'buyer_id',
      formattedName: 'buyer.to_s',
      type: 'number',
      list: true,
      required: true,
      hint: '',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_register_array: ['client', 'vendor'],
          query: ['id', 'name'],
        }, 'name asc'],
      },
      sort: {
        fieldName: 'name',
      },
      itemText: 'name',
      itemValue: 'id',
      withDetails: true,
      typeDetails: 'client',
      md: 8,
    },
    {
      label: 'Nº do Pedido',
      name: 'order_number',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 2,
    },
    {
      label: 'Data Emissão',
      name: 'emission_date',
      formattedName: 'emission_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      md: 2,
      list: true,
    },
    {
      label: 'Data Entrega',
      name: 'delivery_date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      clearable: true,
      list: true,
      md: 2,
    },
    {
      label: 'NFe',
      name: 'note',
      formattedName: 'note_formatted',
      list: true,
      type: 'text',
      component: 'dynamic-text-field',
      readOnly: true,
      md: 2,
    },
    {
      label: 'Descrição',
      name: 'name',
      columnWidth: '300px',
      type: 'text',
      list: true,
    },
    {
      label: 'Total de Impostos Aproximados',
      formattedName: 'total_tax_aproximates',
      name: 'total_tax_aproximates',
      type: 'number',
    },
    {
      label: 'Valor Total',
      formattedName: 'total_ordered_formatted',
      type: 'number',
      list: true,
    },
    {
      label: 'Qtd Original',
      name: 'original_quantity',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      preference: ['General', 'number_precision_quantity'],
      min: 0.001,
      max: 99999999999999,
      step: '0.01',
      default: 1,
      md: 6,
      fixed: true,
    },
    {
      label: 'Qtd Devolvida',
      name: 'returned_quantity',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      preference: ['General', 'number_precision_quantity'],
      min: 0.001,
      max: 99999999999999,
      step: '0.01',
      default: 1,
      md: 6,
      fixed: true,
    },
    {
      label: 'Qtd Saldo',
      name: 'balance_quantity',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      preference: ['General', 'number_precision_quantity'],
      min: 0.001,
      max: 99999999999999,
      step: '0.01',
      default: 1,
      md: 6,
      fixed: true,
    },
  ],
};
