// import Vue from 'vue';
// import _ from 'lodash';

// import { createHintForRegisterField } from '../Common/createHintForRegisterField';
// import { calculateOrderSaleRealTime } from '../Common/CalculateRealTime';
// import { setTaxesValues } from '../Common/SetTaxesValues ';

export const DefaultFieldEvents = {
  order: {
    change: (formValue) => {
      formValue.items = formValue.items || [];
      formValue.items.forEach((item) => {
        item.order_number = formValue.order;
      });
    },
  },
  // buyer_id: {
  //   async change(formValue, newValue, objectValue) {
  //     const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
  //     formValue.buyer = buyer;
  //     formValue.buyer_id = buyer.id;
  //     Vue.set(formValue, 'buyer', buyer);
  //     Vue.set(formValue, 'buyer_id', buyer.id);
  //     if (buyer.addresses.length === 0) {
  //       Vue.prototype.$notifyError('Cliente não possui endereço', {
  //         action: {
  //           text: 'Clique aqui para cadastrar',
  //           push: {
  //             name: 'entries-registers-edit',
  //             params: {
  //               id: objectValue.id,
  //             },
  //           },
  //           class: 'custom-message',
  //         },
  //         duration: 6000,
  //       });
  //     } else {
  //       const apportionment = true;
  //       const hint = await createHintForRegisterField(buyer.id, buyer);
  //       const salesmanId = _.get(buyer, 'preference.salesman_id');
  //       if (salesmanId) {
  //         const salesman = await Vue.prototype.$api.Registers.show(salesmanId);
  //         Vue.set(formValue, 'salesman_id', salesmanId);
  //         Vue.set(formValue, 'salesman', salesman);
  //       }
  //       const shipping_company_id = _.get(buyer, 'preference.shipping_company_id');
  //       if (shipping_company_id) formValue.shipping_company_id = shipping_company_id;

  //       if (formValue.buyer_id && formValue.nature_operation_id) {
  //         formValue.buyer = {
  //           ...formValue.buyer,
  //           taxpayer_type: buyer.taxpayer_type,
  //         };
  //         const data = await calculateOrderSaleRealTime(formValue.items, buyer, formValue.is_costumer);
  //         setTaxesValues(formValue, data, apportionment);
  //       }
  //       return { fieldAttributes: { hint } };
  //     }
  //   },
  // },
};
