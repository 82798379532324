<template>
  <v-tabs
    v-model="activeTab"
    v-bind="$attrs"
    :color="color"
    v-on="$listeners">
    <template v-for="(tab, i) in tabs">
      <v-tab :key="i" class="mx-auto pb-5">
        <v-subheader class="mt-4">
          <v-icon :color="tab.iconColor || color" class="mr-2">
            {{ tab.icon }}
          </v-icon>
          {{ tab.title }}
        </v-subheader>
      </v-tab>
      <v-tab-item :key="i" class="mx-6">
        <component
          :is="tab.tabContent"
          :item="item"
          :sub-table-headers="subTableHeaders"
          :title-type="titleType" />
      </v-tab-item>
    </template>
  </v-tabs>
</template>

<script>
export default {
  name: 'DynamicTabs',
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'primary',
    },
    item: {
      type: Object,
    },
    subTableHeaders: {
      type: Array,
    },
    tabs: {
      type: Array,
    },
    titleType: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tabs--pill,
.v-tab,
.v-tab:before {
  border-radius: 24px;

  &.v-tabs--icons-and-text {
    &:not(.v-tabs--vertical) > .v-tabs-bar {
      height: 100px;

      .v-tab, .v-tab:before {
        border-radius: 4px;
      }
    }
  }
}
</style>
