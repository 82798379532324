<template>
  <div :id="item.id">
    <GenericCardPanel class="pa-2 my-1">
      <div ref="chartStockItems"
           class="chart"
           height="600" />
    </GenericCardPanel>
  </div>
</template>
<script>
import '@plugins/charts';
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabStock',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  async created() {
    this.setStockItemsPieChart();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    async setStockItemsPieChart() {
      const payload = {
        stock_ids: [],
        use_inactive: false,
      };
      const { data } = await this.$api.Charts.stockItems(payload);

      this.chart = this.$chartsPie3D(this.$refs.chartStockItems, data.data, {
        valueName: 'quantity_total',
        categoryName: 'name',
        strokeColor: '#fff',
        strokeOpacity: 1,
      });
    },
  },
};
</script>
<style scoped>
.chart {
  width: 100%;
  height: 100vh;
}</style>
