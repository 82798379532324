import * as Icons from '@constants/icons';
import * as Options from '@constants/options';
import { masks } from '@constants/masks';
import * as ParseDomain from '@constants/parse-domains';
import config from '@src/core/config';

export function getDefaultPrecision(precision = config.DEFAULT_PRECISION ?? 5) {
  return precision;
}

export function formatDate(date, getHours = false) {
  if (!date) return null;
  const d = new Date(new Date(date).toUTCString().substr(0, 25));
  if (getHours) {
    return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()} ${d.getHours().toString().padStart(2, '0')}:${d
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}`;
  }
  return `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
}

export function parseDate(date, getHours = false) {
  if (!date) return null;

  const [dateSplit, hours] = date.split(' ');
  const [day, month, year] = dateSplit.split('/');
  const result = [year, month, day].join('-');

  const d = new Date(new Date(`${result}`).toUTCString().substr(0, 25));

  if (!d) return null;
  if (getHours) {
    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')} ${hours ?? '00:00:00'}`;
  }
  return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
}

/** *
 *  parseDateTimeToISO8061 -  Convert date and time to ISOString format
 *  date = YYYY-MM-DD or YYYY-MM-DD HH:mm:ss;
 *
 */
export function getTimezoneOffset(dateStr, offsetSign = false) {
  if (!dateStr) return;
  const date = new Date(dateStr);
  const timezoneOffset = date.getTimezoneOffset();
  const hours = Math.floor(Math.abs(timezoneOffset) / 60);
  const minutes = Math.abs(timezoneOffset) % 60;
  if (offsetSign) {
    const sign = minutes < 0 ? '-' : '+';
    return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function parseDateTimeToISO8061(date, time = false, timezone) {
  if (!date) return null;
  if (!timezone) {
    timezone = getTimezoneOffset(date) || '03:00';
  }
  if (time) {
    return new Date(`${date}T${time}-${timezone}`).toISOString();
  }
  return new Date(`${date}T00:00:00-${timezone}`).toISOString();
}

export function formatDateTimeToISO(date, time = false, timezone = '+00:00') {
  if (!date) return null;
  if (time) {
    return new Date(`${date}T${time}${timezone}`).toLocaleString('pt-BR');
  }
  return new Date(`${date}T00:00:00${timezone}`).toLocaleString('pt-BR');
}

function transformDateFormat(inputDate) {
  const [day, month, year] = inputDate.split('/');
  const transformedDate = `${year}-${month}-${day}`;

  return transformedDate;
}

export function isSameWeek(date1, date2) {
  const dateObj1 = new Date(date1);
  let dateObj2 = new Date(date2);
  if (!Number.isNaN(dateObj2)) {
    dateObj2 = new Date(transformDateFormat(date2));
  }

  dateObj1.setHours(0, 0, 0, 0);
  dateObj2.setHours(0, 0, 0, 0);

  const mondayDate1 = new Date(dateObj1);
  mondayDate1.setDate(dateObj1.getDate() - dateObj1.getDay() + (dateObj1.getDay() === 0 ? -6 : 1));
  const mondayDate2 = new Date(dateObj2);
  mondayDate2.setDate(dateObj2.getDate() - dateObj2.getDay() + (dateObj2.getDay() === 0 ? -6 : 1));

  return mondayDate1.getTime() === mondayDate2.getTime();
}

export function isDateGreaterThan(date1, date2) {
  const dateObj1 = date1 ? new Date(date1) : new Date();
  let dateObj2 = new Date(date2);
  if (!Number.isNaN(dateObj2)) {
    dateObj2 = new Date(transformDateFormat(date2));
  }
  return dateObj1 > dateObj2;
}

export function formatCep(cep) {
  if (!cep) return null;
  return cep.replace(/(\d{5})(\d{3})/g, '$1-$2');
}

export function formatCnpjCpf(cnpjCpf) {
  if (!cnpjCpf) return null;

  if (typeof cnpjCpf === 'string') {
    const formattedCnpjCpf = cnpjCpf.replace(/[^\d]/g, '');
    if (formattedCnpjCpf.length === 11) {
      return formattedCnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return formattedCnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
}

export function formatPhone(phone) {
  if (!phone) return null;

  const formattedPhone = phone.replace(/[^\d]/g, '');
  if (formattedPhone.length === 8) {
    return formattedPhone.replace(/(\d{4})(\d{4})/, '$1-$2');
  }
  if (formattedPhone.length === 9) {
    return formattedPhone.replace(/(\d{5})(\d{4})/, '$1-$2');
  }
  if (formattedPhone.length === 10) {
    return formattedPhone.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
  }
  if (formattedPhone.length === 11) {
    return formattedPhone.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
  }
}

export function formatCurrency(amount, language = 'pt-BR', currency = 'BRL', precision = getDefaultPrecision()) {
  let amountParsed = amount;
  if (typeof amountParsed !== 'number') {
    amountParsed = parseFloat(amount);
  }
  if (typeof amountParsed !== 'number') return null;
  return amountParsed.toLocaleString(language, {
    style: 'currency',
    currency,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
}

export function formatNumber(number, precision = getDefaultPrecision(), language = 'pt-BR') {
  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  number = Number.isNaN(number) ? 0 : number;
  const formatted = Number(number).toLocaleString(language, {
    style: 'decimal',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return formatted;
}

export function currencyWithSign(currency, sign) {
  let localCurrency;
  if (currency === 0) {
    localCurrency = 'R$ 0,00';
  } else {
    const start = Math.sign(currency) === -1 ? 1 : 0;
    const currencyString = formatCurrency(currency);
    localCurrency = [currencyString.slice(start, 3), sign, currencyString.slice(3)].join('');
  }
  return localCurrency;
}

export function getOptionsArrayByKey(key) {
  const options = Options.options[0];
  const optionsArray = options[key];
  return optionsArray;
}

export function getOptionText(key, value) {
  const options = Options.options[0];
  if (value && options[key]) {
    const item = options[key].find((element) => element.value === value);
    return (item && item.text) || '';
  }
  return '';
}

export function getMaskText(key, value) {
  const mask = masks[0];
  if (value && mask[key]) {
    const item = mask[key].find((element) => element.value === value);
    return (item && item.text) || '';
  }
  return '';
}

export function replaceMask(value, mask, i = 0) {
  if (!value || !mask) return;
  // eslint-disable-next-line
  return mask.replace(/#/g, () => value[i++] || '');
}

export function getOptionValue(key, text) {
  const options = Options.options[0];
  if (text && options[key]) {
    const item = options[key].find((element) => element.text === text);
    return (item && item.value) || '';
  }
  return '';
}

export function getOptionValueByValue(key, value) {
  const options = Options.options[0];
  if (value && options[key]) {
    const item = options[key].find((element) => element.value === value);
    return (item && item.value) || '';
  }
  return '';
}

export function getOptionColorByText(key, text) {
  const options = Options.options[0];
  if (text && options[key]) {
    const item = options[key].find((element) => element.text === text);
    return (item && item.color) || '';
  }
  return '';
}
export function getOptionColorByValue(key, value) {
  const options = Options.options[0];
  if (value && options[key]) {
    const item = options[key].find((element) => element.value === value);
    return (item && item.color) || '';
  }
  return '';
}

export function getOptionIconByValue(key, value) {
  const options = Options.options[0];
  if (value && options[key]) {
    const item = options[key].find((element) => element.value === value);
    return (item && item.icon) || '';
  }
  return '';
}

export function onlyNumbers(value) {
  if (!value) return null;
  return value.replace(/[^\d]/g, '');
}

export function currentDate(getHours = false) {
  return formatDate(new Date(), getHours);
}

export function getCurrentHours(datetime) {
  let d;
  if (datetime) {
    d = new Date(datetime);
  } else {
    d = new Date();
  }
  return `${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}`;
}

export const slugify = (text) => {
  const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh______';
  const p = new RegExp(a.split('').join('|'), 'g');

  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '_') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special chars
    .replace(/&/g, '_and_') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/__+/g, '_') // Replace multiple - with single -
    .replace(/^_+/, '') // Trim - from start of text
    .replace(/_+$/, ''); // Trim - from end of text
};

export function getDomainParse(key, domain) {
  const options = ParseDomain.domains[0];
  if (domain && options[key]) {
    const item = options[key].find((element) => element.domain === domain);
    return (item && item.parse_domain) || '';
  }
  return '';
}

export function modifyRef(obj, newObj) {
  Object.keys(obj).forEach((key) => {
    delete obj[key];
  });

  Object.keys(newObj).forEach((key) => {
    obj[key] = newObj[key];
  });
}

export function setInLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

export function getInLocalStorage(key) {
  if (localStorage.getItem(key)) {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      localStorage.removeItem(key);
    }
  }
}

export function removeFromLocalStorage(key) {
  localStorage.removeItem(key);
}

export function setInLocalStorageAsJson(key, value) {
  const parsed = JSON.stringify(value);
  localStorage.setItem(key, parsed);
}

export function getInLocalStorageAsJson(key) {
  if (localStorage.getItem(key)) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      localStorage.removeItem(key);
    }
  }
}

export function downloadText(filename, text) {
  const linkElement = document.createElement('a');
  linkElement.style.display = 'none';
  linkElement.setAttribute('target', '_blank');

  linkElement.href = `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`;
  linkElement.download = filename;

  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
}

export function checkSuperUser() {
  const uid = localStorage.getItem('uid');
  if (String(uid || '').indexOf('certus.inf') !== -1 || String(uid || '').indexOf('certus.com') !== -1 || String(uid || '').indexOf('icertus.com') !== -1 || String(uid || '').indexOf('icertus.com.br') !== -1) {
    return true;
  }
  return false;
}

export function threatEmpty(item, field) {
  return !item[field] || item[field].length === 0 ? {} : item[field];
}

export function orderedArray(items, order) {
  return items.sort((a, b) => {
    if (a[order] > b[order]) {
      return 1;
    }
    if (a[order] < b[order]) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
}

export function convertHourStringToMinutes(time = '00:00') {
  const [hour, minutes] = time.split(':');
  return parseInt(hour, 10) * 60 + parseInt(minutes, 10);
}

export function convertMinutesToHourString(timeInMinutes = 0) {
  const hours = Math.trunc(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function exportItenstoArrayStrings(labels = [], items = []) {
  const keysList = labels.map((item) => item.key);
  const labelTitles = labels.map((label) => label.label);
  const rows = [];
  rows.push(labelTitles);
  items.forEach((item) => {
    const row = keysList.map((key) => item[key] ?? '');
    rows.push(row);
  });
  return rows.filter((row) => row.length > 0);
}

export function exportToCSV(filename, rows) {
  const processRow = function (row) {
    let finalVal = '';
    for (let j = 0; j < row.length; j += 1) {
      let innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = `"${result}"`;
      }
      if (j > 0) {
        finalVal += ',';
      }
      finalVal += result;
    }
    return `${finalVal}\n`;
  };

  let csvFile = '';
  for (let i = 0; i < rows.length; i += 1) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function downloadCSVFromJson(filename, arrayOfJson) {
  // convert JSON to CSV
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const header = Object.keys(arrayOfJson[0]);
  let csv = arrayOfJson.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  // Create link and download
  const link = document.createElement('a');
  link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function isValidCNPJ(cnpj) {
  if (!cnpj) return false;

  if (
    !cnpj
    || cnpj === '00000000000000'
    || cnpj === '11111111111111'
    || cnpj === '22222222222222'
    || cnpj === '33333333333333'
    || cnpj === '44444444444444'
    || cnpj === '55555555555555'
    || cnpj === '66666666666666'
    || cnpj === '77777777777777'
    || cnpj === '88888888888888'
    || cnpj === '99999999999999'
  ) {
    return false;
  }

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof cnpj === 'string';
  const validTypes = isString || Number.isInteger(cnpj) || Array.isArray(cnpj);

  // Elimina valor em formato inválido
  if (!validTypes) return false;

  // Filtro inicial para entradas do tipo string
  if (isString) {
    // Limita ao máximo de 18 caracteres, para CNPJ formatado
    if (cnpj.length > 18) return false;

    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(cnpj);
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(cnpj);

    // Se o formato é válido, usa um truque para seguir o fluxo da validação
    if (digitsOnly || validFormat) return true;
    return false;
  }

  // Guarda um array com todos os dígitos do valor
  const match = cnpj.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false;

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  // Cálculo validador
  const calc = (x) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i = i) {
      const n = slice[x - i];
      factor -= 1;
      sum += n * factor;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 === digits[1];
}

export function isValidCPF(cpf) {
  if (typeof cpf !== 'string') return false;
  cpf = cpf.replace(/[\s.-]*/gim, '');

  if (
    !cpf
    || cpf.length !== 11
    || cpf === '00000000000'
    || cpf === '11111111111'
    || cpf === '22222222222'
    || cpf === '33333333333'
    || cpf === '44444444444'
    || cpf === '55555555555'
    || cpf === '66666666666'
    || cpf === '77777777777'
    || cpf === '88888888888'
    || cpf === '99999999999'
  ) {
    return false;
  }

  /**
  const regex = /^(?:(\d)\1{10})$|(\D)|^(\d{12,})$|^(\d{0,10})$/g;
  const match = cpf.match(regex);

  if (!match) return false;
  */

  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i += 1) {
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i += 1) {
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
}

export function isValidFullName(name) {
  if (name.lenght < 5) return false;
  const countWord = name.split(/\d+/);
  if (countWord.lenght < 2) return false;
  if (/\s/g.test(name) === false) return false;
  return true;
}

export function filterArray(array, prop) {
  const distinctValues = new Set();
  return array.filter((obj) => {
    const value = obj[prop];
    if (!distinctValues.has(value)) {
      distinctValues.add(value);
      return true;
    }
    return false;
  });
}

export function formatBoolean(value) {
  return value ? 'Sim' : 'Não';
}

export function formatOption(option, value) {
  if (!value) return '-';
  return this.getOptionText(option, value);
}

export function formatArrayToOptions(arrayOrigin) {
  const options = [];
  if (arrayOrigin) {
    arrayOrigin = arrayOrigin.forEach((element) => {
      options.push({ text: element, value: element });
    });
  }
  return options;
}

export function buildTextValueFromArray(list) {
  const newList = [];

  list.forEach((text, index) => {
    if (index % 2 === 1) {
      return;
    }
    if (text === "''") {
      text = '';
    }
    const value = list[index + 1];
    newList.push({ text, value });
  });
  return newList;
}

export function removeIndexByName(array, indexesToRemove) {
  array.forEach((field, index) => {
    indexesToRemove.forEach((fieldName) => {
      if (field.name === fieldName) {
        array.splice(index);
      }
    });
  });
}

export function removeEmptyProperties(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));
}

export function addItemByPosition(array, modifications) {
  if (!modifications && modifications.position) return;

  modifications.forEach((modification) => {
    array.splice(modification.position, 0, modification);
  });
  array.forEach((arrayItem) => {
    delete arrayItem.position;
  });
}

export function stripHTML(value) {
  if (typeof value === 'string') {
    return value.replace(/<.*?>/g, '');
  }
  return value;
}

/**
 * Converts a string written in snake case to camel case
 *
 * @param {String} str String in snake case (ex: snake_case)
 * @returns {String} String in camel case (ex: CamelCase)
 */
export function snakeToCamel(str) {
  if (!str) return '';
  return str.toLowerCase().replace(/([-_][a-z])/g, (group) => group
    .toUpperCase()
    .replace('-', '')
    .replace('_', ''));
}

export function camelToSnake(camelCase) {
  return camelCase.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
}

/**
   * Convert an query array to string and format to graphql collection form
   *
   * @param {Array} arr It consists of the representation of fields in array form - ex: ["id","name"]
   * @returns {String} The string representation of the array in collection form - ex: {id name}
   *
   */
export function generateGraphqlCollection(arr) {
  if (!arr) return '';
  return JSON.stringify(arr, (_, value) => value)
    .replaceAll('"', '')
    .replaceAll(',', ' ')
    .replaceAll('[', '{ ')
    .replaceAll(']', ' }')
    .replace(/([-_][a-z])/g, (group) => group
      .toUpperCase()
      .replace('-', '')
      .replace('_', ''));
}

export function decodeGraphqlResponse(arr) {
  // if (!arr) return '';
  return JSON.stringify(arr, (key) => key)
    .replace(/([A-Z])/g, ' $1').split(' ').join('_')
    .toLowerCase();
}

export function camelToUnderscore(key) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase();
}

/**
 * Converts the keys of an array of CamelCase objects to snake_case.
 *
 *  @param {Array} input - The array of objects with keys in CamelCase.
 *  @return {Array} A new array of objects with the keys converted to snake_case.
 */
export function formatCamelCaseKeysToSnakeCaseKeys(input) {
  return input.map((obj) => {
    const newObj = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const snakeCaseKey = key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);

        if (Array.isArray(obj[key])) {
          newObj[snakeCaseKey] = obj[key].map((item) => (typeof item === 'object'
            ? formatCamelCaseKeysToSnakeCaseKeys([item])[0]
            : item));
        } else if (typeof obj[key] === 'object') {
          // eslint-disable-next-line prefer-destructuring
          newObj[snakeCaseKey] = formatCamelCaseKeysToSnakeCaseKeys([obj[key]])[0];
        } else {
          newObj[snakeCaseKey] = obj[key];
        }
      }
    }

    return newObj;
  });
}

/**
 * Convert a filter written in array form to graphql filter form
 *
 * @param {Array} arr It consists of the representation of filters in array form - ex: [{ "use_inactive_eq": false, "type_nature_in": ["purchase"]}]
 * @returns {String} The string representation of the array in filter form - ex: {use_inactive_eq: false, type_nature_in: [purchase]}
 */
export function generateGraphqlFilter(arr) {
  if (!arr) return '';
  return JSON.stringify(arr)
    .replaceAll('"', '');
}

export function removeNullUndefined(object) {
  // eslint-disable-next-line
  for (const key in object) {
    if (object[key] === null || object[key] === undefined) {
      delete object[key];
    } else if (typeof object[key] === 'object') {
      removeNullUndefined(object[key]);
    }
    return;
  }
}

export function removeWhiteSpacesFrom(fields, object) {
  if (!Array.isArray(fields)) return;
  fields.forEach((name) => {
    if (!object[name]) return;
    object[name] = object[name].trim();
  });
}

/**
 * Returns the icon reference based on the constant name.
 * @param {string} name - The name of the icon constant.
 * @returns {string|null} The corresponding icon reference, or null if the icon is not found.
 * @example
 * // Import the getIconByName function
 * import { getIconByName } from './path/to/the/constants-file';
 *
 * // Get the icon reference based on the constant name
 * const icon = getIconByName('ICON_ACCOUNT_CASH');
 * if (icon) {
 *   // Do something with the icon
 * } else {
 *   console.error('Icon not found.');
 * }
 */
export function getIconByName(name) {
  if (Object.prototype.hasOwnProperty.call(Icons, name)) {
    return Icons[name];
  }
  console.error(`Icon with the name '${name}' not found.`);
  return null;
}
