import { MASK_MONTH_YEAR } from '@constants/masks';

export default {
  titles: {
    item: 'Item faturamento dos ultimos 12 meses',
    items: 'Itens faturamento dos ultimos 12 meses',
    edit: 'Editar Item',
    create: 'Criar Item',
    new: 'Adicionar Item',
  },
  fields: [
    {
      label: 'Mês de referência',
      name: 'reference_month',
      type: 'string',
      component: 'dynamic-text-field',
      list: true,
      mask: MASK_MONTH_YEAR,
      md: 6,
    },
    {
      label: 'Valor total do faturamento',
      name: 'billing_total_value',
      formattedName: 'billing_total_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      precision: 2,
      md: 6,
    },
  ],
};
