<template>
  <div :id="item.id">
    <GenericCardPanel
      title="Informações do Fluxo de Caixa"
      :fields="cashFlowInfoFields" />
  </div>
</template>

<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabCashFlow',
  components: { GenericCardPanel },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      cashFlowInfoFields: [],
    };
  },
  mounted() {
    this.cashFlowInfoFields = [
      { label: 'Data de Criação', value: this.item.date_formatted, col: 6 },
      { label: 'Nome', value: this.item.name, col: 6 },
      { label: 'Data de Início', value: this.item.start_date_formatted, col: 3 },
      { label: 'Data Final', value: this.item.end_date_formatted, col: 3 },
      { label: 'Tipo', value: this.item.type_formatted, col: 3 },
      { label: 'Resumido', value: this.item.is_summarized_formatted, col: 3 },
      // { label: 'Consolidar', value: this.item.is_consolidate_start_formatted, col: 3 },
    ];
  },
};
</script>

<style scoped>
.red-color {
  color: red;
}
.green-color {
  color: green;
}
.gray-color {
  color: gray;
}</style>
