<template>
  <div>
    <v-card color="white" class="pa-2 my-1 mt-n4" outlined>
      <v-card-title class="indigo--text text--lighten-1">
        <span class="text-md-h6">CONTATOS</span>
      </v-card-title>
      <v-card-text>
        <div v-if="item.contacts && item.contacts.length > 0">
          <template v-for="(element, index) in item.contacts">
            <v-divider v-if="index !== 0" :key="index" class="divider mt-n2 mb-6 mx-2" />
            <GenericCardPanel :key="index"
                              bg-color="white"
                              text-bold
                              :text-style="{ fontSize: 'medium' }"
                              text-color="grey--text text--darken-3 text-h6 mt-n8"
                              class="mb-2"
                              :title="`Contato ${element.main === true ? 'principal' : 'auxiliar'}`"
                              :fields="getContactFields(element)" />
          </template>
        </div>
        <div v-else>
          Não há contatos cadastrados
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabContacts',
  components: {
    GenericCardPanel,
  },
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    getContactFields(item) {
      return [
        { label: 'Nome/Empresa', value: item.name, col: 3 },
        { label: 'E-mail', value: item.email, col: 3 },
        { label: 'Telefone', value: item.telephone, col: 3 },
        { label: 'Site', value: item.site, col: 3 },
        { label: 'Aniversário', value: item.date_birth, col: 3 },
        { label: 'Gênero', value: item.gender_formatted, col: 3 },
        { label: 'Setor', value: item.sector, col: 3 },
        { label: 'Notificações', value: item.type_notification_formatted, col: 3 },
      ];
    },
  },
};
</script>

<style>
.divider.v-divider {
  border-color: rgba(0,0,0,0.09);
    border-width: thin 0px 2.6px;
    border-radius: -1;
}
</style>
