import ManifestSchema from '@schemas/Manifest';

const ListManifest = () => import(/* webpackChunkName: "list-manifest" */'./ListManifest');
const DetailsManifest = () => import(/* webpackChunkName: "details-manifest" */'./DetailsManifest');

export default [
  {
    path: ManifestSchema.routes.list.path,
    name: ManifestSchema.routes.list.name,
    meta: {
      requiresAuth: true,
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: ManifestSchema.titles.items },
      ],
    },
    component: ListManifest,
  },
  {
    path: ManifestSchema.routes.create.path,
    name: ManifestSchema.routes.create.name,
    meta: {
      requiresAuth: true,
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: ManifestSchema.titles.items, route: ManifestSchema.routes.list.name },
        { name: 'Cadastrar' },
      ],
    },
    component: DetailsManifest,
  },
  {
    path: ManifestSchema.routes.edit.path,
    name: ManifestSchema.routes.edit.name,
    meta: {
      requiresAuth: true,
      rule: 'isInitialRule',
      breadcrumb: [
        { name: 'Movimentações' },
        { name: ManifestSchema.titles.items, route: ManifestSchema.routes.list.name },
        { name: 'Editar' },
      ],
    },
    component: DetailsManifest,
  },
];
