import Config from '@src/core/config';

import { routes as analytics } from '@modules/analytics';
import { routes as anticipation } from '@modules/anticipation';
import { routes as auth } from '@modules/auth';
import { routes as integrations } from '@modules/integrations';
import { routes as dashboard } from '@modules/main';
import { routes as digitalbank } from '@modules/digitalbank';
import { routes as entry } from '@modules/entries';
import { routes as financial } from '@modules/financial';
import { routes as production } from '@modules/production';
import { routes as reports } from '@modules/reports/index';
import { routes as settings } from '@modules/settings';
import { routes as sellmore } from '@modules/sellmore';
import { routes as supply } from '@modules/supply';
import { routes as table } from '@modules/tables';

const externalRoutes = [
  {
    name: '/bidding/national',
    path: 'external-biddings-list',
    meta: {
      external: {
        path: 'https://pncp.gov.br/app/editais?q=&status=recebendo_proposta&pagina=1',
        newWindow: true,
      },
    },
  },
  {
    name: '/supply/pdv',
    path: 'external-pdv-list',
    meta: {
      external: {
        path: Config.PDV_URL,
        newWindow: true,
      },
    },
  },
  {
    name: '/settings/helpcenter',
    path: 'settings-helpcenter-list',
    meta: {
      external: {
        path: 'https://intercom.help/icertus/pt-BR/',
        newWindow: true,
      },
    },
  },
  {
    name: '/anticipation/informations',
    path: 'anticipation-informations',
    meta: {
      external: {
        path: 'https://icertus.com.br/antecipacao-de-recebiveis/',
        newWindow: true,
      },
    },
  },
];

export default [
  ...analytics,
  ...anticipation,
  ...auth,
  ...integrations,
  ...dashboard,
  ...digitalbank,
  ...entry,
  ...financial,
  ...production,
  ...reports,
  ...settings,
  ...sellmore,
  ...supply,
  ...table,
  ...externalRoutes,
  { path: '*', redirect: '/auth/logout' },
];
