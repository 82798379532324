import _ from 'lodash';
import Store from '@store/index';
import loadingTemplate from '@plugins/loading/LoadingTemplate';
import dialogLoadingTemplate from '@plugins/loading/DialogLoadingTemplate';
import vuetify from '@plugins/vuetify';

export default {
  install(Vue) {
    Vue.prototype.$startLoading = (loaderMessage) => {
      Store.dispatch('startLoading', loaderMessage);
    };

    Vue.prototype.$endLoading = (loaderMessage) => {
      Store.dispatch('endLoading', loaderMessage);
    };

    Vue.prototype.$isLoading = (loaderMessage) => Store.getters.isLoading(loaderMessage);

    Vue.prototype.$anyLoading = () => Store.getters.anyLoading;

    Vue.component('DmLoading', loadingTemplate);
    Vue.component('DmDialogLoading', dialogLoadingTemplate);

    const LoadingDialogConstructor = Vue.extend(dialogLoadingTemplate);
    const instances = {};

    Vue.prototype.$loadingDialog = (propsData, instanceName = 'default') => {
      if (instances[instanceName]) {
        _.assign(instances[instanceName], propsData);
        return instances[instanceName];
      }

      instances[instanceName] = new LoadingDialogConstructor(
        {
          vuetify,
          propsData,
        },
      );
      instances[instanceName].$mount();
      document.body.appendChild(instances[instanceName].$el);

      return instances[instanceName];
    };

    Vue.loadingDialog = Vue.prototype.$loadingDialog;
  },
};
