// service that handle search requests
const endpoint = 'mixpanel';

export default ($axios) => ({
  async sendLog(params) {
    const payload = {
      event_id: params.event_id,
      event: params.event,
      user_params: {
        name: params.name,
        email: params.email,
        customer_id: params.customer_id,
        company_id: params.company_id,
      },
    };

    const res = $axios.post(`${endpoint}.json`, payload);
    return res;
  },
});
