import {
  OPTIONS_TYPE_NATURE,
  OPTIONS_NATIONAL_SIMPLE_TYPE,
  OPTIONS_DATE_ORIGIN,
} from '@constants/options';
import { ICON_TAXES } from '@constants/icons';

export default {
  service: 'NatureOperations',
  icon: ICON_TAXES,
  routes: {
    list: 'tables-natureoperations-list',
    edit: 'tables-natureoperations-edit',
    create: 'tables-natureoperations-create',
  },
  titles: {
    item: 'Natureza de Operação',
    items: 'Natureza de Operações',
    edit: 'Editar Natureza de Operação',
    create: 'Criar Natureza de Operação',
    new: 'Novo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  graphql: {
    useIndex: true,
  },
  filterUrl: 'nature_operations/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 3,
    },
    {
      label: 'Tipo',
      name: 'type_nature',
      formattedName: 'type_nature_formatted',
      type: 'string',
      list: true,
      required: true,
      component: 'dynamic-select',
      items: OPTIONS_TYPE_NATURE,
      md: 3,
      clearable: true,
    },
    {
      label: 'Movimentar estoque',
      name: 'use_move_stock',
      formattedName: 'use_move_stock_formatted',
      type: 'boolean',
      list: true,
      component: 'dynamic-checkbox',
      md: 2,
    },
    // {
    //   label: 'Lançamento manual de impostos',
    //   name: 'use_manual_tax_launch',
    //   formattedName: 'use_manual_tax_launch_formatted',
    //   type: 'boolean',
    //   list: true,
    //   component: 'dynamic-checkbox',
    //   md: 2,
    // },
    {
      label: 'Desabilitado',
      name: 'use_inactive',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    // Informações adicionais
    {
      label: 'Informações de Interesse do Contribuinte',
      name: 'taxpayer_additional_info',
      type: 'string',
      component: 'dynamic-text-area',
      md: 12,
      tab: 'Informações adicionais',
    },
    {
      label: 'Informações de Interesse do Fisco',
      name: 'person_additional_info',
      type: 'string',
      component: 'dynamic-text-area',
      md: 12,
      tab: 'Informações adicionais',
    },
    // Outras Configurações
    {
      label: 'Considerar Operação como Faturamento',
      name: 'use_billing',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 6,
      tab: 'Outras Configurações',
    },
    {
      label: 'Tributo Impostos',
      name: 'use_taxes',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 6,
      tab: 'Outras Configurações',
      hint: 'Total aproximado dos tributos Federais, Estaduais e Municipais: ... Fonte: IBPT',
      persistentHint: true,
    },
    // {
    //   label: 'Gerar (Contas a Pagar - Contas a Receber) pela data do Pedido',
    //   name: 'use_account',
    //   type: 'boolean',
    //   component: 'dynamic-checkbox',
    //   md: 6,
    //   tab: 'Outras Configurações',
    // },
    // {
    //   label: 'Priorizar CST ICMS',
    //   name: 'use_st_icms',
    //   type: 'boolean',
    //   component: 'dynamic-checkbox',
    //   md: 6,
    //   tab: 'Outras Configurações',
    //   hint: 'Ignora a CST cadastrada no cadastro de Produto e Serviço',
    //   persistentHint: true,
    // },
    // {
    //   label: 'Aplicar Natureza de Operação por NCM',
    //   name: 'use_nature_operation_ncm',
    //   type: 'boolean',
    //   component: 'dynamic-checkbox',
    //   md: 6,
    //   tab: 'Outras Configurações',
    // },
    // {
    //   label: 'Natureza de Operação por NCM',
    //   name: 'type_nature',
    //   formattedName: 'type_nature_formatted',
    //   type: 'string',
    //   list: true,
    //   component: 'dynamic-select',
    //   items: OPTIONS_TYPE_NATURE,
    //   md: 3,
    // },
    {
      label: 'Gerar Contas a Receber / Pagar por Data do Pedido',
      name: 'date_origin',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_DATE_ORIGIN,
      md: 6,
      tab: 'Outras Configurações',
      clearable: true,
      disabled: () => false,
    },
    {
      label: 'Tabela Simples Nacional',
      name: 'simple_national_type',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_NATIONAL_SIMPLE_TYPE,
      md: 6,
      clearable: true,
      tab: 'Outras Configurações',
    },
    /*
    {
      label: 'Incluir nos Dados Adicionais',
      component: 'dynamic-divider',
      tab: 'Outras Configurações',
    },
    {
      label: 'Número do Pedido',
      name: 'use_order',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 4,
      tab: 'Outras Configurações',
    },
    {
      label: 'Vendedor(a)',
      name: 'use_seller_info',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 4,
      tab: 'Outras Configurações',
    },
    {
      label: 'Forma de Parcelamento',
      name: 'use_type_installment_info',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 4,
      tab: 'Outras Configurações',
    },
    {
      label: 'Forma de Pagamento',
      name: 'use_payment_type_info',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 4,
      tab: 'Outras Configurações',
    },
    {
      label: 'Número da Ordem',
      name: 'use_order_number',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 4,
      tab: 'Outras Configurações',
    },
    */
  ],
};
