export default {
  Name: 'Report',
  Width: '8.2292in',
  Language: 'pt-BR',
  ReportParameters: [
    {
      AllowBlank: true,
      AllowNull: true,
      DataType: 'String',
      Hidden: true,
      Name: 'ReportName',
      Prompt: 'ReportName',
    },
  ],
  Layers: [
    {
      Name: 'default',
    },
  ],
  CustomProperties: [
    {
      Name: 'DisplayType',
      Value: 'Page',
    },
    {
      Name: 'SizeType',
      Value: 'Default',
    },
    {
      Name: 'PaperOrientation',
      Value: 'Portrait',
    },
  ],
  Page: {
    PageWidth: '21cm',
    PageHeight: '29.7cm',
    RightMargin: '0cm',
    LeftMargin: '0cm',
    TopMargin: '0cm',
    BottomMargin: '0cm',
    Columns: 1,
    ColumnSpacing: '0.5cm',
  },
  PageHeader: {
    Name: 'PageHeader',
    Height: '0.8958in',
    ReportItems: [
      {
        Type: 'image',
        Name: 'Image1',
        Source: 'Embedded',
        Value: 'Logo',
        MIMEType: 'image/png',
        Sizing: 'FitProportional',
        HorizontalAlignment: 'Center',
        VerticalAlignment: 'Middle',
        Left: '0.17in',
        Top: '0.1in',
        Width: '1.4687in',
        Height: '0.6771in',
      },
      {
        Type: 'textbox',
        Name: 'NomeEmpresa',
        ZIndex: 1,
        CanGrow: true,
        KeepTogether: true,
        Value: '=First(Fields!name.Value, "CompanyDataSet")',
        Style: {
          FontSize: '12pt',
          FontWeight: 'Bold',
          PaddingLeft: '2pt',
          PaddingRight: '2pt',
          PaddingTop: '2pt',
          PaddingBottom: '2pt',
          TextAlign: 'Center',
        },
        Left: '1.7708in',
        Top: '0.2052in',
        Width: '5.3646in',
        Height: '0.25in',
      },
      {
        Type: 'textbox',
        Name: 'PaginaEData',
        ZIndex: 2,
        CanGrow: true,
        KeepTogether: true,
        Value: '="Pag " & Globals!PageNumber & " de " & Globals!TotalPages & "\n " & Day(DateString()) & "/" & Month(DateString()) & "/" & Year(DateString())',
        Style: {
          FontSize: '8pt',
          PaddingLeft: '2pt',
          PaddingRight: '2pt',
          PaddingTop: '2pt',
          PaddingBottom: '2pt',
          TextAlign: 'Left',
        },
        Left: '7.2605in',
        Top: '0.1in',
        Width: '0.9687in',
        Height: '0.4896in',
      },
      {
        Type: 'textbox',
        Name: 'TextBox1',
        ZIndex: 3,
        CanGrow: true,
        KeepTogether: true,
        Value: '=Parameters!ReportName.Value',
        Style: {
          FontSize: '12pt',
          FontWeight: 'Bold',
          PaddingLeft: '2pt',
          PaddingRight: '2pt',
          PaddingTop: '2pt',
          PaddingBottom: '2pt',
          TextAlign: 'Center',
        },
        Left: '1.7917in',
        Top: '0.514in',
        Width: '5.3646in',
        Height: '0.25in',
      },
    ],
  },
  Body: {
    Height: '2pt',
  },
  PageFooter: {
    Name: 'PageFooter',
    Height: '0.7799in',
    ReportItems: [
      {
        Type: 'textbox',
        Name: 'TextBox2',
        CanGrow: true,
        KeepTogether: true,
        Value: '=First(Fields!name.Value, "CompanyDataSet")',
        Style: {
          FontSize: '12pt',
          FontWeight: 'Bold',
          PaddingLeft: '2pt',
          PaddingRight: '2pt',
          PaddingTop: '2pt',
          PaddingBottom: '2pt',
          TextAlign: 'Center',
        },
        Left: '1.3646in',
        Top: '0.0233in',
        Width: '5.3646in',
        Height: '0.25in',
      },
      {
        Type: 'textbox',
        Name: 'TextBox3',
        ZIndex: 1,
        CanGrow: true,
        KeepTogether: true,
        Value: '=First(Fields!street.Value, "MainAddressDataSet") & ", " & First(Fields!number.Value, "MainAddressDataSet") & " - " & First(Fields!neighborhood.Value, "MainAddressDataSet") & " - " & First(Fields!city.Value, "MainAddressDataSet") & " " & First(Fields!uf.Value, "MainAddressDataSet") & " CEP " & First(Fields!zip_code.Value, "MainAddressDataSet") & "  Fone " & First(Fields!telephone.Value, "MainContactDataSet") & "\nE-mail: " & First(Fields!email.Value, "MainContactDataSet") & " Site: " & First(Fields!site.Value, "MainContactDataSet")',
        Style: {
          PaddingLeft: '2pt',
          PaddingRight: '2pt',
          PaddingTop: '2pt',
          PaddingBottom: '2pt',
          TextAlign: 'Center',
        },
        Left: '0.4522in',
        Top: '0.2799in',
        Width: '7.3021in',
        Height: '0.4688in',
      },
      {
        Type: 'line',
        Name: 'Line1',
        ZIndex: 2,
        StartPoint: {
          X: '0.2cm',
          Y: '0.041cm',
        },
        EndPoint: {
          X: '20.8cm',
          Y: '0.041cm',
        },
      },
    ],
  },
};
