import Vue from 'vue';
import { ICON_TRUCK } from '@constants/icons';
import { OPTIONS_UF } from '@constants/options';
import { MASK_BR_CAR_PLATE, MASK_RNTC } from '@constants/masks';
import '@plugins/rules';

export default {
  icon: ICON_TRUCK,
  fields: [
    {
      label: 'TRANSPORTADORA',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'infoFreighter',
    },
    {
      label: 'Transportadora',
      name: 'shipping_company_id',
      formattedName: 'shipping_company.to_s',
      type: 'number',
      list: true,
      required: true,
      component: 'dynamic-select',
      dataSource: {
        service: 'Registers',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          type_register_array: 'shipping_company',
          query: ['id', 'name'],
        }, 'name asc'],
      },
      itemText: 'name',
      itemValue: 'id',
      md: 12,
      tabGroup: 'infoFreighter',
    },
    {
      label: 'Espécie',
      name: 'volume_species',
      type: 'text',
      component: 'dynamic-text-field',
      md: 4,
      tabGroup: 'infoFreighter',
    },
    {
      label: 'Marca',
      name: 'volume_brand',
      type: 'text',
      component: 'dynamic-text-field',
      md: 4,
      tabGroup: 'infoFreighter',
    },
    {
      label: 'Nº de Conhecimento',
      name: 'volume_number',
      type: 'text',
      component: 'dynamic-text-field',
      md: 4,
      tabGroup: 'infoFreighter',
    },
    {
      label: 'Quantidade',
      name: 'volume_amount',
      type: 'number',
      component: 'dynamic-text-field',
      mask: '###############',
      md: 4,
      tabGroup: 'infoFreighter',
    },
    {
      label: 'Peso Líquido',
      name: 'volume_net_weight',
      type: 'number',
      component: 'dynamic-number-field',
      md: 4,
      precision: 3,
      tabGroup: 'infoFreighter',
    },
    {
      label: 'Peso Bruto',
      name: 'volume_gross_weight',
      type: 'number',
      component: 'dynamic-number-field',
      md: 4,
      precision: 3,
      tabGroup: 'infoFreighter',
    },
    {
      label: 'VEÍCULO',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
      tabGroup: 'infoVehicle',
    },
    {
      md: 12,
      component: 'dynamic-divider',
      colClass: 'mb-2',
    },
    {
      label: 'Placa',
      name: 'vehicle_plate',
      type: 'text',
      component: 'dynamic-text-field',
      mask: MASK_BR_CAR_PLATE,
      md: 4,
      rules: [
        Vue.prototype.$rules.brCarPlate(),
      ],
      tabGroup: 'infoVehicle',
    },
    {
      label: 'UF',
      name: 'vehicle_uf',
      type: 'text',
      component: 'dynamic-select',
      items: OPTIONS_UF,
      md: 4,
      tabGroup: 'infoVehicle',
    },
    {
      label: 'RNTC',
      name: 'vehicle_rntc',
      type: 'text',
      mask: MASK_RNTC,
      component: 'dynamic-text-field',
      md: 4,
      tabGroup: 'infoVehicle',
    },
  ],
};
