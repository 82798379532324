<template>
  <DataList
    :headers="taxesHeaders"
    :sort-by="['id']"
    item-key="id"
    :no-edit="true"
    :no-delete="true"
    :no-check="true"
    :hide-default-header="hideDefaultHeader"
    :sort-desc="[false, true]"
    :items="taxes">
    <template v-slot:customHeader>
      <thead :class="`v-data-table-header ${customHeader.customClass}`">
        <tr>
          <th
            v-for="(n, index) in customHeader.ungroupedHeadersFront"
            :key="index" />
          <template v-for="(header) in customHeader.groupHeaders">
            <th
              :key="header.text"
              class="text-center parent-header"
              :colspan="header.colspan"
              :class="header.class">
              {{ header.text }}
            </th>
          </template>
        </tr>
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="text-center"
            :class="index >= customHeader.ungroupedHeadersFront ? 'child-header': ''"
            :style="header.width ? `minWidth: ${header.width}` : 'fit-content'">
            {{ header.text }}
          </th>
        </tr>
      </thead>
    </template>
  </DataList>
</template>

<script>
import DataList from '@sharedComponents/dynamic/DataList';
import SchemaUtil from '@schemas/Util';

export default {
  name: 'TabListTaxes',
  components: {
    DataList,
  },
  props: {
    taxes: {
      type: Array,
      default: () => [],
    },
    schema: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      customHeader: [],
      headers: [],
      hideDefaultHeader: true,
    };
  },
  computed: {
    taxesHeaders() {
      return SchemaUtil.getHeaders(this.childSchema.schema, false);
    },
    childSchema() {
      return this.schema.childSchemas.find((schema) => schema.name === 'taxes');
    },
  },
  mounted() {
    this.getChildSchemaObjects();
  },
  methods: {
    getChildSchemaObjects() {
      this.customHeader = this.childSchema.customHeader;
      this.hideDefaultHeader = this.childSchema.hideDefaultHeader;
      this.headers = this.taxesHeaders;
    },
  },
};
</script>
