<template>
  <div class="tab-devolution-purchase-itens">
    <GenericCardForm>
      <PartialPurchaseTaxes :child-schema="getChildSchemaByName('taxes')" :taxes="itemTaxes" />
    </GenericCardForm>
  </div>
</template>

<script>
import { ICON_REFRESH } from '@constants/icons';
import PartialPurchaseSchema from '@schemas/PartialPurchase';
import GenericCardForm from '@sharedComponents/dynamic/GenericCardForm';
import PartialPurchaseTaxes from './PartialPurchaseTaxes';

export default {
  name: 'TabOrderPurchaseItemTax',
  components: {
    GenericCardForm,
    PartialPurchaseTaxes,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      itemTaxes: [],
      icons: {
        refresh: ICON_REFRESH,
      },
      actions: [],
      filterParams: {},
    };
  },
  computed: {
    schema() {
      return PartialPurchaseSchema;
    },
  },
  created() {
    this.itemTaxes = this.item.items;
  },
  methods: {
    getChildSchemaByName(name) {
      return this.schema.childSchemas.find((schema) => schema.name === name);
    },
  },
};
</script>
