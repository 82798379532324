<template>
  <v-app id="app" :class="changeLayout() === 'gradient'?'gradient main-app':'main-app'">
    <Loader :is-visible="isLoading" text="Aguarde" />
    <div v-if="changeLayout() === 'clouds'" class="ocean">
      <div class="wave" />
      <div class="wave" />
      <div class="wave" />
      <div class="wave" />
      <div class="wave" />
    </div>
    <Header v-if="!printMode && (changeLayout() === 'blank')" class="d-none d-flex" style="left: 50px !important;" />
    <Sidebar v-if="!printMode && (changeLayout() === 'blank')" class="d-none d-flex" />
    <v-main>
      <a v-if="$route.name !== 'auth-register-basic'" :href="urlWhatsappSupport" class="float" target="_blank">
        <v-icon color="white" class="my-float" large>{{ icons.whatsapp }}</v-icon>
      </a>
      <div
        v-if="$route.name === 'auth-login' || $route.name === 'auth-redirect'"
        class="auth-login">
        <transition name="fade">
          <router-view />
        </transition>
      </div>
      <div
        v-else-if="$route.name === 'auth-register' || $route.name === 'auth-steps'"
        class="auth-register">
        <v-container v-if="!printMode" class="d-flex" fluid>
          <v-row>
            <v-col align="center">
              <transition name="fade">
                <router-view />
              </transition>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-else class="all-pages">
        <v-container v-if="!printMode" class="px-4 py-0 fill-height d-none d-flex" fluid>
          <v-row class="fill-height">
            <v-col>
              <transition name="fade">
                <router-view />
              </transition>
            </v-col>
          </v-row>
        </v-container>
        <router-view v-else />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { axiosInstance } from './modules/api';
import { mapState } from 'vuex';
import config from '@src/core/config';
import { ICON_WHATSAPP } from '@constants/icons';

import Header from './modules/main/components/Header';
import Loader from './modules/main/components/widgets/Loader';
import Sidebar from './modules/main/components/Sidebar';

export default {
  components: {
    Header,
    Loader,
    Sidebar,
  },
  data() {
    return {
      icons: {
        whatsapp: ICON_WHATSAPP,
      },
      isLoading: false,
      axiosInterceptor: null,
      iCertusLogo: require('@assets/iCertus.png'),
      urlWhatsappSupport: '',
    };
  },
  computed: {
    ...mapState({
      printMode: (state) => state.app.printMode,
    }),
  },
  mounted() {
    this.enableInterceptor();
    this.whatsappSupport();
  },
  methods: {
    toggleTheme() {},
    checkEnv() {
      return process.env.VUE_APP_NODE_ENV === 'development';
    },
    changeLayout() {
      if (this.$route.meta.layout === 'login') {
        return 'clouds';
      }
      if (this.$route.meta.layout === 'registerbasic') {
        return 'gradient';
      }
      return 'blank';
    },
    enableInterceptor() {
      this.axiosInterceptor = axiosInstance.interceptors.request.use((res) => {
        this.isLoading = true;
        return res;
      }, (error) => {
        this.isLoading = false;
        return Promise.reject(error);
      });

      axiosInstance.interceptors.response.use((response) => {
        this.isLoading = false;
        return response;
      }, (error) => {
        this.isLoading = false;
        return Promise.reject(error);
      });
    },

    disableInterceptor() {
      axiosInstance.interceptors.request.eject(this.axiosInterceptor);
    },
    whatsappSupport() {
      this.urlWhatsappSupport = `https://web.whatsapp.com/send?phone=${config.WHATSAPP_SUPPORT_BR}&text=Olá!`;
    },
  },
};
</script>

<style lang="scss">
@import "./core/scss/responsive";
@import "./core/scss/global";
@import "./core/scss/overrides";
@import "./core/scss/normalize";

@font-face {
   font-family: 'Montserrat';
   src: url('../public/assets/fonts/Montserrat-Bold.ttf');
}

a {
  text-decoration: none;
}
.v-row {
  margin: 0px !important;
}
.v-main {
    padding: 0px 0px 0px 30px;
}
.gradient {
  background: linear-gradient(#9be3ec, #ffffff) !important;
}

.ocean {
  height: 5%;
  width:100%;
  position:absolute;
  bottom:0;
  left:0;
  background: #003874;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
  position: absolute;
  top: -165px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -170px;
  animation: wave 6s cubic-bezier( 0.40, 0.45, 0.42, 0.70) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

.wave:nth-of-type(3) {
  top: -190px;
  animation: wave 8s cubic-bezier( 0.45, 0.66, 0.22, 0.20) -.125s infinite, swell 6s ease -1.55s infinite;
  opacity: 1;
}

.wave:nth-of-type(4) {
  top: -180px;
  animation: wave 5s cubic-bezier( 0.20, 0.44, 0.80, 0.10) -.155s infinite, swell 8s ease -1.25s infinite;
  opacity: 1;
}

.wave:nth-of-type(5) {
  top: -175px;
  animation: wave 9s cubic-bezier( 0.50, 0.60, 0.80, 0.90) -.135s infinite, swell 6s ease -1.55s infinite;
  opacity: 1;
}

.auth-login {
  height: 100%;
  margin-left: -50px;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}
@media only screen and (min-width: 320px) and (max-width: 958.98px) {
  .v-main {
    padding-right: 10px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .v-main {
    align-items: center !important;
  }
  .main-app {
    margin-right: -10px !important;
  }

  .ocean {
    display: none;
  }
  .wave {
    width: 100%;
    background: none;
  }
}
/* right: 20px when remove intercom */
.float {
  position: fixed;
  width: 49px;
  height: 49px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 9999;
  transition: all 0.2s ease-in-out;
}

a.float:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.my-float {
  padding: 2px;
  margin-bottom: 2px;
  margin-left: 1px
}
</style>
