<template>
  <DataList
    :headers="getItemsHeaders"
    :sort-by="['id']"
    item-key="id"
    :no-edit="schema.noEdit"
    :no-delete="schema.noCreate"
    :no-check="schema.noCheck"
    :hide-default-header="schema.hideDefaultHeader"
    :sort-desc="[false, true]"
    :items="items" />
</template>

<script>
import DataList from '@sharedComponents/dynamic/DataList';
import SchemaUtil from '@schemas/Util';

export default {
  name: 'GenericTabItems',
  components: {
    DataList,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    schema: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      customHeader: [],
      headers: [],
    };
  },
  computed: {
    getItemsHeaders() {
      return SchemaUtil.getHeaders(this.schema, false);
    },
  },
  mounted() {},
  methods: {},
};
</script>
