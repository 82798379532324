import { ICON_SPROUT } from '@constants/icons';
import ProductCompositionItemBusiness from '@businesses/ProductCompositionItem';

export default {
  service: 'Compositions',
  business: ProductCompositionItemBusiness,
  icon: ICON_SPROUT,
  routes: {
    list: '',
    edit: '',
    create: '',
  },
  titles: {
    item: 'Matéria-prima da Composição do Produto',
    items: 'Matérias-primas da Composição do Produto',
    edit: 'Editar Matéria-prima',
    create: 'Adicionar Matéria-prima',
    new: 'Adicionar Item',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: '',
  fields: [
    {
      label: 'Produto',
      name: 'product_id',
      formattedName: 'product.to_s',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      required: true,
      list: true,
      md: 8,
    },
    {
      label: 'Código',
      name: 'product_id',
      formattedName: 'product.code',
      type: 'number',
      component: 'dynamic-autocomplete',
      dataSource: {
        service: 'Products',
        method: 'getByFilterGraphql',
        props: [{
          use_inactive_eq: false,
          query: ['id', 'code', 'to_s'],
        }, 'name asc'],
      },
      itemText: 'code',
      itemValue: 'id',
      list: true,
      md: 4,
    },
    {
      label: 'Unidade de medida',
      name: 'unity_id',
      formattedName: 'unity.to_s',
      type: 'number',
      list: true,
      required: true,
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-measuringunits-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['unity'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      disabled: true,
      md: 6,
    },
    {
      label: 'Quantidade',
      name: 'quantity',
      formattedName: 'quantity_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      required: true,
      preference: ['General', 'number_precision_quantity'],
      min: 0.001,
      max: 99999999999999,
      step: '0.01',
      default: 1,
      md: 4,
    },
    {
      label: 'Fixa',
      name: 'is_fixed',
      formattedName: 'is_fixed_formatted',
      type: 'boolean',
      list: true,
      component: 'dynamic-checkbox',
      md: 2,
    },
    {
      label: 'Custo Unitário',
      name: 'purchase_cost',
      formattedName: 'purchase_cost_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 4,
    },
    {
      label: 'Custo Total',
      name: 'total_purchase_cost',
      formattedName: 'total_purchase_cost_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 4,
    },
  ],
};
