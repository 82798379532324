import Vue from 'vue';
import * as AppUtils from '@plugins/app-utils';

export const setTaxesValues = (formValue, { items, total_taxes, totalizers }) => {
  const unsetTax = 0.00;
  const updatedItems = formValue.items.map((item, index) => {
    const icmsTaxes = {
      base: items[index].icms?.base ?? unsetTax,
      aliquot: items[index].icms?.aliquot ?? unsetTax,
      value: items[index].icms?.value ?? unsetTax,
      base_formatted: AppUtils.formatNumber(items[index].icms?.base ?? unsetTax),
      aliquot_formatted: AppUtils.formatNumber(items[index].icms?.aliquot ?? unsetTax),
      value_formatted: AppUtils.formatNumber(items[index].icms?.value ?? unsetTax),
    };
    const icmsStTaxes = {
      base: items[index].icms_st?.base ?? unsetTax,
      aliquot: items[index].icms_st?.aliquot ?? unsetTax,
      value: items[index].icms_st?.value ?? unsetTax,
      base_formatted: AppUtils.formatNumber(items[index].icms_st?.base ?? unsetTax),
      aliquot_formatted: AppUtils.formatNumber(items[index].icms_st?.aliquot ?? unsetTax),
      value_formatted: AppUtils.formatNumber(items[index].icms_st?.value ?? unsetTax),

    };
    const icmsFinalCostumerTaxes = {
      base: items[index].icms_final_costumer?.base ?? unsetTax,
      aliquot: items[index].icms_final_costumer?.aliquot ?? unsetTax,
      value: items[index].icms_final_costumer?.value ?? unsetTax,
      base_formatted: AppUtils.formatNumber(items[index].icms_final_costumer?.base ?? unsetTax),
      aliquot_formatted: AppUtils.formatNumber(items[index].icms_final_costumer?.aliquot ?? unsetTax),
      value_formatted: AppUtils.formatNumber(items[index].icms_final_costumer?.value ?? unsetTax),

    };
    const ipiTaxes = {
      base: items[index].ipi?.base ?? unsetTax,
      aliquot: items[index].ipi?.aliquot ?? unsetTax,
      value: items[index].ipi?.value ?? unsetTax,
      base_formatted: AppUtils.formatNumber(items[index].ipi?.base ?? unsetTax),
      aliquot_formatted: AppUtils.formatNumber(items[index].ipi?.aliquot ?? unsetTax),
      value_formatted: AppUtils.formatNumber(items[index].ipi?.value ?? unsetTax),
    };
    const pisTaxes = {
      base: items[index].pis?.base ?? unsetTax,
      aliquot: items[index].pis?.aliquot ?? unsetTax,
      value: items[index].pis?.value ?? unsetTax,
      base_formatted: AppUtils.formatNumber(items[index].pis?.base ?? unsetTax),
      aliquot_formatted: AppUtils.formatNumber(items[index].pis?.aliquot ?? unsetTax),
      value_formatted: AppUtils.formatNumber(items[index].pis?.value ?? unsetTax),

    };
    const cofinsTaxes = {
      base: items[index].cofins?.base ?? unsetTax,
      aliquot: items[index].cofins?.aliquot ?? unsetTax,
      value: items[index].cofins?.value ?? unsetTax,
      base_formatted: AppUtils.formatNumber(items[index].cofins?.base ?? unsetTax),
      aliquot_formatted: AppUtils.formatNumber(items[index].cofins?.aliquot ?? unsetTax),
      value_formatted: AppUtils.formatNumber(items[index].cofins?.value ?? unsetTax),

    };
    const simpleNational = {
      base: items[index].simple_national?.base ?? unsetTax,
      aliquot: items[index].simple_national?.aliquot ?? unsetTax,
      value: items[index].simple_national?.value ?? unsetTax,
      base_formatted: AppUtils.formatNumber(items[index].simple_national?.base ?? unsetTax),
      aliquot_formatted: AppUtils.formatNumber(items[index].simple_national?.aliquot ?? unsetTax),
      value_formatted: AppUtils.formatNumber(items[index].simple_national?.value ?? unsetTax),

    };

    return {
      ...item,
      icms: icmsTaxes,
      icms_st: icmsStTaxes,
      icms_final_costumer: icmsFinalCostumerTaxes,
      ipi: ipiTaxes,
      pis: pisTaxes,
      cofins: cofinsTaxes,
      simple_national: simpleNational,
      tax_approximate_percentage: items[index].tax_approximate_percentage ?? unsetTax,
      tax_approximate: items[index].tax_approximate ?? unsetTax,
      tax_approximate_percentage_formatted: AppUtils.formatNumber(items[index].tax_approximate_percentage ?? unsetTax),
      tax_approximate_formatted: AppUtils.formatNumber(items[index].tax_approximate ?? unsetTax),
    };
  });
  formValue.items = updatedItems;
  Vue.set(formValue, 'total_taxes', total_taxes);
  Vue.set(formValue, 'total_taxes_formatted', AppUtils.formatCurrency(total_taxes));
  Vue.set(formValue, 'items', formValue.items);
  Vue.set(formValue, 'totalizers', totalizers);
};
