<template>
  <div class="tab-order-sale-itens">
    <GenericCardPanel title="Itens do Orçamento">
      <DataList :headers="headers"
                :no-check="true"
                :items="setOrderSalesItens(item)"
                item-key="id"
                class="mx-2"
                :sort-by="['id']"
                :sort-desc="[false, true]" />
    </GenericCardPanel>
  </div>
</template>

<script>
import { ICON_REFRESH } from '@constants/icons';
import DataList from '@sharedComponents/dynamic/DataList';
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabOrderSaleItems',
  components: {
    DataList,
    GenericCardPanel,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      icons: {
        refresh: ICON_REFRESH,
      },
      headers: [
        { text: 'Código', value: 'product.code' },
        { text: 'Produto', value: 'product.to_s' },
        { text: 'Nome', value: 'name' },
        { text: 'Unidade de medida', value: 'unity.to_s' },
        { text: 'Quantidade', value: 'quantity' },
        { text: 'Valor unitário', value: 'unit_value_formatted' },
        { text: 'Valor desconto', value: 'discount_value_formatted' },
        { text: '% Desconto', value: 'discount_percentage_value' },
        { text: 'Valor Total', value: 'total_value_formatted' },
        { text: 'Estoque', value: 'stock.to_s' },
        { text: 'Natureza da Operação', value: 'nature_operation.to_s' },
        { text: 'NCM', value: 'ncm.to_s' },
        { text: 'Número da Ordem', value: 'order_number' },
        { text: 'Número do Item', value: 'item_number' },
        { text: 'Observações', value: 'observation' },
      ],
      actions: [],
      filterParams: {},
    };
  },
  computed: {},
  methods: {
    setOrderSalesItens(item) {
      const OrderSalesItens = item.items;
      return OrderSalesItens;
    },
  },
};
</script>
