import Vue from 'vue';

/**
 * @param { Object } formValue
 *
 * @typedef { Object } Options
 * @property { string } key - name of the object you need to return
 * @property { Array } fields - objects you need inside return
 * @property { string } service - name of the service from $api
 *
 * @param { Options } options
 */

// @TODO Use showGraphql when AllTypes done on backend
export default async function loadDataFromShowApi(formValue, options) {
  const { key, fields, service } = options;
  const id = `${key}_id`;
  if (!formValue[id]) return;
  let response = await Vue.prototype.$api[service].show(formValue[id]);
  if (response.data) response = response.data;
  if (fields.length > 0) {
    const obj = {};
    fields.forEach((field) => {
      obj[field] = response[field];
    });
    formValue[key] = obj;
    return;
  }
  formValue[key] = response;
}
