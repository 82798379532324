const numNames = [
  ['zero', 'um', 'dois', 'três', 'quatro', 'cinco', 'seis', 'sete', 'oito', 'nove'],
  ['', 'dez', 'vinte', 'trinta', 'quarenta', 'cinquenta', 'sessenta', 'setenta', 'oitenta', 'noventa'],
  ['', 'cento', 'duzentos', 'trezentos', 'quatrocentos', 'quinhentos', 'seiscentos', 'setecentos', 'oitocentos', 'novecentos'],
];

const places = [
  '', 'mil', 'milhões',
  'bilhões', 'trilhões',
  'quatrilhões', 'quintilhões',
  'sextilhões', 'septilhões',
  'octilhões', 'nonilhões',
  'decilhões', 'undecilhões',
  'duodecilhões', 'tredecilhões',
];

const dozens = ['dez', 'onze', 'doze', 'treze', 'quatorze', 'quinze', 'dezesseis', 'dezessete', 'dezoito', 'dezenove'];

function getGroup(number) {
  if (number === '100') { return 'cem'; }

  let output = '';
  // for ++
  // eslint-disable-next-line
  for (let i = 0; i < number.length; i++) {
    const current = number[i];
    if (output) {
      // for continue
      // eslint-disable-next-line
      if (current === '0') continue;
      output += ' e ';
    }

    if (((number.length === 2 && i === 0) || (number.length === 3 && i === 1)) && current === '1') { return output + dozens[+number[i + 1]]; }

    output += numNames[number.length - i - 1][+current];
  }

  return output;
}

function numberInFull(number) {
  const output = [];
  const numbers = [];

  number = number.toString();
  while (number.length > 0) {
    const piece = number.length <= 3 ? number : number.substring(number.length - 3, 6);
    numbers.push({ number: +piece, name: getGroup(piece) });
    number = number.length - 3 > 0 ? number.substring(0, number.length - 3) : '';
  }

  if (numbers.length === 1 && numbers[0].number === 0) { return numbers[0].name; }
  // for --
  // eslint-disable-next-line
  for (let i = numbers.length - 1; i > 0; i--) {
    if (numbers[i].number !== 0) {
      output.push(`${numbers[i].name} ${numbers[i].number === 1 ? places[i].replace('ões', 'ão') : places[i]}`);
    }
  }

  if (numbers.length && numbers[0].number > 0) {
    output.push(`${(numbers.length > 1 && (numbers[0].number < 100 || numbers[0].number % 100 === 0) ? 'e ' : '')}${numbers[0].name}`);
  } else if (output.length > 1) { output.splice(output.length - 1, 0, 'e'); }

  return output.join(' ');
}

function numberInFullMonetary(numero) {
  const numberStr = numero.toString().replace('-', '').split('.');
  const numberBefore = numberStr[0] || 0;
  const numberAfter = (`${numberStr[1]}00`).substring(0, 2);

  const numberBeforeExtended = (numero < 0 ? 'menos ' : '') + numberInFull(numberBefore);
  const numberAfterExtended = numberInFull(numberAfter);

  const realOrReals = numberBefore === 1 ? ' real' : ' reais';
  const andQuestion = numberBefore > 0 ? ' e ' : '';
  const centOrCents = numberAfter === 1 ? ' centavo' : ' centavos';

  return (numberBefore > 0 ? numberBeforeExtended + (realOrReals) : '') + (numberAfter > 0 ? (andQuestion) + numberAfterExtended + (centOrCents) : '');
}

export { numberInFullMonetary, numberInFull };
export default { numberInFullMonetary, numberInFull };
