import { ICON_NOTEBOOK_MULTIPLE } from '@constants/icons';

export default {
  service: '',
  icon: ICON_NOTEBOOK_MULTIPLE,
  routes: {
    addressClientTypes: {
      list: {
        path: '/tables/addressclienttypes',
        name: 'tables-addressclienttypes-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Endereço por Cliente' },
          ],
        },
        component: './ListAddressClientTypes',
      },
      create: {
        path: '/tables/addressclienttypes/create',
        name: 'tables-addressclienttypes-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Endereço por Cliente', link: '/tables/addressclienttypes' },
            { name: 'Criar Tipo de Endereço por Cliente' },
          ],
        },
        component: './CreateAddressClientTypes',
      },
      edit: {
        path: '/tables/addressclienttypes/edit/:id',
        name: 'tables-addressclienttypes-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Endereço por Cliente', link: '/tables/addressclienttypes' },
            { name: 'Editar Tipo de Endereço por Cliente' },
          ],
        },
        component: './EditAddressClientTypes',
      },
    },
    addressTypes: {
      list: {
        path: '/tables/addresstypes',
        name: 'tables-addresstypes-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Endereço' },
          ],
        },
        component: './ListAddressTypes',
      },
      create: {
        path: '/tables/addresstypes/create',
        name: 'tables-addresstypes-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Endereços', link: '/tables/addresstypes' },
            { name: 'Criar Tipo de Endereço' },
          ],
        },
        component: './CreateAddressTypes',
      },
      edit: {
        path: '/tables/addresstypes/edit/:id',
        name: 'tables-addresstypes-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Endereços', link: '/tables/addresstypes' },
            { name: 'Editar Tipo de Endereço' },
          ],
        },
        component: './EditAddressTypes',
      },
    },
    categories: {
      list: {
        path: '/tables/categories',
        name: 'tables-categories-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Categorias' },
          ],
        },
        component: './ListCategories',
      },
      create: {
        path: '/tables/categories/create',
        name: 'tables-categories-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Categorias', link: '/tables/categories' },
            { name: 'Criar Categoria' },
          ],
        },
        component: './CreateCategories',
      },
      edit: {
        path: '/tables/categories/edit/:id',
        name: 'tables-categories-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Categorias', link: '/tables/categories' },
            { name: 'Editar Categoria' },
          ],
        },
        component: './EditCategories',
      },
    },
    cests: {
      list: {
        path: '/tables/cests',
        name: 'tables-cests-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'CESTs' },
          ],
        },
        component: './ListCests',
      },
      create: {
        path: '/tables/cests/create',
        name: 'tables-cests-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'CESTs', link: '/tables/cests' },
            { name: 'Criar CEST' },
          ],
        },
        component: './CreateCests',
      },
      edit: {
        path: '/tables/cests/edit/:id',
        name: 'tables-cests-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'CESTs', link: '/tables/cests' },
            { name: 'Editar CEST' },
          ],
        },
        component: './EditCests',
      },
    },
    coins: {
      list: {
        path: '/tables/coins',
        name: 'tables-coins-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Moedas' },
          ],
        },
        component: './ListCoins',
      },
      create: {
        path: '/tables/coins/create',
        name: 'tables-coins-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Moedas', link: '/tables/coins' },
            { name: 'Criar Moeda' },
          ],
        },
        component: './CreateCoins',
      },
      edit: {
        path: '/tables/coins/edit/:id',
        name: 'tables-coins-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Moedas', link: '/tables/coins' },
            { name: 'Editar Moeda' },
          ],
        },
        component: './EditCoins',
      },
    },
    evaluations: {
      list: {
        path: '/tables/evaluations',
        name: 'tables-evaluations-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Avaliações' },
          ],
        },
        component: './ListEvaluations',
      },
      create: {
        path: '/tables/evaluations/create',
        name: 'tables-evaluations-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Avaliações', link: '/tables/evaluations' },
            { name: 'Criar Tipo de Avaliação' },
          ],
        },
        component: './CreateEvaluations',
      },
      edit: {
        path: '/tables/evaluations/edit/:id',
        name: 'tables-evaluations-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Avaliações', link: '/tables/evaluations' },
            { name: 'Editar Tipo de Avaliação' },
          ],
        },
        component: './EditEvaluations',
      },
    },
    listPrices: {
      list: {
        path: '/tables/listprices',
        name: 'tables-listprices-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Listas de Preços' },
          ],
        },
        component: './ListListPrices',
      },
      create: {
        path: '/tables/listprices/create',
        name: 'tables-listprices-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Listas de Preços', link: '/tables/listprices' },
            { name: 'Criar Lista de Preço' },
          ],
        },
        component: './CreateListPrices',
      },
      edit: {
        path: '/tables/listprices/edit/:id',
        name: 'tables-listprices-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Listas de Preços', link: '/tables/listprices' },
            { name: 'Editar Lista de Preço' },
          ],
        },
        component: './EditListPrices',
      },
    },
    measuringUnits: {
      list: {
        path: '/tables/measuringunits',
        name: 'tables-measuringunits-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Unidades de Medidas' },
          ],
        },
        component: './ListMeasuringUnits',
      },
      create: {
        path: '/tables/measuringunits/create',
        name: 'tables-measuringunits-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Unidades de Medidas', link: '/tables/measuringunits' },
            { name: 'Criar Unidade de Medida' },
          ],
        },
        component: './CreateMeasuringUnits',
      },
      edit: {
        path: '/tables/measuringunits/edit/:id',
        name: 'tables-measuringunits-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Unidades de Medidas', link: '/tables/measuringunits' },
            { name: 'Editar Unidade de Medida' },
          ],
        },
        component: './EditMeasuringUnits',
      },
    },
    ncms: {
      list: {
        path: '/tables/ncms',
        name: 'tables-ncms-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'NCMs' },
          ],
        },
        component: './ListNcms',
      },
      create: {
        path: '/tables/ncms/create',
        name: 'tables-ncms-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'NCMs', link: '/tables/ncms' },
            { name: 'Criar NCM' },
          ],
        },
        component: './CreateNcms',
      },
      edit: {
        path: '/tables/ncms/edit/:id',
        name: 'tables-ncms-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'NCMs', link: '/tables/ncms' },
            { name: 'Editar NCM' },
          ],
        },
        component: './EditNcms',
      },
    },
    observations: {
      list: {
        path: '/tables/observations',
        name: 'tables-observations-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Observações' },
          ],
        },
        component: './ListObservations',
      },
      create: {
        path: '/tables/observations/create',
        name: 'tables-observations-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Observações', link: '/tables/observations' },
            { name: 'Criar Tipo de Observação' },
          ],
        },
        component: './CreateObservations',
      },
      edit: {
        path: '/tables/observations/edit/:id',
        name: 'tables-observations-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Observações', link: '/tables/observations' },
            { name: 'Editar Tipo de Observação' },
          ],
        },
        component: './EditObservations',
      },
    },
    occupations: {
      list: {
        path: '/tables/occupations',
        name: 'tables-occupations-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Ocupações' },
          ],
        },
        component: './ListOccupations',
      },
      create: {
        path: '/tables/occupations/create',
        name: 'tables-occupations-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Ocupações', link: '/tables/occupations' },
            { name: 'Criar Tipo de Ocupação' },
          ],
        },
        component: './CreateOccupations',
      },
      edit: {
        path: '/tables/occupations/edit/:id',
        name: 'tables-occupations-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Ocupações', link: '/tables/occupations' },
            { name: 'Editar Tipo de Ocupação' },
          ],
        },
        component: './EditOccupations',
      },
    },
    operations: {
      list: {
        path: '/tables/operations',
        name: 'tables-operations-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Operações' },
          ],
        },
        component: './ListOperations',
      },
      create: {
        path: '/tables/operations/create',
        name: 'tables-operations-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Operações', link: '/tables/operations' },
            { name: 'Criar Tipo de Operação' },
          ],
        },
        component: './CreateOperations',
      },
      edit: {
        path: '/tables/operations/edit/:id',
        name: 'tables-operations-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Operações', link: '/tables/operations' },
            { name: 'Editar Tipo de Operação' },
          ],
        },
        component: './EditOperations',
      },
    },
    paymentMethods: {
      list: {
        path: '/tables/paymentmethod',
        name: 'tables-paymentmethod-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Formas de Pagamentos' },
          ],
        },
        component: './ListPaymentMethod',
      },
      create: {
        path: '/tables/paymentmethod/create',
        name: 'tables-paymentmethod-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Formas de Pagamentos', link: '/tables/paymentmethod' },
            { name: 'Criar Forma de Pagamento' },
          ],
        },
        component: './CreatePaymentMethod',
      },
      edit: {
        path: '/tables/paymentmethod/edit/:id',
        name: 'tables-paymentmethod-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Formas de Pagamentos', link: '/tables/paymentmethod' },
            { name: 'Editar Forma de Pagamento' },
          ],
        },
        component: './EditPaymentMethod',
      },
    },
    productCategories: {
      list: {
        path: '/tables/productcategories',
        name: 'tables-productcategories-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Categorias de Produtos' },
          ],
        },
        component: './ListProductCategories',
      },
      create: {
        path: '/tables/productcategories/create',
        name: 'tables-productcategories-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Categorias de Produtos', link: '/tables/productcategories' },
            { name: 'Criar Categoria de Produto' },
          ],
        },
        component: './CreateProductCategories',
      },
      edit: {
        path: '/tables/productcategories/edit/:id',
        name: 'tables-productcategories-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Categorias de Produtos', link: '/tables/productcategories' },
            { name: 'Editar Categoria de Produto' },
          ],
        },
        component: './EditProductCategories',
      },
    },
    productTypes: {
      list: {
        path: '/tables/producttypes',
        name: 'tables-producttypes-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Produtos' },
          ],
        },
        component: './ListProductTypes',
      },
      create: {
        path: '/tables/producttypes/create',
        name: 'tables-producttypes-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Produtos', link: '/tables/producttypes' },
            { name: 'Criar Tipo de Produto' },
          ],
        },
        component: './CreateProductTypes',
      },
      edit: {
        path: '/tables/producttypes/edit/:id',
        name: 'tables-producttypes-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Produtos', link: '/tables/producttypes' },
            { name: 'Editar Tipo de Produto' },
          ],
        },
        component: './EditProductTypes',
      },
    },
    sectors: {
      list: {
        path: '/tables/sectors',
        name: 'tables-sectors-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Setores' },
          ],
        },
        component: './ListSectors',
      },
      create: {
        path: '/tables/sectors/create',
        name: 'tables-sectors-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Setores', link: '/tables/sectors' },
            { name: 'Criar Setor' },
          ],
        },
        component: './CreateSectors',
      },
      edit: {
        path: '/tables/sectors/edit/:id',
        name: 'tables-sectors-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Setores', link: '/tables/sectors' },
            { name: 'Editar Setor' },
          ],
        },
        component: './EditSectors',
      },
    },
    serviceCodes: {
      list: {
        path: '/tables/servicecodes',
        name: 'tables-servicecodes-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Códigos de Serviço' },
          ],
        },
        component: './ListServiceCodes',
      },
      create: {
        path: '/tables/servicecodes/create',
        name: 'tables-servicecodes-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Códigos de Serviço', link: '/tables/servicecodes' },
            { name: 'Criar Código de Serviço' },
          ],
        },
        component: './CreateServiceCodes',
      },
      edit: {
        path: '/tables/servicecodes/edit/:id',
        name: 'tables-servicecodes-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Códigos de Serviço', link: '/tables/servicecodes' },
            { name: 'Editar Código de Serviço' },
          ],
        },
        component: './EditServiceCodes',
      },
    },
    serviceCst: {
      list: {
        path: '/tables/servicecst',
        name: 'tables-servicecst-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Situação Tributária de Serviço' },
          ],
        },
        component: './ListServiceCst',
      },
      create: {
        path: '/tables/servicecst/create',
        name: 'tables-servicecst-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Situação Tributária de Serviço', link: '/tables/servicecst' },
            { name: 'Criar Situação Tributária de Serviço' },
          ],
        },
        component: './CreateServiceCst',
      },
      edit: {
        path: '/tables/servicecst/edit/:id',
        name: 'tables-servicecst-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Situação Tributária de Serviço', link: '/tables/servicecst' },
            { name: 'Editar Situação Tributária de Serviço' },
          ],
        },
        component: './EditServiceCst',
      },
    },
    serviceIssqn: {
      list: {
        path: '/tables/serviceissqn',
        name: 'tables-serviceissqn-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Situação Tributária ISSQN' },
          ],
        },
        component: './ListServiceIssqn',
      },
      create: {
        path: '/tables/serviceissqn/create',
        name: 'tables-serviceissqn-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Situação Tributária ISSQN', link: '/tables/serviceissqn' },
            { name: 'Criar Tributária ISSQN' },
          ],
        },
        component: './CreateServiceIssqn',
      },
      edit: {
        path: '/tables/serviceissqn/edit/:id',
        name: 'tables-serviceissqn-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Situação Tributária ISSQN', link: '/tables/serviceissqn' },
            { name: 'Editar Tributária ISSQN' },
          ],
        },
        component: './EditServiceIssqn',
      },
    },
    stockTypes: {
      list: {
        path: '/tables/stocktypes',
        name: 'tables-stocktypes-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Estoque' },
          ],
        },
        component: './ListStockTypes',
      },
      create: {
        path: '/tables/stocktypes/create',
        name: 'tables-stocktypes-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Estoque', link: '/tables/stocktypes' },
            { name: 'Criar Tipo de Estoque' },
          ],
        },
        component: './CreateStockTypes',
      },
      edit: {
        path: '/tables/stocktypes/edit/:id',
        name: 'tables-stocktypes-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos de Estoque', link: '/tables/stocktypes' },
            { name: 'Editar Tipo de Estoque' },
          ],
        },
        component: './EditStockTypes',
      },
    },
    types: {
      list: {
        path: '/tables/types',
        name: 'tables-types-list',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos' },
          ],
        },
        component: './ListTypes',
      },
      create: {
        path: '/tables/types/create',
        name: 'tables-types-create',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos', link: '/tables/types' },
            { name: 'Criar Tipo' },
          ],
        },
        component: './CreateTypes',
      },
      edit: {
        path: '/tables/types/edit/:id',
        name: 'tables-types-edit',
        meta: {
          requiresAuth: true,
          rule: 'all_types',
          breadcrumb: [
            { name: 'Cadastros' },
            { name: 'Tipos', link: '/tables/types' },
            { name: 'Editar Tipo' },
          ],
        },
        component: './EditTypes',
      },
    },

  },
  titles: {
    addressClientTypes: {
      item: 'Tipo de Endereço por Cliente',
      items: 'Tipos de Endereços por Cliente',
    },
    addressTypes: {
      item: 'Tipo de Endereço',
      items: 'Tipos de Endereços',
    },
    categories: {
      item: 'Categoria de Produto',
      items: 'Categorias de Produtos',
    },
    cests: {
      item: 'CEST',
      items: 'CESTs',
    },
    coins: {
      item: 'Moeda',
      items: 'Moedas',
    },
    evaluations: {
      item: 'Avaliação',
      items: 'Avaliações',
    },
    listPrices: {
      item: 'Lista de Preço',
      items: 'Listas de Preços',
    },
    measuringUnits: {
      item: 'Unidade de Medida',
      items: 'Unidades de Medidas',
    },
    ncms: {
      item: 'NCM',
      items: 'NCMs',
    },
    observations: {
      item: 'Tipo de Observação',
      items: 'Tipos de Observações',
    },
    occupations: {
      item: 'Ocupação',
      items: 'Ocupações',
    },
    operations: {
      item: 'Tipo de Operação',
      items: 'Tipos de Operações',
    },
    paymentMethods: {
      item: 'Forma de Pagamento',
      items: 'Formas de Pagamentos',
    },
    productCategories: {
      item: 'Categoria de Produto',
      items: 'Categorias de Produtos',
    },
    productTypes: {
      item: 'Tipo de Produto',
      items: 'Tipos de Produtos',
    },
    sectors: {
      item: 'Setor',
      items: 'Setores',
    },
    serviceCodes: {
      item: 'Código de Serviço',
      items: 'Códigos de Serviços',
    },
    serviceCst: {
      item: 'Código de Serviço CST',
      items: 'Códigos de Serviços CST',
    },
    serviceIssqn: {
      item: 'Código de Serviço ISSQN',
      items: 'Códigos de Serviços ISSQN',
    },
    stockTypes: {
      item: 'Tipo de Estoque',
      items: 'Tipos de Estoques',
    },
    types: {
      item: 'Tipo',
      items: 'Tipos',
    },
  },
};
