import Vue from 'vue';

export default {
  fieldEvents: {
    invoice_id: {
      change: async (formValue, fieldValue, fieldObjectValue) => {
        const { data } = await Vue.prototype.$api.Invoices.show(fieldObjectValue);
        if (data) {
          Vue.set(formValue, 'id', data.id);
          Vue.set(formValue, 'invoice_number', data.invoice_number);
          Vue.set(formValue, 'series', data.series);
          Vue.set(formValue, 'invoice_key', data.key);
          Vue.set(formValue, 'invoice_emission_date', data.emission_date);
          Vue.set(formValue, 'invoice_emission_date_formatted', data.emission_date_formatted);
          Vue.set(formValue, 'invoice_uf', data.buyer.uf);
          Vue.set(formValue, 'client', data.buyer.to_s);
          Vue.set(formValue, 'invoice_key', data.invoice_key);
          Vue.set(formValue, 'invoice_total_value', data.total_invoice);
          Vue.set(formValue, 'invoice_total_value_formatted', data.total_note_formatted);
        }
      },
    },
  },
};
