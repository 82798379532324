/**
* Returns the cities with provided brazilian UF.
* @export
* @param { String } uf
* @returns { Array } cities
*/

import config from '@src/core/config';

const endpoint = `${config.API_ROOT}`;

export default function searchCitiesByUf(uf) {
  const promise = new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', `${endpoint}/cnpj_map/cities/${uf}`);
    request.setRequestHeader('Content-type', 'application/json');
    request.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`);
    request.send();
    request.onload = () => {
      if (request.status === 200) {
        let responseFormatted = [];
        if (typeof JSON.parse(request.response) === 'object' && JSON.parse(request.response).length >= 1) {
          responseFormatted = JSON.parse(request.response);
          resolve(responseFormatted);
        } else {
          resolve(responseFormatted);
        }
      }
      reject(
        new Error({ errors: 'Ops, tente novamente!' }),
      );
    };
  });
  return promise;
}
