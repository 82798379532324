import { ICON_MEASURING_UNIT } from '@constants/icons';
// import OrderProductionItemBusiness from '@businesses/OrderProductionItem';

export default {
  service: 'CashFlows',
  // business: OrderProductionItemBusiness,
  icon: ICON_MEASURING_UNIT,
  routes: {
    list: '',
    edit: '',
    create: '',
  },
  titles: {
    item: 'Ítem do Fluxo de Caixa',
    items: 'Itens do Fluxo de Caixa',
    edit: 'Editar Fluxo de Caixa',
    create: 'Criar Fluxo de Caixa',
    new: 'Adicionar Item',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  noEdit: true,
  noDelete: true,
  noActions: true,
  filterUrl: '',
  fields: [
    {
      label: 'Data',
      name: 'date',
      formattedName: 'date_formatted',
      type: 'date',
      component: 'dynamic-date-field',
      offsetMd: 2,
      list: true,
      required: true,
      clearable: true,
      md: 2,
      order: 1,
    },
    {
      label: 'Entrada',
      name: 'value_entry',
      formattedName: 'value_entry_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Saida',
      name: 'value_exit',
      formattedName: 'value_exit_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Saldo',
      name: 'balance',
      formattedName: 'balance_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Total Parcial',
      name: 'partial',
      formattedName: 'partial_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      md: 3,
    },
    {
      label: 'Titulo',
      name: 'title',
      type: 'text',
      component: 'dynamic-text-field',
      disabled: true,
      list: true,
      md: 2,
    },
    {
      label: 'Razão Social',
      name: 'name',
      type: 'text',
      component: 'dynamic-text-field',
      disabled: true,
      list: true,
      md: 2,
    },
    {
      label: 'Origem',
      name: 'origin.to_s',
      type: 'text',
      component: 'dynamic-text-field',
      disabled: true,
      list: true,
      md: 2,
    },
    {
      label: 'Banco',
      name: 'bank.to_s',
      type: 'text',
      component: 'dynamic-text-field',
      disabled: true,
      list: true,
      md: 2,
    },
  ],
};
