import _ from 'lodash';

export default {
  isLoading: (state) => (loaderMessage) => {
    if (typeof loaderMessage === 'string') {
      return state.activeLoaders.indexOf(loaderMessage) > -1;
    } if (_.isArray(loaderMessage)) {
      let loading = false;
      _.each(loaderMessage, (loader) => {
        loading = loading === false ? state.activeLoaders.indexOf(loader) > -1 : loading;
      });
      return loading;
    }
    return false;
  },
  anyLoading: (state) => state.activeLoaders.length > 0,
};
