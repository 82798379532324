/* eslint-disable max-classes-per-file */
export class BackendNeedLogoutError extends Error {
  constructor(message, config) {
    super(message, config);
    this.config = config;
    this.name = 'BackendNeedLogoutError';
    this.message = message;
  }
}

export class BackendGenericError extends Error {
  constructor(message, config) {
    super(message, config);
    this.config = config;
    this.name = 'BackendGenericError';
    this.message = message;
  }
}

export class BackendNetworkError extends Error {
  constructor(message, config) {
    super(message, config);
    this.config = config;
    this.name = 'BackendNetworkError';
    this.message = message || 'O servidor está offline. Contate o suporte.';
  }
}
