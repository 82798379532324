<template>
  <Btn icon
       :aria-label="ariaLabel"
       v-bind="$attrs"
       :disabled="isDisabled"
       @click="handleOpenOrigin()">
    <v-icon>{{ icons.origin }}</v-icon>
  </Btn>
</template>

<script>
import { ICON_ACTION_OPEN_ORIGIN } from '@constants/icons';
import Btn from './Btn.vue';

import AccountPaymentSchema from '@schemas/AccountPayment';
import AccountReceivableSchema from '@schemas/AccountReceivable';
import BankSchema from '@schemas/Bank';
import InvoiceSchema from '@schemas/Invoice';
import OrderPurchaseSchema from '@schemas/OrderPurchase';
import OrderSaleSchema from '@schemas/OrderSale';
import OrderBudgetSchema from '@schemas/OrderBudget';
import OrderProductionSchema from '@schemas/OrderProduction';
import OrderServiceSchema from '@schemas/OrderService';
import DevolutionConsignmentSchema from '@schemas/DevolutionConsignment';
import PartialPurchaseSchema from '@schemas/PartialPurchase';
import PartialSaleSchema from '@schemas/PartialSale';
import RemittanceConsignmentSchema from '@schemas/RemittanceConsignment';
import DevolutionIndustrializationSchema from '@schemas/DevolutionIndustrialization';
import RemittanceIndustrializationSchema from '@schemas/RemittanceIndustrialization';
import StockHistorySchema from '@schemas/StockHistory';

export default {
  name: 'BtnOpenOrigin',
  components: { Btn },
  inheritAttrs: true,
  props: {
    origin: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        origin: ICON_ACTION_OPEN_ORIGIN,
      },
      originUrlName: '',
      originId: 0,
      originType: 'origin',
      ariaLabel: 'Abrir Origem',
    };
  },
  computed: {
    isDisabled() {
      if (this.disabled) return this.disabled;
      return this.originUrlName === this.$route.name;
    },
  },
  watch: {
    origin() {
      this.setDomainAndId();
    },
  },
  created() {
    this.setDomainAndId();
  },
  methods: {
    handleOpenOrigin() {
      if (!this.hasDomain()) {
        this.$notifyError('Não foi possível abrir a origem deste item');
        return;
      }
      if (this.originUrlName !== '') {
        const routeData = this.$router.resolve({ name: this.originUrlName, params: { id: this.originId } });
        window.open(routeData.href, '_blank');
      }
    },
    hasDomain() {
      const { domain } = this.origin;
      return domain;
    },
    setDomainAndId() {
      const { domain, id } = this.origin;
      this.originId = id;
      let name = '';
      switch (domain) {
        case 'account_payment':
          name = AccountPaymentSchema.routes.edit.name;
          this.ariaLabel = AccountPaymentSchema.titles.item;
          break;
        case 'account_receivable':
          name = AccountReceivableSchema.routes.edit.name;
          this.ariaLabel = AccountReceivableSchema.titles.item;
          break;
        case 'bank':
          name = BankSchema.routes.launches.name;
          this.ariaLabel = BankSchema.titles.item;
          break;
        case 'remittance_consignment':
          name = RemittanceConsignmentSchema.routes.edit.name;
          this.ariaLabel = RemittanceConsignmentSchema.titles.item;
          break;
        case 'consignment_devolution':
          name = DevolutionConsignmentSchema.routes.edit.name;
          this.ariaLabel = DevolutionConsignmentSchema.titles.item;
          break;
        case 'remittance_industrialization':
          name = RemittanceIndustrializationSchema.routes.edit.name;
          this.ariaLabel = RemittanceIndustrializationSchema.titles.item;
          break;
        case 'industrialization_devolution':
          name = DevolutionIndustrializationSchema.routes.edit.name;
          this.ariaLabel = DevolutionIndustrializationSchema.titles.item;
          break;
        case 'invoices':
          name = InvoiceSchema.routes.list.name;
          this.ariaLabel = InvoiceSchema.titles.item;
          break;
        case 'order_purchase':
          name = OrderPurchaseSchema.routes.edit.name;
          this.ariaLabel = OrderPurchaseSchema.titles.item;
          break;
        case 'order_sale':
          name = OrderSaleSchema.routes.edit.name;
          this.ariaLabel = OrderSaleSchema.titles.item;
          break;
        case 'order_budget':
          name = OrderBudgetSchema.routes.edit.name;
          this.ariaLabel = OrderBudgetSchema.titles.item;
          break;
        case 'production_order':
          name = OrderProductionSchema.routes.edit.name;
          this.ariaLabel = OrderProductionSchema.titles.item;
          break;
        case 'service_order':
          name = OrderServiceSchema.routes.edit.name;
          this.ariaLabel = OrderServiceSchema.titles.item;
          break;
        case 'stock':
          name = StockHistorySchema.routes.launches;
          this.ariaLabel = StockHistorySchema.titles.item;
          break;
        case 'partial_sale':
          name = PartialSaleSchema.routes.edit.name;
          this.ariaLabel = PartialSaleSchema.titles.item;
          break;
        case 'partial_purchase':
          name = PartialPurchaseSchema.routes.edit.name;
          this.ariaLabel = PartialPurchaseSchema.titles.item;
          break;
        default:
          console.warn('Erro: Origem não cadastrada');
          name = this.$route.name;
          break;
      }
      this.originUrlName = name;
      this.ariaLabel = !this.ariaLabel ? 'Abrir origem' : this.ariaLabel;
    },
  },
};
</script>
