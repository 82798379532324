import { ICON_CIRCLE_SLICE_7 } from '@constants/icons';

export default {
  service: 'CostCenters',
  icon: ICON_CIRCLE_SLICE_7,
  routes: {
    list: {
      path: '/tables/costcenter/list',
      name: 'tables-costcenters-list',
    },
  },
  titles: {
    item: 'Centro de Custo',
    items: 'Centros de Custo',
  },
};
