const ListOpenTitlesPayments = () => import(/* webpackChunkName: "list-open-title-payments" */ './payments/ListOpenTitlesPayments');
const ListOpenTitlesReceivables = () => import(/* webpackChunkName: "list-open-title-receivables" */ './receivables/ListOpenTitlesReceivables');

export default [
  {
    path: '/financial/opentitles/payments',
    name: 'financial-opentitles-payments',
    meta: {
      requiresAuth: true,
      rule: 'open_titles',
      breadCrumb: [
        { name: 'Financeiro' },
        { name: 'Títulos em Aberto' },
        { name: 'Contas a Pagar' },
      ],
    },
    component: ListOpenTitlesPayments,
  },
  {
    path: '/financial/opentitles/receivables',
    name: 'financial-opentitles-receivables',
    meta: {
      requiresAuth: true,
      rule: 'open_titles',
      breadCrumb: [
        { name: 'Financeiro' },
        { name: 'Títulos em Aberto' },
        { name: 'Contas a Receber' },
      ],
    },
    component: ListOpenTitlesReceivables,
  },
];
