import Vue from 'vue';
import * as AppUtils from '@plugins/app-utils';

export async function createHintForRegisterField(registerId, registerData = null) {
  let fetchedData = null;
  if (!registerData) { fetchedData = await Vue.prototype.$api.Registers.show(registerId); }
  const { cnpj_cpf, addresses } = registerData || fetchedData;

  if (cnpj_cpf && addresses[0]) {
    return `${AppUtils.formatCnpjCpf(cnpj_cpf)}, ${addresses[0]?.to_s ?? ''}`;
  }
  return '';
}
