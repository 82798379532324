<template>
  <div v-if="!readOnly && !field.readOnly">
    <div class="input-group">
      <label>{{ field.label }} {{ field.required ? '*' : '' }}</label>
    </div>
    <v-layout row wrap class="pt-2">
      <v-flex v-for="(item, index) in fieldItems(field)" :key="index" :class="field.row ? 'xs6 sm4 md3 lg3' : 'xs12'">
        <v-checkbox :input-value="parsedFieldValue"
                    :disabled="checkDisabled()"
                    :class="field.class"
                    :label="item[field.itemText] || item"
                    :value="item[field.itemValue] || item"
                    v-bind="$attrs"
                    v-on="$listeners"
                    @change="onCheckboxChange" />
      </v-flex>
    </v-layout>
    <p v-if="field.hint" class="grey--text mb-0">
      {{ field.hint }}
    </p>
  </div>
  <div v-else class="dm-information">
    <label>{{ field.label }}</label>
    <p>{{ readOnlyFieldValue }}</p>
  </div>
</template>

<script>
import DynamicFieldMixin from './DynamicFieldMixin';

export default {
  name: 'DynamicCheckboxGroup',
  mixins: [DynamicFieldMixin],
  data() {
    return {
      valid: true,
      fieldValue: [],
      emptyValue: [],
      parsedFieldValue: [],
    };
  },
  computed: {
    readOnlyFieldValue() {
      return this.fieldValue.join(', ');
    },
  },
  watch: {
    fieldValue: {
      handler: 'onFieldValueChange',
      immediate: true,
    },
  },
  methods: {
    onFieldValueChange(value) {
      if (!value || String(value) === '') {
        this.parsedFieldValue = [];
      } else {
        this.parsedFieldValue = String(value).trim().split('|');
      }
    },
    onCheckboxChange(value) {
      this.fieldValue = value.join('|');
      this.fieldChanged(this.fieldValue);
    },
    fieldItems(field) {
      if (field.items) {
        return field.items;
      } if (field.dataSource && field.dataSource.modelName) {
        return this.$store.state[field.dataSource.state || 'dynamicModule'][field.dataSource.modelName];
      }

      return [];
    },
  },
};
</script>

<style lang="scss">
</style>
