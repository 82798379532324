import { ICON_CASH_CLOCK } from '@constants/icons';

export default {
  service: '',
  icon: ICON_CASH_CLOCK,
  routes: {
    list: {
      path: '/financial/opentitles/payments',
      name: 'financial-opentitles-payments',
    },
  },
  titles: {
    item: 'Conta a Pagar',
    items: 'Contas a Pagar',
  },
};
