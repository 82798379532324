import Vue from 'vue';
import _ from 'lodash';

import { applyGlobalDiscount } from './Common/ApplyGlobalDiscount';
import { applyListPriceInItems } from './Common/ApplyListPrice';
import { calculateOrderBudgetRealTime } from './Common/CalculateRealTime';
import { calculateTotals } from './Common/CalculateTotals';
import { createHintForRegisterField } from './Common/createHintForRegisterField';
import { setTaxesValues } from './Common/SetTaxesValues ';

import OrderBudgetSchema from '@schemas/OrderBudget';
import { DefaultScreenEvents } from './OrderBudget/DefaultScreenEvents';
import { DefaultFieldEvents } from './OrderBudget/DefaultFieldEvents';
import { setCbenef } from './Common/ItemCbenef';

const applyBusinessItems = async (formValue) => {
  if (formValue.buyer_id && formValue.items.length > 0) {
    formValue.items = await applyListPriceInItems(formValue, OrderBudgetSchema.service);
    const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
    const data = await calculateOrderBudgetRealTime(formValue.items, buyer, formValue.is_costumer);
    if (formValue.use_freight === false) {
      calculateTotals(formValue, true);
    } else {
      calculateTotals(formValue);
    }
    setTaxesValues(formValue, data, true);
    if (data.totalizers?.ipi) {
      formValue.total_ordered = formValue.total_products + data.totalizers.ipi;
    }
    await setCbenef(formValue, data);
    Vue.set(formValue, 'items', formValue.items);
  }
};

export default {
  screenEvents: {
    ...DefaultScreenEvents,
    async beforeUpdate(formValue) {
      if (!formValue.buyer_id) return;
      const buyerHint = await createHintForRegisterField(formValue.buyer_id);

      return { fields: [{ label: 'Cliente', hint: buyerHint }] };
    },
  },
  fieldEvents: {
    buyer_id: {
      async change(formValue) {
        const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
        formValue.buyer = buyer;
        formValue.buyer_id = buyer.id;
        Vue.set(formValue, 'buyer', buyer);
        Vue.set(formValue, 'buyer_id', buyer.id);
        if (buyer.addresses.length === 0) {
          Vue.prototype.$notifyError('Cliente não possui endereço', {
            action: {
              text: 'Clique aqui para cadastrar',
              push: {
                name: 'entries-registers-edit',
                params: {
                  id: buyer.id,
                },
              },
              class: 'custom-message',
            },
            duration: 6000,
          });
        } else {
          const apportionment = true;
          const hint = await createHintForRegisterField(buyer.id, buyer);
          const salesmanId = _.get(buyer, 'preference.salesman_id');
          if (salesmanId) {
            const salesman = await Vue.prototype.$api.Registers.show(salesmanId);
            Vue.set(formValue, 'salesman_id', salesmanId);
            Vue.set(formValue, 'salesman', salesman);
          }
          const shipping_company_id = _.get(buyer, 'preference.shipping_company_id');
          if (shipping_company_id) formValue.shipping_company_id = shipping_company_id;

          if (formValue.buyer_id && formValue.nature_operation_id) {
            formValue.buyer = {
              ...formValue.buyer,
              taxpayer_type: buyer.taxpayer_type,
            };
            const data = await calculateOrderBudgetRealTime(formValue.items, buyer, formValue.is_costumer);
            setTaxesValues(formValue, data, apportionment);
          }
          return { fieldAttributes: { hint } };
        }
      },
    },
    is_costumer: {
      change: async (formValue) => {
        if (formValue.buyer_id) {
          await applyBusinessItems(formValue);
        }
      },
    },
    nature_operation_id: {
      change: async (formValue) => {
        if (formValue.buyer_id) {
          formValue.items = formValue.items || [];
          formValue.items.forEach((item) => {
            item.nature_operation_id = formValue.nature_operation_id;
          });
          await applyBusinessItems(formValue);
        }
      },
    },
    price_list_id: {
      change: async (formValue) => {
        await applyBusinessItems(formValue);
      },
    },
    items: {
      beforeCreate: async (formValue, newItem) => {
        if (!formValue.buyer_id) return 'Não é possível adicionar ítens sem selecionar um cliente';
        if (!formValue.stock_id) return 'Não é possível adicionar ítens sem selecionar o estoque';
        if (!formValue.nature_operation_id) return 'Não é possível adicionar ítens sem selecionar a natureza de operação';
        if (formValue?.buyer?.addresses?.length === 0) return 'Para adicionar ítens no orçamento precisa ter endereço UF no cliente selecionado';

        newItem.stock_id = formValue.stock_id;
        newItem.nature_operation_id = formValue.nature_operation_id;
        newItem.order_number = formValue.order;
        newItem.buyer_id = formValue.buyer_id;
        newItem.price_list_id = formValue.price_list_id;
      },
      change: async (formValue) => {
        await applyBusinessItems(formValue);
      },
    },
    use_freight: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          await applyBusinessItems(formValue);
        }
      },
    },
    expense_value: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          await applyBusinessItems(formValue);
        }
      },
    },
    freight_value: {
      change: async (formValue) => {
        if (formValue.items.length > 0) {
          await applyBusinessItems(formValue);
        }
      },
    },
    observations: {
      beforeCreate: async (formValue) => {
        if (!formValue.buyer_id) return 'Não é possível adicionar observações sem selecionar um cliente';
      },
    },
    ...DefaultFieldEvents,
  },
  applyGlobalDiscount,
};
