import { OPTIONS_UF, OPTIONS_FREIGHT } from '@constants/options';

export default [
  // Line 1
  {
    label: 'Nome / Razão Social',
    name: 'transporter_id',
    formattedName: 'transporter.to_s',
    type: 'number',
    list: true,
    component: 'dynamic-autocomplete',
    dataSource: {
      service: 'Registers',
      method: 'getByFilterGraphql',
      props: [
        {
          use_inactive_eq: false,
          type_register_array: 'shipping_company',
          query: ['id', 'name'],
        },
        'name asc',
      ],
    },
    sort: {
      fieldName: 'name',
    },
    itemText: 'name',
    itemValue: 'id',
    colClass: 'nf-field top left',
    prependIcon: '',
    withDetails: true,
    typeDetails: 'client',
    md: 4,
  },
  {
    label: 'Frete por Conta',
    name: 'transporter.freight_type',
    formattedName: 'transporter.freight_type_formatted',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_FREIGHT,
    cols: 2,
    colClass: 'nf-field top left',
    prependIcon: '',
    required: true,
    default: 'issuer',
  },
  {
    label: 'Código RNTC',
    name: 'transporter.vehicle_rntc',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    colClass: 'nf-field top left',
    prependIcon: '',
  },
  {
    label: 'Placa Veículo',
    name: 'transporter.vehicle_plate',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 1,
    colClass: 'nf-field top left',
    prependIcon: '',
  },
  {
    label: 'UF',
    name: 'transporter.vehicle_uf',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_UF,
    cols: 1,
    clearable: true,
    colClass: 'nf-field top left',
    prependIcon: '',
  },
  {
    label: 'Inscrição Estadual',
    name: 'transporter.ie',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    colClass: 'nf-field top left right',
    prependIcon: '',
  },
  // Line 2
  {
    label: 'Logradouro',
    name: 'transporter.street',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 4,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Número',
    name: 'transporter.number',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'CEP',
    name: 'transporter.zip_code',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'CNPJ / CPF',
    name: 'transporter.cnpj',
    type: 'date',
    component: 'dynamic-text-field',
    cols: 4,
    prependIcon: '',
    colClass: 'nf-field top left right',
  },
  // Line 3
  {
    label: 'Complemento',
    name: 'transporter.complement',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 4,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Bairro',
    name: 'transporter.neighborhood',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'País',
    name: 'transporter.country',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Peso Líquido',
    name: 'transporter.volume_net_weight',
    type: 'number',
    component: 'dynamic-number-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
    preference: ['General', 'number_precision_quantity'],
  },
  {
    label: 'Peso Bruto',
    name: 'transporter.volume_gross_weight',
    type: 'number',
    component: 'dynamic-number-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left right',
    preference: ['General', 'number_precision_quantity'],
  },
  // Line 4
  {
    label: 'Município',
    name: 'transporter.city',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 4,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'UF',
    name: 'transporter.uf',
    type: 'text',
    component: 'dynamic-select',
    items: OPTIONS_UF,
    cols: 1,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Marca',
    name: 'transporter.volume_brand',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Espécie',
    name: 'transporter.volume_species',
    type: 'text',
    component: 'dynamic-text-field',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left',
  },
  {
    label: 'Quantidade',
    name: 'transporter.volume_amount',
    type: 'number',
    component: 'dynamic-text-field',
    preference: ['General', 'number_precision_quantity'],
    min: 0.001,
    max: 99999999999999,
    step: '0.01',
    mask: '###############',
    cols: 3,
    prependIcon: '',
    colClass: 'nf-field top left right',
  },
  // Line 5
  {
    label: 'Incluir Frete',
    name: 'transporter.use_freight',
    type: 'boolean',
    component: 'dynamic-checkbox',
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left bottom',
  },
  {
    label: 'Frete',
    name: 'total_freight',
    type: 'number',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left bottom',
  },
  {
    label: 'Outras Desp. Acessórias',
    name: 'expense_value',
    type: 'number',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left bottom',
  },
  {
    label: 'Valor do Seguro',
    name: 'total_safe',
    type: 'text',
    component: 'dynamic-number-field',
    preference: ['General', 'number_precision_money'],
    cols: 2,
    prependIcon: '',
    colClass: 'nf-field top left bottom',
  },
  {
    label: 'Nº de Conhecimento',
    name: 'transporter.knowledge',
    type: 'string',
    component: 'dynamic-text-field',
    cols: 4,
    prependIcon: '',
    colClass: 'nf-field left top right bottom',
  },
];
