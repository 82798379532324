<template>
  <v-dialog :value="isOpened" :max-width="maxWidth" :persistent="persistent" scrollable>
    <GenericDialogCard :icon="icon"
                       :title="title"
                       :show-close="showClose"
                       :max-width="maxWidth"
                       @get-close="closeDialog">
      <v-row :class="rowClass">
        <v-col cols="12">
          <dynamic-form ref="form"
                        v-model="formValue"
                        bg-color="white"
                        class="mt-4"
                        :fields="fields"
                        @save="onSaveClick()" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <dynamic-buttons
          class="mt-n5 ml-4"
          :buttons="dialogButtons"
          :scope="formValue"
          align="left"
          @click="(payload) => $emit('buttonClick', { button: payload.button, item: payload.scope })" />
      </template>
    </GenericDialogCard>
  </v-dialog>
</template>

<script>
import { ICON_ALERT, ICON_DESCRIPTION } from '@constants/icons';
import _ from 'lodash';
import GenericDialogCard from '@sharedComponents/dynamic/GenericDialogCard';
import DynamicForm from '@sharedComponents/DynamicForm';
import DynamicButtons from '@sharedComponents/DynamicButtons';

export default {
  name: 'DynamicDialog',
  components: {
    'dynamic-form': DynamicForm,
    'dynamic-buttons': DynamicButtons,
    GenericDialogCard,
  },
  props: {
    value: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default: () => [],
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: [String, Object],
      default: ICON_ALERT,
    },
    maxWidth: {
      type: String,
      default: '750px',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    saveLabel: {
      type: String,
      default: '',
    },
    saveIcon: {
      type: String,
      default: 'mdi-content-save',
    },
    btnsWidth: {
      type: String,
      default: 'fit-content',
    },
    rowClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpened: false,
      formValue: {},
      icons: {
        title: this.icon || ICON_DESCRIPTION,
      },
    };
  },
  computed: {
    dialogButtons() {
      if (this.buttons.length === 0) {
        return [
          {
            buttonLabel: this.saveLabel || 'Salvar',
            depressed: true,
            class: 'mx-2 text--bold grey--text text--darken-3 font-montserrat',
            color: 'success',
            width: this.btnsWidth,
            click: this.onSaveClick,
            visible: !this.readOnly,
          },
          {
            buttonLabel: 'Cancelar',
            click: this.close,
            class: 'mx-2 font-montserrat',
            width: this.btnsWidth,
            color: 'error',
            visible: !this.readOnly,
          },
          {
            buttonLabel: 'Fechar',
            class: 'mx-2 font-montserrat',
            width: this.btnsWidth,
            text: true,
            click: this.close,
            visible: this.readOnly,
          },
        ];
      }

      return this.buttons;
    },
  },
  watch: {
    value(newVal) {
      this.formValue = _.cloneDeep(newVal);
    },
  },
  created() {
    this.formValue = this.value ? _.cloneDeep(this.value) : {};
  },
  methods: {
    closeDialog(payload) {
      this.isOpened = payload;
    },
    validate() {
      if (this.$refs.form) {
        return this.$refs.form.validate();
      }
      return true;
    },
    clearForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    setFocus(index) {
      const field = this.$refs.form.$el.querySelector(`[tabindex="${index}"]`);

      if (field) {
        field.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });

        const fieldInput = field.getElementsByTagName('input');
        if (fieldInput.length) {
          fieldInput[fieldInput.length - 1].select();
        }
      }
    },
    open() {
      this.clearForm();
      this.isOpened = true;
      this.formValue = this.value ? _.cloneDeep(this.value) : {};
      document.body.addEventListener('keyup', this.detectKeyPressed);

      this.$nextTick(() => {
        this.setFocus(0);
      });

      this.$emit('open');
    },
    close() {
      this.isOpened = false;
      document.body.removeEventListener('keyup', this.detectKeyPressed);
      this.$emit('close');
    },
    onSaveClick() {
      if (!this.validate()) {
        return;
      }

      this.$emit('save', this.formValue);
      this.close();
    },
    detectKeyPressed(event) {
      if (event.keyCode === 27) {
        this.close();
      } else if (event.keyCode === 13) {
        this.onSaveClick();
      }
    },
  },
};
</script>
