import Vue from 'vue';
import * as AppUtils from '@plugins/app-utils';
import { applyGlobalDiscountInvoice } from './Common/ApplyGlobalDiscount';
import { calculateTotalsInvoice } from './Common/CalculateTotals';
import { calculateInvoicesTaxesRealTime } from './Common/CalculateRealTime';
// import InvoiceItemBusiness from './InvoiceItem';

const setTaxesValues = (formValue, { items, total_taxes, totalizers }) => {
  const unsetTax = 0.0;

  const itemsTaxes = formValue.items
    .filter((item) => item._destroy !== true)
    .map((item, index) => {
      const icmsTaxes = {
        base: items[index].icms?.base ?? unsetTax,
        aliquot: items[index].icms?.aliquot ?? unsetTax,
        value: items[index].icms?.value ?? unsetTax,
        base_formatted: AppUtils.formatNumber(items[index].icms?.base ?? unsetTax),
        aliquot_formatted: AppUtils.formatNumber(items[index].icms?.aliquot ?? unsetTax),
        value_formatted: AppUtils.formatNumber(items[index].icms?.value ?? unsetTax),
      };
      const icmsStTaxes = {
        base: items[index].icms_st?.base ?? unsetTax,
        aliquot: items[index].icms_st?.aliquot ?? unsetTax,
        value: items[index].icms_st?.value ?? unsetTax,
        base_formatted: AppUtils.formatNumber(items[index].icms_st?.base ?? unsetTax),
        aliquot_formatted: AppUtils.formatNumber(items[index].icms_st?.aliquot ?? unsetTax),
        value_formatted: AppUtils.formatNumber(items[index].icms_st?.value ?? unsetTax),
      };
      const icmsFinalCostumerTaxes = {
        base: items[index].icms_final_costumer?.base ?? unsetTax,
        aliquot: items[index].icms_final_costumer?.aliquot ?? unsetTax,
        value: items[index].icms_final_costumer?.value ?? unsetTax,
        base_formatted: AppUtils.formatNumber(items[index].icms_final_costumer?.base ?? unsetTax),
        aliquot_formatted: AppUtils.formatNumber(items[index].icms_final_costumer?.aliquot ?? unsetTax),
        value_formatted: AppUtils.formatNumber(items[index].icms_final_costumer?.value ?? unsetTax),
      };
      const ipiTaxes = {
        base: items[index].ipi?.base ?? unsetTax,
        aliquot: items[index].ipi?.aliquot ?? unsetTax,
        value: items[index].ipi?.value ?? unsetTax,
        base_formatted: AppUtils.formatNumber(items[index].ipi?.base ?? unsetTax),
        aliquot_formatted: AppUtils.formatNumber(items[index].ipi?.aliquot ?? unsetTax),
        value_formatted: AppUtils.formatNumber(items[index].ipi?.value ?? unsetTax),
      };
      const pisTaxes = {
        base: items[index].pis?.base ?? unsetTax,
        aliquot: items[index].pis?.aliquot ?? unsetTax,
        value: items[index].pis?.value ?? unsetTax,
        base_formatted: AppUtils.formatNumber(items[index].pis?.base ?? unsetTax),
        aliquot_formatted: AppUtils.formatNumber(items[index].pis?.aliquot ?? unsetTax),
        value_formatted: AppUtils.formatNumber(items[index].pis?.value ?? unsetTax),
      };
      const cofinsTaxes = {
        base: items[index].cofins?.base ?? unsetTax,
        aliquot: items[index].cofins?.aliquot ?? unsetTax,
        value: items[index].cofins?.value ?? unsetTax,
        base_formatted: AppUtils.formatNumber(items[index].cofins?.base ?? unsetTax),
        aliquot_formatted: AppUtils.formatNumber(items[index].cofins?.aliquot ?? unsetTax),
        value_formatted: AppUtils.formatNumber(items[index].cofins?.value ?? unsetTax),
      };
      const simpleNational = {
        base: items[index].simple_national?.base ?? unsetTax,
        aliquot: items[index].simple_national?.aliquot ?? unsetTax,
        value: items[index].simple_national?.value ?? unsetTax,
        base_formatted: AppUtils.formatNumber(items[index].simple_national?.base ?? unsetTax),
        aliquot_formatted: AppUtils.formatNumber(items[index].simple_national?.aliquot ?? unsetTax),
        value_formatted: AppUtils.formatNumber(items[index].simple_national?.value ?? unsetTax),
      };
      return {
        ...item,
        icms: icmsTaxes,
        icms_st: icmsStTaxes,
        icms_final_costumer: icmsFinalCostumerTaxes,
        ipi: ipiTaxes,
        pis: pisTaxes,
        cofins: cofinsTaxes,
        simple_national: simpleNational,
        tax_approximate_percentage: items[index].tax_approximate_percentage ?? unsetTax,
        tax_approximate: items[index].tax_approximate ?? unsetTax,
        tax_approximate_percentage_formatted: AppUtils.formatNumber(items[index].tax_approximate_percentage ?? unsetTax),
        tax_approximate_formatted: AppUtils.formatNumber(items[index].tax_approximate ?? unsetTax),
      };
    });
  formValue.items = itemsTaxes;

  formValue.items.forEach((item, index) => {
    item.cfop = items[index].cfop;
  });

  Vue.set(formValue, 'total_taxes', total_taxes);
  Vue.set(formValue, 'total_taxes_formatted', AppUtils.formatCurrency(total_taxes));
  Vue.set(formValue, 'items', formValue.items);
  Vue.set(formValue, 'totalizers', totalizers);
};

const getOperationService = (formValue) => {
  if (formValue.document_type === 'entry') return 'OrderPurchasesTaxes';
  if (formValue.document_type === 'exit') return 'OrderSalesTaxes';
  return null;
};

let destiny_location_temp = '';
const setDestinyLocation = async (formValue) => {
  const { nature_operation_id } = formValue;
  const uf = formValue?.buyer?.uf || formValue?.vendor?.uf;
  if (!nature_operation_id || !uf) return;

  await Vue.prototype.$api.NatureOperationCommands.getDetailsByUF(nature_operation_id, uf).then((response) => {
    const { destiny_location } = response;
    formValue.destiny_location = destiny_location;

    Vue.set(formValue, 'destiny_location', formValue.destiny_location);
    destiny_location_temp = destiny_location;
  });
};

const setExpenseValueOnItems = async (formValue) => {
  if (formValue?.expense_value > 0 && formValue?.items.length > 0) {
    const totalExpenseValue = parseFloat(formValue?.expense_value ?? 0);
    const totalProducts = formValue.items.map((item) => parseFloat(item.total_value)).reduce((total, items) => total + items);

    formValue.items.forEach((item) => {
      const totalItem = parseFloat(item?.tributary_quantity ?? 0) * parseFloat(item?.unit_value ?? 0);
      item.other_expenses = totalExpenseValue * (totalItem / totalProducts);
    });
  }

  if (formValue?.expense_value <= 0 && formValue?.items.length > 0) {
    formValue.items.forEach((item) => {
      item.other_expenses = 0;
    });
  }
};

const applyBusinessItems = async (formValue) => {
  calculateTotalsInvoice(formValue);
  if (formValue.buyer_id && formValue.nature_operation_id) {
    const buyer = formValue.buyer ?? (await Vue.prototype.$api.Registers.show(formValue.buyer_id));
    const typeOrigin = getOperationService(formValue);

    setExpenseValueOnItems(formValue);
    const data = await calculateInvoicesTaxesRealTime(formValue.items, buyer, formValue.is_costumer, typeOrigin);
    setTaxesValues(formValue, data);
    await setDestinyLocation(formValue);
  }
};

const validateDates = (formValue) => {
  const {
    emission_date,
    emission_date_hour,
    exit_date,
    exit_date_hour,
  } = formValue;

  if (!emission_date || !emission_date_hour || !exit_date || !exit_date_hour) {
    return 'Campos de data de emissao e saída são obrigatórios!';
  }

  const emission_date_timestamp = new Date(emission_date).valueOf();
  const exit_date_timestamp = new Date(exit_date).valueOf();
  if (emission_date_timestamp > exit_date_timestamp) {
    return 'A data/hora de emissão não pode ser posterior à de saída!';
  }
};

export default {
  screenEvents: {
    // Return false or a string to cancel save action, returning a String the screen will notify as an error.
    async beforeSave(formValue) {
      const action = formValue.id ? 'update' : 'create';

      if (validateDates(formValue)) return validateDates(formValue);

      formValue.destiny_location = destiny_location_temp;
      await setDestinyLocation(formValue);

      formValue.items = formValue.items || [];
      const visibleItems = formValue.items.filter((item) => !item._destroy);

      if (visibleItems.length === 0) {
        return 'Não é possível salvar a nota sem ítens!';
      }

      if (action === 'update' && formValue.status === 'canceled') {
        return 'Não é possível alterar uma ordem cancelada.';
      }

      if (action === 'update' && formValue.status === 'delivered') {
        return 'Não é possível alterar uma ordem entregue.';
      }

      //   if (!formValue.status || formValue.status === 'initial') {
      //     formValue.status = 'pending';
      //   }

      //   if (formValue.delivery_date) {
      //     formValue.status = 'delivered';
      //   } else {
      //     formValue.status = 'pending';
      //   }
    },
    // // is possible to change the initial formValue before start creating
    // beforeCreate(formValue) {
    //   formValue.emission_date = new Date();
    // },
    // beforeDelete(formValue) {
    //   if (formValue.status !== 'canceled') {
    //     return 'Primeiro efetue o cancelamento da ordem.';
    //   }
    // },
  },
  fieldEvents: {
    buyer_id: {
      change: async (formValue) => {
        const buyer = await Vue.prototype.$api.Registers.show(formValue.buyer_id);
        const addressMain = buyer.addresses.find((address) => address.main === true);
        formValue.buyer = {
          register_id: formValue.buyer_id,
          name: buyer.name,
          cnpj_cpf: buyer.cnpj_cpf,
          ie: buyer.ie_rg,
          taxpayer_type: buyer.taxpayer_type,
          street: addressMain?.street ?? '',
          number: addressMain?.number ?? '',
          zip_code: addressMain?.zip_code ?? '',
          complement: addressMain?.complement ?? '',
          neighborhood: addressMain?.neighborhood ?? '',
          code_country: addressMain?.country?.code ?? '',
          country: addressMain?.country?.name ?? '',
          city: addressMain?.city ?? '',
          uf: addressMain?.uf ?? '',
          telephone: buyer?.contacts[0]?.telephone ?? '',
          code_ibge: buyer?.addresses[0]?.ibge ?? '',
        };
        Vue.set(formValue, 'buyer', formValue.buyer);

        await applyBusinessItems(formValue);
      },
    },
    is_costumer: {
      change: async (formValue) => {
        if (formValue.buyer_id) {
          await applyBusinessItems(formValue);
        }
      },
    },
    transporter_id: {
      change: async (formValue) => {
        const transporter = await Vue.prototype.$api.Registers.show(formValue.transporter_id);
        formValue.transporter = transporter;
        const addressMain = transporter.addresses.find((address) => address.main === true);
        formValue.transporter = {
          transporter_id: formValue.transporter_id,
          name: transporter.name,
          cnpj: transporter.cnpj_cpf,
          ie: transporter.ie_rg,
          street: addressMain.street,
          number: addressMain.number,
          zip_code: addressMain.zip_code,
          complement: addressMain.complement,
          neighborhood: addressMain.neighborhood,
          code_country: addressMain.country ? addressMain.country.code : '',
          country: addressMain.country ? addressMain.country.name : '',
          city: addressMain.city,
          uf: addressMain.uf,
          telephone: transporter.contacts.length ? transporter.contacts[0].telephone : '',
        };
        Vue.set(formValue, 'transporter', formValue.transporter);

        if (transporter?.addresses.length > 0) {
          Vue.set(formValue, 'transporter.street', addressMain.street);
          Vue.set(formValue, 'transporter.number', addressMain.number);
          Vue.set(formValue, 'transporter.zip_code', addressMain.zip_code);
          Vue.set(formValue, 'transporter.complement', addressMain.complement);
          Vue.set(formValue, 'transporter.neighborhood', addressMain.neighborhood);
          if (addressMain.country) Vue.set(formValue, 'transporter.country', addressMain.country.name);
          Vue.set(formValue, 'transporter.city', addressMain.city);
          Vue.set(formValue, 'transporter.uf', addressMain.uf);
          if (transporter.contacts.length > 0) Vue.set(formValue, 'transporter.telephone', transporter.contacts[0].telephone);
        }
      },
    },
    nature_operation_id: {
      change: async (formValue, fieldValue, fieldObjectValue) => {
        const nature_operation_id = fieldObjectValue;

        const { data } = nature_operation_id ? await Vue.prototype.$api.NatureOperations.showGraphql(nature_operation_id, ['id', 'to_s', 'natureOperationStates', ['uf_state']]) : { data: {} };
        formValue.nature_operation = { id: data.id, name: data.to_s, to_s: data.to_s };
        formValue.nature_operation_name = formValue.nature_operation.name;

        formValue.items = formValue.items || [];
        formValue.items.forEach((item) => {
          item.nature_operation_id = formValue.nature_operation_id;
        });

        await applyBusinessItems(formValue);
      },
    },
    order: {
      change: (formValue) => {
        formValue.items = formValue.items || [];
        formValue.items.forEach((item) => {
          item.order_number = formValue.order;
        });
      },
    },
    stock_id: {
      change: (formValue) => {
        formValue.items = formValue.items || [];
        formValue.items.forEach((item) => {
          item.stock_id = formValue.stock_id;
        });
      },
    },
    expense_value: {
      change: async (formValue) => {
        if (formValue.buyer_id) {
          await applyBusinessItems(formValue);
        }
      },
    },
    'transporter.use_freight': {
      change: async (formValue) => {
        if (formValue.buyer_id) {
          await applyBusinessItems(formValue);
        }
      },
    },
    total_freight: {
      change: async (formValue) => {
        if (formValue.buyer_id) {
          await applyBusinessItems(formValue);
        }
      },
    },
    items: {
      beforeCreate: async (formValue, newItem) => {
        if (!formValue.buyer_id) return 'Não é possível adicionar ítens sem selecionar um cliente';
        if (!formValue.nature_operation_id) return 'Não é possível adicionar ítens sem selecionar a natureza de operação';
        if (formValue?.buyer?.uf === '') return 'Para adicionar ítens na nota fiscal precisa ter endereço UF no Destinatário/Remetente selecionado';

        newItem.buyer_uf = formValue?.buyer?.uf ?? '';
        newItem.nature_operation_id = formValue.nature_operation_id;
        newItem.order_number = formValue.order;
      },
      change: async (formValue) => {
        const { nature_operation_id } = formValue;
        const natureOperations = nature_operation_id ? await Vue.prototype.$api.NatureOperations.show(nature_operation_id) : { data: {} };
        formValue.nature_operation = { id: natureOperations.data.id, name: natureOperations.data.to_s, to_s: natureOperations.data.to_s };
        formValue.nature_operation_name = natureOperations.data.to_s ?? '';
        formValue.items.forEach((item) => {
          item.unit_value_formatted = AppUtils.formatCurrency(parseFloat(item.unit_value));
          item.discount_percentage_value_formatted = `${AppUtils.formatNumber(parseFloat(item.discount_percentage_value))}%`;
          item.discount_value_formatted = AppUtils.formatCurrency(parseFloat(item.discount_value));
          item.total_value_formatted = AppUtils.formatCurrency(parseFloat(item.total_value));
          item.nature_operation = { id: natureOperations.data.id, name: natureOperations.data.to_s, to_s: natureOperations.data.to_s };
          item.nature_operation_name = natureOperations.data.to_s ?? '';
        });

        await applyBusinessItems(formValue);
      },
      delete: async (formValue) => {
        if (formValue.buyer_id) {
          await applyBusinessItems(formValue);
        }
      },
    },
    'bill.duplicates': {
      change(formValue) {
        const { duplicates } = formValue.bill;
        const duplicatesFormatted = duplicates.map((duplicate) => {
          const value = duplicate.value ?? 0;
          return {
            ...duplicate,
            value_formatted: AppUtils.formatCurrency(value),
          };
        });
        formValue.bill.duplicates = duplicatesFormatted;
        Vue.set(formValue, 'bill', formValue.bill);
      },
    },
    indicator_intermediary: {
      change(formValue) {
        if (formValue.indicator_intermediary === '0') {
          formValue.intermediary_cnpj = '';
          formValue.intermediary_name = '';
        }
      },
    },
  },
  applyGlobalDiscountInvoice: async (formValue, discount, usePercent) => {
    applyGlobalDiscountInvoice(formValue, discount, usePercent);
    await applyBusinessItems(formValue);
  },

  applyAlterInfoNF: (formValue, invoice_number) => {
    if (formValue.status !== 'pending' || formValue.status !== 'rejected') {
      return;
    }
    Vue.set(formValue, 'invoice_number', invoice_number);
    return formValue;
  },
  applyAlterInfoNFKey: (formValue, invoice_key) => {
    if (formValue.status !== 'rejected') {
      return;
    }
    Vue.set(formValue, 'invoice_key', invoice_key);
    return formValue;
  },
};
