import { ICON_CARD_TEXT } from '@constants/icons';
// import OrderSaleItemBusiness from '@businesses/OrderSaleItem';

function disabledWhenBankLayoutIsBTG(formValue) {
  if (formValue?.bank_layout === 'btg_pactual_208') {
    return true;
  }
  return false;
}

const solveWalletsItems = [];
export function getWalletsItems() { return solveWalletsItems; }
export function setWalletsItems(param) { solveWalletsItems.push(param); }
export function resetWalletsItems() { solveWalletsItems.splice(0); }

const solveSpeciesItems = [];
export function getSpeciesItems() { return solveSpeciesItems; }
export function setSpeciesItems(param) { solveSpeciesItems.push(param); }
export function resetSpeciesItems() { solveSpeciesItems.splice(0); }

const solveInstructionsItems = [];
export function getInstructionsItems() { return solveInstructionsItems; }
export function setInstructionsItems(param) { solveInstructionsItems.push(param); }
export function resetInstructionsItems() { solveInstructionsItems.splice(0); }

export default {
  icon: ICON_CARD_TEXT,
  fields: [
    {
      label: 'BOLETO',
      component: 'dynamic-title',
      colClass: 'mt-4',
      size: 'md',
      propTag: 'h5',
    },
    {
      label: 'Número Atual',
      name: 'current_number',
      type: 'number',
      component: 'dynamic-text-field',
      required: true,
      md: 4,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Aceite',
      name: 'accept',
      type: 'boolean',
      component: 'dynamic-checkbox',
      required: true,
      md: 2,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Nº Sequencial Remessa',
      name: 'sequence_number',
      type: 'number',
      component: 'dynamic-text-field',
      required: true,
      md: 4,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'CNAB Ativado',
      name: 'active',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    {
      label: 'Carteira',
      name: 'wallet_id',
      type: 'number',
      required: true,
      component: 'dynamic-select',
      items: getWalletsItems(),
      itemText: 'to_s',
      itemValue: 'id',
      md: 6,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Espécie',
      name: 'specie_id',
      type: 'number',
      required: true,
      component: 'dynamic-select',
      items: solveSpeciesItems,
      itemText: 'to_s',
      itemValue: 'id',
      md: 4,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Informar Juros Diários',
      name: 'interest_per_day',
      type: 'boolean',
      component: 'dynamic-checkbox',
      required: true,
      md: 2,
    },
    {
      label: 'Instrução',
      name: 'instruction_id',
      type: 'number',
      required: true,
      component: 'dynamic-select',
      items: solveInstructionsItems,
      itemText: 'to_s',
      itemValue: 'id',
      md: 8,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Moeda',
      name: 'coin_id',
      type: 'number',
      required: true,
      component: 'dynamic-select',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['coin', 'name asc'],
      },
      itemText: 'description',
      itemValue: 'id',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
      md: 4,
    },
    {
      label: 'Controle de Dias',
      component: 'dynamic-title',
      colClass: 'mt-4',
      size: 'md',
      propTag: 'h6',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Dias Limites Desconto',
      name: 'discount_limit_day',
      type: 'number',
      component: 'dynamic-text-field',
      required: true,
      md: 3,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Dias p/ Multa',
      name: 'fine_day',
      type: 'number',
      component: 'dynamic-text-field',
      required: true,
      md: 3,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Dias p/ Protesto',
      name: 'protest_day',
      type: 'number',
      component: 'dynamic-text-field',
      required: true,
      md: 3,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Floater',
      name: 'floater_day',
      type: 'number',
      component: 'dynamic-text-field',
      required: true,
      md: 3,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Valores',
      component: 'dynamic-title',
      colClass: 'mt-4',
      size: 'md',
      propTag: 'h6',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: '% Desconto',
      name: 'discount_value',
      type: 'number',
      component: 'dynamic-text-field',
      required: true,
      md: 4,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: '% Abatimento',
      name: 'rebate_value',
      type: 'number',
      component: 'dynamic-text-field',
      required: true,
      md: 4,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: '% Juros(mês)',
      name: 'interest_value',
      type: 'number',
      component: 'dynamic-text-field',
      required: true,
      md: 4,
    },
    {
      label: '% Multa',
      name: 'fine_value',
      type: 'number',
      component: 'dynamic-text-field',
      required: true,
      md: 6,
    },
    {
      label: '% IOF',
      name: 'iof_value',
      type: 'number',
      component: 'dynamic-text-field',
      required: true,
      md: 6,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Informação Adicional',
      component: 'dynamic-title',
      colClass: 'mt-4',
      size: 'md',
      propTag: 'h6',
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Local de Pagamento',
      name: 'info_1',
      type: 'text',
      component: 'dynamic-text-field',
      md: 6,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Informação da Ficha de Compensação',
      name: 'info_2',
      type: 'text',
      component: 'dynamic-text-field',
      md: 6,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Info 3',
      name: 'info_3',
      type: 'text',
      component: 'dynamic-text-field',
      md: 6,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
    {
      label: 'Info 4',
      name: 'info_4',
      type: 'text',
      component: 'dynamic-text-field',
      md: 6,
      disabled: (formValue) => disabledWhenBankLayoutIsBTG(formValue),
    },
  ],
};
