import Vue from 'vue';
import '@plugins/charts';

export function SalesmanChart(data) {
  return this.$chartsBarsWithMovingBullets(this.$refs.chartSalesman, data, {
    valueName: 'steps',
    categoryName: 'nome',
    strokeColor: '#fff',
    strokeOpacity: 1,
  });
}

export const CustomCharts = {
  install(vue) {
    vue.prototype.$customChartsSalesmanChart = SalesmanChart;
  },
};

Vue.use(CustomCharts);
