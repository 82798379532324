import GeneralAccountPaymentsSchema from './GeneralAccountReceivableReportsSchema';

const GeneralAccountPayments = () => import(/* webpackChunkName: "reports-acounts-receivable-general" */'./GeneralAccountReceivablesReports');
export const AccountReceivableRoutes = [
  {
    path: GeneralAccountPaymentsSchema.route.path,
    name: GeneralAccountPaymentsSchema.route.name,
    meta: {
      requiresAuth: true,
      rule: 'registers',
      breadcrumb: [
        { name: 'Relatórios' },
        { name: 'Contas a Receber' },
      ],
    },
    component: GeneralAccountPayments,
  },
];
