import { ICON_PRODUCT } from '@constants/icons';
import {
  OPTIONS_UF, OPTIONS_FREIGHT, OPTIONS_INVOICE_STATUS, OPTIONS_TYPE_INVOICES_ENVIRONMENTS, OPTIONS_INVOICE_DOCUMENT_TYPE,
} from '@constants/options';

export default {
  service: 'Invoices',
  icon: ICON_PRODUCT,
  route: {
    path: '/reports/invoices/general',
    name: 'reports-invoices-general',
  },
  title: 'Relatórios de Notas Fiscais',
  filtersLayout: [
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'v-col',
          cols: '8',
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [
                {
                  label: 'Destinatário',
                  name: 'buyer_id',
                  type: 'number',
                  hint: '',
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'Registers',
                    method: 'getByFilter',
                    props: [{ type_register_array: 'client' }, 'name asc'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 12,
                  clearable: true,
                },
                {
                  label: 'Transportadora',
                  name: 'shipping_company_id',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'Registers',
                    method: 'getByFilter',
                    props: [{ type_register_array: 'shipping_company' }, 'name asc'],
                  },
                  itemText: 'name',
                  itemValue: 'id',
                  md: 12,
                  clearable: true,
                },
              ],
            },
          ],
        },
        {
          component: 'v-col',
          cols: '4',
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [{
                label: 'Datas',
                name: 'dynamic-dates',
                fields: [
                  {
                    name: 'emission_date',
                    label: 'Data de Emissão',
                  },
                  {
                    name: 'exit_date',
                    label: 'Data de Saída',
                  },
                ],
                component: 'dynamic-multi-date-filter',
                md: 12,
                clearable: true,
              }],
            },
          ],
        },
        {
          component: 'dynamic-filters',
          containerClass: 'pt-0',
          fields: [
            {
              label: 'Produto',
              name: 'product_id',
              formattedName: 'product.to_s',
              type: 'number',
              hideNot: true,
              component: 'dynamic-autocomplete',
              dataSource: {
                service: 'Products',
                method: 'getByFilter',
                props: [{ use_inactive_eq: false }, 'name asc'],
              },
              itemText: 'name',
              itemValue: 'id',
              md: 8,
              clearable: true,
            },
            {
              label: 'Código',
              name: 'items.product.code',
              type: 'number',
              hideNot: true,
              component: 'dynamic-text-field',
              condition: '_matches',
              notCondition: '_does_not_match',
              md: 4,
            },
            {
              label: 'FILTROS DO DESTINATÁRIO',
              component: 'dynamic-title',
              md: 2,
              size: 'xl',
              propTag: 'h6',
              hideNot: true,
            },
            {
              component: 'dynamic-divider',
              md: 10,
            },
            {
              label: 'Bairro',
              name: 'buyer.addresses.neighborhood',
              type: 'text',
              hideNot: true,
              component: 'dynamic-text-field',
              condition: '_matches',
              notCondition: '_does_not_match',
              md: 4,
            },
            {
              label: 'Cidade',
              name: 'buyer.addresses.city',
              type: 'text',
              hideNot: true,
              component: 'dynamic-text-field',
              condition: '_matches',
              notCondition: '_does_not_match',
              md: 4,
            },
            {
              label: 'UF',
              name: 'buyer.addresses.uf',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              items: OPTIONS_UF,
              md: 4,
            },
            {
              label: 'Avaliação',
              name: 'buyer.evaluation_id',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['evaluation'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Tipo',
              name: 'buyer.type_id',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['type'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Atividade / Ocupação',
              name: 'buyer.occupation_id',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['occupation'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'FILTROS DA NOTA FISCAL',
              component: 'dynamic-title',
              md: 2,
              size: 'xl',
              propTag: 'h6',
              hideNot: true,
            },
            {
              component: 'dynamic-divider',
              md: 10,
            },
            {
              label: 'Parcelamento',
              name: 'type_installment_id',
              formattedName: 'type_installment.to_s',
              type: 'number',
              multiple: true,
              hideNot: true,
              clearable: true,
              component: 'dynamic-autocomplete',
              redirectCreateRoute: 'tables-typeinstallment-create',
              dataSource: {
                service: 'TypeInstallment',
                method: 'getByFilter',
                props: [{ inactive_eq: false }],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Natureza da Operação',
              name: 'nature_operation_id',
              formattedName: 'nature_operation.to_s',
              type: 'number',
              multiple: true,
              hideNot: true,
              clearable: true,
              component: 'dynamic-select',
              dataSource: {
                service: 'NatureOperations',
                method: 'getByFilterGraphql',
                props: [{
                  use_inactive_eq: false,
                  type_nature_eq: 'sale',
                  query: ['id', 'name'],
                }],
              },
              itemText: 'name',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Status',
              name: 'status',
              type: 'string',
              multiple: true,
              hideNot: true,
              clearable: true,
              component: 'dynamic-select',
              items: OPTIONS_INVOICE_STATUS,
              md: 4,
            },
            {
              label: 'Frete',
              name: 'freight',
              formattedName: 'freight_formatted',
              type: 'string',
              multiple: true,
              hideNot: true,
              clearable: true,
              component: 'dynamic-select',
              items: OPTIONS_FREIGHT,
              md: 4,
            },
            {
              label: 'Ambiente',
              name: 'environments',
              type: 'string',
              multiple: true,
              hideNot: true,
              clearable: true,
              component: 'dynamic-select',
              items: OPTIONS_TYPE_INVOICES_ENVIRONMENTS,
              md: 4,
            },
            {
              label: 'Modelo de Notas Fiscais',
              name: 'invoice_setting_id',
              formattedName: 'invoice_setting_id.to_s',
              type: 'number',
              component: 'dynamic-select',
              dataSource: {
                service: 'InvoiceTemplates',
                method: 'index',
              },
              itemText: 'to_s',
              itemValue: 'id',
              multiple: true,
              hideNot: true,
              clearable: true,
              md: 4,
            },
            {
              label: 'Tipo de Documento',
              name: 'document_type',
              type: 'text',
              required: true,
              list: true,
              multiple: true,
              hideNot: true,
              clearable: true,
              items: OPTIONS_INVOICE_DOCUMENT_TYPE,
              component: 'dynamic-select',
              md: 4,
            },
          ],
        },
      ],
    },
  ],
};
