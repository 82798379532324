import {
  ICON_DESCRIPTION,
  ICON_INVOICE,
  ICON_CANCEL_CIRCLE,
} from '@constants/icons';
import { OPTIONS_INVOICE_STATUS } from '@constants/options';
import InvoiceBusiness from '@businesses/Invoice';

import ListFields from './invoice/ListFields';
import CustomerFields from './invoice/CustomerFields';
import EmitterFields from './invoice/EmitterFields';
import BillsFields from './invoice/BillsFields';
import TaxesFields from './invoice/TaxesFields';
import TransporterFields from './invoice/TransporterFields';
import AditionalFields from './invoice/AditionalFields';
import InvoiceItemsSchema from './InvoiceItems';
import InvoiceItemTaxSchema from './InvoiceItemTax';
import InvoiceBillsDuplicatesSchema from './InvoiceBillsDuplicates';

import TabInvoice from '@modules/supply/pages/invoices/components/TabInvoice';
import TabInvoiceErrors from '@modules/supply/pages/invoices/components/TabInvoiceErrors';

export const checkDisableFormFields = (formValue) => {
  if (formValue.status === 'authorized') return true;
  if (formValue.status === 'canceled') return true;
  if (formValue.status === 'disabled') return true;
  return false;
};

export default {
  service: 'Invoices',
  business: InvoiceBusiness,
  icon: ICON_INVOICE,
  routes: {
    list: {
      path: '/supply/invoices',
      name: 'supply-invoices-list',
    },
    create: {
      path: '/supply/invoices/create',
      name: 'supply-invoices-create',
    },
    edit: {
      path: '/supply/invoices/edit/:id',
      name: 'supply-invoices-edit',
    },
    import: {
      path: '/supply/invoices/import',
      name: 'supply-invoices-import',
    },
  },
  disableSaveButton: checkDisableFormFields,
  titles: {
    item: 'Espelho da Nota Fiscal',
    items: 'Notas Fiscais',
    create: 'Criar Nota Fiscal',
    edit: 'Editar Notas Fiscais',
    import: 'Importar Arquivo XML de Nota Fiscal',
    new: 'Novo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  fields: [
    ...ListFields,
  ],
  statusSettings: {
    options: OPTIONS_INVOICE_STATUS,
    statusField: 'status',
    initialStatus: 'initial',
    controlCanceled: true,
  },
  layout: [
    {
      component: 'slot',
      name: 'nf-header',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          disabled: checkDisableFormFields,
          containerClass: 'pt-0',
          fields: [...EmitterFields],
        },
      ],
    },
    {
      label: 'DESTINATÁRIO / REMETENTE',
      class: 'mb-0 mx-3 pb-0',
      component: 'dynamic-divider',
      md: 12,
      size: 'md',
      propTag: 'h5',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          disabled: checkDisableFormFields,
          fields: [...CustomerFields],
        },
      ],
    },
    {
      label: 'FATURA / DUPLICATA',
      component: 'dynamic-divider',
      class: 'mx-3 pb-0 mb-4',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'generic-card-form',
      class: 'mx-3 my-3',
      children: [
        {
          component: 'v-row',
          noGutters: true,
          class: 'nf-field top right bottom left ma-0',
          children: [
            {
              component: 'v-col',
              class: 'nf-field right',
              cols: '4',
              children: [
                {
                  component: 'dynamic-form',
                  disabled: checkDisableFormFields,
                  containerClass: 'pa-0',
                  fields: [...BillsFields],
                },
              ],
            },
            {
              component: 'v-col',
              cols: '8',
              children: [
                {
                  component: 'dynamic-child-schema',
                  disabled: checkDisableFormFields,
                  fieldName: 'bill.duplicates',
                  containerClass: 'pa-0',
                  childSchema: InvoiceBillsDuplicatesSchema,
                  noCheck: true,
                  noEdit: true,
                  noDelete: checkDisableFormFields,
                }],
            }],
        },
      ],
    },

    {
      label: 'CÁLCULO DOS IMPOSTOS',
      component: 'dynamic-divider',
      class: 'mb-0 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          disabled: checkDisableFormFields,
          fields: [...TaxesFields],
        },
      ],
    },
    {
      label: 'TRANSPORTADOR / VOLUMES TRANSPORTADOS',
      component: 'dynamic-divider',
      class: 'mb-0 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'dynamic-form',
          disabled: checkDisableFormFields,
          fields: [...TransporterFields],
        },
      ],
    },
    {
      label: 'DADOS DOS PRODUTOS',
      component: 'dynamic-divider',
      class: 'mb-4 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'generic-card-form',
      class: 'mx-3 my-3',
      children: [
        {
          component: 'v-row',
          noGutters: true,
          class: 'nf-field top right bottom left ma-0',
          children: [
            {
              component: 'dynamic-tabs',
              tabs: [
                {
                  label: 'Itens da Nota',
                  children: [
                    {
                      component: 'dynamic-child-schema',
                      disabled: checkDisableFormFields,
                      noEdit: checkDisableFormFields,
                      noDelete: checkDisableFormFields,
                      noCheck: true,
                      fieldName: 'items',
                      containerClass: 'pa-0',
                      childSchema: InvoiceItemsSchema,
                    },
                  ],
                },
                {
                  label: 'Conferência de Impostos',
                  children: [
                    {
                      component: 'dynamic-child-schema',
                      disabled: checkDisableFormFields,
                      fieldName: 'items',
                      containerClass: 'pa-0',
                      noCheck: true,
                      noEdit: true,
                      noDelete: true,
                      noCreate: true,
                      noActions: true,
                      childSchema: InvoiceItemTaxSchema,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      component: 'slot',
      name: 'nf-totals',
    },
    {
      label: 'DADOS ADICIONAIS',
      component: 'dynamic-divider',
      class: 'mb-4 mx-3 pb-0',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      component: 'generic-card-form',
      class: 'mx-3 my-3',
      children: [
        {
          component: 'v-row',
          noGutters: true,
          children: [
            {
              component: 'dynamic-form',
              disabled: checkDisableFormFields,
              fields: [...AditionalFields],
            },
          ],
        },
      ],
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabInvoice,
    },
    {
      icon: ICON_CANCEL_CIRCLE,
      iconColor: 'red',
      title: 'Erros de transmissão',
      tabContent: TabInvoiceErrors,
    },
  ],
};
