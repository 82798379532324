import * as AppUtils from '@plugins/app-utils';

const endpoint = 'open_titles/account_payment_installments';

export default ($axios) => ({
  async index() {
    let responseData = {};
    await $axios.get(`${endpoint}.json`).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },
  async indexPaginable(params) {
    const {
      optionsPaginable,
    } = params;
    const payloadPaginable = `?page=${optionsPaginable.page}&per_page=${optionsPaginable.per_page}`;
    let responseData = {};
    let totalizers = {};
    let paginator = {};
    await $axios.get(`${endpoint}.json${payloadPaginable}`).then((response) => {
      const { headers } = response;
      paginator = {
        page: response.data?.page ?? headers['icertusweb-current-page'],
        per_page: response.data?.per_page ?? headers['icertusweb-items-per-page'],
        total_items: response.data?.total_items ?? headers['icertusweb-total-items'],
        total_pages: response.data?.total_pages ?? headers['icertusweb-total-pages'],
      };
      const { open_titles, total, total_overdue } = this.formatResponseData(response.data);
      responseData = open_titles;
      totalizers = { total, total_overdue };
    });
    return { open_titles: [...responseData], totalizers, paginator };
  },
  async consolidate() {
    let responseData = {};
    await $axios.get(`${endpoint}/consolidate.json`).then((response) => {
      responseData = this.formatResponseData(response.data);
    });
    return responseData;
  },

  async liquidate(payload) {
    let responseData = {};
    const formattedPayload = payload.map((
      {
        to_s,
        isSelected,
        account_payment,
        name,
        bank,
        issuance_date,
        origin,
        due_date_formatted,
        payment_method,
        source_due_date,
        status,
        title_formatted,
        bank_formatted,
        payment_method_formatted,
        value_formatted,
        difference_value_formatted,
        total_value_formatted,
        type_account_payment_installment,
        ...items
      },
    ) => items);

    await $axios.post(`${endpoint}/settlement.json`, { account_payment_installments: formattedPayload })
      .then((response) => {
        responseData = this.formatResponseData(response.data);
      });

    return responseData;
  },

  formatResponseData(data, type = 'table') {
    const { open_titles, total, total_overdue } = data;
    const format = async (item) => {
      if (type === 'table') {
        item.issuance_date = AppUtils.formatDate(item.issuance_date);
        item.due_date = AppUtils.formatDate(item.due_date);
        item.payment_date = AppUtils.formatDate(item.payment_date);
        item.compensation_date = AppUtils.formatDate(item.compensation_date);
        item.source_due_date = AppUtils.formatDate(item.source_due_date);

        item.title_formatted = item.title;
        item.bank_formatted = item.bank.to_s;
        item.payment_method_formatted = item.payment_method.to_s;
        item.value_formatted = AppUtils.formatCurrency(item.value);
        item.difference_value_formatted = item.difference_value !== 0 ? AppUtils.formatCurrency(item.difference_value) : 'R$ 0,00';
        item.total_value_formatted = AppUtils.formatCurrency(item.total_value);
      } else if (type === 'refresh') {
        item.title_formatted = item.title;
        item.due_date = item.due_date;
        item.payment_date = item.payment_date;

        if (item.value_formatted.startsWith('R')) {
          item.value_formatted = item.value !== 0 ? AppUtils.formatCurrency(Number(item.value)) : 'R$ 0,00';
        } else {
          item.value_formatted = item.value !== 0 ? `R$ ${AppUtils.formatCurrency(Number(item.value))}` : 'R$ 0,00';
        }
        if (item.difference_value_formatted.startsWith('R')) {
          item.difference_value_formatted = item.difference_value !== 0 ? AppUtils.formatCurrency(Number(item.difference_value)) : 'R$ 0,00';
        } else {
          item.difference_value_formatted = item.difference_value !== 0 ? `R$ ${AppUtils.formatCurrency(Number(item.difference_value))}` : 'R$ 0,00';
        }

        // Recalculates the total of parcel
        item.total_value = item.value - item.difference_value;
        item.total_value_formatted = item.value !== 0 ? AppUtils.formatCurrency(item.total_value) : 'R$ 0,00';

        await this.getPaymentMethod(item.payment_method_id).then((res) => {
          item.payment_method_formatted = res.data.to_s;
        });
        await this.getBank(item.bank_id).then((res) => {
          item.bank_formatted = res.data.to_s;
        });
      }
    };

    let responseData = {};
    open_titles.forEach((item) => {
      format(item);
    });
    responseData = {
      open_titles,
      total,
      total_overdue,
      totalizers: { total, total_overdue },
    };
    return responseData;
  },

  async getPaymentMethod(id) {
    return $axios.get(`all_types/${id}.json`);
  },
  async getBank(id) {
    return $axios.get(`banks/${id}.json`);
  },
});
