<template>
  <div class="tab-order-sale-itens mt-5">
    <GenericCardPanel title-outside title="PROCESSOS DE FABRICAÇÃO">
      <DataList v-if="localItems.length > 0"
                :headers="headers"
                :items="localItems"
                class="mt-n12"
                item-key="id"
                no-check
                :sort-by="['id']"
                :sort-desc="[false, true]" />
      <p v-else class="text-style ml-5 mt-n14">
        Não existem processos de fabricação cadastrados neste produto
      </p>
    </GenericCardPanel>
  </div>
</template>

<script>
import { ICON_REFRESH } from '@constants/icons';
import SchemaUtil from '@schemas/Util';
import ProductCompositionFactory from '@schemas/ProductCompositionFactoryItem';
import DataList from '@sharedComponents/dynamic/DataList';
import GenericCardPanel from '@sharedComponents/dynamic/GenericCardPanel';

export default {
  name: 'TabProductCompositionFactory',
  components: {
    DataList,
    GenericCardPanel,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      icons: {
        refresh: ICON_REFRESH,
      },
      schema: ProductCompositionFactory,
      localItems: [],
    };
  },
  computed: {
    headers() {
      return SchemaUtil.getHeaders(this.schema, false, this.actionsWidth);
    },
  },
  mounted() {
    this.getItems(this.item.id).then((response) => {
      this.localItems = [...response];
    });
  },
  methods: {
    async getItems(id) {
      const compositions = await this.getProcedures(id);
      return compositions || [];
    },
    async getProcedures(id) {
      if (id) {
        const response = await this.$api.Compositions.show(id);
        const { procedures } = response.data;
        return procedures || [];
      }
      return [];
    },
  },
};
</script>
<style lang="scss" scoped>
.text-style {
  font-family: 'Montserrat' !important;
  font-size: 15px !important;
  color: #3a3a3a !important;
}
</style>
