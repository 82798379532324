import { ICON_PRODUCT } from '@constants/icons';
import {
  OPTIONS_UF, OPTIONS_TYPE_REGISTER,
} from '@constants/options';

export default {
  service: 'Registers',
  icon: ICON_PRODUCT,
  route: {
    path: '/reports/registers',
    name: 'reports-registers-general',
  },
  title: 'Relatórios de Cadastros',
  filtersLayout: [
    {
      component: 'v-row',
      noGutters: true,
      children: [
        {
          component: 'v-col',
          cols: '12',
          children: [
            {
              component: 'dynamic-filters',
              containerClass: 'pt-0',
              fields: [
                {
                  label: 'Nome',
                  name: 'id',
                  type: 'number',
                  hint: '',
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'Registers',
                    method: 'index',
                    props: [{}, 'name asc'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 8,
                  clearable: true,
                },
                {
                  label: 'Tipo de Cadastro',
                  name: 'type_register',
                  type: 'string',
                  multiple: true,
                  clearable: true,
                  md: 4,
                  hideNot: true,
                  component: 'dynamic-select',
                  condition: '_array',
                  items: OPTIONS_TYPE_REGISTER,
                },
                {
                  label: 'Vendedor',
                  name: 'preference.salesman.id',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'Registers',
                    method: 'getByFilter',
                    props: [{ type_register_array: 'salesman' }, 'name asc'],
                  },
                  itemText: 'to_s',
                  itemValue: 'id',
                  md: 6,
                  clearable: true,
                },
                {
                  label: 'Transportadora',
                  name: 'shipping_company_id',
                  type: 'number',
                  hideNot: true,
                  component: 'dynamic-autocomplete',
                  dataSource: {
                    service: 'Registers',
                    method: 'getByFilter',
                    props: [{ type_register_array: 'shipping_company' }, 'name asc'],
                  },
                  itemText: 'name',
                  itemValue: 'id',
                  md: 6,
                  clearable: true,
                },
              ],
            },
          ],
        },
        {
          component: 'dynamic-filters',
          containerClass: 'pt-0',
          fields: [
            {
              label: 'Bairro',
              name: 'addresses.neighborhood',
              type: 'text',
              hideNot: true,
              component: 'dynamic-text-field',
              condition: '_matches',
              notCondition: '_does_not_match',
              md: 4,
            },
            {
              label: 'Cidade',
              name: 'addresses.city',
              type: 'text',
              hideNot: true,
              component: 'dynamic-text-field',
              condition: '_matches',
              notCondition: '_does_not_match',
              md: 4,
            },
            {
              label: 'UF',
              name: 'addresses.uf',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              items: OPTIONS_UF,
              md: 4,
            },
            {
              label: 'Avaliação',
              name: 'evaluation_id',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['evaluation'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Tipo',
              name: 'type_id',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['type'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Atividade / Ocupação',
              name: 'occupation_id',
              type: 'text',
              hideNot: true,
              component: 'dynamic-select',
              multiple: true,
              clearable: true,
              dataSource: {
                service: 'AllTypesCommands',
                method: 'getManyByTypeExcludeDisables',
                props: ['occupation'],
              },
              itemText: 'to_s',
              itemValue: 'id',
              md: 4,
            },
            {
              label: 'Status do cadastro',
              name: 'installments.paid_off',
              type: 'string',
              multiple: true,
              clearable: true,
              hideNot: true,
              component: 'dynamic-select',
              items: [
                { text: 'Ativo', value: false },
                { text: 'Inativo', value: true },
              ],
              md: 4,
            },
          ],
        },
      ],
    },
  ],
};
