const endpoint = 'customer';

export default ($axios) => ({
  /**
   * Get one object
   * @param {Number} id Customer Id
   */
  async show() {
    let responseData = {};
    await $axios.get(`${endpoint}.json`).then((response) => {
      responseData = this.formatResponseData(response.data, 'row');
    });
    return responseData;
  },
  /**
   * Create one object
   * @param {Object} data Form Data
   */
  async create(payload) {
    const res = await $axios.post(`${endpoint}.json`, payload);
    return res;
  },
  /**
   * Update one object
   * @param {Number} id Customer Id
   * @param {Object} data Form Data
   */
  async update(id, payload) {
    await $axios.put(`${endpoint}/${id}.json`, payload);
  },
  /**
   * Destroy one object
   * @param {Number} id Customer Id
   */
  async destroy(id) {
    await $axios.delete(`${endpoint}/${id}.json`);
  },
  /*
  * Format Functions
  */
  formatResponseData(data) {
    return data;
  },
});
