export default [
  // line 1
  {
    label: 'RNTRC',
    name: 'rntrc',
    type: 'number',
    component: 'dynamic-text-field',
    list: true,
    clearable: true,
    cols: 6,
    colClass: 'nf-field top left bottom right',
    prependIcon: '',
  },
  {
    label: 'CIOT',
    name: 'ciot',
    component: 'dynamic-text-field',
    type: 'number',
    clearable: true,
    cols: 6,
    prependIcon: '',
    colClass: 'nf-field top bottom right',
  },
];
