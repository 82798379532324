<template>
  <v-btn v-if="route || type === 'route'"
         :color="color"
         :class="classCss + 'font-montserrat'"
         :to="{ name: route, params: { id: id } }"
         type="button"
         v-bind="$attrs"
         :target="target"
         :elevation="elevation"
         v-on="$listeners">
    <slot />
  </v-btn>
  <v-btn v-else-if="type === 'new'"
         :to="'/' + route + '/create'"
         color="primary"
         :class="classCss + 'font-montserrat'"
         type="button"
         v-bind="$attrs"
         :target="target"
         :elevation="elevation"
         v-on="$listeners">
    <slot />
  </v-btn>
  <v-btn v-else-if="type === 'edit'"
         :to="'/' + route + '/edit/' + id"
         :color="color"
         :class="classCss + 'font-montserrat'"
         type="button"
         v-bind="$attrs"
         :target="target"
         :elevation="elevation"
         v-on="$listeners">
    <slot />
  </v-btn>
  <v-btn v-else-if="type === 'submit'"
         color="success"
         :class="classCss + 'font-montserrat'"
         v-bind="$attrs"
         :target="target"
         :elevation="elevation"
         width="98"
         v-on="$listeners">
    <strong style="color: #3a3a3a">Salvar</strong>
  </v-btn>
  <v-btn v-else-if="type === 'reset'"
         :class="classCss + 'font-montserrat outlined'"
         label="Limpar"
         color="black"
         type="reset"
         outlined
         v-bind="$attrs"
         width="98"
         :target="target"
         :elevation="elevation"
         v-on="$listeners">
    <strong style="color: #3a3a3a">{{ label || 'Limpar' }}</strong>
  </v-btn>
  <v-btn v-else-if="type === 'back'"
         :class="classCss + 'font-montserrat'"
         type="button"
         v-bind="$attrs"
         width="98"
         color="secondary"
         :target="target"
         :elevation="elevation"
         @click="$router.go(-1)"
         v-on="$listeners">
    <strong style="color:#3a3a3a">Voltar</strong>
  </v-btn>
  <v-btn v-else-if="type === 'custom'"
         :loading="loading"
         :color="color"
         :class="classCss + 'font-montserrat'"
         v-bind="$attrs"
         :target="target"
         :elevation="elevation"
         v-on="$listeners">
    <slot />
  </v-btn>
</template>

<script>
import { ICON_SAVE } from '@constants/icons';

export default {
  name: 'Btn',
  props: {
    label: {
      type: String,
      default: '',
    },
    route: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
    type: {
      type: String,
      default: 'custom',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    tooltip: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    elevation: {
      type: String,
      default: '0',
    },
  },
  data: () => ({
    icons: {
      save: ICON_SAVE,
    },
  }),
  mounted() {},
};
</script>

<style>
.font-montserrat {
  font-family: 'Montserrat' !important;
  letter-spacing: 0px !important;
}
.outlined {
  border: solid 2px #3a3a3a !important;
}
</style>
