export default {
  /** @TODO - Constantes de ambiente devem ser declaradas em MAIÚSCULO p/ melhorar a identificação no código */
  NODE_ENV: process.env.VUE_APP_NODE_ENV,

  APP_URL: process.env.VUE_APP_APP_URL,
  APP_URL_AUTH: process.env.VUE_APP_URL_AUTH,
  API_ROOT: process.env.VUE_APP_API_ROOT,

  API_ADMINISTRATION: process.env.VUE_APP_API_ADMINISTRATION,
  API_GATEWAY_CHARTS: process.env.VUE_APP_API_GATEWAY_CHARTS,
  API_GATEWAY_FINANCIAL: process.env.VUE_APP_API_GATEWAY_FINANCIAL,
  API_GATEWAY_NFSE: process.env.VUE_APP_API_GATEWAY_NFSE,
  API_GATEWAY_PCP: process.env.VUE_APP_API_GATEWAY_PCP,
  API_GATEWAY_TAXES: process.env.VUE_APP_API_GATEWAY_TAXES,
  API_GATEWAY_TRADE: process.env.VUE_APP_API_GATEWAY_TRADE,

  ACTIVE_REPORTS_KEY: process.env.VUE_APP_ACTIVE_REPORTS_KEY,

  BTG_APP_AUTH_URI: process.env.VUE_APP_BTG_APP_AUTH_URI,
  BTG_APP_CLIENT_ID: process.env.VUE_APP_BTG_APP_CLIENT_ID,
  BTG_APP_CLIENT_SECRET: process.env.VUE_APP_BTG_APP_CLIENT_SECRET,
  BTG_APP_CLIENT_SCOPE: process.env.VUE_APP_BTG_APP_CLIENT_SCOPE,

  INTERCON_APP_ID: process.env.VUE_APP_INTERCON_APP_ID,

  DEFAULT_PRECISION: process.env.VUE_APP_DEFAULT_PRECISION,

  ACTIVE_INDICATIONS: process.env.VUE_APP_ACTIVE_INDICATIONS,
  ACTIVE_PARTNER_FORM: process.env.VUE_APP_ACTIVE_PARTNER_FORM,

  PDV_URL: process.env.VUE_APP_PDV_URL,

  S3_BUCKET_NFE_URL: process.env.VUE_APP_S3_BUCKET_NFE_URL,
  S3_BUCKET_NFCE_URL: process.env.VUE_APP_S3_BUCKET_NFCE_URL,
  S3_BUCKET_MDFE_URL: process.env.VUE_APP_S3_BUCKET_MDFE_URL,

  MAINTENANCE_MODE: process.env.VUE_APP_MAINTENANCE_MODE,

  WHATSAPP_SUPPORT_BR: process.env.VUE_APP_WHATSAPP_SUPPORT_BR,
};
