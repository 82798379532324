<template>
  <div class="text-xs-center">
    <v-dialog v-model="status"
              :width="width"
              :hide-overlay="!modal"
              :modal="modal"
              persistent>
      <v-card>
        <div class="primary" style="height: 5px; width: 100%" />
        <v-card-text class="subheading">
          <slot>
            <span v-if="text" class="loading-dots" v-html="text" />
          </slot>
          <slot name="progress">
            <v-progress-linear v-if="!numSteps"
                               indeterminate
                               height="20"
                               color="primary"
                               class="mb-0" />
            <v-progress-linear v-else
                               v-model="progress"
                               height="20"
                               color="primary"
                               class="mb-0" />
          </slot>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DmFullLoading',
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    when: {
      type: Boolean,
      default: undefined,
    },
    loader: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    width: {
      type: String,
      default: '450',
    },
    text: {
      type: String,
      default: '',
    },
    step: {
      type: Number,
      default: 0,
    },
    numSteps: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    progress() {
      return (this.step / this.numSteps) * 100;
    },
    isLoading() {
      const store = this.$store;
      if (!store) {
        // throw new Error('Vuex not initialized.');
      }
      return store.getters.isLoading;
    },
    anyLoading() {
      const store = this.$store;
      if (!store) {
        // throw new Error('Vuex not initialized.');
      }
      return store.getters.anyLoading;
    },
    status() {
      if (this.when !== undefined) {
        return this.when;
      }
      if (this.loader) {
        return this.isLoading(this.loader);
      }
      return this.anyLoading;
    },
  },
};
</script>

<style lang="scss">
.fullLoading {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
