<template>
  <div class="tab-order-sale-itens">
    <GenericCardForm>
      <TabListTaxes
        :schema="schema"
        :taxes="itemTaxes" />
    </GenericCardForm>
  </div>
</template>

<script>
import GenericCardForm from '@sharedComponents/dynamic/GenericCardForm';
import OrderSalesSchema from '@schemas/OrderSale';
import TabListTaxes from '@sharedComponents/tabs/TabListTaxes';

export default {
  name: 'TabOrderSaleItemTax',
  components: {
    GenericCardForm,
    TabListTaxes,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      itemTaxes: [],
    };
  },
  computed: {
    schema() {
      return OrderSalesSchema;
    },
  },
  created() {
    this.itemTaxes = this.item.items;
  },
};
</script>
