<template>
  <v-text-field
    v-if="!readOnly && !field.readOnly"
    v-model="fieldValue"
    :required="checkRequired()"
    :prefix="field.prefix"
    :counter="maxLength"
    :maxlength="maxLength"
    :suffix="field.suffix"
    :mask="field.mask"
    :return-masked-value="Boolean(field.mask)"
    :multi-line="field.multiLine"
    :hint="field.hint"
    :persistent-hint="true"
    :disabled="checkDisabled()"
    :rules="validationRules"
    :label="field.label"
    :type="showPwd ? 'text' : 'password'"
    :append-icon="showPwd ? eyeIcon : eyeOffIcon"
    :class="field.class"
    :autofocus="field.autofocus"
    :rounded="field.rounded"
    :filled="field.filled"
    :prepend-icon="innerIcon ? '' : prependIcon"
    :prepend-inner-icon="innerIcon ? prependIcon : ''"
    v-bind="$attrs"
    v-on="$listeners"
    @input="$emit('input', $event)"
    @change="fieldChanged"
    @blur="onBlurTextField"
    @click:append="showPwd = !showPwd" />
  <div v-else class="dm-information">
    <label>{{ field.label }}</label>
    <p>{{ fieldValue }}</p>
  </div>
</template>

<script>
import { ICON_EYE, ICON_EYE_OFF, ICON_FORM_TEXTBOX_PASSWORD } from '@constants/icons';
import { slugify } from '@plugins/app-utils';
import DynamicFieldMixin from './DynamicFieldMixin';

export default {
  name: 'DynamicTextField',

  mixins: [DynamicFieldMixin],

  data() {
    return {
      showPwd: '',
      fieldValue: '',
      eyeIcon: ICON_EYE,
      eyeOffIcon: ICON_EYE_OFF,
      defaultIcon: ICON_FORM_TEXTBOX_PASSWORD,
    };
  },

  computed: {
    maxLength() {
      if (this.field.maxLength && String(this.field.maxLength) !== '0') {
        return parseInt(this.field.maxLength);
      }

      return undefined;
    },
    prependIcon() {
      return this.field.prependIcon === undefined ? this.defaultIcon : this.field.prependIcon;
    },
    appendIcon() {
      return this.field.appendIcon;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
  },

  methods: {
    onBlurTextField() {
      if (this.field.slug) {
        this.fieldValue = slugify(this.fieldValue);
        if (this.maxLength) {
          this.fieldValue = this.fieldValue.substr(0, this.maxLength);
        }
        this.fieldChanged(this.fieldValue);
      }
    },
  },
};
</script>

<style lang="scss"></style>
