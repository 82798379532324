import Vue from 'vue';

export const calcTotalAndAbsoluteDiscount = (formValue) => {
  const discount = (parseFloat(formValue.discount_percentage_value) || 0) / 100;
  const unit = (parseFloat(formValue.unit_value) || 0);
  const quantity = (parseFloat(formValue.quantity) || 0);
  Vue.set(formValue, 'unit_value', formValue.unit_value);
  Vue.set(formValue, 'discount', formValue.discount);
  Vue.set(formValue, 'quantity', formValue.quantity);

  const total = unit * quantity;
  const totalWithDiscount = total - (total * discount);
  const discountValue = total - totalWithDiscount;
  Vue.set(formValue, 'discount_value', discountValue);
  Vue.set(formValue, 'total_without_discount_value', total);
  Vue.set(formValue, 'total_value', totalWithDiscount);
};

const calcItemTaxes = (formValue) => {
  const icms = { value: 0, aliquot: 0, base: 0 };
  const icms_st = { value: 0, aliquot: 0, base: 0 };
  const ipi = { value: 0, aliquot: 0, base: 0 };
  const pis = { value: 0, aliquot: 0, base: 0 };
  const cofins = { value: 0, aliquot: 0, base: 0 };

  Vue.set(formValue, 'icms', icms);
  Vue.set(formValue, 'icms_st', icms_st);
  Vue.set(formValue, 'ipi', ipi);
  Vue.set(formValue, 'pis', pis);
  Vue.set(formValue, 'cofins', cofins);

  return {
    icms,
    icms_st,
    ipi,
    pis,
    cofins,
  };
};

export const DefaultScreenEvents = {
  async beforeSave(formValue) {
    const taxes = calcItemTaxes(formValue);
    if (!formValue.nature_operation) {
      const { data } = await Vue.prototype.$api.NatureOperations.showGraphql(formValue.nature_operation_id, ['id', 'to_s']);
      formValue.nature_operation = { id: data.id, name: data.to_s, to_s: data.to_s };
    }

    if (!formValue.stock_id) {
      const { data: stockData } = await Vue.prototype.$api.Stocks.showGraphql(formValue.stock_id, ['id', 'to_s']);
      formValue.stock = { id: stockData.id, name: stockData.to_s, to_s: stockData.to_s };
    }

    if (formValue.ncm_id) {
      const responseNcm = await Vue.prototype.$api.AllTypes.show(formValue.ncm_id);
      formValue.ncm = responseNcm;
      formValue.ncm_name = responseNcm.description ?? '';
    }

    if (formValue.unity_id) {
      const responseUnity = await Vue.prototype.$api.AllTypes.show(formValue.unity_id);
      formValue.unity = responseUnity;
    }

    formValue = { ...formValue, ...taxes };
  },
};
