import * as AppUtils from '@plugins/app-utils';
import DynamicService from '@sharedServices/dynamic/DynamicService';

const formatResponse = (item) => {
  item.bank_layout_formatted = AppUtils.getOptionText('bank_layout', item.bank_layout);
  item.accept_formatted = item.accept ? 'Sim' : 'Não';
  item.active_formatted = item.active ? 'Sim' : 'Não';
  item.interest_per_day_formatted = item.interest_per_day ? 'Sim' : 'Não';
};

const endpoint = 'billet_layouts';

const Cnabs = DynamicService(endpoint, {
  formatResponse,
});

const CnabsCommands = ($axios) => ({
  async getConfig(bank_layout, field) {
    const payload = {
      billet_layout: {
        bank_layout,
      },
    };
    const res = await $axios.post(`${endpoint}/cnab/config.json`, payload);

    return res?.data[field] || [];
  },
});

export { Cnabs, CnabsCommands };
export default { Cnabs, CnabsCommands };
