import NationalSimpleProductTaxesBusiness from '@businesses/NationalSimpleProductTaxes';

export default {
  service: 'NationalSimpleProductTaxes',
  business: NationalSimpleProductTaxesBusiness,
  routes: {
    list: '',
    edit: '',
    create: '',
  },
  titles: {
    item: 'Item tabela de taxas de produtos e serviços',
    items: 'Itens tabela de taxas de produtos e serviços',
    edit: 'Editar Item',
    create: 'Criar Item',
    new: 'Adicionar Item',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: '',

  fields: [
    {
      label: 'De',
      name: 'min_value',
      formattedName: 'min_value_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      precision: 2,
      md: 6,
    },
    {
      label: 'Até',
      name: 'max_value',
      formattedName: 'max_value_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      list: true,
      precision: 2,
      md: 6,
    },
    {
      label: 'Alíquota',
      name: 'aliquot',
      formattedName: 'aliquot_formatted',
      type: 'number',
      list: true,
      disabled: true,
      component: 'dynamic-number-field',
      precision: 2,
      md: 3,
    },
    {
      label: 'IRPJ',
      name: 'irpj_aliquot',
      formattedName: 'irpj_aliquot_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      precision: 2,
      md: 3,
    },
    {
      label: 'CSLL',
      name: 'csll_aliquot',
      formattedName: 'csll_aliquot_formatted',
      type: 'number',
      list: true,
      required: true,
      component: 'dynamic-number-field',
      precision: 2,
      md: 3,
    },
    {
      label: 'COFINS',
      name: 'cofins_aliquot',
      formattedName: 'cofins_aliquot_formatted',
      type: 'number',
      list: true,
      required: true,
      component: 'dynamic-number-field',
      precision: 2,
      md: 3,
    },
    {
      label: 'PIS',
      name: 'pis_aliquot',
      formattedName: 'pis_aliquot_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      precision: 2,
      md: 3,
    },
    {
      label: 'CPP',
      name: 'cpp_aliquot',
      formattedName: 'cpp_aliquot_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      precision: 2,
      list: true,
      md: 3,
    },
    {
      label: 'ICMS',
      name: 'icms_aliquot',
      formattedName: 'icms_aliquot_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      precision: 2,
      required: true,
      md: 3,
    },
    {
      label: 'IPI',
      name: 'ipi_aliquot',
      formattedName: 'ipi_aliquot_formatted',
      type: 'number',
      list: true,
      component: 'dynamic-number-field',
      precision: 2,
      md: 3,
    },
  ],
};
