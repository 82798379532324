import {
  ICON_DESCRIPTION,
  ICON_FORM_NUMERIC,
  ICON_PRODUCT,
} from '@constants/icons';
import { checkSuperUser } from '@plugins/app-utils';
import ProductBusiness from '@businesses/Product';
import TabProducts from '@modules/supply/pages/products/components/TabProducts';
import TabProductsCompositions from '@modules/supply/pages/products/components/TabProductsCompositions';
import TabProductsCompositionFactory from '@modules/supply/pages/products/components/TabProductsCompositionFactory.vue';
import '@plugins/rules';

const nicknameSize = checkSuperUser() ? 5 : 8;
const useNicknameSize = checkSuperUser() ? 2 : 3;

export default {
  formName: 'Products',
  service: 'Products',
  hideSaveButton: true,
  hideReturnButton: true,
  business: ProductBusiness,
  icon: ICON_PRODUCT,
  routes: {
    list: {
      path: '/supply/products',
      name: 'supply-products-list',
    },
    edit: {
      path: '/supply/products/edit/:id',
      name: 'supply-products-edit',
    },
    create: {
      path: '/supply/products/create',
      name: 'supply-products-create',
    },
  },
  titles: {
    item: 'Produto e Serviço',
    items: 'Produtos e Serviços',
    edit: 'Editar Produto e Serviço',
    create: 'Criar Produto e Serviço',
    new: 'Novo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  canClone: true,
  filterUrl: 'products/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'Informações do Produto',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h5',
    },
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      required: true,
      md: 8,
      sortable: true,
    },
    {
      label: 'Código',
      name: 'code',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: 4,
      sortable: true,
    },
    {
      label: 'Apelido',
      name: 'nickname',
      type: 'text',
      list: true,
      component: 'dynamic-text-field',
      md: nicknameSize,
      sortable: true,
    },
    {
      label: 'Usar Apelido',
      name: 'use_nickname',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: useNicknameSize,
    },
    {
      label: 'Integração MarketPlace',
      name: 'use_integration_marketplace',
      formattedName: 'use_integration_marketplace_formatted',
      type: 'boolean',
      visible: () => checkSuperUser(),
      component: 'dynamic-checkbox',
      default: false,
      md: 2,
    },
    {
      label: 'Código SKU',
      name: 'skucode',
      type: 'text',
      visible: () => checkSuperUser(),
      component: 'dynamic-text-field',
      prependIcon: ICON_FORM_NUMERIC,
      md: 3,
      sortable: true,
      disabled: (formValue) => !formValue.use_integration_magis5,
      mask: '########',
      hint: 'O código SKU deve conter 8 dígitos.',
    },
    {
      label: 'Detalhes do Produto',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Tipo do Produto',
      name: 'product_type_id',
      formattedName: 'product_type.to_s',
      type: 'number',
      list: true,
      redirectCreateRoute: 'tables-producttypes-create',
      component: 'dynamic-select',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['product_type'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      required: true,
    },
    {
      label: 'Unidade de medida',
      name: 'unity_id',
      formattedName: 'unity.to_s',
      type: 'number',
      list: true,
      redirectCreateRoute: 'tables-measuringunits-create',
      component: 'dynamic-select',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['unity'],
      },
      itemText: 'description',
      itemValue: 'id',
      md: 3,
      required: true,
    },
    {
      label: 'Categoria do Produto',
      name: 'product_category_id',
      formattedName: 'product_category.to_s',
      type: 'number',
      list: true,
      redirectCreateRoute: 'tables-productcategories-create',
      component: 'dynamic-select',
      clearable: true,
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['product_category'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
    },
    {
      label: 'Código de Barras',
      name: 'barcode',
      prependIcon: ICON_FORM_NUMERIC,
      type: 'text',
      component: 'dynamic-text-field',
      md: 3,
      list: true,
      sortable: true,
      hint: 'O código de barra deve conter 8, 12, 13 ou 14 dígitos.',
    },
    {
      label: 'Informações de Tributação',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'NCM',
      name: 'ncm_id',
      formattedName: 'ncm.to_s',
      type: 'number',
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-ncms-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['ncm'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      required: true,
    },
    {
      label: 'CEST',
      name: 'cest_id',
      formattedName: 'cest.to_s',
      type: 'number',
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-cests-create',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['cest'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
    },
    /*
    {
      label: 'Código do Serviço',
      name: 'service_code_id',
      formattedName: 'service_code.to_s',
      type: 'number',
      component: 'dynamic-select',
      redirectCreateRoute: 'tables-servicecodes-create',
      dataSource: {
        service: 'AllTypes',
        method: 'getManyByTypeExcludeDisables',
        props: ['service_code'],
      },
      itemText: 'to_s',
      itemValue: 'id',
      md: 3,
      disabled: true,
    },
    */
    {
      label: 'Valores do Produto',
      component: 'dynamic-title',
      md: 12,
      size: 'md',
      propTag: 'h6',
    },
    {
      label: 'Custo de aquisição',
      name: 'purchase_cost',
      formattedName: 'purchase_cost_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
      list: true,
    },
    {
      label: 'Valor de venda',
      name: 'sale_value',
      formattedName: 'sale_value_formatted',
      type: 'number',
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
      list: true,
    },
    {
      label: 'Inativo',
      name: 'use_inactive',
      formattedName: 'use_inactive_formatted',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 1,
      offsetMd: 5,
      list: false,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabProducts,
    },
    {
      icon: ICON_DESCRIPTION,
      title: 'Composição',
      tabContent: TabProductsCompositions,
    },
    {
      icon: ICON_DESCRIPTION,
      title: 'Processos de fabricação',
      tabContent: TabProductsCompositionFactory,
    },
  ],
};
