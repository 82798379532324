import {
  ICON_DESCRIPTION,
  ICON_PRODUCT,
  ICON_TAG_MULTIPLE,
} from '@constants/icons';
import PriceListItem from './PriceListItem';
import TabPriceList from '@modules/entries/pages/pricelist/components/TabPriceList';
import TabPriceListItems from '@modules/entries/pages/pricelist/components/TabPriceListItems';
// import { OPTIONS_CASHFLOWS_TYPE } from '@constants/options';
// import CashFlowBusiness from '@businesses/CashFlow';

export default {
  service: 'PriceList',
  // business: CashFlowBusiness,
  icon: ICON_TAG_MULTIPLE,
  routes: {
    list: { path: '/entries/pricelist', name: 'entries-pricelist-list' },
    create: { path: '/entries/pricelist/create', name: 'entries-pricelist-create' },
    edit: { path: '/entries/pricelist/edit/:id', name: 'entries-pricelist-edit' },
  },
  titles: {
    item: 'Lista de Preço',
    items: 'Listas de Preços',
    edit: 'Editar Lista de Preço',
    create: 'Criar Lista de Preço',
    new: 'Novo',
  },
  hideReportsOnCreate: true,
  hideReportsOnUpdate: true,
  messages: {
    successDelete: 'Lista de Preço excluida com sucesso!',
    successEdit: 'Lista de Preço alterada com sucesso!',
    successCreate: 'Lista de Preço criada com sucesso!',
    errorSave: 'Erro ao salvar lista de preço!',
    askSaveAsDefault: 'Tem certeza que deseja alterar os valores padrão de acordo com a lista?',
    confirmSaveAsDefault: 'Os valores dos produtos foram alterados com sucesso',
    errorSaveAsDefault: 'Não foi possivel alterar os valores dos produtos, tente novamente mais tarde',
    errorSearchSaveAsDefault: 'Nenhum produto na lista, preencha as categorias e clique em buscar produtos',
    askApplyDefaultInListValue: 'Tem certeza que deseja alterar os valores da lista pelos valores padrão?',
    askCalcNewValues: 'Tem certeza que deseja alterar os valores da lista de preços?',
    askGetNewItems: 'Tem certeza que deseja carregar novos itens?',
  },
  filterUrl: 'pricelist/filters',
  formName: 'pricelist',
  fields: [
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      component: 'dynamic-text-field',
      required: true,
      list: true,
      md: 10,
    },
    {
      label: 'Inativo',
      name: 'is_inactive',
      type: 'boolean',
      component: 'dynamic-checkbox',
      md: 2,
    },
    {
      label: 'Categorias dos Produtos',
      name: 'category_ids',
      formattedName: 'categories',
      // type: 'number',
      list: true,
      multiple: true,
      chips: true,
      hint: 'É possivel escolher mais de uma categoria',
      redirectCreateRoute: 'tables-productcategories-create',
      component: 'dynamic-select',
      dataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['product_category'],
      },
      itemText: 'description',
      itemValue: 'id',
      type: 'select',
      filterOptionsType: 'dynamic',
      filterOptionsDataSource: {
        service: 'AllTypesCommands',
        method: 'getManyByTypeExcludeDisables',
        props: ['product_category'],
      },
      options_key: 'orders_production_status',
      md: 6,
    },
    {
      label: 'Valor Mínimo',
      name: 'min_value',
      type: 'number',
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
    },
    {
      label: 'Valor Máximo',
      name: 'max_value',
      type: 'number',
      component: 'dynamic-number-field',
      preference: ['General', 'number_precision_money'],
      md: 3,
    },
  ],
  childSchemas: [
    {
      label: 'Produtos',
      name: 'items',
      schema: PriceListItem,
      noCreate: true,
      noDelete: true,
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabPriceList,
    },
    {
      icon: ICON_PRODUCT,
      title: 'Produtos',
      tabContent: TabPriceListItems,
      noActions: true,
    },
  ],
};
