import { ICON_TITLE, ICON_DESCRIPTION } from '@constants/icons';
import {
  OPTIONS_TYPE_INVOICES,
  OPTIONS_TYPE_INVOICES_ENVIRONMENTS,
  OPTIONS_TYPE_INVOICES_RPS_NATURE_OPERATIONS,
  OPTIONS_TYPE_INVOICES_RPS_SPECIAL_TAX_REGIME,
  OPTIONS_TYPE_INVOICES_RPS_TAX_REGIME,
  OPTIONS_UF,
} from '@constants/options';
import InvoiceTemplateBusiness from '@businesses/InvoiceTemplate';

import TabInvoiceTemplate from '@modules/settings/pages/invoicetemplates/components/TabInvoiceTemplate';
import TabInvoiceTemplateRPS from '@modules/settings/pages/invoicetemplates/components/TabInvoiceTemplateRPS';
import TabInvoiceTemplateNFCe from '@modules/settings/pages/invoicetemplates/components/TabInvoiceTemplateNFCe';

const citiesItems = [];

export function setCitiesItems(params) { citiesItems.push(params); }
export function getCitiesItems() { return citiesItems; }
export function resetCitiesItems() { citiesItems.splice(0); }

export default {
  service: 'InvoiceTemplates',
  business: InvoiceTemplateBusiness,
  icon: ICON_TITLE,
  hideSaveButton: true,
  hideReturnButton: true,
  hideActionsMenu: true,
  outerStatusDetails: true,
  routes: {
    list: 'settings-invoices-list',
    create: { name: 'settings-invoices-create', path: '/settings/invoices/create' },
    edit: { name: 'settings-invoices-edit', path: '/settings/invoices/edit/:id' },
  },
  titles: {
    item: 'Modelo de Documentos Fiscal',
    items: 'Modelos de Documentos Fiscais',
    edit: 'Editar Modelo de Documento Fiscal',
    new: 'Novo',
  },
  messages: {
    successDelete: '',
    successEdit: '',
    successCreate: '',
    errorSave: '',
  },
  filterUrl: 'invoices_settings/filters', // TODO remove use of filterUrl, use getByFilter from DynamicService
  fields: [
    {
      label: 'Descrição',
      name: 'description',
      type: 'text',
      required: true,
      list: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Tipo do Modelo',
      name: 'type_invoice',
      formattedName: 'type_invoice_formatted',
      type: 'string',
      required: true,
      list: true,
      component: 'dynamic-select',
      items: OPTIONS_TYPE_INVOICES,
      md: 4,
    },
    {
      label: 'Ambiente',
      name: 'environment',
      formattedName: 'environment_formatted',
      type: 'string',
      required: true,
      list: true,
      component: 'dynamic-select',
      items: OPTIONS_TYPE_INVOICES_ENVIRONMENTS,
      md: 4,
    },
    {
      label: 'Modelo',
      name: 'model',
      type: 'number',
      required: true,
      list: true,
      component: 'dynamic-text-field',
      md: 4,
    },
    {
      label: 'Série',
      name: 'series',
      type: 'number',
      list: true,
      component: 'dynamic-text-field',
      md: 4,
      disabled: (formValue) => formValue?.type_invoice === 'nfse',
      required: (formValue) => formValue?.type_invoice !== 'nfse',
    },
    {
      label: 'Número Atual',
      name: 'current_number',
      type: 'number',
      list: true,
      component: 'dynamic-text-field',
      md: 4,
      disabled: (formValue) => formValue?.type_invoice === 'nfse',
      required: (formValue) => formValue?.type_invoice !== 'nfse',
    },
    // RPS
    {
      label: 'UF',
      name: 'uf',
      type: 'string',
      list: true,
      component: 'dynamic-select',
      items: OPTIONS_UF,
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Cidade/Município',
      name: 'city',
      type: 'text',
      hint: 'Selecione o estado para carregar as cidades',
      component: 'dynamic-select',
      items: citiesItems,
      tab: 'RPS',
      md: 6,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Natureza da Operação',
      name: 'nfs.nature_operation',
      formattedName: 'nature_operation_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_TYPE_INVOICES_RPS_NATURE_OPERATIONS,
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Regime Tributário',
      name: 'nfs.tax_regime',
      formattedName: 'tax_regime_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_TYPE_INVOICES_RPS_TAX_REGIME,
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Regime Especial de Tributação',
      name: 'nfs.special_tax_regime',
      formattedName: 'special_tax_regime_formatted',
      type: 'string',
      component: 'dynamic-select',
      items: OPTIONS_TYPE_INVOICES_RPS_SPECIAL_TAX_REGIME,
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Série do Lote Atual',
      name: 'nfs.current_batch_serie',
      type: 'number',
      component: 'dynamic-text-field',
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Número do RPS Atual',
      name: 'nfs.current_batch_number',
      type: 'number',
      component: 'dynamic-text-field',
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Login da Prefeitura',
      name: 'nfs.city_hall_login',
      type: 'string',
      component: 'dynamic-text-field',
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Senha da Prefeitura',
      name: 'nfs.city_hall_password',
      type: 'string',
      component: 'dynamic-input-password',
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Alíquota ISS',
      name: 'nfs.aliquot_iss',
      type: 'string',
      component: 'dynamic-text-field',
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Senha do Certificado',
      name: 'nfs.access_key',
      type: 'string',
      component: 'dynamic-input-password',
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Email',
      name: 'nfs.email',
      type: 'string',
      component: 'dynamic-text-field',
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Enviar notificação p/ o cliente',
      name: 'nfs.send_notification_to_customer',
      type: 'boolean',
      component: 'dynamic-checkbox',
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'Receber notificação',
      name: 'nfs.get_notification',
      type: 'boolean',
      component: 'dynamic-checkbox',
      tab: 'RPS',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfse') {
          return true;
        }
        return false;
      },
    },
    // {
    //   label: 'Empresa Cadastrada API NFSe',
    //   name: 'nfs.company_check_api_nfse',
    //   type: 'boolean',
    //   component: 'dynamic-checkbox',
    //   tab: 'RPS',
    //   md: 3,
    //   disabled: true,
    // },
    {
      label: 'ID Token',
      name: 'nfce.id_token',
      type: 'string',
      component: 'dynamic-text-field',
      tab: 'NFC-e',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfce') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfce') {
          return true;
        }
        return false;
      },
    },
    {
      label: 'CSC do Token',
      name: 'nfce.csc_token',
      type: 'string',
      component: 'dynamic-text-field',
      tab: 'NFC-e',
      md: 3,
      disabled: (formValue) => {
        if (formValue?.type_invoice === 'nfce') {
          return false;
        }
        return true;
      },
      required: (formValue) => {
        if (formValue?.type_invoice === 'nfce') {
          return true;
        }
        return false;
      },
    },
  ],
  tabs: [
    {
      icon: ICON_DESCRIPTION,
      title: 'Detalhes',
      tabContent: TabInvoiceTemplate,
    },
    {
      icon: ICON_DESCRIPTION,
      title: 'RPS',
      tabContent: TabInvoiceTemplateRPS,
    },
    {
      icon: ICON_DESCRIPTION,
      title: 'NFC-e',
      tabContent: TabInvoiceTemplateNFCe,
    },
  ],
};
