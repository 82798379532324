import { AccountingAccounts } from './pages/accountingaccount/service';
import { AllTypes, AllTypesCommands } from './pages/alltypes/service';
import { CostCenters } from './pages/costcenter/service';
import { Countries } from './pages/alltypes/service';
import {
  CloneNatureOperationStates, NatureOperations, NatureOperationStates, NatureOperationStatesICMS, NatureOperationCommands,
} from './pages/taxes/natureOperations/service';
import NationalSimple from './pages/taxes/nationalSimple/service';
import { TypeInstallment, TypeInstallmentCommands } from './pages/typeinstallments/service';

export { default as routes } from './routes';

export const globalServices = {
  AccountingAccounts,
  AllTypes,
  AllTypesCommands,
  CostCenters,
  Countries,
  NatureOperations,
  NatureOperationCommands,
  NatureOperationStates,
  NatureOperationStatesICMS,
  NationalSimple,
  TypeInstallment,
  TypeInstallmentCommands,
  CloneNatureOperationStates,
};
