<template>
  <dm-inline-calendar-selector
    v-if="!readOnly && !field.readOnly"
    :required="checkRequired()"
    :hint="field.hint"
    :persistent-hint="true"
    :disabled="checkDisabled()"
    :label="field.label"
    :date="fieldValue"
    :text-input-class="field.class"
    :rules="validationRules"
    :clearable="field.clearable"
    :type="field.type"
    :default-date="field.defaultDate"
    :rounded="field.rounded"
    :filled="field.filled"
    :prepend-icon="innerIcon ? '' : prependIcon"
    :prepend-inner-icon="innerIcon ? prependIcon : ''"
    v-bind="$attrs"
    v-on="$listeners"
    @update:date="fieldChanged" />
  <div v-else class="dm-information">
    <label>{{ field.label }}</label>
    <p v-if="field.dateFormat">
      {{ fieldValue | formatUTCDate(field.dateFormat) }}
    </p>
  </div>
</template>

<script>
import { ICON_CALENDAR } from '@constants/icons';
import InlineCalendarSelector from '@sharedComponents/form/InlineCalendarSelector';
import DynamicFieldMixin from './DynamicFieldMixin';
import DynamicUtils from './DynamicUtils';

export default {
  name: 'DynamicDateField',
  components: {
    'dm-inline-calendar-selector': InlineCalendarSelector,
  },
  mixins: [DynamicFieldMixin],
  data() {
    return {
      DynamicUtils,
      fieldValue: null,
      defaultIcon: ICON_CALENDAR,
    };
  },
  computed: {
    prependIcon() {
      return this.field.prependIcon === undefined ? this.defaultIcon : this.field.prependIcon;
    },
    appendIcon() {
      return this.field.appendIcon;
    },
    innerIcon() {
      return this.field.useInnerIcon === undefined ? false : this.field.useInnerIcon;
    },
  },
};
</script>

<style lang="scss"></style>
